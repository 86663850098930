/* eslint-disable */
ee.views["campaigns"] = (function ViewCampaigns() {
    /*-- Returned object of view class--*/
    var that = {}, _thumbnailOptions = { target: '.etemplateboxtd', canvaswidth: 80, canvasheight: 60 }
    /*-- Private property --*/
    var listCampaigns = {}, $screen, activeFilters = [], pagination, data,
        timeUnits = [{ name: 'days', time: 1440 }, { name: 'hours', time: 60 }, { name: 'minutes', time: 1 }],
        DefaultFilters = { status: ["0", "1", "2", "3", "4", "5"], type: ["1", "2"] },
        perpage = 25;
    /*-- Private methods --*/
    var _isDataCampaignReady = function () {
        if (!ee.data.contactgroups) {
            ee.api.loadContactOverview();
            return false;
        }
        if (!ee.data.channels) {
            ee.api.loadcampaigns(_parserCampaignList);
            return false;
        }
        if (!ee.data.channels.isFormatedCampaignList) {
            _parserCampaignList();
            ee.indiOff();
        }
        return true;
    };
    let _formatSingleChannel = (channel) => {

        var email = channel?.recipientcount;
        channel.statustype = ee.views.campaigns.getStatusName(channel.status ? channel.status : 0) //channels sent to us are always active, and they don't have status sent.
        channel.statusname = ee.t[channel.statustype?.toLowerCase?.()];
        channel.typeName = ee.views.campaigns.getTypeName(channel);
        channel.dateTime = new Date(channel?.dateadded)?.getTime?.();
        //Common settings
        channel.triggerfrequencyConverted = ee.views.campaigns.getUnit(channel.triggerfrequency);
        channel.triggerdelayConverted = ee.views.campaigns.getUnit(channel.triggerdelay);
        channel.splitoptimizationminutesConverted = ee.views.campaigns.getUnit(channel.splitoptimizationminutes);
        channel.isEditable = ee.views.campaigns.isEditable?.(channel.status, channel.triggertype);
        channel.sent = channel.sentcount?.numberFormat?.(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        channel.opened = channel.openedcount?.numberFormat?.(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        channel.clicked = channel.clickedcount?.numberFormat?.(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        channel.bounced = channel.failedcount?.numberFormat?.(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        channel.complaint = channel.failedabuse?.numberFormat?.(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        channel.unsubscribe = channel.unsubscribedcount?.numberFormat?.(0, ee.t.decimelseparator, ee.t.thousandsseparator);
        channel.iscampaign = channel.iscampaign ? channel.iscampaign : false;
        //Calculate statistic
        if (email) {
            channel.deliveredPERC = (channel.sentcount / email * 100).toFixed(2);
            channel.deliveredClass = _colorClass(channel.deliveredPERC, 'delivered');
            if (channel?.sentcount) {
                channel.openedPERC = (channel?.openedcount / channel?.sentcount * 100).toFixed(2);
                channel.clickedPERC = (channel?.clickedcount / channel?.sentcount * 100).toFixed(2);
                channel.complaintPERC = (channel?.failedabuse / channel?.sentcount * 100).toFixed(2);
                channel.unsubscribePERC = (channel?.unsubscribedcount / channel?.sentcount * 100).toFixed(2);
            } else {
                channel.unsubscribePERC = channel.complaintPERC = channel.clickedPERC = channel.openedPERC = '0.00'
            }
            channel.openedClass = _colorClass(channel.openedPERC, 'opened');
            channel.clickedClass = _colorClass(channel.clickedPERC, 'clicked');
            channel.bouncedPERC = (channel.failedcount / email * 100).toFixed(2);
            channel.bouncedClass = _colorClass(channel.bouncedPERC, 'bounced');
        } else {
            channel.unsubscribePERC = channel.complaintPERC = channel.bouncedPERC = channel.deliveredPERC = channel.openedPERC = channel.clickedPERC = '0.00';
            channel.bouncedClass = channel.deliveredClass = channel.openedClass = channel.clickedClass = 'eterrible';
        }
        //Format Attachments in TemplateChannels
        if (channel.templatechannels) {
            if (channel.templatechannels.length > 1) {
                channel.split = true;
            }
            for (var i = 0, n = channel.templatechannels.length; i < n; i++) {
                var templatechannel = channel.templatechannels[i];
                templatechannel.screenshotpath = ee.api.path + "/userfile" + templatechannel.screenshot;
                templatechannel.mediumscreenshotpath = ee.api.path + "/userfile" + templatechannel.mediumscreenshot;
                templatechannel.thumbnailpath = ee.api.path + "/userfile" + templatechannel.thumbnail;
                if (templatechannel.templateattachments) {
                    for (var j = 0, m = templatechannel.templateattachments.length; j < m; j++) {
                        templatechannel.templateattachments[j].sizeMB = ee.tools.bytesToSize(templatechannel.templateattachments[j].size);
                    }
                }
            }
            if (channel.templatechannels.length === 1) {
                channel.firstscreenshotpath = channel.templatechannels[0].screenshotpath;
                channel.firstmediumscreenshotpath = channel.templatechannels[0].mediumscreenshotpath;
                channel.firstthumbnailpath = channel.templatechannels[0].thumbnailpath;
                channel.firsttemplateid = channel.templatechannels[0].templateid;
                if (ee.data.channelscreenshotrefresh && ee.data.channelscreenshotrefresh === channel.channelid) {
                    channel.firstscreenshotpath += "?time=" + (new Date()).getTime();
                }
            }
        }
        if (channel.isEditable) {
            ee.data.openclickcampaigns.push(channel);
        }
    };
    let _colorClass = (val, type) => {
        switch (type) {
            case 'delivered':
                if (val >= 95) return 'egreat';
                if (val >= 90) return "egood";
                if (val >= 80) return "enormal";
                if (val >= 50) return "ebad";
                return "eterrible";
                break;
            case 'opened':
                if (val >= 20) return "egreat";
                if (val >= 10) return "egood";
                if (val >= 5) return "enormal";
                if (val >= 1) return "ebad";
                return "eterrible";
                break;
            case 'clicked':
                if (val >= 5) return "egreat";
                if (val >= 2) return "egood";
                if (val >= 0.1) return "enormal";
                if (val >= 0.05) return "ebad";
                return "eterrible";
                break;
            case 'bounced':
                if (val <= 1) return "egreat";
                if (val <= 3) return "egood";
                if (val <= 5) return "enormal";
                if (val <= 10) return "ebad";
                return "eterrible";
        }
    };
    let _formatChannels = (data, search) => {

        let targetobject;
        if (!search) {
            targetobject = ee.data.channels;
        }
        targetobject = new Collection.Data(data);
        ee.data.openclickcampaigns = [];
        targetobject.each(function () {
            _formatSingleChannel(this);
        });
        ee.data.channelscreenshotrefresh = null;
        return targetobject;
    };
    //Note: this should be move to api method doing after campaign/list request
    var _parserCampaignList = function (callback) {
        ee.data.channels.isFormatedCampaignList = true;
        var now = new Date(), millis = now.getTime() + (now.getTimezoneOffset() * 60000);
        //Parse data...
        ee.data.channels.each(function () {
            var triggertime = (this.triggerdate) ? new Date(this.triggerdate).getTime() : 0;
            //Added new local varible
            this.options = {
                editable: false,
                details: true,
                showReport: false,
                reactivate: false,
                inprogress: false,
                pause: false,
                deletes: true
            };
            if (_isEditable(this.status, this.triggertype) === true) {
                this.options.editable = true;
                this.options.details = false;
            }
            this.isShowReport = false;
            if (this.status !== 6 && millis > triggertime) {
                this.options.showReport = true;
            }
            //Note: Only autoresponder can paused/reactive
            // this.isPaused = false;
            if (this.status === 4) {//Note: relaxed conditions, because old normal campaigns should possibility to reactivated from paused
                this.options.reactivate = true;
            }
            //Note: Only autoresponders and repeatly campaign should posibilty to paused && triggertime < millis
            //Note: M.F. Cancel in progress was not an option for Sending/Processing statuses because of trigger time filter that was on the first IF block...
            //I moved it to the second one and seems to work
            if (this.status >= 0 && this.status < 3 && triggertime < millis) {
                if ((this.triggertype > 2 || this.triggercount > 1 || (this.triggertype === 2 && this.triggerfrequency > 0))) {
                    this.options.pause = true;
                } else if (this.triggertype == 2 && this.status == 0 && this.lastprocessed == null && (moment.utc(this.triggerdate) < moment())) { //check for scheduled campaigns that were not processed by system on time
                    this.options.inprogress = false; //You can cancel only campaigns that are actually IN PROGRESS, so "Processing" and "Sending"
                    this.options.deletes = true;
                } else {
                    this.options.inprogress = true;
                    this.options.deletes = false;
                }
            }

            //Formated targetsname
            if ($.isArray(this.targets) && this.targets.length > 0) {
                var targetsname = [];
                for (var i = 0, n = this.targets.length; i < n; i++) {
                    var value = parseInt(this.targets[i].substr(1)), type = this.targets[i][0], selectedset;
                    selectedset = ee.data.contactgroups.get(((type === "s") ? 'segmentid' : 'listid'), value)
                    if (selectedset) targetsname.push(selectedset.name);
                }
                this.targetsformatted = targetsname;
            }
            //Last activity
            this.lastprocessed = (!_.isNil(this)) ? this.lastprocessed : this.lastactivity;
            if (!this.lastprocessed) {
                this.lastprocessed = this.dateadded;
            }
            this.lastprocessedformatted = moment(this.lastprocessed).format(ee.tools.getLocalDateFormat()); //EE-1605, lastprocessed was formatted to locale string and later interpreted still as a datetime string
            //Trigger type
            if (this.templatechannels.length > 1 && this.triggertype < 3) {
                //Split onlu for regular and scheduler trigger type
                this.triggertypename = ee.t.split;
            } else {
                switch (this.triggertype) {
                    case 3:
                        this.triggertypename = ee.t.onadd
                        break;
                    case 4:
                        this.triggertypename = ee.t.onopen
                        break;
                    case 5:
                        this.triggertypename = ee.t.onclick
                        break;
                    default:
                        break;
                }
            }

            //counts
            this.recipientcount = parseInt(this.recipientcount);
            this.sentcount = parseInt(this.sentcount);
            this.clickedrate = this.sentcount  ? (100 * (this.clickedcount/this.sentcount)).toFixed(2) : "0";
            this.openedrate = this.sentcount ? (100 * (this.openedcount/this.sentcount)).toFixed(2) : "0";
            this.recipientcount = this.recipientcount + "";
            this.sentcount = this.sentcount + "";
            //Info
            if (this.templatechannels.length > 0) {
                var info = [];
                if (this.triggerdate && millis < triggertime) {
                    info.push(ee.t.scheduledfor + " <strong>" + ee.tools.time(this.triggerdate).fromUTC() /*ee.api.formatDate(this.triggerdate, "yy/mm/dd hh:ii")*/+ " </strong>");
                }
                if (this.triggercount === 0) {
                    info.push(ee.t.sendindefinitely + " " + ee.t.every + " <strong>" + this.triggerfrequencyConverted.value + " " + this.triggerfrequencyConverted.unit.toLowerCase() + "</strong>");
                }
                if (this.triggercount > 1) {
                    info.push(ee.t.send + " <strong>" + this.triggercount + "</strong> " + ee.t.times + " " + ee.t.every + " <strong>" + this.triggerfrequencyConverted.value + " " + this.triggerfrequencyConverted.unit.toLowerCase() + "</strong>");
                }
                if (this.triggerdelay > 0) {
                    info.push(ee.t.sendlower + " <strong>" + this.triggerdelayConverted.value + " " + this.triggerdelayConverted.unit.toLowerCase() + "</strong> " + ee.t.afterevent);
                }
                this.info = info.join(" | ");
            }
            if (this.info || this.triggertypename) {
                this.additionaloptions = true;
            }
            
        });
        if (!callback) {
            that.show();
            return;
        }
        callback();
    };

    var _filterChannels = function () {

        if (activeFilters.type[0] === "6") {
            data = listCampaigns.filter(['status'], '6', true);
            $screen.statssection.addClass("disabled");
            $screen.campaignsTable.addClass("hideStatus");
        } else {
            $screen.statssection.removeClass("disabled");
            $screen.campaignsTable.removeClass("hideStatus");
            data = listCampaigns.filter(['triggertype'], activeFilters.type, true).filter(['status'], activeFilters.status, true);
            /*NOTE: In first run we are sending array with numbers and functions TO DO: unify activeFilters.type in first run with others */
            if (activeFilters.type.indexOf("2") !== -1 || activeFilters.type.indexOf(2) !== -1) {
                data = data.filter("triggerfrequency", "0", true);
            } else {
                data.add(listCampaigns.filter("triggertype", "2", true).filter("triggerfrequency", "0", true, true).getAll())
            }
        }
        //Set tab
        $screen.triggertype.find("li").removeClass("active");
        $screen.triggertype.find("li[data-value=" + activeFilters.type.join("-") + "]").addClass("active");
        if (data.length === 0) {
            ee.t.dynamicEmptyCampaignListColspan = 7;
            if (activeFilters.type[0] === "6") {
                ee.t.dynamicEmptyCampaignListColspan = 6
                ee.t.dynamicEmptyCampaignList = ee.t.emptydraftscampaignlist;
            } else if (activeFilters.type.length === 3) {
                ee.t.dynamicEmptyCampaignList = ee.t.emptyautomationcampaignlist;
            } else {
                ee.t.dynamicEmptyCampaignList = ee.t.emptycampaignlist;
            }
            //List is empty then display info and stop method
            return;
        }
       
        $screen.searchinputcampaign.val("");
        _searchCampaigns()
        dropdownMenuIsClicked = false;
        ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);


    };
    let CampaignNameSearch = _.debounce((searchstring, filter) =>{
        return EE_API.Campaign.List({search: searchstring, limit: 500})
        .then((data) => {
            //tmpData = searchdata;
            searchdata = _.filter(data, (campaign) => {
                return _getTabType(campaign) === filter;
            })
            return searchdata
        })
        .then((searchdata) =>{

            return _formatChannels(searchdata, true)
        })
        .then((searchdata) => {
            const millis = parseInt(moment().format('x'));
            searchdata.each( (i) => {
                let element = searchdata.data[i];
                const triggertime = (element.triggerdate) ? parseInt(moment(element.triggerdate).format('x')) : 0;
                //Added new local varible
                element.options = {
                    editable: false,
                    details: true,
                    showReport: false,
                    reactivate: false,
                    inprogress: false,
                    pause: false,
                    deletes: true
                };
                if (element.status !== 6 && millis > triggertime) {
                    element.options.showReport = true;
                }
            })
            pagination.updateData(searchdata.getAll());
            html.widget.TemplateThumbnail.init($screen, _thumbnailOptions);
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
            return searchdata
        })
        .catch((err) => {
            console.log(err)
        })
    }, 
    500, {
        'leading': false,
        'trailing': true
    });
    var _searchCampaigns = function () {
        var searchstring = $screen.searchinputcampaign.val(),
            from = $screen.datefrom.datepicker('getDate'),
            to = $screen.dateto.datepicker('getDate'),
            //filters = $('#etriggertype li.active').data('value').split('-'),
            tmpData;
            let filter = $('#etriggertype li.active').data('type');
        pagination.setPage(1);//back to first page
        if (searchstring !== "") {
            CampaignNameSearch(searchstring, filter);
        } else {
            tmpData = data;
            if (from && to) {
                to.setHours(23); to.setMinutes(59);
                from.setHours(0); from.setMinutes(0);
                tmpData = tmpData.filterBetweenDate("dateTime", from, to);
            }
            if (tmpData.data.length === 0) {
                ee.t.dynamicEmptyCampaignListColspan = (activeFilters.type[0] === "6") ? 6 : 7;
                ee.t.dynamicEmptyCampaignList = ee.t.desclistnotfound;
            }
            pagination.updateData(tmpData.getAll());
            html.widget.TemplateThumbnail.init($screen, _thumbnailOptions);
        }
    };

    var _getUnit = function (val) {
        var v = { value: 0, unit: ee.t.days };
        $.each(timeUnits, function () {
            if (val % this.time === 0) {
                v.value = val / this.time;
                v.unit = ee.t[this.name];
                v.time = this.time;
                return false;
            }
        });
        return v;
    };

    var _getStatusName = function getStatusName(val) {
        switch (val) {
            case -1: return "Deleted";
            case 0: return "Active";
            case 1: return "Processing"; //Inprogress
            case 2: return "Sending";    //Inprogress
            case 3: return "Completed";
            case 4: return "Paused";
            case 5: return "Cancelled";
            case 6: return "Draft";
        }
    };

    var _getTypeName = function getTypeName(campaign) {

        if (campaign.status === 6) {
            return ee.t.draft;
        }
        if (campaign.triggertype > 2) {
            return ee.t.automation;
        }
        if (campaign.triggertype === 2) {
            return ee.t.scheduledfor + ": " + ee.api.formatDate(campaign.triggerdate, "yy/mm/dd hh:ii");
        }
        return ee.t.regular;

    };
    var _getTabType = function getTabType(campaign) {
        if (campaign.status === 6) {
            return "draft"
        }
        if (campaign.triggertype > 2) {
            return "auto"
        }
        return "campaign"

    };

    var _changeCampaignStatus = function (camapignId, newStatus) {
        var campaign = listCampaigns.get("channelid", camapignId),
            method = (newStatus === 0) ? "/campaign/reactivate" : "/campaign/pause";
        if (campaign) {
            ee.indiOn();
            ee.api.request(method, { channelid: camapignId }, function (data) {
                ee.indiOff();
                campaign.status = newStatus;
                campaign.statusname = _getStatusName(newStatus);
                if (newStatus === 0) {
                    campaign.options.reactivate = false;
                    campaign.options.pause = true;
                } else {
                    campaign.options.reactivate = true;
                    campaign.options.pause = false;
                }
                pagination.refresh();
            });
        };
    }

    var _isEditable = function (status, triggertype) {
        //Campaign is complited user can't edit it
        if (status === 5) {
            return false;
        }
        //Camapgin is fresh, autoresponder, draft, or scheduled and not sending
        if (triggertype > 2 || status === 6 || status === 4 || (triggertype === 2 && status === 0)) {
            return true;
        }
        return false;
    };

    var _resetInstances = function () {
        $screen.remove();
        pagination = false;
        data = false;
    }
    /*-- Public property and methods --*/
    let dropdownMenuIsClicked;
    that.menuItemsOnClick = (row) => {
        dropdownMenuIsClicked = true;
        if (row == null) {return;}
        $(row.parentElement).off('click');

        //Go To reports
        $(row.parentElement).on("click", "li.eaction-raport", function () {
            dropdownMenuIsClicked = false;
            ee.goTo("#/activity/" + $(this).data("channelid"));
        });
        //Edit/ViewDetails
        $(row.parentElement).on("click", "li.eaction-edit", function () {
            dropdownMenuIsClicked = false;
            ee.goTo("#/campaigncreator/" + $(this).data("channelid"));
        });
        //Copy Campaign
        $(row.parentElement).on("click", ".eaction-copycontacts", function () {
            dropdownMenuIsClicked = false;
            var channel = ee.data.channels.get("channelid", $(this).data("channelid")),
                $content = html.get('CopyContactsToList', { name: channel.name }),
                modal = html.modal.create($content, {
                    title: ee.t.createlist,
                    buttons: [
                        {
                            name: ee.t.save,
                            css: 'btn_lg btn_primary btn-info',
                            callback: function () {
                                var novalidMsg = ee.t.pleasefillallrequiredpoints,
                                    statuses = [],
                                    options = {
                                        campaignID: channel.channelid,
                                        listName: $content.newlistname.val().trim()
                                    };
                                if (!options.listName) return html.modal.info(novalidMsg);
                                $content.find('.estatuscheckbox:checked').each(function () {
                                    statuses.push($(this).data('name'));
                                });
                                if (!statuses.length) return html.modal.info(novalidMsg);
                                options.statuses = statuses.join(',');
                                ee.api.createFromCampaign(options, function () {
                                    modal.close();
                                    html.modal.info(ee.t.processtimewarning);
                                });
                            }
                        }
                    ]
                });
                ee.tools.validateEntityName(
                    {
                        screen: $content,
                        inputname: '#enewlistname',
                        submit: modal.$footer.find('.btn_primary')
                    }
                )

        });

        $(row.parentElement).on("click", ".eaction-copycampaign", function () {
            dropdownMenuIsClicked = false;
            var channelid = $(this).data('channelid');
            html.modal.confirm(ee.t.copycampaignmsg, ee.t.copycampaign, function () {
                //TODO: Need fixing keep filter settings when you reload the page
                ee.indiOn();
                EE_API.Campaign.Copy({ channelid: channelid }).then(function () {
                    ee.data.channels = false;
                    ee.changeHashQuietly('#/campaigns/drafts');
                    ee.view.show();
                    ee.indiOff();

                });
            }, false, { confirmTitle: ee.t.copy });
        });
        const refreshCampaignList = () => {
            EE_API.Campaign.List()
            .then((data) => {
                _formatChannels(data)
                _parserCampaignList();
                listCampaigns = ee.data.channels.filter(['iscampaign'], 'true');
                _filterChannels();
                dropdownMenuIsClicked = false;
                ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
            })
            .catch(err => {
                console.log(err)
            })
        };
        //Reactivate
        $(row.parentElement).on("click", ".eaction-reactivate", function () {
            dropdownMenuIsClicked = false;
            _changeCampaignStatus($(this).data("channelid"), 0);
            refreshCampaignList();
        });
        //Pause
        $(row.parentElement).on("click", ".eaction-pause", function () {
            dropdownMenuIsClicked = false;
            _changeCampaignStatus($(this).data("channelid"), 4);
            refreshCampaignList();
        });
        //Cancel inprogress
        $(row.parentElement).on("click", ".eaction-cancelinprogress", function () {
            dropdownMenuIsClicked = false;
            var campaign = listCampaigns.get("channelid", $(this).data("channelid"));
            if (campaign) {
                ee.api.cancelinprogress(campaign);
                return;
            }
        });
        //Delete Campaign
        ///[\\/\\]/.test(campaignName) - v3 breaks with / and \ in url
        $(row.parentElement).on("click", ".eaction-delete", function () {
            dropdownMenuIsClicked = false;
            const campaign = listCampaigns.get("channelid", $(this).data("channelid"));
            const campaignName = campaign.name;

            if (campaign) {
                if (/[\\/\\]/.test(campaignName) || !ee.session.can.ViewAutomations) {
                    html.modal.confirm(html.render(ee.t.deletecampaignmsg, campaign), ee.t.removecampaign, function () {
                        ee.api.deleteCampaign(campaign.channelid, function () {
                            listCampaigns = ee.data.channels;
                            that.show(activeFilters);
                        });
                    }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" });
                } else {
                    //Resource has 4 possible value, Templates = 0, Lists = 1, Campaign = 2, Automation = 3
                    return API_V3.Journeys.GetAutomationUsage(encodeURIComponent(campaignName), {Resource: 2})
                    .then(function(data) {
                        if (data.length > 0) {
                            const automatedCampaignName = data.join(', ');
                            if (ee.session.can.ModifyAutomations) {
                                html.modal.confirm(ee.t.deletecampaignusageinautomation.replace(/{{AutomatedCampaignName}}/g, "<span class='automationsName'>" + automatedCampaignName + "</span>"),
                                ee.t.removetemplate, function () {
                                    ee.api.deleteCampaign(campaign.channelid, function () {
                                        listCampaigns = ee.data.channels;
                                        that.show(activeFilters);
                                    });
                                }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
                            } else {
                                html.modal.info(ee.t.noaccesstodeletecampaignusageinautomation.replace(/{{AutomatedCampaignName}}/g, "<span class='automationsName'>" + automatedCampaignName + "</span>"));
                            }
                        } else {
                            html.modal.confirm(html.render(ee.t.deletecampaignmsg, campaign), ee.t.removecampaign, function () {
                                ee.api.deleteCampaign(campaign.channelid, function () {
                                    listCampaigns = ee.data.channels;
                                    that.show(activeFilters);
                                });
                            }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" });
                        }
                    })
                    .catch(function(err) {
                        if (data && data.responseJSON) {
                            html.modal.error(data.responseJSON.Error);
                        }
                        console.log(err);
                    })
                }
            }
        });
        
    } 
    that.show = function (filters) {
        ee.initNextGen();
        return;

        dropdownMenuIsClicked = false;
        ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
        if (!ee.session.can.ViewCampaigns) { return ee.goTo("#/dashboard"); }
        ee.indiOn();
        if (!_isDataCampaignReady()) {
            return;
        }
        if ($screen) {
            _resetInstances();
        }
        
        ee.logEvent('Campaings screen loaded.');
        ee.frame.content.empty();
        listCampaigns = ee.data.channels.filter(['iscampaign'], 'true');
        activeFilters = $.extend(true, {}, DefaultFilters, filters);
        //Display screen
        $screen = html.get("Campaigns", { enablecontactfeatures: ee.data.account.enablecontactfeatures, isSub: ee.data.account.issub, isVerified: ee.session.hasOption("InstantAccessVerified") });
        $screen.appendTo(ee.frame.content);
        ee.indiOff();

        dropdownMenuIsClicked = false;
        ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
        $screen.triggertype.on('click', 'li', function (e) {
            e.stopPropagation();
            var $this = $(this), tab = $this.attr("data-hash");
            activeFilters.type = $this.attr("data-value").split("-");
            _filterChannels();
            ee.Router.updateHash('#/campaigns/' + tab, !e.originalEvent);
            
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
            return false;
        });
        
        
        /*-- Bind Events --*/

        //Create Campaign
        if (ee.session.can.ViewCampaigns) {
            $screen.createcampaign.click(() => {
                ee.data.contactgroups = null; //we need to reload segments and lists (contactgroups)
                ee.logEvent('Campaing - Load creator.');
                ee.goTo("#/campaigncreator");
                return false;
            });

        }

        //Add focus to dropdown
        html.addDropdownFocus($screen.campaignsTable);
        
        //Search
        html.bindSearch($screen.searchcampaign, _searchCampaigns, {autoSearch: true});
        //Date Ragne Filter
        var intFromDate = new Date(), initToDate = new Date();
        intFromDate.setMonth((initToDate.getMonth() - 48));
        $screen.dateto.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", initToDate).change(function () {
            _searchCampaigns();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
        });
        $screen.datefrom.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", intFromDate).change(function (e) {
            _searchCampaigns();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
        });
        $screen.dateselect.on('click', 'li', function () {
            var $li = $(this), val = $li.data('range'), to = new Date(), from = new Date();
            $screen.daterangetitle.html($li.html());
            if ($li.hasClass("drop-first") === true) {
                $screen.fromto.removeClass('hide');
            } else {
                $screen.fromto.addClass('hide');
            }
            switch (val) {
                case 'day':
                    from.setTime(to.getTime() - 86400000);
                    break;
                case 'week':
                    from.setTime(to.getTime() - 604800000);
                    break;
                case '2weeks':
                    from.setTime(to.getTime() - (604800000 * 2));
                    break;
                case 'month':
                    from.setMonth((to.getMonth() - 1));
                    break;
                case '3months':
                    from.setMonth((to.getMonth() - 3));
                    break;
                case 'halfyear':
                    from.setMonth((to.getMonth() - 6));
                    break;
            }
            if (val) {
                $screen.dateto.datepicker('setDate', to);
                $screen.datefrom.datepicker('setDate', from);
            }
            _searchCampaigns();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
        });
        //Status Filter
        $screen.statusselect.on('click', 'li', function (e, arr) {
            if ($screen.statssection.hasClass("disabled")) {
                return;
            }
            var $li = $(this), $input = $li.find("input:first");
            if ($(e.target).hasClass('edatacbox')) {
                return $(e.target).parent().trigger('click', [true]);
            }  else {
                var input = $(this).find('.edatacbox')
                if (!arr) input.prop('checked', !input.prop('checked'));
            }
            activeFilters.status = $screen.statusselect.find("input:checked").map(function () {
                return this.value.split(",");
            }).get();

            _filterChannels();
            return false;
        });
        //Refresh
        $screen.on("click", ".action-refresh", function () {
            $screen.find('.paginationlist span').addClass('disabled').removeClass('active');
            $screen.newcampaignslist.html(html.getraw('CampaignListLoading', { info: ee.t.refreshcampaigns }));
            EE_API.Campaign.List()
            .then(data => {
                return _formatChannels(data)
            })
            .then(() => {
                return _parserCampaignList();
            })
            .then(() => {
                listCampaigns = ee.data.channels.filter(['iscampaign'], 'true');
                return _filterChannels();
            }).then(() => {
                dropdownMenuIsClicked = false;
                ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
            })
            .catch(err => {
                console.log(err)
            })
            return false;
        });
        
        //Pagination
        pagination = new html.Pagination([], {
            template: "CampaignList",
            searchparameters: ['name'],
            resultcontainer: $screen.newcampaignslist,
            footercontainer: '.paginationlist',
            perpage: perpage,
            param: {
                enableAdvence: ee.data.account.enablecontactfeatures
            }
        });
        pagination.eventChangePage(function () {
            html.widget.TemplateThumbnail.init($screen, _thumbnailOptions);
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.menuItemsOnClick);
        });
        that.pagination = pagination;
        //First run
        _filterChannels();
        //goTo tabs...

        var $li = $screen.triggertype.find('li[data-hash=' + ee.Router.action() + ']');
        if ($li.length === 1) {
            $li.trigger('click');
        } else {
            ee.Router.updateHash('#/campaigns', true);
        }
        //Preview template
        $screen.on('click', '.etemplateboxtd', function () {
            ee.views.templates.previewContent(this.dataset.templateid);
        });
    };

    /*-- Return --*/
    that.getUnit = _getUnit;
    that.isEditable = _isEditable;
    that.getStatusName = _getStatusName;
    that.getTypeName = _getTypeName;
    that.formatSingleChannel = _formatSingleChannel;
    return that;
})();
