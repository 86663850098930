ee.views['payments'] = (function ViewPayments() {
    var $screen, $paymentsdropdown, $paymenttypeselection, $paymenttier;
    let planList = [];
    var _currentPricingPlan = null;

    //@var bool
    // default value for enabling autorecharge
    var _enableAutoRecharge = true;

    // init stripe for making support payments;
    const _initStripe = () =>
        new Promise((res) => {
            if (!window.Stripe) {
                return ee.tools.loadScript('https://js.stripe.com/v3/').then(() => {
                    const key = ee.isLive() ? ee.api.publickey : 'pk_test_IrRPpaascKmIELAj1u1jNAZP';
                    window.stripe = new Stripe(key);
                    res(true);
                });
            }
            res(true);
        });

    var _registerPayPal = function (makepurchase) {
            ee.indiOn();
            $screen.find('.ewarningtext').html('');

            if (ee.data.purchasecredit.activetab == 1) {
                const activePlan = _.find(planList, (plan) => {
                    return plan.pricingbillingplan === ee.data.account.pricingplan;
                });

                var $termsOfUse = $('#etermsofuse');
                if (!$termsOfUse.is(':checked')) {
                    $('#etermsofusevalid').html(ee.t.erterms);
                    valid = false;
                }

                if (activePlan.typedesc === 'PayAsYouGo') {
                    var selectedamount = parseInt($paymentsdropdown.amountoptions.find(':checked').val());
                    var purchaseData = JSON.stringify({
                        amount: selectedamount,
                        autolevel: ee.tools.getAutoCreditLevel(selectedamount),
                    });

                    var payload = {
                        purchasedata: purchaseData,
                    };

                    if (ee.data.account.pricingplan >= 2) {
                        payload.plan =
                            $screen.paymentchoosetier.find('.tierbox.active .tierbutton').attr('data-pricingplan') || 2;
                    }

                    if ($('#echargerightaway').prop('checked')) {
                        return EE_API.Payment.makePayPalPurchase(payload)
                            .then(function (data) {
                                if (ee.bootedAsChild) {
                                    window.top.location = data;
                                } else {
                                    window.location = data;
                                }
                            })
                            .catch(function (error) {
                                console.error(error);
                                if (error instanceof Error) {
                                    ee.captureStripeException(error);
                                } else {
                                    ee.captureStripeMsg(error);
                                }
                                ee.goTo('#/payments/unsuccesfulpayment');
                            });
                    } else {
                        return EE_API.Payment.registerPayPal(payload)
                            .then(function (data) {
                                if (ee.bootedAsChild) {
                                    window.top.location = data;
                                } else {
                                    window.location = data;
                                }
                            })
                            .catch(function (error) {
                                console.error(error);

                                if (error instanceof Error) {
                                    ee.captureStripeException(error);
                                } else {
                                    ee.captureStripeMsg(error);
                                }
                                ee.goTo('#/payments/unsuccesfulpayment');
                            });
                    }
                } else {
                    var purchasedata = {};
                    purchasedata.plan = $('#epaymentchoosetier_options')
                        .find('.tierbox.active > .tierbutton')
                        .data('pricingplan');
                    purchasedata.maxcontacts = $('#epaymentchoosetier_options')
                        .find('.tierbox.active > .tierbutton')
                        .data('contacts');

                    //if user selected something that is not part of his actual plan - check him out and charge right away
                    if (
                        purchasedata.maxcontacts != ee.data.account.maxcontacts ||
                        purchasedata.plan != ee.data.account.pricingplan ||
                        ee.data.account.istrialbillingplan
                    ) {
                        return EE_API.Payment.Checkout(purchasedata)
                            .then(function (data) {
                                try {
                                    sessionStorage.setItem('checkouttoken', data.checkouttoken);
                                } catch (err) {
                                    console.error(err);
                                    console.error('You need to enable session storage');
                                }
                            })
                            .then(function () {
                                return EE_API.Payment.Register({ provider: 2 });
                            })
                            .then(function (data) {
                                if (ee.bootedAsChild) {
                                    window.top.location = data;
                                } else {
                                    window.location = data;
                                }
                            })
                            .catch(function (err) {
                                console.error(err);
                                if (err instanceof Error) {
                                    ee.captureStripeException(err);
                                } else {
                                    ee.captureStripeMsg(err);
                                }
                            });
                    } else {
                        return EE_API.Payment.Register({ provider: 2 })
                            .then(function (data) {
                                if (ee.bootedAsChild) {
                                    window.top.location = data;
                                } else {
                                    window.location = data;
                                }
                            })
                            .catch(function (err) {
                                console.error(err);
                                if (err instanceof Error) {
                                    ee.captureStripeException(err);
                                } else {
                                    ee.captureStripeMsg(err);
                                }
                            });
                    }
                }
            }
        },
        _purchaseFromAuto = function (params) {
            ee.indiOn();

            var selectedamount = parseInt($paymentsdropdown.amountoptions.find(':checked').val());
            var data = {
                amount: selectedamount,
                autolevel: ee.tools.getAutoCreditLevel(selectedamount),
                plan: params.plan,
            };
            $screen.find('.ewarningtext').html('');

            const { billingdetails } = ee.data.account;
            //CommonInfo
            if (!_.isEmpty(billingdetails.provider) && billingdetails.provider.name === 'PayPal') {
                return EE_API.Payment.makeAutoPaypalPurchase(data)
                    .then(function () {
                        return ee.session.refreshUserData();
                    })
                    .then(function (userData) {
                        ee.data.account.autocreditstatus = 'on';
                        ee.indiOff();
                        ee.goTo('#/payments/autorechargesuccessful');
                    })
                    .catch(function (error) {
                        if (error instanceof Error) {
                            ee.captureStripeException(error);
                        } else {
                            ee.captureStripeMsg(error);
                        }
                        ee.indiOff();
                        ee.goTo('#/payments/unsuccesfulpayment');
                    });
            } else {
                console.error('Payments: billing provider is missing');
            }
        },
        _purchaseValidation = function () {
            //TO DO - validator
            var $pay = $('#epaymentplanselection');
            $paymenttypeselection.find('.error').removeClass('error');
            var valid = true;

            var $termsofuse = $('#etermsofuse');
            if ($termsofuse && !$termsofuse.is(':checked')) {
                $('#etermsofusevalid').html(ee.t.erterms);
                valid = false;
            }
            if ($paymenttypeselection.autopurchasecredit) {
                if (
                    $paymenttypeselection.autopurchasecredit.is(':checked') &&
                    (isNaN(parseInt(purchasecredit.autopurchaselevel.val())) ||
                        parseInt(purchasecredit.autopurchaselevel.val()) < 0)
                ) {
                    $screen.autopurchaselevelvalid.html(ee.t.erauto);
                    valid = false;
                }
            }

            return valid;
        },
        _createMonthsList = function (datausage) {
            var tmp = new Date(ee.data.account.datecreated);
            var date = { y: tmp.getFullYear(), m: tmp.getMonth() + 1 };
            var now = new Date();
            var n = (now.getFullYear() - (date.y + 1)) * 12 + (12 - date.m) + now.getUTCMonth() + 1;
            for (var i = 0; i <= n; i++) {
                datausage.months.push({
                    yearmonth: date.y + '/' + (date.m < 10 ? '0' + date.m : date.m),
                    display: (date.m < 10 ? '0' + date.m : date.m) + '/' + date.y,
                });
                date.m++;
                if (date.m == 13) {
                    date.m = 1;
                    date.y++;
                }
            }
            datausage.from = datausage.months[datausage.months.length - 1].yearmonth + '/01';
            datausage.months.reverse();
        },
        _loadNewUsageData = function (datausage) {
            EE_API.Account.LoadSubscriptionUsage({ from: datausage.from, to: datausage.to })
                .then(function (data) {
                    var data = _formatUsageData(data);
                    ee.data.usage[datausage.from] = data;
                    ee.view.showNewUsage();
                })
                .catch(function (err) {
                    console.error(err);
                    if (err instanceof Error) {
                        ee.captureStripeException(err);
                    } else {
                        ee.captureStripeMsg(err);
                    }
                });
        },
        _redirectToStripe = (sessionId) => {
            if (_stripeCheckoutInProgress) {
                return;
            }
            _stripeCheckoutInProgress = true;

            return stripe.redirectToCheckout({ sessionId }).then((resp) => {
                _stripeCheckoutInProgress = false;
                if (resp.error) {
                    throw resp.error;
                }

                return resp;
            });
        },
        _stripeCheckoutInProgress = false,
        _chargeByStripe = (clientSecret) => {
            if (_stripeCheckoutInProgress) {
                return;
            }
            _stripeCheckoutInProgress = true;

            _initStripe().then(() => {
                return stripe.handleCardPayment(clientSecret).then((resp) => {
                    _stripeCheckoutInProgress = false;
                    if (resp.error) {
                        throw resp.error;
                    }

                    return resp;
                });
            });
        },
        _formatUsageData = function (data) {
            var totalCountsParent = {};
            var totalCountsSub = {};
            $.each(data.parent, function (index, elem) {
                if (elem.date) elem.date = ee.api.formatDate(elem.date, 'yy-mm-dd', false, true);
                for (var key in elem) {
                    var value = this[key];
                    if (key === 'date' || key === 'maxcontacts' || $.isNumeric(value) === false) {
                        continue;
                    }
                    if (!totalCountsParent[key]) {
                        totalCountsParent[key] = value;
                    } else {
                        totalCountsParent[key] += value;
                    }
                }
            });
            $.each(data.subaccounts, function (index, elem) {
                elem.accountname = elem.email.replace(/[^a-zA-Z0-9]/g, '');
                $.each(elem.usage, function (index, elem) {
                    if (elem.date) elem.date = ee.api.formatDate(elem.date, 'yy-mm-dd', false, true);

                    for (var key in elem) {
                        var value = this[key];
                        if (key === 'date' || $.isNumeric(value) === false) {
                            continue;
                        }
                        if (!totalCountsSub[key]) {
                            totalCountsSub[key] = value;
                        } else {
                            totalCountsSub[key] += value;
                        }
                    }
                });
                data.subaccounts[index].totalCountsSub = totalCountsSub;
                totalCountsSub = {};
            });
            data.totalCountsParent = totalCountsParent;
            return data;
        },
        _recountPrice = function () {
            //recount prices only when on ACTIVE tab

            const { activePlan } = ee.data.account;
            var payment = true;
            var amount = null;
            var plancontainer = $('.tierbox.active');
            var plan = plancontainer.find('.tierbutton').data('pricingplan');
            var contacts = plancontainer.find('.tierbutton').data('contacts');
            var formattedcontacts = contacts ? parseInt(contacts).numberFormat() : '';
            var basiccost = plancontainer.find('.contactsvalue').text();
            var percent = ee.data.account.discountpercent ? ee.data.account.discountpercent * 100 : 0;
            var discountfactor = ee.data.account.discountpercent ? 1 - ee.data.account.discountpercent : 1;
            var paymentObject = {
                payment: payment,
                amount: amount,
                plan: plan,
                contacts: contacts,
                percent: percent,
                formattedcontacts: formattedcontacts,
                basiccost: basiccost,
                discountfactor: discountfactor,
                _currentPricingPlan: activePlan.name,
            };

            if (activePlan.typedesc === 'ContactSubscription') {
                //do checkout and up
                if (!ee.data.account.subscriptionbillingdate) {
                    //new accounts always pay 100% of cost
                    paymentObject.amount = basiccost;
                } else if (
                    plan != ee.data.account.pricingplan ||
                    contacts != ee.data.account.maxcontacts ||
                    ee.data.account.istrialbillingplan // APP-1407 we want to be able to see what we will pay for the initial plan if we are on trial
                    || (!ee.data.account.istrialbillingplan && !ee.data.account?.billingdetails?.plan?.isactive && !ee.data.account?.provider)
                ) {
                    //we check prorate for selected plan only
                    return EE_API.Payment.Checkout({ plan: plan, maxcontacts: contacts }).then(function (data) {
                        paymentObject.proRated = true;
                        paymentObject.amount = data.plancost.toFixed(2);
                        paymentObject.hasSupportCost = !!data.dedicatedsupportcost;
                        paymentObject.supportCost = data.dedicatedsupportcost.toFixed(2);
                        paymentObject.monthlySupportCost = data.monthlydedicatedsupportcost;
                        paymentObject.hasDiscount = !!data.discountamount;
                        paymentObject.totalDiscountAmount = data.discountamount.toFixed(2);
                        paymentObject.totalCostAfterDiscount = data.totalcost.toFixed(2);
                        return _rewritePrice(paymentObject);
                    });
                } else {
                    //nothing changed
                    paymentObject.payment = false;
                }
            } else {
                paymentObject.amount = $('#epaymentamountdropdown input').val();
                paymentObject.amount = parseFloat(paymentObject.amount).toFixed(2);

                //APP-1407 if the account is on trial we want to see the payment box not update button
                if (!$('#echargerightaway').prop('checked') && !ee.data.account.istrialbillingplan) {
                    paymentObject.payment = false;
                }
            }

            _rewritePrice(paymentObject);
        },
        _rewritePrice = function (paymentObject) {
            if (ee.data.account.discountpercent) {
                if (moment().diff(ee.data.account.discountexpiry, 'days') <= 0 || !ee.data.account.discountexpiry) {
                    //discount expiry in the future or non-expiring one
                    paymentObject.discounttime = ee.data.account.discountexpiry
                        ? moment(ee.data.account.discountexpiry).format('L')
                        : undefined;
                    paymentObject.totalCostAfterDiscount ??= (
                        paymentObject.amount * paymentObject.discountfactor
                    ).toFixed(2);

                    paymentObject.totalDiscountAmount ??= (
                        paymentObject.amount - paymentObject.totalCostAfterDiscount
                    ).toFixed(2);
                } else {
                    //discount in the past - no discount at all
                    paymentObject.totalCostAfterDiscount ??= parseFloat(paymentObject.amount).toFixed(2);
                }
            } else {
                paymentObject.totalCostAfterDiscount ??= parseFloat(paymentObject.amount).toFixed(2);
            }
            var summary = html.get('PaymentSummary', {
                payment: paymentObject,
                acc: ee.data.account,
            });
            $('#epaymentsummary').empty().append(summary);
            $('#epaymentaction').text(paymentObject.payment ? ee.t.pay : ee.t.update);
            $('#eamountselected').html(paymentObject.payment ? ' $' + paymentObject.totalCostAfterDiscount : '');
            return;
        },
        _getActiveTab = () => {
            if (ee.data.account?.billingdetails?.provider?.name === 'PayPal') {
                return 1;
            }
            return 0;
        },
        _TierButtonChange = function (clickedplan) {
            $screen.paymentchoosetier.find('.tierbox').removeClass('active');
            clickedplan.parent().addClass('active');

            const planDesc = ee.data.account.activePlan.pricingbillingplandesc;
            const planDailyCost = ee.data.account.activePlan.dailycost;

            if (
                !(
                    planDesc.endsWith('Unlimited') ||
                    planDesc.endsWith('UnlimitedPro') ||
                    planDesc.endsWith('PayPerEmail') ||
                    planDesc.endsWith('PayPerEmailPro')
                ) &&
                planDailyCost > 0
            ) {
                const tierInfo = clickedplan.parent().find('.tier_info');
                if (tierInfo.length > 0) {
                    const text = `$${planDailyCost.toFixed(2)}/day`;
                    const dailyCostElem = $('<p></p>').addClass('tier_info').text(text);

                    tierInfo[0].after(dailyCostElem[0]);
                }
            }

            //add Active to clicked element

            var $this = $(this),
                $plan = clickedplan.data('pricingplan'),
                $minimum = clickedplan.data('minimum');

            //Code below is unused anywhere and also it is defined in global namespace (missing var)
            //$tierminimum = $this.attr('data-tier');

            $('.tierbutton').each(function () {
                var checkedplan = $(this).data('pricingplan');
                //check if plan is part of the same family and show "upgrade" or "select plan" accordingly
                var buttontext;
                if (ee.data.account.istrialbillingplan || $plan > checkedplan) {
                    buttontext = ee.t.selectplan;
                } else {
                    buttontext = ee.t.upgrade;
                }
                $(this).removeClass('disabled').attr('disabled', false).text(buttontext);
            });

            clickedplan.addClass('disabled').attr('disabled', true).text(ee.t.currentplan);
        },
        _paymentProvider = ['None', 'CreditCard', 'PayPal'],
        _paymentStatus = ['Off', 'On', 'InProgress'],
        _paymentLogType = ['Approved', 'Declined', 'Error'],
        _failedErrorMsg = {
            CreditCard:
                'There was a problem processing your most recent payment. There could be various reasons for this including insufficient funds or an invalid expiry date. Please see the specific details below for more information on your specific issue.  If you are unable to resolve the issue, please contact your credit card company or bank for more details.',
            PayPal: 'There was a problem processing your most recent PayPal payment. There could be various reasons for this including insufficient funds, an invalid credit card or payment limitations of PayPal. Please see the specific details below for more information on your specific issue.  If you are unable to resolve the issue, please contact PayPal for more details.  You may also try using a credit card instead of PayPal to complete your transaction.',
        },
        that = {
            show: function () {
                if (ee.data.account.issub || !ee.session.can.ViewBilling) return ee.goTo('#/account');

                switch (ee.Router.action()) {
                    case 'planregistrationsuccesful':
                    case 'autorechargesuccessful':
                    case 'paymentsuccessful':
                        that.showFinishedTransaction(true, false);
                        return;
                    case 'unsuccesfulpayment':
                        that.showFailedTransaction();
                        return;
                    case 'planupdatesuccesful':
                        that.showFinishedTransaction(true, true);
                        return;
                    case 'paymentupdatesuccesful':
                        that.showFinishedTransaction(false, false, true);
                        return;
                    case 'updatesuccesful':
                        that.showFinishedTransaction(false, true);
                        return;
                    case 'autoincreaseupdatesuccesful':
                        that.showFinishedTransaction(false, false, false);
                        return;
                    case 'history':
                        that.showPaymentHistory();
                        return;
                    case 'usage':
                        if (ee.data.account.pricingplan == 7 || ee.data.account.pricingplan == 8) {
                            that.showNewUsage();
                        } else {
                            that.showUsage();
                        }
                        return;
                    default:
                        if (!ee.session.can.ModifyBilling) {
                            return ee.goTo('#/payments/history');
                        }
                        if (!ee.data.purchasecredit) {
                            if (typeof ee.data.purchasecredit == 'undefined')
                                ee.data.purchasecredit = { activetab: {} };
                            ee.data.purchasecredit.activetab = _getActiveTab();
                        }
                        that.showPurchaseCredit();
                }
            },

            showPurchaseCredit: function () {
                const account = _.cloneDeep(ee.data.account);
                const { activePlan } = account;

                const mapTiers = (tiers) => {
                    const mappedTiers = _.map(tiers, (contactscost, contactsnumber) => {
                        contactsnumber = parseInt(contactsnumber);

                        return {
                            contactscost,
                            contactsnumber,
                            contactsnumberformatted: contactsnumber.numberFormat(),
                            procontactscost: contactscost,
                        };
                    });

                    return _.filter(mappedTiers, (tier) => {
                        return tier.contactsnumber >= account.maxcontacts;
                    });
                };

                const parsePairPlans = (billingplans) => {
                    let result;

                    result = {
                        unlimitedStandard: _.find(billingplans, (plan) =>
                            plan.pricingbillingplandesc.endsWith('Unlimited')
                        ),
                        unlimitedPro: _.find(billingplans, (plan) =>
                            plan.pricingbillingplandesc.endsWith('UnlimitedPro')
                        ),
                        apiStandard: _.find(billingplans, (plan) =>
                            plan.pricingbillingplandesc.endsWith('PayPerEmail')
                        ),
                        apiPro: _.find(billingplans, (plan) => plan.pricingbillingplandesc.endsWith('PayPerEmailPro')),
                    };

                    if (result.unlimitedStandard && result.unlimitedPro) {
                        result.unlimitedStandard.tiers = mapTiers(_.cloneDeep(result.unlimitedStandard.tiers));
                        result.unlimitedPro.tiers = mapTiers(_.cloneDeep(result.unlimitedPro.tiers));
                    }

                    if (ee.data.account.billingdetails.plan.maxcontacts < 1000000) {
                        _.forEach(result, (plan) => {
                            if (_.isEmpty(plan?.tiers)) {
                                return;
                            }

                            plan.tiers = _.filter(plan.tiers, (tier) => tier.contactsnumber <= 1000000);
                        });
                    }

                    return result;
                };

                let plans = [];

                ee.indiOn();

                const calls = [
                    EE_API.Payment.loadRegisteredPaymentProvider(),
                    EE_API.Payment.getBillingPlans(),
                    EE_API.Payment.paymentProviderStatus(),
                ];

                if (!window.Stripe) {
                    calls.push(ee.tools.loadScript('https://js.stripe.com/v3/'));
                }

                return Promise.all(calls)
                    .then((resp) => {
                        const providerData = resp[0];
                        const billingPlans = resp[1];
                        const providerStatus = resp[2];

                        planList = _.cloneDeep(resp[1]);

                        const payments = providerData;

                        plans = parsePairPlans(billingPlans);

                        ee.indiOff();

                        providerStatus.paymentprovider = _paymentProvider[providerStatus.paymentprovider];
                        providerStatus.status = _paymentStatus[providerStatus.status];
                        providerStatus.type = _paymentLogType[providerStatus.type];

                        if (!providerData.paymentprovider) {
                            ee.data.account.payments = {};
                        }

                        if (payments.brand) {
                            //payments.brand = payments.brand.toLowerCase();
                            payments.details = '•••• •••• •••• ' + payments.details;
                        } else payments.brand = 'Paypal';
                        if (payments.expirationdate) {
                            var expiration = new Date(payments.expirationdate);
                            payments.exp =
                                expiration.getMonth() + 1 + '/' + expiration.getFullYear().toString().substr(2, 2);
                        }

                        ee.data.account.payments = payments;
                        ee.logEvent('Payments screen loaded');

                        const rechargeValues = [
                            { value: 5, min: 1 },
                            { value: 10, min: 2 },
                            { value: 20, min: 5 },
                            { value: 50, min: 10 },
                            { value: 100, min: 20 },
                            { value: 150, min: 30, plan: 3, hide: account.pricing2017 },
                            { value: 200, min: 50 },
                            { value: 500, min: 100 },
                            { value: 600, min: 150, plan: 4, hide: account.pricing2017 },
                            { value: 1000, min: 250 },
                            { value: 1500, min: 300 },
                            { value: 100, min: 20, minimum: 3, id: 'eamountbasic', hide: account.pricing2017 },
                            { value: 500, min: 100, minimum: 4, id: 'eamountprofessional' },
                            { value: 1000, min: 250, id: 'eamounthighvolume' },
                            { value: 2500, min: 750, id: 'eamountagency' },
                        ];

                        ee.frame.content.empty();

                        $paymentsdropdown = html.get('RechargeAmountDropdown', {
                            account: ee.data.account,
                            rechargelist: rechargeValues,
                        });
                        $paymenttypeselection = html.get('PaymentTypeSelection', {
                            account: ee.data.account,
                            hasBillingDetials: !_.isEmpty(ee.data.account.billingdetails.provider),
                        });

                        const { apiPro, apiStandard } = plans;
                        const apiProPrice = apiPro ? (apiPro.priceperemail * 1000).toFixed(2) : '0.9';
                        const apiStandardPrice = apiStandard ? (apiStandard.priceperemail * 1000).toFixed(2) : '0.9';
                        const dailyCostApi = apiStandard ? apiStandard.dailycost.toFixed(2) : '0.40';
                        const dailyCostApiPro = apiPro ? apiPro.dailycost.toFixed(2) : '1';

                        plans.unlimitedPro.name = plans.unlimitedPro.name.replace(' Pro','')

                        $paymenttier = html.get('PaymentTierSelection', {
                            account: ee.data.account,
                            planPricing2017: {
                                premium: ee.data?.account?.activePlan?.pricingbillingplandesc === 'Pricing2017Tier2',
                                advanced: ee.data?.account?.activePlan?.pricingbillingplandesc === 'Pricing2017Tier1',
                            },
                            unlimitedplans: activePlan.typedesc === 'ContactSubscription',
                            apiProPrice,
                            apiStandardPrice,
                            dailyCostApi,
                            dailyCostApiPro,
                            plans,
                            unlimitedPROContactsVal:
                                ee.data.account.maxcontacts > 10000 ? ee.data.account.maxcontacts : 10000,
                            grandfathredPlan: activePlan.pricingbillingplan < 24,
                        });
                        var $deliverytools = html.get('ContactDeliveryTools', {
                            pricing2015: ee.data.account.pricingplan == 1,
                            pricing2017: ee.data.account.pricing2017,
                            contactfeatures: ee.data.account.enablecontactfeatures,
                        });

                        $screen = html.get('PurchaseCredit', {
                            bootedAsChild: ee.bootedAsChild,
                            account: ee.data.account,
                            screentitle: ee.t.billing,
                            pricingplan: ee.data.account.pricingplan,
                            pricing2015: ee.data.account.pricingplan == 1,
                            pricing2017: ee.data.account.pricing2017,
                            unlimitedplans: activePlan.typedesc === 'ContactSubscription',
                            contactfeatures: ee.data.account.enablecontactfeatures,
                            autocreditstatus: ee.data.account.autocreditstatus,
                            autocreditenabled:
                                ee.data.account.autocreditlevel > 0 || ee.data.account.autocreditstatus == 'on',
                        });

                        if (activePlan.typedesc === 'ContactSubscription') {
                            $screen.paymentamount.hide(); // hide yellow container for dropdown with money/autolevel amounts
                        }

                        if (ee.data.account.autocreditamount != 5) {
                            $paymentsdropdown.amountoptions.find('input[value="5"]').closest('li').remove();
                        }

                        $paymentsdropdown.appendTo($screen.paymentamount);
                        $screen.$paymentsdropdown = $paymentsdropdown;
                        $paymenttypeselection.appendTo($screen.paymentstypeselection);
                        $screen.$paymenttypeselection = $paymenttypeselection;
                        $paymenttier.prependTo($screen.paymentchoosetier);
                        $screen.$paymenttier = $paymenttier;
                        $deliverytools.appendTo($screen.deliverytoolscontainer);

                        const { autocreditenabled } = ee.data.account;
                        const { hasanypayments } = ee.data.account.billingdetails.plan;

                        const pricingPlanName =
                            account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';

                        if (ee.session.can.ModifyBilling) {
                            var $paymentlegalsection = html.get('PaymentLegalAndConfirm', {
                                autocreditenabled,
                                account: ee.data.account,
                                // hidepayrightnow: activePlan.typedesc === 'ContactSubscription' || !hasanypayments,
                                hidepayrightnow: activePlan.typedesc === 'ContactSubscription' || !hasanypayments,
                            });
                            $paymentlegalsection.appendTo($screen.paymentlegalandsend);
                        }
                        if (activePlan.typedesc === 'ContactSubscription' && !ee.data.account.istrialbillingplan) {
                            $screen.paymentscreen1.find('#eunlimitedplans').show().addClass('active in');
                            $screen.paymentscreen1.find('#epayasyougoplans').hide();
                            $screen.paymentamount.hide();
                        } else if (activePlan.typedesc === 'PayAsYouGo' && !ee.data.account.istrialbillingplan) {
                            $screen.paymentscreen1.find('#epayasyougoplans').show().addClass('active in');
                            $screen.paymentscreen1.find('#eunlimitedplans').hide();
                            $screen.paymentamount.show();
                        } else if (ee.data.account.istrialbillingplan) {
                            $screen.paymentscreen1.find('#eunlimitedplans').show();
                            $screen.paymentscreen1.find('#epayasyougoplans').show();
                        }

                        if ($screen.find('#standardProPricing').length) {
                            const unlimited = html.get('PlanDropdown', { options: plans.unlimitedStandard.tiers });
                            const unlimitedPro = html.get('PlanDropdown', {
                                ispro: true,
                                options: plans.unlimitedPro.tiers,
                            });

                            activePlan.tiers = mapTiers(_.cloneDeep(activePlan.tiers));
                            const firstStd = plans.unlimitedStandard.tiers[0];
                            const firstUnlimited = plans.unlimitedPro.tiers[0];

                            //hide high-tier plans for accounts with

                            //select first options on each one, so prices will match
                            $screen.find('#eunlimiteddropdown').empty().append(unlimited);
                            $screen.find('#eunlimitedprodropdown').empty().append(unlimitedPro);
                            $screen
                                .find('#eunlimitedplantierbox .contactnumberbutton')
                                .text(firstStd.contactsnumber.numberFormat() + ' Contacts');
                            $screen.find('#eunlimitedplantierbox .contactsvalue').text(firstStd.contactscost);
                            $screen
                                .find('#eunlimitedproplantierbox .contactnumberbutton')
                                .text(firstUnlimited.contactsnumber.numberFormat() + ' Contacts');
                            $screen
                                .find('#eunlimitedproplantierbox .contactsvalue')
                                .text(firstUnlimited.procontactscost);
                            $screen.on('click', '.contactnumber', function () {
                                //add it to info about payment. By default it starts at 2500

                                var contact = $(this);
                                $(this)
                                    .closest('ul')
                                    .siblings('.contactnumberbutton')
                                    .text(parseInt(contact.val()).numberFormat() + ' Contacts');
                                $(this).closest('.tieroption').find('.contactsvalue').text(contact.data('cost'));
                                $(this)
                                    .closest('.tieroption')
                                    .find('.tierbutton')
                                    .data('contacts', contact.val())
                                    .attr('data-contacts', contact.val());
                                try {
                                    _recountPrice();
                                } catch (e) {
                                    console.error(e);
                                }
                            });

                            $screen.on('click', '.tierbox.active .autoincreasemaxcontacts_check', (e) => {
                                const enable = e.target.checked;
                                e.target.checked = !enable;

                                const secondCheckbox = $screen
                                    .find('.autoincreasemaxcontacts_check')
                                    .filter((i, elem) => {
                                        return elem.id !== e.target.id;
                                    });
                                secondCheckbox[0].checked = !enable;

                                html.modal.confirm(
                                    ee.t.changeautoincrease,
                                    ee.t.changeautoincreaseheader,
                                    () => {
                                        EE_API.Account.UpdateAutoIncrementMaxContacts({ enable: enable })
                                            .then(() => {
                                                ee.data.account.autoincreasemaxcontacts = enable;
                                                e.target.checked = enable;
                                                secondCheckbox[0].checked = enable;
                                                html.widget.Toast.info(ee.t.autoincreasechanged, '', {
                                                    fadeIn: 250,
                                                    fadeOut: 250,
                                                    timeOut: 1000,
                                                });
                                            })
                                            .catch((e) => {
                                                console.error(e);
                                                e.target.checked = !enable;
                                                secondCheckbox[0].checked = !enable;
                                            });
                                    },
                                    false,
                                    { confirmTitle: ee.t.confirm, confirmCSS: 'btn_primary btn_lg', size: 'emodal-md' }
                                );
                            });

                            $screen.on('click', '.planselectiontab', function () {
                                var that = $(this);
                                that.hasClass('showRecharge')
                                    ? $screen.paymentamount.show()
                                    : $screen.paymentamount.hide();
                            });

                            if (activePlan.typedesc === 'ContactSubscription') {
                                $paymentlegalsection.find('.chargerightnowlabel').hide();
                                //$paymentlegalsection.enablerechargeinfo.hide();
                                $('.tierbox.active > .tierbutton').trigger('click');
                            }
                        }
                        $screen.appendTo(ee.frame.content);

                        if (!_.isEmpty(ee.data.account.billingdetails.provider)) {
                            ee.api.loadProviderData();
                        }

                        // go back on payments to new app screens
                        $('.historyGoBack').on('click', function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            if (window.top.history.length) {
                                const event = new CustomEvent('navigateBack');
                                return window?.parent?.document?.dispatchEvent?.(event);
                            }
                            ee.goTo('#/billing?goBack=/billing');
                        });

                        if (
                            _.some(['Declined', 'Error'], providerStatus.status) &&
                            _.isString(providerStatus.errormessage)
                        ) {
                            var msgContent = _failedErrorMsg[providerStatus.paymentprovider];
                            var $paymentError = $('#epaymanterror');
                            $paymentError.find('#epaymanterror_message').text(providerStatus.errormessage);
                            if (msgContent) {
                                $paymentError.find('#epaymanterror_content').text(msgContent);
                            }

                            $paymentError.removeClass('hidden');
                        }

                        if ($screen.deliverytoolscontainer) {
                            $screen.deliverytoolscontainer.on('change', '#edeliverytools', function () {
                                var that = this;
                                if (that.checked) {
                                    EE_API.Account.ActivateContactFeature().then(function (data) {
                                        $deliverytools.show();
                                        ee.data.account.enablecontactfeatures = true;
                                    });
                                } else {
                                    EE_API.Account.DisableContactFeature().then(function (data) {
                                        $deliverytools.hide();
                                        ee.data.account.enablecontactfeatures = false;
                                    });
                                }
                            });
                        }
                        that.paymentsourceInteractions($paymenttypeselection);
                        //select purchasetype
                        $screen.paymentoptions.on('click', 'a', function () {
                            var $this = $(this);
                            ee.data.purchasecredit.activetab = $this.data('source');
                            $screen.paymentstypeselection
                                .removeClass('eccarrow epparrow')
                                .addClass($this.attr('id') + 'arrow');
                        });
                        if (ee.data.account.paymentprovider > 0 && ee.data.account.autocreditenabled) {
                            $screen.paymentoptions.find('a').parent().hide();
                            $screen.paymentoptions
                                .find('#e' + ee.data.account.paymenttype)
                                .trigger('click')
                                .parent()
                                .addClass('col-sm-push-3')
                                .show();
                            $screen.paymentstypeselection.addClass('emiddlearrow');
                        } else {
                            $screen.paymentoptions.find('#ecc').trigger('click');
                        }

                        //events
                        /*NOTE: Find difrent place for that function... mayby closure (if $screen is available)? */
                        if ($screen.paymentchoosetier) {
                            var actuallyselectedplan = ee.data.account.pricingplan;
                            $screen.paymentchoosetier.on('click', '.tierbutton', function () {
                                ee.logEvent('Payments - change pricing plan.');
                                var clickedplan = $(this);
                                if (
                                    ee.data.account.istrialbillingplan &&
                                    $(this).data('pricingplan') != actuallyselectedplan
                                ) {
                                    actuallyselectedplan = $(this).data('pricingplan');
                                    ee.indiOn();

                                    EE_API.Payment.setPlan({ plan: actuallyselectedplan })
                                        .then(function () {
                                            return ee.session.refreshUserData();
                                        })
                                        .then(function () {
                                            _TierButtonChange(clickedplan);
                                            ee.data.account.pricingplan = actuallyselectedplan;

                                            try {
                                                _recountPrice();
                                            } catch (e) {
                                                console.error(e);
                                            }
                                            // Reload whole window as the legacy mode is loaded in iframe and we need to update plan data on the new app

                                            if (ee.parameters.legacyMode) {
                                                return window.top?.location?.reload?.();
                                            }

                                            function checkPrivileges(planName) {
                                                switch (planName) {
                                                    case 'Unlimited Pro':
                                                        ee.session.can.ViewSubAccounts = true;
                                                        ee.session.can.ViewAutomations = true;
                                                        ee.session.can.ViewCampaigns = true;
                                                        break;
                                                    case 'Email API Pro':
                                                        ee.session.can.ViewSubAccounts = true;
                                                        ee.session.can.ViewAutomations = false;
                                                        ee.session.can.ViewCampaigns = false;
                                                        break;
                                                    case 'Unlimited':
                                                        ee.session.can.ViewSubAccounts = false;
                                                        ee.session.can.ViewAutomations = false;
                                                        ee.session.can.ViewCampaigns = true;
                                                        break;
                                                    default:
                                                        ee.session.can.ViewAutomations = false;
                                                        ee.session.can.ViewCampaigns = false;
                                                        ee.session.can.ViewSubAccounts = false;
                                                }
                                                ee.indiOff();
                                                return html.frame.refreshMenus();
                                            }
                                            checkPrivileges(ee.data.account.activePlan.name);
                                        })
                                        .catch(function (error) {
                                            ee.indiOff();
                                            console.error(error);
                                        });
                                } else {
                                    _TierButtonChange(clickedplan);
                                    try {
                                        _recountPrice();
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }

                                //add Active to clicked element
                                var $this = $(this),
                                    $plan = $this.data('pricingplan'),
                                    $minimum = $this.data('minimum');

                                //Code below is unused anywhere and also it is defined in global namespace (missing var)
                                //$tierminimum = $this.attr('data-tier');

                                //disable options in dropdown payment that are too small for selected Tier

                                if ($plan == 3 || $plan == 4) {
                                    $paymentsdropdown.amountoptions.find('li').show();
                                    var selected = false;
                                    $paymentsdropdown.amountoptions.find('li input').each(function () {
                                        if ($(this).val() < $minimum) {
                                            $(this).closest('li').hide();
                                        } else {
                                            if (!selected) {
                                                selected = $(this);
                                            }
                                        }
                                    });

                                    $(selected).click().closest('li').addClass('selected');
                                    $paymentsdropdown.amountoptions.find('li.tiers').hide();
                                } else if ($plan == 2) {
                                    $paymentsdropdown.paymentamountdropdown.removeAttr('disabled');
                                    $paymentsdropdown.amountoptions.find('li').show();
                                    $paymentsdropdown.amountoptions.find('li.tiers').hide();
                                    if (ee.data.account.autocreditamount > 0) {
                                        $paymentsdropdown.amountoptions
                                            .find('[value=' + ee.data.account.autocreditamount + ']')
                                            .click();
                                    } else {
                                        $paymentsdropdown.amountoptions.find('[value=100]').click();
                                    }
                                }
                            });
                        }

                        //TO DO: remove default pricing, hightiers logic, clean it
                        //prepare defaults
                        $paymentsdropdown.amountoptions.on('click', 'input', function () {
                            $paymentsdropdown.amountoptions.find('li').removeClass('selected');
                            var $this = $(this);
                            $this.closest('li').addClass('selected');
                            $paymentsdropdown.paymentamountdropdown.html(
                                $this.parent().html() +
                                    " <i style='position: absolute;right: 10px;top: 15px;' class='fa fa-lg fa-caret-down'></i>"
                            );
                            try {
                                _recountPrice();
                            } catch (e) {
                                console.error(e);
                            }
                        });
                        if (_.includes(['Basic', 'Advanced', 'Premium'], pricingPlanName)) {
                            $('.tieroption').hide();
                            $(
                                $screen.paymentchoosetier.find(
                                    '[data-pricingplan="' + ee.data.account.pricingplan + '"]'
                                )
                            )
                                .click()
                                .closest('.tieroption')
                                .show();
                        }

                        //prepare default screen for both paying and not-paying, but promising customers
                        if (
                            ee.data.account.pricingplan == 2 &&
                            ee.data.account.defaultpricingplan != null /*&& !ee.data.account.autocreditenabled*/
                        ) {
                            $screen.paymentchoosetier
                                .find('[data-pricingplan=' + ee.data.account.defaultpricingplan + ']')
                                .trigger('click');
                            //$screen.paymentchoosetier.find('[data-pricingplan=' + ee.data.account.defaultpricingplan + ']').parent().addClass('active');
                        } else if (ee.data.account.pricingplan >= 5) {
                            var $tier = $screen.paymentchoosetier.find(
                                    '[data-pricingplan=' + ee.data.account.pricingplan + ']'
                                ),
                                $tierpricingplan = $tier.attr('data-pricingplan');
                            //hide lower tiers
                            for (var i = 0; i < $screen.paymentchoosetier.find('.tierbutton').length; i++) {
                                var $this = $($screen.paymentchoosetier.find('.tierbutton')[i]);

                                if (
                                    ee.data.account.standardProPricing &&
                                    !ee.data.account.istrialbillingplan &&
                                    ee.data.account.billingdetails.activeplan.pricingbillingplandesc.endsWith('Pro') &&
                                    parseInt($this.attr('data-pricingplan')) !== activePlan.pricingbillingplan
                                ) {
                                    $this.closest('.tieroption').addClass('hide');
                                } else if (
                                    $this.attr('data-pricingplan') < ee.data.account.pricingplan &&
                                    !ee.data.account.istrialbillingplan
                                ) {
                                    $this.closest('.tieroption').addClass('hide');
                                }
                            }

                            $screen.paymentchoosetier
                                .find('[data-pricingplan=' + ee.data.account.pricingplan + ']')
                                .trigger('click');
                        } else if (ee.data.account.pricingplan == null) {
                            //testing only
                            //$screen.paymentchoosetier.find('[data-pricingplan=2]').click();
                        }
                        /* PREPARE ENABLE AUTOCREDIT OPTIONS */
                        if (activePlan.typedesc !== 'ContactSubscription' || ee.data.account.istrialbillingplan) {
                            if (ee.data.account.autocreditamount) {
                                $screen.paymentamount.children('label').text(ee.t.autorechargeplan);
                                $paymentsdropdown.amountoptions
                                    .find('[value=' + ee.data.account.autocreditamount + ']')
                                    .click();
                            } else if (
                                ee.data.account.defaultpricingplan == 3 ||
                                ee.data.account.defaultpricingplan == 4
                            ) {
                                $paymentsdropdown.amountoptions
                                    .find('[data-minimum=' + ee.data.account.defaultpricingplan + ']')
                                    .click();
                            } else $paymentsdropdown.amountoptions.find('[value=100]').click();
                        }

                        $screen.paycountry = html.countryList($screen.paymentstypeselection.find('#epaycountry'));
                        $screen.paycountry.on('click', 'option', function () {});
                        $screen.paycountry
                            .find('[value="' + ee.data.account.countryid + '"]')
                            .prop('selected', 'selected');

                        //Check terms
                        const $termsOfUse = $('#etermsofuse');
                        $termsOfUse.on('click', function () {
                            $('#epaybutton').prop('disabled', $termsOfUse.is(':checked') ? '' : 'disabled');
                        });

                        //Valid Payment
                        //selecting "pay right now checkbox"
                        $('#echargerightaway').on('click', function () {
                            var that = $(this);
                            if (that.prop('checked')) {
                                $('#epaymentaction').html(ee.t.pay);
                                if (ee.data.account.pricingplan > 6) {
                                    var amount = parseInt($('totalPaymentCost').html());
                                } else {
                                    var amount = parseInt($paymentsdropdown.amountoptions.find(':checked').val());
                                    if (ee.data.account.discountpercent)
                                        amount = (amount * (1 - ee.data.account.discountpercent / 100)).toFixed(2);
                                }
                                try {
                                    _recountPrice();
                                } catch (e) {
                                    console.error(e);
                                }
                                $('.paypalpayorupdate').html(ee.t.finishpaymentwithpaypal);
                            } else {
                                $('#epaymentaction').html(ee.t.submit);
                                $('#eamountselected').html('');
                                $('.paypalpayorupdate').html(ee.t.finishupdatewithpaypal);
                                //$('#eenablerechargeinfo').html(ee.t.enableautorecharge);
                            }
                        });

                        $('#epaybutton').on('click', function () {
                            ee.logEvent('Payments - proceed with payment.');
                            $('#epaymanterror').addClass('hidden');
                            $('#epaymentnodata').addClass('hidden');

                            if (
                                ee.data.purchasecredit.activetab == 1 ||
                                _purchaseValidation() || //new payment
                                !ee.data.account.istrialbillingplan ||
                                ee.data.account.autocreditenabled
                            ) {
                                //existing payment - old account
                                that._mainPurchaseProcess();
                            }
                        });

                        //set basic data on screen
                        try {
                            _recountPrice();
                        } catch (e) {
                            console.error(e);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },

            _logError: (err) => {
                if (err instanceof Error) {
                    ee.captureStripeException(err);
                } else {
                    ee.captureStripeMsg(err);
                }
                ee.indiOff();
                return console.error(err);
            },

            _getPaymentType: (type) => {
                switch (type) {
                    case 'SetupAndCharge':
                        return 0;
                    case 'Charge':
                        return 2;
                    case 'Setup':
                    default:
                        return 1;
                }
            },
            // Check if any pricing tier panel is selected. If no then leave active plan from account.
            _fetchSelectedPricingPlan() {
                const { activePlan } = ee.data.account;

                let PlanFromPanelTier = null;

                if (activePlan.typedesc === 'PayAsYouGo') {
                    planFromPanelTier = $('#epaymentchoosetier_options')
                        .find('.tierbox.active > .tierbutton')
                        .data('pricingplan');
                }

                if (activePlan.typedesc === 'ContactSubscription') {
                    planFromPanelTier = $('#epaymentchoosetier_options')
                        .find('.tierbox.active > .tierbutton')
                        .data('pricingplan');
                }

                return planFromPanelTier || activePlan.pricingbillingplan;
            },

            _getTransactionType: () => {
                const $chargerightaway = $('#echargerightaway');

                const { billingdetails, activePlan } = ee.data.account;
                const { provider, nextbillingdate } = billingdetails;
                const { planName } = billingdetails.plan;

                if (activePlan.typedesc === 'ContactSubscription') {
                    if (!provider) {
                        const selectedPlan = $('#epaymentchoosetier')
                            .find('.tierbox.active .tierbutton')
                            .attr('data-pricingplan');

                        if (
                            parseInt(selectedPlan) === activePlan.pricingbillingplan &&
                            billingdetails.plan.nextbillingdate
                        ) {
                            return 'Setup';
                        }

                        return 'SetupAndCharge';
                    }

                    return 'Charge';
                }

                //this condition is here to explicite show which plan has which 'strategy' of handling payments
                if (activePlan.typedesc === 'PayAsYouGo') {
                    if (!$chargerightaway.is(':visible') && !provider) {
                        return 'SetupAndCharge';
                    }

                    if (
                        (!provider && !$chargerightaway.prop('checked')) ||
                        (provider && !$chargerightaway.prop('checked'))
                    ) {
                        return 'Setup';
                    }
                    if (!provider && $chargerightaway.prop('checked')) {
                        return 'SetupAndCharge';
                    }
                    if (provider && $chargerightaway.prop('checked')) {
                        return 'Charge';
                    }
                }

                return 'Setup';
            },

            _getPaymentProvider: () => {
                const { billingdetails } = ee.data.account;

                if (_.isEmpty(billingdetails.provider)) {
                    return ee.data.purchasecredit.activetab ? 'PayPal' : 'Stripe';
                }

                return billingdetails.provider.name;
            },

            _mainPurchaseProcess: function () {
                const { autocreditenabled, autocreditstatus, pricingPlanName, istrialbillingplan, activePlan } =
                    ee.data.account;
                const accountMaxContacts = ee.data.account.maxcontacts;
                const accountPricingPlan = ee.data.account.pricingplan;

                const amount = $paymentsdropdown.amountoptions.find(':checked').val();
                const maxContacts = $('#epaymentchoosetier_options')
                    .find('.tierbox.active > .tierbutton')
                    .attr('data-contacts');
                const plan = that._fetchSelectedPricingPlan(pricingPlanName);
                const planType = activePlan.typedesc;

                const autoCreditLevel = !_.isNil(amount) ? ee.tools.getAutoCreditLevel(parseInt(amount)) : null;
                const transactionType = that._getTransactionType();
                const paymentProvider = that._getPaymentProvider();

                const rawParams = {
                    amount,
                    maxContacts,
                    plan,
                    level: autoCreditLevel,
                };

                let stripeParams;

                if (planType === 'PayAsYouGo') {
                    stripeParams = _.pick(rawParams, ['amount', 'level', 'plan']);
                } else if (planType === 'ContactSubscription') {
                    stripeParams = _.pick(rawParams, ['maxContacts', 'plan']);
                }

                if (autocreditenabled && autocreditstatus === 'off') {
                    ee.indiOn();
                    return EE_API.Payment.enableSuspendedPayment()
                        .then(() => {
                            return ee.session.refreshUserData();
                        })
                        .then(() => {
                            ee.indiOff();
                        })
                        .catch(_logError);
                } else if (autocreditstatus === 'off' && !autocreditenabled) {
                    if (paymentProvider === 'Stripe') {
                        return that.paymentWithStripe(transactionType, stripeParams);
                    }

                    return _registerPayPal(transactionType === 'SetupAndCharge');
                } else if (autocreditstatus === 'on' || autocreditstatus === 'inprogress') {
                    let purchasedata = {};

                    if (planType === 'PayAsYouGo') {
                        purchasedata = {
                            autoCreditAmount: amount,
                            autoCreditLevel,
                            plan,
                        };
                    }


                    if (planType === 'ContactSubscription') {
                        ee.indiOn();
                        purchasedata = { plan, maxContacts };

                        //if user selected something that is not part of his actual plan - check him out and charge right away
                        if (parseInt(maxContacts) !== accountMaxContacts || plan !== accountPricingPlan) {
                            $('#epaybutton').attr('disabled', true);

                            if (paymentProvider === 'Stripe') {
                                return that.paymentWithStripe('Charge', stripeParams);
                            }

                            return EE_API.Payment.Checkout(purchasedata)
                                .then((data) => {
                                    return EE_API.Payment.Charge({ checkoutToken: data.checkouttoken });
                                })
                                .then(() => {
                                    ee.indiOff();
                                    if (istrialbillingplan) {
                                        ee.goTo('#/payments/planregistrationsuccesful');
                                    } else {
                                        ee.goTo('#/payments/planupdatesuccesful');
                                    }
                                })
                                .catch((error) => {
                                    if (error instanceof Error) {
                                        ee.captureStripeException(error);
                                    } else {
                                        ee.captureStripeMsg(error);
                                    }
                                    ee.indiOff();
                                    ee.goTo('#/payments/unsuccesfulpayment');
                                });
                        } else if (!autocreditenabled && paymentProvider === 'Stripe') {
                            //card change
                            that.paymentWithStripe(transactionType, stripeParams);
                        } else if (!autocreditenabled && paymentProvider === 'PayPal') {
                            //paypal card register
                            return EE_API.Payment.Register({ provider: 2 }).then((data) => {
                                if (ee.bootedAsChild) {
                                    window.top.location = data;
                                } else {
                                    window.location = data;
                                }
                            });
                        } else {

                            //show them update without registration

                            if (paymentProvider === 'Stripe') {
                                return that.paymentWithStripe(transactionType, stripeParams);
                            } else if (paymentProvider === 'PayPal') {
                                ee.indiOn();

                                return EE_API.Payment.Checkout(purchasedata)
                                .then((data) => {
                                    return EE_API.Payment.Charge({ checkoutToken: data.checkouttoken });
                                })
                                .then(() => {
                                    ee.indiOff();
                                    if (istrialbillingplan) {
                                        ee.goTo('#/payments/planregistrationsuccesful');
                                    } else {
                                        ee.goTo('#/payments/planupdatesuccesful');
                                    }
                                })
                                .catch((error) => {
                                    if (error instanceof Error) {
                                        ee.captureStripeException(error);
                                    } else {
                                        ee.captureStripeMsg(error);
                                    }
                                    ee.indiOff();
                                    ee.goTo('#/payments/unsuccesfulpayment');
                                });
                            }

                            ee.indiOff();
                            $('#epaymentnodata').removeClass('hidden');
                            ee.goTo('#/payments/autoincreaseupdatesuccesful');
                        }
                    } else {
                        if ($('#echargerightaway').prop('checked')) {
                            //payment
                            if (paymentProvider === 'Stripe') {
                                //card change
                                return that.paymentWithStripe(transactionType, stripeParams);
                            }

                            _purchaseFromAuto(purchasedata);
                        } else {
                            ee.indiOn();
                            return EE_API.Payment.updateautocredit(purchasedata)
                                .then(function () {
                                    return ee.session.refreshUserData();
                                })
                                .then(function () {
                                    ee.indiOff();
                                    ee.data.account.autocreditenabled = true;
                                    ee.goTo('#/payments/planupdatesuccesful');
                                })
                                .catch(() => {
                                    ee.indiOff();
                                });
                        }
                    }
                } else {
                    //problem with existing autopayment
                    html.modal.confirm(
                        ee.t.invalidautocredit,
                        ee.t.invalidautocreditheader,
                        function () {
                            delete ee.data.account.payments;
                            ee.data.account.autocreditstatus = '';
                            ee.data.account.paymentprovider = 0;
                            ee.data.account.paymenttype = 'none';
                            ee.data.account.autocreditenabled = false;
                            ee.view.show();
                        },
                        false,
                        { confirmTitle: ee.t.edit, confirmCSS: 'btn_primary btn_lg' }
                    );
                }
            },

            returnToAccount: () => {
                if (ee.bootedAsChild) {
                    ee.goTo('#/payments?goBack=/billing');
                } else {
                    ee.goTo('#/account', true);
                }
            },

            showFinishedTransaction: function (payment, upgrade, paymentchange) {
                ee.logEvent('Payments - finished transaction');
                ee.frame.content.empty();
                const { activePlan } = ee.data.account;

                var header, maintext, waiting;
                var progress = 0;
                if (activePlan.typedesc === 'PayAsYouGo') {
                    if (payment) {
                        //payment + autorecharge
                        header = ee.t.paymentthankyou;
                        maintext = ee.t.creditstatusisbeingupdated + ee.data.account.email;
                        //autorecharge setup
                    } else if (paymentchange) {
                        //update
                        header = ee.t.goodtogo;
                        maintext = ee.t.paymentdetailsedited;
                    } else {
                        //autorecharge
                        header = ee.t.success;
                        maintext = 'Your account will be auto-recharged when credits get below the set value';
                    }
                } else {
                    if (payment) {
                        if (upgrade) {
                            //upgrade
                            header = ee.t.success;
                            maintext = 'We sent a receipt to ' + ee.data.account.email;
                        } else {
                            //new payment
                            waiting = ee.t.processingpayment;
                            header = ee.t.paymentthankyou;
                            maintext = ee.t.creditstatusisbeingupdated + ee.data.account.email;
                        }
                    } else if (paymentchange) {
                        //update
                        header = ee.t.goodtogo;
                        maintext = ee.t.paymentdetailsedited;
                    } else {
                        //autoincreasecontacts{
                        header = ee.t.goodtogo;
                        maintext = ee.t.autoincreasemaintext;
                    }
                }

                var timer = setInterval(function () {
                    progress = progress + 2;
                    $('#eprogresspercent').attr('data-progress', progress);
                    if (progress >= 60) {
                        clearInterval(timer);
                    }
                }, 600);
                if (payment) {
                    $screen = html.get('PendingTransaction', {
                        header: header,
                        maintext: maintext,
                        waiting: waiting,
                        timeforprocessingneeded: payment,
                    });
                    var successTimeout = setTimeout(function () {
                        ee.frame.content.empty();
                        $screen = html.get('FinishedTransaction', {
                            header: header,
                            maintext: maintext,
                            waiting: waiting,
                            timeforprocessingneeded: false,
                        });
                        $screen.appendTo(ee.frame.content);
                        $screen.gotoaccount.off().on('click', that.returnToAccount);
                    }, 15000);
                } else {
                    $screen = html.get('FinishedTransaction', {
                        header: header,
                        maintext: maintext,
                        waiting: waiting,
                        timeforprocessingneeded: false,
                    });
                    $screen.gotoaccount.off().on('click', that.returnToAccount);
                }
                $screen.appendTo(ee.frame.content);

                try {
                    if (
                        sessionStorage.getItem('checkouttoken') &&
                        activePlan.typedesc === 'ContactSubscription' &&
                        ee.data.account.paymenttype == 'pp'
                    ) {
                        //check if there is an ongoing payment with paypal
                        var checkouttoken = sessionStorage.getItem('checkouttoken');
                        sessionStorage.removeItem('checkouttoken');
                        return EE_API.Payment.Charge({ checkouttoken: checkouttoken })
                            .then(function () {
                                if (ee.data.account.subscriptionbillingdate) {
                                    ee.goTo(`#/payments/planupdatesuccesful`);
                                } else {
                                    ee.goTo(`#/payments/planregistrationsuccesful`);
                                }
                            })
                            .catch(function (err) {
                                if (successTimeout) clearTimeout(successTimeout);
                                console.error(err);
                                if (err instanceof Error) {
                                    ee.captureStripeException(err);
                                } else {
                                    ee.captureStripeMsg(err);
                                }
                                ee.indiOff();
                                ee.goTo('#/payments/unsuccesfulpayment');
                            });
                    }
                } catch (err) {
                    console.error(err);
                    console.error('You need to enable session storage');
                }
            },

            getPaymentType: (type) => {
                return that._getPaymentType(type);
            },

            chargeByStripe: (secret) => {
                return _chargeByStripe(secret);
            },
            showFailedTransaction: function (autorecharge) {
                const { activePlan } = ee.data.account;

                try {
                    if (sessionStorage.getItem('checkouttoken') && activePlan.typedesc === 'ContactSubscription') {
                        //check if there is an ongoing payment with paypal
                        sessionStorage.removeItem('checkouttoken');
                    }
                } catch (err) {
                    console.error(err);
                    console.error('You need to enable session storage');
                }
                ee.frame.content.empty();

                ee.logEvent('Payments - failed transaction');
                $screen = html.get('FailedTransaction');
                $screen.appendTo(ee.frame.content);
                $screen.gotoaccount.on('click', that.returnToAccount);
            },
            showPaymentHistory: function () {
                ee.logEvent('Payments - show payment history');
                ee.frame.content.empty();
                let fromDate, toDate;
                if (!ee.data.payments) {
                    const currentDate = new moment();
                    toDate = ee.tools.time(currentDate).toUTC();
                    fromDate = ee.tools.time(currentDate.subtract(6, 'months')).toUTC();
                    ee.api.loadPaymentHistory(100, fromDate, toDate, that.showPaymentHistory, false);

                    return;
                } else {
                    toDate = ee.data.payments.toDate;
                    fromDate = ee.data.payments.fromDate;
                }
                if (!ee.data.payments.daterangetitle) {
                    ee.data.payments.daterangetitle = ee.t.last6months;
                }

                //Load payments
                var paymenthistory = html.get('PaymentHistory', {
                    bootedAsChild: ee.bootedAsChild,
                    ...ee.data.payments
                });
                paymenthistory.appendTo(ee.frame.content);
                ee.view.screen = paymenthistory;

                $('.goToBilling').on('click', (e) => {
                    e.preventDefault;
                    e.stopPropagation();
                    ee.goTo('#/billing?goBack=/billing');
                })

                if (ee.data.payments.daterangetitle) {
                    paymenthistory.daterangetitle.html(ee.data.payments.daterangetitle);
                    if (ee.data.payments.daterangetitle === ee.t.specifictimerange) {
                        paymenthistory.fromto.removeClass('hide');
                    }
                }
                _.forEach(ee.data.payments.list, function (item) {
                    //add info about discounts, if available
                    if (item.discountpercent) {
                        item.discount = item.discountpercent * 100;
                    }
                });

                var pagination = new html.Pagination(ee.data.payments.list, {
                    template: 'PaymentHistoryTable',
                    resultcontainer: paymenthistory.paginationresult,
                    footercontainer: paymenthistory.find('.paginationlist'),
                    perpage: 10,
                    paginationinfo: false,
                    firstlast: false,
                });
                //START DATE FILTER
                if (ee.data.payments.toDate) paymenthistory.dateto.val(moment(ee.data.payments.toDate).format('L'));
                if (ee.data.payments.fromDate)
                    paymenthistory.datefrom.val(moment(ee.data.payments.fromDate).format('L'));
                paymenthistory.dateto.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() });
                paymenthistory.datefrom.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() });
                paymenthistory.fromto.on('change', '.hasDatepicker', function () {
                    ee.api.loadPaymentHistory(
                        100,
                        ee.tools.time(paymenthistory.datefrom.val()).toUTC(),
                        ee.tools.time(paymenthistory.dateto.val()).toUTC(),
                        function () {
                            ee.data.payments.daterangetitle = paymenthistory.daterangetitle.text();
                            pagination.updateData(ee.data.payments.list);
                        },
                        ee.t.datetimeformat
                    );
                });
                //END DATE FILTER

                // Send Receipt
                var paymentapikey = ee.session.apikey();
                paymenthistory.on('click', '.esendreceipt', function () {
                    ee.api.sendReceipt($(this).data('paymentid'));
                });
                // Get Receipt PDF
                paymenthistory.on('click', '.egetreceipt', function (e) {
                    e.preventDefault();
                    const paymentId = $(this).data('paymentid');
                    const href = `${ee.api.path}/account/getreceipt?version=2&paymentid=${paymentId}`;

                    fetch(href, {
                        method: 'GET',
                        headers: {
                            'X-ElasticEmail-ApiKey': paymentapikey,
                        },
                    })
                        .then((res) => {
                            if (res.ok) {
                                return res;
                            }
                            throw new Error(`${ee.t.download} ${ee.t.error}: ${res.status}`);
                        })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.target = '_blank';
                            link.click();
                            link.remove();
                        })
                        .catch((err) => {
                            console.log(err.message);
                            html.modal.info(err.message, ee.t.errormsg);
                        });
                });
                // Send Invoice
                paymenthistory.on('click', '.esendinvoice', function () {
                    ee.api.sendInvoice($(this).data('paymentid'));
                });
                // Get Invoice PDF
                paymenthistory.on('click', '.egetinvoice', function (e) {
                    e.preventDefault();
                    const paymentId = $(this).data('paymentid');
                    const href = `${ee.api.path}/account/getinvoice?version=2&paymentid=${paymentId}`;

                    fetch(href, {
                        method: 'GET',
                        headers: {
                            'X-ElasticEmail-ApiKey': paymentapikey,
                        },
                    })
                        .then((res) => {
                            if (res.ok) {
                                return res;
                            }
                            throw new Error(`${ee.t.download} ${ee.t.error}: ${res.status}`);
                        })
                        .then((res) => res.blob())
                        .then((blob) => {
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.target = '_blank';
                            link.click();
                            link.remove();
                        })
                        .catch((err) => {
                            console.log(err.message);
                            html.modal.info(err.message, ee.t.errormsg);
                        });
                });

                // Event filter by date
                paymenthistory.dateselect.on('click', 'li', function () {
                    var $li = $(this),
                        val = $li.data('range'),
                        to = new moment(),
                        from = new moment();
                    paymenthistory.daterangetitle.html($li.html());
                    if ($li.hasClass('drop-first') === true) {
                        paymenthistory.fromto.removeClass('hide');
                    } else {
                        paymenthistory.fromto.addClass('hide');
                    }
                    switch (val) {
                        case 'month':
                            from.subtract(1, 'months');
                            break;
                        case '3months':
                            from.subtract(3, 'months');
                            break;
                        case 'halfyear':
                            from.subtract(6, 'months');
                            break;
                        case 'year':
                            from.subtract(1, 'years');
                            break;
                    }

                    if (val) {
                        paymenthistory.dateto.val(to.format('L'));
                        paymenthistory.datefrom.val(from.format('L'));
                        paymenthistory.dateto.trigger('change');
                    }
                });
            },
            showUsage: function () {
                ee.logEvent('Payments - show OLD account usage');
                ee.frame.content.empty();
                if (!ee.data.usage) {
                    ee.data.usage = { months: [] };
                }
                var datausage = ee.data.usage;
                let accountcreation = moment(ee.data.account.datecreated).date(1);
                let now = moment().date(1);
                //Make months list
                if (datausage.months.length == 0) {
                    let months = now.diff(accountcreation, 'months');
                    for (months; months >= 0; months--) {
                        datausage.months.push({
                            yearmonth: accountcreation.format('YYYY/MM'),
                            display: accountcreation.format('MM/YYYY'),
                        });
                        accountcreation.add(1, 'months');
                    }
                    datausage.months.reverse();
                }
                //Load subpage frame
                var usage = html.get('AccountUsage', datausage);
                usage.appendTo(ee.frame.content);
                if (datausage.from) {
                    usage.yearmonth.val(
                        ee.tools.time(datausage.from, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate('YYYY/MM/01')
                    );
                } else {
                    usage.yearmonth.val(ee.tools.time(now).toDate('YYYY/MM/01'));
                }
                //Add event on yearthmont select
                usage.yearmonth.off().on('change', function () {
                    let from, to, n;
                    if ($(this).val()) {
                        from = ee.tools.time($(this).val(), 'YYYY/MM/DD').toDate();
                        const endMonth = moment($(this).val()).endOf('month').format('YYYY-MM-DD hh:mm');
                        to = ee.tools.time(endMonth).toDate();
                    } else {
                        from = ee.tools.time(moment().startOf('month')).toDate();
                        to = ee.tools.time(moment()).toDate();
                    }
                    n = 0;
                    datausage.from = from;
                    datausage.to = to;
                    //If we don't have info about current date range then call api to get data
                    ee.api.loadusage(from, to, ee.view.showusage);
                    return;
                });
                //If varible "to" is undefined then call yearmonth event change
                if (typeof datausage[datausage.from] === 'undefined') {
                    usage.yearmonth.trigger('change');
                    return;
                }
                datausage[datausage.from].chargeforcontacts = ee.data.account.chargeforcontacts;
                const currentPricingPlanName =
                    ee.data.account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';
                const showVerificationCosts =
                    currentPricingPlanName !== 'Pricing2018Unlimited' &&
                    currentPricingPlanName !== 'Pricing2018UnlimitedPro';
                const usageTable = html.get(
                    'ShowUsageTable',
                    _.merge({}, datausage[datausage.from], { showVerificationCosts: showVerificationCosts })
                );
                usageTable.appendTo(usage.result);
                //Toggle tabs
                usage.result
                    .find('.collapse')
                    .on('show.bs.collapse', function () {
                        var $contanier = $(this).closest('.usagelist__table');
                        $contanier.find('.usagelist__table__footer').removeClass('hidden');
                        $contanier.find('.fa-chevron-down').addClass('rotate180');
                    })
                    .on('hide.bs.collapse', function () {
                        var $contanier = $(this).closest('.usagelist__table');
                        $contanier.find('.usagelist__table__footer').addClass('hidden');
                        $contanier.find('.fa-chevron-down').removeClass('rotate180');
                    });
            },
            showNewUsage: function () {
                ee.frame.content.empty();
                if (!ee.data.usage) {
                    ee.data.usage = { months: [] };
                }
                var datausage = ee.data.usage;
                //Make months list
                if (datausage.months.length == 0) {
                    _createMonthsList(datausage);
                }
                //Load subpage frame
                var usage = html.get('AccountUsage', datausage);

                ee.logEvent('Payments - show account usage');

                usage.appendTo(ee.frame.content);
                usage.yearmonth.val(moment(datausage.from).format('YYYY/MM/01'));

                //Add event on yearthmont select
                usage.yearmonth.off().on('change', function () {
                    var from = new Date(ee.api.formatDate($(this).val(), 'yy/mm/dd', false, true)),
                        to = new Date(from.getFullYear(), from.getMonth() + 1, 0),
                        n = 0;
                    datausage.from = ee.tools.time(from).toUTC();
                    datausage.to = ee.tools.time(to).toUTC();
                    //If we don't have info about current date range then call api to get data
                    _loadNewUsageData(datausage);
                });
                //If varible "to" is undefined then call yearmonth event change
                if (typeof datausage[datausage.from] === 'undefined') {
                    usage.yearmonth.trigger('change');
                    return;
                }

                if (ee.data.usage[datausage.from].parent.length !== 0) {
                    var usageRow = ee.data.usage[datausage.from];
                    var usageRowParent = usageRow.parent;
                    var usageRowTotal = usageRow.totalCountsParent;

                    usageRowParent = usageRowParent.map(function (item) {
                        item.costformatted = item.cost.toFixed(2);
                        item.supportcostformatted = item.supportcost.toFixed(2);
                        item.plancostformatted = item.plancost.toFixed(2);
                        item.privateipcostformatted = item.privateipcost.toFixed(2);
                        return item;
                    });

                    usageRowTotal.costformatted = usageRowTotal.cost.toFixed(2);

                    usageRowTotal.supportcostformatted =
                        usageRowTotal.supportcost == 0 ? 0 : usageRowTotal.supportcost.toFixed(2);
                    usageRowTotal.plancostformatted =
                        usageRowTotal.plancost == 0 ? 0 : usageRowTotal.plancost.toFixed(2);
                    usageRowTotal.privateipcostformatted =
                        usageRowTotal.privateipcost == 0 ? 0 : usageRowTotal.privateipcost.toFixed(2);
                    usageRow.totalCountsParent = usageRowTotal;
                    usageRow.parent = usageRowParent;
                    var usageTable = html.get(
                        'ShowNewParentUsageTable',
                        $.extend({ d: usageRow }, { email: ee.data.account.email })
                    );
                    usageTable.appendTo(usage.result);
                } else {
                    var usageTable = html.get('NoUsageData');
                    usageTable.appendTo(usage.result);
                }
                if (ee.data.usage[datausage.from].parent.length !== 0) {
                    var subCount = 0;
                    for (var i = 0; i < ee.data.usage[datausage.from].subaccounts.length; i++) {
                        if (ee.data.usage[datausage.from].subaccounts[i].usage.length !== 0) {
                            subCount++;
                            var usageTable = html.get(
                                'ShowNewSubaccUsageTable',
                                $.extend({ sub: ee.data.usage[datausage.from].subaccounts[i] })
                            );
                            usageTable.appendTo(usage.result);

                            if (subCount > 1) {
                                usageTable.find('.subacc_info').hide();
                            }
                        }
                    }
                }
                //Toggle tabs
                usage.result
                    .find('.collapse')
                    .on('show.bs.collapse', function () {
                        var $contanier = $(this).closest('.usagelist__table');
                        $contanier.find('.usagelist__table__footer').removeClass('hidden');
                        $contanier.find('.fa-chevron-down').addClass('rotate180');
                    })
                    .on('hide.bs.collapse', function () {
                        var $contanier = $(this).closest('.usagelist__table');
                        $contanier.find('.usagelist__table__footer').addClass('hidden');
                        $contanier.find('.fa-chevron-down').removeClass('rotate180');
                    });
            },
            paymentsourceInteractions: function ($paymenttypeselection) {
                var closepopup,
                    onlyDig = /^\D+/g;
                const { activePlan } = ee.data.account;
                //cvv tolltip
                $paymenttypeselection.disableautocredit.on('click', function () {
                    let disableautocreditinfo = ee.t.disableautocredit;
                    let disableautocredittitle = ee.t.disableautocreditheader;
                    if (activePlan.typedesc === 'ContactSubscription') {
                        if (ee.data.account.subscriptionbillingdate !== null) {
                            var subscriptionend = ee.api.formatDate(
                                new Date(ee.data.account.subscriptionbillingdate),
                                'dd/mm/yy',
                                false,
                                true
                            );
                            disableautocreditinfo = html.render(ee.t.disableautocreditwithrenewdate, {
                                date: subscriptionend,
                            });
                        } else {
                            disableautocreditinfo = ee.t.removecurrentpaymentmethod;
                            disableautocredittitle = ee.t.confirmaction;
                        }
                    }
                    html.modal.confirm(
                        disableautocreditinfo,
                        disableautocredittitle,
                        function () {
                            ee.indiOn();
                            return EE_API.Payment.removePaymentMethod()
                                .then(function () {
                                    return ee.session.refreshUserData().then(() => {
                                        ee.goTo('#/payments');
                                        ee.indiOff();
                                    });
                                })
                                .then(function () {
                                    ee.view.show();
                                });
                        },
                        false,
                        { confirmTitle: ee.t.yes, confirmCSS: 'btn_lg btn_destructive btn-danger' }
                    );
                });

                /*$paymenttypeselection.changepayment.on('click', function () {
                    //for users that want to change their plan without cancelling Contact Delivery Tools or IPS - we just allow them to finish form once again.
                    html.modal.confirm(ee.t.changeeautocredit, ee.t.changeautocreditheader, function () {
                        delete ee.data.account.payments;
                        //ee.data.account.autocreditstatus = "";
                        //ee.data.account.paymentprovider = 0;
                        //ee.data.account.paymenttype = "none";
                        ee.data.account.autocreditenabled = false;
                        ee.view.show();
                    }, false, { confirmTitle: ee.t.yes, confirmCSS: "btn_primary btn_lg" })
                })*/
            },

            paymentWithStripe: (type, params) => {
                ee.logEvent('Payments - start payment process with Stripe');

                const { activePlan } = ee.data.account;

                const selectedPlan =
                    $('#epaymentchoosetier').find('.tierbox.active .tierbutton').attr('data-pricingplan') ||
                    ee.data.account.pricingplan;

                const paymentParams = {
                    ...params,
                    type: that._getPaymentType(type),
                    plan: selectedPlan,
                    planType: activePlan.typedesc,
                };

                ee.indiOn();
                const key = ee.isLive() ? ee.api.publickey : 'pk_test_IrRPpaascKmIELAj1u1jNAZP';
                window.stripe = new Stripe(key);

                return EE_API.Payment.preparePayment(paymentParams)
                    .then((resp) => {
                        const { sessionid, clientsecret } = resp;

                        if (!sessionid && !clientsecret) {
                            throw 'Stripe session id and client secret are missing.';
                        }

                        if (sessionid) {
                            return _redirectToStripe(sessionid);
                        }

                        if (clientsecret) {
                            return _chargeByStripe(clientsecret);
                        }

                        return;
                    })
                    .then(() => {
                        ee.indiOff();

                        switch (params.type) {
                            case 2: //Charge
                                if (ee.data.account.istrialbillingplan) {
                                    ee.goTo('#/payments/planregistrationsuccesful');
                                } else {
                                    ee.goTo('#/payments/planupdatesuccesful');
                                }
                                break;
                            case 1: //Setup && SetupAndCharge
                            default:
                                ee.goTo('#/payments/paymentupdatesuccesful');
                                break;
                        }

                        //return ee.session.loadAccount();
                    })
                    .catch(function (resp) {
                        ee.indiOff();

                        console.error(resp);
                        if (resp instanceof Error) {
                            ee.captureStripeException(resp);
                        } else {
                            ee.captureStripeMsg(resp);
                        }
                        //html.modal.info(resp.error.message);

                        ee.goTo('#/payments/unsuccesfulpayment');
                    });
            },
        };

    return that;
})();
