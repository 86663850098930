(ee.views["showcase"] = function () {
    
    var that = {
        show: function () {
            ee.indiOn(); 
            var that = this;
            var doc = ee.frame.content;
            screen = html.get('ShowcaseTemplate',{});
            ee.indiOff();
            screen.appendTo(doc);


            $('#buttondisable').click (function() {  
                $('input#disabledRadio').attr('disabled', true);
            });
            $("#buttonUndisable").click (function() {
                $('input#disabledRadio').removeAttr("disabled", false);
            });

            $('#buttonDisableCheckbox').click (function() {  
                $('input#disabledCheckbox').attr('disabled', true);
            });
            $("#buttonUndisableCheckbox").click (function() {
                $('input#disabledCheckbox').removeAttr("disabled", false);
            });

            $('#buttonDisableCheckboxMinus').click (function() {  
                $('input#disabledCheckboxMinus').attr('disabled', true);
            });
            $("#buttonUndisableCheckboxMinus").click (function() {
                $('input#disabledCheckboxMinus').removeAttr("disabled", false);
            });

            $("input").click (function() {
                var parent = $("#parentInput").prop('checked');
                if (parent == true) {
                    $('#childInputFirst').attr('disabled', false);
                    $('#childInputSecond').attr('disabled', false);
                } else {
                    $('#childInputFirst').attr('disabled', true);
                    $('#childInputSecond').attr('disabled', true);
                }

            })
         
            $("input").click (function() {
                var parent = $("#parentInputcheckbox").prop('checked');
                if (parent == true) {
                    $('#childInputThird').attr('disabled', false);
                    $('#childInputFourth').attr('disabled', false);
                } else {
                    $('#childInputThird').attr('disabled', true);
                    $('#childInputFourth').attr('disabled', true);
                }

            })
            
            if (ee.isReseller()) {
                $("div").each(function(){
                    if($(this).hasClass('search_icon_pro')){
                        $(this).addClass('search_icon');
                        $(this).removeClass("search_icon_pro");
                    }
                })
            }
            if (ee.isReseller()) {
                $('a').each(function(){
                    if($(this).hasClass('p_left')){
                        $(this).addClass('p_left-reseller');
                        $(this).removeClass('p_left');
                    }
                    if($(this).hasClass('p_right')){
                        $(this).addClass('p_right-reseller');
                        $(this).removeClass('p_right');
                    }
                })
            }
        },
    }; 
    return that;
}());
