;(root => {
    let $domainScreen
    let $domainContainer
    let _pagination
    let _validEmails
    const domainhelplink = 'https://help.elasticemail.com/en/articles/2281323-how-to-verify-your-domain'
    const emailhelplink = 'https://help.elasticemail.com/account-settings/how-to-verify-your-email'

    const _isValidEmail = email => {
        if (ee.tools.isValidEmail(email) === false) {
            html.modal.info(`<b>${email}</b>: ${ee.t.invalidemailaddress}`, ee.t.error)
            return false
        }
        return true
    }
    const _afterInitPagination = () => {
        if (_validEmails.domains.getLength() === 0) {
            $domainScreen.domainheader.hide()
        } else {
            $domainScreen.domainheader.show()
        }
    }
    const _confirmAddSender = modal => {
        modal.$content.empty().append(html.get('DomainAddedSuccessSenderDialog'))
        modal.$footer.find('.btn-info').hide()
        modal.$footer
            .find('.btn-link')
            .text(ee.t.close)
            .removeClass('btn-link')
            .addClass('btn-default')
    }
    const _validate = domainname => {
        let dnsToCheck
        let timer
        const progressmax = 60
        const domain = _validEmails.domains.get('domain', domainname.toLowerCase())
        const transitionEvent = html.whichTransitionEvent()
        let $content = html.get('DomainValidateDialog', {domain: domain.name})
        const $circleParts = $content.find('.ko-progress-circle__slice, .ko-progress-circle__fill')
        const validate = span => {
            const $span = $(span)
            const $spanico = $span.find('i')
            const method = $span.data('dns')
            let progress = 0
            //Preper data
            $circleParts.css('transition', 'none') //Temporary disabled animation for reset
            $content.progresspercent.attr('data-progress', progress)
            $content.progressinfo.find('div:first').html(method) //Set validating text...
            setTimeout(() => {
                $circleParts.removeAttr('style')
            }, 1)
            $span.addClass('domain-inprogress')

            timer = setInterval(() => {
                progress += 1
                $content.progresspercent.attr('data-progress', progress)
                if (progress >= progressmax) {
                    clearInterval(timer)
                }
            }, 490)
            _validEmails.domains[`verify${method}`](domain)
                .then(isValid => {
                    clearInterval(timer)
                    if ($content === null) return
                    $content.progresspercent
                        .one(transitionEvent, () => {
                            const newMethod = dnsToCheck.shift()
                            $spanico.addClass(isValid ? 'domain-ico-positive' : 'domain-ico-negative')
                            $span.removeClass('domain-inprogress')
                            if (newMethod) {
                                validate(newMethod)
                                return
                            }
                            if (domain.spf) {
                                finalMessage(ee.t.yourdomainisauthenticated, 'success')
                            } else {
                                finalMessage(ee.t.yourdomaincannotbeauthenticated, 'failed')
                            }
                        })
                        .attr('data-progress', progressmax)
                })
                .catch(e => {
                    console.log(e)
                    clearInterval(timer)
                    if ($content) {
                        $circleParts.css('transition', 'none')
                        $content.progresspercent.attr('data-progress', progressmax)
                        finalMessage(e.message, 'failed')
                    }
                })
        }
        let finalMessage = (msg, status) => {
            $content.progressinfo.html('<div class="ko-proggress-circle__status"></div>')
            $content.progresspercent.addClass(`ko-progress-circle__${status} ko-progress-circle__small`)
            $content.domainvalidateresults.html(msg).fadeIn(200)
        }
        const modal = html.modal.create($content, {
            title: `Domain ${domainname}`,
            width: '520px',
            css: 'domain-validatedialog',
            onClose: () => {
                clearInterval(timer)
                dnsToCheck = []
                $content = null
            },
            cancel: {title: ee.t.close, css: 'btn-link'},
        })
        //Start validate
        dnsToCheck = $content.find('.domain-authenticationdetails span').toArray()
        validate(dnsToCheck.shift())
    }
    const _helpDialog = helpName => {
        const $content = html.get(`Help${helpName}`, {})
        const modal = html.modal.create($content, {
            width: '1040px',
            maxToScreenHeight: true,
            cancel: {title: ee.t.close, css: 'btn-link'},
            css: 'emodal-helpcontent',
        })
        html.clickToCopy.init($content, true)
        $content.on('click', '[data-toggle=collapse]', element => {
            const $this = $(element.currentTarget)
            const collapsein = $this.hasClass('chevron-down') ? true : false
            $content.find('.collapse.in').collapse('hide')
            $content.find('.chevron-down').removeClass('chevron-down')
            $content.find($this.data('target')).collapse('toggle')
            if (collapsein === false) {
                $this.addClass('chevron-down')
            }
        })
    }
    const _bindEvents = () => {
        /*EVENTS*/
        //Verify a sender
        $domainScreen.on('click', '.action-verifydomainbyemail', () => {
            const $addSenderDialog = html.get('DomainAddSenderDialog', {})
            const modal = html.modal.create($addSenderDialog, {
                title: ee.t.emailverification,
                buttons: [
                    {
                        name: ee.t.verifyemail,
                        css: ' btn_lg btn_primary btn-info',
                        callback: () => {
                            const domainemail = $addSenderDialog.domainemail.val()
                            if (_isValidEmail(domainemail) !== true) return
                            ee.indiOn()
                            $.when(_validEmails.add(domainemail))
                                .then(() => {
                                    _confirmAddSender(modal)
                                    _pagination.updateData(_prepareDomainsList())
                                })
                                .always(() => {
                                    ee.indiOff()
                                })
                        },
                    },
                ],
            })
        })
        $domainScreen.on('click', '.action-verifydomain', () => {
            const addDomain = html.get('AddDomain', {reseller: ee.isReseller()})
            const modal = html.modal.create(addDomain, {
                title: ee.t.adddomain,
                buttons: [
                    {
                        name: ee.t.add,
                        title: ee.t.ttsavedomain,
                        css: 'btn_primary btn_lg',
                        callback: () => {
                            let domain = addDomain.name
                                .val()
                                .trim()
                                .toLowerCase()
                            const trackingType = addDomain.httpstracking.val().trim()

                            if (ee.tools.isValidDomain(domain) === false) {
                                html.modal.info(ee.t.wrongdomain, ee.t.error)
                                return
                            }

                            if (_.includes(domain, 'www.')) {
                                domain = domain.substring('www.'.length)
                            }

                            ee.indiOn()
                            $.when(_validEmails.domains.add(domain, 'http')).then(() => {
                                _validEmails
                                    .reload()
                                    .then(() => {
                                        _pagination.updateData(_prepareDomainsList())
                                        modal.close()
                                    })
                                    .always(ee.indiOff)
                            })
                        },
                    },
                ],
            })
        })
        //Hide dropdown if empty
        _.forEach($domainScreen.paginationresult.find('.dropdown'), dropdown => {
            const $drop = $(dropdown)
            if (!$drop.find('ul li').length) {
                $drop.addClass('hide')
            }
        })

        //Toogle expand email list
        $domainScreen.paginationresult.on('click', '.domain-toggleexpand', element => {
            let emaillist
            const $this = $(element.currentTarget)
            const $row = $this.closest('.domain-emails')
            const $ul = $row.find('.domain-emails-list:first')
            const $info = $row.find('.domain-emails-info')
            $ul.slideToggle(200)
            if ($this.hasClass('domain-ico-expanded')) {
                $this.removeClass('domain-ico-expanded').addClass('domain-ico-expand')
                emaillist = $ul.find('li').length
                if (emaillist > 0) {
                    $info.html(` ${n} Email(s)`)
                }
            } else {
                $this.removeClass('domain-ico-expand').addClass('domain-ico-expanded')
                $info.empty('')
            }
        })
        //Remove all emails
        $domainScreen.paginationresult.on('click', '.action-deleteallemails', e => {
            const domain = $(e.currentTarget).data('domain')
            html.modal.confirm(ee.t.deletesenders, ee.t.deleteallsenders, () => {
                ee.indiOn()
                const emailsToDelete = _validEmails.filter(['domain'], domain, true)
                const delCount = emailsToDelete.getLength()
                let deleted = 0
                _.forEach(emailsToDelete.data, email => {
                    $.when(_validEmails.remove(email)).then(() => {
                        deleted++
                        if (deleted >= delCount) {
                            _pagination.updateData(_prepareDomainsList())
                            ee.indiOff()
                        }
                    })
                })
            })
        })
        //Resend verification
        $domainScreen.paginationresult.on('click', '.action-resendverification', e => {
            const $this = $(e.currentTarget)
            const emailAddress = $this.data('email')
            $.when(_validEmails.resendEmailVerification(emailAddress)).then(() => {
                $this.attr('disabled', true).html(ee.t.emailwassent)
            })
        })
        //Set Bounce Domain
        $domainScreen.paginationresult.on('click', '.action-managebouncedomain', e => {
            const $this = $(e.currentTarget)
            const domainname = $this.data('domain')
            const domain = _.find(ee.data.domains.data, o => {
                return o.domain === domainname
            })
            //default values for bounce domain panel : turned off bounced domain
            const query = {
                isverp: false, //ee.data.account.enablebounceshandling,
                domain: domainname,
                // customBouncesDomain: domain.custombouncesdomain ? domain.custombouncesdomain : `bounces.${domainname}`,
            }

            domain.createnew = !domain.verp || !domain.custombouncesdomain
            const isSubAccount = ee.data.account.issub && ee.data.account.isprivatebrandedsub
            const panelcontent = html.get('CustomBounceDomains', {domain, isSubAccount})

            const options = {
                title: 'Manage Bounce Domain',
                sidePanel: true,
                draggable: false,
                buttons: [
                    {
                        name: ee.t.cancel,
                        css: 'btn_tertiary btn_md close_button',
                        callback: () => {
                            modal.close()
                        },
                    },
                    {
                        name: ee.t.close,
                        css: 'btn btn-primary btn_md btn_closemodal hide',
                        callback: () => {
                            modal.close()
                        },
                    },
                    {
                        name: ee.t.continue,
                        css: 'btn_primary btn_md continuebutton',
                        callback: () => {
                            query.isverp = true
                            query.customBouncesDomain = domain.custombouncesdomain ? domain.custombouncesdomain : `bounces.${domainname}`
                            query.isCustomBouncesDomainDefault = $('input[name="makedefaultbounce"]:checked')
                            modal.close()
                            validateNewBounceDomain(query)
                        },
                    },
                    {
                        name: ee.t.savechanges,
                        css: 'btn_primary btn_md existingbounce',
                        callback: () => {
                            switch ($('input[name="bounceOption"]:checked').val()) {
                                case 'none':
                                    query.isverp = false
                                    delete query.customBouncesDomain
                                    break
                                case 'defaultdomain':
                                    query.isverp = true
                                    query.customBouncesDomain = ''
                                    break
                                case 'existingdomain':
                                    query.isverp = true
                                    query.isCustomBouncesDomainDefault = $('input[name="makedefaultbounce"]:checked')
                                    break
                            }
                            modal.$title.empty()
                            //hide data, show validator
                            $('.close_button').addClass('hide')
                            $('#custombouncecontent').addClass('hide')
                            $('.existingbounce').addClass('hide')
                            //start ticking of validator
                            $('.mxverification').removeClass('hide')
                            //finish validator and show results
                            // eslint-disable-next-line no-undef
                            return EE_API.Domain.SetVerp(query)
                                .then(() => {
                                    $('.domain-icon')
                                        .removeClass('fa-spinner fa-pulse fas')
                                        .addClass('success-icon')
                                    $('.domain-text').text('Changes saved')
                                    $('.btn_closemodal').removeClass('hide')
                                })
                                .then(data => {
                                    return _validEmails.reload()
                                })
                                .then(() => {
                                    return _pagination.updateData(_prepareDomainsList())
                                })
                                .catch(err => {})
                        },
                    },
                ],
            }
            let modal = html.modal.create(panelcontent, options)
            const currentBounceSetting = $('input[name="bounceOption"]:checked').val();
            switch ($('input[name="bounceOption"]:checked').val()) {
                case 'none':
                    $('.continuebutton').hide();
                    $('.existingbounce').hide();
                    break
                case 'defaultdomain':
                    $('.continuebutton').hide();
                    $('.existingbounce').hide();
                    break
                case 'existingdomain':
                    $('.existingbounce').hide();
                    
                    break
            }
            $('input[name="bounceOption"]').on('click', () => {
                switch ($('input[name="bounceOption"]:checked').val()) {
                    case 'none':
                        $('.continuebutton').hide()
                        if(currentBounceSetting !== 'none'){   
                        $('.existingbounce').show()
                        }
                        break
                    case 'defaultdomain':
                        $('.continuebutton').hide()
                        if(currentBounceSetting !== 'defaultdomain'){
                            $('.existingbounce').show()
                        }
                        break
                    case 'existingdomain':
                        $('.existingbounce').hide()
                        $('.continuebutton').show()
                        break
                }
            })
            modal.$content.on('click', '.createnewbouncedomain', () => {
                modal.close()
                validateNewBounceDomain(query)
            })
        })
        //TurnOff Default Domain
        $domainScreen.paginationresult.on('click', '.action-removedefaultbounce', e => {
            const $this = $(e.currentTarget)
            const domainname = $this.data('domain')
            const domain = _.find(ee.data.domains.data, o => {
                return o.domain === domainname
            })
            const query = {
                isverp: true, //ee.data.account.enablebounceshandling,
                domain: domainname,
                customBouncesDomain: domain.custombouncesdomain,
                isCustomBouncesDomainDefault: false,
            }
            return EE_API.Domain.SetVerp(query)
                .then(data => {
                    return _validEmails.reload()
                })
                .then(() => {
                    modal.close()
                    return _pagination.updateData(_prepareDomainsList())
                })
                .catch(err => {})
        })
        //Remove email
        $domainScreen.paginationresult.on('click', '.action-deleteemail', e => {
            const $this = $(e.currentTarget)
            const validemailid = $this.data('validemailid')
            const validemail = _validEmails.get('validemailid', validemailid)
            if (!validemail) return
            const modal = html.modal.create(
                html.get(
                    'DomainDeleteDialog',
                    {itemtodelete: validemail.email, text: ee.t.deleteemailconfirmation},
                    true
                ),
                {
                    title: `Delete ${validemail.domain}`,
                    buttons: [
                        {
                            name: ee.t.deletes,
                            css: 'btn_destructive btn_lg lastofright',
                            callback: () => {
                                ee.indiOn()
                                modal.close()
                                $.when(_validEmails.remove(validemail))
                                    .then(() => {
                                        _pagination.updateData(_prepareDomainsList())
                                    })
                                    .always(() => {
                                        ee.indiOff()
                                    })
                            },
                        },
                        {
                            name: ee.t.cancel,
                            css: 'btn-link',
                            callback: () => {
                                modal.close()
                            },
                        },
                    ],
                }
            )
            //temporary
            modal.$container.modalCancel.hide()
        })
        //Helps show
        $domainScreen.paginationresult.on('click', '.domain-authenticationdetails span[data-help]', e => {
            _helpDialog($(e.currentTarget).data('help'))
        })
        //Validate Domain
        $domainScreen.paginationresult.on('click', '.action-validate', e => {
            _validate($(e.currentTarget).data('domain'))
        })
        //Add domain
        $domainScreen.paginationresult.on('click', '.action-adddomain', e => {
            const domainname = $(e.currentTarget).data('domain')
            ee.indiOn()
            $.when(_validEmails.domains.add(domainname, 'http')).then(() => {
                _validEmails
                    .reload()
                    .then(() => {
                        _pagination.updateData(_prepareDomainsList())
                    })
                    .always(ee.indiOff)
            })
        })
        //Add email
        $domainScreen.paginationresult.on('click', '.action-addemail', e => {
            const $this = $(e.currentTarget)
            const domain = _validEmails.domains.get('domain', $this.data('domain'))
            if (!domain) return
            const $addEmailDialog = html.get('DomainAddEmailDialog', {
                domain: domain.domain,
                reseller: ee.isReseller(),
                helplink: emailhelplink,
            })
            const modal = html.modal.create($addEmailDialog, {
                width: '720px',
                title: `${ee.t.addingemailto} ${domain.domain}`,
                buttons: [
                    {
                        name: ee.t.verifyemail,
                        css: 'btn-info btn_lg btn_primary lastofright',
                        callback: () => {
                            const email = `${$addEmailDialog.usernameemail.val().toLowerCase()}@${domain.domain}`
                            if (_isValidEmail(email) !== true) return
                            ee.indiOn()
                            $.when(_validEmails.add(email))
                                .then(() => {
                                    _confirmAddSender(modal)
                                    _pagination.updateData(_prepareDomainsList())
                                })
                                .always(() => {
                                    ee.indiOff()
                                })
                        },
                    },
                    {
                        name: ee.t.cancel,
                        css: 'btn-link',
                        callback: () => {
                            modal.close()
                        },
                    },
                ],
            })
            //temporary
            modal.$container.modalCancel.hide()
        })
        //Set/edit default sender
        $domainScreen.paginationresult.on('click', '.action-setasdefault', e => {
            const $this = $(e.currentTarget)
            const domain = $this.data('domain')
            const sender = $this.data('sender')
            const defaultDomain = html.get('DefaultDomain', {sender: sender ? sender : `mailer@${domain}`})
            const modal = html.modal.create(defaultDomain, {
                title: sender ? ee.t.editdefaultsender : ee.t.setdefaultdomain,
                buttons: [
                    {
                        name: ee.t.save,
                        title: ee.t.setdefaultdomain,
                        css: 'btn_lg btn_primary lastofright',
                        callback: () => {
                            ee.indiOn()
                            modal.close()
                            _validEmails.domains
                                .setDefault(defaultDomain.defaultemail.val())
                                .then(() => {
                                    return _validEmails.reload()
                                })
                                .then(() => {
                                    return _pagination.updateData(_prepareDomainsList())
                                })
                                .always(ee.indiOff)
                        },
                    },
                    {
                        name: ee.t.cancel,
                        css: 'btn-link',
                        callback: () => {
                            modal.close()
                        },
                    },
                ],
            })
            //temporary
            modal.$container.modalCancel.hide()
        })
        //Remove domain
        $domainScreen.paginationresult.on('click', '.action-deletedomain', e => {
            const domainName = $(e.currentTarget).data('domain')
            const modal = html.modal.create(
                html.get('DomainDeleteDialog', {itemtodelete: domainName, text: ee.t.deletedomainconfirmation}, true),
                {
                    title: `${ee.t.deletes} ${domainName}`,
                    buttons: [
                        {
                            name: ee.t.deletes,
                            css: 'btn_destructive btn_lg lastofright',
                            callback: () => {
                                ee.indiOn()
                                modal.close()
                                $.when(_validEmails.removeDomain(domainName))
                                    .then(() => {
                                        _pagination.updateData(_prepareDomainsList())
                                    })
                                    .always(() => {
                                        ee.indiOff()
                                    })
                            },
                        },
                        {
                            name: ee.t.cancel,
                            css: 'btn-link',
                            callback: () => {
                                modal.close()
                            },
                        },
                    ],
                }
            )
            //temporary
            modal.$container.modalCancel.hide()
        })
        html.bindSearch($domainScreen.searchdomain, search => {
            let searchdata = ee.data.domains
            if (search !== '') {
                searchdata = searchdata.filter(['domain'], search)
            }
            _pagination.setPage(1)
            _pagination.updateData(searchdata.getAll())
        })
        //-- ADMIN ONLY --//
        // -- TODO: admin methods should be moved to admininterface.js
        //Edit tracking type https/http
        $domainScreen.paginationresult.on('click', '.action-edittrackingtype', e => {
            const $this = $(e.currentTarget)
            const tracking = $this.data('tracktype')
            const domain = $this.data('domain')
            const $content = html.get('SetHttps', {})
            $content.httpstracking.val(String(tracking))
            const modal = html.modal.create($content, {
                title: 'Set HTTPS',
                buttons: [
                    {
                        name: ee.t.save,
                        css: 'btn_lg btn_primary',
                        callback: () => {
                            $.when(_validEmails.domains.adminSetTrackingType(domain, $content.httpstracking.val()))
                                .then(() => {
                                    modal.close()
                                })
                                .always(() => {
                                    ee.indiOff()
                                })
                        },
                    },
                ],
            })
        })
        //Edit tracking
        $domainScreen.paginationresult.on('click', '.action-edittracking', e => {
            const domain = $(e.currentTarget).attr('data-domain')
            $.when(_validEmails.domains.adminGetTrackingDomain(domain, ee.data.account.publicaccountid)).then(data => {
                const trackingSetup = html.get('TrackingSetup', {trackingdomain: data.trackingdomain})
                const modal = html.modal.create(trackingSetup, {
                    title: 'Edit Tracking',
                    buttons: [
                        {
                            name: ee.t.save,
                            css: 'btn_lg btn_primary',
                            callback: () => {
                                ee.indiOn()
                                $.when(
                                    _validEmails.domains.adminSaveTrackingDomain(
                                        trackingSetup.defaulttracking.val(),
                                        domain,
                                        ee.data.account.publicaccountid
                                    )
                                )
                                    .then(() => {
                                        modal.close()
                                    })
                                    .always(() => {
                                        ee.indiOff()
                                    })
                            },
                        },
                    ],
                })
            })
        })
        //-- END ADMIN ONLY --//
    }
    let validateNewBounceDomain = query => {
        const iscustombouncesdomaindefault = _.find(ee.data.domains.data, (domain) => domain.domain === query.domain).iscustombouncesdomaindefault;
        const subdomain = query.customBouncesDomain.replace(`.${query.domain}`, '')
        const validationdescription = html.render(ee.t.custombouncedomain_desc, {domain: query.domain})
        const resellerbouncedomain = ee.data.account.advancedoptions.bouncesdomain
            ? ee.data.account.advancedoptions.bouncesdomain
            : undefined
        const panelcontent = html.get('ValidateNewDomain', {
            query,
            subdomain,
            validationdescription,
            resellerbouncedomain,
            iscustombouncesdomaindefault,
        })
        const options = {
            title: 'Manage Bounce Domain',
            sidePanel: true,
            draggable: false,
            buttons: [
                {
                    name: ee.t.cancel,
                    css: 'btn btn_tertiary btn_md btn_cancel',
                    callback: () => {
                        modal.close()
                    },
                },
                {
                    name: ee.t.close,
                    css: 'btn btn-primary btn_md btn_closemodal hide',
                    callback: () => {
                        modal.close()
                    },
                },
                {
                    name: ee.t.continue,
                    css: 'btn btn_md btn_primary savesubdomain',
                    callback: () => {
                        const subdomain = $('#bouncedomainname').val()
                        const subdomaintext = `${subdomain}(or ${subdomain}.${query.domain})`
                        query.customBouncesDomain = `${$('#bouncedomainname').val()}.${query.domain}`
                        query.isCustomBouncesDomainDefault = $('input[name="makedefaultbounce"]:checked').val()
                        $('.validationstep').addClass('hide')
                        $('.savesubdomain').addClass('hide')
                        $('.domainrecordinfo').removeClass('hide')
                        $('.verifymx').removeClass('hide')
                        $('.chosenSubdomainDesc').text(subdomaintext)
                    },
                },
                {
                    name: ee.t.verify,
                    css: 'btn-primary btn_md hide verifymx',
                    callback: () => {
                        modal.$title.empty()
                        //hide data, show validator
                        $('.domainrecordinfo').addClass('hide')
                        $('.btn_cancel').addClass('hide')
                        $('.verifymx').addClass('hide')
                        //start ticking of validator
                        $('.mxverification').removeClass('hide')
                        //finish validator and show results

                        return EE_API.Domain.SetVerp(query)
                            .then(() => {
                                $('.domain-icon')
                                    .removeClass('fa-spinner fa-pulse fas')
                                    .addClass('success-icon')
                                $('.domain-text').text('Domain verified')
                                $('.btn_closemodal').removeClass('hide')
                            })
                            .then(() => {
                                return _validEmails.reload()
                            })
                            .then(() => {
                                return _pagination.updateData(_prepareDomainsList())
                            })
                            .catch(err => {
                                $('.domain-icon')
                                    .removeClass('fa-spinner fa-pulse fas')
                                    .addClass('far fa-times-circle')
                                $('.domain-text').text(err.error)
                                $('.btn_closemodal').removeClass('hide')
                            })
                    },
                },
            ],
        }
        let modal = html.modal.create(panelcontent, options)
        $('#bouncedomainname').on('keyup keydown change', function() {
            $(this).val()
                ? $('.savesubdomain')
                      .attr('disabled', false)
                      .removeClass('disabled')
                : $('.savesubdomain')
                      .attr('disabled', true)
                      .addClass('disabled')
        })
        $('#changeinfotoMX').on('click', e => {
            e.preventDefault()
            const MXTable = $('#MXdomainrecordtable')
            const chevron = $('#chev')
            chevron.toggleClass('collapsed not-collapsed')
            MXTable.toggleClass('hide')
        })
    }
    let _prepareDomainsList = () => {
        const emails = _validEmails.data
        const domains = _.cloneDeep(_validEmails.domains.getAll())
        _.forEach(emails, el => {
            _.filter(domains, domain => {
                if (domain.domain.toLowerCase() === el.domain.toLowerCase()) {
                    if (domain.emails) {
                        _.find(domain.emails, {email: el.email.toLowerCase()}) === undefined
                            ? domain.emails.push(el)
                            : false
                        domain.emails.length > 1 ? (domain.more = true) : (domain.more = false)
                    } else {
                        domain.emails = [el]
                    }
                }
                return domain.domain === el.domain
            })
        })
        _.forEach(domains, domain => {
            domain.bouncesdomain = ee.data.account.advancedoptions.bouncesdomain || domain.custombouncesdomain
            domain.enablebounceshandling = ee.data.account.enablebounceshandling
            if (ee.data.domains.data.some(i => i.iscustombouncesdomaindefault === true)) {
                domain.customdomaindefault = ee.data.domains.data.find(
                    item => item.iscustombouncesdomaindefault === true
                ).custombouncesdomain
            }
        })
        ee.data.domains.data = domains
        return ee.data.domains.data
    }
    const _initDomainList = () => {
        _pagination = new html.Pagination(_prepareDomainsList(), {
            template: 'DomainList',
            resultcontainer: $domainScreen.paginationresult,
            footercontainer: $domainScreen.find('.domains-paginationlist'),
            perpage: 8,
            paginationinfo: false,
            firstlast: false,
            reinitcallback: _afterInitPagination,
            param: {
                defaultbounce: ee.data.account.advancedoptions.bouncesdomain,
                domainrestricted: ee.data.account.domainrestricted,

                issub: ee.data.account.issub,
                reseller: ee.isReseller(),
                isValidEmail() {
                    return this.spf || this.emailvalidated
                },
                domainhelplink,
                emailhelplink,
                isAdmin: ee.session.can.ViewSettings,
            },
        })
        _afterInitPagination()
    }
    const that = {
        init: () => {
            _validEmails = dataModel.create('ValidEmails')
            $domainContainer = html.get('DomainsPreloader')
            return _validEmails
                .require()
                .then(data => {
                    $domainScreen = html.get('DomainsContainer')
                    const acc = ee.data.account
                    if (acc.actualdailysendlimit == -500 && !ee.data.account.istrialbillingplan) {
                        html.get('StartAlertWarning', {
                            msg: ee.data.account.enablebounceshandling
                                ? ee.t.verpdomainwarning
                                : ee.t.validdomainwarning,
                            url:
                                acc.supportlink != undefined
                                    ? acc.supportlink
                                    : 'https://help.elasticemail.com/en/articles/2281323-how-to-verify-your-domain',
                        }).insertBefore($domainScreen.domainheader)
                    }
                    _initDomainList()
                    _bindEvents()
                    $domainContainer.empty().append($domainScreen)
                    return $domainContainer
                })
                .catch(err => {
                    console.log('Error', err)
                })
        },
    }

    root.DomainUI = that
})(html.widget)
