(function () {
    ee.editors.core = {
        _editorsTypeName: ["Raw HTML", "Drag&Drop", "Landing Page"],
        _defaultTemplate: { templateid: 0, templatetype: undefined, name: "New Template", subject: "", fromemail: "", fromname: "", bodytext: "", bodyhtml: "", bodyamp: "", css: "", templatescope: 0, ispublic: false, isdirty: false, contentLoaded: true, originaltemplateid: 0 },
        _loadColorPicker: function (callback) {
            if (typeof ee.frame.spectrum === "function") {
                return true;
            }
            $.loadCssFile(ee.tools.getLibPath() + 'lib/colorpicker.css?version=' + ee.libVersion.colorpicker, function () {
                $.cachedScript(ee.tools.getLibPath() + "lib/colorpicker.js?version=" + ee.libVersion.colorpicker, {
                    success: function () {
                        if (callback) { callback() } else { ee.view.show(); }
                    }
                });
            });
            return false;
        },
        _templateTagsFunction: () => {
            if (location.hash.includes('#/campaigncreator')) {
                $('#tagInTemplate').closest('.iconsintemplatemenu').hide();
            }
            const showTemplateTags = () => {
                let i;
                let tags = ee.data.selectedtemplate.tags;
                $('#templateTags').find('span').remove();
                for (i = 0; i < tags.length; i++) {
                    let tag = tags[i];
                    $('#templateTags').append($('<span>', {
                        value: tag,
                        text: tag,
                        class: 'elem_tags deleteicon',
                        id: 'deleteTag'+ i,
                    }));

                }
            };
            const deleteTemplateTags = () => {
                $('.deleteicon').on('click', (e) => {
                    let idNumber = ee.data.selectedtemplate.tags.indexOf(e.target.textContent);
                    let indexLength = 1;
                    
                    ee.data.selectedtemplate.tags.splice(idNumber, indexLength);
                    e.target.remove();
                })
            }

            if (!ee.data.selectedtemplate.tags) {
                ee.data.selectedtemplate.tags = [];
            }
            
            $('#tagInTemplate').on('click', () => {
                const template = ee.data.selectedtemplate;
                const win = html.get("TemplateTags", { template: template, landingpage: ee.data.selectedtemplate.templatetype === 2 });
                let title;
                if (location.hash.includes('landingpages')) {
                    title = ee.t.managelandingtags;
                } else {
                    title = ee.t.managetags;
                }
                const modal = html.modal.create(win, {
                    title: title,
                    size: "emodal-md",
                    buttons: [{
                        name: ee.t.save,
                        css: "btn_lg btn_primary",
                        callback: () => {
                            let newtag = $('#addtag').val();
                            if (!ee.data.selectedtemplate.tags.includes(newtag) && (newtag != '')) {
                                ee.data.selectedtemplate.tags.push(newtag);
                            }
                            let i;
                            let tags = ee.data.selectedtemplate.tags;
                            let newTagsToAdd = [];
                            
                            let templatetags;
                            if (ee.data.selectedtemplate.templatetype === 2) {
                                templatetags = ee.data.landingpagetags;
                            } else {
                                templatetags = ee.data.templatetags;
                            }
                            newTagsToAdd = tags.filter((item) => {
                                if (!templatetags.data.some(tag => tag.name === item)){
                                    return true;
                                }
                                return false;
                            });
                            newTagsToAdd.forEach((tag)=>{
                                templatetags.data.push({name:tag});
                            })
                            
                            

                            let tagsToAddCount = newTagsToAdd.length;
                            if (tagsToAddCount > 0) {
                                let promises = [];
                                newTagsToAdd.forEach(element => {
                                    let promise;
                                    if (ee.data.selectedtemplate.templatetype === 2) {
                                        query = {tag: element, type: 1};
                                    } else {
                                        query = {tag: element, type: 0};
                                    }
                                    
                                    promise = (EE_API.Template.AddTag(query)
                                    .then((response)=>{
                                        const data = response;
                                        ee.data.addedtag = element;
                                    }))
                                    
                                    promises.push(promise);
                                });
                                Promise.all(promises).then(() => {
                                    ee.api.saveTemplate(ee.data.selectedtemplate, () => {}, false); 
                                    modal.close();
                                });
                            } else {
                                ee.api.saveTemplate(ee.data.selectedtemplate, () => {}, false); 
                                modal.close();
                            }

                        }
                    }]
                });
                let autocompletetags = [];
                let tags;
                if (ee.data.selectedtemplate.templatetype === 2) {
                    tags = ee.data.landingpagetags.data;
                } else {
                    tags = ee.data.templatetags.data;
                }
                
                for (i = 0; i < tags.length; i++) {
                    let tag = tags[i].name;
                    autocompletetags.push(tag)
                }
                //AUTOCOMPLETE FUNCTIONS
                function autocomplete(input, arr) {
                    /*the autocomplete function takes two arguments,
                    the text field element and an array of possible autocompleted values:*/
                    let currentFocus;
                    /*execute a function when someone writes in the text field:*/
                    input.addEventListener("input", function(e) {
                        let divWithItems;
                        let divMatchingItems;
                        let i;
                        let val = this.value;
                        /*close any already open lists of autocompleted values*/
                        closeAllLists();
                        if (!val) { return false;}
                        currentFocus = -1;
                        /*create a DIV element that will contain the items (values):*/
                        divWithItems = document.createElement("DIV");
                        divWithItems.setAttribute("id", this.id + "autocomplete-list");
                        divWithItems.setAttribute("class", "autocomplete-items");
                        /*append the DIV element as a child of the autocomplete container:*/
                        this.parentNode.appendChild(divWithItems);
                        /*for each item in the array...*/
                        for (i = 0; i < arr.length; i++) {
                            /*check if the item starts with the same letters as the text field value:*/
                            if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                            /*create a DIV element for each matching element:*/
                            divMatchingItems = document.createElement("DIV");
                            /*make the matching letters bold:*/
                            divMatchingItems.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                            divMatchingItems.innerHTML += arr[i].substr(val.length);
                            /*insert a input field that will hold the current array item's value:*/
                            divMatchingItems.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                            /*execute a function when someone clicks on the item value (DIV element):*/
                            divMatchingItems.addEventListener("click", function(e) {
                                /*insert the value for the autocomplete text field:*/
                                input.value = this.getElementsByTagName("input")[0].value;
                                /*close the list of autocompleted values,
                                (or any other open lists of autocompleted values:*/
                                closeAllLists();
                            });
                            divWithItems.appendChild(divMatchingItems);
                            }
                        }
                    });
                    /*execute a function presses a key on the keyboard:*/
                    input.addEventListener("keydown", function(e) {
                        let autocompletelistItem = document.getElementById(this.id + "autocomplete-list");
                        if (autocompletelistItem) autocompletelistItem = autocompletelistItem.getElementsByTagName("div");
                        if (e.keyCode == 40) {
                            /*If the arrow DOWN key is pressed,
                            increase the currentFocus variable:*/
                            currentFocus++;
                            /*and and make the current item more visible:*/
                            addActive(autocompletelistItem);
                        } else if (e.keyCode == 38) { //up
                            /*If the arrow UP key is pressed,
                            decrease the currentFocus variable:*/
                            currentFocus--;
                            /*and and make the current item more visible:*/
                            addActive(autocompletelistItem);
                        } else if (e.keyCode == 13) {
                            if (currentFocus > -1) {
                            /*and simulate a click on the "active" item:*/
                            if (autocompletelistItem) autocompletelistItem[currentFocus].click();
                            }
                        }
                    });
                    function addActive(autocompletelistItem) {
                        /*a function to classify an item as "active":*/
                        if (!autocompletelistItem) return false;
                        /*start by removing the "active" class on all items:*/
                        removeActive(autocompletelistItem);
                        if (currentFocus >= autocompletelistItem.length) currentFocus = 0;
                        if (currentFocus < 0) currentFocus = (autocompletelistItem.length - 1);
                        /*add class "autocomplete-active":*/
                        autocompletelistItem[currentFocus].classList.add("autocomplete-active");
                    }
                    function removeActive(autocompletelistItem) {
                        /*a function to remove the "active" class from all autocomplete items:*/
                        let i;
                        for (i = 0; i < autocompletelistItem.length; i++) {
                            autocompletelistItem[i].classList.remove("autocomplete-active");
                        }
                    }
                    function closeAllLists(element) {
                        /*close all autocomplete lists in the document,
                        except the one passed as an argument:*/
                        let autocompletelistItems = document.getElementsByClassName("autocomplete-items");
                        let i;
                        for (i = 0; i < autocompletelistItems.length; i++) {
                            if (element != autocompletelistItems[i] && element != input) {
                                autocompletelistItems[i].parentNode.removeChild(autocompletelistItems[i]);
                            }
                        }
                    }
                    /*execute a function when someone clicks in the document:*/
                    document.addEventListener("click", function (e) {
                        closeAllLists(e.target);
                    });
                }
                //END OF AUTOCOMPLETE
                const addNewTag = () => {
                    let newtag = $('#addtag').val();
                    if (!ee.data.selectedtemplate.tags.includes(newtag)) {
                        if (newtag === '') {
                            return
                        }
                        
                        ee.data.selectedtemplate.tags.push(newtag);
                    } else {
                        $(() => {
                            let selectedTag = $('#templateTags').find(`span[value='${newtag}']`);
                            selectedTag.addClass('alreadytag');

                        setTimeout(() => {
                            selectedTag.removeClass('alreadytag');
                            selectedTag.addClass('tagtransition');
                        }, 190)
                        })
                    }
                        
                    showTemplateTags();
                    deleteTemplateTags();	
                    $('#addtag').val('');
                }

                $('#addtag').on("keypress", (event) => {
                    let keycode = (event.keyCode ? event.keyCode : event.which);
                    if (keycode == '44') {
                        event.preventDefault();
                    }
                    if(keycode == '13' || keycode == '44') {
                        addNewTag();
                    }
                    autocomplete(document.getElementById("addtag"), autocompletetags);
                });
                $('#addtag-btn').on('click', () => {
                    addNewTag();
                });
                showTemplateTags();
                deleteTemplateTags();
            })
        },

        _showBodyText: function (templateid, callback) {
            if (!ee.data.selectedtemplate && !templateid) {
                console.log("ERROR: not selected template");
                return;
            }
            //Note:check this
            if (templateid > 0 || (ee.data.selectedtemplate && ee.data.selectedtemplate.contentLoaded === false)) {
                ee.views.templates.setSelectedTemplate(templateid || ee.data.selectedtemplate.templateid, false, function () {
                    ee.editors.core._showBodyText(false, callback);
                });
                return;
            }
            var selected = ee.data.selectedtemplate;
            var win = html.get("BodyText", selected),
                modal = html.modal.create(win, {
                    title: ee.t.bodytextonly, size: "emodal-lg", buttons: [
                        {
                            name: ee.t.savetextbody,
                            css: 'btn_primary btn_lg',
                            callback: function () {
                                var tags = /(<([^>]+)>)/gi;
                                ee.data.selectedtemplate.bodytext = win.bodytext.val().replace(tags, '');
                                ee.api.saveTemplate(ee.data.selectedtemplate, function () { modal.close(); }, false);

                            }
                        }]
                });
        },
        _publishLandingPage: (templateid, callback) => {
            if (!ee.data.selectedtemplate && !templateid) {
                console.error("ERROR: not selected template");
                return;
            }
            if (templateid > 0 || (ee.data.selectedtemplate && ee.data.selectedtemplate.contentLoaded === false)) {
                ee.views.templates.setSelectedTemplate(templateid || ee.data.selectedtemplate.templateid, false, function () {
                    ee.editors.core._publishLandingPage(false, callback);
                });
                return;
            }
            const selected = ee.data.selectedtemplate;
            const win = html.get("PublishlandingPage", selected);
            const modal = html.modal.create(win, {
                    title: ee.t.publish, size: "emodal-md", buttons: [
                        {
                            name: ee.t.downloadhtml,
                            css: 'btn_primary btn_lg',
                            callback: function (button) {
                                let templateid = ee.data.selectedtemplate.templateid;
                                let query = {templateid: templateid}
                                
                                return EE_API.Template.DownloadTemplate(query)
                                .then ((data) => {
                                    let blob = new Blob([data]);

                                    let templatename = ee.data.selectedtemplate.name;
                                    let fileName = `${templatename}.html`;
                                    let link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    link.download = fileName;
                                    link.click();
                                })
                                .catch((err) => {
                                    console.log({err})
                                })
                            }
                        },
                        {
                            name: ee.t.view,
                            css: 'btn_primary btn_lg',
                            callback: function () {
                                let linkToOpen = $('#templateLink').val();
                                window.open(linkToOpen, '_blank');
                            }
                            
                        }]
                });
        },
        _showBodyAmp: function (templateid, callback) {
            if (!ee.data.selectedtemplate && !templateid) {
                console.log("ERROR: not selected template");
                return;
            }
            //Note:check this
            if (templateid > 0 || (ee.data.selectedtemplate && ee.data.selectedtemplate.contentLoaded === false)) {
                ee.views.templates.setSelectedTemplate(templateid || ee.data.selectedtemplate.templateid, false, function () {
                    ee.editors.core._showBodyAmp(false, callback);
                });
                return;
            }
            var selected = ee.data.selectedtemplate;
            var win = html.get("BodyAmp", selected),
                modal = html.modal.create(win, {
                    title: ee.t.bodyamponly, size: "emodal-lg", buttons: [
                        {
                            name: ee.t.saveampbody,
                            css: 'btn_primary btn_lg',
                            callback: function () {
                                ee.indiOn();
                                ee.data.selectedtemplate.bodyamp = win.bodyamp.val();
                                ee.api.saveTemplate(ee.data.selectedtemplate, function () { modal.close(); }, false);
                            }
                        }]
                });
        },
        _dirty: function ($screen, action) {
            var saveButtons = $screen.find('button[data-trigger=esave]');
            if (saveButtons.length === 0) return;
            saveButtons.removeClass("disabled");
            saveButtons.off().click(action);
            ee.data.selectedtemplate.isdirty = true;
        },
        _disableDirty: function ($screen) {
            var saveButtons = $screen.find('button[data-trigger=esave]');
            if (saveButtons.length === 0) return;
            saveButtons.addClass("disabled");
            saveButtons.off();
            ee.data.selectedtemplate.isdirty = false;
        },
        _XSSProtectHtml: function (bodyhtml) {
            //                                  //Remove Event atribute                  //Remove Scripts
            if (ee.data.selectedtemplate.templatetype === 2) {
                return bodyhtml.replace(/ on\w+=(("[^"]*")|'[^']*')/ig, '');
            } else {
                return bodyhtml.replace(/ on\w+=(("[^"]*")|'[^']*')/ig, '').replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

            }
        },
        _mobileView: function ($screen) {
            var editor = this, win;
            //Mobile Previow
            if ($screen.moible) {
                $screen.moible.click(function () {
                    if ($screen.moible.hasClass("active")) { return; }
                    win = html.get("MobilePreview", {});
                    win.appendTo($screen.mobilepreview);
                    win.previewframe.on('load', function () {
                        win.previewframe[0].contentWindow.document.body.style.margin = '0px';
                        window.scrollTo(0, 0); 
                    });
                    var doc = win.previewframe[0].contentWindow.document;
                    doc.open();
                    doc.write(editor.getTemplateContent().bodyhtml);
                    doc.close();
                    $screen.workspace.animate({ "opacity": 0 });
                    $screen.mobilepreview.animate({ "opacity": 1 });
                    $screen.workspace.css("height", "775px");
                    $('#editorUndo').addClass('disabled');
                    $('#editorRedo').addClass('disabled');
                    $('#emoible').hide();
                    $('#edesktop').show();
                });
            }
            //Desktop Preview
            if ($screen.desktop) {
                $screen.desktop.click(function () {
                    $screen.workspace.animate({ "opacity": 1 });
                    $screen.mobilepreview.animate({ "opacity": 0 });
                    $screen.workspace.css("height", "");
                    $screen.mobilepreview.empty();
                    win = null;
                    $('#emoible').show();
                    $('#edesktop').hide();
                });
            }
        },
        _showSendTest: function () {
            var editor = this;
            var storageOBJ = ( !(_.isNil(window.localStorage)) && window.localStorage['SendTestEmail']) ? JSON.parse(window.localStorage['SendTestEmail']) : {};
            var email = ee.data.account.email;
            var to = "";
            var fromemail = ee.data.selectedtemplate.fromemail || email;
            var firstname = ee.data.account.firstname;
            var lastname = ee.data.account.lastname;

            if (ee.data.selectedtemplate.isdirty) {
               ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $('#dtemplateframe'))
            }
            if (!ee.data.account.accountaccesslevel.includes('SendHttp')) {
                html.modal.info(ee.t.sendtestwithoutaccesslevel, ee.t.warning);
                return;
            }
            if (ee.data.selectedtemplate.fromname) {
                fromemail = '"' + ee.data.selectedtemplate.fromname + '" <' + fromemail + '>';
            }
            if (!ee.data.selectedtemplate.subject) ee.data.selectedtemplate.subject = ee.data.selectedtemplate.name;

            if (storageOBJ.to) {
                to = storageOBJ.to
            } else if (firstname && lastname) {
                to = '"' + firstname + ' ' + lastname + '" ' + '<' + email + '>';
            }

            var win = html.get("SendTest", {fromemail: fromemail, subject: ee.data.selectedtemplate.subject, to: to});
            html.widget.SelectFromEmail(win.fromemail, fromemail, true).then(function () {
                var modal = html.modal.create(win, {
                    title: ee.t.sendtestemail, buttons: [{
                        name: ee.t.send,
                        title: ee.t.ttsendtestnow,
                        css: 'btn_primary btn_lg',
                        callback: function () {
                            ee.indiOn();
                            const template = ee.data.selectedtemplate;
                            const data = {
                                to: win.toemail.val(),
                                from: win.find('input[name=fromemail]').val(),
                                subject: win.subject.val(),
                                bodyhtml: editor.getTemplateContent().bodyhtml,
                                bodyamp: template?.bodyamp,
                                fromdraft: true,
                                channel: "Test Mail"
                            };
                            if (template.bodytext && template.bodytext.length > 0) {
                                data.bodytxt = template.bodytext;
                            }

                            const parsedEmails = (win.toemail.val().split(';')).map(email => {
                                if (/<.+>/.test(email)) {
                                    const firstBracket = email?.indexOf('<');
                                    const lastBracket = email?.indexOf('>');
                                    return email?.slice?.(firstBracket+1,lastBracket);
                                }
                                return _.trim(email);
                            });
                            const emails = parsedEmails.filter(email => email);
                            const isEmailValid = _.every(emails, email => ee.tools.isEmail(email));

                            if (isEmailValid) {
                                const contactsToLoad = [];
                                const invalidContacts = [];
                                emails.forEach(email => {
                                    contactsToLoad.push(EE_API.Contact.LoadContact({ email }, true).then((resp) => {
                                        const { status } = resp;
                                        if (status > 0) {
                                            invalidContacts.push(email);
                                        }
                                    }).catch((rej) => {
                                        if (!rej.error.includes('Could not find contact:')) {
                                            console.error(rej.error);
                                        }
                                        invalidContacts.push(email);
                                    }));
                                });

                                Promise.all(contactsToLoad).then(() => {
                                    if (_.isEmpty(invalidContacts) || !ee.data.account?.billingdetails?.activeplan?.typedesc?.includes("ContactSubscription")) {
                                        ee.api.send(data);
                                        if(!_.isNil(window.localStorage)) {
                                            const localData = { to: data.to }
                                            store.local.set('SendTestEmail', localData);
                                        };
                                        modal.close();
                                    } else {
                                        ee.indiOff();
                                        win.find("#ecannotsend").show();
                                        win.find("#ehelpinfo").hide();
                                        setErrorOnToInput();
                                    }
                                });
                            } else {
                                ee.indiOff();
                                win.find("#eemailinvalid").show();
                                win.find("#ehelpinfo").hide();
                                setErrorOnToInput();
                            }
                        }
                    }]
                });
            });
            win.find("input#efromemail").prop('disabled', true);
            win.on('keyup', "input#etoemail", function () {
                $("#eemailinvalid").hide();
                $("#ecannotsend").hide();
                $("#ehelpinfo").show();
                $(this).css('border', '0.1rem solid #CFD6DB');
            });
            const setErrorOnToInput = function () {
                win.find("input#etoemail").addClass("has-error").css('border', '2px solid #e80229');
            }
        },
        _showMergeFields: function (callback) {

            var ContactFields = dataModel.create("ContactFields");

            $.when(ContactFields.require()).then(function () {
                const isCustomBranding = ee.data.account.privatebrandingurl && ee.data.account.issub;
                let mergecontent = html.get("MergeFields", { list: (!!ee.data.availablefields) ? ee.data.availablefields.getAll() : [], isCustomBranding: isCustomBranding });
                let modal = html.modal.create(mergecontent, { title: ee.t.insertmergefields, alignToScreenHeight: true, cancel: { title: ee.t.close } });
                    $('.h2-mergefield').on('click', (event) => {
                        $(event.currentTarget.nextSibling).slideToggle('fast', 'linear');
                        $('.mergefields').not(event.currentTarget.nextSibling).css('display', 'none');
                        if ($('.icondropdown-mergefield').not(event.currentTarget.lastChild).hasClass('fa-sort-up')) {
                            $('.icondropdown-mergefield').not(event.currentTarget.lastChild).removeClass('fa-sort-up').addClass('fa-sort-down');
                        }
                        
                        $(event.currentTarget.lastChild).toggleClass('fa-sort-up', 'fa-sort-down');
                        $(event.currentTarget.lastChild).toggleClass('fa-sort-down', 'fa-sort-up');
                    })
                mergecontent.on("click", '.mergefields li', function () {
                    let ob = $(this);
                    let insert = this.id;
                    if (ob.data("tag") === "ahref") {
                        insert = `<a href='{${ob.html()}}'>${ob.data("name")}</a>`;
                    }
                    if (callback) {
                        callback(insert);
                    }
                    modal.close();
                });
            });
        },
        _replaceAllRgbToHex: function (html) {
            return html.replace(/\brgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g, function ($0, $1, $2, $3) {
                return "#" + ("0" + Number($1).toString(16)).substr(-2) + ("0" + Number($2).toString(16)).substr(-2) + ("0" + Number($3).toString(16)).substr(-2);
            });
        },
        _collectDefaultOptions: () => {
            //Set Scope
            var $screen = ee.editor.options.screenHandle || ee.editor.screen,
                query = $screen.elasticCollectForm();

            if (!query.name) query.name = query.subject;

            ee.data.selectedtemplate.name = _.trim(ee.tools.StringRemoveEmoji(_.trim(query.name).substr(0, 100))); //80 chars in inputs, 20 chars for random/date identifiers
            
        },

        autosave: function ($target, autoSaveNewTemplateCallback) {
            var editor = (ee.editor.core) ? ee.editor.core : ee.editor;
            var lastSavedTemplate = { bodyhtml: '', css: '' };
            var newTemplateCallback = autoSaveNewTemplateCallback;
            var lastTimeAutoSave = null;
            var templateSnapshots = false;
            var status = 'free';
            var isSaving = false;
            var interval = 3000;//Check changes on template
            var timerRefreshInterval = 500;
            var saveTimeoutHandler = 0;
            var timerTimeoutHandler = 0;
            let autoSaveId = null;

                //$btnTemplate = html.get('AutoSaveButton', {}),
            var _compare = () => { 
                if (!ee.data.selectedtemplate) return; 
                    if (!lastSavedTemplate.bodyhtml.length > 0) { return true; }

                    const newTemplate = editor.getTemplateContent();
                    if (_.isEqual(newTemplate, lastSavedTemplate)) { return true; }
                        
                    isSaving = true;
                    status = 'pending';
                    //$btnTemplate.restoresnapshot.attr('disabled', true);
                    lastSavedTemplate = newTemplate;
                    return false;
                },
                _saveSnapshot = function () {
                    var snapshot = {
                        templateName: "Snapshot_" + ee.data.selectedtemplate.templateid + "_" + ee.api.formatDate(new Date(), "yymmddhhiiss"),
                        bodyHtml: lastSavedTemplate.bodyhtml,
                        css: lastSavedTemplate.css,
                        bodyText: '',
                        templateType: ee.data.selectedtemplate.templatetype,
                        originalTemplateID: ee.data.selectedtemplate.templateid
                    }
                    ee.api.saveTemplateSnapshot(snapshot, function () {
                        lastTimeAutoSave = new Date().getTime();
                        _printTime();
                        status = 'free';
                        // if ($btnTemplate && $btnTemplate.restoresnapshot) {
                        //     $btnTemplate.restoresnapshot.attr('disabled', false);
                        // }
                    });
                },
                _saveNewTemplate = function () {
                    ee.editor.save(function (result) {
                        lastTimeAutoSave = new Date().getTime();
                        // if ($btnTemplate && $btnTemplate.restoresnapshot) {
                        //     $btnTemplate.restoresnapshot.attr('disabled', false);
                        // }
                        status = 'free';
                        if (newTemplateCallback) newTemplateCallback(result);
                    }, true);
                },
                _loadSnapshots = function () {
                    ee.api.loadTemplateSnapshots(ee.data.selectedtemplate.templateid, function (data) {
                        ee.data.templateSnapshots = new Collection.Data(data);
                        ee.data.templateSnapshots.each(function () {
                            this.contentLoaded = true;
                            this.screenshotpath = ee.api.path + "/userfile" + this.screenshot;
                            this.dateformated = ee.tools.time(this.dateadded).fromUTC("L");
                            this.timeformated = ee.tools.time(this.dateadded).fromUTC("LTS");
                        });
                        that.showSnapshots();
                    });
                },
                _printTime = function () {
                    var tmpTime = ~~((new Date().getTime() - lastTimeAutoSave) / 1000);
                    var interval = timerRefreshInterval;
                    var timeWrite = tmpTime + 's';

                    if (tmpTime > 180) {
                        tmpTime = ~~(tmpTime / 60);
                        interval = 3000;
                        timeWrite = tmpTime + 'm';
                    }

                    //$btnTemplate.snapshottimer.html(ee.t.autosave + ' ' + timeWrite + ' ' + ee.t.ago).removeClass('hidden');
                    clearTimeout(timerTimeoutHandler);
                    timerTimeoutHandler = setTimeout(_printTime, interval);
                },
                that = {
                    start: function () {
                        clearTimeout(timerTimeoutHandler);
                        clearTimeout(saveTimeoutHandler);
                        
                        lastSavedTemplate = editor.getTemplateContent();
                        status = 'free';
                        lastTimeAutoSave = Date.now();
                        ee.data.templateSnapshots = false;
                        //$btnTemplate.snapshottimer.html('').addClass('hidden');
                        if (ee.data.selectedtemplate.templateid != 0) { 
                            saveTimeoutHandler = setTimeout(that.createSnapshot, interval);
                        } else {
                            if (!location.hash.includes('#/campaigncreator')) { return _saveNewTemplate(); }
                            ee.views.campaigncreator.saveDraftCampaign();
                            saveTimeoutHandler = setTimeout(that.createSnapshot, interval);
                        }
                    },
                    stop: function () {
                        clearTimeout(saveTimeoutHandler);
                        clearTimeout(timerTimeoutHandler);
                        status = 'free';
                    },
                    updateTemplate: () => {
                        if (!ee.data.selectedtemplate || !ee.editor.core || !($('.template__name-input').val())) { return; }

                        _saveSnapshot();

                        var template = ee.editor.core.getTemplateContent();
                        ee.editor.core._collectDefaultOptions();
                        ee.data.selectedtemplate.css = template.css;
                        ee.data.selectedtemplate.bodyhtml = ee.editor.core._replaceAllRgbToHex('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' + ee.editor.core._XSSProtectHtml(template.bodyhtml));
                        template = null;
                        ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $("#dtemplateframe"))
                        .then(templateid => {
                            isSaving = false;
                        });
                    },
                    createSnapshot: function () {
                    if (!ee.data.selectedtemplate || !ee.editor) return;
                        clearTimeout(saveTimeoutHandler)
                        if (ee.data.selectedtemplate.templateid <= 0) {
                            setTimeout(that.createSnapshot, interval);
                            return _saveNewTemplate(); 
                        }
                    
                        if (!_compare() || $('#ename').hasClass('tittlechanged')) {
                            $('#ename').removeClass('tittlechanged');
                            that.updateTemplate();
                        } else {
                            status = 'free';
                        }

                            setTimeout(that.createSnapshot, interval);
                        
                        
                    },
                    showSnapshots: function () {
                        if (!ee.data.templateSnapshots) {
                            _loadSnapshots();
                            return;
                        }
                        // var $modalContent = html.get("ShowSnapshots", { template: ee.data.selectedtemplate, list: ee.data.templateSnapshots.getAll(), isEmpty: (ee.data.templateSnapshots.data.length > 0 ? false : true) }),
                        //     modal = html.modal.create($modalContent, {
                        //         title: ee.t.restoreautosave,
                        //         width: "72rem",
                        //         onClose: function () {
                        //             ee.data.templateSnapshots = false;
                        //         }
                        //     });
                        //Modal events
                        // if ($modalContent.snapshotslist) {
                        //     var $previewItems = $modalContent.snapshotspreview.find('.wraper');
                        //     //Click event on list of snapshot to generate preview
                        //     $modalContent.snapshotslist.on('click', '.snapshots-list-item', function (e) {
                        //         e.stopPropagation();
                        //         var $this = $(this);
                        //         $previewItems.css('display', 'none');
                        //         var $img = $previewItems.filter("[data-templateid=" + $this.data('target') + "]");
                        //         $img.css('display', 'block');
                        //         html.widget.TemplateThumbnail.init($img, {
                        //             target: '.snapshots-preview-item',
                        //             quality: 5
                        //         });
                        //     });
                        //     //Restore snapshot
                        //     $modalContent.snapshotslist.on('click', '.btn[data-action=snapchotrestored]', function (e) {
                        //         e.stopPropagation();
                        //         var template = ee.views.templates.getTemplate($(this).closest('.snapshots-list-item').data('target'), 'snapshot');
                        //         ee.data.selectedtemplate.bodyhtml = template.bodyhtml;
                        //         ee.data.selectedtemplate.css = template.css;
                        //         ee.data.selectedtemplate.isdirty = true;
                        //         ee.editor.reload();
                        //         modal.close();
                        //     });
                        //     $modalContent.snapshotslist.find('.snapshots-list-item:first').click();
                        // }
                    }
                };
            //Init button to restore snapshot
            // $target.html($btnTemplate);
            // $btnTemplate.restoresnapshot.click(function () {
            //     that.showSnapshots();
            // });
            return that;
        }
    };
})();
