ee.editors["etemplate"] = (function eTemplate() {
    /*-- Returned object of view class--*/
    var that = {}, _autoSaveWidget = false,
        /*-- Private methods --*/
        setOptions = function ($editorHandle, options) {
            ee.editor.options = $.extend({}, ee.editor.defaultOptions, options);
            ee.editor.options.dframeminwidth = ee.editor.options.workSpaceMaxWidth + 234;
            ee.editor.frame = $editorHandle || ee.frame.content;
        },
        disabledButtonsUndoRedo = function () {
            $('#editorUndo').addClass('disabled');
            $('#editorRedo').addClass('disabled');
        },
        //Note: deprecated
        isDirty = function () {
            disabledButtonsUndoRedo();
            return false;
            if (typeof ee.editor.options.isDirty === "function") {
                ee.editor.options.isDirty();
                return;
            }
            if ($(ee.editor.screen.save[0]).hasClass("disabled")) {
                ee.editors.core._dirty(ee.editor.screen, function () { ee.editor.save(); });
            }
        },
        initEditorLayout = function () {
            var screen = ee.editor.screen;
            $('#hidelayout').on('click', function () {
                ee.editor.core.toggleLayoutBorder();
                $('#hidelayout').toggleClass('clickedgridlayout');
            });
            //body background
            $('#backgroundcolortemplate').html(screen.backgroundcolor.val(ee.editor.core.layoutStruct.cssStyle["#dbody"]['background-color']).val());
            $('#eworkspace').css('background-color',screen.backgroundcolor.val(ee.editor.core.layoutStruct.cssStyle["#dbody"]['background-color']).val());
            screen.backgroundcolor.val(ee.editor.core.layoutStruct.cssStyle["#dbody"]['background-color']).spectrum(that.spectrumOptions).change(function (event, color) {
                if ($.type(color) === "null") {
                    ee.editor.core.setStyle("#dbody", "background-color", "");
                } else {
                    if ($('#backgroundImage')[0].value) {
                        ee.editor.core.setStyle("#dbody", "background-color", "");
                    } else {
                        ee.editor.core.setStyle("#dbody", "background-color", color.toString());
                        $("#eworkspace").css("background-color", color.toString());
                        $('#backgroundcolortemplate').html(color.toString())
                    }
                }
            });
            const backgroundImageUrl = $('#backgroundImage')[0].value;
            if (backgroundImageUrl) {
                ee.editor.core.body.css('background', `url(${backgroundImageUrl})`);
                $("#eworkspace").css('background', `url(${backgroundImageUrl})`);
            }
            $('#deleteBackgroundImage').on('click', () => {
                if ($('#backgroundImage')[0].value) {
                    $('#backgroundImage')[0].value = '';
                    const backgroundColor = $('#ebackgroundcolor')[0].value;
                    ee.editor.core.body.css('background', `${backgroundColor}`);
                    ee.editor.core.setStyle("#dbody", "background-color", backgroundColor);
                    $("#eworkspace").css('background', ``);
                    if (backgroundColor) {
                        $("#eworkspace").css('background-color', `${backgroundColor}`);
                    } else {
                         $("#eworkspace").css('background-color','rgb(228, 230, 236)');
                    }
                    
                }
            })

            $('#openMediaManager').on('click', () => {
                var $win = html.get("ButtonLink", { url: $('#backgroundImage').attr("href") });
                var $el = $win.link;

                function changeImageBackground(url, $el) {
                    
                        ee.editor.core.setStyle("#dbody", "background-color", '');
                        ee.editor.core.body.css('background', `url(${url})`);
                        $("#eworkspace").css('background', `url(${url})`);
                        document.getElementById('backgroundImage').value = url;
                        html.widget.FileManagerUpload.callbackFiles(url, $el);

                }

                window.openMediaManager(oldMediaManager, $el, changeImageBackground, 'images');
            
                function oldMediaManager() {
                    html.widget.FileManager.init(function (url, listname) {
                        changeImageBackground(url, $el)
                    }, "images");
                }
            
            })
            //BIND LAYOUT DROP ITEMS

            $(".ddropitem").each(function () {
                var div = $(this);
                div.attr("draggable", "true");
                div.on("dragstart", function (e) {
                    ee.editor.core.disableTools();
                    ee.editor.core.expandDropAreaToggle();
                    //store the drag object
                    ee.editor.core.dragobj = { mockup: $(this).data('mockup').split("|") };
                    //firefox requires this
                    // if (!navigator.userAgent.match(/msie/i)) {
                    if (ee.tools.isFF()) {
                        e.originalEvent.dataTransfer.setData('text/plain', 'Only required to start drag on ff.');
                    }
                    return true;
                    //  }
                }).on("dragend", function (e) {
                    if (ee.editor.core.isExpanded === true) {
                        ee.editor.core.expandDropAreaToggle();
                    }
                    if (ee.editor.core.dragobj !== null) {
                        ee.editor.core.dragobj = null
                        return false;
                    }

                });
            });
        },
        initEditorDesign = function () {
            var screen = ee.editor.screen,
                layoutStruct = ee.editor.core.layoutStruct;
            var topMarginEditor = $('#etopmargin').val();
            //MAIN PAGE STYLE
            //page width
            $("#valueofmessagewidth").html(screen.bodywidth.val(parseInt(layoutStruct.cssStyle[".layer_1"]["max-width"])).val() + ' px')
            screen.bodywidth.val(parseInt(layoutStruct.cssStyle[".layer_1"]["max-width"])).change(function (e, method, skipbuffer) {
                if (!skipbuffer) {
                    ee.editor.core.bufferGlobal.set(method, { input: screen.bodywidth, type: "style", value: parseInt(layoutStruct.cssStyle[".layer_1"]["max-width"], 10) });
                }
                var pageWidth = parseInt(this.value);
                if (ee.data.selectedtemplate.templatetype === 2) {
                    layoutStruct.maxWidth = 1024;
                }
                if (pageWidth > layoutStruct.maxWidth || pageWidth < layoutStruct.minWidth) {
                    if (ee.data.selectedtemplate.templatetype === 2) {
                        html.modal.info(ee.t.wrongwidthlanding, ee.t.warning);
                    } else {
                        html.modal.info(ee.t.wrongwidth, ee.t.warning);
                    }
                    this.value = layoutStruct.width;
                    return;
                }
                ee.editor.core.setWidth(false, pageWidth);
                ee.editor.core.isChange();
                $("#valueofmessagewidth").html(pageWidth + " px");
                if ($('.layer_2.badgemargin')){
                    $('.layer_2.badgemargin').css('max-width', pageWidth)
                }

            });
            //top margin
            $('#valueofmargintop').html(screen.topmargin.val(parseInt(layoutStruct.cssStyle["#dbody"]["padding-top"])).val() + " px");
            screen.topmargin.val(parseInt(layoutStruct.cssStyle["#dbody"]["padding-top"])).change(function (e, method) {
                ee.editor.core.bufferGlobal.set(method, { input: screen.topmargin, type: "style", value: parseInt(layoutStruct.cssStyle["#dbody"]["padding-top"], 10) });
                ee.editor.core.setPaddingTop(parseInt(this.value));
                topMarginEditor = this.value;
                $('#valueofmargintop').html(topMarginEditor + " px");
            });
            const directionSelect = $('select[name=direction]');
            if (layoutStruct.cssStyle[".edtext"]["direction"] === 'rtl') {
                directionSelect.val('rtl');
            }

            directionSelect.change(function (e, method) {
                //ee.editor.core.bufferGlobal.set(method, { input: this, type: "style", value: layoutStruct.cssStyle[".edtext"]["direction"] });
                var value = $(this).val();
                var align = (value === "rtl") ? "right" : "left";
                ee.editor.core.setStyle(".edtext", "text-align", align);
                ee.editor.core.setStyle(".edtext", "direction", value);
                ee.editor.core.isChange(true);
            })       
            
            function rangeColor(input) {
                var createDivElement = document.createElement('div');
                var preBar = document.createElement('p');
                var minValueOfInput = parseInt(input.min, 10);
                var maxValueOfInput = parseInt(input.max, 10);
                var range = maxValueOfInput - minValueOfInput;
                var getVal = function() {
                    var widthOfInput;
                    if (input.clientWidth == 0){
                        widthOfInput = parseInt(331, 10);
                    } else {
                        widthOfInput = parseInt(input.clientWidth, 10);
                    }
                    // ~~ as quicker Math.floor() operation to change it from float into integer
                    //we need to calculate what part of whole input width is entered value
                    var calculatedWidthEnteredValue = ~~(widthOfInput * (parseInt(input.value, 10) - minValueOfInput) / range);
                    return calculatedWidthEnteredValue;
                };
                createDivElement.className = 'barCnt';
                preBar.className = 'preBar';
                input.className = input.className.length ? (input.className + ' colorized') : 'colorized';
                input.parentNode.replaceChild(createDivElement, input);
                createDivElement.appendChild(input);
                createDivElement.appendChild(preBar);
                input.addEventListener('input', function() {
                preBar.style.width = getVal() + 'px';
                });
                // next line is for undo and redo buttons
                $(input).on('change', function () {
                    preBar.style.width = getVal() + 'px';
                })
                preBar.style.width = getVal() + 'px';
            }
            rangeColor(document.getElementById('etopmargin'));
            rangeColor(document.getElementById('ebodywidth'));

            //Border
            var layer1 = ee.editor.core.body.find(".layer_1:first");
            screen.mainborderwidth.val(layer1.css('border-top-width')).change(function (e, method) {
                ee.editor.core.bufferGlobal.set(method, { input: screen.mainborderwidth, type: "style", value: layer1[0].style.borderTopWidth || "0px" });
                layer1.css("border-width", $(this).val());
                ee.editor.core.layoutStruct.cssStyle[".layer_1"]["border-width"] = parseInt($(this).val()) * 2;
                ee.editor.core.setWidth(false, screen.bodywidth.val());
                screen.bodywidth.trigger("change", [undefined, true]);
            });
            screen.mainborderstyle.val(layer1.css('border-top-style')).change(function (e, method) {
                ee.editor.core.bufferGlobal.set(method, { input: screen.mainborderstyle, type: "style", value: layer1.css('border-top-style') });
                layer1.css("border-style", $(this).val());
                screen.bodywidth.trigger("change", [undefined, true]);
            });
            $('#bordercolortemplate').html(ee.editor.core.RGBToHex(screen.mainbordercolor.val(layer1.css('border-top-color')).val()))
            screen.mainbordercolor.val(layer1.css('border-top-color')).spectrum(that.spectrumOptions).change(function (event, color) {
                if ($.type(color) === "null") {
                    layer1.css("border-color", "");
                } else {
                    layer1.css("border-color", color.toString());
                    $('#bordercolortemplate').html(color.toString())
                }
            });
            //ROW STYLE
            $('#bodycolortemplate').html(screen.drow.val(ee.editor.core.layoutStruct.cssStyle[".drow"]["background-color"]).val());
            screen.drow.val(ee.editor.core.layoutStruct.cssStyle[".drow"]["background-color"]).spectrum(that.spectrumOptions).off().change(function (event, color) {
                if ($.type(color) === "null") {
                    ee.editor.core.setStyle(".drow", "background-color", "");
                } else {
                    ee.editor.core.setStyle(".drow", "background-color", color.toString());
                    $('#bodycolortemplate').html(color.toString());
                }
            });
            //CELL INNER MARGIN
            screen.cellpading.val(ee.editor.core.layoutStruct.cssStyle[".edcontent>tbody>tr>td"]?.["padding"])?.off()?.change(function (e, method) {
                ee.editor.core.bufferGlobal.set(method, { input: screen.cellpading, type: "style", value: layoutStruct.cssStyle[".edcontent>tbody>tr>td"]["padding"] });
                var value = parseInt($(this).val());
                ee.editor.core.setCellPadding(value);
            });

            //Font Size
            $('.dfontsize input').on('click',  function (e) {
                e.stopPropagation();
            });
            $('.dfontsize input').on('blur', function (e, method) {
                var $this = $(this), value = parseInt($this.val(), 10), selector = $this.closest('.dcontroller-style').data('selector');
                //ee.editor.core.bufferGlobal.set(method, { input: $(this), type: "style", value: parseInt(layoutStruct.cssStyle[selector]["font-size"], 10), trigger: 'blur' });
                if (value < 0 || isNaN(value) || value === Infinity) { value = 12; $this.val(value); }
                if (ee.editor.core.compareStyleValue(selector, 'font-size', value + 'px')) {
                    return;
                }
                ee.editor.core.setStyle(selector, 'font-size', value + "px");
                ee.editor.core.isChange();
            });
            $('.dfontsize input').on('keyup', function (e) {
                if (e.keyCode == 13) {
                    $(this).trigger('blur');
                }
            });
            $('.dfontsize li').on('click', function () {
                var $li = $(this);
                $li.closest('.ddropdownoptions').find('.dfontsizevalue:first').val($li.data('value')+'px').trigger('blur');
            });
            function hideOpenedButton () {
                if ($('#buttoncaret').hasClass('fa-caret-up')) {
                    $('#buttoncaret').toggleClass('fa-caret-up fa-caret-down');
                    $('#buttonoption').toggle();
                }
            }
            function hideOpenedLink () {
                if ($('#linkcaret').hasClass('fa-caret-up')) {
                    $('#linkcaret').toggleClass('fa-caret-up fa-caret-down');
                    $('#linkoption').toggle();
                }
            }
            function hideOpenedText () {
                if ($('#textcaret').hasClass('fa-caret-up')) {
                    $('#textcaret').toggleClass('fa-caret-up fa-caret-down');
                    $('#textoption').toggle();
                }
            }
            function hideOpenedH1 () {
                if ($('#h1caret').hasClass('fa-caret-up')) {
                    $('#h1caret').toggleClass('fa-caret-up fa-caret-down');
                    $('#h1option').toggle();
                }
            }
            function hideOpenedH2 () {
                if ($('#h2caret').hasClass('fa-caret-up')) {
                    $('#h2caret').toggleClass('fa-caret-up fa-caret-down');
                    $('#h2option').toggle();
                }
            }
            function hideOpenedH3 () {
                if ($('#h3caret').hasClass('fa-caret-up')) {
                    $('#h3caret').toggleClass('fa-caret-up fa-caret-down');
                    $('#h3option').toggle();
                }
            }
            function hideOpenedH4 () {
                if ($('#h4caret').hasClass('fa-caret-up')) {
                    $('#h4caret').toggleClass('fa-caret-up fa-caret-down');
                    $('#h4option').toggle();
                }
            }
            $('#h1changes').on('click', function () {
                $('#h1option').toggle();
                $('#h1caret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedText();
                hideOpenedLink ();
                hideOpenedButton();
                hideOpenedH2();
                hideOpenedH3();
                hideOpenedH4();
            })
            $('#h2changes').on('click', function () {
                $('#h2option').toggle();
                $('#h2caret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedText();
                hideOpenedLink ();
                hideOpenedButton();
                hideOpenedH1();
                hideOpenedH3();
                hideOpenedH4();
            })
            $('#h3changes').on('click', function () {
                $('#h3option').toggle();
                $('#h3caret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedText();
                hideOpenedLink ();
                hideOpenedButton();
                hideOpenedH1();
                hideOpenedH2();
                hideOpenedH4();
            })
            $('#h4changes').on('click', function () {
                $('#h4option').toggle();
                $('#h4caret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedText();
                hideOpenedLink ();
                hideOpenedButton();
                hideOpenedH1();
                hideOpenedH2();
                hideOpenedH3();
            })
            $('#textchanges').on('click', function () {
                $('#textoption').toggle();
                $('#textcaret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedLink ();
                hideOpenedButton();
                hideOpenedH1();
                hideOpenedH2();
                hideOpenedH3();
                hideOpenedH4();
            })

            $('#linkchanges').on('click', function () {
                $('#linkoption').toggle();
                $('#linkcaret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedText();
                hideOpenedButton();
                hideOpenedH1();
                hideOpenedH2();
                hideOpenedH3();
                hideOpenedH4();
            })
            $('#buttonchanges').on('click', function () {
                $('#buttonoption').toggle();
                $('#buttoncaret').toggleClass('fa-caret-up fa-caret-down');
                hideOpenedText();
                hideOpenedLink ();
                hideOpenedH1();
                hideOpenedH2();
                hideOpenedH3();
                hideOpenedH4();
            })
            $('#textoptionbutton').on('click', () => {
                $('#hiddenListTextButton').toggle();
            })
            $('#hiddenListTextButton').on('click', () => {
                $('#hiddenListTextButton').hide();
            })
            $('#imageOptionButton').on('click', () => {
                $('#hiddenListImageButton').toggle();
            })
            $('#hiddenListImageButton').on('click', () => {
                $('#hiddenListImageButton').toggle();
            })
            $('#buttonsOptionButton').on('click', () => {
                $("#hiddenListButtonsButton").toggle();
            })
            $('#hiddenListButtonsButton').on('click', () => {
                $("#hiddenListButtonsButton").hide();
            })
            const elementsToHide = (e) => {
                if (!e?.target?.className?.includes?.('clickedbuttons')  ) {
                    $("#hiddenListButtonsButton").hide();
                }
                if (!e?.target?.className?.includes?.('clickedimages')) {
                    $('#hiddenListImageButton').hide();
                }
                if (!e?.target?.className?.includes?.('clickedtexts')) {
                    $('#hiddenListTextButton').hide();
                }
            }
            $('#etemplateeditor').on('click', (e)=>{
                elementsToHide(e)
            })
            $(document).on('click', function (e) {
                elementsToHide(e)
            })

            ee.tools.validateEntityName(
                {
                    screen: screen,
                    emptyname: ee.t.requiredtemplatename,
                    inputname: '#ename',
                    submit: "#esave"
                }
            )

            var TemplateNameH4 = screen.find(".template-name");
            var TemplateNameText = screen.find(".js-template-name")
            var TemplateNameInput = screen.find("#ename");
            var TemplateNamePencilIcon = screen.find(".js-edit-template-name");
            TemplateNamePencilIcon.on("click", function() {
                TemplateNameH4.addClass("hidden");
                TemplateNameInput.removeClass("hidden");
                TemplateNamePencilIcon.addClass("clicked")
                TemplateNameInput.focus();
            });

            TemplateNameInput.blur(function() {
                if (!$(this).hasClass('incorrect_input_value')) {
                    TemplateNameInput.addClass("hidden");
                    TemplateNameH4.removeClass("hidden")
                    TemplateNameText.text(TemplateNameInput.val());
                }
            });


            //Undo&Redo
            disabledButtonsUndoRedo();
            $('#editorUndo').on('click', function() {
                ee.editor.core.bufferGlobal.undo();
                disabledButtonsUndoRedo();
            });
            $('#editorRedo').on('click', function() {
                ee.editor.core.bufferGlobal.redo();
                disabledButtonsUndoRedo();
            });

            ee.editor.core._templateTagsFunction();

            //Font family
            $('.dfontfamily li[data-name]').on('click',  function (e, method) {
                var $this = $(this), $btnvalue = $this.closest('.ddropdownoptions').find('.doptionsvalue'), $container = $this.closest('.dcontroller-style'), $head = ee.editor.core.body.head,
                    source = $this.data('fontsource'), name = $this.data('name'), selector = $container.data('selector');

                //Check if the current value is the same as the previous one
                if (ee.editor.core.compareStyleValue(selector, 'font-family', name)) {
                    return;
                }
                ee.editor.core.bufferGlobal.set(method, { input: $container.find('li.selected'), type: "fontfamily" });
                $container.find('li.selected').removeClass('selected');
                ee.editor.core.setStyle(selector, 'font-family', $this.data('fontfamily'));
                if (source && $head.find('link[data-name=' + name + ']').length === 0) {
                    let $link = $(`<link href="https://fonts.googleapis.com/css?family=${source}&amp;subset=cyrillic,latin-ext" data-name="${name}" rel="stylesheet" type="text/css">`);
                    $link.appendTo($head);
                }
                $this.addClass('selected');
                $btnvalue.data("name", name).html($this.html());
                ee.editor.core.isChange();
            });

            //COMMON STYLE
            $(".dcontroller-style").each(function () {
                var div = $(this),
                    selector = div.data("selector"),
                    slugSelector = selector.replace(/\s+/g, ''),
                    $fontsize = div.find(".dfontsize:first"),
                    $fontfamily = div.find(".dfontfamily:first");
                //Font size
                if ($fontsize.length === 1) {
                    var value = ee.editor.core.layoutStruct.cssStyle[slugSelector]['font-size'];
                    $fontsize.find('.dfontsizevalue').val(value);
                }
                //Fontfaimly
                if ($fontfamily.length === 1) {
                    var name = ee.editor.core.layoutStruct.cssStyle[slugSelector]['font-family'].split(',', 2)[0].replace(/'/g, "");
                    $fontfamily.find('.doptionsvalue').html(name);
                    $fontfamily.find('li[data-name=' + name.replace(/\s+/g, '_').toLowerCase() + ']').addClass('selected');
                }


            });
            //BIND COLOR EVENT
            $(".dcontroller-style .espectrum").each(function () {
                var $input = $(this), cssName = $input.data("css"), localSelector = $input.data("selector");
                if (!localSelector) { localSelector = $input.closest('.dcontroller-style').data("selector"); }
                var localSlugSeletctor = localSelector.replace(/\s+/g, '');
                $input.val(ee.editor.core.layoutStruct.cssStyle[localSlugSeletctor]?.[cssName]);
                $input.spectrum(that.spectrumOptions).off().change(function (event, color) {
                    if (!color) {
                        ee.editor.core.setStyle(localSelector, cssName, "");
                    } else {
                        ee.editor.core.setStyle(localSelector, cssName, color.toString());
                    }
                });
            });
            ee.editor.core._$SpectrumInputs = $("input.espectrum");
            ee.editor.core._$SpectrumInputs.show();
            //Some options require Tooltips
            html.reloadTooltip(screen.menusytle, true);
            html.reloadTooltip(screen.menustyles, true);
        },
        startEditor = function () {
            ee.logEvent('Template editor screen loaded');
            window?.document?.dispatchEvent?.(new CustomEvent('templateEditorCoreInitStart'));
            const productUrl = ee.productCode === 'EmailApi' ? '/api/' : '/marketing/';
            const isLandingTemplate = (ee.data.selectedtemplate.templatetype === 2);
            const landingBreadcrumbs = {
                name: ee.t.landingpages,
                url: `${ee.parameters.legacyMode ?  '/' : '#/'}${ee.Router.prevPath() || "landingpages/new"}`,
            };
            const templateBreadcrumb = {
                name: ee.t.templates,
                url: `${ee.parameters.legacyMode ?  '/' : '#/'}templates/new`,
            };

            const breadcrumb = isLandingTemplate ? landingBreadcrumbs : templateBreadcrumb;

            
            var screen = html.get("DdTemplate", {
                fontfamily: html.getraw("eListFontFamily", {}),
                fontsize: html.template("eOptionsFontSize"),
                fontsizelist: [
                    {fontsizeoption : "6" },
                    {fontsizeoption : "8" },
                    {fontsizeoption : "9" },
                    {fontsizeoption : "10" },
                    {fontsizeoption : "11" },
                    {fontsizeoption : "12" },
                    {fontsizeoption : "14" },
                    {fontsizeoption : "16" },
                    {fontsizeoption : "18" },
                    {fontsizeoption : "20" },
                    {fontsizeoption : "22" },
                    {fontsizeoption : "24" },
                    {fontsizeoption : "28" },
                    {fontsizeoption : "32" },
                    {fontsizeoption : "36" },
                    {fontsizeoption : "48" },
                    {fontsizeoption : "56" },
                    {fontsizeoption : "72" },
                    {fontsizeoption : "100" },
                    {fontsizeoption : "120" },
                    {fontsizeoption : "160" }
                ],
                account: ee.data.account,
                template: ee.data.selectedtemplate,
                options: ee.editor.options,
                isDraft: (ee.data.selectedtemplate.templatescope === 2),
                isAdmin: ee.session.can.AdminView || (ee.session.can && ee.session.can.AdminView),
                isLandingpage: (ee.data.selectedtemplate.templatetype === 2),
                isTrialBillingPlan: ee.data.account.istrialbillingplan,
                isEELogoRequired: ee.data.account.iseelogorequired,
                breadcrumb,
                legacyMode: ee.parameters.legacyMode
            });
            $(() => {
                $('.templatemenutop').closest('.container').addClass('templatecontainer');
                $(window).on('hashchange', () => {
                    if (!location.hash.includes('edit')) {
                        $('.container').removeClass('templatecontainer');
                    }

                })
            })



            var template = ee.data.selectedtemplate;

            //TODO: here editor conntent is append to container
            ee.editor.frame.append(screen);
            ee.editor.screen = screen;
            //show confirm when template is dirt
            if (ee.editor.frame.attr('id') !== 'etemplateeditor') {
                //html.frame.bindConfirm(function () { ee.editor.destroy(); }, function () { return !ee.data.selectedtemplate.isdirty });
            }

            $('#egobackbreadcrumb').on('click',function(e){
                if(!ee?.parameters?.legacyMode || !window?.parent) return;
                e.preventDefault();
                e.stopPropagation();
                const event = new CustomEvent('navigateBack', { detail: {breadcrumbUrl:breadcrumb?.url} })
                window.parent.document.dispatchEvent(event)
            });


            screen.save = screen.find("[data-trigger='esave']");
            screen.templateframe.on('load', function () {



                /*Clean Spectrum palet*/
                if (window.localStorage) {
                    delete window.localStorage[that.spectrumOptions.localStorageKey];
                }
                ee.editor.core = new ee.editor.ddeditor.init(template.bodyhtml, screen.templateframe);
                var core = ee.editor.core;

                let templateBody = template.bodyhtml.match(/<body.*?>/);
                const backgroundUrlRegexp = templateBody? templateBody[0].match(/background: url.*?>/) : null;
                if (backgroundUrlRegexp) {
                    const isBackgroundUrl = backgroundUrlRegexp[0].match(/http[s]?:\/\/(www\.)?(.*)?\/?(.)*(?=&quot)/);
                    const backgroundUrl = isBackgroundUrl ? isBackgroundUrl[0] : null;
                    if (backgroundUrl) {
                       $('#backgroundImage')[0].value = backgroundUrl; 
                    }
                }

                core.createLayoutStruct(template.css);
                if (location.hash.startsWith("#/campaigncreator")) {
                    screen.sidebar.elasticfloatingMenu(that.options.menuWindow, screen);

                    $('.eframeoverlay-close')
                        .addClass('pull-left return-button')
                        .html('<i class="fas fa-chevron-left iconstyle"></i>Back')
                        .removeClass('eframeoverlay-close pull-right')
                    ;
                    $('.frameoverlay-next').show();
                    if (ee.data.selectedChannelTemplate.templateid === -1) {
                        $('.frameoverlay-next').addClass('disabled');
                    } else {
                        $('.frameoverlay-next').removeClass('disabled');
                    }
                
                    $('.templatecontainer').css('margin-top', '10px');
                    $('#etemplateeditor').css('box-shadow', 'none');
                    $('.iconbutton')
                        .attr('id','esavedraft')
                        .removeClass('disabled');
                    $('.savedrafttemplate').css('top','9px');

                    //bootstrap data-toggle starts working only after template editor load, so I disabled it by removing attribute data-toggle
                    $('#etemplateoptions').on('click', function (e) {
                        let target = e.target;
                        if(target && target.hasAttribute('data-toggle')) {
                            $('#etemplateoptions').removeAttr('data-toggle');
                        }
                        $('#etemplateoptions').closest('.iconsintemplatemenu').toggleClass('open');
             
                    })
                    const closeDropdownMenu = function (e) {
                        var target = e.target.id;
                        if (target != 'etemplateoptions' ) {
                            $('#etemplateoptions').closest('.iconsintemplatemenu').removeClass('open');
                        }
                    }

                    $('#etemplateeditor').on('click', function (e) {
                        if ($('#etemplateoptions').closest('.iconsintemplatemenu').hasClass('open')) {
                                closeDropdownMenu(e);
                        }

                        let textTarget = e.target.closest('.dfontfamily');
                        let sizeTarget = e.target.closest('.dfontsize');
                        if ($('.dfontfamily').hasClass('open') && !textTarget) {
                            $('.dfontfamily').removeClass('open');
                        }
                        if ($('.dfontsize').hasClass('open') && !sizeTarget) {
                            $('.dfontsize').removeClass('open');
                        }
                    })
                    $('.template-tabs').on('click', function() {
                        $('.template-tabs').removeClass('active');
                        $(this).addClass('active');
                    });

                    $('.dfontfamily').on('click', function(e) {
                        let target = e.target.closest('.select-button');
                        if(target && target.hasAttribute('data-toggle')) {
                            e.target.closest('.select-button').removeAttribute('data-toggle','dropdown');
                        }

                        $('.dfontfamily').toggleClass('open');
                    })
                    $('.dfontsize').on('click', function(e) {
                        let target = e.target.closest('.btn-default');
                        if (target && target.hasAttribute('data-toggle')) {
                            e.target.closest('.btn-default').removeAttribute('data-toggle','dropdown');
                        }

                        $('.dfontsize').toggleClass('open');
                    })
                }
                if (location.hash.includes("#/campaigncreator/")) {
                    $('#dsidebar')
                        .css('top', '120px')
                        .css('margin-top', '0px');
                    screen.sidebar.elasticfloatingMenu(that.options.menuWindow, screen);
                }
                screen.floatmenu.elasticfloatingMenu(ee.frame.content_wrapper, screen);

                //Bind events to edit Layout
                ee.editor.initEditorLayout();
                //Bind events to style edit
                ee.editor.initEditorDesign();
                core.init = true;
                core.changeCallback = ee.editor.isDirty;
                //Mobile Previow
                ee.editor.core._mobileView(ee.editor.screen);

                window?.document?.dispatchEvent?.(new CustomEvent('templateEditorCoreInitEnd'));
                
                //Send Test
                if (screen.sendtest) {
                    screen.sendtest.click(function () {
                        ee.logEvent('Template editor - send test');
                        ee.editor.core._showSendTest()
                    });
                }
                $('#ename').on('change', function() {
                    $('#ename').addClass('tittlechanged')
                    window?.document?.dispatchEvent?.(new CustomEvent('templateEditorNameChanged'));
                })
                $(window).on('resize.Screen', function () { core.disableTools(); });
                //Autosave enable
                if (ee.editor.core.autosave && ee.data.selectedtemplate.templateid !== 0) {
                    _autoSaveWidget = new ee.editor.core.autosave(screen.autosave, ee.editor.options.autoSaveNewTemplateCallback);
                    _autoSaveWidget.start();
                    that.autoSaveWidget = _autoSaveWidget; //TMP FOR TEST!
                };
                if (location.hash.includes('create/') || location.hash.includes('campaigncreator') && ee.data.selectedtemplate.templateid === 0) {
                    let debouncedSave;
                    const templateName = ee.data.selectedtemplate.name;
                    
                    if (this.newTemplate){
                        clearTimeout(this.newTemplate)
                    }
                    this.newTemplate = setInterval(()=> {
                        if (ee.editor && ee.editor.core.bufferGlobal.haveUndo() || ee.data.selectedtemplate && ee.data.selectedtemplate.name !== templateName) {
                            $('#templateChanges').text(ee.t.saving);
                            clearInterval(this.newTemplate)

                            ee.data.selectedtemplate.isdefault = false;
                            ee.data.selectedtemplate.bodyhtml = ee.editor.core.getTemplateContent().bodyhtml;
                            let template = ee.data.selectedtemplate;
                            template.bodyhtml = ee.editor.core.getTemplateContent().bodyhtml;
                            let frame = $('#dtemplateframe');

                            let changedhash;
                            if (!location.hash.includes('#/campaigncreator')) {
                                $(window).on('hashchange', () => {
                                    if (location.hash.includes('/templates') && !changedhash && template.templateid === 0) {
                                        $('#epaginationresult').html(html.getraw('TemplateListLoading', {}));
                                        changedhash = true;
                                    } else if (location.hash.includes('/templates') && !changedhash) {
                                        $('#erefresh').click()
                                    }
                                })
                            }


                            if (debouncedSave) {
                                debouncedSave.cancel();
                            }
                            debouncedSave = _.debounce (() => {

                                EE_API.Template.Save(template)
                                .then ((response) => {
                                    let templatedata = false;
                                    if (typeof(response) == "number") {
                                        templatedata = response;
                                    } else {
                                        templatedata = parseInt(response);
                                    }
                                    template.templateid = templatedata;

                                    let popstateListener = (element, event, listener) => {
                                        element.addEventListener(event, popstateListenerEvent = () => {
                                            element.removeEventListener(event, popstateListenerEvent);
                                            return listener();
                                        });
                                    }
                                    if (location.hash.includes('#/campaigncreator')) {
                                        EE_API.Template.GetEmbeddedHtml({ templateID: template.templateid });
                                        ee.views.templates.saveWithScreenshot(template, $('#dtemplateframe'))
                                        ee.data.selectedChannelTemplate.templateid = template.templateid;
                                        ee.views.campaigncreator.insertChannelTemplateToTab();
                                        $('.frameoverlay-next').removeClass('disabled');
                                    } else if (location.hash.includes('create')) {
                                            ee.editor.exitEditor ? ee.changeHashQuietly(`#/${ee.Router.controller()}/edit/${encodeURIComponent(template.name)}`) : ee.goTo(`#/${ee.Router.controller()}/edit/${encodeURIComponent(template.name)}`);
                                            ee.views.templates.saveWithScreenshot(template, frame)
                                            if (location.hash.includes('edit')){
                                                $('.template-content').find('.container').addClass('templatecontainer');
                                            }
                                            popstateListener(window, 'popstate', () => {
                                                $('.container').removeClass('templatecontainer');
                                                if (location.hash === "#/templates/create/dragdrop") {
                                                    ee.goTo('#/templates');
                                                }
                                            })

                                    } else {
                                        ee.views.templates.saveWithScreenshot(template, frame)
                                        $('.container').removeClass('templatecontainer');

                                    }
                                    _autoSaveWidget = new ee.editor.core.autosave(screen.autosave, ee.editor.options.autoSaveNewTemplateCallback);
                                    _autoSaveWidget.start();
                                    that.autoSaveWidget = _autoSaveWidget;
                                })
                                .catch((e) => {
                                    $('#templateChanges').text(ee.t.templatenotsaved);
                                    $('#ename').on('change', function() {
                                        debouncedSave()
                                    })
                                    return
                                })
                            }, 3000)
                        debouncedSave();

                        }
                    }, 3000)
                    $('.return-button').on('click', () => {
                        clearInterval(this.newTemplate);
                    })
                    const onClickEsc = (event) => {
                        if (event.key === 'Escape') {
                            clearInterval(this.newTemplate);
                        }
                    }
                    document.addEventListener('keydown', onClickEsc);

                    $(window).on('hashchange', () => {
                        clearInterval(this.newTemplate);
                        document.removeEventListener('keydown', onClickEsc);
                    })
                }


                if (ee.data.selectedtemplate.isdirty === true) {
                    ee.editor.core.isChange();
                }

                $('#editdefault').on('click', function (e) {
                    const templateid = ee.data.selectedtemplate.templateid;
                    const template = ee.data.selectedtemplate;
                    const win = html.get("TemplateDefaultOptions", { template: template, accountscope: ee.data.account.scope, subaccount: ee.data.account.issub, islanding: template.templatetype === 2  });

                    ee.logEvent('Template editor - show default options');

                    html.widget.SelectFromEmail(win.fromemail, template.fromemail, true).then(function () {
                        var modal = html.modal.create(win, {
                            title: ee.t.templatedetail,
                            size: "emodal-md",
                            buttons: [{
                                name: ee.t.save,
                                css: "btn_lg btn_primary",
                                title: ee.t.ttsavedefaults,
                                callback: function () {
                                    if (ee.data.selectedtemplate.templatetype !== 2) { //2 is landing page type
                                        template.name = win.find('input[name=name]').val();
                                        template.fromemail = win.find('input[name=fromemail]').val();
                                        template.fromname = win.fromname.val();
                                        template.subject = win.subject.val();
                                    }
                                    template.removeScreenshot = false;

                                    if (win.scope) template.templatescope = win.scope.is(':checked') === true ? 1 : 0;
                                    if (win.isdefault) template.isdefault = win.isdefault.is(':checked');

                                    ee.indiOn();
                                    window?.document?.dispatchEvent?.(new CustomEvent('templateSaving'));
                                    if (ee.data.selectedtemplate.templateid == "0" || !ee.data.selectedtemplate.templateid) {
                                        return EE_API.Template.Save(template)
                                        .then(function(resp) {
                                            ee.data.selectedtemplate.templateid = resp;
                                            $('#ename').val(template.name);
                                            $('.js-template-fromemail').val(template.fromemail);
                                            $('.js-template-fromname').val(template.fromname);
                                            $('.js-template-subject').val(template.subject);
                                            $(".js-template-name").html(template.name);
                                            $('.js_ename-value').val(template.name);
                                            ee.indiOff();
                                            modal.close();
                                            window?.document?.dispatchEvent?.(new CustomEvent('templateSaved'));
                                        }).
                                        catch(() => {
                                            window?.document?.dispatchEvent?.(new CustomEvent('templateSaveError'));
                                        });
                                    } else {
                                        if (ee.data.selectedtemplate.templatetype !== 2) { //2 is landing page type
                                            $('#ename').val(template.name);
                                            $('.js-template-fromemail').val(template.fromemail);
                                            $('.js-template-fromname').val(template.fromname);
                                            $('.js-template-subject').val(template.subject);
                                            $(".js-template-name").html(template.name);
                                            $('.js_ename-value').val(template.name);
                                        }
                                        
                                        return EE_API.Template.Update(template)
                                        .then(function(){
                                            ee.indiOff();
                                            modal.close();
                                            window?.document?.dispatchEvent?.(new CustomEvent('templateSaved'));
                                        }).
                                        catch(() => {
                                            window?.document?.dispatchEvent?.(new CustomEvent('templateSaveError'));
                                        });
                                    }
                                }
                            }]
                        });
                        ee.tools.validateEntityName(
                            {
                                screen: win,
                                inputname: 'name',
                                emptyname: ee.t.requiredtemplatename,
                                submit: modal.$footer.find('.btn_primary')
                            }
                        )
                    });
                });
            });

            $('div').find('#econtent_wrapper').addClass('template-content');
            $(window).on('hashchange', () => {
                if (!location.hash.includes('templates/edit')) {
                    $('div').find('#econtent_wrapper').removeClass('template-content');
                }
            })

            $('.workbox').css('width', '100%');

            const documentHeight = window.innerHeight;
            $('#dsidebar').css('height', documentHeight);
            let userHeader = template.bodyhtml.match(/<head.*?>([\s\S]*)<\/head>/);
            let bodyHtml = template.bodyhtml.match(/<body.*?>([\s\S]*)<\/body>/);
            bodyHtml = (!_.isEmpty(bodyHtml)) ? bodyHtml[0] : null;
            userHeader = (!_.isEmpty(userHeader)) ? userHeader[0] : null;

            if (!bodyHtml || !userHeader) {
                ee.indiOff();
                const encodedTemplateName = ee.tools.encodeBase64Url(ee.data.selectedtemplate.name);
                if (!!ee.parameters.legacyMode) {
                    return window.top.location.assign(`${window.top.location.origin}${productUrl}templates/new/editor/${encodedTemplateName}`);
                };
                window.history.replaceState({}, "", `${ee.baseurl}#/templates`);
                ee.goTo(`#/templates/new/editor/${encodedTemplateName}`);
                return;
            }

            const header = html.getraw('DefaultHeader', {
                isNewTemplate: template.templateid === 0 && template.originaltemplateid === 0 ? true : false,
                lang: (ee.t._locale != "en") ? ee.t._locale.toLowerCase() : "",
                editorVersion: ee.editor.ddeditor.version,
                libVersion: ee.libVersion,
                disablecache: (new Date().getTime()),
                libpath: ee.tools.getLibPath(),
                userHeader: userHeader,
                alreadyHasFontAwesome: userHeader.includes("use.fontawesome.com/releases"),
                alreadyHasSpectrum: userHeader.includes("spectrum.min.css")
            });

            const doc = screen.templateframe[0].contentWindow.document;
            doc.open();

            doc.write(html.getraw('eTemplateFrame', {
                bodyhtml: bodyHtml,
                header: header
            }));
            doc.close();
            return;
        },
        exitEditor = function (event, target) {
            ee.logEvent('Template editor - close');
            if (ee.editor && ee.editor.frame) {
                ee.editor.frame.css('min-height', '');
            }
            if (!target) {
                var prevPath = ee.Routing.prevPath(); 
                target = (prevPath.indexOf('create') > -1) ? ee.Routing.controller() : prevPath;
            }
            if (!ee.data.selectedtemplate.isdirty) {
               
                if (ee.Router.currentPath().includes('create/')) {
                    ee.changeHashQuietly('#/' + target)
                } else {
                    ee.editor.destroy();
                    ee.goTo('#/' + target);
                }
                
            } else {
                html.modal.confirm(ee.t.withoutsaving, ee.t.info, function () {
                    ee.editor.destroy();
                    ee.goTo('#/' + target);
                });
            }
            return;
        },
        destroy = function () {
            $(document).off('mousedown');
            if (ee.editor.screen) {
                $('body').find(".sp-container").remove();
                ee.editor.screen.empty();
                $(window).off('keydown.etemplate');
            }
            if (_autoSaveWidget) {
                _autoSaveWidget.stop();
                _autoSaveWidget = null;
            }
            ee.editor = false;
            return;
        },

        getNewEmptyLandingpage = function () {
            var privbrand = ee.data.account.enableprivatebranding || (ee.data.account.issub && !!ee.data.account.privatebrandingurl),
                template = $.extend({}, ee.editors.core._defaultTemplate);
            template.templatetype = 2;
            template.name = `${ee.t.newlandingpage}:${moment().format('YYYY-MM-DD HH:mm:ss')}`
            template.bodyhtml = html.getraw('defaultLandingPage', { privbranding: privbrand, apiurl: ee.api.apiurl, isLandingpage: (template.templatetype === 2), isTrialBillingPlan: ee.data.account.istrialbillingplan, isReseller: ee.isReseller(), publicaccountid: ee.data.account.publicaccountid, formid: html.generateUniqueId(10) });
            template.css = html.template("DefaultLandingPageCssStyle");

            return template;
        },
        getNewEmptyTemplate = function () {
            var privbrand = ee.data.account.enableprivatebranding || (ee.data.account.issub && !!ee.data.account.privatebrandingurl),
                template = $.extend({}, ee.editors.core._defaultTemplate);
            template.css = html.template("DefaultCssStyle");
            template.templatetype = 1;
            template.name = `${ee.t.newtemplate}:${moment().format('YYYY-MM-DD HH:mm:ss')}`
            template.bodyhtml = html.getraw('defaultTemplate', { privbranding: privbrand, apiurl: ee.api.apiurl, isReseller: ee.isReseller() });


            return template;

        },
        _$SpectrumInputs = false,
        _saveCallback = function (result) {
            ee.editor.core._disableDirty(ee.editor.screen);
            html.reloadTooltip(ee.editor.screen);
            ee.editor.screen.save.off().addClass("disabled");
            if (result.data) { 
                ee.editor.exitEditor({}, ee.Router.controller() + "/edit/" + result.data);
            }
        },
        /*-- Public methods --*/
        load = function ($handle, options) {
            ee.indiOn();
            if (ee.editors.core._loadColorPicker(function () { ee.editor.load($handle, options); }) === false) {
                return;
            }
            //If Template is empty, then create new one
            if (!ee.data.selectedtemplate) {
                ee.data.selectedtemplate = this.getNewEmptyTemplate();

            }
            ee.editor.setOptions($handle, options);

            //Initial editor, and load template to iframe
            ee.editor.startEditor();

            var screen = ee.editor.screen;

            /* MENU BUTTONS BIND */
            if (screen.moible) {
                screen.moible.click(function () {
                    $('#editorUndo').addClass('disabled');
                    $('#editorRedo').addClass('disabled');
                    $("#hidelayout").addClass('disabled');
                    screen.menueditor.hide();
                    $('#emoible').hide();
                    $('#edesktop').show();
                    $('.workbox').css('overflow', 'unset');
                });
                screen.desktop.click(function () {
                    disabledButtonsUndoRedo();
                    screen.menueditor.show();
                    $("#hidelayout").removeClass('disabled');
                    $('#emoible').show();
                    $('.workbox').css('overflow', 'auto');
                    $('#edesktop').hide();
                });
            }
            
            if (ee.editor.options.defaultButtons) {
                //Scope
                if (screen.scope) {
                    screen.scope.change(function () {
                        ee.data.selectedtemplate.isdirty = true;
                        ee.editors.core._dirty(screen, ee.editor.save);
                    });
                }
                //Edit TextBody
                if (ee.data.selectedtemplate.templatetype === 2) {
                    $('#publishLandingPage').click(() => {
                        ee.editor.core._publishLandingPage();

                        let domain = (ee.data.account.privatebrandingurl) ? `${ee.getProtocol()}//${ee.data.account.privatebrandingurl}` : ee.api.path;
                        let linkToPage = ee.data.selectedtemplate.link;
                        linkToPage = linkToPage.split('/page')[1];
                        let linkToLandingPage = `${domain}/page${linkToPage}`;


                        $('#templateLink').val(linkToLandingPage);


                        $('#copyLinkToLandingpage').click ( () => {
                            $('#templateLink').val(linkToLandingPage).select();
                            document.execCommand("copy");
                            html.widget.Toast.info(ee.t.copiedlink, "", {fadeIn: 250, faeOut: 250, timeOut: 1000});
                        })

                    });

                    $('#downloadLandingPage').click ( () => {
                        let templateid = ee.data.selectedtemplate.templateid;
                        let query = {templateid: templateid}

                        return EE_API.Template.DownloadTemplate(query)
                        .then ((data) => {
                            let blob = new Blob([data]);

                            let templatename = ee.data.selectedtemplate.name;
                            let fileName = `${templatename}.html`;
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = fileName;
                            link.click();
                        })
                        .catch((err) => {
                            console.log({err})
                        })
                    });
                    $('#previewLandingPage').click ( () => {
                        if (ee.data.selectedtemplate.templateid != 0) { //templateid == 0 has only new templates before first save
                            ee.views.templates.previewContent(ee.data.selectedtemplate.templateid,ee.data.selectedtemplate.ispublic)
                        } else {
                            ee.views.templates.previewContent(ee.data.selectedtemplate.originaltemplateid, true)
                        }
                        

                    });

                } else {
                    screen.editbodytext.click ( () => {
                        ee.editor.core._showBodyText();
                    });
                    screen.editbodyamp.click ( () => {
                        ee.editor.core._showBodyAmp();
                    });

                }


                //Save Button is binded in isDirty method
                //IT IS NEW TEMPLATE ENABLE DIRTY!
                // if (ee.data.selectedtemplate.templateid === 0) {
                //     ee.editor.isDirty();
                // }
            } else {
                if (location.hash.includes('campaigncreator')) {
                    screen.editbodytext.click ( () => {
                        ee.editor.core._showBodyText();
                    });
                    screen.editbodyamp.click ( () => {
                        ee.editor.core._showBodyAmp();
                    });
                }
            }
            $('#closeTrialBadgeInfo').on('click', () => {
                $('#trialSidebar').hide();
            })
            $('#badgeUpgrade').on('click', () => {
                ee.goTo("#/payments")
            })
            //Right Menu Tabs Controll
            screen.tablayout.on("click", function () {
                screen.menusytle.hide();
                screen.menulayout.show();
                screen.menustyles.hide();
                ee.frame.content_wrapper.trigger('scroll');
            });
            screen.tabdesign.on("click", function () {
                screen.menusytle.show();
                screen.menulayout.hide();
                screen.menustyles.hide();
                ee.frame.content_wrapper.trigger('scroll');
            });
            screen.tabstyles.on("click", function () {
                screen.menusytle.hide();
                screen.menulayout.hide();
                screen.menustyles.show();
                ee.frame.content_wrapper.trigger('scroll');
            });
            if (ee.editor.options.defaultSettings) {
                html.EmojiInput(screen.subject);
                $('#edefaultsettings').on('keyup', 'input[type=text]', function () {
                    ee.editor.isDirty();
                })
            }
            $(".template__name-input").on('change', function () {
                let text = $(".template__name-input").val();
                ee.data.selectedtemplate.name = text;
            })

        },
        save = function (callback, silent) {
            if (!callback) callback = _saveCallback;
            ee.logEvent('Template editor - save');
            var template = ee.editor.core.getTemplateContent();
            ee.editor.core._collectDefaultOptions();
            ee.data.selectedtemplate.css = template.css;
            ee.data.selectedtemplate.bodyhtml = ee.editor.core._replaceAllRgbToHex('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' + ee.editor.core._XSSProtectHtml(template.bodyhtml));
            template = null;
            if (ee.data.selectedtemplate.bodyhtml.indexOf('{{') != -1) {
                html.modal.confirm(ee.t.doublebrackets, ee.t.warning, function () {
                    return ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $("#dtemplateframe"))
                    .then(function(result) {
                        if (location.hash.contains('#/campaigncreator')) {
                            return callback(result);
                        }
                    });

                });
                return;
            } else {
                return ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $("#dtemplateframe"))
                .then(function(result) {
                    if (location.hash.includes('#/campaigncreator')) {
                        return callback(result);
                    }
                });
            }
        },
        reload = function () {
            var frame = ee.editor.frame, options = ee.editor.options;
            ee.editor.destroy();
            ee.editor = ee.editors.etemplate;
            ee.editor.load(frame, options);
        },
        listOfUsedFonts = function () {
            var screen = ee.editor.screen, fonts = [];
            screen.find('.dfontfamily li.selected').each(function () {
                var name = this.dataset.name;
                if (fonts.indexOf(name) === -1) {
                    fonts.push(name);
                }
            });
            return fonts;
        };
    that.load = load;
    that.save = save;
    that.reload = reload;
    that.destroy = destroy;
    that.getNewEmptyTemplate = getNewEmptyTemplate;
    that.getNewEmptyLandingpage = getNewEmptyLandingpage;
    that.setOptions = setOptions;
    that.startEditor = startEditor;

    that.initEditorLayout = initEditorLayout;
    that.initEditorDesign = initEditorDesign;
    that.exitEditor = exitEditor;

    that.isDirty = isDirty;
    that._$SpectrumInputs = _$SpectrumInputs;
    that.spectrumOptions = {
        preferredFormat: "hex",
        showPalette: true,
        showSelectionPalette: true, // true by default
        palette: [],   //This shoild ne fill by default template collor
        maxSelectionSize: 15,
        localStorageKey: "spectrum.draganddropeditor",
        allowEmpty: true,
        showInitial: true,
        showInput: true,
        showAlpha: false,
        ChangeOnline: true,
        clickoutFiresChange: true,
        show: function (color) {
            ee.data.lastcolor = (!color) ? "" : color.toHexString();
            ee.editor.core.bufferGlobal.set(false, { input: $(this), type: "spectrum", value: color });
        },
        hide: function (color) {
            color = (!color) ? "" : color.toHexString();
            if (ee.data.lastcolor !== color && ee.editor && ee.editor.core) {
                ee.editor.core.isChange(true);
            } else {
                ee.editor.core.bufferGlobal.removeLast();
            }
        }
    };
    that.defaultOptions = {
        autoSaveNewTemplateCallback: function (result) {
            ee.changeHashQuietly('/' + ee.Router.controller() + "/edit/" + result.data);

        },
        screenHandle: false,
        workSpaceMaxWidth: 900,
        defaultSettings: true,
        defaultButtons: true,
        menuWindow: "",
        saveMethod: false,
        isDirty: false
    };
    that.listOfUsedFonts = listOfUsedFonts;
    return that;
})();
