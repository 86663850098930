﻿(html.frame = function () {
    var $mainMenuViewLi,
        $mobileMenuViewLi,
        that = {
            init: function () {
                var frame = html.get('Frame', {});
                var topnavbarObj = ee.config('topnavbar');
                var menuObj = ee.config("menu");
                var mainnavbar = html.get("MainNavbar", topnavbarObj );
                var mainmenu = html.get("MainMenu", {
                    items: menuObj,
                    base: ee.base,
                    isReseller: ee.isReseller(),
                    issub:ee.data.account.issub,
                    supportLink: ee.data.account.supportlink,
                    logourl: ee.parameters.logourl,
                    isbootedAsChild: ee.parameters.bootedAsChild
                });
                var mobilemenu = html.get("MobileMenu", {
                    items: menuObj,
                    base: ee.base,
                    isReseller: ee.isReseller(),
                    issub:ee.data.account.issub,
                    supportLink: ee.data.account.supportlink,
                    logourl: ee.parameters.logourl
                });

                if (!ee.legacyMode) {
                    mainnavbar.appendTo(frame.mainnavbar);
                    mainmenu.appendTo(frame.mainnav_wrapper);
                    mobilemenu.appendTo(frame.mobilenav_wrapper);
                } else {
                    frame.find('#emainnav_wrapper').hide();
                    frame.find('#emobilenav_wrapper').hide();
                    frame.find('#emainnavbar').hide();
                    frame.find('#econtent_wrapper').css("left","0");
                    frame.find('#econtent_wrapper').css("width","100%");
                    frame.find('#econtent_wrapper').css("padding-top","0");
                }

                ee.frame = frame;
                const getTokenAndLoginToApp = () => {
                    const tokenRequest = API_V3.Accounts.GetDisposableToken
                    ee.indiOn();
                    tokenRequest().then((resp) => {
                        const {Token} = resp
                        ee.logEvent('Switch to APP.ELASTICEMAIL.COM.');
                        if (window.ga) {
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Switch to beta app.ee.com',
                            eventAction: 'product switch click'
                        });
                        }

                        ee.session.destroy();

                        window.location.href = `https://app.elasticemail.com/login?t=${Token}`
                    })
                }
                if ( ee.parameters && ee.parameters.isBeta && !ee.parameters.bootedAsChild) {
                    mainnavbar.on("click", "#dropDownMenu-account", function () {
                        getTokenAndLoginToApp()
                    });
                } else if (ee.parameters && ee.parameters.isBeta && ee.parameters.bootedAsChild) {
                    ee.indiOn();
                    mainnavbar.on("click", "#dropDownMenu-account", function () {
                        window.location.href = `https://app.elasticemail.com/`;
                    })
                }

                function addmenuclasses() {
                    ee.frame.find('#emainnav_wrapper').addClass('nonedisplay');
                    ee.frame.find('#emobilenav_wrapper').addClass('nonedisplay');
                    ee.frame.mainnavbar.find('#topnavbar').addClass('mobilenavbarleft');
                    ee.frame.content_wrapper.addClass('mobilenavbarleft');
                }
                function mobilemenutoggler () {
                    $('#eframe').toggleClass('menu-active');

                        if (ee.base && window.ga) {
                            window.ga('send', {
                                hitType: 'event',
                                eventCategory: 'SwitchNavigationMenuType',
                                eventAction: 'toggle',
                                eventLabel: ($('#eframe').hasClass("menu-active")) ? "showDesktop" : "showMobile"
                            });
                        }
                        if(html.isMobile()) {
                        if (!ee.frame.hasClass('menu-active')) {
                            addmenuclasses();
                            ee.frame.content_wrapper.removeClass('nonedisplay');
                            ee.frame.mainnav_wrapper.removeClass('menufullscreen');
                        } else {
                            ee.frame.find('#emainnav_wrapper').removeClass('nonedisplay');
                            ee.frame.find('#emobilenav_wrapper').removeClass('nonedisplay');
                            ee.frame.mainnavbar.find('#topnavbar').removeClass('mobilenavbarleft');
                            ee.frame.content_wrapper.removeClass('mobilenavbarleft');
                            ee.frame.content_wrapper.addClass('nonedisplay');
                            ee.frame.mainnav_wrapper.addClass('menufullscreen');
                       }}
                }


                if (!html.isMobile()) {
                    ee.frame.addClass('menu-active');
                } else {
                    addmenuclasses();
                    ee.frame.mainnavbar.find('#topnavbar').addClass('mobilenavbar');
                    ee.frame.mainnavbar.find('#equestioncontainer').hide();
                    ee.frame.mainnavbar.find('#enotificationsbellcontainer').hide();
                    ee.frame.mainnavbar.find('.navbar_admin').hide();
                    ee.frame.mainnavbar.find('.dropdown').addClass('mobilemenu_dropdown');
                    ee.frame.mainnavbar.find('#edropdownMenu').addClass('mobile-margin-menu_dropdown');
                    ee.frame.mainnavbar.find('.vertical_line').hide();

                    ee.frame.mainnav_wrapper.find('#emainmenunav li').on('click', function() {
                        mobilemenutoggler();

                    });
                        mainnavbar.on("click", "#edropdownMenu > li", function () {
                            if ($('#eframe').hasClass('menu-active')) {
                                mobilemenutoggler();
                            }
                        });
                }

                ee.container.empty();
                ee.frame.appendTo(ee.container);

                $mainMenuViewLi = ee.frame.mainnav_wrapper.find('li[data-viewname]');
                $mobileMenuViewLi = ee.frame.mobilenav_wrapper.find('li[data-viewname]');
                $('#hamburgermenu').on('click', function () {
                    mobilemenutoggler();
                    $('.hamburgercolor').toggleClass('navbar-icon-close navbar-icon-open');
                    $('.navbar-button').toggleClass('navbar-button-close navbar-button-open');
                });

                that.bindMenu();
                that.bindMobileMenu();
                if (ee.base && !ee.bootedAsChild) {
                    var dashboardtracking = html.get("DashboardTrackingNew", { base: ee.base }, "", true)
                    $('body').append(dashboardtracking);
                }

            },
            bindTopNavbar: function () {
                var mainnavbar = ee.frame.mainnavbar;

                mainnavbar.on("click", "#esignout", function () {
                    ee.logEvent('Logged Out.');
                    ee.session.can = {};

                    ee.views['login'].logout();
                });
                mainnavbar.on("click", "#egotolanguages", function () {
                    ee.goTo("#/language")
                });
                mainnavbar.on("click", "#ebackadmin", function () {
                    var returnUrl = "#/admin";

                    if(store) {
                        if (store.session('adminReturnPath')) {
                            returnUrl = store.session('adminReturnPath');
                            store.session.remove('adminReturnPath');
                        }
                    } else {
                        try {
                            if (sessionStorage.getItem('adminReturnPath')) {
                                returnUrl = sessionStorage.getItem('adminReturnPath');
                                sessionStorage.removeItem('adminReturnPath');
                            }
                        } catch(err) {
                            console.log(err);
                            console.log("Cookies and sessionStorage disabled.");
                        }
                    }

                    ee.session.unloadAccount(returnUrl);
                    return false;
                });
                mainnavbar.on("click", "span.eparent", function () {
                    ee.session.unloadAccount("#/account/subaccounts");
                    return false;
                });
                mainnavbar.on("click", "li[data-view]", function (event) {
                    event.stopPropagation();
                    var view = $(this).data('view');
                    ee.goTo('#/' + view);
                })

                mainnavbar.on("click", "#edropdownMenu > li", function () {
                    var $this = $(this);

                    if (ee.base && window.ga && $this.attr("id")) {
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'DropDownMenuItem',
                            eventAction: 'click',
                            eventLabel: $this.attr("id")
                        });
                    }
                    ee.frame.find('#rightmenu').removeClass('open');
                })
            },

            bindMobileMenu: function () {
                var mobilemenu = ee.frame.mobilenav_wrapper;
                var $mobileMenuLinks = mobilemenu.find('a');

                mobilemenu.off('click').on('click', 'a', function () {
                    var $this = $(this);
                    var view = $this.data('view');

                    if (ee.base && window.ga && $this.attr("id")) {
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'MobileMenuItem',
                            eventAction: 'click',
                            eventLabel: $this.attr("id")
                        });
                    }

                    if ($this.prop('target') === "_blank") return true;

                    //$('#eframe').addClass('menu-active');
                    ee.frame.mainnav_wrapper.find('.on').removeClass('on');
                    if (view) {
                        ee.frame.mainnav_wrapper.find('a[href="#/' + view + '"]').click();
                    }
                    
                    return false;
                })
            },
            bindMenu: function () {
                var mainmenu = ee.frame.mainnav_wrapper,
                    elem = mainmenu.find('a[href="#/' + ee.Router.currentPath() + '"]');

                if (!elem.hasClass('eparentelem')) {
                    elem.parents('li').addClass('on');
                    elem.parent('li').removeClass('on');
                }
                mainmenu.on('click', '.etoggler', function () {
                    var clickedLink = $(this)["0"].offsetParent;
                    $(clickedLink).parent().toggleClass("on");
                    var numberOfTogglers = $('.etoggler').length;
                    var hasClassEmianli = $(clickedLink).parent().hasClass('emianli');
                    for (var i = 0; i < numberOfTogglers; i++) {
                        var href = $('.etoggler')[i].offsetParent;
                        if (clickedLink != href) {
                            if (! hasClassEmianli ) {
                                $(href).parent().not('.emianli').removeClass("on");
                            } else {
                                $(href).parent().removeClass("on");
                            }
                        }
                    }
                    return false;
                });

                mainmenu.on('click', 'a', function (e) {
                    const $this = $(this);
                    const $parent = $this.parent();

                    if ($this.hasClass('js-menu-item__verifications')) {
                        e.stopPropagation();
                        e.preventDefault();
                        const TokenPromise = ee.data.account.isuser ? API_V3.Users.GetDisposableToken : API_V3.Accounts.GetDisposableToken;
                        TokenPromise().then(response => {
                            window.location.replace(`https://verifications.elasticemail.com?magicToken=${response.Token}`);
                        });
                        return;
                    }


                    const elemId = $this.attr('id');
                    if (elemId && elemId !== "home-menu-item") {
                        ee.logEvent(elemId);
                    }

                    if (ee.base && window.ga && $this.attr("id")) {
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'MenuItem',
                            eventAction: 'click',
                            eventLabel: $this.attr("id")
                        });

                        const action = (ee.data.account) ? ee.data.account?.billingdetails?.activeplan?.pricingbillingplandesc : 'Unknown';
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: action,
                            eventAction: 'click',
                            eventLabel: $this.attr("id")
                        });

                        if ($this.attr("id") === 'verification-menu-item') {
                            window.ga('send', {
                                hitType: 'event',
                                eventCategory: 'MenuItem',
                                eventAction: 'Verification click'
                            });
                        }
                    }

                    const $toggler = $this.children(".etoggler");
                    if($toggler.length > 0) {
                        $toggler.click();
                    } else {
                        $this.parent().siblings().each(function(i,element){
                            $(element).removeClass('on');
                        })
                    }
                });
                mainmenu.on('click', '#upgradeButton', function() {
                    ee.logEvent('MainMenu - Load My Account.');
                    window.location.hash = "#/account";
                });

                if ((ee.data.account.firstname == "" || ee.data.account.firstname == undefined) && (ee.data.account.lastname == "" || ee.data.account.lastname == undefined)) {
                    $('#menuUsername').hide();
                }
            },
            unbindConfirm: function () {
                ee.frame.mainnav_wrapper.off('click.confirm');
                ee.frame.mainnavbar.off('click.confirm');
                ee.frame.notifications.off('click.confirm');
                delete ee.data.confirmNavigation;
                delete ee.data.confirmCB;
            },
            bindConfirm: function (callback, statemant, options) {
                /* TO DO: rewrite on more DRY k.g.*/
                ee.frame.mainnav_wrapper.on('click.confirm', 'a', function (e) {
                    const $this = $(this);
                    const href = $this.prop('href');
                    const target = $this.prop('target');
                    if (href === window.location.href || !__stopGoOut(e, href)) {return false};
                    if (target === "_blank") return true;
                });


                ee.frame.mainnavbar.on('click.confirm', 'a', function (e) {
                    const $this = $(this);
                    const href = $this.prop('href');
                    const target = $this.prop('target');
                    if (href === window.location.href || !__stopGoOut(e, href)) {return false};
                    if (target === "_blank") {return true};
                    
                });

                ee.frame.notifications.on('click.confirm', 'a', function (e) {
                    const $this = $(this);
                    const href = $this.prop('href');
                    const target = $this.prop('target');
                    if (href === window.location.href || !__stopGoOut(e, href)) {return false};
                    if (target === "_blank") {return true};
                   
                });
                $(".ecampaigncreator a.breadcrumb").on('click',function(e){
                    const $this = $(this);
                    const href = $this.prop('href');
                    const target = $this.prop('target');
                    if (href === window.location.href || !__stopGoOut(e, href)) {return false};
                    if (target === "_blank") {return true};
                   
                })


                ee.data.confirmNavigation = true;
                ee.data.confirmCB = callback;

                function __stopGoOut(e, target) {
                    if ($(e.target).hasClass('etoggler') || $(e.target).hasClass('fa-angle-down')) return true;
                    if (!statemant || (statemant && statemant())) {
                        return true;
                    } else {
                        e.stopImmediatePropagation();
                        var msg = (options && options.infomessag) ? options.infomessag : ee.t.withoutsaving;
                        html.modal.confirm(msg, ee.t.info, function () {
                            if (callback) callback();
                            that.unbindConfirm();
                            
                            window.top.location = target;
                        }, function () {
                            var elem = ee.frame.mainnav_wrapper.find('a[href="#/' + ee.Router.currentPath() + '"]');

                            if (!elem.hasClass('eparentelem')) {
                                elem.parents('li').addClass('on');
                                elem.parent('li').removeClass('on');
                            }
                        });
                        return false;
                    };
                };
            },
            addSelect: function () {
                var viewname = ee.Router.controller();
                /* TO DO: find a way to remove ubly ifs, also rewrite it more DRY */
                if (viewname === "campaigns") {
                    viewname = "campaigns/new/";
                }
                if (viewname === "payments" || viewname === "reputation") {
                    viewname = "account";
                }
                if (viewname === "contacts") { 
                    viewname = "contacts/new/overview"
                }
                if (viewname === "templates") { 
                    viewname = "templates/new"
                }
                if (viewname === "forms") {
                    viewname = "forms/new"
                }
                if (viewname === "landingpages") {
                    viewname = "landingpages/new"
                }
                $mainMenuViewLi.removeClass('selected');
                $mobileMenuViewLi.removeClass('selected');
                $mainMenuViewLi.each(function () {
                    if ($(this).data('viewname') === viewname) {
                        $(this).addClass('selected');
                        return false;
                    }
                });
                $mobileMenuViewLi.each(function () {
                    if ($(this).data('viewname') === viewname) {
                        $(this).addClass('selected');
                        return false;
                    }
                });
            },
            refreshMenus: function() {
                var topnavbarObj = ee.config('topnavbar'),
                menuObj = ee.config("menu"),
                mainnavbar = html.get("MainNavbar", topnavbarObj),
                mainmenu = html.get("MainMenu", { items: menuObj, base: ee.base}),
                mobilemenu = html.get("MobileMenu", { items: menuObj, base: ee.base });
                ee.frame.mainnavbar.empty();
                ee.frame.mainnav_wrapper.empty();
                ee.frame.mobilenav_wrapper.empty();
                mainnavbar.appendTo(ee.frame.mainnavbar);
                mainmenu.appendTo(ee.frame.mainnav_wrapper);
                mobilemenu.appendTo(ee.frame.mobilenav_wrapper);

                that.bindMenu();
                that.bindMobileMenu();
            }

        };

    return that;
}());
