/**
* @namespace ee.Session
*/

(function () {
    ee.session = (function Session() {
        var sessionObj = {
            key: { val: '', options: {} },           //ee.apikey
            remember: { val: '', options: { expires: 14 } }, //ee.remember
            parentkey: { val: '', options: {} },//ee.parentaccount.apikey
            parentemail: { val: '', options: {} },//ee.parentaccount.username
            adminkey: { val: '', options: {} },    //ee.adminkey
            adminaccess: { val: '', options: {} },    //ee.adminkey
            locale: { val: '', options: { expires: 14 }, overt: true, irremovable: true },
            debugmode: { val: 'false', options: {}, overt: true },
            interfaceloaded: { val: '' },
        },

            EnumAccessLevel = {
                none: 0,
                admin: 1,
                full: 2,
                limited: 4,
                fullorlimited: 6,
                readonly: 8,
                usergroup: 12,
                accountgroup: 14,
                admingroup: 15,
                adminfull: 16,
                adminfullgroup: 31
            },
            StringAccessLevel = {
                none: "None",
                admin: 1,
                full: "ViewAccount,ViewContacts,ViewForms,ViewCampaigns,ViewChannels,ViewAutomations,ViewSurveys,ViewSettings,ViewBilling,ViewSubAccounts,ViewUsers,ViewFiles,ViewReports,ModifyAccount,ModifyContacts,ModifyForms,ModifyCampaigns,ModifyChannels,ModifyAutomations,ModifySurveys,ModifyFiles,Export,SendSmtp,SendHttp,SendSMS,ModifySettings,ModifyBilling,ModifySubAccounts,ModifyUsers,Security",
                limited: "ViewAccount,ViewContacts,ViewForms,ViewCampaigns,ViewChannels,ViewAutomations,ViewSurveys,ViewSettings,ViewBilling,ViewSubAccounts,ViewUsers,ViewFiles,ViewReports,ModifyContacts,ModifyForms,ModifyCampaigns,ModifyChannels,ModifyAutomations,ModifySurveys,ModifyFiles,Export,SendSmtp,SendHttp,SendSMS",
                fullorlimited: "ViewAccount,ViewContacts,ViewForms,ViewCampaigns,ViewChannels,ViewAutomations,ViewSurveys,ViewSettings,ViewBilling,ViewSubAccounts,ViewUsers,ViewFiles,ViewReports,ModifyContacts,ModifyForms,ModifyCampaigns,ModifyChannels,ModifyAutomations,ModifySurveys,ModifyFiles,Export,SendSmtp,SendHttp,SendSMS",
                readonly: "ViewAccount,ViewContacts,ViewForms,ViewCampaigns,ViewChannels,ViewAutomations,ViewSurveys,ViewSettings,ViewBilling,ViewSubAccounts,ViewUsers,ViewFiles,ViewReports,Export",
                usergroup: 12,
                accountgroup: "ViewAccount,ViewContacts,ViewForms,ViewCampaigns,ViewChannels,ViewAutomations,ViewSurveys,ViewSettings,ViewBilling,ViewSubAccounts,ViewUsers,ViewFiles,ViewReports,ModifyAccount,ModifyContacts,ModifyForms,ModifyCampaigns,ModifyChannels,ModifyAutomations,ModifySurveys,ModifyFiles,Export,SendSmtp,SendHttp,SendSMS,ModifySettings,ModifyBilling,ModifySubAccounts,ModifyUsers,Security",
                admingroup: 15,
                adminfull: 16,
                adminfullgroup: 31
            },
            accessLevel = EnumAccessLevel['none'],
            accessLevelNew = [],
            targetPage = false,
            that,
            _loadOtherAccount = function (email, redirect) {
                try {
                    sessionStorage.setItem('subaccountemailtouse', email);
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
                delete ee.session.can;
                ee.session.loadAccount(redirect);
            };

        var _loadAccountInNewTabCheck = function () {
            if (localStorage.getItem("subaccountemailtouse")) {
                try {
                    sessionStorage.setItem("subaccountemailtouse", localStorage.getItem("subaccountemailtouse"));
                    sessionStorage.setItem('inspection', 'true');
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }

                if (window.Intercom) {
                    window.Intercom('shutdown');
                }

                localStorage.removeItem("subaccountemailtouse");
            }
        };

        const _showEETools = () => {
            if (!ee.isDebug()) {
                return
            }

            const tools = html.get('EEToolsContainer', {isImpersonating: ee.session.isInspection()})
            tools.appendTo(ee.frame)

            $('#closeEEToolModal').on('click', function(e) {
                $('#eetoolscontainer').remove();
            });

            $('#unloadImpersonation').on('click', function() {
                ee.session.unloadAccount();
            })

            $(document).on("submit", "#eetoolsform", function(e){
                e.preventDefault();
                const searchItem = $('#accountIdToImpersonate').val();
                if (_.isEmpty(searchItem) || !_.isNumber(parseInt(searchItem))) {
                    alert('Please provide account id')
                    return;
                }

                const data = {
                    searchstring: searchItem,
                    type: 'Account',
                    contains: false,
                    offset: 0,
                    count: true,
                    limit: 1,
                    apikey: ee.session.apikey()
                };

                function toFormData(o) {
                    return Object.entries(o).reduce((d,e) => (d.append(...e),d), new FormData())
                }

                const formData = toFormData(data);

                const path = `2=noisrev?hcraes/nimda/`;

                ee.indiOn()
                axios({
                    method: "post",
                    url: `${ee.api.path}${path.split('').reverse().join('')}`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((resp) => {
                    if (!resp || !resp?.data?.success) {
                        alert('Search error')
                        console.error(resp)
                        ee.indiOff();
                        return;
                    }

                    if (_.isEmpty(resp.data.accounts) || resp.data.numberaccounts === 0) {
                        alert('Account not found')
                        ee.indiOff();
                        return;
                    }

                    const i = resp.data.accounts[0].publicaccountid

                    sessionStorage.setItem('subaccountemailtouse', i);
                    sessionStorage.setItem('inspection', 'true');

                    window.location.reload();
                })

            });
        };

        var setSession = function (key, value) {
            if (!sessionObj[key] || sessionObj[key].val === value) return;
            var item = sessionObj[key];
            item.val = value;
            item.changed = true;
            if(store) {
                const cookieKey = key;
                if (store.session(cookieKey)) { store.session.remove(cookieKey); }

                store.session(cookieKey, item.val);
            } else {
                try {
                    sessionStorage.setItem(key, item.val);
                } catch(err) {
                    console.log(err);
                    console.log("Localstorage and sessionStorage disabled.");
                }
            }
        };

        var destroySession = function (key) {
            if (!sessionObj[key] || sessionObj[key].irremovable === true) return false;
            var item = sessionObj[key];
            item.val = '';
            item.changed = false;
            if(store) {
                store.session.remove(`e${key}`);
                if (store.session('remember')) { store.session.remove('remember') }
                if (store.session('key')) {  store.session.remove('key'); }
            } else {
                try {
                    sessionStorage.removeItem('e'+key);
                } catch(err) {
                    console.log("Cookies and sessionStorage disabled.");
                }
            }
            return true;
        };

        var refreshData = function () {
            if (!ee.data) {
                ee.data = {};
                return;
            }
            var adminbackup = ee.data.admin;
            ee.data = {};
            if (adminbackup) {
                ee.data.admin = adminbackup
            }
            ee.views.querytool.resetTableDef();
            //window.scrollTo(0, 0);
        };

        var rundashboard = function () {
            //Finis singin or load account
            if (targetPage) {
                ee.showFrame(targetPage);
                var target = targetPage;
                targetPage = false;
                ee.goTo(target);
                ee.hashChangeEnabled = true;
            } else {
                ee.showFrame();
            }

        };

        var allowedAccountFields = [
            'secondaryhash',
            'datecreated',
            'email',
            'firstname',
            'lastname',
            'isfreebillingplan',
            'istrialbillingplan',
            'isreseller',
            'issub',
            'lastactivity',
            'pricingplan',
            'last30dayscost',
            'username',
            'isconverted',
            'premiumsupport',
            'prioritysupport',
            'rint'
        ];

        var enableMessenger = function (account) {
            if (account.enablemessenger && !ee.isReseller()) {
                return true;
            }

            return false;
        };
        /*
        var isIntercomAllowed = function (account) {
            if (ee.base && (
                _.includes(account.email, "@elasticemail.com" ||
                account.publicaccountid === '0563a144-7a18-48f4-8c1a-df64a839a5ad' ||
                account.publicaccountid === '56d36b56-37b5-4a44-b0d0-a5cabf0ea27c' ||
                account.publicaccountid === '5607c3b1-c5f5-4e8c-b48b-b8b4bc86b3cd' ||
                account.publicaccountid === '732a3279-93e7-4de6-8f3d-d715698e3b31'
            ))) {
                return true;
            }

            return false;
        };
        */
       const _mapProviderStatus = (statusId) => {
            switch(statusId) {
                case 2:
                    return "InProgress";
                case 1:
                    return "On";
                case 0:
                default:
                    return "Off"
            }
        }


        const fetchAccount = (restartDashboard = true) => {

            ee.indiOn();

            ee.api.loadAccount(function (account) {
                ee.indiOff();
                ee.data.account.accessLevelNew = new dataModel.create("AccessLevel");
                ee.data.account.accessLevelNew.data = ee.data.account.accountaccesslevel.split(',');

                if (!_.isEmpty(account.googleadtags) && window.gtag) {
                    _.forEach(account.googleadtags, (tag) => {
                        window.gtag('event', 'conversion', tag)
                    })
                }

                var accesslevels = {}
                ee.data.account.accessLevelNew.data.forEach(function (element) {
                    accesslevels[element] = true;
                });
                if (ee.data.account.can) {
                    $.extend(ee.data.account.can, accesslevels)
                } else {
                    ee.data.account.can = accesslevels;
                }
                if (ee.session.can) {
                    $.extend(ee.session.can, accesslevels)
                } else {
                    ee.session.can = accesslevels;
                }

                var parseName = function(data) {
                    if (data.firstname && !data.lastname) { return data.firstname; }
                    if (data.firstname && data.lastname) { return data.firstname + " " + data.lastname; }

                    return false;
                }

                ee.data.account.pricingPlanName = account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';

                var data = _.pick(account, allowedAccountFields);

                const mapSupportPlan = (id) => {
                    switch(id) {
                        case 1:
                            return 'Priority';
                        case 2:
                            return 'Premium';
                        default:
                            return 'Free'
                    }
                }

                ee.selfHostedCustomBrandingCheck(ee.data.account)

                data.datecreated_at = moment(data.datecreated).unix();
                data.lastactivity_at = moment(data.lastactivity).unix();
                data.lasteventlogload_at = moment(data.lasteventlogload).unix();
                data.subscriptionbillingdate = moment(data.subscriptionbillingdate).unix();

                data.email = _.toLower(data.email);
                data.username = _.toLower(data.username);
                data.pricingPlanName = account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';
                data.hasPrivateIp = (ee.data.account.mailerid > 0)
                data.referralLink = `https://elasticemail.com/referral-reward?r=${account.affiliatelink}`;
                data.referralCode = ee.data.account.affiliatelink;

                ee.data.account.billingdetails.plan.planName = account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';
                ee.data.account.activePlan = account.billingdetails.activeplan
                const pricingPlanDesc = account.billingdetails.activeplan.pricingbillingplandesc;
                ee.data.account.standardProPricing = _.includes(pricingPlanDesc, 'Unlimited') || _.includes(pricingPlanDesc, 'PayPerEmail');
                ee.data.account.billingplans = _.cloneDeep(account.billingplans)
                ee.data.account.billingdetails.plan.supportplan = mapSupportPlan(ee.data.account.billingdetails.plan.supportplan)

                if (!_.isEmpty(account.billingdetails.provider)) {
                    ee.data.account.billingdetails.provider.status = _mapProviderStatus(account.billingdetails.provider.status);
                }

                delete data.datecreated;
                delete data.lastactivity;
                delete data.lasteventlogload;

                data = _.merge({
                    app_id: "a62u0ibr",
                    user_hash: data.secondaryhash,
                    currentUrl: ee.Router.currentPath(),
                    hide_default_launcher: !enableMessenger(account)
                },
                    data
                );

                if (parseName(data)) { data.name = parseName(data); }
                if (!that.isInspection() && window.Intercom ) { window.Intercom("boot", data); }

                if (
                    account.adminaccesslevel !== "None" &&
                    (
                        account.email.indexOf('@elasticemail.com') !== -1
                        || sessionStorage.inspection
                        || sessionStorage.subaccountemailtouse
                    )
                ) {
                    const adminAccessLevels = account.adminaccesslevel.split(',');
                    const accessLevelList = _.reduce(adminAccessLevels, (result, value) => {
                        result[value] = true

                        return result
                    }, {})

                    $.extend(ee.data.account.can, accessLevelList)
                    ee.session.can = ee.data.account.can;
                    ee.data.account.admin = true;
                    if (!ee.session.get('adminkey')) {
                        store.session('adminkey', store.session.get('key'));
                    }

                    rundashboard();
                } else {
                    if (restartDashboard) { rundashboard(); }
                }

            })
        }

        var loadAccount = function (redirect) {
            if (!sessionObj.key.val) { return false; }
            refreshData();
            targetPage = redirect;

            _loadAccountInNewTabCheck();
            fetchAccount();
            return true;
        };

        return that = {
            init: function () {

                if(store) {
                    //Load sessionObj to browser cookies
                    for (var key in sessionObj) {
                        sessionObj[key].val = store.local(key) || store.session(key) || '';}
                } else {
                    try {
                        //Load sessionObj to session storage
                        for (var key in sessionObj) {
                            sessionObj[key].val = sessionStorage.getItem(key) || '';
                        }
                    } catch(err) {
                        console.log(err);
                        console.log("Cookies and sessionStorage disabled.");
                    }
                }
                // set apikey using remember and refresh cookie
                if (sessionObj.remember.val !== '' && sessionObj.key.val === '') {
                    setSession('key', sessionObj.remember.val);
                    setSession('remember', sessionObj.remember.val);
                }
                that.init = true;
            },
            isSessionExpired: false,
            getOauthParams: function(resp) {
                var referal = ee.views.register.getReferal();
                var params = {
                    googleIdToken: resp.getAuthResponse().id_token,
                    userName: resp.getBasicProfile().getName(),
                    password: ""
                };

                if(store) {
                    store.session('loggedByOAuth', true);
                } else {
                    try {
                        sessionStorage.setItem('loggedByOAuth', true);
                    } catch(err) {
                        console.log(err);
                        console.log("Cookies and sessionStorage disabled.");
                    }
                }

                if (html.widget.Marketing.getSourceId()) { params.sourceid = html.widget.Marketing.getSourceId();}

                if (html.widget.Marketing.getClientSource()) {
                    switch (html.widget.Marketing.getClientSource()) {
                        case 6: //rewirte mobile to googleoauthmobile
                            params.clientsource = 9;
                            break;
                        case 7: //rewirte desktop to googleoauthdesktop
                            params.clientsource = 8;
                            break;
                        default:
                            params.clientsource = html.widget.Marketing.getClientSource();
                            break;
                    }
                }

                try {
                    if (window.sessionStorage.plan) {
                        if ($.isNumeric(window.sessionStorage.plan) && window.sessionStorage.plan > 0) {
                            params.plan = parseInt(window.sessionStorage.plan,10);
                            if (params.plan === 7) { params.maxcontacts = parseInt(window.sessionStorage.planvalue, 10) || 2500 }
                            else if (params.plan === 8) { params.maxcontacts = parseInt(window.sessionStorage.planvaluepro, 10) || 2500 }
                        } else {
                            delete window.sessionStorage.plan;
                        }
                    }
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }

               return _.merge(params, referal);
            },
            getSSOParams: function(resp) {
                const referal = ee.views.register.getReferal();
                const params = {
                    authProviderToken: resp.access_token,
                    userName: resp.profile.name,
                    password: ""
                };

                if (store) {
                    store.session('loggedBySSO', "true");
                } else {
                    try {
                        sessionStorage.setItem('loggedBySSO', "true");
                    } catch(err) {
                        console.log(err);
                        console.log("Cookies and sessionStorage disabled.");
                    }
                }

                if (html.widget.Marketing.getSourceId()) { params.sourceid = html.widget.Marketing.getSourceId();}

                if (html.widget.Marketing.getClientSource()) {
                    switch (html.widget.Marketing.getClientSource()) {
                        case 6: //rewirte mobile to googleoauthmobile
                            params.clientsource = 9;
                            break;
                        case 7: //rewirte desktop to googleoauthdesktop
                            params.clientsource = 8;
                            break;
                        default:
                            params.clientsource = html.widget.Marketing.getClientSource();
                            break;
                    }
                }

                try {
                    if (window.sessionStorage.plan) {
                        if ($.isNumeric(window.sessionStorage.plan) && window.sessionStorage.plan > 0) {
                            params.plan = parseInt(window.sessionStorage.plan,10);
                            if (params.plan === 7) { params.maxcontacts = parseInt(window.sessionStorage.planvalue, 10) || 2500 }
                            else if (params.plan === 8) { params.maxcontacts = parseInt(window.sessionStorage.planvaluepro, 10) || 2500 }
                        } else {
                            delete window.sessionStorage.plan;
                        }
                    }
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }

                return _.merge(params, referal);
            },
            ssoAuthSetup: function () {
                if (!window.Oidc) {
                    console.log('Oidc library is not defined');
                    return
                }

                if (!ee.parameters.authProviderClientID) {
                    console.log('ProviderClientId is missing')
                    return
                }

                const width = 500;
                const height = 500;
                const left = (window.screen.width / 2) - (width / 2) + window.screenY;
                const top = (window.screen.height / 2) - (height / 2) + window.screenTop;

                const configuration = {
                    authority: ee.parameters.authProviderUrl,
                    client_id: ee.parameters.authProviderClientID,
                    redirect_uri: ee.parameters.authProviderRedirectUrl,
                    post_logout_redirect_uri: ee.parameters.authProviderRedirectUrl,
                    response_type: "id_token token",
                    scope: "openid profile email",
                    loadUserInfo: true,
                    revokeAccessTokenOnSignout: true,
                    userStore: new window.Oidc.WebStorageStateStore({ store: window.sessionStorage }),
                    popupWindowFeatures: 'location=no,toolbar=no,width='+width+',height='+height+',left='+left+',top='+top,
                    silentRequestTimeout: 600000,
                    accessTokenExpiringNotificationTime: 180,
                    checkSessionInterval: 600
                };

                if (ee.parameters.authProviderLogoutUrl) {
                    configuration.metadata = {
                        end_session_endpoint: ee.parameters.authProviderLogoutUrl,
                    }
                }
                return new window.Oidc.UserManager(configuration);
            },
            getAllowedAccountFields: function () {
                return allowedAccountFields;
            },
            loadAccount: loadAccount,
            //Set cookis for current account
            loadSubAccount: function (email, redirect) {
                try {
                    sessionStorage.setItem('parentemail', ee.data.account.email);
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
                _loadOtherAccount(email, redirect);
            },
            loadAccountAsAdmin: function (email, redirect) {
                try {
                    sessionStorage.setItem('inspection', 'true');
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
                try {
                    window.Intercom('shutdown');
                }catch{
                    console.log('Cannot find Intercom');
                }
                _loadOtherAccount(email, redirect);
            },
            setAccount: function (apikey, remember, redirect, email) {
                if (email) {
                    try {
                        sessionStorage.setItem('username', email);
                    } catch(err) {
                        console.log(err);
                        console.log("You need to enable session storage");
                    }
                };

                setSession('key', apikey);
                if (remember === true) {
                    setSession('remember', apikey);
                    localStorage.setItem('remember', apikey);
                }

                this.loadAccount(redirect);
            },
            unloadAccount: function (redirect) {
                try {
                    sessionStorage.removeItem('subaccountemailtouse');
                    sessionStorage.removeItem('parentemail');
                    sessionStorage.removeItem('inspection');
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
                delete ee.session.can;
                if (!!ee?.events && !ee.events?.leaveReactApp) {
                    ee.events.leaveReactApp = new Event('leaveReactApp');
                }
                !!ee?.events && window.dispatchEvent(ee?.events?.leaveReactApp);
                this.loadAccount(redirect);
            },
            unset: function (name) {
                return destroySession(name);
            },
            destroy: function () {
                for (var key in sessionObj) {
                    destroySession(key);
                }

                if (window.Intercom) {
                    window.Intercom('shutdown');
                }

                // NOTE: Reported by Piotr. Sometimes ekay has path '/account'
                // (probably only for admins but just for case I add extra key cleaning). I try to find reason. KG
                if(store) {
                    if (store.session('remember')) { store.session.remove('remember', { path: '/account' }) }
                    if (store.session('key')) {  store.session.remove('key', { path: '/account' }); }
                    if (store.session('token')) {  store.session.remove('token');}
                } else {
                    try {
                        if(sessionStorage.getItem('remember')) sessionStorage.removeItem('remember');
                        if(sessionStorage.getItem('key')) sessionStorage.removeItem('key');
                        if(sessionStorage.getItem('token')) sessionStorage.removeItem('token');
                    } catch(err) {
                        console.log(err);
                        console.log("Cookies and sessionStorage disabled.");
                    }
                }

            },
            hasAccess: function (requiredLevel) {
                //add check for stringaccesslevel
                //return ((EnumAccessLevel[requiredLevel.toLowerCase()] & accessLevel) != 0) || html.Access.hasAccess(requiredLevel);
                return html.Access.hasAccess(requiredLevel);
            },
            /* accessors */
            apikey: function () {
                return sessionObj.key.val;
            },
            refreshApiKey: function () {
                const key = store.session.get('key') || store.local.get('key')
                if (ee.data && ee.data.account && key) {
                    if (sessionObj.key.val === sessionObj.adminkey.val) {
                        setSession('adminkey', key);
                    }
                    if (sessionObj.key.val === sessionObj.remember.val) {
                        setSession('remember', key);
                    }
                    setSession('key', key);
                }
            },
            parentApikey: function () {
                return sessionObj.parentkey.val || sessionObj.key.val;
            },
            get: function (key) {
                if (!sessionObj[key]) { return false; }
                return sessionObj[key].val || false;
            },
            set: function (key, value) {
                if (!sessionObj[key] || sessionObj[key].overt !== true) { return false; }
                setSession(key, value);
            },
            isInspection: function () {
                try {
                    return sessionStorage.getItem('inspection') === 'true';
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                    return false;
                }
            },
//ee.data.account.marketingoptions
            hasMarketingConsent: function() {
                if (!ee.data.account.marketingconsent) { return false;}

                if (_.isString(ee.data.account.marketingconsent)) {
                    return _.includes(ee.data.account.marketingconsent, "NewsletterSubscribed")
                }

                if (_.isBoolean(ee.data.account.marketingconsent)) {
                    return ee.data.account.marketingconsent;
                }

                return false;
            },

            hasMarketingOption: function(option) {
                if (!ee.data.account) {
                    console.warn('#ee.session -> hasMarketingOption: acoount not loaded');
                    return false;
                }

                var marketingOptions = _.split(ee.data.account.marketingoptions, ',');

                return _.includes(marketingOptions, option);
            },

            hasOption: function(option) {
                if (!ee.data.account) {
                    console.warn('#ee.session -> hasOption: acoount not loaded');
                    return false;
                }
                var options = _.split(ee.data.account.options, ',');

                return _.includes(options, option);
            },

            setImpersonation: function (data) {
                try {
                    if (sessionStorage.getItem('subaccountemailtouse') && !data._ignoreSub && !data.inheritedAdmin && !data.subaccountemailtouse) {
                        const subaccountemailtouse = (sessionStorage.getItem('subaccountemailtouse').startsWith('"') && sessionStorage.getItem('subaccountemailtouse').endsWith('"')) ? _.trim(sessionStorage.getItem('subaccountemailtouse'),'"') : sessionStorage.getItem('subaccountemailtouse')
                        data.subaccountemailtouse = subaccountemailtouse
                    };
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
                if (data.inheritedAdmin) { delete data.inheritedAdmin };
                if (data._ignoreSub) { delete data._ignoreSub };
            },
            appendEETools: function () {
                $('#eetools').on('click', () => {
                    _showEETools();
                })
            },
            refreshUserData: function (restartDashboard = false) {
                ee.indiOn();

                //custom event to reload account and product data on app
                if(ee.bootedAsChild){
                    const event = new CustomEvent('refreshUserData', { detail: {} })
                    window.parent.document.dispatchEvent(event)
                }
                //

                return EE_API.Account.Load()
                .then((account) => {{
                    ee.indiOff();
                    ee.data.account.accessLevelNew = new dataModel.create("AccessLevel");
                    ee.data.account.accessLevelNew.data = ee.data.account.accountaccesslevel.split(',');
                    var accesslevels = {}
                    ee.data.account.accessLevelNew.data.forEach(function (element) {
                        accesslevels[element] = true;
                    });
                    if (ee.data.account.can) {
                        $.extend(ee.data.account.can, accesslevels)
                    } else {
                        ee.data.account.can = accesslevels;
                    }
                    if (ee.session.can) {
                        $.extend(ee.session.can, accesslevels)
                    } else {
                        ee.session.can = accesslevels;
                    }

                    var parseName = function(data) {
                        if (data.firstname && !data.lastname) { return data.firstname; }
                        if (data.firstname && data.lastname) { return data.firstname + " " + data.lastname; }

                        return false;
                    }

                    ee.data.account.billingdetails = account.billingdetails
                    ee.data.account.pricingPlanName = account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';

                    var data = _.pick(account, allowedAccountFields);

                    data.datecreated_at = moment(data.datecreated).unix();
                    data.lastactivity_at = moment(data.lastactivity).unix();
                    data.lasteventlogload_at = moment(data.lasteventlogload).unix();
                    data.email = _.toLower(data.email);
                    data.username = _.toLower(data.username);
                    data.pricingPlanName = account?.billingdetails?.activeplan?.pricingbillingplandesc || 'Unknown';
                    data.hasPrivateIp = (ee.data.account.mailerid > 0)
                    data.referralLink = `https://elasticemail.com/referral-reward?r=${account.affiliatelink}`;
                    data.referralCode = ee.data.account.affiliatelink;

                    ee.data.account.billingplans = _.cloneDeep(account.billingplans);
                    ee.data.account.billingdetails.plan.planName = ee.getPricingPlanName(account.billingdetails.plan.id);
                    ee.data.account.activePlan = account.billingdetails.activeplan
                    const pricingPlanDesc = account.billingdetails.activeplan.pricingbillingplandesc;
                    ee.data.account.standardProPricing = _.includes(pricingPlanDesc, 'Unlimited') || _.includes(pricingPlanDesc, 'PayPerEmail');

                    if (!_.isEmpty(account.billingdetails.provider)) {
                        ee.data.account.billingdetails.provider.status = _mapProviderStatus(account.billingdetails.provider.status);
                    }

                    ee.data.account.billingmethod = account.billingmethod;
                    ee.data.account.pricingplan = account.pricingplan;
                    ee.data.account.paymentprovider = account.paymentprovider;
                    ee.data.account.maxcontacts = account.maxcontacts;
                    ee.data.account.showautocredit = account.showautocredit;
                    ee.data.account.nextbillingdate = account.nextbillingdate;
                    ee.data.account.remainingcontacts = account.remainingcontacts;
                    ee.data.account.autocreditenabled = account.autocreditenabled;
                    ee.data.account.autocreditlevel = account.autocreditlevel;
                    ee.data.account.autocreditamount = account.autocreditamount;
                    ee.data.account.autocreditstatus = account.autocreditstatus;
                    ee.data.account.emailcredits = account.emailcredits;
                    ee.data.account.credit = account.credit;
                    ee.data.account.istrialbillingplan = account.istrialbillingplan;


                    delete data.datecreated;
                    delete data.lastactivity;
                    delete data.lasteventlogload;

                    data = _.merge({
                        app_id: "a62u0ibr",
                        user_hash: data.secondaryhash,
                        currentUrl: ee.Router.currentPath(),
                        hide_default_launcher: !enableMessenger(account)
                    },
                        data
                    );

                    if (parseName(data)) { data.name = parseName(data); }
                    if (!that.isInspection() && !account.issub && window.Intercom) { window.Intercom("boot", data); }

                    if (
                        account.adminaccesslevel !== "None" &&
                        (
                            account.email.indexOf('@elasticemail.com') !== -1
                            || sessionStorage.inspection
                            || sessionStorage.subaccountemailtouse
                        )
                    ) {
                        const adminAccessLevels = account.adminaccesslevel.split(',');
                        const accessLevelList = _.reduce(adminAccessLevels, (result, value) => {
                            result[value] = true

                            return result
                        }, {})

                        $.extend(ee.data.account.can, accessLevelList)
                        ee.session.can = ee.data.account.can;
                        ee.data.account.admin = true;
                        if (!ee.session.get('adminkey')) {
                            store.session('adminkey', store.session.get('key'));
                        }

                        rundashboard();
                    } else {
                        if (restartDashboard) { rundashboard(); }
                    }

                }
                })
                .catch((e) => {console.log(e)})
            },

            getLanguage: function() {
                return sessionObj?.locale?.val;
            },

            login: function(query, $screen, _alertHandle) {
                ee.indiOn();

                if (!_alertHandle) { _alertHandle = function() { alert('error'); } }

                const userName = (query.username) ?  query.username : "";
                const password = (query.password) ? query.password : "";
                const rememberme = (query.rememberme || query.googleIdToken) ? true : "";
                const googleIdToken = (query.googleIdToken) ? query.googleIdToken :"";
                const authProviderToken = query.authProviderToken ? query.authProviderToken : "";

                return EE_API.Account.Login(query)
                .then(function (data) {
                    if (data.status && typeof data.status === 'string') {
                        data.status = data.status.toLowerCase();
                        if (data.status === 'twofactorcoderequired' || (data.status === 'twofactorcodeexpired' && !query.twofactorauthcode)) {
                            ee.indiOff();

                            ee.views.login.showTwoFactorAuth(userName, password, rememberme, googleIdToken, authProviderToken);
                            return data;
                        }
                        if (query.disable2FA) {
                            data.status += 'backupcode';
                        }
                        if ($screen.twofapasscode) { //if login error occured during this step there must be some problem with 2fa code - remove it and show error.
                            $screen.twofapasscode.val("").trigger("blur");
                        }
                        throw new Error(data.status);
                    }

                    return data;
                })
                .then(function(data) {;
                    var apiKey = ((typeof data === "string") ? data : data.apikey);
                    if (query.disable2FA) {
                        try {
                            sessionStorage.setItem('disabled2FA', 'true');
                        } catch(err) {
                            console.log(err);
                            console.log("You need to enable session storage");
                        }
                    }

                    ee.session.setAccount(apiKey, rememberme, "#/dashboard", userName);
                })
                .catch(_alertHandle);
            }
        }
    })();
})();
