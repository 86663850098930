const english =
{
    "downloadconfirmationtitle": "Download confirmation",
    "downloadconfirmation": "Please, confirm that you want to download the Data Processing Addendum.",
    "thousandsseparator": ",",
    "decimelseparator": ".",
    "dateformat": "dd/mm/yy",
    "datetimeformat": "dd/mm/yy HH:ii:ss",
    "passwordrecovery": "Password Recovery",
    "reset": "Reset",
    "setdefaultcreatetemplate": "Set as default when creating new templates",
    "setdefaultcreatelanding": "Set as default when creating new landing pages",
    "setasdefaulttemplate": "Set as default template",
    "resetdefaulttemplate": "Reset default template",
    "requiredtemplatename": "Template name is required. It will help you find the template faster.",
    "allowforsubaccounts": "Allow for sub accounts",
    "disableforsubacc": "Disable for sub accounts",
    "setdashboardlanguage": "Set the dashboard language",
    "language": "Language",
    "accept": "Accept",
    "male": "Male",
    "female": "Female",
    "menu": "Menu",
    "navigate": "Navigate",
    "avgopenrate": "Avg. open rate",
    "avgclickrate": "Avg. click rate",
    "avgunsubscriberate": "Avg. unsubscribe rate",
    "avgbouncerate": "Avg. bounce rate",
    "allchangessaved": "All changes saved.",
    "saving": "Saving...",
    "templatenotsaved": "Template could not be saved",
    "switchover": "Switch over",
    "saved": "Saved",
    "actions": "Actions",
    "day": "day",
    "enteremailaddress": "Enter email address",
    "confirmemail": "Confirm email",
    "password": "Password",
    "enterpasswordtoconfirmidentity": "Please enter your current account password. We are asking for this to confirm your identity.",
    "username": "User name",
    "accountreadytouse": "Your account is ready for use",
    "workemail": "Work email",
    "needaccount": "Need an account?",
    "haveaccount": "Already have an account?",
    "show": "Show",
    "requirephysicaladdress": "We require your physical address to comply with international anti-spam laws.",
    "continuenextstep": "Continue",
    "transactional": "Transactional",
    "stale": "Stale",
    "notconfirmed": "NotConfirmed",
    "notconfirmedspace": "Not Confirmed",
    "forgot": "Forgot password?",
    "terms": "Terms of Use",
    "termsonly": "Terms",
    "agreeterms": "I have read and agree to the",
    "agreewarning": "You must agree to our Terms of Use.",
    "termsconditions": "Terms & Conditions",
    "readandagreetoterms": "I have read and agree to terms & conditions.",
    "pleasedeleteallemails": "Please delete all emails in my mail queue and reactivate my account.",
    "pleasekeepallemails": "Please keep all emails in my mail queue and refer me to the support to discuss this further.",
    "pleasedeletemyaccount": "Please delete my account.",
    "readanddonotagreetoterms": "I have read and do not agree to terms & conditions.",
    "termsdesc1": "Your account is eligible for re-activation.",
    "termsdesc2": "The following is a binding contract between {{company}} and Elastic Email Inc. Please read the following and re-confirm your agreement to our Terms and Conditions.",
    "marketingconsent": "I would like to receive your newsletter.",
    "apiconsent": "I confirm that I will only send to email addresses that have given me permission to send to them as per our email policy and terms and conditions.",
    "apinotconsentedinfo": "You must confirm that you will only send to email addresses that have given you permission to send to them as per our email policy and terms and conditions.",
    "warning": "Warning",
    "alert": "Alert",
    "redo": "Redo",
    "undo": "Undo",
    "allfieldsrequired": "All fields are required.",
    "injectlogoinemails": "Include Logo Footer",
    "injectlogoinemailstooltip": "Your logo will be included at the end of all emails sent by your Sub-Accounts and will link to your website if you have that configured.",
    "passwordtooshort": "Password should have minimum length of 6 characters.",
    "passwordsnotmatch": "Passwords do not match.",
    "resetpassword": "Reset your password",
    "resetpasswordbtn": "Reset password",
    "resetpassworddesc": "To change your password you have to type it twice.",
    "emailsnotmatch": "Emails do not match.",
    "returntologin": "Return to Log in",
    "invalidemailaddress": "Invalid email address",
    "invalidusernameorpassword": "Invalid username or password.",
    "signin": "Sign in",
    "login": "Log in",
    "loginwith": "Log in with",
    "logout": "Logout",
    "welcome": "Welcome back",
    "classic": "Classic",
    "erroremail": "Please enter a valid email address",
    "errorpassword": "Use 6 characters or more for your password",
    "errorname": "Please enter your name",
    "errorlastname": "Please enter your last name",
    "erroraddress": "Please enter your address",
    "errorpostcode": "Please enter your post code",
    "errorcity": "Please enter your city",
    "errorstate": "Please enter your state",
    "errorpasscode": "Please enter a valid 6-digit authentication passcode",
    "errorbackupcode": "Please enter a valid backup code",
    "invalidauthenticationpasscode": "Please enter a valid 6-digit authentication passcode",
    "invalidbackupcode": "Invalid authentication backup code.",
    "twofapasscoderesendinprogress": "Resend in progress...",
    "esc": "Esc",
    "activateaccount": "Activate Account",
    "thankforsign": "Thank you for signing up!",
    "sendactivationemail": "We have sent you an activation email. Please check your email inbox and click the link in the email to complete the sign up process.",
    "sendactivationemailshort": "We sent you an activation email. Please check your inbox.",
    "noemailinyourinboxresendactivationemail": "No email in your inbox? Resend your activation email.",
    "textresetpassword": "Enter your email address below and we'll send you a link to reset your password.",
    "textafterresetpassword": "A reset password link was sent to your email. <br><br>Please check your inbox for further instructions.",
    "resetpasswordlinksent": "A reset password link<br/>was sent to your email.",
    "pleasecheckinbox": "Please check your inbox for further instructions.",
    "istillcantlogin": "I still can't log in.",
    "letmetalktoarealperson": " Let me talk to a real person",
    "continue": "Continue",
    "minpasswordlength": "6 characters or more",
    "teasertext": "<h3 class='text-center'>Sign up for free account!</h3><small>Create your free account in a few seconds and join thousands of happy customers sending mail with us.</small>",
    "teasertextacymailing": "<h3 class='text-center'>Great choice!</h3><small>Create your free account in a few seconds and join thousands of happy customers sending mail with us. </small><small>Up to 150,000 emails are free every month!</small><br /><h3>Notice</h3><small>Elasticemail.com is not affiliated with or endorsed by the Joomla!® Project or Open Source Matters. The Joomla!® logo is used under a limited license granted by Open Source Matters the trademark holder in the United States and other countries.</small>",
    "teasertextmailpoet": "<h3 class='text-center'>Great choice!</h3><small>Create your free account in a few seconds and join thousands of happy customers sending mail with us.</small> <small>Up to 150,000 emails are free every month!</small><br /><h3>Notice</h3><small>Elasticemail.com is not affiliated with or endorsed by the Wordpress!® Project or Open Source Matters. The Wordpress!® logo is used under a limited license granted by Open Source Matters the trademark holder in the United States and other countries.</small>",
    "loginteaser": "Ready to create a great email experience for your contacts? <br/>Log in and start sending emails your customers will love.",
    "remember": "Keep me logged in",
    "account": "Account",
    "accountdetails": "Account details",
    "accountoverview": "Account overview",
    "overview": "Overview",
    "accountinfo": "Account info",
    "settings": "Settings",
    "addons": "Add-ons",
    "dashboard": "Dashboard",
    "lefttoright": "Left To Right",
    "righttoleft": "Right To Left",
    "multiple": "Multiple",
    "accountcreatesuccesstitle": "Account created",
    "accountcreatesuccesstext": "We have sent an email to your inbox with your account activation link to complete the sign-up process.",
    "signout": "Sign out",
    "createaccount": "Create account",
    "signup": "Sign up",
    "signupforfree": "Sign up for free",
    "signupwith": "Sign up with",
    "nextsignup": "Next",
    "gotosignin": "Go to Log In",
    "invalidindustry": "Please select your industry.",
    "invalidcountry": "Please select your country.",
    "registeruser": "Register user",
    "registerusertext": "Almost there! Please create password:",
    "create": "Create",
    "update": "Update",
    "more": "More",
    "less": "Less",
    "readmore": "Read more",
    "planfeatures": "Plan features",
    "morewitharrow": "More  <i class='fa fa-arrow-circle-down'></i>",
    "lesswitharrow": "Less  <i class='fa fa-arrow-circle-up'></i>",
    "enteryouremail": "Enter your account email address to reset your password",
    "emailsentreset": "An email has been sent to your address with further instructions.",
    "enterwordsbelow": "One more step - please validate your request by completing the form below:",
    "captchaformat": "Choose captcha format",
    "loggedinas": "Logged in as",
    "subaccount": "Sub account",
    "subaccountload": "Load sub account",
    "items": "items",
    "start": "Start",
    "startdesc": "Get started",
    "getadvice": "Get advice",
    "getadviceinformation": "Take your business to the next level with our Dedicated Support options.",
    "reports": "Reports",
    "showreports": "Show reports",
    "morereports": "More reports",
    "viewactivity": "View activity",
    "loading": "Loading...",
    "loadingcontacts": "Loading contacts...",
    "loadingautomations": "Loading automations...",
    "emailmarketingautomationtitle": "Email Marketing Automation done right.",
    "emailmarketingautomationdesc": "Use our new version of automated campaigns to create a visual journey of email sequences. Keep your audience engaged and boost your company's growth.",
    "emailmarketingautomation1": "Create simple but powerful automated funnels",
    "emailmarketingautomation2": "Set up the workflow to build a better connection with your audience",
    "emailmarketingautomation3": "Drive engagement by creating a customized path based on users' action",
    "emailmarketingautomation4": "Send targeted content to the right subscriber at the right time",
    "emailmarketingautomation5": "Grow your business with the right insights of your audience",
    "nodatatoshow": "No data to show",
    "contacts": "Contacts",
    "ofcontacts": "Contacts",
    "contactlist": "Contact list",
    "contactlists": "contact lists",
    "contactlimit": "Contact limit",
    "template": "Template",
    "templates": "Templates",
    "newlandingpage": "New Landing Page",
    "landingpage": "Landing Page",
    "landingpages": "Landing Pages",
    "wasadded": "was added",
    "waschanged": "was changed",
    "wasremoved": "was removed",
    "fontsandcolors": "Fonts and Colors",
    "fontstyle": "Font style",
    "ippool": "IP Pool",
    "pool": "Pool",
    "ipsavailable": "IP(s) available",
    "campaigns": "Campaigns",
    "campaigndetails": "Campaign details",
    "channels": "Channels",
    "channel": "Channel",
    "statistics": "Statistics",
    "trend": "trend",
    "activity": "Activity",
    "ovractivity": "Account overall activity",
    "referrals": "Referrals",
    "queue": "Queue",
    "nouseragentsdata": "It looks like there is no data during the selected period of time.",
    "setup": "Setup",
    "goback": "Go back",
    "gobacktoaccount": "Take me back to account",
    "myaccountbtn": "Return to My Account",
    "returntoactivity": "Return to Activity",
    "back": "Back",
    "forward": "Forward",
    "yes": "Yes",
    "no": "No",
    "idontknow": "I don't know",
    "cancel": "Cancel",
    "canceled": "Canceled",
    "never": "Never",
    "notavaliable": "Not avaliable",
    "ok": "OK",
    "close": "Close",
    "messageid": "Message ID",
    "jobid": "Job ID",
    "view": "View",
    "check": "Check",
    "customize": "Customize",
    "viewemail": "View email",
    "hidedetails": "Hide details",
    "showdetails": "Show details",
    "emailheaders": "Email Headers",
    "smscount": "SMS count",
    "viewsms": "View SMS",
    "smsonly": "SMS only",
    "edithtml": "Edit HTML",
    "editbodytext": "Edit text body",
    "editlayout": "Edit layout",
    "bodytext": "Text body",
    "bodycolor": "Body color",
    "bodypadding": "Body padding",
    "bodytextonly": "Text only body",
    "editbodyamp": "Edit AMP body",
    "bodyamp": "AMP only",
    "bodyampmessage": "Content inside this section will be served to users who can receive AMP-enhanced emails.",
    "addusers": "Add users",
    "bodytextmessage": "* Review your advanced option setting 'Auto create text body'. If this option is enabled, our system will automatically create a text only body from your template if a text only body is not provided",
    "save": "Save",
    "saveandclose": "Save & Close",
    "savecopy": "Save copy",
    "savechanges": "Save changes",
    "copytemplate": "Copy template",
    "copylandingpage": "Copy landing page",
    "dformemail": "Default from email",
    "dfromname": "Default from name",
    "dsubject": "Default subject",
    "preview": "Preview",
    "mobile": "Mobile",
    "desktop": "Desktop",
    "copyendedit": "Copy and<br/>edit",
    "mobilepreview": "Mobile preview",
    "social": "Social",
    "easy": "Easy",
    "medium": "Medium",
    "size": "Size",
    "now": "Now",
    "schedule": "Schedule",
    "editdetails": "Edit details",
    "advanced": "Advanced",
    "showformatque": "Format queue",
    "shownotificationqueue": "Notification queue",
    "showjobqueue": "Job queue",
    "showsubmissionqueue": "Submission queue",
    "send": "Send",
    "sendevery": "Send every",
    "sendlower": "send",
    "sendimmediately": "Send immediately",
    "hours": "hours",
    "minutes": "minutes",
    "minutesbetweensends": "between sends during optimization period",
    "times": "times",
    "optional": "optional",
    "anycampaign": "Any campaign",
    "lastprocessed": "Processed",
    "event": "Event",
    "afterevent": "after event",
    "specificlink": "Specific link contains",
    "triggeraudience": "Audience",
    "templatestosend": "Templates to send",
    "newsubscribers": "New Subscribers",
    "and": "and",
    "or": "or",
    "exit": "Exit",
    "staticlists": "Static lists",
    "lists": "Lists",
    "to": "To",
    "last": "last",
    "defaults": "default",
    "notvaliddate": " is not a valid birth date",
    "backtoyouraccount": "Back to main account",
    "backtoworldmap": "Back to world map",
    "mainaccount": "Main account",
    "deletedcontacts": "Deleted contacts",
    "autoupgrade": "Auto Upgrade",
    "unloadthisaccount": "Unload",
    "errordate": "<b>data</b> variable is incorrect, Unable to parse this values.<br/>",
    "error": "Error",
    "errorupload": "Error upload",
    "savetextbody": "Save text body",
    "saveampbody": "Save AMP body",
    "counting": "Counting",
    "ipaddress": "IP address",
    "payment": "Payment",
    "finished": "Finished!",
    "grabrssfeedbutton": "Grab RSS Feed",
    "rssfetcherrormessage": "An error occured while requesting an RSS Feed. Check if the URL you have entered is correct. Please disable adblock and try again. If the error remains we suggest asking RSS provider about his CORS policy.",
    "paymentregistration": "Payment registration",
    "title": "Title",
    "fromipaddress": "User with IP",
    "contactuploadstart": "are uploaded",
    "contactuploadend": "were uploaded succesfully",
    "howyouwanttoaddcontacts": "How do you want to upload contacts?",
    "contactexportstart": "is exported",
    "contactexportend": "was exported succesfully",
    "contactexportfailed": "was not exported",
    "invalidfileformat": "Invalid file format",
    "invalidfile": "Invalid file",
    "invalidfilemore": "Find out more about how you can prepare your file",
    "contactuploadfiletoolarge": "<b>Your file is too large.</b> Please try to either Zip it or split in to a number of smaller files.<br/><i>Maximum size of the uploaded file is 20Mb.</i>",
    "contactuploadwrongformat": "File <b>{{name}}</b> have invalid format.<br/>Acceptable file format are: <i>zip, csv, json, xml, txt</i>.",
    "contactuploadmissingfile": "The file is missing. Try to upload your file again.",
    "missingfile": "Missing file",
    "uploadinfocolumnname": "Make sure that exactly one column in the uploaded file contains the word EMAIL. The file has to be encoded in UTF-8 standard.",
    "wascreated": "was created",
    "wasdeleted": "was removed",
    "listrandomstart": "is created from randomly selected contacts",
    "listcreationfailed": "was not created",
    "listnthstart": "is currently split into multiple lists.",
    "listrandomcreated": "was succesfully created",
    "listnthcreated": "was succesfully splitted into several lists",
    "segmentcreated": "Segment created",
    "segmentdeleted": "Segment deleted",
    "segmentsaved": "Segment saved",
    "campaigncancel": "was stopped",
    "campaignexportstart": "is exported",
    "campaignexportend": "was exported succesfully",
    "campaignexportfailed": "was not exported",
    "logexportstart": "is exported",
    "logexportend": "was exported succesfully",
    "logexportfailed": "was not exported",
    "linklogexportstart": "is exported",
    "linklogexportend": "was exported succesfully",
    "linklogexportfailed": "was not exported",
    "linkcontactsexportstart": "is exported",
    "passwordchangedlogout": "You must log out and log back in.",
    "apichanged": "All of your current sessions will be logged out.",
    "passwordchangednologout":  "Password changed successfully.",
    "linkcontactsexportend": "was exported succesfully",
    "linkcontactsexportfailed": "was not exported",
    "unsubscribeexportstart": "is exported",
    "unsubscribeexportend": "was exported succesfully",
    "unsubscribeexportfailed": "was not exported",
    "passwordreset": "sent request for password reset",
    "passwordchange": "sent request for password change",
    "passwordreminder": "sent request for password reminder",
    "emailchange": "Email address was changed.",
    "emailreset": "sent request for changing email address",
    "customfieldaddstart": "is being added to all contacts",
    "customfieldaddend": "was added succesfully to all contacts",
    "customfielddeletestart": "is being removed from all contacts",
    "customfielddeleteend": "was removed succesfully from all contacts",
    "paymentstarted": "is being processed",
    "paymentsuccesful": "was succesful",
    "paymentdeclined": "was declined",
    "paymentregistrationstart": "is being verified",
    "paymentregistrationsuccess": "was succesfully registered",
    "paymentregistrationdeclined": "was delined",
    "nonotifications": "No notifications",
    "backtopurchase": "Return to purchase",
    "backtobillingdetails": "Return to billing details",
    "completepayment": "Complete payment",
    "returntopaymentdetails": "Return to payment details",
    "paymentdetails": "Payment details",
    "makepayment": "Make payment",
    "existingprofiledata": "Same as profile details",
    "newbillingdata": "Use different billing details",
    "selectprivateips": "Select Private IPs",
    "billingdetails": "Billing details",
    "orderisbeingprocessed": "Your request is being processed",
    "pleaseselectindustry": "Please select industry",
    "agency": "Agency",
    "beauty": "Beauty",
    "businessservice": "Business service",
    "computers": "Computers",
    "consulting": "Consulting",
    "dailydealsecoupons": "Daily deals / eCoupons",
    "eeommerce": "eCommerce",
    "education": "Education",
    "entertainmentevents": "Entertainment / Events",
    "financialservices": "Financial Services",
    "gaming": "Gaming",
    "government": "Government",
    "healthfitness": "Health / Fitness",
    "insurance": "Insurance",
    "internetsoftware": "Internet / Software",
    "marketingadvertising": "Marketing / Advertising",
    "mediapublishing": "Media / Publishing",
    "mobileapp": "Mobile App",
    "nongovernmentorganization": "Non-Government Organization (NGO)",
    "politicsadvocacy": "Politics / Advocacy",
    "realestate": "Real Estate",
    "retail": "Retail",
    "smallbusiness": "Small Business",
    "socialmedianetworking": "Social Media / Networking",
    "technology": "Technology",
    "telecommunications": "Telecommunications",
    "travel": "Travel",
    "university": "University",
    "selecttutorial": "Select tutorial",
    "starttutorialtitle": "Start your tutorial",
    "starttutorialtext": "To begin your tutorial, click highlighted menu element.",
    "tutorialbutton": "Ok, I got it",
    "overallactivity": "Click the button in order to check your account's performance over the last month",
    "overallactivitytitle": "Overall activity",
    "qualityquantity": "Use <strong>Quantity and Quality</strong> graphs to analyze your account performance",
    "qualityquantitytitle": "Quantity and Quality graphs",
    "tutcompare": "Compare your campaigns’ performance against each other with the newly featured Compare tool ",
    "tutcomparetitle": "Compare tool",
    "newcamptab": "Your campaigns are distributed in to different tabs now. Navigate through them to find your regular campaigns, a/b campaigns, triggered campaigns, etc",
    "newcamptabtitle": "New campaign tabs",
    "firstcampaignpopuptitle": "Marketing tutorial",
    "firstcampaignpopuptext": "Take a quick step by step walkthrough to send your first campaign to yourself.",
    "getapikeypopuptitle": "API key tutorial",
    "getapikeypopuptext": "Learn how to send via SMTP or our API in minutes.",
    "getapikeyfinishtitle": "Great!",
    "getapikeyfinishtext": "You are one step closer to using Elastic Email as a relay for your messaging. Good job!",
    "updateprofilepopuptitle": "Update your profile",
    "updateprofilepopuptext": "It is important that you update all information. Remember that being recognizable is a huge part of success.",
    "uploadcontactspopuptitle": "Upload contacts",
    "uploadcontactslist": "Upload contacts",
    "uploadcontactslistinformation": "Upload, grow and relax! With our contact tools it is easier than you think.",
    "uploadcontactspopuptext": "Email Marketing isn't worth much without your subscribers. Upload your client base right away!",
    "uploadcontactsfinishtitle": "Good job!",
    "uploadcontactsfinishtext": "You know how to store your contacts. Now you can upload and manage your live data.",
    "tutorialcreatetemplate": "Create template",
    "createtemplatepopuptitle": "Create template",
    "createtemplateinformation": "Make your design ideas come to life in just a few clicks.",
    "createtemplatepopuptext": "Edit our pre-designed templates to create email your users will love!",
    "createtemplatefinishtitle": "Classy!",
    "createtemplatefinishtext": "You have contacts, you have template. Now it's time to send some real stuff!",
    "addsubaccountspopuptitle": "Create sub account",
    "addsubaccountspopuptext": "Learn how to manage your subaccounts to better organize your workflow!",
    "addsubaccountsfinishtitle": "All set!",
    "addsubaccountsfinishtext": "Now you can let your clients or coworkers to use the system!",
    "createcampaigntitle": "Send a campaign",
    "createcampaigntext": "Are you ready for your next campaign? Prepare and send it in blink of an eye!",
    "checkreportstitle": "Check activity",
    "checkreportstext": "Take a moment to look at results of your last campaigns. Check and analyze data about your recipients!",
    "connecttosmtp": "Connect to SMTP API",
    "connecttosmtpdesc": "Start sending emails in less than 3 minutes with our standard SMTP configuration.",
    "connecttoapi": "Connect to HTTP API",
    "connecttoapidesc": "Integrate for faster email submission, channel analytics, contact management and more.",
    "configurewebhooks": "Configure Webhooks (Notifications)",
    "configurewebhooksdesc": "Configure HTTP callbacks to notify your application of opens, clicks, bounces, unsubscribes and other email events.",
    "configureinbound": "Configure Inbound Processing",
    "configureinbounddesc": "Have our inbound processing service parse inbound emails and HTTP post the content to your servers.",
    "supersearchtutorialtitle": "Power search tool",
    "supersearchtutorialcontent": "Use the <strong>power search tool</strong> to quickly navigate through entire system. Search for anything you need and get instant access to your contacts, emails, campaigns, templates etc.",
    "notificationstutorialtitle": "Notifications",
    "notificationstutorialcontentaccount": "Instant <strong>notifications</strong> will keep you in touch with all activity in your main account, as well as subaccounts. Each notification provides a link for quick access.",
    "notificationstutorialcontentsubaccount": "Instant <strong>notifications</strong> will keep you in touch with all activity in your account. Each notification provides a link for quick access.",
    "supertotutorialtitle": "Contact search tool",
    "supertotutorialcontent": "Use our <strong>contact search tool</strong> to quickly select your lists of contacts. It's easy - go ahead and try!",
    "splitabtutorialtitle": "A/X Split testing",
    "splitabtutorialcontent": "<strong>A/X split testing</strong> is a way to really test how your mail or message is doing and how your subscribers are responding to it. Test your delivery with different versions of your newsletter - you can test multiple templates in one sending.",
    "startgreeting": "It’s good to have you here. What would you like to do?",
    "dashboardgreeting": "It’s good to have you here!",
    "tutorialfirstcampaign": "I want to send a test campaign",
    "tutorialgetapikey": "I want to get my API key",
    "tutorialupdateprofile": "I want to update my profile",
    "tutorialuploadcontacts": "I want to upload my contacts",
    "tutorialcheckourblog": "Check best tips and tricks from the Elastic Email Blog, or watch our how-to videos on YouTube.",
    "tutorialbrowseresources": "Browse our Resources!",
    "blogtitle": "Marketing resources",
    "blogtext": "Check best tips and tricks from the Elastic Email blog, or watch our how-to videos on YouTube.",
    "blog": "Blog",
    "resourcestitle": "Developer resources",
    "resourcestext": "For detailed API documentation, code libraries and code samples visit our resources page.",
    "resources": "Resources",
    "videotutorials": "Video tutorials",
    "github": "GitHub",
    "apidocs": "API Docs",
    "devguides": "Dev Guides",
    "thiscontentisdisabledduringtutorialtext": "This option is outside of scope of this tutorial.",
    "thiscontentisdisabledduringtutorialtitle": "Please select another option.",
    "sendcampaigninformation": "When it's time to send a real campaign to your contacts.",
    "uploadcontactstutorialsafetestlistselected": "You can store your data in existing list, or create new one. <br><br>For sake of this tutorial please select your existing <strong>Safe test list</strong>",
    "uploadcontactstutorialnocontactfeatures": "Your data will be saved in <strong>All contacts</strong>",
    "newstartscreen": "New design",
    "newstartscreendesc": "Take a look on our new start screen and check improvements we made for you!",
    "startgettingstarted": "Getting started",
    "startemailssent": "Emails Sent",
    "startemailssentlink": "View email history",
    "startemailssentdesc": "Detailed view of all the current mail you have been sending.",
    "startinprogress": "In progress",
    "startinprogresslink": "View activity",
    "startinprogressdesc": "View the activity screen where you can view the current status of pending emails by clicking in progress.",
    "startactivecontacts": "Contacts",
    "startactivecontactslink": "Add contacts",
    "startactivecontactsdesc": "Upload or manually add contacts to your contact list.",
    "startcampaigns": "Campaigns",
    "startcampaignslink": "Send a campaign",
    "startcampaignsdesc": "Send a marketing campaign to your contacts using your email templates.",
    "startcampaignstutorialdesc": "Click here for a walkthrough tutorial on sending a campaign",
    "starttemplates": "Templates",
    "starttemplateslink": "Create a template",
    "starttemplatesdesc": "Create an email template for your transactional mail or for use in a marketing campaign.",
    "startcredit": "Credit",
    "startcreditlink": "Purchase credit",
    "startsetup": "Setup",
    "startsetupdesc": "Setup your domain for SPF and DKIM and view your SMTP Config and HTTP API settings and options.",
    "free": "Free",
    "emailmarketing": "Email marketing",
    "unlimitedprodescription": "Elite class in email marketing tools.",
    "unlimitedpro": "Unlimited PRO",
    "pricing2015": "Pricing2015",
    "insufficientcredits": "Insufficient credits",
    "currentcreditsamount": "Current credits amount",
    "startplanlink": "Upgrade plan",
    "startcreditdesc": "Purchase credit for your account or configure your auto top-up rules.",
    "noaddons": "Discover the additional tools that help you take your business to the next level",
    "startreputation": "Reputation",
    "emailssentbillingperiod": "Emails sent this billing period",
    "getthelatesttips": "Get the latest tips and tricks, marketer's guides and last company updates from our marketing blog.",
    "extensionstoourfeatures": "Looking for extensions to our features and tools? Check out our integration libraries.",
    "takealook": "Take a look",
    "gotolibraries": "Go to libraries",
    "startreputationlink": "View reputation details",
    "startreputationdesc": "View your reputation details and find ways to improve your delivery.",
    "startcostperthousand": "Cost per thousand",
    "startcostperthousandlink": "View pricing level",
    "startcostperthousanddesc": "Find out how to get lower prices!",
    "startreferrals": "Referrals",
    "startreferralslink": "Refer a friend",
    "startreferralsdesc": "Get your referral link to share and earn free credit.",
    "previewreferralsite": "Preview Referral Site",
    "logoimgdescription": "Add the logo of your company or your picture to personalize your dashboard experience.",
    "startsubaccounts": "sub accounts",
    "startsubaccountslink": "Create sub account",
    "startsubaccountsdesc": "Create sub accounts to separate mail for separate accounts or business units.",
    "startdomaintutorialdesc": "Please validate your domain and configure the default sender to be able to send more than 500 emails a day. ",
    "startverpdomaintutorialdesc": "Just one more thing. Your account is currently limited to 500 emails/day until you validate an email that does not belong to a free email provider.",
    "startunsubscribetutorialdesc": "An unsubscribe merge tag is required in all emails.  An unsubscribe link will be added to emails that do not have the merge tag.",
    "startrequiredsmsverification": "To unlock more free emails, please verify your account with SMS code. ",
    "startprofileupdatetutorialdesc_instantaccess": "An activation email has been sent to you. For any help with your account, <a id='openchat'>contact us</a> via the messenger below.",
    "subaccountstartprofileupdatetutorialdesc": "Welcome! Please note, you are limited to 5 emails/day until your account profile is complete and accurate.",
    "activateaccountbeforestartingtutorials": "If you want to run this tutorial, you need to activate your account first.",
    "startpaymentproblems": "Whoops, it seems that there is a problem with your registered payment. ",
    "onretiredblillingplan": "Your free billing plan is being retired at the end of January and we sent you a detailed email about this change.  If you did not receive this email please contact support. If you change your plan to one of our new plans before it expires, you will receive a 50% discount for 1 year. Go to your %%link%% screen and choose 'Change Plan' to take advantage of this special offer.",
    "migratefromgrandfathreplanmodal": "Please confirm you would like to change to one of our new billing plans and receive a 50% discount for 1 year.  Your current plan and this amazing discount expire at the end of January.  If you proceed now to take advantage of this offer you will no longer receive free emails and you will automatically be migrated to one of our latest trial plans with your discount applied.  You will need to choose the appropriate plan and add your credit card.  If you have any questions please contact support prior to making this change.",
    "pleasecontactsupportforupgrade": "Please contact support if you would like to change your grandfathered plan",
    "startsubscribetonewsletter": "Stay up to date with our updates and new feature announcements by subscribing to our monthly newsletter.",
    "startdenysubscribetonewsletter": "No, thanks",
    "startinviteuser": "Invite new user to your account.",
    "startinvitenewuser": "Invite new user",
    "startallworkswelldesc": "Everything is fine!",
    "starttutorialaddcontactstitle": "Add some contacts!",
    "starttutorialaddcontacts": "You may add each contact manually, or upload them as CSV or XML file.",
    "starttutorialcreatetemplatetitle": "Create interesting content!",
    "starttutorialcreatetemplate": "Create your email from scratch using our easy to use Email Designer or browse the template gallery for inspiration!",
    "starttutoriallaunchcampaigntitle": "You are ready to send!",
    "starttutoriallaunchcampaign": "Schedule your first campaign to send your template to your contacts",
    "legacysmtpwarning": "Your account is currently sending through a legacy SMTP connection that will no longer work after May 31/2020.  Please create new <a id='smtpCredentials'> SMTP credentials </a> and update your connections to use the newly created credentials ASAP.  For more information please <a id='openchat'>contact us</a>.",
    "cancelplanmodaldesc": "Are you sure you want to cancel your {{planname}} plan? Your plan will remain active until {{subscriptionbilling}} and will be returned to a Trial plan when it expires. Note that your payment information will be removed and will need to be added again if you would like to continue using our services.",
    "cancelplanheader": "Cancel plan?",
    "switchtext": "This version of our App will be available until November 14, 2022. Return to the latest version of our App.",
    "switchtextcb": "This version of our App will be available until December 8, 2022. Learn more and get prepared to inform your customers <a href='https://elasticemail.com/custom-branding' target='_blank'>here</a>.",
    "confirmcancelplan": "Your plan has been successfully canceled.",
    "startexport": "Exports",
    "export": "Export",
    "exporthistory": "Export history",
    "startexportdesc": "View a list of your recently exported files.",
    "info": "Information",
    "generalinfo": "General information",
    "desc_generalinfo": "If you do not have a physical address in your template you can use the merge tag {accountaddress} included in default templates to merge your address information below.",
    "activationresend": "<p>This activation message was sent,<br/>please check your mailbox</p>",
    "resetpasswordsuccess": "Your password was successfully changed<br/><b>You can login now</b>",
    "wrongphoneformat": "Wrong phone number format. Use digits 0-9 and special signs + , -",
    "activatedsuccess": "Your account has been successfully activated! <b>You can login now</b>",
    "privateippurchasesuccess": "You have successfully re-activated your account and a private IP has been configured for you.",
    "needsomethingmore": "Need more results?",
    "hitenter": " Type at least 4 letters and hit ENTER for a full search",
    "powersearchtool": "Power search tool",
    "startcreatedescription": "Create a campaign, build a survey or make a template.",
    "startreportsdescription": "Review campaign and automation statistics and export results.",
    "startcontactdescription": "Add and edit contacts and create segments or lists.",
    "searchforanything": "Search for anything",
    "searchforlistsandcontacts": "Type here to find your lists and contacts",
    "proceed": "Proceed",
    "hello": "Hello",
    "alertaccountunderreview": "Account under review",
    "neversignedin": "Your account has not yet been activated. Please check your email and click the link that was sent to the email address you created your account with.",
    "login_neversignedin": "Your account is not active yet. Please, check your inbox at {{email}}, open the activation email and click the link. {{{resendlink}}} if you can't find it.",
    "emailhasbeenresent": "Activation email has been resent successfully. Please, check your inbox.",
    "clicktoresend": "Click to resend",
    "resendactivationemail": "Resend the activation email",
    "sendactivationagain": "Send activation mail again please »",
    "backtosingup": "Back to signup form »",
    "enablecookies": "Are cookies out of menu? Please consider logging in with the cookies on for the best Elastic Email experience.",
    "newaccount": "Welcome <b>{{email}}</b>! Your account is now active and you are ready to get started.  Below are quick links to common tasks.",
    "disabledaccount": "Your account has been disabled and you are not permitted to log in to our system.  Please contact support for further information or help with your account.",
    "underreviewyouraccount": "Your Account",
    "iscurrentlyunderreview": "is currently under review and all emails have been paused.",
    "iscurrentlyunderreviewitappears": "is currently under review and all emails have been paused because it appears that more than one main account has been created, which is violation of our",
    "youarenotallowedtohavemore": "You are not allowed to have more than one account sending the same or similar content. If your account is suspended or canceled, creating another account may result in its cancellation.",
    "iscurrentlyunderreviewquestionablecontent": "is currently under review and all emails have been paused because of questionable content you are sending from your account.",
    "sendingemailsthat": "Sending emails that do not comply with the",
    "pleasereviewyouremailcontent": "Please review your email content and only then proceed with unblocking your account.",
    "iscurrentlyunderreviewbecause": "is currently under review because the most recently added domain is blacklisted. This is a violation of our terms as stated in our",
    "acceptableusagepolicy": "Acceptable Usage Policy",
    "istreated": "is treated as violation of our",
    "pleasereviewthisexcerpt": "Please review this excerpt from our",
    "pleasereviewthestatus": "Please review the status of your domain and once delisted, please proceed with unblocking your account.",
    "underreview": "is currently under review and all emails have been paused.",
    "iscurrentlyunderpoorreputation": "is currently under review and all emails have been paused due to your account's poor reputation. If you are receiving this notification, there is most likely more than one issue to resolve with your reputation score.",
    "iscurrentlyundercomplaintsregarding": "is currently under review and all emails have been paused due to the high level of complaints regarding your communication. Complaints are tracked when contacts mark your email as junk or spam. Industry standard for tolerable complaint threshold is 0.1% (1 email per 1000) - we noticed there is a higher number on your account.",
    "iscurrentlyundercomplaintindications": "is currently under review and all emails have been paused as there are indications that emails your account is sending are unsolicited. Sending unsolicited email is against our",
    "iscurrentlyunderallemails": "is currently under review and all emails have been paused due to the high level of invalid addresses you're sending to. Please see your account's Reputation for more information.",
    "pleasereviewyourdeliveryhistory": "Please review your delivery history and your contact lists and then proceed with unblocking your account.",
    "wehavepausedalloutgoing": "We have paused all outgoing email until this issue is resolved. Your email may or may not resume if your account is reactivated, depending on the outcome of the following review.",
    "pleasereviewyourcontactlistonly": "Please review your contact list and only then proceed with unblocking your account.",
    "underreview1": "Our internal spam prevention measures have identified email sent from your account as being in contravention of this",
    "underreview2": "Please review the content of your emails and once ready, proceed to unblock your account by filling in the Re-activation Form.",
    "reactivationform": "Re-activation Form",
    "underreviewbuttonname": "{{buttonname}}",
    "nopaymentsallowed": "Your account is not allowed to purchase more credit. If you need more information please contact support.",
    "throttled": "You are experiencing poor responses by destination servers so we are currently limiting your sending to Engaged contacts only to try and help improve your delivery. Please see your <a class=\"alert-link\" href=\"#/account/reputation\">reputation</a> for more information.",
    "throttledline1": "You are experiencing poor responses by destination servers so we are currently limiting your sending to Engaged contacts only to try and help improve your delivery. ",
    "throttledline2": "Please see your ",
    "throttledline3": "reputation",
    "throttledline4": " for more information.",
    "dailysendlimitalert": "Your account is currently limited to {{dailysendlimit}} emails/day.",
    "alertadvancedtoolsoff": "Our advanced delivery engine requires detailed statistics about your contact's engagement and behavior. It uses this information to optimize your delivery to ensure the highest possible delivery rates. Turning this feature off may affect your overall delivery rate or contact engagement. Are you sure you would like to disable this feature?",
    "novaliddomain": "<b>Send From Your Own Domain</b> - To send directly from your own domain see <a href=\"#/account/advancedoptions\">Use Your Own Domains</a> found under Advanced Settings on your Account page. Please see <a href=\"{{domainauthorization}}\" target=\"_blank\">/domain-authorization</a> for detailed information on setting up your own sending domain.",
    "wrongdomain": "You entered the incorrect domain format",
    "choosedomain": "Choose a domain",
    "validdomainwarning": "Your account is currently limited to 500 emails/day until a valid domain with a default sender has been configured.",
    "verpdomainwarning": "Your account is currently limited to 500 emails/day until you validate an email that does not belong to a free email provider.",
    "emailaddressisempty": "Email address is empty",
    "defaultmessage": "Welcome <b>{{email}}</b>! Here you will find an overall summary of your account and quick links to common tasks.",
    "filloutaccountprofile": "We need a little more information to ensure your mail goes out safely.<br/>Please fill out fields below:",
    "campaignfillaccountprofile": "Before starting this campaign please fill out your account profile.<br/>This is required by anti-spam legislation.",
    "profilefillaction": "Click here to update your profile",
    "disablerequiresprofile": "Disable Requires Profile",
    "enablerequiresprofile": "Enable Requires Profile",
    "estimatedcost": "The estimated cost of the campaign",
    "unitcost": "Unit cost",
    "clicktoupdate": "Click here to update.",
    "timeouttext": "This page tried to connect with server, but the connection timed out.<br/> Do you want to refresh page?",
    "timeouttitle": "Connection Timeout",
    "youracc": "Your account",
    "pleasereviewthecontent": "Please review the content of your emails and once ready, proceed with unblocking your account.",
    "pleasereviewyouraccount": "Please review your account and once you are ready proceed with unblocking your account.",
    "hasbeenputunderreview": "has been put under review and all emails have been paused because some of your communication is failing a spam check by Spam Assassin.",
    "modalunderrewievcontentii": "Please review the content of your emails and once ready, proceed to unblock your account by filling in the Re-activation Form.",
    "accountsuspensionorcancellation": "We suspect that your account is being used in contravention of this Acceptable Use Policy or otherwise in a manner that is harmful to Elastic or the Services, we may restrict your access or use of your account, including by suspending or canceling your account. <br> <br>Our internal spam prevention measures have identified email sent from your account as being in contravention of this Acceptable Use Policy.",
    "acceptableusepolicy": "Acceptable Use Policy",
    "iscurrentlyunderreviewandallemails": "is currently under review and all emails have been paused as we have received a direct spam complaint from one of your contacts.",
    "pleasereviewyourcontactlist": "Please review your contact list, and once ready, proceed with unblocking your account.",
    "fillinreactivation": "FILL IN-REACTIVATION FORM",
    "iscurrentlyunder": "is currently under review due to violation of our",
    "choosecontacttype": "Choose field type",
    "birthday": "Birthday",
    "phone": "Phone",
    "street": "Street address",
    "city": "City",
    "zip": "Zip",
    "zipcode": "Zip code",
    "state": "State",
    "country": "Country",
    "consent": "Consent",
    "streetaddress": "Street address",
    "currency": "Currency",
    "createwebform": "Create form",
    "createsegment": "Create Segment",
    "savesegment": "Save Segment",
    "createsegmentdesc": "Create segments to target subscribers based on various parameters like location, engagement and activity. ",
    "newsegment": "New Segment",
    "unknowignore": "Unknown - Ignore",
    "createnewfield": "Create new field",
    "contactfield": "Contact field",
    "placeholderinteger": "Integers only",
    "placeholderdecimal": "Numbers only, both integers and decimals",
    "placeholderdatetime": "Date in provided format: MM/DD/YYYY HH:mm",
    "placeholderboolean": "'true' or 'false'",
    "placeholdertext": "Text field",
    "uploadcontactplaceholder": "Type, paste text or drop a file*",
    "advancedsettins": "Advanced settings",
    "choosefieldtype": "Choose field type",
    "containssmall": "contains",
    "contactfieldsplaceholder": "Type column name",
    "boolean": "Boolean",
    "outof": "out of",
    "number": "Number",
    "decimal": "Decimal",
    "datetime": "DateTime",
    "bignumber": "Big number",
    "maxlength": "Max length",
    "enterfieldname": "Enter field name",
    "fieldname": "Field name",
    "booleantrue": "True",
    "booleanfalse": "False",
    "enablecaptcha": "Add ReCaptcha to your form",
    "descenablecaptcha": "Enable Google ReCaptcha (requires jQuery)",
    "descenabledoubleoptin": "Send double opt in confirmation email",
    "disablecontactfeature": "<b>Contact Features Enabled</b> - Your account is using contact management tool now. Please click <a href=\"\" class=\"edisablecontactfeature\">here</a> to disable.",
    "selectcontactslist": "Select a contacts list",
    "setuploadedstatus": "Set uploaded contacts status to",
    "uploadedcontacts": "Uploaded contacts",
    "uploadcontactsfilesinfo": "We support various formats but for the smoothest performance we recommend .csv file. Make sure that the file has included field labeled 'email'. ",
    "howpreparefile": "Find out more about how you can prepare your file ",
    "here": "here",
    "uploadedcontactstooltip": "This is the number of contacts uploaded during this session regardless of their status.",
    "contactsuploaded": "Contacts uploaded.",
    "usedaccountstorage": "Used account storage",
    "usedaccountstoragetooltip": "This is the number of contacts stored in your account during this billing period based on your current plan limit.",
    "availableaccountstorage": "Available account storage",
    "availableaccountstoragetooltip": "This is the number of contacts you can still store on your account.",
    "backtoactive": "Restore to active",
    "addcontacts": "Add contacts",
    "addcontactsbtn": "Add Contacts",
    "addcontactdetails": "Add contact details",
    "choosecontactlist": "Choose contact list",
    "setcontactpermissions": "Set contact permissions",
    "youcaneasilyuploaddesc": "You can easily add your contact to the existing lists by filling the inputs below. If you want to add more contacts than one use Upload Contacts page.",
    "addagroupofcontacts": "Add a group of contacts.",
    "addgroup": "Upload contacts",
    "managecontacts": "Manage",
    "managecontactsbuttton": "Manage contacts",
    "customfields": "Custom Fields",
    "customfield": "Custom field",
    "managefields": "Manage custom fields",
    "fieldtype": "Field type",
    "fieldname": "Field name",
    "fieldlength": "Field length",
    "datatype": "Data type",
    "addcustomfields": "Add Custom Fields",
    "managecontactfields": "Add, remove or reorganize data about your contacts that will help you increase engagement.",
    "needtips": "Need any tips?",
    "removecustomfield": "Remove custom field",
    "confirmdeletefield": "Are you sure you want to remove field: ",
    "deletefieldalert": "All contact data associated with this field will be deleted, this operation can not be undone.",
    "findmorecustomfields": "Find more about Custom Fields.",
    "ttmanagecontactfields": "Add, remove or reorganize data about your contacts that will help you increase engagement. Need any tips? Find out more about <a href='http://help.elasticemail.com/contacts/how-to-create-custom-contact-fields' ><b>how to use Custom Fields</b></a> in the best way.",
    "managecontactfieldsoutside": "Add, remove or reorganize data about your contacts that will help you increase engagement.",
    "managecontactsimportant": "Changes you will make will affect all of your contacts.",
    "deliverytool": "Add contact delivery and optimization tools to your plan",
    "deliverytool_desc": "Optimization tools for managing your contacts and empowering you to maximize the ROI from your email campaigns.",
    "deliverytool_info": "Contact delivery and optimization tools are enabled by default. If you do not require these features, disable them above and you will not be charged. ",
    "enablecontactfeatures": "Contact delivery tools",
    "desc_enablecontactfeatures": "Take advantage of the impressive possibilities of our contact delivery and optimization tools such as contact segmentation. Contact delivery tools will not work on subaccounts unless the feature is enabled on parent account.",
    "requestpremiumsupport": "Add Dedicated Support",
    "addpremiumsupport": "Add premium support",
    "addprioritysupport": "Add priority support",
    "freesupportdesc": "No matter how skilled you might be, sometimes we all need a little support. We are here to help you succeed with your email needs.<br> Get fast, accurate and personalized support from our team 24/7.",
    "prioritysupportdescview": "Our support response times are fast, but if you want 'ahead of the line' support, then Priority Support is for you. <br> When you connect with us via the In-App Messenger, email or our website, your query is prioritized ahead of non-priority customers.",
    "premiumsupportdescview": "The \"White-Glove\" support product with the highest priority response times available. When you connect with us via the In-App Messenger, you will receive instant chat engagement from our global team 24/7. You will also receive a dedicated Account manager where issues can be escalated to if needed. Your Account Manager will also pro-actively review your Account periodically to make sure everything is in order and ensure you have all the configuration settings optimized for your particular sending habits. Sound like something that you are looking for? <a style=\"cursor:pointer\" id=\"contactUs\"><strong>Contact us</strong></a> for more details.",
    "contactsales": "Contact sales",
    "helpcenter": "Help center",
    "dedicatedsupport": "Dedicated support",
    "buypremiumsupport": "Buy Premium Support",
    "premiumpayment": "Premium Support requires registered payment method. <br /> Choose payment method to complete purchase.",
    "prioritypayment": "Priority Support requires registered payment method. <br /> Choose payment method to complete purchase.",
    "contactsalesheading": "Have you got any questions about custom options or prices?",
    "contactsalesparagraph": "Do not hesitate to contact our Sales team. We are happy to answer all your questions!",
    "adddedicatedsupport": "Add Dedicated Support",
    "dedicsupport": "Dedicated Support",
    "support": "Support",
    "emailsupportoption": "Email Support - FREE",
    "prioritysupportoption": "Priority Support - $1/Day for In-App Support",
    "premiumsupportoption": "Premium Support - $7/Day for In-App Real-Time Chat Support",
    "enablepremiumtoolsdesc": "Enable powerful optimization tools for managing your contacts and empowering you to maximize the ROI from your email campaigns.",
    "requestpremiumsupportdesc": "Need a little extra love?  Receive personalized help and advice about your Elastic Email account in your time zone with a dedicated account manager via our Premium Support chat.  The cost is only $7/day with a 30 day minimum.",
    "buypremiumsupportdesc": "Get in touch with our experts to maximize your results and receive personalized advice about your account via our Premium Support chat.",
    "buyprioritysupportdesc": "Receive priority help from our team ahead of non-priority customers.",
    "buypremiumsupportdescprice": "The cost is only ${{price}}/month.",
    "buydedicatedsupportdescprice": "The cost is only ${{price}}/month.",
    "paymentprocessedsuccesfully": "Payment processed successfully",
    "paymentprovider": "Payment provider",
    "privateiprequestsuccesful": "Your private IP(s) are being prepared. We have sent you your receipt via email.",
    "premiumsupportrequestsuccesful": " It will take a moment to  set up your Premium Support. We have sent you your receipt via email.",
    "prioritysupportrequestsuccesful": " It will take a moment to  set up your Priority Support. We have sent you your receipt via email.",
    "remainingbillingperiod": "prorated amount for remaining days in the current billing period",
    "contactmanagement": "Contact management",
    "campaignmanagement": "Campaign management",
    "deliveryoptimization": "Dynamic delivery optimization based on contact engagement and history",
    "detailedanalytics": "Detailed contact analytics",
    "trackengagement": "Track engagement with your customers",
    "additionalcontactfields": "Additional contact fields including social media fields",
    "detailedcontacthistory": "Detailed contact event history tracking",
    "trackingdatanotavailable": "The tracking data will be visible in the upcoming days.",
    "multilist": "Multi-list management",
    "listgeneration": "Random and nth list generation",
    "segmentingtools": "Powerful segmenting tools and history",
    "axsplitcampaign": "A-X split campaign testing",
    "campaignscheduling": "Campaign scheduling",
    "dynamicaudienceselection": "Dynamic real-time audience selection",
    "autoresponder": "Autoresponder",
    "autoresponders": "Event driven campaigns (autoresponders)",
    "attachmenteditors": "Attachment editor for your campaigns",
    "attachmentmissing": "The campaign cannot be sent because one of the attachments is no longer available in Media Manager",
    "templatescripting": "Custom template scripting for dynamic content",
    "clicktrackingbylink": "Detailed click tracking by link",
    "andmore": "And more",
    "ttenablecontactfeatures": "Click here to enable contact delivery tools on your Account.",
    "ttrequestpremiumsupport": "Receive personalized help and advice about your Elastic Email account in your time zone.",
    "featuredescription1": "The following advanced features are available for an additional cost of <strong>$0.19/1000 contacts per month</strong> for the first 1,000,000 contacts and <strong>$0.09/1000 contacts per month</strong> for remaining contacts.",
    "featuredescription2": "The following advanced features are available for an additional cost of<br/><strong>$0.29/1000 contacts per month</strong> for the first 100,000 contacts,<br/><strong>$0.19/1000 contacts per month</strong> for next 1,000,000 contacts,<br/><strong>$0.09/1000 contacts per month</strong> for next 10,000,000 contacts,<br/><strong>$0.03/1000 contacts per month</strong> for remaining contacts.",
    "copycontacts": "Copy contacts",
    "addcontactsctolist": "Add Contacts to List",
    "addcontactsto": "Add contacts to",
    "addcontactto": "Add contact to",
    "allowselectinglist": "Make lists optional for subscribers",
    "help": "Help",
    "created": "Created",
    "searchlogsplaceholder": "Search logs by email address",
    "allcontacts": "All Contacts",
    "all": "All",
    "allcontactssmall": "all contacts",
    "selectstatus": "Change status to",
    "selected": "Selected",
    "list": "List",
    "segment": "Segment",
    "segments": "Segments",
    "createlist": "Create List",
    "createlistdesc": "Use lists to easily categorize subscribers by things like interests and preferences. ",
    "copylist": "Copy List",
    "rule": "Rule",
    "createnewlist": "Create new list",
    "savecontactstolist": "Create static list",
    "ttsavecontactstolist": "Save selected contacts to new list",
    "tolist": "to list",
    "contactsgrowth": "Contacts growth",
    "contactsgrowthdesc": "Build relationships. Grow your list with our features and create a form or a landing page to collect new contacts.",
    "accountstorage": "Account storage",
    "accountstoragedesc1": "Each upload of your contacts will consume your storage limit. ",
    "accountstoragedesc2": "your current plan to increase the limit.",
    "learnmoreabout": "Learn more about",
    "countingcontacts": "how the contacts are counted.",
    "recentgrowth": "Recent growth",
    "recentgrowthdesc": "Changes in audience in the last 30 days.",
    "audienceperformance": "Audience performance",
    "avgclick": "Avg. click rate",
    "avgopen": "Avg. open rate",
    "createwebform": "Create Web Form",
    "createlanding": "Create Landing Page",
    "savelist": "Save List",
    "runquery": "Run query",
    "query": "Query",
    "subquery": "Add sub-rule",
    "custom": "Custom",
    "statistic": "Statistic",
    "setstatus": "Set Status",
    "exportdata": "Export",
    "fileformat": "File format",
    "copy": "Copy",
    "copysegment": "Copy segment",
    "copylink": "Copy link",
    "copycode": "Copy code",
    "copyhtmlcode": "Copy HTML code",
    "copyformcode": "Copy Form Code",
    "copycodedesc": "Copy and paste the code to your website. Visitors who subscribe will be added to your contact list. You may change the order of the fields or style to fit your site.",
    "copytolist": "Add to list",
    "movetolist": "Move to list",
    "showcontacts": "Show contacts",
    "showhistory": "Show history",
    "hidehistory": "Hide history",
    "trigger": "Trigger",
    "webform": "Web Form",
    "fileupload": "File Upload",
    "deleteform": "Are you sure you want to delete this form?",
    "webforms": "Web forms",
    "newform": "New form",
    "formname": "Form name",
    "forms": "Forms",
    "myforms": "My forms",
    "formsdescription": "Use this tool to create a subscribe form which you can embed on your website to grow your email list.",
    "formchooseappearance": "Choose form appearance",
    "descformchooseappearance": "How your form will be generated: as part of the existing sidebar, as a popup or sticked to the bottom of the screen",
    "formchoosedelay": "Choose form delay",
    "descformchoosedelay": "How much time it will take from opening of the page until the appearance of the form.",
    "formchoosestyle": "Select style",
    "descformchoosestyle": "Choose style for your form.",
    "formchoosecolorpalette": "Select color scheme",
    "descformchoosecolorpalette": "Choose predesigned color scheme for your form.",
    "formchooselabels": "Choose helpers display",
    "descformchooselabels": "Form fields can be labeled or have placeholders inside. Choose any version you like.",
    "labels": "Labels",
    "label": "Label",
    "formaddheader": "Add headers to form",
    "descformaddheader": "Personalize your form, add welcome message, encourage people to subscribe",
    "formtitle": "Form title",
    "formdescription": "Form description",
    "descformsticky": "Simple form with only one field - email. Use it for option to allow quick subscription.",
    "formconsent": "I consent to have my data being collected and stored from this form",
    "formtrackingconsent": "Allow tracking of my email activity such as opens and clicks",
    "trackingtooltip": "Use this setting if you have contacts that have explicitly enabled or disabled in email tracking such as opens and clicks - this consent will be stored with the contact records.",
    "trackingconsent": "Tracking consent",
    "trackingconsentquickadd": "This person permitted to allow tracking activity (eg. tracking opens and clicks).",
    "required": "Required",
    "requiredcheck": "Required",
    "properties": "Properties",
    "defaulttemplate": "Default",
    "placeholder": "Placeholder",
    "statisticlist": "List statistics",
    "makelist": "Make list",
    "deletelist": "Delete List",
    "createfirstlist": "You do not have any lists.",
    "engaged": "Engaged",
    "rejected": "Rejected",
    "active": "Active",
    "paused": "Paused",
    "bounced": "Bounced",
    "inbound": "Inbound",
    "duplicated": "Duplicated",
    "syntaxerror": "Syntax error",
    "unsubscribed": "Unsubscribed",
    "subscribed": "Subscribed",
    "complaint": "Complaint",
    "complaints": "Complaints",
    "inactive": "Inactive",
    "spam": "Spam",
    "unsub": "Unsub",
    "readytosend": "Ready to send",
    "waitingtoretry": "Waiting to retry",
    "firstname": "First name",
    "lastname": "Last name",
    "firstnameshort": "First",
    "lastnameshort": "Last",
    "score": "Score",
    "source": "Source",
    "added": "Added",
    "addedvia": "Added via",
    "addedday": "Added day",
    "updated": "Updated",
    "overallcontactsclicks": "Overall clicks for specific links",
    "dataiscollectedfrom": "Data is collected from 2016-09-07.",
    "createdfromip": "Created from IP address",
    "consentip": "Consented from IP address",
    "consentdate": "Consented date",
    "consenttracking": "Consented tracking",
    "trackingallow": "Allow - Contacts have explicitly agreed to permit email tracking.",
    "trackingdeny": "Deny - Contacts have explicitly requested to disable all forms of tracking.",
    "trackingunknown": "Unknown - Email tracking will be enabled.",
    "search": "Search",
    "searchforsegments": "Search for segments",
    "searchforlists": "Search for lists",
    "searchforcontacts": "Search for contact",
    "searchforfields": "Search for fields",
    "searchforcampaigns": "Search for campaigns",
    "is": "Is",
    "isnot": "Is not",
    "contains": "Contains",
    "notcontains": "Not contains",
    "startswith": "Starts with",
    "endswith": "Ends with",
    "monthday": "Month/Day Only",
    "with": "with",
    "equals": "Equals",
    "notequals": "Not equals",
    "of": "of",
    "on": "on",
    "editlist": "Edit List",
    "editform": "Edit Form",
    "allowunsubscribe": "Enable on Subscription Management",
    "subscriptionmanagement": "Subscription management",
    "movecontacts": "Move contacts to list",
    "amountcontacts": "contacts will be added to this list.",
    "contactdelete": "Delete from all lists",
    "contactremove": "Remove from this list",
    "deletecontactsmsg": "Are you sure you want to delete the selected contact(s) from your account?",
    "deletecontactsalertmsg": "This action cannot be undone.",
    "deleteallcontactsmsg": "<b>You have all contacts selected.</b><br/><br/>Are you sure you want to delete the selected contact(s) <u>from your account</u>?<br/><br/><b>The selected contact(s) will be deleted from all lists and this action cannot be undone</b>.",
    "removecontactsmsg": "<b>You have {{amount}} selected contact(s).</b><br/><br/>Are you sure you want to remove the selected contact(s) <u>from this list</u>?<br/><br/><b>The selected contact(s) will not be deleted from your account or removed from other lists</b>.",
    "removeallcontactsmsg": "<b>You have all contacts selected.</b><br/><br/>Are you sure you want to remove the selected contact(s) <u>from this list</u>?<br/><br/><b>The selected contact(s) will not be deleted from your account or removed from other lists</b>.",
    "inactivecontactemsg": "Are you sure you want to remove the selected contact(s) <u>from this list</u><br /><br/><b>Note: If you have sent emails to these contacts in the past month you will not be able to delete them, but they will be marked as 'inactive'.</b>.",
    "inactivedelcontactmsg": "Are you sure you want to delete the selected contact(s) <u>from your account</u>?<br/><b>If selected contact(s) are 'younger' than one month and were email sent to them, they could be only inactive</b>.",
    "removecontactsheader": "Delete contacts",
    "changestatus": "Change status",
    "changestatustitle": "Change Status",
    "changeconsenttracking": "Change consent tracking",
    "targetlist": "Target list",
    "newlist": "New list",
    "existinglist": "Existing list",
    "emptylistname": "List has to be named.",
    "duplicatedlistname": "List with this name already exists.",
    "duplicatedsegmentsname": "Segment with this name already exists.",
    "deletecontactsstaymsg": "Contacts will not be deleted.",
    "segmentdeleted": "Segment deleted.",
    "deletesegmentmsg": "Are you sure you want to delete this segment and all of it's history?",
    "deletelistmsg": "Are you sure you want to delete this list and all of it's history?",
    "deleteexportmsg": "Are you sure you want to <strong>permanently</strong> delete file: ",
    "moreactions": "More actions",
    "wasdeleted": "was deleted",
    "deletelistheader": "Delete list",
    "deletesegmentheader": "Delete segment",
    "deleteexportheader": "Delete export file?",
    "usezipcompression": "Use ZIP compression",
    "eventtype": "Event Type",
    "contacthistory": "Contact event history",
    "websiteurl": "Website URL",
    "annualrevenue": "Annual revenue",
    "industry": "Industry",
    "numberofemployees": "Number of employees",
    "facebook": "Facebook ID",
    "twitter": "Twitter ID",
    "googleplus": "Google+ ID",
    "google": "Google",
    "linkedin": "LinkedIn ID",
    "visits": "Visits",
    "pageviews": "Page views",
    "firstpurchase": "First purchase",
    "lastpurchase": "Last purchase",
    "purchasecount": "Number of purchases",
    "lastmonth": "Last month",
    "stats": "Stats",
    "lastopened": "Last opened",
    "lastclicked": "Last clicked",
    "nolastmonthsent": "Sorry, no information about emails sent during last month.",
    "nolastmonthopened": "Sorry, no information about emails opened during last month.",
    "nolastmonthclicked": "Sorry, no information about emails clicked during last month.",
    "twitterusername": "Twitter username",
    "twitterprofilephoto": "Url for Twitter profile photo",
    "twitterfollowercount": "Number of Twitter followers",
    "twitterbio": "Twitter user bio",
    "linkedinbio": "LinkedIn bio",
    "linkedinconnections": "Number of LinkedIn connections",
    "faxnumber": "Fax number",
    "mobilenumber": "Mobile number",
    "errorlistname": "Please enter the name for the list",
    "listexists": "List exists",
    "thesamelistname": "A list with the given name already exists",
    "fieldsfound": "defined contacts fields",
    "recognizedfields": "recognized contact fields",
    "recognizedfield": "recognized contact field",
    "duplicates": "repeated",
    "addcontactsproblemwithfile": "No contacts were provided or the file was uploaded incorrectly. Please try again.",
    "addcontactsfileandtext": "We can analyse at once only one source of data. Add a file or text input and try again",
    "addcontactsproblemwithtext": "There was an error in analysis of your input, so we couldn't find any contacts. Please make sure that each entry has email address",
    "addcontactsproblemwithentry": "We couldn't parse the given contacts or there were no valid emails. Please make sure that each entry contains email address",
    "addcontactsproblemwithpattern": "We couldn't connect given contacts with the pattern you provided. Please try again",
    "addcontactslistexists": "List with selected name already exist. Please select it from the 'Existing list' dropdown or provide other name.",
    "addcontactscsverrorinentries": "Fields were recognised succesfully, but provided contacts doesn't match the fields.",
    "addcontactsunknownerror": "There was a problem with data you provided. Please check if it is properly formatted and try again.",
    "sendcontacts": "Send contacts",
    "uploadingpleasewait": "We are uploading and analysing your data. It may take a while. Please don't close this page.",
    "typelistname": "Type name for your new list",
    "typesegmentname": "Type name for your new segment",
    "segmentname": "Segment name",
    "definefields": "Define contact fields",
    "removeduplicates": "Please note that we will automatically remove all duplicates available on the uploaded list.",
    "cleanlist": "Clean list (1c/1000 contacts)",
    "additionaldata": "Additional data",
    "approvedupload": "Approved - upload!",
    "contactfields": "Contact fields",
    "dropfiletouploadcontacts": "Drop file to upload contacts",
    "descdropfiletouploadcontacts": "You can upload  .txt, .xml, .csv and .json files.",
    "confirmationofterms": "Confirmation of terms",
    "contactsfields": "Contact fields",
    "assigncontacts": "Assign contacts",
    "assigncontactto": "Assign contact to",
    "confirmation": "Confirmation",
    "uploadcontacts": "Upload contacts",
    "uploadcontactsbtn": "Upload Contacts",
    "weareuploadingyourdata": "We are uploading your data",
    "pleasedonotclosethisview": "Please, do not close this view",
    "skiptoassigncontacts": "Skip to assign contacts",
    "contactfieldsfound": "Contact fields found",
    "randomcontactfromupload": "Random contact from added",
    "savesettings": "Save settings",
    "selectlistname": "Select list name",
    "approveandsave": "Approve and save",
    "wehavefoundthattheuploaded": "We have found that the uploaded",
    "wehavefound": "We have found",
    "wehavefoundinfile": "We have found that the uploaded file contains",
    "descuploadcontacts": "Please, define the unknown contact fields or skip to options",
    "uploadcantakeamomentpleasecheckaddresses": "It can take a moment. Meanwhile, have a look at types of addresses we have found.",
    "pleasedefineunknowncontactfields": "Please, define the unknown contact fields or skip to options. Please note that you might not have access to all available contact fields or you're trying to add contact fields that don't exist.",
    "contactshavebeenuploaded": "Please, define the unknown contact fields or skip to options. Please note that you might not have access to all available contact fields or you're trying to add contact fields that don't exist.",
    "contactshavebeenuploadedlittle": "Contacts are being uploaded.",
    "contactshavebeenuploadedlots": "Contacts are beign uploaded - it looks like quite big file so it may take few minutes before all data is properly processed - we will handle this for you. You can close this window now.",
    "desccontactshavebeenuploaded": "You may <span class='ecloseoverlay'>exit</span> this screen now.",
    "loadingsegments": "Loading the segments section...",
    "addfield": "Add field",
    "buildwebform": "Build web form",
    "htmlcode": "HTML code",
    "restoredefaultform": "Restore default form",
    "restoresavedform": "Restore saved form",
    "choosefields": "Choose fields to include in form",
    "gender": "Gender",
    "urltonavigate": "Display the following web page after contact signs up",
    "urltonavigateactivation": "Display the following web page after contact activates via email",
    "urltonavigatealreadyactive": "Display the following web page if contact has previously subscribed",
    "activationtemplatename": "Use the following activation template",
    "descurltonavigate": "Custom Url to navigate to after contact has submitted the form, starting with http:// or https://. This is an optional setting for branding purposes, we provide a default page for this.",
    "descurltonavigateactivation": "Custom Url to navigate to after contact has confirmed activation by Double Opt-In email confirmation, starting with http:// or https://. This is an optional setting for branding purposes, we provide a default page for this.",
    "descurltonavigatealreadyactive": "Custom Url to navigate to after contact submitted the form, but is already subscribed",
    "descactivationtemplatename": "Custom activation template name",
    "optionalparameters": "Optional parameters",
    "subscribe": "Subscribe",
    "activate": "Activate",
    "deactivate": "De-Activate",
    "additionalfields": "Additional fields",
    "formsnavtabfields": "Fields",
    "quick": "Quick",
    "quickadd": "Quick add",
    "addsinglecontact": "Add contact",
    "quickaddsuccess": "Contact {{email}} has been added successfully",
    "uploadfile": "Upload file",
    "file": "File",
    "string": "string",
    "uploadeddata": "data",
    "filetoupload": "File to upload",
    "titleuploadsub": "Upload contacts",
    "contactsuploading": "We're uploading your contacts - please wait a moment. We will let you know when your contacts will be ready to use.",
    "contactsuploadingcompleted": "Your contacts have been uploaded. You can find them on your List tab in the Contacts screen.",
    "contactdetails": "Contact details",
    "removecontact": "Delete contact",
    "deletepermanently": "Delete permanently",
    "editcontact": "Edit contact",
    "contact": "Contact",
    "hasbeenloaded": "has been loaded",
    "download": "Download",
    "downloadlowercase": "download",
    "log": "Log",
    "logs": "Logs",
    "compare": "Compare",
    "contacthistorynotfound": "No results were found",
    "addanotherservice": "Add another service",
    "characterserror": "Invalid characters in filename",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "horizontalinlinetext": "Horizontal, inline text",
    "verticalinlinetext": "Vertical,inline text",
    "horizontaltextbelow": "Horizontal, text below",
    "verticaltextbelow": "Vertical, text below",
    "iconstyle": "Icon style",
    "roundedsolid": "Rounded solid",
    "roundedoutlined": "Rounded outlined",
    "squaresolid": "Square solid",
    "squareoutlined": "Square outlined",
    "gallery": "Gallery",
    "gray": "Gray",
    "dark": "Dark",
    "light": "Light",
    "standardwebforms": "Standard web fonts",
    "customwebforms": "Custom web fonts",
    "leftrightmargin": "Left/Right Margin",
    "topbottommargin": "Top/Bottom Margin",
    "display": "Display",
    "icononly": "Icon only",
    "textonly": "Text only",
    "both": "Both",
    "linktextstyle": "Link text style",
    "linxtext": "Link text",
    "insertlink": "Insert link",
    "profileurl": "Profile URL",
    "styles": "Styles",
    "mobileview": "Mobile view",
    "desktopview": "Desktop view",
    "setdefaultcreatetemplate": "Set as default when creating new templates",
    "requiredtemplatename": "Template name is required. It will help you find the template faster.",
    "campaignnotfound": "Campaign not found",
    "selectandedit": "select<br/>and edit",
    "draftnotuse": "Specified template is not use by any campaign.",
    "templatenamcharacterslimit": "The maximum length of template name is 50 characters",
    "availableforsubaccounts": "Available for sub accounts",
    "descavailableforsubaccounts": "Available for sub accounts",
    "unsubscribeinfo": "If you no longer wish to receive mail from us, you can",
    "unsubscribeinfo2": "Not interested in our newsletter?",
    "viewonlineinfo": " Unable to view? Read it ",
    "online": "Online",
    "headerinfo": "This text will show up next to the subject line in some email clients.",
    "emailclients": " Email clients",
    "spamscore": "Spam score",
    "sometips": "Some tips",
    "tips": "Tips",
    "spamtipsdesc": "Below are some of the reasons given by the various filters as to why your email have some spam score.",
    "newtest": "New Test",
    "downloadhtmlcontent": "Download HTML content",
    "downloadhtml": "Download HTML",
    "downloadscreenshots": "Download screenshots",
    "spamfilters": "Spam filters",
    "filters": "Filters",
    "statusfilters": "Status filters",
    "showtestresults": "Show test results",
    "displayfullscreen": "Open full screen",
    "closefullscreen": "Close full screen",
    "togglefullscreen": "Toggle full screen",
    "pub": "Public",
    "priv": "Private",
    "saveaspublic": "Save as public",
    "saveasprivate": "Save as private",
    "tags": "Tags",
    "managetags": "Manage Template Tags",
    "managelandingtags": "Manage Landing Page Tags",
    "templatetags": "Template tags",
    "landingtags": "Landing Page tags",
    "addtagandpressenter": "Press Enter to add tag",
    "tagbelongstotemplate": "Tag already belongs to this template.",
    "alternativelyyoucanusetags": "Alternatively, you can use tags",
    "choosepredesigninfo": "Are you sure you want to replace the current template with this template?<br/> The existing content will be lost.",
    "withoutsaving": "Are you sure you want to exit the editor without saving?",
    "campaignwithoutsaving": "Are you sure you want to exit the campaign creator without saving?",
    "exiwithoutsaving": "Are you sure you want to exit without saving?",
    "templatenotfound": "Template not found",
    "editdefaults": "Default Options",
    "workoncopytemplate": "You are about to edit the Raw HTML for this template that was created using the Email Designer.  <b>Changes that you make potentially could affect it working properly in the Email Designer.</b>  You can Save a Raw HTML Copy to ensure your original template will still function in the Email Designer.",
    "uploadsuccess": "Upload success",
    "processingfile": "Processing file",
    "uploadcontactsuccess": "<p>You have successfully added contacts to your list. Keep up the good work!</p>",
    "resizeimage": "Resize image",
    "unlinkimage": "Unlink image",
    "linkimage": "Link image",
    "buttonlink": "Link button",
    "rounding": "Rounding",
    "button": "Button",
    "buttons": "Buttons",
    "evaluate": "Evaluate",
    "buttonwithtext": "Button & caption",
    "buttonwithtextandimage": "Button, caption & image",
    "buttonwithimage": "Image & button",
    "buttonwithimageandtext": "Image, caption & button",
    "awesomebuttons": "Awesome button",
    "buttontextstyle": "Button text style",
    "unsubscribe": "Unsubscribe",
    "unsubscribesetting": "Unsubscribe settings",
    "form": "Form",
    "descriptionaddform": "Click here to select a form",
    "selectform": "Change form",
    "showhtml": "Show html",
    "descriptionedittext": "This is sample text. To edit this text accept the layout and click here. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    "descriptioneditimage": "Click to select image<br/> or drop an image here.",
    "descriptionbrokenimage": "<b>Image not found</b>",
    "imagefailedtoload": "Image failed to load.",
    "descriptionnologo": "<b>Logo image not loaded</b><br/>if you don't have logo, provide it<br/> in Account/Custom Branding",
    "newtemplate": "New Template",
    "defaultemailsubject": "Default email subject",
    "searchfortemplates": "Search for templates",
    "viewcampaigns": "View campaigns",
    "viewcampaignsbtn": "View Campaigns",
    "createrandomlist": "Create randomized list",
    "randomlist": "Randomized list",
    "createnthselectionlists": "Create nth selection lists",
    "nthselectionlists": "Nth selection lists",
    "numberofrandomcontacts": "# of random contacts",
    "numberofrandomcontactsplaceholder": "Specify the number of contacts in the new list",
    "numberoflists": "Number of lists",
    "excludeblocked": "Exclude blocked",
    "nameoflists": "Name of list series",
    "processtimewarning": "The selection will be submitted for processing and could take several minutes to complete depending on the size of your selection.<br/><br/>Your new list will be available soon and you might have to refresh to view it.",
    "processingworkinprogress": "We are processing your request. It may take a few minutes.",
    "viewdetails": "View details",
    "autosave": "Autosave",
    "ago": "ago",
    "restoreautosave": "Restore autosave",
    "restore": "Restore",
    "therearenoautosaveavailable": "There are no autosave available",
    "restorefromautosave": "Restore from autosave",
    "templatestats": "Template stats",
    "name": "Name",
    "nameandtrigger": "Name & trigger",
    "from": "From",
    "body": "Body",
    "typetemplate": "Type",
    "allow": "Allow",
    "deny": "Deny",
    "moredetails": "More details",
    "sendcount": "Send count",
    "recipientcount": "Recipient count",
    "jobcount": "Job count",
    "createtemplate": "Create template",
    "templatedetail": "Default options",
    "templatename": "Template name",
    "dformemail": "Default from email",
    "dfromname": "Default from name",
    "dsubject": "Default subject",
    "editdesign": "Edit design",
    "pagedesign": "Edit page design",
    "pagedesignoptions": "Page Design",
    "headerdesign": "Edit header design",
    "footerdesign": "Edit footer design",
    "heading": "Heading",
    "link": "Link",
    "inputs": "Inputs",
    "subscribebutton": "Subscribe Button",
    "section": "section",
    "page": "page",
    "textcolor": "Text color",
    "fontfamily": "Font family",
    "fontsize": "Font size",
    "lineheight": "Line height",
    "backgroundcolor": "Background color",
    "backgroundimage": "Background image",
    "bodybackgroundcolor": "Body background color",
    "color": "Color",
    "fontweight": "Font weight",
    "bold": "bold",
    "textdecoration": "Text decoration",
    "textdirection": "Text direction",
    "normal": "normal",
    "underline": "underline",
    "linethrough": "Line-through",
    "none": "None",
    "youdonthaveanytags": "You don’t have any tags. Please go to template options on editor screen to add them.",
    "margintop": "Margin top",
    "border": "Border",
    "bordercolor": "Border color",
    "borderwidth": "Border width",
    "borderstyle": "Border style",
    "bordertop": "Border-top",
    "borderright": "Border-right",
    "borderbottom": "Border-bottom",
    "borderleft": "Border-left",
    "borderradius": "Border radius",
    "textalign": "Text align",
    "solid": "Solid",
    "dashed": "Dashed",
    "dotted": "Dotted",
    "grooved": "Grooved",
    "width": "Width",
    "text": "Text",
    "unsubscribelink": "Unsubscribe link",
    "contactinfo": "Contact info",
    "droptoremove": "Drop here to remove",
    "dragtothepage": "Drag to the page",
    "sendtest": "Send test",
    "sendtestwithoutaccesslevel": "You need to activate your account before you can send an email",
    "mergefieldscontact": "Contact",
    "mergefielddescription": "Click to place merge field in email content.",
    "mergefieldaccount": "Account profile",
    "general": "General",
    "filemanager": "File manager",
    "copymedialink": "Copy media link",
    "copyformlink": "Copy Form Link",
    "copyfilelink": "Copy file link",
    "pleaseclicktoselectimage": "Please click on image box to select image.",
    "mergefields": "Merge fields",
    "insertmergefields": "Merge fields",
    "selectfile": "Select file",
    "mediamanager": "Media Manager",
    "copiedinfomm": "Media link copied to clipboard",
    "sortby": "sort by",
    "newest": "Newest first",
    "oldest": "Oldest first",
    "searchimages": "Search images",
    "searchmedia": "Search media",
    "uploadfiles": "Upload files",
    "uploadmedia": "Upload media",
    "uploadsuccessful": "Upload successful",
    "dragdropeditor": "Email designer",
    "dragdrop": "Drag and drop",
    "draganddrop": "Email designer",
    "filesor": "files to upload or ",
    "browse": "browse",
    "rawhtml": "Raw HTML",
    "savetemplate": "Save template",
    "editor": "Editor",
    "autoformat": "Autoformat",
    "autoformatcode": "Autoformat Code",
    "movestylesinline": "Move Styles Inline",
    "moveinlinestyles": "Would you like to move styles inline for best results in Gmail and Outlook?",
    "contentgoesherhe": " Content goes here",
    "addfile": "Add File",
    "select": "Select",
    "deselect": "Deselect",
    "lowercaseselect": "select",
    "selectashtml": "Select As HTML",
    "nofiles": "No files yet",
    "choosefiletoupload": "Choose file to upload",
    "toemail": "To",
    "fromemail": "From",
    "fromname": "From Name",
    "replyname": "Reply Name",
    "replyemail": "Reply To",
    "useforreplyto": "Use for Reply To",
    "subject": "Subject",
    "filenotselected": "You have not selected any file",
    "filename": "File name",
    "descfiledeleted": "Are you sure you want to delete this file?<br/>This action cannot be undone.",
    "descimagedeleted": "Are you sure you want to delete this image?<br/>This action cannot be undone.",
    "removefile": "Delete",
    "wxh": "WxH",
    "savetemplatebeforesend": "You must save this template to send a test email.",
    "sendtestemail": "Send test email",
    "testemailsent": "Test mail sent.",
    "deletetemplate": "Are you sure you want to delete this template?",
    "deletetemplateusageinautomation": "This template is in use by {{AutomatedCampaignName}}. Deleting the template will pause the automated campaign and set its status to invalid.",
    "noaccesstodeletetemplateusageinautomation": "This template is in use by {{AutomatedCampaignName}}. You do not have permission to delete it. Contact the main account holder to grant you the necessary permission.",
    "deletecampaignusageinautomation": "This campaign is in use by {{AutomatedCampaignName}}. Deleting the campaign will pause the automated campaign and set its status to invalid.",
    "noaccesstodeletecampaignusageinautomation": "This campaign is in use by {{AutomatedCampaignName}}. You do not have permission to delete it. Contact the main account holder to grant you the necessary permission.",
    "deletelistusageinautomation": "This list is in use by {{AutomatedCampaignName}}. Deleting the list will pause the automated campaign and set its status to invalid.",
    "noaccesstodeletelistusageinautomation": "This list is in use by {{AutomatedCampaignName}}. You do not have permission to delete it. Contact the main account holder to grant you the necessary permission.",
    "deleteautomationusageinautomation": "This automation is in use by {{AutomatedCampaignName}}. Deleting the automation will pause the automated campaign and set its status to invalid.",
    "noaccesstodeleteautomationusageinautomation": "This automation is in use by {{AutomatedCampaignName}}. You do not have permission to delete it. Contact the main account holder to grant you the necessary permission.",
    "choosetemplate": "Choose template",
    "templategallery": "Template gallery",
    "parenttemplates": "Parent templates",
    "removetemplate": "Delete template",
    "enterunlimitednumberofaddress": "Enter unlimited number of addresses, separated by a semi-colon (;)",
    "align": "Align",
    "dimensions": "Dimensions",
    "constrainproportions": "Constrain proportions",
    "left": "Left",
    "right": "Right",
    "center": "Center",
    "simpleeditor": "Simple Editor",
    "descsimpleeditor": "Download a template from http://stamplia.com/ or upload your own and easily edit its content",
    "selecttemplatetoimport": "Please selected template to import",
    "selectedtemplate": "Selected template",
    "selecttemplate": "Select template",
    "choosetests": "Choose tests",
    "blocks": "blocks",
    "showbodytexttitle": "Save your template",
    "showbodytextdesc": "You need to save your template before editing Text Body",
    "templateusedtitle": "Template in use",
    "templateuseddesc": "This template is part of an actively sending campaign. Changes made will immediately affect outgoing mail. Continue?",
    "doublebrackets": "You are using '{{ }}' signs, reserved for template scripting. If it is intended, click YES.<br/> If not, please return to your template and remove unnecessary '{}' signs, as they may result in errors in your template. <br/><br/> If you want to use Merge Tags, use single pair of {} instead",
    "wrongwidth": "Not correct width template. Template can not be wider not narrower than 800px and 320px",
    "wrongwidthlanding":"Not correct width landing page. Landing pagecan not be wider not narrower than 1024px and 320px",
    "pagewidth": "Page width",
    "pagestyle": "Main Page Style",
    "commonstyle": "Common Style",
    "commonpagestyle": "Common Page Style",
    "row": "Row",
    "cell": "Cell",
    "style": "Style",
    "header": "Header",
    "topmargin": "Top margin",
    "messagewidth": "Message width",
    "design": "Design",
    "layout": "Layout",
    "showlayout": "Show Grid Layout",
    "background": "Background",
    "normaltext": "Normal Text",
    "imgalttext": "Image Alt-Text",
    "newelementstyle": "New Element Style",
    "padding": "Padding",
    "paddingtop": "Padding top",
    "paddingbottom": "Padding bottom",
    "paddingleft": "Padding left",
    "paddingright": "Padding right",
    "links": "Links",
    "top": "Top",
    "middle": "Middle",
    "bottom": "Bottom",
    "font": "Font",
    "removecell": "Remove Cell",
    "movecell": "Drag Cell",
    "copycell": "Drag And Copy Cell",
    "fullsize": "Full Size",
    "addlinkimage": "Add Link Image",
    "selectimage": "Select Image",
    "separator": "Separator",
    "line": "Line",
    "fulltext": "One column",
    "twocolumntext": "Two columns",
    "threecolumntext": "Three columns",
    "rightimage": "Right Image",
    "leftimage": "Left Image",
    "image": "Image",
    "imagesplustext": "Image & caption",
    "textplusimage": "Caption & image",
    "twocolumnimage": "Image group",
    "titleplusimageplustext": "Title-Image-Text Column",
    "footer": "Unsubscribe footer",
    "preheader": "Preheader",
    "surveystatus": "Survey status",
    "linktoyoursurvey": "Link to your survey",
    "sharelinkdesc": "Link can be added to your email templates or shared however you need.",
    "removesurvey": "Delete survey",
    "deletesurveymsg": "Are you sure you want to delete this survey and associated statistics?",
    "deletesurveyresults": "Are you sure you want to delete this survey results and associated statistics?",
    "removeresults": "Remove selected results",
    "responses": "Responses",
    "lastupdated": "Last updated",
    "createsurvey": "Create survey",
    "surveyname": "Survey name",
    "expirydate": "Expiry date",
    "expired": "Expired",
    "exportsummary": "Export summary",
    "answer": "Answer",
    "noanswer": "- No Answer -",
    "selectanswer": "Select answer",
    "sendanswers": "Send answers",
    "durationtime": "Duration time",
    "submitteddate": "Submitted date",
    "fromip": "From IP",
    "responsessummary": "Survey summary",
    "showanswers": "Show Answers",
    "prieviewsurvey": "Preview survey",
    "startdate": "Start date",
    "responsesoverview": "Responses overview",
    "addanswer": "Additional answer field",
    "questiontitledesc": "What question do you want to ask?",
    "addquery": "Add query",
    "requirethisquestion": "Require this question",
    "question": "Question",
    "textmedia": "Text/Media",
    "pagebreak": "Page break",
    "addpage": "Add page",
    "choosequestiontype": "Choose question type",
    "radiobuttons": "Radio buttons",
    "dropdownmenu": "Dropdown menu",
    "checkboxes": "Checkboxes",
    "checkyourinbox": "Please check your new inbox and click the verification link found in the e-mail we have just sent you",
    "textbox": "Textbox",
    "essayllonganswer": "Essay/Long answer",
    "confirmationpage": "Confirmation page",
    "experiedpage": "Expired Page",
    "confirmationpagedesc": "<span style=\"font-size:32px;line-height:1em\">Thank you.</span><br/><p>Your response has been recorded.</p>",
    "experiedpagedesc": "<h1 style='text-align: center'>Sorry!<br><small>The survey you are looking for is no longer available</small></h1>",
    "sharelink": "Share Link",
    "surveys": "Surveys",
    "survey": "Survey",
    "surveyerrorsave": "You can not save the survey,<br/>because not all fields are completed",
    "responsenumber": "Response number",
    "timestart": "Time Start",
    "surveyanswers": "Survey answers",
    "collectedresponsesbetween": "Collected responses between",
    "surveyexportresults": "Survey export results",
    "filetitle": "File Title",
    "dropyourqueryhere": "Drop your query here",
    "newquestiondesc": "Take advantage of the survey creator and choose the best fitting question fields",
    "netpromoterscore": "Net Promoter® score",
    "notatalllikely": "Not at all likely",
    "extremelylikely": "Extremely likely",
    "npsdefaultquery": "How likely is it that you would recommend _________ to a friend or colleague?",
    "npsinformation": "Please note, that NPS measures customer experience and predicts business growth. You can calculate your NPS using the answer to a single, key question, using 0-10 scale: How likely is it that you would recommend (company name) to a friend or colleague?",
    "refreshcampaigns": "Refreshing campaign list...",
    "settingcampaign": "Settings & tracking",
    "trackopencampaign": "Find out the exact number of times your recipients' attention got captivated to open email communication you sent.",
    "trackclickcampaign": "Find out if your recipients were interested enough not only to open your campaigns but also to interact with them.",
    "editautomation": "Edit automation",
    "editautomationerr": "Editing automated campaign pauses the workflow and prevents new contacts from entering it.",
    "pause": "Pause",
    "reactivate": "Reactivate",
    "showreport": "Show report",
    "showactivity": "Show activity",
    "regular": "Regular",
    "scheduled": "Scheduled",
    "split": "Split",
    "addattachment": "Add Attachment(s)",
    "templateoptions": "Template options",
    "campaigncreator": "Campaign creator",
    "numberofcontacts": "Number of contacts",
    "campagingtemplateinfo": "Only new campaigns can view the template that was used to create this campaign",
    "desclistnotfound": "No search results were found, please adjust your search criteria and search again.",
    "sendindefinitely": "Send indefinitely",
    "subjectcontent": "Subject / Content",
    "addab": "Add A/B",
    "legal": "Legal",
    "leavecreator": "Leave creator",
    "desccampaignsendsuccess": "Campaign <b>\"{{name}}\"</b> is being prepared and will start in a moment.",
    "desccampaignschedulesuccess": "Campaign <b>\"{{name}}\"</b>  has been scheduled for <b>{{date}}</b>",
    "desccampaignautorespondersuccess": "Autoresponder <b>\"{{name}}\"</b> has been saved and activated",
    "desccampaigndraft": "Finish editing your campaign before sending",
    "desctemplategallerycampaign": "Choose from templates saved<br/>on your account or predesigned templates.",
    "desctemplategallerycampaignmobile": "Choose from templates saved<br/>on your account.",
    "usedifferentreplyto": "Use different reply to",
    "every": "every",
    "everyCaptalize": "Every",
    "sendnow": "Send now",
    "addtomytemplates": "Add to My templates",
    "onadd": "When contact is added to audience",
    "onopen": "When contact from audience opens an email",
    "onclick": "When contact from audience clicks an email",
    "opens": "Opens",
    "clicks": "Clicks",
    "optimizesplitfor": "Optimize split for",
    "triggerevent": "When to send",
    "createcampaign": "Create campaign",
    "createcampaigncaptalize": "Create Campaign",
    "createautomation": "Create automation",
    "publish": "Publish",
    "published": "Published",
    "copycampaignconfiguration": "Copy campaign configuration",
    "atox": "A-X",
    "winning": "Winning!",
    "winner": "Winner!",
    "date": "Date",
    "dateadded": "Date Added",
    "dateupdated": "Date Updated",
    "lastcontactactivity": "Last contact activity",
    "unsubscribeddate": "Date Unsubscribed",
    "unsubscribed": "Unsubscribed",
    "recipients": "Recipients",
    "opened":"Opened",
    "clicked": "Clicked",
    "abuse": "Complaint",
    "invalid": "Invalid",
    "unsubscribereasonnone": "None",
    "unsubscribereasonunknown": "Unknown",
    "unsubscribereasonnolongerwant": "I no longer wish to receive your emails",
    "unsubscribereasonirrelevantcontent": "Content is not relevant or interesting",
    "unsubscribereasontoofrequent": "You are sending too frequently",
    "unsubscribereasonneverconsented": "I never wanted to receive that email",
    "unsubscribereasondeceptivecontent": "Email contains deceptive content",
    "unsubscribereasonabusereported": "Contact marked the email as junk or spam with their email provider",
    "unsubscribereasonthirdparty": "Contact unsubscribed via a third-party unsubscribe system",
    "unsubscribereasonlistunsubscribe": "Contact unsubscribed via list-unsubscribe header",
    "deceptivecontent_short": "Deceptive content",
    "deceptivecontent": "Emails are deceptive or misleading",
    "externalabusereport": "External abuse report",
    "irrelevantcontent_short": "Irrelevant content",
    "irrelevantcontent": "Content not relevant or interesting",
    "listunsubscribeheader": "List-unsubscribe header",
    "neverconsented": "Did not agree to receiving emails",
    "neverconsented_short": "Never consented",
    "nolongerwant_short": "No longer want",
    "nolongerwant": "No longer want to receive emails",
    "thirdpartyunsubscribe": "Third party unsubscribe",
    "toofrequent_short": "Too frequent",
    "toofrequent": "Emails are too frequent",
    "overall": "Overall",
    "viewtemplate": "View template",
    "viewgraph": "View graph",
    "graph": "graph",
    "byquality": "By quality",
    "byquantity": "By quantity",
    "choose": "Choose",
    "daterange": "Date range",
    "changethedaterange": "Change the date range",
    "chooselist": "Choose List",
    "removecampaign": "Delete campaign",
    "removeautomation": "Delete automation",
    "copycampaign": "Copy campaign",
    "deletecampaignmsg": "Are you sure you want delete campaign <b>\"{{name}}\"</b> and associated statistics?</br></br><b>NOTE:</b> This will not cancel emails that are currently in progress from this campaign. If you would like to cancel the emails that are in progress please choose the 'Cancel in Progress” option.",
    "deleteautomationmsg": "Are you sure you want delete automation",
    "copycampaignmsg": "Are you sure you want to copy configuration of this campaign?",
    "viewtemplatetitle": "Template not found",
    "viewtemplatedesc": "Template was not used for this campaign or has been deleted.",
    "desccampaignsubmit": "Your campaign has been submitted. It is being processed by system for starting email delivery.",
    "campaignsubmit": "Campaign submitted",
    "processing": "Processing",
    "completed": "Completed",
    "cancelled": "Cancelled",
    "numbers": "Numbers",
    "shownumbers": "Show numbers",
    "percentages": "Percentages",
    "showpercentages": "Show percentages",
    "lastactivity": "Last activity",
    "emailcredits": "Email credits",
    "emailsremaining": "# of emails you can send",
    "emailssenttoday": "Emails sent today",
    "calculating": "Calculating...",
    "remainingemails": "Remaining emails",
    "remainingfreeemails": "Remaining free emails",
    "remaininginplan": "Emails available in plan",
    "requiresemailcredits": "Requires email credits",
    "requiresproplan": "Requires PRO plan",
    "templatecredits": "Template credits",
    "costoftest": "The cost of test",
    "managetemplatecredits": "Manage template credits",
    "manageemailcredits": "Manage email credits",
    "runtest": "Run test",
    "resendconfirm": "I understand and I would like to resend email to this address.",
    "successresentmsg": "Your email has been submitted successfully",
    "howspeedup": "How to speed up your delivery rate?",
    "lhowspeedup": "Learn how to speed up your delivery rate.",
    "speedupmsg": "Elastic Email is designed to deliver your bulk marketing or transactional mail quickly, efficiently and effectively, after all, this is what we do! However, the world of email delivery is fairly complicated and involves quite a few different factors that can influence the success or failure of an email making it to the inbox. Because of this, we want to make sure you know about all the ways you can help your email delivery go as smoothly as possible.",
    "speedupmsgsub": "In order to achieve best deliverability rate and speed, please follow these steps: send only to addresses that confirmed their will in double opt-in fashion, make sure that you send to recipients that interacted with your mail during last 6 months, remove from your list recipients who do not open your mail for several weeks, do not resend messages to users who unsubscribed or marked your mail as spam, make sure that your domain is not blacklisted, make sure that your sender domain is validated with SPF+DKIM.",
    "campaignstatus": "Campaign status",
    "saveandexit": "Save and exit",
    "saveasdraft": "Save as draft",
    "draft": "Draft",
    "triggertype_1": "Send now",
    "triggertype_2": "Schedule on",
    "triggertype_3": "When contact is added to \"{{audience}}\"",
    "triggertype_4": "When contact from \"{{audience}}\" opens {{from}}",
    "triggertype_5": "When contact from \"{{audience}}\" clicks {{from}}",
    "frequencysend": "Frequency send",
    "setschedulefor": "Scheduled for",
    "unabletotriggerforsegments": "You cannot trigger campaigns for segments. Please choose other list or condition for sending",
    "anemail": "an email",
    "emailfrom": "email from",
    "removetemplatechannel": "Are you sure you want to remove this template channel?",
    "errorcampaign": "Campaign error",
    "errorcampaignname": "You must provide a campaign name",
    "errorcampaigntargets": "You must select at least one segment or list for your campaign",
    "errorcampaigntemplates": "You must provide at least one template for your campaign",
    "errorcampaignfromemail": "Invalid FROM email address:",
    "errorcampaignrplayemail": "Invalid REPLY TO email address:",
    "errorcampaignsubject": "You must provide a subject for template",
    "tooltipcreatenewlist": "Turn Manage Subscriptions setting on to activate. By enabling this option, your subscribers will be able to choose which of your available lists they wish to remain on or unsubscribe from.",
    "sendcampaignconfirmationtext": "Are you sure you want to send this campaign to",
    "sendautomationcampaignconfirmationtext": "Do you want to schedule a campaign with ",
    "sendcampaignconfirmationtitle": "Confirmation of sending the campaign",
    "journeys": "Journeys",
    "automationchannels": "Automation channels",
    "viewautomationworkflow": "View automation workflow",
    "automation": "Automation",
    "automations": "Automations",
    "dripcampaigns": "Drip campaigns",
    "showbouncedbycategory": "Bounced by category",
    "categoryname": "Bounced category name",
    "bouncecount": "Bounced count",
    "bouncecount_desc": "Number of subsequent bounces from this Contact",
    "inprogress": "In progress",
    "inprogressestimates": "Note: All delivery rates are estimates combining data from all servers involved in your delivery and may not be accurate. Use this information as a relative measure of delivery speed.",
    "allinprogressestimates": "All delivery rates are estimates combining data from all servers involved in your delivery and may not be accurate. Use this information as a relative measure of delivery speed.",
    "inprogressfilename": "In_Progress",
    "delivered": "Delivered",
    "sms": "sms",
    "emailtotal": "Email",
    "emails": "Emails",
    "ofemails": "Emails",
    "smstotal": "SMS",
    "displayinglogfor": "Displaying log for",
    "exportlogfrom": "Export log from",
    "showlog": "Show log",
    "showoverview": "Overview",
    "retrynow": "Send now",
    "resend": "Resend",
    "skip": "Skip",
    "lastten": "Last 10",
    "newestten": "10 Newest",
    "sendhistory": "Send history",
    "smsupdaterequired": "Text message result requires updating, click Update.",
    "downloadlog": "Export Log",
    "exportlog": "Export",
    "dataexport": "Export data",
    "showing": "Showing",
    "result": "Result",
    "results": "results",
    "cancelinprogress": "Cancel in progress",
    "descexportquicklog": "Export logs from selected category",
    "desccancelinprogress": "Are you sure you want to cancel emails that are currently In Progress?  Credit is not refunded for the In Progress emails that will be cancelled if you continue.",
    "desccancelcampaign": "Are you sure you want to cancel this campaign that is currently in progress? Credit is not refunded for the cancelled campaign if you continue.",
    "emailsinprogress": "emails in progress",
    "hostname": "Host Name",
    "assignuserpermission": "Assign user permissions",
    "moreavailable": "more available",
    "nodata": "No data to graph.",
    "refresh": "Refresh",
    "category": "Category",
    "campaign": "Campaign",
    "nofiltersapplied": "Show log (no filters applied)",
    "linktracking": "Link tracking",
    "nologsforlinktracking": "No log has been found for the given link and campaign",
    "tracking": "Tracking",
    "linktrackingfilename": "LinkTracking",
    "linkstatistics": "Link statistics",
    "trackedlink": "Tracked link",
    "location": "Location",
    "count": "Count",
    "linksclicked": "Links clicked",
    "linksclickedfile": "LinksClicked",
    "numberofclicks": "Number of clicks",
    "percentage": "Percentage",
    "showlive": "Current Delivery",
    "viewinnewwindow": "View in New Window",
    "noliveviewdesc": "A Private IP is required to see this information. It shows detailed information for each IP and real-time delivery information for specific destination domains.  You can request a private IP on the Settings screen.",
    "mailwithoutsubjecttitle": "No Subject",
    "mailwithoutsubjecttext": "Your message does not have subject! It has higher chance to be treated like spam. Click \"YES\", to send it anyway",
    "nobouncedyet": "Great, no mails were bounced! Keep up the good work!",
    "noinprogressnow": "Nothing is going out right now.",
    "noopenedyet": "Sorry, no mails were opened yet!",
    "noclickedyet": "Sorry, no links have been clicked yet.",
    "noabuseyet": "Great, no mails were considered spam! Keep up the good work!",
    "nounsubscribedyet": "Great, no one resigned from your email! Keep up the good work!",
    "systemrate": "System Rate",
    "accountsendingrate": "Account sending rate",
    "failed": "Failed",
    "waiting": "Waiting",
    "ready": "Ready",
    "nochannelslistmsg": "No channels have been created yet.",
    "nocamplistmsg": "No campaigns have been created yet.",
    "noautomationlistmsg": "No automation campaign have been created yet.",
    "noemailinprogrmsg": "No emails in progress have been found.",
    "nologs7daysmsg": "No logs have been found.<br/>Logs older than <span>7 days</span> will not be stored.",
    "nologs3daysmsg": "No logs have been found.<br/>Logs older than <span>3 days</span> will not be stored.",
    "nologsmsg": "No email logs found.<br/>Logs older than <span>35 days</span> are not stored.",
    "noactivitymsg": "No activity has been found.",
    "noinprogmsg": "There are no emails in your In Progress queue.",
    "showquantity": "Show quantity graph",
    "quantitygraph": "Quantity graph",
    "showquality": "Show quality graph",
    "qualitygraph": "Quality graph",
    "comparecampaigns": "Compare campaigns",
    "comparegraph": "Compare graph",
    "nocomparemsg": "Add campaigns or channels from above to compare.",
    "noclicklinks": "No links have been found.<br/>Links older than <span>35 days</span> will not be stored.",
    "allsent": "All sent",
    "cboxmsg": "You have <span class='ecboxcount'>0</span> <span class='ecboxitem'>item</span> selected <button id='ecboxexport' class='btn btn_sm btn_primary btn-info'>Export</button><button class='btn btn_sm btn_tertiary' id='ecboxdeselect'>Deselect</button>",
    "youhave": "You have",
    "clcktoshowcd": "Click to show contact details",
    "shwbouncedetails": "Show bounce details",
    "allbounced": "All bounced",
    "itemsingle": "item",
    "itemmulti": "items",
    "buyprivateip": "For more information BUY private IP",
    "suppressed": "Suppressed",
    "submitted": "Submitted",
    "rts": "Ready to send",
    "billingdesc": "Change plan and billing details and view payment history, usage and receipts.",
    "profiledesc": "Update your profile information that can be used in your mailings.",
    "settingsdesc": "All the cool options you can configure in one place.",
    "reputationdesc": "Details on your Account's configuration and sending reputation.",
    "securitydesc": "Ensure your Account is secure and safe.",
    "legaldesc":"View our usage policies and download our DPA.",
    "allinprogress": "All emails in progress",
    "allwtr": "All emails waiting",
    "allrts": "All emails ready to send",
    "nocampaignsdrpdwn": "No campaigns",
    "nochannelsdrpdwn": "No channels",
    "addcampaign": "Add campaign",
    "addchannel": "Add channel",
    "quantity": "Quantity",
    "quality": "Quality",
    "emaillogs": "Email log",
    "cancelplan": "Cancel plan",
    "includes": "Includes",
    "notificationlog": "Notification log",
    "retrfuture": "Retrying/Future scheduled",
    "timerange": "Time range",
    "today": "Today (since midnight)",
    "last24hours": "Last 24 hours",
    "last7days": "Last 7 days",
    "last14days": "Last 14 days",
    "last21days": "Last 21 days",
    "last30days": "Last 30 days",
    "last35days": "Last 35 days",
    "last3months": "Last 3 months",
    "last6months": "Last 6 months",
    "lastyear": "Last year",
    "snshlast5minutes": "Snapshot - last 5 minutes activity",
    "specifictimerange": "Specific time range",
    "specificdaterange": "Specific Date Range",
    "data": "Data",
    "nodeliveryqmsg": "No delivery queue have been found.",
    "deliveryqueue": "Delivery queue",
    "emailbydomain": "Email by domain",
    "notemplate": "No template",
    "unsubscribereasons": "Unsubscribe reasons",
    "unsubscribereason": "Unsubscribe reason",
    "errornotfoundcorrectdns": "Error: the correct DNS record was not found",
    "attachfile": "Attach file link from media manager",
    "attachimage": "Attach image link from media manager",
    "dragmedia": "Drag your media",
    "infoaboutdragdropfiles": "Save your time. Quickly upload your media by drag and drop.",
    "addmore": "Add more",
    "otherfiles": "Other files",
    "dropfiletoupload": "Drop file to upload",
    "descdropfiletoupload": "Please make sure your file names do not contain any<br/> of the following characters: '\\', '/', ':', '*', '?', '\"', '<', '>', '|', '+', '#', '&'",
    "imagetoolarge": "Your image is too large.",
    "imagestoolarge": "Your images are too large.",
    "weresizeit": "We will resize it to the allowed size (below 2 MB).",
    "weresizethem": "We will resize them to the allowed size (below 2 MB).",
    "filenamealreadyexists": "File name already exists. The file will be overwritten.",
    "filenamecontainsunallowedcharacters": "File name contains unallowed characters, which will be automatically changed upon upload completion.",
    "wrongfilename": "File name contains unallowed characters, which were automatically changed upon upload completion",
    "filecantbeuploaded": "File can't be uploaded, because of the bad file format.",
    "filesizeexceedesmaximumallowed": "File (filesizeMB) exceeds the maximum allowed size of 20MB.",
    "fileuploadunsuccessful": "Upload unsuccessful",
    "fileuploadsuccessful": "Upload successful",
    "domainvalidation": "Domain verification",
    "domainisuccessfullyvalidated": "your domain is successfully verified",
    "domainissuccessfullyvalidateandlearnmore": "your domain is successfully verified. Improve your deliverability by setting up the remaining record(s)",
    "domainisnotvalidated": "modify your domain records to make your campaigns look as if they come from ",
    "loadingdomains": "Loading a domains",
    "emailverification": "Email verification",
    "youremailaddressisverified": "your email address(es) is verified for sending",
    "youremailaddressisnotverified": "your email address(es) are not verified for sending",
    "learnmore": "Learn more",
    "domainvalidlearnmore": "Completely brand your emails with your own domain and improve your delivery",
    "validate": "Verify",
    "valid": "Verified",
    "validating": "Verifying",
    "reverifydomain": "Re-Verify Domain",
    "verifyingmxrecord": "Verifying MX record",
    "addemail": "Add email",
    "custombouncedomain": "Custom bounce domain",
    "bouncedomain": "Bounce domain",
    "custombouncesdomain_desc": "Your domain is using custom bounce domain settings",
    "defaultbouncesdomain_desc": "Your domain is using our generic bounce domain settings",
    "turnedoffbouncedomain_desc": "Your domain is not using a custom bounce domain.",
    "defaultcustombouncesdomain_desc": "Your domain is using your configured default bounce domain settings",
    "notverifiedbouncesdomain_desc": "Your domain is not verified, so you cannot configure the bounce domain. Verify your domain to enable bounce domain configuration.",
    "werecommendsettingcustombouncedomain": "We recommend creating a custom bounce domain.",
    "makedefaultbounce": "Share custom bounce domain with all available domains.",
    "custombouncedomain_desc": "A custom bounce domain must be a subdomain (like name.your-domain.com). We strongly recommend using a subdomain such as bounces.{{domain}} for bounce domains.",
    "custombouncedomain_records_desc": "A custom bounce domain overrides Elastic Email bounce address (bounces.elasticemail.net) for all outgoing communication.",
    "resellercustombouncedomain_records_desc": "A custom bounce domain overrides  bounce address (the 'bounces.' subdomain) for all outgoing communication.",
    "startwithaddingmx": "Start with adding the following MX record to your DNS settings.",
    "domainsemptytitle": "You need to verify the domain you wish to send from",
    "domainsemptydesc": "Before you can send campaigns with a From email address like you@yourdomain.com, you'll need to verify that you have access to an email address at that domain",
    "verifyadomain": "Verify domain",
    "verifyemailsquestion": "Verify contacts email address",
    "whitelabeldomain": "White label domain",
    "verifydomaininformation": "Improve your delivery with verified sending domains.",
    "verifieddomaininformation": "If you would like to manage a domain, please go to the Settings screen.",
    "domainverification": "Domain verification",
    "verifydomain": "Verify domain",
    "updateprofileinformation": "Be sure to update your profile information to receive full functionality of your trial plan.",
    "changeorupdateprofileinformation": "Change or update your profile details anytime you need.",
    "resendverificationemail": "Resend verification email",
    "emailwassent": "Email was sent",
    "addverifyemaildesc": "Enter an email address below from the domain you would like to send your campaigns from. A verification email will be sent to this address",
    "emailaddress": "Email address",
    "addsenderconfirm": "Please check your new inbox and click the verification link found in the e-mail we have just sent you",
    "sendersuccessfulyverifiedtitle": "Domain successfully verified",
    "sendersuccessfulyverifieddesc": "However, there is still some room for improvement",
    "sendersuccessfulyverifiedhowto": " how to enhance your deliverability by adding the remaining domain records",
    "addsenderdesc": "We need to be sure that you own an email address at the domain you want to send campaigns from. Enter an email address below to receive a verification email",
    "addsenderhelp": "Enter an email that contains the domain you want to verify",
    "yourdomainisauthenticated": "Your domain is verified",
    "yourdomaincannotbeauthenticated": "Your domain cannot be verified",
    "deletesenders": "Are you sure want to delete all emails from this domain?",
    "deleteallsenders": "Delete all emails",
    "deleteemail": "Delete email",
    "deleteemailconfirmation": "Are you sure you want to delete this email, you will no longer be able to send from it?",
    "deletedomainconfirmation": "Are you sure you want to delete this domain validation?",
    "verifyemail": "Verify email",
    "addingemailto": "Adding email to",
    "createnewbouncedomain": "Create Custom Bounce Domain",
    "createnewbounce_desc": "Instead of using default Elastic Email bounce domain, you can customize the address at any time and point it to your own domain.,",
    "createnewbounce_descresellers": "Instead of using default bounce domain, you can customize the address at any time and point it to your own domain.,",
    "unknowncontactfields": "unknown contact fields",
    "unknown": "Unknown",
    "unknown_short": "Unknown",
    "ignore": "Ignore",
    "blacklisted": "IP reputation issue",
    "nomailbox": "No mailbox",
    "greylisted": "Grey listed",
    "throttledcategory": "Throttled",
    "timeout": "Timeout",
    "connectionproblem": "Connection problem",
    "spfproblem": "SPF problem",
    "accountproblem": "Account problem",
    "dnsproblem": "DNS problem",
    "whitelistingproblem": "Whitelisting problem",
    "codeerror": "Code error",
    "notdelivered": "Not delivered",
    "notdeliveredcancelled": "Not delivered cancelled",
    "manualcancel": "Manual cancel",
    "connectionterminated": "Connection terminated",
    "selectcountrycode": "Select country code",
    "selectcountry": "Select country",
    "exportfilenamechartsmsg": "File name cannot contain any of the following characters: \\ / : * ? \" < > + # & |",
    "accountsecurity": "Account security",
    "twofactorauthentication": "Two-Factor Authentication",
    "allowtwofactorauthentication": "Allow two-factor authentication",
    "addtwofactorauthentication": "Add Two-Factor Authentication",
    "twofactorauthentication_desc": "Two-form authentication adds supplementary security measure to your account.",
    "twofactorauthenticationsubaccount_desc": "Two-form authentication adds supplementary security measure to your subaccount. To log in, owner need to enter both his standard password and a generated security code sent to either his mobile phone or his email address.",
    "requirealldevices": "Require all devices to log in with new password.",
    "twofactorauthentication_email_desc": "Email allows you to receive security codes on both your computer or mobile device. Make sure you stay connected to Wifi or mobile data is enabled on your mobile device when accessing email.",
    "twofactorauthentication_sms_desc": "With SMS messages you will receive your security code via text message on your mobile device. When cellular service is out of reach you will not be able to receive your security code necessary to log in to your account.",
    "twofactorauthentication_both_desc": "Email and text messages employ the power of both solutions allowing you to log in and authenticate to your account, even if you don't have your mobile device handy.",
    "enableauthentication": "Enable authentication",
    "disableauthentication": "Disable authentication",
    "twofamethodsms": "text messages (sms)",
    "twofamethodsms_desc": "With SMS messages you will receive your security code via text message on your mobile device. When cellular service is out of reach you will not be able to receive your security code necessary to log in to your account.",
    "twofamethodemails": "emails",
    "twofamethodemails_desc": "Email allows you to receive security codes on both your computer or mobile device. Make sure you stay connected to Wifi or your internet access is allowed via cellular service when accessing email your mobile device.",
    "twofamethodboth": "text messages (sms) and emails",
    "twofamethodboth_desc": "Email and text messages employ the power of both solutions allowing you to log in and authenticate to your account, even if you don't have your mobile device handy.",
    "twofayouremailaddress": "Your email address",
    "twofayouremailaddress_des": "We will use this email address to send you messages with security codes necessary to pass the authentication successfully.",
    "twofasecuritycode": "Security code",
    "twofasecuritycode_desc": "We have just sent you an email containing 6-digit authentication passcode. Please, open it on your device and enter the passcode below.",
    "twofaauthenticationpasscode": "6-digit authentication passcode",
    "twofaresendpasscode": "Resend passcode",
    "twofacantlogin": "I can't log in",
    "twofayourphonenumber": "Your mobile phone number",
    "twofayourphonenumber_desc": "We will use this phone number to send you text messages with security codes necessary to pass the authentication successfully.",
    "twofabackupcode": "Backup code",
    "twofabackupcode_desc": "It is important to keep the backup code somewhere safe. It will grant you access to your account in the event of losing your mobile device. Remember, the backup code can be used only once.",
    "twofapasscoderesend": "The passcode has been resent",
    "twofapasscoderesendsms": "The passcode has been resent. Please check messages on your device",
    "twofapasscoderesendemail": "The passcode has been resent. Please check your inbox",
    "twofaaccountsecured": "Awesome! Your account is super secure right now",
    "twofalogintitle": "Authentication passcode required",
    "twofalogin_desc": "We sent you a message containing 6-digit authentication passcode. Please, open it on your device and enter the passcode below.",
    "twofaloginbackup_desc": "Backup code will grant admission to your account in the event of losing access to either your email account or your mobile device, or both. Remember, the backup code can be used only once.",
    "twofadisabledtitle": "Disable authentication",
    "twofadisabled_desc": "Are you sure you want to disable the two-factor authentication? Your account may not be as secure as it is now.",
    "twofadisabledbackupcode": "Logging with backup code successful. Re-enable two-factor authentication to secure your account.",
    "twofabreaksetupboth": "Do you really want to interrupt the configuration, and lose previous settings?",
    "twofactorcodeexpired": "Authentication passcode expired. A new passcode was sent.",
    "twofactorcodeincorrect": "Please enter a valid 6-digit authentication passcode",
    "twofactorcodeincorrectbackupcode": "Invalid authentication backup code.",
    "donthavemobilephonenumber": "I don't have a mobile phone number",
    "donthavemobilephonenumber_desc": "No phone number? No worries, we've got you covered. You can still use two-factor authentication with your email address only.",
    "countrycode": "Country code",
    "mobilephonenumber": "Mobile phone number",
    "authenticatewithemail": "Authenticate with email",
    "apiconfiguration": "API",
    "api": "API",
    "security": "Security",
    "device": "Device",
    "browser": "Browser",
    "trusted": "Trusted",
    "untrusted": "Untrusted",
    "trustedbrowser": "Trusted browser",
    "trustedbrowserlist": "Trusted browser list",
    "trustedbrowser_desc": "Check when and where devices with specific browsers have accessed your account.",
    "time": "Time",
    "recentlyuseddevices": "Recently used browsers",
    "senddevicesnotificationalert": "Receive log in notifications when new browsers have accessed your account. ",
    "sessions": "Sessions",
    "endsession": "Untrust device",
    "startsessions": "Trust this device",
    "reviewallsessions": "Review all sessions",
    "newlogin": "New log-in",
    "log_in": "Log-in",
    "logindate": "Login date",
    "taxcode": "Tax Id. No",
    "website": "Website",
    "companylogo": "Company logo",
    "logourl": "Logo URL",
    "logourl_desc": "The 'Logo URL' field is a URL or link to your logo. The logo will display at the login screen and in the generic notification emails that are sent.",
    "lastlogin": "Last login",
    "newsletter": "Newsletter",
    "supportlink": "Support link",
    "supportlink_desc": "'Support Link' is used in email notifications to your users. It should be a page that has information on how to contact you for support.",
    "review": "Review",
    "reviewaccount": "Review account",
    "emailsabovelimit": "Emails above the limit are charged at this cost.",
    "billing": "Billing",
    "inboundcount": "Inbound count",
    "attachmentcount": "Attachment count",
    "autorechargeplan": "Auto-recharge",
    "autorechargeplandesc": "Choose when you would like to have your account auto-recharged.",
    "paymentmethod": "Payment method",
    "paymentmethoddesc": "All transactions are secure and encrypted for your safety.",
    "disableautocredit": "Are you sure you want to remove auto-recharge?",
    "removecurrentpaymentmethod": "Remove current payment method?",
    "disableautocreditwithrenewdate": "A registered payment method is required to renew your plan on {{date}}.  If you remove this payment method your plan will be cancelled after {{date}}.  Are you sure you want to remove your credit card?",
    "disableautocreditheader": "Remove auto-recharge",
    "confirmaction": "Confirm action",
    "changeeautocredit": "Do you want to change your existing payment?",
    "changeautocreditheader": "Change payment type?",
    "changeautoincrease": "Turn auto-increase contact limit on or off. This feature will automatically upgrade your plan to higher contact limit if you upload more contacts than your current plan allows.",
    "changeautoincreaseheader": "Let us handle your plan upgrades for you",
    "autoincreasechanged": "Contact limit auto-increase changed",
    "changeplan": "Change plan",
    "invalidautocredit": "It seems that your there was a problem with your payment. Please try editing it",
    "invalidautocreditheader": "Problem with payment",
    "submit": "Submit",
    "paymenthistory": "Payment history",
    "paymentreceiptmessage": "Payment receipt has been sent to your account email address.",
    "paymentreceiptmessagetitle": "Payment receipt emailed",
    "invoicemessage": "Payment Invoice has been sent to your account email address.",
    "invoicemessagetitle": "Payment invoice emailed",
    "downloadinvoice": "Download invoice",
    "sendreceipt": "Send receipt",
    "sendinvoice": "Send invoice",
    "getinvoice": "Get invoice",
    "getreceipt": "Get receipt",
    "showusage": "Show usage",
    "usage": "Usage",
    "totalcontacts": "Total contacts",
    "activecontacts": "Active Contacts",
    "completedcontacts": "Completed Contacts",
    "availablecontacts":"Available contacts",
    "grandtotalcost": "Grand total cost",
    "main": "Main",
    "currentplan": "Current plan",
    "reputationdetails": "Reputation details",
    "customcss": "Custom CSS",
    "customcheadercode": "Custom Header Code",
    "reputationhistory": "Reputation history",
    "reputationoverview": "Reputation overview",
    "totaldeductions": "Total rep deductions",
    "deductions": "Deductions",
    "reputation": "Reputation",
    "unknownusers": "Unknown users",
    "emailssent": "Emails sent",
    "contentscore": "Content score",
    "content": "Content",
    "preferences": "Preferences",
    "calculatecost": "Calculate cost",
    "numberofemails": "Number of emails",
    "addprivateip": "Add private IP ($0.79/day)",
    "approximatemonthlycost": "Approximate monthly cost: ",
    "enabletooltips": "Enable tooltips",
    "enabletooltipsdesc": "Tick it if you want a bit of help from dashboard when you are stuck.",
    "changelanguage": "Change language",
    "credit": "Current credit",
    "addbelow": "(Add/Below)",
    "senderlevel": "Sender Level",
    "priceemail": "Price for 1000 emails",
    "priceperemail": "Price per email",
    "accountactivity": "Account activity",
    "purchasecredits": "Purchase credit",
    "additionaltools": "Additional tools",
    "planspriceperemail": "Additional emails",
    "emailprice": "Email price",
    "currentprice": "Discounted price",
    "isdefault": "Default",
    "totalemailsend": "Total emails sent",
    "monthly": "monthly",
    "months": "months",
    "days": "days",
    "discount": "Discount",
    "accountautorecharge": "Account auto-recharge",
    "availableemails": "Available emails",
    "pro": "pro",
    "addcredits": "Add credits",
    "monthlyemailssent": "Emails sent this month",
    "upgrade": "Upgrade",
    "gopro": "Go pro",
    "myplan": "My plan",
    "upgradepricing": "It's time to upgrade!",
    "plan_renews": "Plan renews on",
    "plan_expires": "Plan expires on",
    "plan_paused": "Plan paused until",
    "expired_plan": "Previous plan expired on",
    "unlimitedtrial": "Unlimited Trial",
    "trial": "Trial",
    "expires": "Expires",
    "basic": "Basic",
    "plandetails": "Plan details",
    "unlimitedtrial_desc": "You currently run on a Trial account. Upgrade the plan and get the most out of available features and tools.",
    "trial_desc": "You currently run on <strong>Trial</strong>. Select a plan crafted to your needs - cancel, downgrade or upgrade at any time.",
    "basic_desc": "You currently run on <strong>Basic</strong> plan.<br/> Select a plan crafted to your needs <br/>and get the most out of your account.",
    "unlimitedplan_desc": "A toolkit to help you make, manage and send emails.",
    "unlimitedproplan_desc": "Even more email marketing and customization tools.",
    "emailapi_desc": "For SMTP and HTTP API senders.",
    "emailapipro_desc": "Go pro when ready for the complete API experience.",
    "plannotactive": "Your plan is not active. Please activate it by registering new payment method, to add another features to your account.",
    "paymentnotactive": "Your payment is not active. Please check your existing payment or register new payment method, to add another features to your account.",
    "unlimitedmonthly": "Unlimited monthly",
    "payasyougo": "Pay as you go",
    "noaccesstocampaigncreator": "PAY AS YOU GO PLANS DO NOT HAVE ACCESS TO OUR CAMPAIGN CREATOR",
    "upgradeplan": "Select your plan",
    "updateprofile": "Update profile",
    "custombranding": "Custom branding",
    "dashboardcname": "Dashboard CNAME",
    "dashboardcname_desc": "Add a CNAME record to your domain's DNS' that points to api.elasticemail.com and will be used for your custom branded dashboard.",
    "enablecustombranding": "Enable custom branding for this account",
    "privatebrandingurl": "Custom login CNAME",
    "enable": "Enable",
    "change": "Change API Key",
    "showapikey": "Show API Key",
    "changeapikeytext": "Are you sure you want to change this API Key? This will block any communication with our API using this key.",
    "apikey": "API Key",
    "newapikey": "New API Key",
    "addapikey": "Add API Key",
    "getparentapikey": "Can be obtained from the owner of the account.",
    "deleteapikeydesc": "Are you sure you want to remove this API Key? This will block any communication with our API using this key.",
    "changeemail": "Change Email",
    "newemail": "New email",
    "currentemail": "Current email",
    "accountemailaddress": "Account email address",
    "changeaccountemail": "Change account email address",
    "changelogin": "Change email address you use for logging in.",
    "verificationemailsent": "Verification email sent",
    "verificationemailsentdesc": "An email has been sent to {{newemail}}. Please click on the verification link to confirm your email address change",
    "emailchanged": "Email changed",
    "emailchangeddesc": "Your email was successfully changed to ",
    "deletedomainvalidation": "Delete domain verification",
    "editinfo": "Edit info",
    "editnew": "Edit new",
    "contactsemailsent": "Email sent",
    "changecurrentpassword": "Change your current main account password.",
    "currentpassword": "Current password",
    "newpassword": "New password",
    "confirmpassword": "Confirm password",
    "editpassword": "Edit password",
    "savepassword": "Save password",
    "saveinfo": "Save info",
    "editemail": "Edit email",
    "saveemail": "Save email",
    "changepassword": "Change password",
    "passwordchanged": "Password changed",
    "accountpassword": "Account password",
    "changeaccountpassword": "Change account password",
    "summary": "Summary",
    "viewsummary": "View summary",
    "profile": "Profile",
    "advancedoptions": "Advanced options",
    "diagnostics": "Diagnostics",
    "options": "Options",
    "manageapikey": "Manage API Keys",
    "apikeys": "API Keys",
    "customaccess": "Custom access",
    "configuration": "Configuration",
    "key": "Key",
    "disable": "Disable",
    "disabled": "Disabled",
    "enabled": "Enabled",
    "useremail": "User email",
    "accesslevel": "Access level",
    "accesstype": "Access type",
    "permissions": "Permissions",
    "preview": "Preview",
    "invite": "Invite",
    "inviteuser": "Invite User",
    "showinviteuser": "Invite",
    "users": "Users",
    "useraccess": "User access",
    "userdetails": "User details",
    "lastseen": "Last seen",
    "normalaccess": "Normal",
    "fullaccess": "Full",
    "limitedaccess": "Limited",
    "viewaccess": "View only",
    "noneaccess": "None",
    "modifyaccess": "Modify",
    "pluginaccess": "Plugin",
    "formaccess": "Subscription form",
    "alternatesettings": "Alternate Server/Port Configuration",
    "smtpsettings": "SMTP configuration",
    "smtp": "SMTP",
    "port": "Port",
    "server": "Server",
    "alternate": "Alternate",
    "httpsettings": "HTTP API settings",
    "domains": "Domains",
    "sending": "Sending",
    "other": "Other",
    "ipmanagement": "IP management",
    "smtpconfiguration": "SMTP",
    "subaccounts": "Sub Accounts",
    "manage": "Manage",
    "accountemail": "Account email",
    "accountname": "Account name",
    "accountstatus": "Account status",
    "invalidpercent": "Invalid",
    "abusepercent": "Abuse",
    "spampercent": "Spam",
    "publicaccountid": "Public Account Identifier",
    "publicaccountid_desc": "Unique account identifier used for referrals, file store and forms.",
    "load": "Load",
    "deletes": "Delete",
    "delete": "Delete",
    "remove": "Remove",
    "move": "Move",
    "addsubaccount": "Add sub account",
    "subaccountdelete": "Are you sure you want to delete this subaccount?  You will not be able to access any statistics from this subaccount in the future.",
    "subaccountdeleteconfirm": "Are you sure you want to delete this subaccount? A confirmation email will be sent to your Account email address. <br><br>Once you confirm the deletion via email, you will no longer be able to access any statistics from this subaccount in the future.",
    "subaccountdeleteemailsent": "Confirmation sent. Please click the link sent to your Account email address.",
    "subaccountdeleteheader": "Subaccount delete",
    "emailsent": "Emails sent",
    "totalsent": "Total emails sent",
    "totalopened": "Total emails opened",
    "totalclicked": "Total emails clicked",
    "totalfailed": "Total emails bounced",
    "pleasefillallrequiredpoints": "Please fill all required points",
    "pleasefillallrequiredfields": "Please fill all required fields",
    "monthlyrefillcredits": "Monthly refill credits",
    "dailysendlimit": "Daily email limit",
    "emailsizelimit": "Max email size in MB (includes attachments)",
    "maxcontacts": "Max contacts",
    "plancost": "Plan cost",
    "storedcontacts": "Stored contacts",
    "sendactivation": "Send activation email",
    "desc_sendactivation": "When enabled, an activation email is sent to your email account. The subaccount gets activated only when you open the activation email and click the verification link.",
    "unlimited": "Unlimited",
    "editmonthlycredits": "Edit monthly credits",
    "edit": "Edit",
    "editashtml": "Edit as HTML",
    "deletesingletemplate": "Delete '{{name}}' template?",
    "deletemoretemplates": "Delete {{selectedtemplateslength}} templates?",
    "deleteuser": "User deletion",
    "userdelete": "Delete this User?",
    "availablecontactsonplan": "Available contacts on your plan",
    "userdeleteheader": "Delete User",
    "edituser": "Edit user",
    "deleteapikey": "Delete",
    "apikeydelete": "Delete this API Key?",
    "smtpdelete": "Delete this SMTP Credential?",
    "apikeydeleteheader": "Delete API Key",
    "smtpdeleteheader": "Delete SMTP Credential",
    "editapikey": "Edit API Key",
    "dkimvalidation": "DKIM validation",
    "spfvalidation": "SPF validation",
    "trackingvalidation": "Tracking validation",
    "mxvalidation": "MX validation",
    "dmarcvalidation": "DMARC validation",
    "validationinprogress": "Validation in progress...",
    "primaryemail": "Primary email",
    "unabletoverify": "Unable to verify",
    "deletedomain": "Are you sure you want to delete <b>{domain}</b> ?",
    "deletedefaultdomain": "Are you sure you want to delete <b>{domain}</b> ?<br/><br/><u>It is a <b>Default Domain</b></u>.<br/> If you remove this domain, you should definitely set another default domain",
    "transfertopayasyougo": "Do you want to migrate your account into <b>'Pay as You Go'</b>? Remaining credits will be set to",
    "analytics": "Analytics",
    "apiaccess": "API access",
    "automationtools": "Automation tools",
    "scheduling": "Scheduling",
    "emaildesigner": "Email designer",
    "emailgallery": "Email gallery",
    "inboundemailprocessing": "Inbound email processing",
    "segmentation": "Advanced segmentation",
    "chargeddaily": "Plan charged daily",
    "selectplan": "Select plan",
    "pay": "Pay",
    "payments": "Payments",
    "paymentconfiguration": "Payment configuration",
    "support": "support",
    "prioritysupport": "Priority support",
    "emailspermonth": "Emails per month",
    "monthlycost": "Monthly cost",
    "monthshort": "mo",
    "additionalemails": "Additional emails",
    "contactslimit": "Contacts limits",
    "ccnumber": "Card number",
    "cvv": "Security code",
    "autorecharge": "Auto recharge",
    "autorechargeoptions": "Recharge options",
    "paymentmanagement": "Payment management",
    "ccinfo": "Credit card information",
    "cvvinfoamex": "Enter the <strong>4 digits</strong> located<br>on the <strong>front</strong> of your card.",
    "cvvinfoother": "Enter the <strong>3 digits</strong> located<br>on the <strong>back</strong> of your card.",
    "paywithcreditcard": "Pay with Credit Card",
    "paywithpaypal": "Pay with PayPal",
    "enablewithcreditcard": "Enable with Credit Card",
    "enablewithpaypal": "Enable with PayPal",
    "paypalaccountemail": "Elastic Email account",
    "paymentsuccessful": "Payment successful. It may take a few minutes until your current credit status gets updated.",
    "paymentinfo": "Payment info",
    "paymentunsuccessful": "Payment unsuccesful",
    "paymentproblems": "Your payment was not processed. Your account was not charged with this operation. Please try again or contact support.",
    "paymentpleasewait": "Your order is processed...",
    "paymentthankyou": "Thank you for your payment!",
    "autorechargesuccess": "Auto recharge successful. It may take a few minutes until your current credit status gets updated.",
    "autorechargewait": "Almost done... just a few more seconds. Please wait.",
    "autorechargepaymentsuccessheader": "Thank you for your payment! It may take a while until your credits status is updated.",
    "autorechargepaymentsuccesstext": "We sent you a receipt to ",
    "finishupdatewithpaypal": "After clicking 'Submit' button you will be redirected to PayPal to securely finish the payment process.",
    "finishpaymentwithpaypal": "After clicking 'Pay' button you will be redirected to PayPal to securely finish the payment process.",
    "finishpaymentwithstripe": "After clicking 'Pay' button you will be redirected to Stripe to securely finish the payment process.",
    "welldone": "Well done!",
    "goodtogo": "You're good to go.",
    "creditstatusisbeingupdated": "It may take a while until your credits status is updated. We also sent you a receipt to ",
    "processingpaymentpleasewait": "Processing payment. Please wait.",
    "processingpayment": "Processing payment.",
    "pleasewait": "Please wait.",
    "paymentdetailsedited": "Your payment details have been edited.",
    "paymentchangemaintext": "Your payment details have been edited.",
    "autoincreasemaintext": "Your plan settings have been updated.",
    "autorechargesuccessheader": "You have successfully setup a credit purchase plan. It may take a few minutes until your current credit status gets updated.",
    "autorechargesuccesstext": "You have successfully setup a credit purchase plan. It may take a few minutes until your current credit status gets updated.",
    "paymentreceipt": "A receipt has been emailed to your account email address and the new credit has been added to your account.",
    "otheramount": "Enter other amount below",
    "receiptemailaddress": "Email address",
    "phonenumber": "Phone",
    "addressline": "Address line",
    "address": "Address",
    "postcode": "Zip / Postal code",
    "postalcode": "Postal code",
    "cardnumber": "Card number",
    "namecard": "Name on card",
    "typecard": "Card Type",
    "expirationdate": "Expiry date",
    "securitycode": "Security code",
    "automaticallypay": "Auto recharge my account when credit gets below",
    "termsuse": "Terms of Use",
    "termsurl": "/resources/usage-policies/terms-of-use",
    "readandagree": "I have read and agree to the",
    "amount": "Amount",
    "myaccount": "My Account",
    "notes": "Notes",
    "paymenterror": "Payment error",
    "registrationerror": "Credit Card registration error",
    "changesmtp": "Change SMTP",
    "expiry": "Expiry",
    "forthefirst": "for the first",
    "fornext": "for next",
    "forremaining": "for remaining contacts",
    "remainingcontacts": "Remaining contacts",
    "contactsstored": "Contacts stored this billing period",
    "contactstext": "contacts",
    "basicdesc": "For home businesses & solopreneurs.",
    "advanceddesc": "For home businesses & professional marketers.",
    "premiumdesc": "For marketing teams & agencies.",
    "fasterdelivery": "Faster delivery speed",
    "webhooksinfo": "Webhooks allow you to stay up-to-date with how contacts engage with your email campaigns. </br> Once your email is sent, opened, or clicked, we'll send you an update, so you can always stay in the loop.",
    "webhooks": "Web hooks",
    "createwebhook": "Create Webhook",
    "resellerfeatures": "Reseller features",
    "additionalusers": "Additional users",
    "usermanagement": "User management",
    "subaccacounts": "Sub accounts",
    "extlogs": "Extended logs",
    "rdns": "rDNS",
    "customrdns": "Custom rDNS",
    "smtphttpaccess": "SMTP and HTTP API Access",
    "smsapi": "SMS API",
    "allfeatures": "All features from",
    "planplus": "plan plus",
    "statementdate": "Statement date",
    "statementperiod": "Statement period",
    "jobsent": "Jobs Sent",
    "attachments": "Attachments",
    "totalcost": "Total cost",
    "attachmenttotalsize": "Attachment total size",
    "privateips": "Private IPs",
    "ipregion": "IP Region",
    "anyregion": "Any region",
    "northamerica": "North America",
    "europe": "Europe",
    "moreinformation": "More Information",
    "smsrecipients": "SMS recipients",
    "smscost": "SMS",
    "smsverification": "SMS verification",
    "cost": "Cost",
    "totalusage": "Total cost",
    "company": "Company",
    "companyname": "Company name",
    "current": "Current",
    "previous": "Previous",
    "prev": "Prev",
    "next": "Next",
    "news": "New",
    "add": "Add",
    "upload": "Upload",
    "addcontact": "Add contact",
    "confirm": "Confirm",
    "confirmupload": "Confirm upload",
    "confirmationtext": "Are you sure?",
    "success": "Success",
    "addsecondkey": "Add second key",
    "allowprivateiprequest": "Allow for private IP purchases",
    "desc_allowprivateiprequest": "Enable subaccount to purchase Private IP's that will be assigned to their account. Once configured, only their emails will be delivered from these IP's.",
    "editsubaccount": "Edit sub account",
    "privateiprequest": "Private IP request",
    "emailcreditscost": "Email credits",
    "templatecreditscost": "Template credits",
    "badgewillbeadded": "Elastic Email badge will be added to all emails.",
    "upgradetrial": "If you want to make email even more custom branded upgrade your plan.",
    "contactcost": "Contact delivery tools",
    "privateipcost": "Private IP",
    "privateipcount": "Private IP count",
    "numberofips": "# IPs",
    "numberofattachments": "# attach",
    "attachmentsize": "Attach size",
    "supportcost": "Support",
    "emailcost": "Email plan",
    "verification": "Verification",
    "verifications": "Verifications",
    "verificationcost": "Verification cost",
    "verificationcount": "Verification count",
    "allowsmtpsend": "Allow SMTP send",
    "desc_allowsmtpsend": "Enable subaccount to submit emails via SMTP Relay",
    "allowapisend": "Allow API send",
    "desc_allowapisend": "Enable subaccount to submit emails via HTTP API.",
    "allowinterfacesend": "Allow interface send",
    "desc_allowinterfacesend": "Enable subaccount to submit emails via user interface (campaign creation)",
    "allowsmssend": "Allow SMS send",
    "desc_allowsmssend": "Enable subaccount to submit SMS text messages to any valid mobile phone number",
    "payouthistory": "Payout history",
    "referalspayouthistory": "Referrals payout history",
    "createdate": "Created date",
    "creditbalance": "Credit balance",
    "total": "Total",
    "nopayoutdesc": "We have not made any payouts to you for your referrals.",
    "currentreferralcreditbalance": "Current referral credit balance",
    "referralpercent": "Referral rate",
    "nopayouttitle": "No payouts",
    "howtoearn": "How to earn money?",
    "lastpayoutdate": "Last payout",
    "activereferrals": "Active referrals",
    "referralprice": "Referral rate",
    "changecustombrandingdatanote": "Note - this information is shared on your profile and custom branding settings so if you change/edit it here it will also be changed elsewhere.",
    "spamcheckresults": "Spam check results",
    "contentanalysis": "Content analysis",
    "spamcheck": "Spam check",
    "property": "Property",
    "description": "Description",
    "value": "Value",
    "scoreimpact": "Score impact",
    "reputationscore": "Reputation impact",
    "invalidemailaddresses": "Invalid email addresses",
    "openrate": "Open rate",
    "clickrate": "Click rate",
    "sendingdomain": "Sending domain",
    "trackingdomain": "Tracking domain",
    "notconfigured": "NOT CONFIGURED",
    "reviewspamhistory": "Review your spam check history",
    "important": "Important",
    "neversharesub": "Your phone number will never be shared with other companies or used for marketing purposes.",
    "nevershare": "Elastic Email will never share your phone number with other companies or use it for marketing purposes.",
    "identityversub": "It is a one time identity verification via SMS to protect our users and prevent abuse of our systems. Please enter your complete phone number, including your country code (eg. +1 123 456 7890). This phone number will only be  used for account security. No charges apply for this text message.",
    "identityver": "This is a one time identity verification. It helps us protect our users and prevents our systems from abuse.",
    "codever": "Please enter the verification code from the SMS we have just sent you and double-check that you have typed in all 4 digits. Make sure that you are within a cellular reach in order not to miss the verification SMS.",
    "wrongnumberformat": "Wrong format! Please make sure you have entered complete phone number, including your country code (eg. +1 123 456 7890).",
    "wrongcodeformat": "Wrong format! Please enter all 4 digits to complete the verification.",
    "wrongnumberformatshort": "We couldn't recognize the phone number format. Try again?",
    "consenttrackingonunsubscribe": "Tracking consent",
    "consenttrackingonunsubscribedesc": "Allow contacts to opt in or out of tracking events such as open and clicks via the unsubscribe tracking page.",
    "notificationque": "Current queue",
    "notificationlink": "Link",
    "lastattempt": "Last attempt",
    "retry": "Retry count",
    "lowcreditnotification": "Credit reminders",
    "lowcreditnotificationdesc": "Tick it if you want us to remind you about low credit, so your account will always be ready to send emails.",
    "sendingoptions": "Sending options",
    "enableopentracking": "Track opens",
    "enableopentrackingdesc": "Tick to track if user opens your emails. This is the most basic way to measure user engagement.",
    "enableclicktracking": " Track clicks",
    "enableclicktrackingdesc": " Tracking clicks allows us to measure if user was interested enough to not only open email, but also to interact with it.",
    "enablelinkclicktracking": "Track clicks by link",
    "enablelinkclicktrackingdesc": "Tick to receive detailed report about links clicked in your emails.",
    "enableutms": "Enable UTM",
    "enableutmsdesc": "Tick to enable UTM parameters on links in your campaign.",
    "enabletemplateutms": "Enable different UTMs for templates",
    "enabletemplateutmsdesc": "Tick to provide different UTM parameters on links in each of your templates.",
    "utmsfortemplateschecked": "Select the template to add the UTM tag.",
    "utmsource": "UTM_Source",
    "utmcampaign": "UTM_Campaign",
    "utmmedium": "UTM_Medium",
    "utmcontent": "UTM_Content",
    "notifyonce": "Notify once per email",
    "tracknotifyoncedesc": "Tick to only send one of each notification type above per email",
    "tracksentdesc": "Tick to receive notifications for sent emails.",
    "trackopeneddesc": "Tick to receive notifications for opened emails.",
    "trackclickeddesc": "Tick to receive notifications for clicked emails.",
    "trackunsubscribeddesc": "Tick to receive notifications about users unsubscribing from your email.",
    "trackcomplaintsdesc": "Tick to receive notifications for complaints.",
    "trackbounceerrordesc": "Tick to receive notifications for bounced emails.",
    "managesubscriptions": "Manage subscriptions",
    "managesubscriptionsdesc_old": "By enabling this option, your subscribers will be able to choose which of your available lists they wish to remain on or unsubscribe from. ",
    "managesubscriptionsdesc": "Allow contacts to manage their subscriptions to individual lists via the unsubscribe page.",
    "managesubscriptionslink": "manage subscriptions.",
    "managesubscribedonly": "Subscribed lists only",
    "managesubscribedonlydesc": "Contacts can only unsubscribe from lists they are currently subscribed to.  Other lists are not displayed. Note - preview will not display this feature.",
    "trackhistory": "Track history",
    "trackhistorydesc": "Toggle this option to easily track the past changes in the number of your contacts on this list. You can monitor up to 10 lists/segments.",
    "segmenttrackhistorydesc": "Track the past changes in the number of your contacts in History tab. You can add up to 10 lists / segment to track.",
    "andorsegmentrulenotification": "Please note, that if you want to use \"And\" and \"Or\" in your rule, you need to add a sub-rule.",
    "transactionalonunsubscribe": "Transactional unsubscribe",
    "criticalemailsonly": "Critical emails only",
    "criticalemailsonly_desc": "Allow contacts to opt-out from all marketing emails and receive only critical (transactional) communication. This option will be visible on your unsubscribe page.",
    "transactionalonunsubscribedesc": "Allow contacts to opt into transactional email only via the unsubscribe tracking page. When set, you must pass the parameter/header 'isTransactional = true' to send to contacts with a Transactional status.",
    "enableunsubscribeheader": "Use list unsubscribe",
    "enableunsubscribeheaderdesc": "Include a List-Unsubscribe header. This header is often used by many mail clients to provide an unsubscribe option outside of the email.",
    "previewunsubscribe": "Preview unsubscribe",
    "previewunsubscribedesc": "View what your unsubscribe form will look like with the various unsubscribe settings.  You must save and wait a minute before previewing to see latest changes.",
    "bccemail": "BCC email address(es)",
    "bccemaildesc": "Every mail transaction you send will be also sent to those addresses.",
    "logourldesc": "Enter a valid path to your logo that will be used on the unsubscribe form.",
    "unsubscribenotificationsemails": "Unsubscribe notifications",
    "subscriptionlink": "Subscription link",
    "subscriptionlinkdesc": "Use this link for a double opt-in web form to subscribe new contacts or re-activate unsubscribed contacts.",
    "unsubscribenotificationsemailsdesc": "Send an email notification when a contact unsubscribes.",
    "newcontactstoday": "New contacts today",
    "newcontacts": "New contacts",
    "newcontactsperday": "New contacts per day",
    "contactsgrowth": "Contacts growth",
    "unsubscribetargets": "Send notifications to:",
    "unsubinfo": "Addresses must be separated by semi-colon ';'",
    "bccinfo": "Addresses must be separated by semi-colon ';'",
    "deliveryreason": "Delivery reason",
    "bounceoptions": "Bounce",
    "bouncehandle": "Handle all bounce responses",
    "bouncereturnsender": "Return bounces",
    "bouncereturnsenderdesc": "This option will enable sending a non-delivery reports back to you.",
    "tospecificemail": "To specific email",
    "ifblanktheywillreturntosender": "If blank they will return to sender",
    "bccnotifications": "Forward system notifications",
    "bcctargets": "Recipients",
    "notificationbcclist": "Email addresses, separated with semicolons",
    "bccnotificationsdesc": "Send system notifications to additional emails",
    "autocreatebody": "Auto create text body",
    "autocreatebodydesc": "Let us create a plain text message, based on your emails content, for users that don't receive HTML emails.",
    "enabletemplatescripting": "Enable template scripting",
    "stalecontactscore": "Stale contact score",
    "contactpruningscore": "Contact pruning (by score)",
    "contactpruningday": "Contact pruning (by day)",
    "stopsendingafter": "Stop sending to contacts after",
    "enabletemplatescriptingdesc": "For advanced users wishing to use scripting in your emails.",
    "enableuitooltips": "Enable UI tooltips",
    "enablecustomheaders": "Allow custom headers",
    "enablecustomheadersdesc": "Tick it to use your own custom headers (to send and receive additional data) in your emails.",
    "fileencoding": "File encoding",
    "encodingtype": "Content transfer encoding type",
    "encodingmsg": "Our system supports Unicode (UTF-8). In order not to experience any syntax errors, select your file encoding from the drop down list and run the upload process.",
    "encodingtypedesc": "The type of content encoding (text, images, everything) that is used to cipher and send your email. We recommend Base64 encoding, but you are free to choose your favorite.",
    "userprovider": "User provided",
    "webnotification": "Webhooks",
    "webnotyficationhelp": "Http address must return status '200 OK' to be properly verified",
    "notifications": "Notifications",
    "webnotifications": "Web notifications",
    "newsnotifications": "You have unread notifications",
    "inboundnotification": "Inbound email",
    "inboundnotificationdesc": "URL where Inbound email notifications will be available. They will be waiting there after being parsed by our system.",
    "inbounddomain": "Inbound domain",
    "inbounddomaindesc": "Please change MX record of selected domain to our inbound domain. After that we will be able to parse and analyse your incoming emails.",
    "notificationfrommycontacts": "Notify me only from my contacts",
    "notificationfrommycontactsdesc": "Select to create notifications only from people on your contact lists.",
    "disableglobalcontacts": "Delivery optimization engine",
    "disableglobalcontactsmodal": "Delivery optimization engine - privacy setting",
    "disableglobalcontactsdesc": "Analyze delivery across our entire platform and can improve your email delivery. Personal data is limited to your contact's email address.",
    "disableglobalcontactsmodaldesc": "Our system has sophisticated algorithms that analyze delivery across our entire platform and can predict and provide recommendations with regards to your email delivery.<br><br> We recommend your participation with this powerful feature that will improve your overall delivery and provide the best results to recipients all around the world.<br><br> Personal data is limited to your contact's email address.",
    "privileges": "Privileges",
    "ulrnotification": "Notification Link",
    "notificationon": "Send notifications on",
    "showadvanced": "&#x25B6; Show advanced",
    "hideadvanced": "&#x25bc; Hide advanced",
    "notifyweeklystats": "Weekly stats",
    "notifyweeklystatsdesc": "Tick it if you want us to report to you with latest statistics.",
    "sent": "Sent",
    "bounceerror": "Bounce/Error",
    "addip": "Add Private IP",
    "purchaseip": "Purchase IP",
    "yesplease": "Yes Please",
    "purchaseprivateip": "Purchase Private IPs",
    "requestadditionalprivateip": "Purchase additional private IPs",
    "addippool": "Add IP pool",
    "delippool": "Delete IP pool",
    "ippoolname": "IP pool name",
    "iplist": "IP list",
    "assignedips": "Assigned IPs",
    "editippool": "Edit IP pool",
    "deleteippool": "Delete",
    "deleteip": "Delete",
    "deleted": "Deleted",
    "addnewip": "Add new IP",
    "ipaddons": "IP",
    "premiumsupport": "Premium support",
    "iprequestmessage": "Your request has been processed successfully. View the current Sender Score and MX ToolBox blacklist report on your Private IP screen.",
    "emailsupportdesc": "Need some help, no problem.  We offer free 24/7 email support at support@elasticemail.com",
    "prioritysupportdesc": "Want your questions answered sooner?  Receive in-app support for your Elastic Email account and your questions will be automatically prioritized over email support.  The cost is only $1/day with a 30 day minimum.",
    "premiumsupportdesc": "Need a little extra love?  Receive real-time in-app chat support from our support team with the highest priority.  A dedicated account manager will be assigned to your account as a point of contact.  The cost is only $7/day with a 30 day minimum.",
    "prioritysupportdeschelp": "You are about to add the Priority Support option to your account. <br />The cost for this feature is only ${{price}}/day and requires a 30 day minimum.<br />Would you like to proceed?",
    "premiumsupportdeschelp": "You are about to add the Premium Support option to your account. <br />The cost for this feature is only ${{price}}/day and requires a 30 day minimum.<br />Would you like to proceed?",
    "deliveryipaddress": "Delivery IP addresses",
    "deliveryippools": "Delivery IP pools",
    "ip": "IP address",
    "isp": "ISP",
    "ipreversedns": "Reverse DNS",
    "ipsenderscore": "Sender score",
    "ipmxtoolbox": "Blacklist",
    "ipmailerid": "MailerID",
    "ipdescription": "Description",
    "deliverystatus": "Delivery status",
    "servername": "Server name",
    "provider": "Provider",
    "ipactive": "Active",
    "domain": "Domain",
    "def": "Default",
    "track": "Tracking",
    "useyourowndomains": "Use your own domains",
    "selectapikey": "You must select API Key",
    "adddomain": "Add Domain",
    "adddomainvalidation": "Add domain validation",
    "setasdefault": "Set as Default",
    "setbouncedomain": "Set Bounce Domain",
    "bouncedomaininfo": "A bounce domain informs the server that all aspects of the message fall in to requirements. In return  the message is “whitelabeled”. This builds reputation with the server and your email service provider. By default, bounce domain address points to bounces.elasticemail.net. ",
    "bouncedomaininforesellers": "A bounce domain informs the server that all aspects of the message fall in to requirements. In return  the message is “whitelabeled”. This builds reputation with the server and your email service provider. ",
    "verify": "Verify",
    "saveandverify": "Save and verify",
    "setdefaultsender": "Set default sender",
    "editdefaultsender": "Edit Default Sender",
    "domainname": "Domain name",
    "defaultemailaddress": "Default sender",
    "apikeyinfo": "Copy and save your API Key. Once you close this window you will not be able to retrieve it.",
    "noapikeyheader": "You do not have any API Keys currently configured.",
    "noapikeytext": "To connect to our API please create a new API Key.",
    "apikeycreated": "API Key Successfully Created",
    "apikeychanged": "API Key changed",
    "createapikey": "Create API Key",
    "createadditionalapikey": "Create additional API Key",
    "smtpinfo": "Copy and save your password. Once you close this window you will not be able to retrieve it.",
    "nosmtpheader": "You do not have any SMTP Credentials currently configured.",
    "nosmtptext": "To send emails via SMTP please create a new SMTP Credential.",
    "createsmtpcredential": "Create SMTP Credential",
    "smtpcreated": "Credentials Succesfully Created",
    "learnhowtouse": "Learn how to use",
    "alternateportdescription": "alternative servers and connection ports",
    "restrictedaccessdesc": "To further secure your connection you can optionally limit access to a single IP or range of IPs. The following formats are supported and delimited by a comma for multiple IPs or IP Ranges: xxx.xxx.xxx.xxx, xxx.xxx.xxx.xxx-yyy.yyy.yyy.yyy, xxx.xxx.xxx.xxx/xx and IPv6.",
    "returnto": "Return to",
    "emailapipro": "Email API PRO", 
    "emailapi": "Email API",
    "apipro": "PRO",
    "emailapipro_description": "Pay as you go pricing for senders ready for the complete API experience.",
    "apikeyconnected": "Plugin successfully connected with the Elastic Email API.",
    "apikeynotconnected": "Plugin disconnected from the Elastic Email API.",
    "useraccessfull": "The same access as the main Account holder.",
    "useraccesslimited": "Limited access to common functionality.",
    "useraccessview": "View only access without the ability to make changes.",
    "useraccesscustom": "Customize your specific permission requirements.",
    "apikeyaccessfull": "Full access to the HTTP API. We recommend limiting to the required permission level. ",
    "viewemailverifications": "View Email Verifications",
    "apikeyaccesseeplugin": "Limited access for typical third party plugins",
    "apikeyaccessplugin": "Limited access for typical third party plugins.",
    "apikeyaccessform": "Limited access for external subscription forms such as Word Press.",
    "apikeyaccesscustom": "Customize your specific permission requirements.",
    "apikeyaccessview": "View only access without the ability to make changes.",
    "accessnone": "No access to screens or data. This permission will deny all access to the system.",
    "accessnonetitle": "Unable to login",
    "accessnonetext": "Your account cannot log in to dashboard. Please contact creator of the account to check your privileges.",
    "apikeyexpiredtitle": "Your Session Has Expired",
    "apikeyexpiredtext" : "Your session has expired and you must log in again.",
    "requiredfor": "Required for",
    "datecreated": "Date created",
    "datetested": "Date tested",
    "diagnosticsdetails": "Diagnostic details",
    "diagnosticticket": "Diagnostics ticket request",
    "generate": "Generate new",
    "newticket": "New ticket",
    "received": "Received by",
    "transaction": "Transaction",
    "details": "Details",
    "additionaldetails": "Additional details",
    "datesubmitted": "Date submitted",
    "datecompleted": "Date completed",
    "deliveryhistory": "Delivery history",
    "dkimresolvelog": "DKIM resolve log",
    "mxlookup": "MX lookup",
    "dnslog": "DNS log",
    "history": "History",
    "message": "MIME message",
    "outboundsmtp": "Outbound SMTP",
    "replytoemail": "Reply to email",
    "replytoname": "Reply to name",
    "request": "API Request",
    "requestonly": "Request",
    "requestinfo": "Request info",
    "response": "API Response",
    "only": "Only",
    "senderemail": "Sender email",
    "sendername": "Sender name",
    "signwithdkim": "Signed with DKIM",
    "spfresolvelog": "SPF resolve log",
    "smtpdialog": "SMTP outbound dialog",
    "transactionlog": "Transaction log",
    "transactionid": "Transaction ID",
    "erccdate": "Please enter CC expiration date in MM/YY format",
    "ermonth": "Please enter month.",
    "eryear": "Please enter year.",
    "eremail": "Please enter your email address.",
    "erfirstname": "Please enter your first name",
    "erlastname": "Please enter your last name",
    "eraddress": "Please enter your address.",
    "ercity": "Please enter your city.",
    "erprovince": "Please enter your state or province.",
    "ercountry": "Please select your Country.",
    "erzip": "Please enter your zip or postal code.",
    "ercreditnumber": "Please enter a valid credit card number.",
    "ercredittype": "Please enter number of either Visa, MasterCard or American Express card.",
    "ercreditname": "Please enter the name which appears on your credit card.",
    "ercreditcvv": "Please enter the 3 or 4 digit security code from the back of your card.",
    "erterms": "To proceed you must agree to our Terms of Use.",
    "termsofuse": "Terms of use.",
    "erauto": "Please enter valid credit level.",
    "erlength": "Input field has too many characters.",
    "descnewlist": " You can separate your contacts into different lists to separate them for email sending purposes. Once you have created a list you can select contacts from other lists to move into the new list or you can upload directly into it using the Add contacts button.",
    "descuploadsub": "Upload files are Comma Separate Values (CSV) files.<br /><br />Your CSV file needs to have at minimum 1 column labelled email as the first row.  You can upload any other column on the ccntact details screen such as first and last.",
    "descuploadblock": "Upload files are Comma Separate Values (CSV)<br /><br />Your CSV file needs to have 2 columns labelled email and status in the initial row. Field order is not important. The values for Blocked Recipients status are Bounced, Unsubscribed or Abuse.",
    "descquickadd": "You can easily add your contact to the existing lists by filling the inputs below. If you want to add more contacts than one, we recommend to add them with ",
    "descquickaddend": "Add a group of contacts.",
    "uploadfilecontacts": "Upload contacts from file (zip, csv, json, xml)",
    "uploadtypecontacts": "Type or copy and paste contacts",
    "descdiag": "Below is automatically generated email address for system diagnostics. Please send message to it for further results. It will log everything what's happening (from submitting email to our system to delivering it to generated email).",
    "descconsentsub": "I confirm these contacts have consented to receive my communications",
    "descconsentsubquickadd": "This person permitted to receive my communications.",
    "descconsenttrackingsub": "Contact does not allow open or click tracking",
    "descaddblockedstatus": "NOTE - You are adding a contact with a blocked status",
    "descuploadblockedstatus": "NOTE - You are uploading contacts with a blocked status",
    "descconsentsuberror": "You must confirm every contact has given you consent to receive your communications.",
    "descconsentsuberrortitle": "Explicit consent required",
    "descconsentsubscribed": " email has been successfully subscribed.",
    "descrawhtml": " Create a template directly<br/>from HTML code.",
    "descdragdrop": "Use our user friendly Email Designer to create a new template.",
    "uploadfailed": "Upload failed.",
    "fileslisttoupload": "Files list to upload",
    "images": "Images",
    "files": "Files",
    "inlinestyles": "Convert styles",
    "sendto": "Send to",
    "sendcampaign": "Send campaign",
    "savecampaign": "Save Campaign",
    "campaigntutorialtitle": "New tabs for campaigns",
    "campaigntutorialcontent": "<p>Your campaigns are distributed in to different tabs now. Navigate through them to find your regular campaigns, automation campaigns, draft, etc.</p>",
    "nocampaignsforitem": "There were no campaigns sent for this group.",
    "targetaudience": "Audience",
    "descchoosetemplate": "Please select one of your templates that you would like to send a campaign with.",
    "descchooselist": "Please select one of your lists that you would like to send your campaign to.",
    "descsendcampaign": "Please click the Preview button to view your campaign before sending.",
    "emptydraftscampaignlist": "No campaign drafts have been saved yet.",
    "emptyautomationcampaignlist": "No triggered campaigns have been set yet.",
    "emptycampaignlist": "You have not sent any campaigns yet.<br/>Click <a href=\"#/campaigncreator\" class=\"btn btn-success\">Create campaign</a> button to start creating your first campaign.",
    "campaignname": "Campaign name",
    "previewcampaign": "Preview campaign",
    "campaignreport": "Single campaign activity",
    "sendingto": "Sending to",
    "scheduledfor": "Scheduled for",
    "invalidscheduletitle": "Invalid schedule",
    "negativeminutes": "You can not schedule a campaign in the past",
    "invalidscheduletime": "You can not schedule a campaign in the past",
    "invalidcontactslist": "You must choose a list with at least one contact. After clicking \"OK\" you will be redirected to \"Campaigns\" page",
    "campaignwithoutrecipients": "Campaign is being prepared and will start in a moment. Please refresh.",
    "freemiumdescneedscredit": "You are now using more than your limit of {{limit}} free contacts and you do not have enough credit to continue.  You need to make a purchase of at least ${{cost}} of credit and have Auto Recharge Credit configured when your credit drops below the level you choose.  ${{cost}}/month will be deducted from your Elastic Email credit for these additional contacts.",
    "freemiumdesc": "You are now using more than your limit of {{limit}} free contacts.  You have enough current credit for these additional contacts but you need to have Auto Recharge Credit configured with a Credit Purchase Amount of at least ${{cost}} to continue.  Your credit will be automatically recharged with the Credit Purchase Amount when it is below the amount you choose.  ${{cost}}/month will be deducted from your Elastic Email credit for these additional contacts.",
    "additionalmonthlycostdesc": "You are now using more than your limit of {{limit}} free contacts. ${{cost}}/month of Elastic Email Credit will be deducted from your Account.",
    "autorechargecreditinfo": "Enabling auto recharge on your account will not make an immediate payment if your current credit balance is greater than your recharge level.  Payment will be made when your credit drops below the recharge level you choose.",
    "monthlycontactsubscription": "Monthly contact subscription",
    "contactsubscription": "Contact subscription",
    "nextbillingdate": "Next billing date",
    "contactscount": "# of contacts",
    "enableautorecharge": "Your card will be charged if your credit falls below chosen plan",
    "payandenableautorecharge": "Make a payment now and set an auto-recharge for the future",
    "chargerightaway": "Make instant payment",
    "autopaymenterrordesc": "Account configuration issue occured. You are using more than your limit of free contacts.",
    "desccurrentreferralcredit": "Refer a friend to our services and earn 20% of what they spend for one year.  Simply provide the link below to get started.  As your referrals purchase credit you will accumulate credit above.  You can request a payout once your credit has reached $100 or more.",
    "referraladspackagedesignedby": "referral ads package designed by Elastic Email.",
    "referralswaytoearn1": "Have you ever wondered how to",
    "referralswaytoearn2": "with Elastic Email? It's easier than you think!",
    "referralswaytoearn3": "For each customer referred to Elastic Email, earn 20% of their purchases for the first six months. Provide a friend or business your referral link to sign up with Elastic Email or share it by email, social media, your website or blog. Need more than a link? Be sure to download our",
    "earnmoney": "earn money",
    "referraladspackage": "referral ads package",
    "referralswaytoearn4": "with ready to go images/banners that can be used in email, websites or even Facebook and Google Ads campaigns.",
    "referralsexplanation": "Once you have reached $100, we will send your earnings via PayPal or choose to use them for Elastic Email services.",
    "referralsreadytoearn": "Ready to get started? Copy the link below and start making money with Elastic Email today!",
    "referralactivelinkcta": "Use the referral link below to get even more friends to your referrals",
    "totalactive": "Total active referrals",
    "referralrate": "Referral rate",
    "dailyactivity": "Daily activity",
    "referralresources": "Referral resources",
    "learnmorereferrals": "about 'Referral Program' and take your referrals to the next level.",
    "accountingdepartment": "Accounting department in case of any questions.",
    "getreflink": "Copy referral link",
    "referralsregistered": "Referrals registered",
    "reflinkcopied": "Referral link copied!",
    "withdrawreferrals": "Request Withdrawal",
    "btntitlewithdrawaltype": "Withdrawal type",
    "optiontransfertopaypal": "Send to PayPal",
    "optiontransfertoelasticemail": "Transfer to my Elastic Email Account",
    "withdrawrequestsent": "Your request has been sent.",
    "referralbalance": "Referral balance",
    "errorreferralcreditstoolow": "The minimum required amount is $100",
    "withdrawmodaltitle": "Request Withdrawal",
    "availablereferralcredits": "Available balance",
    "withdrawalamount": "Withdrawal amount",
    "withdrawalmsgtxtareatitle": "Message / Comments",
    "emailinvalid": "Please enter a valid email address",
    "payoutrecordamountlabel": "Set amount (in USD)",
    "record": "Record",
    "recordpayout": "Record payout",
    "referralswithdrawaltypechoose": "Choose the withdrawal type",
    "pleasepickpayouttype": "Please choose the withdrawal type",
    "pleaseconfirmelasticemail": "Please confirm your Elastic Email Account email address",
    "pleaseprovidepaypalemail": "Please provide your PayPal email address",
    "envelopefrom": "Envelope From",
    "yourreputationscore": "Your reputation score of ",
    "islowerreputation": " is lower than your current sending level's reputation score requirement of ",
    "islowerreputationdesc": " In order to reach higher levels and lower prices, you need to address your reputation score. Please review the details below to address your specific areas of improvement and contact support if you have any questions or require assistance.",
    "reputationrequirment": " meets your current sending level's reputation score requirement of ",
    "nextreputationneed": ". In order to reach the next sending level however, your reputation score needs to be at ",
    "nextreputationdesc": ". Please review the details below to address your specific areas of improvement to ensure lower prices at the next sending level. Contact support if you have any questions or require assistance.",
    "reputationscreendesc": "Account reputation is designed to be a reflection of the quality of email being sent on the account. Improving an account's reputation will simultaneously improve an account's delivery and vice versa. Review areas that have negative values to improve your reputation. ",
    "reputationlearnhow": "Learn how we calculate reputation.",
    "nextlevelrepuataion": " meets your current and next sending level's reputation score requirement. Keep up the great work and enjoy the savings of sending quality email!",
    "maxreputation": " Congratulations, you are enjoying the lowest price per email on the market! Your repuation score is ",
    "deschtmlcodebuilderwebform": "Copy and paste the code below to your website. Visitors who subscribe will be added to your contact list. You may change the order of the fields or style to fit your site.",
    "paymentdescription": "Thank you for your purchase. A receipt for this payment has been emailed to the address you supplied. The payment will show up on your credit card billing statement as: geographicalmediaUSD OrderID: ",
    "descreviewaccount": "Are you sure to review your account ?",
    "desccomplaints": "Users reporting your email as spam",
    "descinvalidemailaddresses": "Email addresses that do not exist",
    "desccontentanalysis": "How your email content is rating to spam filtering software",
    "descopenrate": "Your last 30 day open rate",
    "descclickrate": "Your last 30 day click rate",
    "descsendingdomain": "Using your own sending domain to further white label your email",
    "desctrackingdomain": "Using your own tracking domain to further white label your email",
    "noticevalid": "Note: If you just added the records in your DNS it can take 2-3 hours before the changes are visible to our servers. If you believe they are correct wait a few hours and try to verify again.",
    "crossdomainerror": "<p>On IE7, IE8, and IE9 just go to:<br/>Settings->Internet Options->Security->Custom Level<br/>Change security settings under \"Miscellaneous\"<br/>Set \"Access data sources across domains\" to Enable.</p>",
    "queryerror": "Can't execute query",
    "descprivateip": "Enhance your delivery by purchasing one or more private IPs.  Private IPs need to be purchased for a minimum of 1 month.",
    "privateipbouncedetails": "View detailed bounce information by purchasing a Private IP.  Private IPs need to be purchased for a minimum of 1 month.",
    "descadditionalprivateip": "Speed up your delivery and redundancy by purchasing additional private IPs.  Private IPs need to be purchased for a minimum of 1 month.",
    "privateipcosttext": "The cost is currently only $",
    "privateipcosttextpermonthend": "/ month / IP.",
    "privateipcosttextperdayend": "/ day / IP.",
    "descprivateip2018": "Using private IP addresses gives you complete control of your IP's external reputation. Other user’s email will have no effect on your delivery.",
    "oneipnotice": "Please note that having at least 2 IPs is highly recommended to ensure redundancy of delivery from separate servers and for any potential reputation issues you may encounter with your mail.",
    "region": "Region",
    "quantityinfo": "Purchase 2 or more Private IPs to also:",
    "quantityinfo1": "increase delivery speed",
    "quantityinfo2": "separate your marketing and transactional email",
    "quantityinfo3": "separate your email by domain and contact quality",
    "quantityinfo4": "create custom sending pools",
    "quantityinfo5": "improve reliability and avoid a single point of failure",
    "ipnoteinformation": "Please provide us with your email frequency/volume (daily, weekly, monthly for example), the type of email you would like to send and any questions you might have about your IP configuration.",
    "status": "Status",
    "showspamcheck": "Show spam check",
    "sendtestimmediately": "Send test template immediately",
    "deliveryrate": "Delivery rate",
    "attemptrate": "Attempt rate",
    "successrate": "Success rate",
    "errormsg": "Error message",
    "errormessage": "Something went wrong!",
    "type": "Type",
    "addnewrule": "Add new rule",
    "addrule": "Add rule",
    "addrecord": "Add new record",
    "editrule": "Edit rule",
    "editrecord": "Edit record",
    "deleterecipients": "Selected addresses will be deleted. Do you wish to continue?",
    "deleteselectedrecipients": "Delete addresses",
    "deletemailfilters": "Selected filters will be deleted. Do you wish to continue?",
    "deleteselectedmailfilters": "Delete filters?",
    "deletekeywords": "Selected keywords will be deleted. Do you wish to continue?",
    "deleteselectedkeywords": "Delete keywords?",
    "deletepatterns": "Selected patterns will be deleted. Do you wish to continue?",
    "deletedomainrules": "Selected domian rules will be deleted. Do you wish to continue?",
    "deleteselectedpatterns": "Delete patterns?",
    "deleteselectedrules": "Delete rules?",
    "deleteglobalblocklist": "Selected emails will be deleted. Do you wish to continue?",
    "deleteselectedglobalblocklist": "Delete emails from global block list?",
    "globalblocklist": "Global block list",
    "mailfilterrule": "Mail filter rule",
    "securitykeywords": "Security keywords",
    "accountabusepatterns": "Account abuse patterns",
    "accountactivated": "Congratulations! Your account has been activated. Please, log in with your work email and password.",
    "seen": "Seen",
    "emailinfo": "Email info",
    "totalscore": "Total score",
    "spamrules": "Spam rules",
    "ttstartmenu": "Quick overview of your account. You can access common actions easily and review your account at a glance.",
    "ttcontactsmenu": "Manage your contacts for your account. You can create contacts, upload, create lists and more.",
    "tttemplatesmenu": "Manage your email templates for your account. You can create custom templates from the Email Designer, copy and paste existing HTML code and choose from a variety of pre-designed templates.",
    "ttlandingpagessmenu": "Manage your landing pages for your account. You can create custom landing pages from the Designer, copy and paste existing HTML code and choose from a variety of pre-designed landing pages.",
    "ttcampaignsmenu": "Manage your sending campaigns for your account. You can create campaigns and easily track how the the campaign is doing in real-time.",
    "ttactivitymenu": "View a graphical representation of your emails as well as a detailed log of the emails sent through your account.",
    "ttaccountmenu": "View your account details such as your status, credit, price, sending level, profile, reputation, subaccounts and more.",
    "ttsignoutmenu": "Sign out of your account. This is very important if you are on a public computer.",
    "tthelp": "Get help for the particular screeen you are on.",
    "ttsettingsmenu": "Change options, manage IPs and set up domains, to send first-class emails",
    "ttengagedsubs": "Contacts which have recently interacted with your mail in a positive way.",
    "ttactivesubs": "Active but unverified contacts.",
    "ttbouncedsubs": "Contacts in your account or list that have hard bounced.",
    "ttunsubsubs": "Contacts in your account or list that have unsubscribed from your mailing.",
    "ttcomplaintsubs": "Contacts in your account or list that have marked your mail as spam/junk",
    "tttransactionalsubs": "Contacts in your account or list that have requested to receive critical/non-marketing transactional mail only.",
    "ttstalesubs": "Contacts in your account or list that have stale status.",
    "ttnotconfirmedsubs": "Contacts in your account or list that have not been confirmed via double optin activation",
    "ttinactive": "Contacts in your account that are manually suppressed.",
    "ttaddsubs": "Add contacts to your account or list.  You will be able to choose from uploading a CSV file or manually adding contacts",
    "ttchooselist": "Select a list to view or edit. The default is All Contacts if you haven't created any custom lists.",
    "ttmakelist": "Make a new list from the selected contact(s).",
    "ttdeletecontactsfromlist": "Delete the selected contact(s) from your list.",
    "ttdeletecontacts": "Delete the selected contact(s) from your account. The selected contact(s) will be deleted from all lists.",
    "ttremovecontacts": "Remove the selected contact(s) from this list. The selected contact(s) will not be deleted from your account or removed from other lists. To delete the contact(s) completely, select all contacts and choose delete.",
    "ttcopytolist": "Move the selected contact(s) to an existing list. After clicking you will be able to choose an existing list.",
    "ttcreatelistonly": "Create a new list without adding any contacts to it. You can add contacts to it after you create it by selecting it in the drop down above.",
    "ttbuildwebform": "Create a dynamic web form that can be easily inserted into your website so your users can directly sign up to your lists.",
    "ttexportlist": "Export your subsribers to a file format such as CSV or XML. This will export what you have selected above - All Contacts or a specific list.",
    "ttdeletelist": "Delete the current list you have selected in the drop down menu above.",
    "tteditlist": "Edit the name of the current list you have selected in the drop down menu above.",
    "ttlistsave": "Save the edited name.",
    "ttlistadd": "Save the newly created list.",
    "ttmovesubs": "Move the selected contacts to the chosen list. The contacts are not removed from the original list they were selected from.",
    "tteditcontact": "Edit the detailed information of this contact.",
    "ttactivate": "Activate the selected contacts so that you can send email to them again.",
    "ttdeactivate": "De-Activate the selected contacts to suppress them from all campaigns.",
    "ttsavecontact": "Save any changes you made to this contact.",
    "ttsavecontactandclose": "Save any changes you made to this contact and close contact's profile",
    "ttcancelcontactedit": "Cancel any changes you have made to this contact if you do not want to save your changes.",
    "ttexitcontactdetail": "Exit the contact details and return to the main contacts screen.",
    "ttexportbutton": "Export the selected list to the format you have choosen.",
    "ttsubupload": "Choose a CSV file to upload. Ensure the CSV file has at minimum the column emailaddress. Other supported columns include: firstname, lastname, title, organizationname, city, country, state, zip, phone, birthdate and gender.",
    "ttsubuploadfile": "Upload the choosen CSV file to the selected list or the default all contacts.",
    "ttsubquickadd": "Manually add the contact to the selected list or the default all contacts. The quick add form will reset itself after successful creation to allow for easy manual additions.",
    "ttshowcontacthistory": "View the event history of this contact.",
    "ttcreatetemplate": "Create a new template to be used with a campaign. You can choose from our Email Designer to build your template, create your template directly from HTML code or choose from pre-designed templates.",
    "ttbuytemplate": "Purchase a professionally designed premium template with your credit.",
    "ttedittemplate": "Edit the selected template using the email designer editor to make any changes to your template.",
    "ttedithtml": "Edit the HTML of the selected template (for advanced users).",
    "tteditbodytext": "Edit the selected template's text only body of your template. You can use the advanced option setting 'Auto Create Text Body' to automatically create a text body for all emails. Use this option only if you prefer to provide a specific text body.",
    "tteditdefaults": "Edit the selected template's defaults including from email, from name and subject. You can override these settings when sending a campaign as well.",
    "ttcopytemplate": "Copy the selected template.",
    "ttviewcampaigns": "View the selected template's campaigns that have been sent.",
    "ttdeletetemplate": "Delete the selected template if you no longer require it.",
    "ttselecteditor": "Use the Email Designer to create your template. No programming or HTML skills required.",
    "ttselecthtml": "Use the raw HTML for advanced users or if you already have an HTML template you want to use.",
    "ttsaverawhtml": "Save the changes you made to the raw HTML code.",
    "ttsaverawhtmlcopy": "Save a copy of the changes you made to the HTML code of the drag and drop template. Potential changes you made could cause it not to work in the Email Designer any longer so this option will preserve your original template.",
    "ttexitrawhtml": "Exit the raw HTML editor and return to the main templates screen.",
    "ttsendtest": "Send a test of your template to email addresses you provide without sending an actual campaign. Note that mail merge (replacement fields) will not work in the preview since you are only supplying a test email address and there is no contact record used in the test.",
    "ttautoformathtml": "Auto-format the HTML code to help make the code easier to read.",
    "ttsendtestnow": "Sends the template email to the provided email addresses.",
    "ttcanceltest": "Cancel the test send and return to the template.",
    "ttgobackfrommobile": "Return to the template editor screen.",
    "ttrotate": "Rotate the mobile device to display horizontal and vertical displays of your template.",
    "ttsaveeditor": "Save the changes you made with the editor.",
    "ttsavepublic": "Save the changes you made with the editor, if you want to be visible to subaccounts in the template gallery.",
    "ttsaveprivate": "Save the changes you made with the editor, if you want to be visible only for you",
    "ttexiteditor": "Exit the Email Designer and return to the main templates screen.",
    "ttpreviewmobile": "Preview this template in several different mobile devices such as iphone and blackberry.",
    "tteditlayout": "Edit the template layout to add rows, columns, images, text and more.",
    "ttpagedesign": "Edit the overall style and theme of your template. Text size, background color, font and more can be set from here.",
    "ttheaderdesign": "Edit the overall style and theme of your header section.  Text size, background color, font and more can be set from here.",
    "ttfooterdesign": "Edit the overall style and theme of your footer section.  Text size, background color, font and more can be set from here.",
    "ttpredesignselect": "Select a public template or one of your existing templates as a starting place for your new template.",
    "ttpredesigncancel": "Cancel your choice to use a pre-dsigned template and return to the template editor screen.",
    "ttexitlayout": "Return to the main template editor screen.",
    "tttemplategallery": "Choose from a growing list of pre-designed templates or purchase a premium professional template.",
    "ttsavedefaults": "Save the changes to your template defaults options.",
    "ttsavecopy": "Save a copy of the selected template with the defaults provided.",
    "ttsavebodytext": "Save the changes to your text only body.",
    "ttsaveimagelink": "Save the image options.",
    "ttcancelimagelink": "Cancel the image options.",
    "tthtmlcode": "View the HTML code for this web form.",
    "ttpreview": "View a preview of what your web form looks like.",
    "ttrestoredefaultform": "Restores the form to default with the 3 most common fields - email, first and last name.",
    "ttexitbuildwebform": "Exit build web form and return to the main contacts screen.",
    "ttactivationtemplate": "Choose custom template for contact activation. Template requires {url} merge tag for generating activation link.",
    "webformtemplateimportant": "Important: Custom activation template requires {url} merge tag for generating activation link. The activation template name should not contain any spaces.",
    "webformsaved": "Webform has been saved.",
    "webformlinkcopied": "Webform link copied.",
    "formlink": "Form link",
    "notifyonsubscription": "Notify the following email when a contact signs up",
    "descnotifyonsubscription": "Get email notification when somebody subscribes using this form",
    "ttrestoretolastdefaultform": "Restores form to the default styles and email field.",
    "ttrestoretolastsavedform": "Restores the form to last saved version.",
    "webformoptionsdisclaimer": "The following settings are optional to custom brand the sign-up experience for your contacts. If you do not configure these options we provide default options.",
    "webformcodedisclaimer": "Copy the HTML Form code and paste it into your website's HTML.",
    "formsnavtabform": "Form",
    "formdisclaimer": "Personal data disclaimer",
    "saveform": "Save Form",
    "subscribetonewsletter": "Subscribe to our newsletter",
    "newsletter_desc": "Elastic Email newsletter provides a monthly dose of fresh stories, tips and best practices.",
    "formdescription_desc": "Enter specific and clear language on the intended use of the collected information.",
    "newsletter_yes": "Subscribe me to the Elastic Email newsletter.",
    "newsletter_no": "No, thank you. I might try later.",
    "form_data_desc": "We use Elastic Email as our marketing automation service. By submitting this form, you agree that the information you provide will be transferred to Elastic Email for processing in accordance with their <a href='https://elasticemail.com/resources/usage-policies/terms-of-use/' target='_blank'>Terms of Use</a> and <a href='https://elasticemail.com/resources/usage-policies/privacy-policy/' target='_blank'>Privacy Policy</a>.",
    "form_resellers_disclaimer": "Provide information of where and how the information collected by this form will be processed and by who.",
    "ttaddfile": "Add a file by choosing a file from your computer or by dragging and dropping your file in the outlined area.",
    "ttselectfile": "Select the file you have highlighted to be used in your template.",
    "ttdeletefile": "Delete a previously uploaded file if you no longer require it.",
    "ttuploadok": "Upload the file to be available for your templates.",
    "ttuploadcancel": "Cancel the file upload and return to the media manager.",
    "ttcreatecampaign": "Create a campaign from an existing template and list. You can preview the campaign before sending it.",
    "ttcreateautomation": "Create a new automation",
    "ttcopycampaign": "Creates exact copy of campaign configuration",
    "ttsendcampaign": "Send the campaign now.",
    "ttschedulecampaign": "Schedule the campaign for a future date.",
    "ttsavecampaign": "Save the campaign to be sent upon your selected trigger event.",
    "ttcancelcampaign": "Cancel the campaign if you are not ready to send it or you want to start over.",
    "ttviewgraph": "View a graphical representation of the selected campaign in real-time. From here you will also be able to view the log for details on the selected campaign.",
    "ttviewtemplate": "View or edit the template that was used to create the selected campaign.",
    "ttdeletecampaign": "Delete the selected campaign. You will no longer be able to view information about this campaign once it is deleted.",
    "ttsendcampaignbutton": "Preview the campaign before actually sending it. You will have the option to cancel before sending.",
    "ttattupload": "Select attachment from your disk for email campaign",
    "ttattuploadfile": "Start uploading email attachment",
    "ttblockedrecipients": "View the email addresses that have been either hard bounced, unsubscribed or reported as spam.",
    "ttuploadblocked": "Upload csv file with blocked, unsubscribed recipients",
    "ttexportblocked": "Export the blocked recipient list to a common file format.",
    "ttexitblockedrecipients": "Exit blocked recipients and return to the main transactional screen.",
    "ttblockuploadfile": "Upload the choosen CSV file of blocked or unsubscribed contacts.",
    "ttrecipients": "The number of attempted deliveries.",
    "ttemailtotal": "The number of attempted email deliveries.",
    "ttsmstotal": "The number of attempted sms deliveries.",
    "ttdelivered": "The percent of successfully delivered emails.",
    "ttopened": "The percent of the delivered emails which were opened. (Opens are only tracked if they display images)",
    "ttclicked": "The percent of the delivered emails which were clicked on.",
    "ttunsub": "The percent of the delivered emails where people unsubscribed. (also includes complaints)",
    "ttcomplaints": "The percent of the delivered emails wherettloadadvanced people complained about your email as SPAM.",
    "ttbounced": "The percent of attempted emails or sms which failed.",
    "ttshowoverview": "Show basic overview",
    "ttshowlog": "Show the detailed emails/sms and results for the selected areas of the graph.",
    "ttcancelinprogress": "Cancel the in progress queue to immediately stop the processing of these emails. The emails have already been submitted to us and charged so there is no refund on the emails cancelled.",
    "ttlinktracking": "Show the detailed links, percentage and count of clicks on them.",
    "ttshowlive": "Show the live view of what is happening with this account.",
    "ttexitlive": "Exit the live screen and return to the main activity screen.",
    "ttpurchasecredit": "Purchase credit for your account. You can use direct credit card payment or PayPal.",
    "ttpaybutton": "Confirm and process your request.",
    "ttsubaccounts": "View your subaccounts list. You can view all your subaccounts, filter by account email, add or delete a subaccount to your account.",
    "ttshowhistory": "View your payment history. You can view all your transactions, filter by date range and email your account email address receipts.",
    "ttsendreceipt": "Send receipt to your email address",
    "ttsendinvoice": "Send invoice to your email address",
    "ttgetreceipt": "Download receipt",
    "ttgetinvoice": "Download invoice",
    "ttshowusage": "View your account usage. You can filter by month and view daily jobs sent, total recipients, attachments and cost.",
    "ttshowreputation": "View detailed information about your account's sending reputation. Information about complaints, invalid email addresses, content analysis your account setup and more. Your reputation details can help provide insight into your email delivery.",
    "ttpreferences": "Edit your preferences to enhance the usability of our software.",
    "ttcalculatecost": "Calculate your approximate montly cost of sending mails.",
    "ttsavepreferences": "Save the changes made to your preferences.",
    "ttspamcheckresults": "View detailed information about how your email is being rated by Spam Assassin (Industry Standard Spam Filtering Software). You are able to view the emails that are causing the problems with specific information on each issue and the impact it has on your reputation.",
    "ttreputationhistory": "View information how your reputation has changed",
    "ttviewspamcheckemail": "View the email that has spam issues detected by Spam Assassin",
    "ttbacktoreputationdetails": "Return to your reputation details.",
    "ttshowreferrals": "View your referral setup and details if you have or want to refer other people to our services.",
    "ttshowpayouthistory": "View the history of your payouts we have made to you for referring other people to our services.",
    "ttshowupdateprofile": "Update your profile information. This information can be inserted directly into your emails by using our Merge Fields.",
    "ttshowcustombranding": "Setup Elastic Email with your own branding for your clients.",
    "ttupdateprofilesave": "Save your profile changes.",
    "ttshowchangeemail": "Change your account email address. This address is what we use to correspond with you so make sure you use an email address that you access regularly.",
    "ttchangeemailsave": "Save your account email change. An email will be sent to your new email address with an activation link you must use to successfuly change your account email addresss.",
    "ttshowchangepassword": "Change your account password. Your account password is ONLY used to access the user interface and is not the password you would use to send email through SMTP or our API. For security purposes, even though you are logged in, you must provide your current password to change it to a new one.",
    "ttchangepasswordsave": "Save your account password change.",
    "tteditmonthlylimitsave": "Save this subaccounts monthly free creditsconfirm.",
    "ttshowadvancedoptions": "View advanced options for your account. Various sending, bounce and notification options can be set from here as well as setting up your custom domains and private IP configuration.",
    "ttsaveoptions": "Save any changes you have made to your advanced options.",
    "ttadddomain": "Add a sending domain you wish to validate. A few simple DNS configurations are required in order for your emails to be sent directly from your custom domains.",
    "ttcheckresourcesfordns": "See our <a href='https://help.elasticemail.com/en/articles/2281323-how-to-verify-your-domain' target='_blank'>Resources</a> for more information on DNS configuration.",
    "ttsavedomain": "Add your new custom domain to your account.",
    "ttsetasdefault": "Make this domain your default sending domain. The default sending domain will be used for emails sent through your account that are not validated whereby our system will send via this domains mailer on behalf of the non-validated domain.",
    "ttverifydomain": "Check your DNS configuration for this domain and ensure it is validated. See our Resources for more information if you are having difficulty validating your domain for SPF, DKIM and Tracking.",
    "ttremovedomain": "Delete this domain validation if you no longer require it.",
    "ttvalidatedomainok": "Close this dialog window. It is important that you have correctly setup your SPF, DKIM and Tracking Domain DNS TXT entries so ensure all three of these are validated.",
    "ttshowdiagnostics": "View and create diagnostic tickets that you can use if you are having issues with your email. We will track and log information that can be helpful for diagnosing issues with sending email through our system.",
    "ttnewticket": "Create a new diagnostics ticket that can be used to track issues with your email.",
    "ttsavediagnosticsticket": "Process the diagnostics ticket generation. Use the email address generated for sending emails to diagnose.",
    "ttcanceldiagnosticsticket": "Cancel your diagnostics ticket.",
    "ttchangeapikey": "Change the current API Key to a new randomly generated unique API Key. If you feel your API Key might have been compromised this is a good option to use.",
    "ttchangeapikey1or2": "Change the selected API Key to a new randomly generated unique API Key.",
    "ttdeleteapikey1or2": "Delete the selected API Key if you are no longer require it.",
    "ttshowaddsubaccount": "Add a subaccount to your account. subaccounts can be used to meet various requirements but most commonly can be used to manage multiple clients under one account.",
    "ttloadsubaccount": "Load the subaccount and view all of it's information. To return to the main account simply click your main account email at the top of the screen.",
    "tteditsubaccount": "Edit the subaccount additional settings.",
    "tteditmonthlylimit": "Edit the monthly sending limit for this subaccount.",
    "ttdeletesubaccount": "Delete the subaccount if you no longer require it.",
    "ttaddsubaccountsave": "Process your subaccount creation. An activation email is not sent or required for subaccounts.",
    "tteditsubaccountsave": "Process your subaccount edition.",
    "ttaddip": "Purchase a private IP for your account.",
    "ttaddpool": "Create a group of IP addresses",
    "ttdelpool": "Remove group of IP addresses",
    "ttpurchaseprivateip": "Purchase a private IP",
    "ttexitpurchasecredit": "Exit purchase credit and return to the main account screen.",
    "ttexitpaymenthistory": "Exit payment history and return to the main account screen.",
    "ttexitaccountusage": "Exit account usage and return to the main account screen.",
    "ttexitreputationdetails": "Exit reputation details and return to the main account screen.",
    "ttexitadvancedoptions": "Exit advanced options and return to the main account screen.",
    "ttnotificationque": "Preview of notification queue.",
    "ttexitdiagnostics": "Exit diagnostics and return to the main account screen.",
    "ttexitsubaccounts": "Exit subaccounts list and return to the main account screen.",
    "ttchangesmtp": "Change entries for SMTP addresses.",
    "ttsendinvity": "Invite new user",
    "repairsuccess": "Template was successfully repaired.",
    "repairtemplate": "Repair template",
    "descpremium": "Purchase a professionally designed template.",
    "confirmbuy": "Are you sure you want to purchase the template <b>'{{name}}'</b>?<br/> <b>{{price}} {{currency_code}}</b> will be deducted from your account credit balance",
    "paynow": "Pay now",
    "predesigned": "Pre-designed",
    "desctemplategallery": "Choose from a growing list of pre-designed free templates.",
    "premium": "Premium",
    "buytemplate": "Purchase template",
    "notrackingresult": "<p class='eon'>You do not have any Tracking Links yet.<p><p class='eoff'>To collect this statistic you should enable <a href='#/settings'><span>\"Track clicks by link\"</span></a> in Advanced Options</p>",
    "notrackingactivateit": "Would you like to track detailed statistics for each link in your emails?",
    "nosearchresults": "<h4 class='margin-search-text-h4'>No search results were found.</h4><p class='margin-top-search-text-p'>Please adjust your search criteria and search again.</p>",
    "notemplates": "<h4 class='margin-search-text-h4'>You do not have any templates created yet.</h4><p class='margin-top-search-text-p'>Click the 'Create template' button on the right to create your first template.</p>",
    "notemplatedrafts": "<h4 class='margin-search-text-h4'>You do not have any draft templates yet.</h4><p class='margin-top-search-text-p'>Draft template is created and used by the campaigns.</p>",
    "notemplateparents": " gallery is empty.",
    "notemplatesglobal": "<h4>Currently gallery is empty.<br/>&nbsp;</h4>",
    "noforms": "<h4 class='margin-search-text-h4'>You do not have any form created yet.</h4><p class='margin-top-search-text-p'>Click the Create button on the right to create your first form.</p></span>",
    "nopurchased": "You do not have any purchased templates yet.<br/>Select the Category on the right to find template to purchase",
    "nocontacts": "You do not have any contacts created yet.<br/>Click the Options button and choose Add Contact on the left to add your first contact(s)",
    "nocontactsforcriteria": "Contacts not found for the specified criteria",
    "nocampaigns": "You do not have any campaigns created yet.<br/>Click the <strong>'Create campaign'</strong> button on the right to create your first campaign.",
    "nocontactstoupload": "No contacts were found to upload",
    "nocontactsforlogs": "No contact logs have been found",
    "checkall": "Check All",
    "showall": "Show All",
    "nosubaccounts": "<i class='fa fa-users'></i><span>There are no subaccounts added to your account yet.<br/><small>subaccount can be used to manage multiplie clients under one account.</small></span>",
    "nosurveys": "Take advantage of the surveys tool to collect feedback from your contacts.<br/>To get started hit <span class=\"enoresult__button\">Create survey</span> button.",
    "nosurveyresponses": "There are no responses in your survey yet.",
    "nospamcheckresults": "<span>No results yet.</span>",
    "nopayments": "<span>For specified range of time not found payments</span>",
    "errorimport": "We're very sorry but an error occurred while importing the template, please contact the support team to fix this issue",
    "loadinginprogress": "Loading in progress!",
    "buytemplates": "Purchase",
    "purchased": "Purchased",
    "cancelauthorizations": "Cancel authorizations",
    "paymentamount": "Purchase credit",
    "purchasecredit": "Purchase credit",
    "billinginformation": "Billing information",
    "cardholderinformation": "Card holder information",
    "saveforprofile": "Save as profile address",
    "copytoprofile": "Copy this information to profile",
    "copytoprofiledesc": "Card holder information will become profile information and will be used in email footer.",
    "changeprofileinfo": "Change profile information",
    "totalpaymentamount": "Total payment amount",
    "paypal": "PayPal",
    "creditcard": "Credit card",
    "paybypaypal": "Pay by PayPal",
    "paybycreditcard": "Pay by Credit Card",
    "onclickbuy": "One Click Buy",
    "changecreditcard": "Change the credit card",
    "ccinusetitle": "Credit Card already in use",
    "ccinusetext": "You already have Credit Card attached to your account. Would you like to change it to a new one?",
    "autorechargemyaccountwith": "Auto-recharge my account with",
    "whenmycreditgetsbelow": "when my credits get below",
    "ccinuseconfirm": "Yes, change Card",
    "ccinusecancel": "Keep the old one",
    "mytemplates": "My templates",
    "grantpermission": "Grant permission",
    "selectcategory": "Select a category",
    "chooseorder": "Choose order",
    "sales": "Sales",
    "loves": "Loves",
    "price": "Price",
    "orderby": "Order by",
    "buynow": "Buy now",
    "spamtest": "Spam test",
    "ttspamtest": "Email scanned by every major spam filter",
    "filter": "Filter",
    "withascoreof": "With a score of",
    "vat": "Vat",
    "downloadzip": "Download ZIP",
    "unknowndesc": "Unique error that has not been categorized by our system",
    "ignoredesc": "Delivery was not attempted",
    "spamdesc": "Considered spam by the recipient or their email service provider",
    "blacklisteddesc": "IP is currently having a reputation issue",
    "nomailboxdesc": "Email address does not exist",
    "greylisteddesc": "Temporarily rejected.  Retrying will likely be accepted",
    "throttleddesc": "Too many emails for the same domain were detected",
    "timeoutdesc": "A timeout occured trying to send this email",
    "connectionproblemdesc": "A connection problem occured trying to send this email",
    "spfproblemdesc": "The domain that sent this email does not have SPF validated properly",
    "accountproblemdesc": "Recipient account problem like over quota or disabled",
    "dnsproblemdesc": "There is a problem with the DNS of the recipients domain",
    "whitelistingproblemdesc": "Recipient's email service provider requires a white listing of the IP sending the email",
    "codeerrordesc": "An unexpected error has occurred",
    "unexpectederror": "Unexpected error",
    "copytoclipboard": "copy to clipboard",
    "copied": "Copied",
    "copiedlink": "Landing Page link copied to clipboard",
    "templatelink": "Template link:",
    "unexpectederrormodal": "Oops, sorry but unexpected error has occured. Something went wrong on our end. Please try again. If the problem persists please contact support and provide the following code:",
    "manualcanceldesc": "Cancelled by an administrator or you cancelled the email yourself from the Activity screen",
    "connectionterminateddesc": "Status is unknown due the connection being terminated by the recipients server",
    "notdelivereddesc": "Delivery was not attempted",
    "blockedrecipientdesc": "Recipient is on your blocked contacts list",
    "unsubscribedesc": "Recipient is on your unsubscribed list",
    "spamcheckdesc": "Email failed our spam check",
    "contentfilterdesc": "Email has suspicious content",
    "globalblockedrecipientdesc": "Recipient is on our global blocked contacts list",
    "usercancelleddesc": "User cancelled the in progress email",
    "notenoughcreditdesc": "Not enough credit",
    "notenoughcreditforattachments": "Not enough credit for attachments",
    "throttledfor48hours": "Not able to deliver within 48 hours",
    "bouncedetails": "Bounce details",
    "paymenturl": "Payment URL",
    "changepaymenturl": "Change payment URL",
    "setpaymenturl": "Set your payment URL",
    "setsmtpservers": "Set branded SMTP servers",
    "smtpserver": "SMTP Server",
    "newfieldsuccess": "New custom field: <b>\"{{name}}\"</b>, was added successfully.",
    "removecontactfieldtitle": "Warning: Remove custom field",
    "removecontactfielddesc": "Are you sure you want to remove field:<br/> <span style='font-weight:600;'>{{name}}</span>?<br/><br/><b class='text-danger'>All contact data associated with this field will be deleted,<br/><u>this operation can not be undone</u>.</b>",
    "errornewfieldtypeempty": "To create a new field, you need to select the field type",
    "errornewfieldnameempty": "Field name cannot be empty",
    "errornewfieldnamestartnumber": "Field name cannot start with numerical value",
    "errornewfieldduplicate": "The field with this name already exists.",
    "errornewfieldlength": "The field of type text requires the give a length greater than 0.",
    "errornewfieldwhitespaces": "Field name cannot contain empty spaces",
    "errornewfieldnovalid": "Field name cannot contain special characters",
    "errorformnovalid": "No valid form",
    "duplicatefieldname": "Custom field name already exists.",
    "nonalphabeticalfield": "Invalid field name.",
    "zerolengthfield": "No field length.",
    "emptyfield": "No field name.",
    "duplicatefield": "Duplicate field name.",
    "nonalphabeticalfielddesc": "You may use only letters, numbers or underscore in field name. First character of each field must start with a letter or underscore.",
    "zerolengthfielddesc": "The field of type text requires the give a length greater than 0.",
    "emptyfielddesc": "Field name cannot be empty.",
    "duplicatefielddesc": "This Custom Field name already exists in the system. Please choose a different one.",
    "searchcustomfield": "Search custom field",
    "customfieldadded": "Custom field added",
    "customfielddeleted": "Custom field deleted",
    "cannotremovecustomfield": "Cannot remove custom field",
    "cannotaddcustomfield": "Cannot add custom field",
    "customfieldinsegment": "The custom field cannot be removed because it’s being used by following segment(s):",
    "pleaseupdatesegments": "Please edit the selected segments and remove the field from rules in order to delete this custom field.",
    "itemsperpage": "Items per page",
    "legalpleasefillrequiredfields": "Please fill in all required fields",
    "legalaccounts": "According to our '<a href='https://elasticemail.com/support/usage-policies/acceptable-use-policy/' target='_blank'>Acceptable Use Policy</a>' you must accurately identify yourself in your email, include your valid physical postal address and email address (or telephone number) by which you can be contacted. The information must remain valid for at least 60 days after your email is sent.",
    "legalsubaccounts": "You should accurately identify yourself in your email, include your valid physical postal address and email address (or telephone number) by which you can be contacted. The information must remain valid for at least 60 days after your email is sent.",
    "validationdomheader": "Domain validation",
    "validationdomdescription1": "Elastic Email takes a strong stance against spam and this step is for your protection and for your best possible delivery. Without your domain properly validated emails are being delivered via <i>mailer@elasticemail.com</i> on behalf of your from email address.",
    "validationdomdescription2": "Configuring a tracking domain is equally as important to ensure the links in your emails are tracked properly. Without these settings configured, your emails and links are more vulnerable for being blocked.",
    "validationdomcallout": "Validating your domain(s) will help with your overall delivery as well as making your recipients feel confident the mail is from you.",
    "helpspfheader1": "What is a SPF record?",
    "helpspfdescription1": "SPF stands for \"Sender Policy Framework\".  A SPF record is in place to identify which mail servers are authorized to send mail for a given domain. It is used to prevent spammers from sending mail with fraudulent From addresses at that domain. ",
    "helpspfcallout": "Though many DNS editors allow for the creation of an SPF record, it is recommended that the SPF record is entered as a TXT record.",
    "helpspfheader2": "How to create a SPF record?",
    "helpspfdescription2": "An SPF record is created by adding a TXT record to your DNS configuration. When you are creating the SPF (TXT) record you will likely see a form with at least two fields, they are: \"Host\" or \"Name\" and \"Value\"",
    "helpspfdescription3": "(Yes, you put the @ symbol under the host or name category. @  means that the record is pointed at your own domain.  Some editors will require the \"@\" symbol, some will require you to enter your own domain, and others will not let you enter anything. Every DNS Editor is different - you may need to contact your hosting provider for information on how to enter this record correctly).",
    "helpspftips1": "Check to see if there are any other SPF records in your domain's DNS. There can only be one SPF record per domain, so if there is an existing record just add include:_spf.elasticemail.com to that record.",
    "helpspftips2": "For example, if your domain already has the record:",
    "helpspftips3": "....then you would just add:",
    "helpspftips4": "The final record would look like this:",
    "helpdkimheader1": "What is a DKIM record?",
    "helpdkimheader2": "How to create a DKIM record?",
    "helpdkimdescription1": "DKIM stands for <i>\"Domain Keys Identified Mail\"</i>. They allow receiving servers to confirm that mail coming from a domain is authorized by the domain's administrators.",
    "helpdkimdescription2": "Create a TXT record and enter:",
    "helpdkimdescription3": " DNS settings can have as many DKIM records as needed.<br /><br />In some DNS settings the Host/Name field may require you to enter<span class=\"ehighlightgreen\"> api._domainkey.yourdomain.com </span>, replacing your domain with your actual domain.",
    "helptrackingheader1": "What is Tracking?",
    "helptrackingdescription1": "Elastic Email \"tracks\" <b>opens, clicks, unsubscribes,</b> etc.  To do that we must rewrite links and use web pages.  Setting up a <i>\"tracking domain\"</i> brands these rewritten links and pages with your own domain.",
    "helptrackingheader2": "How to create a tracking record?",
    "helptrackingdescription2": "Create a <span class=\"etooltip\" data-title=\"A Canonical Name record (abbreviated as CNAME record) is a type of resource record in the Domain Name System (DNS) used to specify that a domain name is an alias for another domain, which is the 'canonical' domain.\">CNAME</span> record and enter:",
    "helptrackinginfo1": "Elastic Email system will look for either 'tracking' or 'email' automatically.",
    "helptrackingcallout": "If another CNAME is used please contact support to get it validated.",
    "helpmxheader": "What is a MX record?",
    "helpmxcallout": "For most users, you will not be adding or changing any MX records in your domain's DNS.",
    "helpmxdescription1": "For optimal delivery you should only send from domains which have a valid MX (mail server) configured. Some destination domains will reject mail that does not have a valid MX record setup.",
    "helpmxdescription2": "The only reason why you would change or add a MX record for use with Elastic Email is if you are using Inbound Email Notifications which are webhooks that are part of our HTTP API. Otherwise, this will give you a green check mark if you have an existing MX record that is used for directing mail to your own mail server.",
    "helpdmarcheader1": "What is a DMARC record?",
    "helpdmarcdescription1": "<i>Domain-based Message Authentication, Reporting & Conformance</i> is an email authentication protocol that is built on top of SPF and DKIM protocols.",
    "helpdmarccallout1": "SPF and DKIM are prerequisites of DMARC and must be in place before setting up a DMARC policy.",
    "helpdmarcdescription2": "A DMARC policy allows a sender to indicate that their emails are signed by SPF and DKIM and tells a receiver what to do if neither of those authentication methods passes – such as junk or bounce the email. DMARC removes the guesswork from the receiver’s handling of these failed emails, limiting or eliminating the user’s exposure to potentially fraudulent & harmful emails. DMARC also provides a way for the email receiver to report back to the sender about emails that pass and/or fail DMARC evaluation.",
    "helpdmarcdescription3": "Note there is no specific configuration needed in Elastic Email besides ensuring that your SPF and DKIM are both valid.  Further, a DMARC policy on your domain(s) will affect all of your email sending from that domain (not just the mail you are sending through Elastic Email) so you need to ensure you are using SPF and DKIM for all your email delivery.  For more information please <a href=\"https://dmarc.org/\" target=\"_blank\">click here</a>.",
    "helpdmarcheader2": "How to create a DMARC record?",
    "helpdmarccallout2": "We strongly recommend using our <a href='https://elasticemail.com/dmarc/' target='blank'>DMARC Generator</a> - it will help you create DMARC record suited for your domain.",
    "helpdmarcinfo1": "The following are example DMARC TXT entries to setup on your domain(s) DNS.",
    "helpdmarcstep1": "Setup your DMARC policy to simply notify you of mail that is not passing SPF and DKIM",
    "helpdmarcstep2": "When you are no longer receiving negative reports, change your DMARC policy to quarantine which will not necessarily bounce email, but indicate to the recipient server they should consider quarantining it (junk or spam folder).",
    "helpdmarcstep3": "When you are satisfied that you are validating all the email from your domain(s) with SPF and DKIM change the policy to reject which will bounce the emails that do not pass SPF and DKIM validation.",
    "helpdmarccallout3": "Please <a href='https://dmarc.org/overview/' target='_blank'>click here</a> to view a list of the most popular tags available for your DMARC policy as above are only examples.",
    "howtouseemojiinsubjectlines": "How to use emoji in subject lines?",
    "howtouseemojiinsubjectlinesdesc": "Emoji characters are widely supported across multiple email clients, particularly on mobile devices. However, emojis do not render everywhere and it is better to keep that in mind. <br/><br/>To be entirely sure your message is well understood avoid replacing words with emojis, like:<b>'We 💛 our 👥'</b><br/><br/>It is better to enhance your messages with emojis, like: <b>'We love our users 💛👥'</b>",
    "sendtimeoptimization": "Send-time optimization: ",
    "segmentnamerestriction": "A segment name cannot contain an apostrophe",
    "listnamerestriction": "A list name cannot contain an apostrophe",
    "sendtimeoptimizationinfo": "<b>1.</b> Select this option to disable send time-optimization.<br/><br/><b>2.</b> Select this option to schedule your emails beginning with the most engaged contacts first. This may improve the delivery of your campaign.<br/><br/><b>3.</b> Select this option to deliver each of your emails at the most optimal time, based on a contact's open time rate. Some (or even all) of the mail may not be sent for up to 24 hours.",
    "themostengagedcontactsfirst": "Send to the most engaged contacts first to optimize delivery",
    "eachcontactsoptimalopen": "Send this campaign to each contacts optimal open time over the next 24 hours",
    "donotleaveblank": "Please do not leave blank",
    "eeform_error_email": "Invalid email address",
    "eeform_error_password": "Password should have minimum length of 6 characters.",
    "eeform_error_confirm": "Passwords do not match.",
    "eeform_email_confirm": "Email do not match.",
    "backslashesinname": "Please note that characters like / or \\ are not allowed.",
    "crc_startinfo": "Please provide details about your email sending habits so we can process your re-activation request.",
    "crc_formrestored": "Entries from your previous session have been restored",
    "crc_howcollectcontacts": "Who are you sending email to?",
    "crc_purchased": "A list or database of email addresses I purchased.",
    "crc_thirdpartdb": "A third party database of email addresses I acquired or have been given access to.",
    "crc_doubleoptform": "People who have subscribed to my newsletter using a double opt in subscription form.",
    "crc_publicwebsite": "Email addresses I have gathered from public websites.",
    "crc_tradeshows": "People who have given me their email at trade shows or other public events.",
    "crc_shopclients": "People who have purchased something from my online business, or signed up to use my application or forum.",
    "crc_singleoptform": "People who have subscribed to my newsletter or notifications using a single opt-in form.",
    "crc_howoldcontactslist": "When was the last time you sent to these contacts?",
    "crc_monthsago": "months ago",
    "crc_monthsago_or_more": "months ago or more",
    "crc_oftenlistmainteance": "How frequently do you email these contacts?",
    "crc_daily": "Daily",
    "crc_weekly": "Weekly",
    "crc_monthly": "Monthly",
    "crc_rarely": "Rarely",
    "crc_neveremailed": "I have never emailed them",
    "crc_whatemailssend": "What is the email you are sending?",
    "crc_whatsending": "What are you sending?",
    "crc_unsoliticed": "None of the above.",
    "crc_newsletters": "A daily, weekly or monthly newsletter for my business, or personal blog.",
    "crc_affiliate": "Affiliate marketing programs.",
    "crc_adproduct": "Advertisements for my products or services.",
    "crc_adcompany": "Advertisements on behalf of other companies.",
    "crc_trans": "Transactional emails generated by my application (order confirmations, signup verifications, system notifications etc.).",
    "crc_emailcontentdesc": "Email content",
    "crc_emailcontentdescinfo": "Please describe in detail, your company and your email project. The information you provide will help us with reactivation of your account.",
    "crc_recipientsagree": "Have all your contacts explicitly requested to receive email from you?",
    "subrequiresemailcredits": "Subaccount requires email credits",
    "desc_subrequiresemailcredits": "Enable to set a monthly refill and secure a continuous communication with your contacts.",
    "noresult_export": "Nothing has been exported yet.",
    "noresult_export_desc": "This screen will store all exported files eg. logs, contacts, trackings, campaigns and surveys. They will be sitting here ready for you to download.",
    "noresult_users": "Invite your friends or employees to join you in managing the account.",
    "noresult_referrals": "Join Elastic Email’s referrals program and start making more money.",
    "noresult_referrals_desc": "Refer customers to Elastic Email and earn 20% of anything they spend for up to a year.",
    "noresult_subaccounts": "If you manage several of your own clients, subaccounts offer an easy and effective way to separate and manage mail.",
    "noresult_subaccounts_desc": "You may also wish to separate mail by sender domain, or contact lists.",
    "status_desc_active": "Those addresses are good to send",
    "status_desc_inactive": "Addresses were uploaded as Inactive because they are role addresses or there is high risk they are spam trap addresses",
    "status_desc_invalid": "Addresses were uploaded as Invalid, because they are not assigned to existing Mailbox or are hosted on domain that do not accept emails",
    "status_desc_bounced": "Addresses were uploaded as Invalid, because they are not assigned to existing Mailbox or are hosted on domain that do not accept emails",
    "status_desc_unsubscribed": "Addresses were uploaded with Unsubscribe status, because in past they Unsubscribed from your list or you uploaded them as Unsubscribed",
    "status_desc_transactional": "Addresses were uploaded with Transactional status, because in past recipient opted to receive only Transactional emails from you or you uploaded them as Transactional",
    "status_desc_stale": "Addresses were uploaded as Stale, because those email addresses are abandoned, they do not open any emails from you or you uploaded them with status Stale",
    "status_desc_abused": "Addresses were uploaded with Complaint status, because in past they marked your email as spam or you uploaded them as Complaint",
    "status_desc_complaint": "Addresses were uploaded with Complaint status, because in past they marked your email as spam or you uploaded them as Complaint",
    "status_desc_syntax": "Addresses that had incorrect syntax, for example double @ character. Those addresses were not uploaded.",
    "status_desc_duplicated": "Addresses that were duplicated in your CSV file. Those addresses were not uploaded.",
    "uploadsummarydesc": "Check how many contacts has beed uploaded and how it affected your limit of contacts on plan. ",
    "contactscounting": "how are contacts counted.",
    "activecount": "Active",
    "activedesc": "Those addresses are good to send",
    "inactivecount": "Inactive",
    "inactivedesc": "Addresses were uploaded as Inactive because they are role addresses or there is high risk they are spam trap addresses",
    "invalidcount": "Invalid",
    "invaliddesc": "Addresses were uploaded as Invalid, because they are not assigned to existing Mailbox or are hosted on domain that do not accept emails",
    "bouncedcount": "Bounced",
    "bounceddesc": "Addresses were uploaded as Invalid, because they are not assigned to existing Mailbox or are hosted on domain that do not accept emails",
    "unsubscribecount": "Unsubscribed",
    "unsubscribeddesc": "Addresses were uploaded with Unsubscribe status, because in past they Unsubscribed from your list or you uploaded them as Unsubscribed",
    "transactionalcount": "Transactional",
    "transactionaldesc": "Addresses were uploaded with Transactional status, because in past recipient opted to receive only Transactional emails from you or you uploaded them as Transactional",
    "stalecount": "Stale",
    "staledesc": "Addresses were uploaded as Stale, because those email addresses are abandoned, they do not open any emails from you or you uploaded them with status Stale",
    "abusedcount": "Abuse",
    "abuseddesc": "Addresses were uploaded with Complaint status, because in past they marked your email as spam or you uploaded them as Complaint",
    "complaintcount": "Complaint",
    "complaintdesc": "Addresses were uploaded with Complaint status, because in past they marked your email as spam or you uploaded them as Complaint",
    "abusecount": "Complaint",
    "abusecountdesc": "Addresses were uploaded with Complaint status, because in past they marked your email as spam or you uploaded them as Complaint",
    "filedoesnotcontainemail": "The file does not contain \"email\" column and cannot be uploaded.",
    "findoutmoreaboutuploadingfiles": "Find out more about how you can prepare your file ",
    "notuploadedsyntaxcount": "Syntax error",
    "syntax errordesc": "Addresses that had incorrect syntax, for example double @ character. Those addresses were not uploaded.",
    "notuploadedduplicatedcount": "Duplicated",
    "duplicateddesc": "Addresses that were duplicated in your CSV file. Those addresses were not uploaded.",
    "languages": "Languages",
    "privacypolicy": "Privacy policy",
    "gdpr_info_modal_title": "GDPR Compliance",
    "gdpr_info_modal_content": "Hello %firstname%,<br /><br /> As the Elastic Email team, we do our best to secure the data and privacy of our users. We believe in open and transparent communication where users' needs are put first. <br /><br /> Due to GDPR and with the security of users in the EU in mind, we would like to inform you that we have updated our Terms of Use, Privacy Policy, Acceptable Use Policy, Anti-Spam Policy as well as our Security information on our website.<br /><br /> By continuing to use Elastic Email from this day forward, please know that you are agreeing to the updated terms and policies. <br /><br /> Please learn more <a id='gdprgoto' href='%infoUrl%' target='_blank' style='font-weight:bold; text-decoration: underline; cursor:pointer;'>here</a> and if you have questions, please contact us at <br /> <a href='mailto:support@elasticemail.com'>support@elasticemail.com</a><br /><br />",
    "fillprofilemodalheader": "Hi there, welcome to Elastic Email",
    "fillprofilemodalcontent_pt1": "We are super excited to have you on board!",
    "fillprofilemodalcontent_pt2": "Feel free to look around. You are currently able to create templates, upload your contacts, update your settings and more. In order to send your first email be sure to fully activate your account by clicking on the activation link in the email that was sent to your inbox.",
    "fillprofilemodalcontent_pt3": "If you didn't receive this email, please check your spam folder or contact us from the messenger at the bottom right of your account.",
    "template_load_error": "Sorry, current template cannot be shown in drag'n'drop editor, please use HTML editor instead.",
    "template_load_error_title": "Oops, sorry but unexpected error has occured",
    "firstrunmodalheader": "Elastic Email is a powerful and flexible platform, help us customize your experience to fit your needs by answering 2 quick questions:",
    "firstrunmarketing": "I want to design and send newsletters to my subscribers, create marketing automations and use Elastic Emails tools to grow and manage my audience.",
    "firstruntransactional": "I want to connect my application to the Elastic Email SMTP service or HTTP API.",
    "firstrunnewbie": "This is totally new for me.",
    "firstrunmigrating": "I am migrating from another email provider.",
    "total_contacts_tooltip": "Total number of contacts an email was sent during the billing period.",
    "geography": "Geography",
    "and": "and",
    "devicedetails": "Device details",
    "entername": "Enter name",
    "graphdatamissing": "Graph data is missing",
    "triggerdesc_clickslink": "Automation starts when contact clicks the link.",
    "triggerdesc_clicksanylink": "Automation starts when contact clicks any link.",
    "triggerdesc_joinslist": "Automation starts when contacts joins the \"{{name}}\" list.",
    "triggerdesc_opensanyemail": "The automation starts when contact opens an email from any campaign.",
    "triggerdesc_opensemail": "The automation starts when contact opens an email \"{{emailName}}\"",
    "triggerdesc_opensemailany": "The automation starts when contact opens an email",
    "triggerdesc_anotherautomation": "Automation starts when triggered by another automation.",
    "triggerdesc_manually": "Automation starts when API request is sent.",
    "triggerdesc_statuschanged": "Automation starts when contact status is changed to \"{{status}}\"",
    "triggerdesc_iferror": "{{category}} is unknown",
    "triggerdesc_default": "Automation trigger",
    "sendspecificemail": "Send \"{{emailName}}\"",
    "sendemail": "Send email",
    "viasmtp": "via SMTP",
    "viahttp": "via HTTP",
    "sendsms": "Send SMS",
    "actiondesc_sendsms": "Send SMS text message to a contact.",
    "actiondesc_joinslist": "Subscribe contact to list \"{{name}}\"",
    "actiondesc_sendwebhook": "Send webhook notification.",
    "actiondesc_removeslist": "Remove contact from list \"{{name}}\"",
    "actiondesc_anotherautomation": "Start automation \"{{name}}\"",
    "actiondesc_iferror": "{{category}} is unknown",
    "actiondesc_default": "Select action",
    "conditiondesc_clickslink": "Did contact click the link \"{{name}}\"?",
    "conditiondesc_clicksanylink": "Did contact click any link?",
    "conditiondesc_opensemail": "Did contact open email \"{{name}}\"?",
    "conditiondesc_joinslist": "Is contact subscribed to list \"{{name}}\"?",
    "conditiondesc_iferror": "{{category}} is unknown",
    "conditiondesc_default": "Condition",
    "delaydesc": "Wait for {{time}} {{unit}}",
    "delaydesc_default": "Wait",
    "unit_minutes": "minute(s)",
    "unit_hours": "hour(s)",
    "unit_days": "day(s)",
    "unit_weeks": "week(s)",
    "resume": "Resume",
    "pause&edit": "Pause & Edit",
    "automationactivity": "Automation activity",
    "automationreactivated": "Automation reactivated",
    "noneactivity": "This automation workflow does not have any sent emails yet.",
    "viewfullreport": "View full report",
    "submittedemails": "Submitted emails",
    "automationwillstartwhen": "Automation will start when",
    "selectspecificlink": "select a specific link",
    "selectlist": "Select a list",
    "selectonetemplate": "Select one of your template",
    "selectonelists": "Select one of your contact lists",
    "selectautomation": "Select automation",
    "selectemails": "Select one of your emails",
    "selectcampaigns": "Select one of your campaigns",
    "selectlink": "Select link",
    "emptyautomation": "You dont have any automations",
    "emptyemails": "You dont have any emails",
    "emptytemplates": "You dont have any templates",
    "emptylists": "You dont have any lists",
    "emptylinks": "Selected template doesn't have any links",
    "emptylinkstemplate": "Selected template does not contain links",
    "emptylinkscampaign": "Selected campaign does not contain links",
    "campaignhasnotemplates": "Selected campaign does not contain template(s)",
    "emptycampaigns": "You dont have any campaigns",
    "email": "Email",
    "selectemail": "Select email",
    "conditioncheckscontact": "Condition checks if contact is subscribed to",
    "setduration": "Set condition duration",
    "cond_emailinfo": "Conditions are checked immediately. Set condition duration to give subscribers time for receiving and opening the email.",
    "cond_actioninfo": "Conditions are checked immediately.",
    "wrongpreviousstep": "Previous step is not properly configured",
    "conditionbased": "Select condition based on",
    "pauseautomatedcampaign": "Pause automated campaign \"{{name}}\"?",
    "congratulations": "Woo hoo!!! Congratulations",
    "publishautomation_info": " Your automation is published. Return to the automation to view results.",
    "preparefortakeoff": "Prepare for takeoff",
    "publishautomation": "You're about to publish this automation",
    "resumeautomation": "Resume automated campaign \"{{name}}\"?",
    "copyerror": "Copy error to clipboard",
    "selectrecipient": "Select recipient",
    "selectcustomfield": "Select custom field",
    "selectorcreatefield": "Select or create new field",
    "nomergefields": "You dont have any merge fields",
    "typesmscontent": "Type your SMS content",
    "noactioncategory": "No action categories",
    "conditionchecksif": "Condition checks if",
    "conditiondesc_opensemailany": "Did contact open an email?",
    "contactopenemail": "Contact opens an email",
    "contactclickanylink": "Contact clicks any link",
    "contactclickspecificlink": "Contact clicks a specific link",
    "contactjoinslist": "Contact joins a list",
    "contactopensemail": "Contact opens an email",
    "contactclickslink": "Contact clicks a link",
    "manuallytriggered": "Manually triggered",
    "triggeredbyanotherautomation": "Triggered by the action of another automation",
    "whenapiissent": "When API request is sent",
    "errorinfo": "Oops, something went wrong!",
    "returntoautomations": "Return to Automations",
    "deletestep": "Delete step",
    "pauseautomation": "Pause automation",
    "resumeautomationtitle": "Resume automation",
    "deletestepconfirm": "Please confirm that you want to delete this step",
    "deletestepchildrenconfirm": "Please confirm that you want to delete this step. This action will also delete any following steps.",
    "errordeleteactive": "You're trying to delete step from Active automation. Please pause it first, and then delete step.",
    "clicktheplus": "Click the plus above to add the first step to your automation workflow.",
    "action": "Action",
    "contactsinstep": "Contacts in step",
    "automationresumed": "Automation resumed",
    "automationcreated": "Automation created",
    "automationupdated": "Automation updated",
    "draftsuccessfullysaved": "Draft successfully saved",
    "draftsuccessfullyupdated": "Draft successfully updated",
    "automationpaused": "Automation paused",
    "sendrepliestodiffaddress": "Send replies to a different address",
    "replyto": "Reply to",
    "listname": "List name",
    "enterlistname": "Enter list name",
    "enablesubscriptionmanagement": "Enable subscription management",
    "frommail": "From email",
    "allowtochooselists": "Allow subscribers to choose which of your lists they wish to stay subscribed to.",
    "delay": "Delay",
    "wait": "Wait",
    "condition": "Condition",
    "workflowincomplete": "Workflow incomplete",
    "selecttrigger": "Select trigger",
    "emailengagement": "Email engagement",
    "stepnotconfigured": "This step is not configured",
    "sendtextmessage": "Send text message",
    "addtolist": "Add to a list",
    "removefromlist": "Remove from a list",
    "manuallytriggernewautomation": "Manually trigger new automation",
    "entertextmessage": "Enter text message",
    "incomplete": "incomplete",
    "step": "step",
    "pluralword": "s",
    "sendcampaingconfim": "Your campaign is ready to go, please confirm.",
    "selectcondition": "Select condition",
    "emailactivity": "Email activity",
    "contactliststatus": "Contact list status",
    "selectrule": "select rule",
    "wasopened": "was opened",
    "hadanylinkclicked": "had any link clicked",
    "hadspecificlinkclicked": "had a specific link clicked",
    "contactonlist": "Contact is on list",
    "movestepstopath": "Move steps to path",
    "exports": "Exports",
    "trackinghistory": "Tracking history",
    "iconset": "I consent",
    "signupmarketingconsent": "I want to receive emails about new products, best practices, or special offers.",
    "signupmarketingconsenttooltip": "You can unsubscribe anytime.",
    "questionmarkimg": "https://elasticemail.com/wp-content/themes/elasticemail/img/assets/info-circle-dark.svg",
    "accountdeletescreentitle":"Your account is going to be deleted",
    "accountdeletescreeninfo": "It's sad to see you go! Your account will be permanently deleted after 30 days.",
    "accountdeletescreeninfo_2": "If you ever change your mind, you are always welcome to start using our service again. In case of any questions, please contact our support team.",
    "deleteaccount": "Delete Account",
    "deleteaccount_desc": "The account will no longer be available, and all data in the account will be permanently deleted.",
    "deleteaccsurveytitle": "We’re sad to see you go",
    "whydeleteaccount": "Why do you want to delete your account? You can choose up to 3 reasons",
    "deleteaccountnote": "Note that deleting the account will remove all your information and data. But before you leave please let us know what we can improve.",
    "deliveryissues": "Delivery Issues",
    "noteasytouse": "Platform is not easy to use",
    "supportbelowexpectations": "Customer Support was below my expectations",
    "pricenotsatisfying": "Not satisfied with the price",
    "notoolsintegration": "Missing some of the integrations",
    "notusinganymore": "I no longer use this platform",
    "fortest": "I only wanted to test it",
    "projectended": "My project has ended",
    "bettercompetitortools": "Elastic Email Competitors have better tools",
    "notinterested": "I am not interested in Email Service Provider at this time",
    "choosendifferentprovider": "My customer have chosen different solution/provider",
    "whatwecandotoimprovement": "What can we do to improve?",
    "enteryourpassword": "Please enter your current account password to confirm your identity.",
    "yourpassword": "Your password",
    "missingintegrationslist": "Could you list the integrations?",
    "couldyoutellwhy": "Could you tell us why?",
    "deleteaccountconfirm": "Are you sure? This action cannot be undone.",
    "deleteaccountwithsubaccounts": "Sub-Accounts currently Active. Deleting your main Account will delete all your Sub-Accounts. Would you like to continue?",
    "accesstoemaildeliveryengine": "Access to Email Delivery Engine",
    "landingpageeditor": "Landing Page Editor",
    "emailautomations": "Email Automations",
    "emailautomation": "Email Automation",
    "unlimitedcustomfields": "Unlimited Custom Fields",
    "paymentssupport": "Support",
    "access": "access",
    "emaildeliveryengine": "Email Delivery Engine",
    "customer": "Customer",
    "paymentwebhooks": "Webhooks",
    "loginconnectionproblem": "There is a problem connecting to the server, please refresh and try again.",
    "enable2fa": "Use the best way to secure your account.",
    "enable2falink": "Enable Two-Factor Authentication.",
    "activationemailhasbeensent": "An activation email has been sent to you. Please check all of your inbox folders. For any help with your account, ",
    "usingprivateip": "Using private IP addresses gives you complete control of your IP's external reputation. ",
    "lmore": "Learn more.",
    "contactusdot": "contact us.",
    "uploadlist": "Upload contacts list",
    "getevenmoreengaged": "Get even more engaged with your audience with personalized email flow. ",
    "createautomationdot": "Create automation.",
    "improvedeliverybyveryfying": "Improve your email delivery rates by verifying your emails are valid before they are sent.",
    "upgradefromtrial": "You are currently on trial plan. To get access, please Upgrade your plan.",
    "nameza": "Name Z-A",
    "nameaz": "Name A-Z",
    "useralreadyactivated": "User already activated.",
    "firstmodified": "First modified",
    "lastmodified": "Last modified",
    "turnonhistorytracking": "Turn on the Track History option for any segment or list on the previous tabs to start tracking the growth of individual lists and segments over time. ",
    "tolearnmorevisitour": " To learn more visit our",
    "quickguide": "quick guide",
    "disableStoreContact": "Store contacts delivered through the API",
    "disableStoreContactinfodesc": "Storing the contacts builds their list, provides more metrics and gives more options for delivery optimization engine.  Turn this option off if you prefer to maintain this information exclusively on your own systems.",
    "disableStoreContactdesc": "This option will cause contacts to be stored when an email is submitted to the API.",
    "useDefaultDomain": "Use default bounce domain",
    "useCustomDomain": "Use custom bounce domain",
    "turnOffBounceDomain": "Turn off bounce domain",
    "validatethroughMX": "Expand to validate through MX",
    "nolistsforcontact": "This contact does not yet belong to any list.",
    "nosegmentsforcontact": "This contact does not yet belong to any segment.",
    "mobiletemplateeditingtoolsnotavailable": "Note: Template editing tools are not available on small screens, open this template on a larger screen to edit.",
    "dayssince": "Days since",
    "segmentrulelabeltrue": "True",
    "segmentrulelabelfalse": "False",
    "segmentrulelabelunknown": "Unknown",
    "segmentrulelabelallow": "Allow",
    "segmentrulelabeldeny": "Deny",
    "segmentrulelabelnolongerwishreceiveemails": "I no longer wish to receive your emails",
    "segmentrulelabelsendingtoofrequently": "You are sending too frequently",
    "segmentrulelabelneveraskedreceive": "I never asked to receive this email",
    "segmentrulelabelcontainsdeceptivecontent": "Email contains deceptive content",
    "segmentrulelabelmarkedasjunkorspam": "Contact marked the email as junk or spam with their email provider",
    "segmentrulelabelunsubscribedviathirdparty": "Contact unsubscribed via a third-party unsubscribe system",
    "segmentrulelabelunsubscribedvialistunsubscribe": "Contact unsubscribed via list-unsubscribe header",
    "segmentrulelabeldeliveryapi": "Delivery Api",
    "segmentrulelabelmanualinput": "Manual Input",
    "segmentrulelabelfileupload": "File Upload",
    "segmentrulelabelwebform": "Web Form",
    "segmentrulelabelcontactapi": "Contact Api",
    "segmentrulelabeltransactional": "Transactional",
    "segmentrulelabelengaged": "Engaged",
    "segmentrulelabelactive": "Active",
    "segmentrulelabelinactive": "Inactive",
    "segmentrulelabelbounced": "Invalid",
    "segmentrulelabelunsubscribed": "Unsubscribed",
    "segmentrulelabelabuse": "Abuse",
    "segmentrulelabelstale": "Stale",
    "segmentrulelabelnotconfirmed": "NotConfirmed",
    "segmentrulelabelis": "is",
    "segmentrulelabelisnot": "is not",
    "segmentrulelabelstartswith": "Starts with",
    "segmentrulelabelendswith": "Ends with",
    "segmentrulelabelcontains": "Contains",
    "segmentrulelabelnotcontains": "Not contains",
    "segmentrulelabelmonthdayonly": "Month/Day only",
    "segmentrulelabeland": "and",
    "segmentrulelabelor": "or",
    "segmentrulelabelfirstname": "Firstname",
    "segmentrulelabellastname": "Lastname",
    "segmentrulelabellistname": "Listname",
    "segmentrulelabelstatus": "Status",
    "segmentrulelabelsource": "Source",
    "segmentrulelabelunsubscribereason": "Unsubscribe Reason",
    "segmentrulelabelemail": "Email",
    "segmentrulelabeldateadded": "Date Added",
    "segmentrulelabeldateupdated": "Date Updated",
    "segmentrulelabelstatuschangedate": "Status Change Date",
    "segmentrulelabelconsentdate": "Consent Date",
    "segmentrulelabelconsentip": "Consent Ip",
    "segmentrulelabelconsenttracking": "Consent tracking",
    "segmentrulelabeldayssincedateadded": "Days since Date Added",
    "segmentrulelabeldayssincedateupdated": "Days since Date Updated",
    "segmentrulelabeldayssinceconsentdate": "Days since Consent Date",
    "segmentrulelabelcreatedfromip": "Created From Ip",
    "segmentrulelabellasterror": "Last error",
    "segmentrulelabeltotalsent": "Total Sent",
    "segmentrulelabeltotalopens": "Total Opens",
    "segmentrulelabeltotalclicks": "Total Clicks",
    "segmentrulelabeltotalbounces": "Total Bounces",
    "segmentrulelabellastsent": "Last Sent",
    "segmentrulelabellastopened": "Last Opened",
    "segmentrulelabellastclicked": "Last Clicked",
    "segmentrulelabellastbounced": "Last Bounced",
    "segmentrulelabeldayssincelastsent": "Days since Last Sent",
    "segmentrulelabeldayssincelastopened": "Days since Last Opened",
    "segmentrulelabeldayssincelastclicked": "Days since Last Clicked",
    "segmentrulelabeldayssincelastbounced": "Days since Last Bounced",
    "segmentrulelabelcustom": "Custom",
    "segmentrulelabelgeneral": "General",
    "segmentrulelabelstatistics": "Statistics",
    "pickrulevalue": "Pick rule value",
    "rulecondition": "Rule condition",
    "rulefield": "Rule field",
    "listcreated": "List created",
    "listscreated": "List created",
    "listsaved": "List saved",
    "listcopied": "List copied",
    "listdeleted": "List deleted",
    "manualinput": "Manual input",
    "segmenteditscreenctagetcloser": "Get closer to your subscribers by sending personalized emails based on their birthdays or country they live in.",
    "upgradetounlimitedcustomfields": "your plan to get access to Unlimited Custom Fields.",
    "doyouwanttobeclosersubscribers": "Do you want to be even closer with your subscribers?",
    "missingcustomfield": "Missing custom field",
    "missingcustomfieldinfo": "At least one of these segment rules has a missing custom field. Please change the rule to fix it.",
    "notrackinghistorytodisplay": "It looks like there is no tracking history to display.",
    "editsegment": "Edit Segment",
    "contactoverviewalert": "Welcome to the new contact screen! ",
    "takemeback": " The old screen is still available ",
    "addcondition": "Add condition",
    "emptystate": "It looks like there is no data to display.",
    "mustprovidesegmentname": "Segment has to be named.",
    "segmentnameduplicated": "Segment with this name already exists.",
    "removecontactsfromlist": "Remove contacts from list",
    "stalestatus": "Stale status",
    "statusofcontacts": "Status of contact(s)",
    "consenttracking": "Consent tracking",
    "contactsmoved": "Contact(s) moved",
    "contactsadded": "Contact(s) added",
    "contactadded": "Contact added",
    "contactstatus": "Contact status",
    "removed": "removed",
    "remove": "Remove",
    "hasbeenchanged": "has been changed",
    "analysisisnotavailablerightnow": "Analysis is not available right now.",
    "removefromlistdesc": "Are you sure you want to remove the selected contact(s) from this list?",
    "movecontactdesc": "This action will result in taking out the contact from one list to another.",
    "workinconjunctionwith": "works in conjuction with the",
    "contactpruningsettings": "Contact Prunning settings",
    "stalesegmentdesc": "Here you can see all contacts that haven't opened any messages for a set period of time. If they have not interacted with a set number of sent emails, they are automatically blocked. The ",
    "disablestorecontact": "Store contacts delivered through the API",
    "disablestorecontactinfodesc": "Storing the contacts builds their list, provides more metrics and gives more options for delivery optimization engine.  Turn this option off if you prefer to maintain this information exclusively on your own systems.",
    "disablestorecontactdesc": "This option will cause contacts to be stored when an email is submitted to the API.",
    "usedefaultdomain": "Use default bounce domain",
    "usecustomdomain": "Use custom bounce domain",
    "turnoffbouncedomain": "Turn off bounce domain",
    "validatethroughmx": "Expand to validate through MX",
    "trackcampaignstats": "Track campaign stats",
    "trackcampaignstatsdesc": "Receive your campaign statistics after 48 hours, directly to your email inbox.",
    "confirmationapiblocked":"Confirm saving changes",
    "confirmationmessageapiblocked": "Use this button after you're done making changes, but please note it's resource heavy and should not be used too often.",
    "oursupportrepliesfast": "Our support replies fast, but if you want 'ahead of the line' assistance, the Dedicated Support is for you. ",
    "disableStoreContact": "Store contacts delivered through the API",
    "disableStoreContactinfodesc": "Storing the contacts builds their list, provides more metrics and gives more options for delivery optimization engine.  Turn this option off if you prefer to maintain this information exclusively on your own systems.",
    "disableStoreContactdesc": "This option will cause contacts to be stored when an email is submitted to the API.",
    "useDefaultDomain": "Use default bounce domain",
    "useCustomDomain": "Use custom bounce domain",
    "turnOffBounceDomain": "Turn off bounce domain",
    "validatethroughMX": "Expand to validate through MX",
    "duetoyouraccount": "Due to your Account poor engagement",
    "iscurrentlyunderreviewandallemails2": "is currently under review and all emails have been paused.",
    "allemailsubmittedbefore": "All email submitted before and during the review will resume when your account will be active.",
    "toimproveyourengagement": "To improve your engagement we require your account to be placed on at least one Private IP. Please contact us about confirming this configuration and our Customer Success Team will make the appropriate changes for you.",
    "yourfreebillingplan": "Your free billing plan is being retired at the end of January and we sent you a detailed email about this change.  If you did not receive this email please contact support. If you change your plan to one of our new plans before it expires, you will receive a 50% discount for 1 year. Go to your",
    "screenandchoose": "screen and choose 'Change Plan' to take advantage of this special offer.",
    "anydate": "Any date",
    "accountinactive": "Email delivery is temporarily paused because your Account has been paused by our system or our team.  Email can still be submitted during this time and will resume when your Account has been re-activated.  For more information on your Account status, please contact our Customer Success Team.  If you no longer see this message, your Account has been re-activated and your email will resume sending soon.",
    "accountmaintenanceinprogress": "Email delivery is temporarily paused while our system is performing a system upgrade to your Account.  This happens very rarely and typically takes less than 30 minutes.  Email can still be submitted during this time and will resume when the upgrade is complete.  If you no longer see this message, the upgrade has been completed and your email will resume sending soon.",
    "viewreport": "View report",
    "expiredbllingplan": "Your free billing plan has been retired as of January 31, 2021.  We sent you a detailed email about this change on January 4, 2021, and have had a message displayed on this screen to advise you of the change as well.  Your current trial plan has 100 free emails/day.  If you require larger sending volumes, we are offering you a 50% discount that will expire July 31, 2021, for all plan purchases.  To view our latest plan options, please visit your %%billingscreen%%.",
    "billingscreen": "Billing screen",
    "cancelsending": "Cancel",
    "viewreports": "View reports",
    "thereisnodatatodisplay": "There is no data to display",
    "apply": "Apply",
    "clientid": "Client ID",
    "setclientid": "The ID that will be used as your single sign-on authentication method. Any character string can be used.",
    "authority": "Authority",
    "setauthority": "The URL of the OIDC/OAuth2 provider. The authority URL setting is used to make HTTP requests to discover more information about the OIDC/OAuth2 provider.",
    "ssosettings": "Single sign-on authentication",
    "loginwithsso": "Log in with SSO",
    "mostpopularlinks": "Most popular links",
    "seeall": "See all",
    "performance": "Performance",
    "48hperformance": "48-hour performance",
    "pauseautoresponder": "Pause autoresponder",
    "pausecampaign": "Pause campaign",
    "reactivatecampaign": "Reactivate campaign",
    "wearesendingyourcampaign": "We are sending your campaign!",
    "seethenumberofemailswaiting": "See the number of emails waiting to be sent for the first time and those that need to be resend.",
    "viewthelivesendingprogress": "View the live sending progress",
    "logouturl": "Logout URL",
    "setlogouturl": "URL with HTTPS protocol used to log you out from SSO. It’s obtained from your OpenID Provider. It may optionally contain port, path, and query parameter components.",
    "performance": "Performance",
    "recipents": "Recipients",
    "viewcontacts": "View Contacts",
    "complained": "Complained",
    "openratethrough": "Your open-rate can go through the roof!",
    "justneedtogive": "You just need to give it a chance. Create personalized campaigns and",
    "nonopenerslist": "lists for non-openers.",
    "deletecampaigninfoaboutsendingstatusanduseinginautomation": "One of the selected campaigns can not be deleted because it is in the sending status. To delete this campaign now you will first have to cancel the campaign./n/n One of the selected campaigns cannot be deleted because it's in the automation flow. Remove the campaign from the flow to delete it.",
    "deletecampaigninfoaboutsendingstatus": "One of the selected campaigns can not be deleted because it is in the sending status. To delete this campaign now you will first have to cancel the campaign.",
    "deletecampaigninfoaboutuseinginautomation": "One of the selected campaigns cannot be deleted because it's in the automation flow. Remove the campaign from the flow to delete it.",
    "deletecampaignsconfirmation": "Are you sure you want to delete selected campaigns and associated statistics from your account?",
    "cancelsendingcampaign": "Cancel campaign",
    "campaigncancelled": "Campaign canceled.",
    "campaignpaused": "Campaign paused.",
    "campaignreactivated": "Campaign reactivated.",
    "campaigncancelconfirmation": "Are you sure you want to cancel \"{{campaignname}}\"?",
    "campaigncpauseconfirmation": "Are you sure you want to pause \"{{campaignname}}\"?",
    "campaignreactivateconfirmation": "Are you sure you want to reactivate \"{{campaignname}}\"?",
    "deletedcampaigns": "Campaign(s) deleted.",
    "deleteddraftcampaigns": "Draft(s) deleted.",
    "deletecampaignstitle": "Delete {{campaignslength}} campaign(s)",
    "cannotdeletecampaigns": "Cannot delete campaign(s)",
    "deletecampaignsandstatistics": "Are you sure you want to delete campaign \"{{campaignname}}\" and associated statistics from your account?",
    "deletedraftcampaigns": "Are you sure you want to delete selected drafts(s)?",
    "openandclicktrackingdisabled": "Open and click-tracking was disabled for this campaign. Because of this some data will be missing from the report.",
    "clicktrackingdisabled": "Click-tracking was disabled for this campaign. Because of this some data will be missing from the report.",
    "opentrackingdisabled": "Open-tracking was disabled for this campaign. Because of this some data will be missing from the report.",
    "trackclicksbylinkswasdisabled": "Track clicks by links was disabled for this campaign. Because of this some data will be missing from the report.",
    "learnmoreabouttracking": "Learn more about tracking.",
    "na": "N/A",
    "trackingdisabled": "Tracking disabled",
    "accountstoragetooltip": "The number of all contacts in status 'Active', 'Engaged' and 'Transactional' that are accounted for in your billing plan.",
    "subscribedtooltip": "The number of contacts in status 'Active' and 'Engaged' that you can send your campaign to.",
    "hidetraditionallogin": "Hide the traditional login",
    "hidetraditionallogindesc": "This feature allows you to hide the traditional login method, leaving only the Single sign-on as an available option",
    "minutesafter": "minutes after",
    "hoursafter": "hours after",
    "daysafter": "days after",
    "immediatelywhen": "Immediately when",
    "contactaddedtoaudience": "contact is added to audience",
    "contactfromaudienceopens": "contact from audience opens",
    "contactfromaudienceclicks": "contact from audience clicks",
    "fromlower": "from",
    "andspecificlinkcontains": "and specific link contains",
    "anycampaignlower": "any campaign",
    "clicksoccured": "clicks occured",
    "opensoccured": "opens occured",
    "clickmap": "Click Map",
    "clicklist": "Click List",
    "usersclicked": "Users clicked",
    "numberofuniqueclicks": "Number of unique clicks",
    "totalclicks": "Total clicks",
    "numberoftotalclicksofalllinks": "Number of total clicks of all links",
    "clickedrate": "Clicked rate",
    "uniqueclicks": "Unique clicks",
    "exportcontacts": "Export contacts",
    "exportlinks": "Export links",
    "linksexported": "Links exported",
    "contactsexported": "Contacts exported",
    "searchforcountries": "Search for countries",
    "inprogressemailssending": "Emails being processed for sending.",
    "inprogressemailswaiting": "Emails which are scheduled to be sent or retried in the future.",
    "inprogresssendingbanner": "See the number of emails waiting to be sent for the first time and those that need to be resent.",
    "throttling": "Throttling",
    "statusofemaildeliveryratetooltip": "Status of email delivery rate. Green means delivery without limits. Yellow is throttled by the recipient server to 3 emails/second. Red means that the recipient server is not accepting your email.",
    "thespeedatwhichtooltip": "The speed at which the recipient servers accept your emails.",
    "accountpaused": "Account paused",
    "notrightnow": "Not right now",
    "contactsupport": "Contact Support",
    "accountcurrentlypaused": "Your account is currently paused. ",
    "proceedtounblockaccount": "To unblock your account, please ",
    "reactivationformdot": "re-activation form.",
    "questions": "Questions? ",
    "accountpausedemailcontent": "Your account is currently paused due to some concerns with email content.",
    "continuesendingwithusquestions": "We would love to help you continue sending with us, but there are a few questions that we need you to answer for us first.",
    "improveemailcontent": "Please improve your email content and proceed with reactivating your account. If you are unsure what the issues are with your content, please",
    "emailcontentrequireadjustment": "We use third party software to help us improve email quality for our customers and there are areas in which your email content might require some adjustment.",
    "mostsignificantareas": " The most significant areas to improve are highlighted on the",
    "contentanalysisscreen": "content analysis screen.",
    "googlecodes": "If you are unsure what the codes mean you can easily Google them with “SpamAssassin CAPITALIZED_CODE_EXAMPLE”.",
    "addressareaswithemailcontent": "Please address these areas with your email content and proceed with reactivating your account.",
    "accountpauseddirectspamcomplaint": "Your account is currently paused as we have received a direct spam complaint from one of your contacts.",
    "continuesendingwithus": "We would love to help you continue sending with us, but we need you to take care of something for us first.",
    "accountpausedhighinvalid": "Your account is currently paused due to sending emails to a high volume of invalid email addresses.",
    "accountpausedhighcomplaint": "Your account is currently paused due to a high complaint rate.",
    "emailsmarkedasspam": "Complaints are tracked when contacts mark your email as junk or spam. The industry standard for tolerable complaint threshold is 0.1% (1 email per 1000).",
    "accountpausedunsolicited": "Your account is currently paused due to some concerns that the emails your account is sending are unsolicited.",
    "accountpausedquestionablecontent": "Your account is currently paused due to some concerns that the emails your account is sending contain questionable content.",
    "pleaseaddresstheseareas": "Please address these areas in your email content and contact Support so that we can proceed with unblocking your account.",
    "contactsupportdot": "Contact Support.",
    "accountpausedforceprivateip": "Your account is temporarily paused due to your account's poor engagement. We have paused all out-going emails until this issue can be resolved.",
    "improveyourengagement": "To improve your engagement, we require your account to be placed on at least one Private IP.",
    "confirmconfigurationchange": "Please contact Support to confirm this configuration change and our support team will help you with this.",
    "accountpausedviolationofterms": "Your account is currently paused due to a potential violation of our",
    "pleasereviewour": "Please review our",
    "usagepolicies": "usage policies",
    "notviolatingusagepolicies": "and if you feel that you are not violating them, please contact Support so that we can proceed with unblocking your account.",
    "accountpausedblacklisteddomain": "Your account is temporarily paused because your domain or it’s IP is blacklisted. We have paused all out-going emails so that we can help you solve this issue.",
    "pleaseseedomainsdetails": "Please see details regarding your domain's listing as well as instructions for removal",
    "domainsuccessfullydelisted": "When the domain is successfully delisted, please contact Support so that we can proceed with unblocking your account.",
    "heredot": "here.",
    "accountpausedpoorreputation": "Your account is currently paused due to your account's poor reputation.",
    "reputationscreen": "reputation screen.",
    "unsureissueswithreputation": "If you are unsure what the issues are with your reputation, please contact us.",
    "proceedwithreactivating" : "Please address these areas and proceed with reactivating your account.",
    "crcstep5desc": "Please describe in detail your company and your email project. The information you provide will help us with reactivation of your account.",
    "companyinformation": "Company Information",
    "crcstep3question": "Have all your contacts explicitly requested to receive email from you?",
    "inputvalue": "Input value",
    "thisfieldisrequired": "This field is required",
    "crcprofilevalidation": "Profile validation",
    "crcpleaseverifyfollowinginformation": "Please verify if the following information is accurate and complete.",
    "crcsuccess1desc": "We have received your request and our support team will be in touch as soon as possible (typically less than a few hours). Please contact Customer Support if you have any additional questions.",
    "crcsuccess2title": "We are happy to have you back {{firstName}}!",
    "crcsuccess2desc": "Your account has been reactivated. Please contact support, if you have any additional questions.",
    "returntodashboard": "Return to Dashboard",
    "thankyou": "Thank You",
    "crcyouremailswillbepaused": "Your emails will remain paused until further notice.",
    "reviewanwserandreachback": "We appreciate you taking the time to answer our questions, our team will be in touch as soon as possible (typically less than a few hours).",
    "accounthasbeendeleted": "Your account has been deleted",
    "accounthasbeendeleted1": "It's sad to see you go! We removed all your data, and you can no longer log in to your account.",
    "accounthasbeendeleted2": "If you ever change your mind, you are always welcome to start using our service again. In case of any questions, contact our support.",
    "finaldecisionafterverify": "After we verify your reactivation form we'll reach out to you with the final decision.",
    "deleteaccountdialogalert": "Are you sure you want to delete your account? This action cannot be undone.",
    "deleteaccountdialogalert2": "Are you sure you want to delete your account? This action cannot be undone. You have {{subAccountsCount}} sub accounts currently active. Deleting your main Account will delete all your sub accounts.",
    "deleteaccountdialogpleaseenterpassword": "Please enter your password to confirm your identity.",
    "freeplanretired_alert": "Your free billing plan has been retired as of January 31, 2021. We sent you a detailed email about this change on January 4, 2021, and have had a message displayed on this screen to advise you of the change as well. Your current trial plan has 100 free emails/day. If you require larger sending volumes, we are offering you a 50% discount that will expire July 31, 2021, for all plan purchases. To view our latest plan options, please visit your <url>billing screen</url>.",
    "pricingchange_alert": "A $0.40/day charge for data storage and account maintenance will be added to your plan on April 15th to be in line with our lowest plan pricing we offer today. Your grandfathered plan still has the amazing free emails that are not available on new plans and your feature access remains the same therefore no action is required by you. If you have any questions please contact our support team.",
    "pleasereviewcontactlist": "Please review your contact list and ensure you are sending to customers who are expecting to receive your email only. If you are confident in this, and once ready, proceed with unblocking your account.",
    "howreduceemailbouncerate": "Find out more on how to reduce your email bounce rate",
    "reducecomplaintrateandproceed": "Please take the necessary actions to reduce your complaint rate and then proceed with unblocking your account.",
    "clickformoreinformation": "For more information please click",
    "howtomanagereputationanddelivery": "Find out more on how to manage reputation and delivery",
    "acceptableusagepolicies": "Acceptable Usage Policies.",
    "contactsupportifyouareunsure": "If you are unsure specifically what we are concerned with, please contact Support to help resolve this issue.",
    "ourteamwillbeintouch": "Our team will be in touch as soon as possible (typically less than a few hours).",
    "accountandemailcurrentlypaused": "Your account and email are currently paused.",
    "emaildeliveryistemporarilypaused": "Email delivery is temporarily paused while our system is performing a system upgrade to your account. This happens very rarely and typically takes less than 30 minutes. Email can still be submitted during this time and will resume when the upgrade is complete. If you no longer see this message, the upgrade has been completed and your email will resume sending soon.",
    "exportdelete": "Are you sure you want to delete this export?",
    "integrateyourapporwebsite":"Integrate your app or website",
    "yourquickstartguide":"Your quick start guide",
    "paidfeature": "Paid feature",
    "upgradeplantounlock": "Upgrade plan to unlock",
    "configwebhooks": "Configure Webhooks",
    "purchaseipinfo1": "Speed up your delivery and redundancy by purchasing additional private IPs.",
    "purchaseipinfo2": "The cost is currently only {{price}}/ day / IP.",
    "purchaseipinfo3": "Private IPs need to be purchased for a minimum of 1 month.",
    "abresults": "A/B results",
    "activatebillingplan": "Activate billing plan",
    "activatebillingplaninfo": "Your billing plan is not active. Please activate it by registering new payment method to add features to your user account.",
    "searchforexports": "Search for exports",
    "thisdatashowstheresultsoftheabtest": "This data shows the results of the A/B test. After the test period we selected a winning template and sent it to the rest of the campaign recipients.",
    "testresults": "Test Results",
    "testresult": "Test Result",
    "results": "Results",
    "testconditions": "Test Conditions",
    "versionswhichdetermine": "versions, which determine the winner by",
    "after": "after",
    "testing": "Testing",
    "werecollectingyourabtestresults": "We're collecting your A/B test results",
    "oncethedatawillbecollected": "Once the data will be collected, we will show you the winning template.",
    "winnerresults": "Winner Results",
    "hereyoucanseethewinner": "Here you can see the \"Winner\" template that we selected after the end of test period and sent it to the rest of the campaign recipients.",
    "winnerCaptalize": "Winner",
    "byclicks": "by clicks",
    "byopens": "by opens",
    "campaignsexported": "Campaigns exported",
    "automationexported": "Automation exported",
    "exportcampaigns": "Export campaigns",
    "exportautoresponders": "Export autoresponders",
    "autorespondersexported": "Autoresponders exported",
    "version": "Version",
    "inboundemailnotification": "Inbound email notification",
    "inboundemailnotification_desc": "Redirect your inbound notifications either to an HTTP URL or an inbox set up on your own domain. You can create many routes and change their order or fully stop them.",
    "inboundstop_desc": "All emails will be sent to our servers but won't be forwarded or stored anywhere afterward.",
    "addsendingdomain": "Add Sending Domain",
    "addfromemail": "Add From Email",
    "defaultsendingdomain": "Default Sending Domain",
    "domainsetupdescription": "To use this domain for sending, add TXT record to your DNS settings. Double-check the specified hostname before saving.",
    "domainsetupdescription2": "You can also setup inbound email handling with this tool using the Inbound MX record. However inbound mail processing is not required to use this domain for sending.",
    "domainsetup": "Domain Setup",
    "changesindnstakesseveralminutes": "If you just changed your DNS settings it can take several minutes for the changes to become visible here.",
    "setupinbounddomain_alert": "To use inbound routes you must setup the MX records for your inbound domain email. Go to <url>Domain setup</url>",
    "addtagbypressingenter": "Type a proposed template tag and add it by pressing Enter.",
    "filesdeleted": "Files deleted.",
    "addtags": "Add tags",
    "notemplatestodisplay": "There are no templates to display",
    "noformstodisplay": "There are no forms to display",
    "deletefile": "Delete 1 file",
    "deletefiles": "Delete {{number}} files",
    "deleteimage": "Delete 1 image",
    "deleteimages": "Delete {{number}} images",
    "deletefiledesc": "Are you sure you want to delete the selected file?",
    "deletefilesdesc": "Are you sure you want to delete the selected files?",
    "deleteimagedesc": "Are you sure you want to delete the selected image?",
    "deleteimagesdesc": "Are you sure you want to delete the selected images?",
    "imageresized": "Image resized (below 2 MB)",
    "unallowedcharacterschanged": "Unallowed characters changed",
    "wrongfileformat": "Wrong file format",
    "sizebiggerthan": "Size bigger than 20 MB",
    "uploadingoneitem": "Uploading 1 item",
    "uploadingitems": "Uploading {{number}} items",
    "failedupload": "Upload failed. Please try again.",
    "medialinkcopied": "Media link copied.",
    "imagedeleted": "Image deleted.",
    "imagesdeleted": "Images deleted.",
    "filedeleted": "File deleted.",
    "searchformedia": "Search for media",
    "itemselected": "item selected",
    "itemsselected": "items selected",
    "nodatatodisplay": "It looks like there is no data to display.",
    "actioncannotbeundone": "This action cannot be undone.",
    "priceperday": "Price per day",
    "eventsexported": "Events exported",
    "extendedloginfo": "Campaign log information is available for {{days}} days. Campaign statistics will be available indefinitely.",
    "needslogupgrade": "Need access for over 35 days?",
    "absplitheadercontrolstooltip": "Easily switch between the A/B test versions results. You can also check the report of the winning template once campaign is completed/sent.",
    "uploadlistmaxedsize": "Your file is too large. Please try to either Zip it or split in to a number of smaller files. Maximum size of the uploaded file is 20Mb.",
    "selectall": "Select All",
    "buy": "Buy",
    "tryforfree": "Try for Free",
    "keyfeatures": "Key features",
    "youcantryforfree": "You can try for free",
    "alreadydecidedbuyit": "Already decided? <buyit> Buy it</buyit>",
    "backtodiscoverproduct": "Back to Discover Products",
    "product_marketing_desc": "All the marketing tools just one click away. Take advantage of comprehensive set of features that will help you create show-stopping campaigns and connect to your customers better. All the marketing tools just one click away. Take advantage of comprehensive set of features that will help you create show-stopping campaigns and connect to your customers better.",
    "product_marketing_feature_1": "Create emails in our Email Designer",
    "product_marketing_feature_2": "Save time by setting up automations",
    "product_marketing_feature_3": "Perfect your campaigns with A/X testing",
    "product_marketing_feature_4": "Segment your audience",
    "product_marketing_feature_5": "Personalize message by using custom fields",
    "product_marketing_feature_6": "Use landing pages to build your customer base",
    "product_api_desc": "All the marketing tools just one click away. Take advantage of comprehensive set of features that will help you create show-stopping campaigns and connect to your customers better. All the marketing tools just one click away. Take advantage of comprehensive set of features that will help you create show-stopping campaigns and connect to your customers better.",
    "product_api_feature_1": "Learn thanks to extensive documentation",
    "product_api_feature_2": "Add plugins and integrations from our library",
    "product_api_feature_3": "Get notified when necessary with webhooks",
    "product_api_feature_4": "SMTP and HTTP access",
    "product_api_feature_5": "Set up multiple API keys with various access levels",
    "product_api_feature_6": "Take advantage of powerful analytics",
    "product_evs_desc": "Improve your email delivery rates by veryfing if your emails are valid before you send them. Don't do it manually - use our Email Verfication Service for automated, seamless verification experience. Eliminate typos, misspellings, role emails and spam traps quickly and efficiently. Improve your sending reputation by avoiding “red-flag” addresses that send your email to the spam folder.",
    "product_evs_feature_1": "Eliminate spam traps and invalid email addresses",
    "product_evs_feature_2": "Domain Data - MX and A Record Lookups",
    "product_evs_feature_3": "Get real time results for on-form corrections",
    "product_evs_feature_4": "Identify role emails, eliminate spelling mistakes",
    "product_evs_feature_5": "Toxic/Throwaway domain identification",
    "product_evs_feature_6": "Bulk verify lists within minutes",
    "freeplan_desc": "Możesz wypróbować produkt za darmo. Przekonaj się sam jak może być dla Ciebie przydatne. W każdej chwili możesz się 'Upgradować'",
    "freeplan_limitation_feature_1": "100 / day emails limitation",
    "freeplan_limitation_feature_2": "100 contacts limitation",
    "freeplan_evs_limitation_feature_1": "Single verifications only",
    "freeplan_evs_limitation_feature_2": "You can upgrade anytime",
    "switchto": "Switch to",
    "discover": "Discover",
    "bouncetype": "Bounce type",
    "lasteventonly": "Last event only",
    "searchbyemails": "Search by emails",
    "searchbyemail": "Search by email",
    "eventhistory": "Event History",
    "sentfrom": "Sent From",
    "recipient": "Recipient",
    "abusereport": "Abuse Report",
    "expandall": "Expand all",
    "viewemailcapital": "View Email",
    "copycampaignconfirmation": "Are you sure you want to copy the configuration of",
    "youremailsubmittedsuccessfully": "Your email has been submitted successfully.",
    "selectall": "Select All",
    "privateiphasbeenaddedtoyouraccount": "Private IP has been added to your account",
    "gotit": "Got it",
    "congratulation": "Congratulation",
    "wecouldnotprocesspaymentforproducts": "We could not process the payment for your product. Please try again.",
    "tryagain": "Try again",
    "viewworkflow": "View workflow",
    "searchforautomations": "Search for automations",
    "reactivateautomation": "Reactivate automation",
    "automationreactiveconfirmation": "Are you sure you want to reactivate \"{{automationname}}\"?",
    "automationpauseconfirmation": "Are you sure you want to pause \"{{automationname}}\"?",
    "pauseandeditautomation": "Pause and edit automation",
    "pauseandedit": "Pause and Edit",
    "pauseandeditconfirmation": "In order to edit the automation, you have to pause it. Are you sure that you want to pause and edit {{automationname}}?",
    "automationdeleted": "Automation(s) deleted",
    "deleteautomationsmessage": "Are you sure you want to delete selected automation(s) and associated statistics from your account?",
    "totalrecipients": "Total recipients",
    "recipientshavecompletedthisautomation": "Recipients have completed this automation",
    "recipientsarecurrentlyinthisautomation": "Recipients are currently in this automation",
    "allemails": "All emails",
    "workflowemails": "Workflow emails",
    "templatedeleted": "Template deleted",
    "createlistbtn_desc": "Use the Create List button to create personalized campaigns and lists for non-openers and other recipient groups.",
    "openers": "Openers",
    "clickers": "Clickers",
    "nonopeners": "Non-openers",
    "openednotclicked": "Opened but not clicked",
    "cannotcreatelistmodal_title": "You cannot create a list for this campaign",
    "cannotcreatelistmodal_desc": "The campaign for which you want to create list is older than {{days}} days, so the list cannot be created.",
    "automationssendemail": "sends e-mail",
    "automationssendsms": "sends SMS",
    "automationsopensemail": "opens e-mail",
    "automationsclickslink": "clicks link",
    "automationsremovefromlist": "removes from list",
    "automationsaddtolist": "adds to list",
    "automationsjoinslist": "joins list",
    "automationsstatuschanged": "status changed",
    "automationssendwebhook": "sends Webhook",
    "automationsmanually": "manually",
    "automationsisonlist": "is on list",
    "automationsdelay": "delays",
    "automationstriggerjourney": "triggers journey",
    "passworddescription": "Use 6 or more characters",
    "marketingconsentregistration": "I want to receive emails about new products, best practices, or special offers.",
    "readandagreetotheterms": "I have read and agree to the <url>Terms of Use</url>",
    "entervalidemail": "Enter a valid email address",
    "passworderror": "Use 6 characters or more",
    "firstnamemaximum": "Firstname's maximum length is 100 characters",
    "lastnamemaximum": "Lastname's maximum length is 100 characters",
    "emailinuse": "Email address in use, please choose another or log in",
    "signup_email_inuse": "You already have an account. <url>Log in here.</url>",
    "signup_error": "Unable to create your account. Please try again in a few minutes. If this error persists and you are unable to create an account please <url>contact support team.</url>",
    "discountBanner_desc": "{{discount}}% off on all purchases available for",
    "purchase": "Purchase",
    "tryagain": "Try again",
    "keepmeloggedin": "Keep me logged in",
    "iforgotmypassword": "I forgot my password",
    "donthaveanaccountsignup": "Don't have an account? Sign up",
    "marketing": "Email Marketing",
    "twofasecuritycode_desc": "We have just sent you an email containing 6-digit authentication passcode. Please, open it on your device and enter the passcode below.",
    "twofaauthenticationpasscode": "6-digit authentication passcode",
    "twofaresendpasscode": "Resend passcode",
    "twofacantlogin": "I can't log in",
    "letmetalktorealperson": "I still can't log in. Let me talk to a real person",
    "loginauthentication": "Login Authentication",
    "resend2fatoomanytimes_error_msg": "You have requested a code resend too many times. Please try again in a few minutes.",
    "twofalogintitle": "Authentication passcode required",
    "twofalogin_desc": "We sent you a message containing 6-digit authentication passcode. Please, open it on your device and enter the passcode below.",
    "twofaloginbackup_desc": "Enter the backup code you've received when configuring Two-Factor Authentication. Remember that you can only use this code once.",
    "twofabackupcode_input": "Backup code",
    "authenticationpasscoderesent": "Authentication passcode resent.",
    "yournewpasswordshouldbedifferent": "Your new password must be different than your previous password.",
    "yourpasswordhasbeenupdated": "Your password has been updated.",
    "didntgetanemail": "Didn't get an email?",
    "forgotpasswordsuccess_desc1": "A few minutes have passed and you're still waiting for our email? Make sure that:",
    "forgotpasswordsuccess_desc2": "you checked your spam folder.",
    "forgotpasswordsuccess_desc3": "you didn't make any typos in the email address, or didn't accidentally use a different one.",
    "forgotpasswordsuccess_desc4": "you don't have any settings (like filters or firewalls) preventing us from delivering the email.",
    "emailaddressenterednotfound": "The email address you entered was not found.",
    "forgotpassworddesc": "Enter your email address below and we'll send you a link to reset your password.",
    "resetyourpassword" : "Reset your password",
    "sendrecoverylink": "Send recovery link",
    "enteravalidemailadress": "Enter a valid email address",
    "resetpasswordlinksent": "A reset password link was sent to your email. Please check your inbox for further instructions.",
    "login_requestsabuse": "We've detected too many login attempts from this IP. Try again in a few moments.",
    "login_invalidusernameorpassword": "Invalid username or password.",
    "login_accountnotfound": "This email address isn't linked to any account, or the password is invalid. To create an account, <url>sign up</url>.",
    "login_neversignedin": "Your account hasn't been activated yet. Check your inbox and click the link that was sent to: {{email}}. <url>Resend the activation email</url> if you can't find it.",
    "login_accountdisabled": "Your account has been disabled by our automated anti-phishing system or for security or abuse reasons. Please <url>contact customer support</url> team for more information.",
    "yourcurrentplan": "Your current plan",
    "passwordnotmatched": "Passwords are not the same",
    "yourpasswordhasbeenchanged": "Your password has been changed",
    "gotologin": "Go to login",
    "passwordtoshort": "Use 6 characters or more",
    "unsubscribemethods": "Unsubscribe Methods",
    "unsubscribemethod": "Unsubscribe method",
    "unsubscribepage": "Unsubscribe page",
    "unsubscribeheader": "Unsubscribe header",
    "thirdpartylink": "Third party link",
    "manualremoval": "Manual removal",
    "spamreport": "Spam report",
    "viaspamreport": "Via spam report",
    "viaspamreporttooltip": "\"Complaint\" status contacts removed from your list due to reporting one of your emails as spam.",
    "viaunsubscribepage": "Via unsubscribe page",
    "viaunsubscribepagetooltip": "Contacts that have unsubscribed via the unsubscribe page.",
    "viathirdpartylink": "Via third-party link",
    "viathirdpartytooltip": "If you use a third-party link, the contacts unsubscribed through it will be visible here.",
    "viamanualremoval": "Via manual removal",
    "viamanualremovaltooltip": "Contacts that you have manually unsubscribed or set as complaints.",
    "vialistunsubscribeheader": "Via list-unsubscribe header",
    "vialistunsubscribeheadertooltip": "Contacts that have unsubscribed via the list-unsubcribe header option. You can disable this option in the settings.",
    "unsubscribedcontacts": "Unsubscribed Contacts",
    "manageunsubscribedpage": "Manage Unsubscribe Page",
    "unsubscribereasonsection_desc": "When unsubscribing via the unsubscribe page, your audience gave the following reasons.",
    "loadingnodots": "Loading",
    "youraccounthasbeenactivated": "Congratulations! Your account has been activated. You can now log in to your account.",
    "login_user_noaccess": "Access denied. The main account did not grant you log in access. To resolve this issue, contact the main account owner.",
    "activationemailresent": "Email resent. If you didn't receive a link, <url>contact support team</url>.",
    "resetpassword_neversignedin": "Your account is inactive. Check your inbox to activate your account before resetting your password. <url>Resend the activation email</url> if you can't find it.",
    "resetpassword_accountnotactivated": "Your account is inactive. Check your inbox to activate your account before resetting your password. <url>Resend the activation email</url> if you can't find it.",
    "toomanyresetpasswordrequests": "You have requested a password reset too many times. Please try again in a few minutes.",
    "unsubscribesettings": "Unsubscribe settings",
    "feedback": "Feedback",
    "devicedetailstab": "Device Details",
    "twofapasscodeexpired": "Your authentication passcode expired. A new passcode was sent",
    "feedback": "Feedback",
    "uploadsuppressionlist": "Upload suppression list",
    "supportmenuitem": "Support",
    "viewmarketing": "View Marketing",
    "viewemailapi": "View Email API",
    "error500_title": "Oh dear, it seems like there’s nothing in here.",
    "error500_desc": "We’re working to fix the problem. Please try again later, or if you need urgent assistance, contact our <supportButton /> or go back to the <goBackButton>previous page</goBackButton>.",
    "error404_title": "Oh dear, it seems like there’s nothing in here.",
    "error404_desc": "The page you are looking for does not exist or some other error occurred. Go back to the <goBackButton>previous page</goBackButton>  or contact our <supportButton></supportButton> for help.",
    "error401_title": "Are you lost?",
    "error401_desc": "We can't let you in here due to security reasons, but maybe we can show you something else?",
    "previouspage": "previous page",
    "customersupport": "Customer Support",
    "deletedomaindesc": "Are you sure you want to delete {{domain}} ? If there are email addresses assigned to this domain, they won't be deleted",
    "extendedlogbannerdesc" : "Currently we store your logs up to",
    "extendedlogbannerdesc2" : "days. Upgrade your plan to access over one month of detailed email logs.",
    "purchaseextendedlogs" : "Purchase Extended Logs",
    "extendedlogsdesc" : "Over one month of detailed email logs available.",
    "extendedlogsinfoalert" :  "Extended Logs need to be purchased for a minimum of 1 month",
    "extendedlogsprice" : "The cost is currently only $2/day.",
    "extendedlogshasbeenaddedtoaccount": "Extended Logs has been added to account",
    "legacybliingdesc": "Change plan and billing details and view payment history, usage and receipts.",
    "validsenderdomainsonly": "Valid sender domains only",
    "validsenderdomainsonly_desc": "Enable this setting to only allow sending where the sender domain is validated. The default domain will not be used as the sender and mail will instead be suppressed if this setting is enabled.",
    "extendedlogs": "Extended logs",
    "cantattachfileswiththesamename": "This file cannot be attached because another attachment has the same file name. Please change the name of this file to attach it.",
    "cantattachfile": "File cannot be attached",
    "movedevicedetails": "Device Details screen has been moved and is available on the new app. Check out our new and improved screen!",
    "viewcampaigninprogress": "Click <a id='egoToCampaignInprogress' style='text-decoration: underline; cursor:pointer;'>here</a> to view live sending progress.",
    "supportoptions": "Support options",
    "tooltipabsplitbutton": "Use A/B testing to see which email variant will perform better with your subscribers. Once you have two (or more) versions of your campaign, they will be sent to 10% of selected contacts (single contact will receive one template). Based on the number of clicks/opens, the “winning” template will be automatically sent to the rest of the contacts.",
    "cannotsendtounsubscribedorinvalid": "This email address is not on your contact list. You can only send a test email to addresses that are in your contact list and have agreed to receive messages from you. Please choose another email address.",
    "missinginputonsend": "The campaign cannot be send. Please fill all the required inputs",
    "missinginputonsave": "The campaign cannot be saved. Please fill all the required inputs",
    "emailapiandsmtprelay": "Email API and SMTP relay",
    "suppressionsmanagement": "Suppressions Management",
    "trackingandanalytics": "Tracking and Analytics",
    "ifyouneedmorethan2ips": "If you need more than 2 private IPs, please ",
    "contactoursupport": "contact our support team",
    "privateip": "Private IP"
}

ee.loadlang('en', 'English', english, true)
