(function (root) {
    var _overlay,
        _customFieldAddForm = function (success) {
            //ADD FORM
            var $addForm = html.get('CustomFieldAddForm');

            $(document).on('change', '#econtactfield, #efieldtype', function (e) {
                var $fieldType = $('#efieldtype');
                if ($fieldType.val() === '0') {
                    $addForm.fieldname.attr("class", 'col-xs-3');
                    $addForm.textfieldlength.removeClass('hidden');
                    $('#elengthlabel').removeClass('hidden');
                } else {
                    $addForm.fieldname.attr("class", 'col-xs-5');
                    $addForm.textfieldlength.addClass('hidden');
                    $('#elengthlabel').addClass('hidden');
                }
            });
            $addForm.on('click', 'input, select', function () {
                $(this).removeClass('error');
            });
            $addForm.on('click', '.btn_primary', function () {
                var newField = $addForm.elasticCollectForm(), $fielderror = $addForm.formaddfielderror,
                    availablefields = ee.data.availablefields;
                $fielderror.hide();
                if (parseInt(newField.type) < 0 || parseInt(newField.type) > 7) {
                    $addForm.find('select').addClass('error');
                    $fielderror.show().children().html(ee.t.errornewfieldtypeempty);
                    return;
                }
                newField.name = newField.name.trim().toLowerCase();
                if (newField.name.length === 0) {
                    $addForm.find('input[name=name]').addClass('error');
                    $fielderror.show().children().html(ee.t.errornewfieldnameempty);
                    return;
                }

                if (/\s/g.test(newField.name)) {
                    $addForm.find('input[name=name]').addClass('error');
                    $fielderror.show().children().html(ee.t.errornewfieldwhitespaces);
                    return;
                }
                if ($.isNumeric(newField.name[0]) === true) {
                    $addForm.find('input[name=name]').addClass('error');
                    $fielderror.show().children().html(ee.t.errornewfieldnamestartnumber);
                    return;
                }
                if (ee.data.availablefields.filter(['name'], newField.name).length > 0) {
                    $addForm.find('input[name=name]').addClass('error');
                    $fielderror.show().children().html(ee.t.errornewfieldduplicate);
                    return;
                }

                if (newField.type === "0" && newField.length <= 0) {
                    $addForm.find('input[name=length]').addClass('error');
                    $fielderror.show().children().html(ee.t.errornewfieldlength);
                    return;
                }
                newField.type = parseInt(newField.type);
                newField.typename = availablefields.getType(newField.type);
                newField.fielddata = availablefields.getFieldData(newField.typename);

                availablefields.AddField(newField)
                    .then(function () {
                        $addForm.find('input').val("");
                        success(newField);
                    });

            });
            return $addForm;
        };

    var that = {
        init: function (onClose) {
            _overlay = false;
            var $managefieldsoverlay = html.get("ManageFields"),
                $customFieldAddForm = _customFieldAddForm(function (newField) {
                    html.get("ContactCustomField", { customfields: newField, editionmode: true }).appendTo($managefieldsoverlay.customfields);
                    html.get("ContactCustomField", { customfields: newField, editionmode: false }).appendTo(screen.customfields);
                    //html.modal.info(html.render(ee.t.newfieldsuccess, { name: newField.name }), ee.t.success);
                    $('#enewfieldinfo').text('New custom field was added successfully.');
                }),
                availablefields = dataModel.create("ContactFields");
                for (var i = 3; i < availablefields.data.length; i++) {
                    switch(availablefields.data[i].type) {
                        case '0':

                    }
                }
            if (ee.session.can.ModifyCustomFields)
                $customFieldAddForm.insertBefore($managefieldsoverlay.customfields);

            $.when(availablefields.require())
                .then(function (data) {
                    html.get("ContactCustomField", { customfields: availablefields.getAll(), editionmode: true })
                        .appendTo($managefieldsoverlay.customfields);
                })
            _overlay = new html.Frameoverlay($managefieldsoverlay).on({ 'beforeClose': onClose });
            if(ee.isLive()) {
                $('#menageCustomDesc').html(ee.t.ttmanagecontactfields);
            } else {
                $('#menageCustomDesc').text(ee.t.managecontactfieldsoutside);
            }
            $managefieldsoverlay.customfields.on('click', '.btn-trash', function () {
                var selected = $(this).data('removefield');
                var $row = $(this).closest('.row');
                var fieldname = $(this).val();
                html.modal.confirm(Mustache.render(ee.t.removecontactfielddesc, { name: fieldname }), ee.t.removecontactfieldtitle, function () {

                    availablefields.RemoveField(fieldname)
                        .then(function () {
                            $row.remove();
                            if (screen && screen.customfields) screen.customfields.find("#contact_" + selected).closest('.row').remove();
                        });

                }, false, { size: "emodal-md" });

            });
        },
        /*NOTE: Method bellow is using by webform.js NOTE figure better place for it */
        customFieldAddForm: _customFieldAddForm
    }

    root.ContactFieldsUI = that;
}(html.widget));
