ee.views['landingpages'] = (function ViewTemplates() {
    if (!ee.templateFilters) {
        ee.templateFilters = [2];
    }
    
    /*-- Returned object of view class--*/
    let that = {};
    let pagination;
    let $screen;
    const cutcompanyname = () => {
        if (ee.data.account.parentcompany && ee.data.account.parentcompany.length > 14) {
            parentcompanyname = (ee.data.account.parentcompany).substring(0,14)
            parentcompanyname += '...'
        } else {
            parentcompanyname = ee.data.account.parentcompany
        }
        return parentcompanyname;
    }
    let firstLoad = true;
    const parseCopyTemplateResponse = (response) => {
        ee.indiOff();
        //Add new tempate to list
        response.data = ee.api._parseTemplate(response, true)
        ee.data.templates.add("unshift", response)
        ee.goTo(`#/landingpages/edit/${response.templateid}`);
    }
    const publishLandingPage = (templateid, callback) => {
        if (!templateid) {
            console.error("ERROR: not selected template");
            return;
        }
        if (templateid > 0) {
            ee.views.templates.setSelectedTemplate(templateid, false, function () {
                ee.editors.core._publishLandingPage(false, callback);
            });
            return;
        }
        const selected = ee.data.templates.data.find(item => item.templateid === templateid)
        const win = html.get("PublishlandingPage", selected);
        const modal = html.modal.create(win, {
                title: ee.t.publish, size: "emodal-md", buttons: [
                    {
                        name: ee.t.downloadhtml,
                        css: 'btn_primary btn_lg',
                        callback: function (button) {
                            let templateid = selected.templateid;
                            let query = {templateid: templateid}
                            
                            return EE_API.Template.DownloadTemplate(query)
                            .then ((data) => {
                                let blob = new Blob([data]);

                                let templatename = selected.name;
                                let fileName = `${templatename}.html`;
                                let link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = fileName;
                                link.click();
                            })
                            .catch((err) => {
                                console.log({err})
                            })
                        }
                    },
                    {
                        name: ee.t.view,
                        css: 'btn_primary btn_lg',
                        callback: function () {
                            let linkToOpen = $('#templateLink').val();
                            window.open(linkToOpen, '_blank');
                        }
                        
                    }]
            });
    } 
    let dropdownMenuIsClicked;
    const setupOnClickMenuElements = (row) => {
        dropdownMenuIsClicked = true;
        if (row == null) {return;}
        $(row.parentElement).off('click');
        //Preview
        $(row.parentElement).on('click', '.gallery-preview', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            var $galleryitem = $(this).closest('ul');
            _previewContent($galleryitem.data('templateid'), $galleryitem.data('ispublic'));
        });
        //edit
        $(row.parentElement).on('click', '.action-edit', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            ee.goTo('#/landingpages/edit/' + $(this).closest('ul').data('templateid'));
        });

        //edit drag drop as HTML
        $(row.parentElement).on('click', '.action-edithtml', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
                ee.goTo('#/landingpages/edit/' + $(this).closest('ul').data('templateid') + '/rawhtml');
        });
        //Copy and edit
        $(row.parentElement).on('click', '.action-copyandedit', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            ee.goTo("#/landingpages/create/" + $(this).closest('ul').data('templateid'));
            return;
        })
        //Default options
        $(row.parentElement).on('click', '.action-editdefault', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            var templateid = $(this).closest('ul').data('templateid'), template = _getTemplate(templateid),
                win = html.get("TemplateDefaultOptions", { template: template, accountscope: ee.data.account.scope, subaccount: ee.data.account.issub });
            html.widget.SelectFromEmail(win.fromemail, template.fromemail, true).then(function () {
                var modal = html.modal.create(win, {
                    title: ee.t.templatedetail,
                    size: "emodal-md",
                    buttons: [{
                        name: ee.t.save,
                        css: "btn_lg btn_primary",
                        title: ee.t.ttsavedefaults,
                        callback: function () {
                            template.name = win.title.val();
                            template.fromemail = win.find('input[name=fromemail]').val();
                            template.fromname = win.fromname.val();
                            template.subject = win.subject.val();
                            if (win.scope) template.templatescope = win.scope.is(':checked') === true ? 1 : 0;
                            if (win.isdefault) template.isdefault = win.isdefault.is(':checked');
                            modal.close();
                            ee.api.saveTemplate(template, false, false);
                        }
                    }]
                });
            });
        });
        $(row.parentElement).on('click', '.action-publish', function (e) {
            dropdownMenuIsClicked = false;
            const templateid = $(this).closest('ul').data('templateid');
            publishLandingPage(templateid);

            let domain = (ee.data.account.privatebrandingurl) ? `${ee.getProtocol()}//${ee.data.account.privatebrandingurl}` : ee.api.path;            const selectedLandingPage = ee.data.templates.data.find(item => item.templateid === templateid)
            let linkToPage = selectedLandingPage.link;
            linkToPage = linkToPage.split('/page')[1];
            let linkToLandingPage = `${domain}/page${linkToPage}`;
            setTimeout(()=>{
                $('#templateLink').val(linkToLandingPage);

                $('#copyLinkToLandingpage').click ( () => {
                    $('#templateLink').val(linkToLandingPage).select();
                    document.execCommand("copy");
                    html.widget.Toast.info(ee.t.copiedlink, "", {fadeIn: 250, faeOut: 250, timeOut: 1000});
                })
            }, 500)
            
        });
        //Copy Template
        $(row.parentElement).on('click', '.action-copy', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            const templateid = $(this).closest('ul').data('templateid'); 
            const template = _getTemplate(templateid);
            if ($.isEmptyObject(template)) {
                return
            }
            const win = html.get("TemplateDefaultOptions", { template: template, hideoptions: true, islanding: template.templatetype === 2 });
            let modal;
            html.widget.SelectFromEmail(win.fromemail, template.fromemail).then(function () {
                modal = html.modal.create(win, {
                    title: ee.t.copytemplate,
                    size: "emodal-md",
                    buttons: [{
                        name: ee.t.savecopy,
                        title: ee.t.ttsavecopy,
                        css: "btn_primary btn_lg",
                        callback: function () {
                            ee.indiOn();
                            let query = {templateID:template.templateid, name: win.name.val(), subject: '', fromname: '', fromemail: '', scope: 0, ispublic: template.ispublic}
                            EE_API.Template.CopyTemplate(query)
                            .then(parseCopyTemplateResponse)
                            .catch((error) => {
                                html.modal.info(error);
                            });
                        }
                    }]
                });
                ee.tools.validateEntityName(
                    {
                        screen: win,
                        inputname: 'name',
                        submit: modal.$footer.find('.btn_primary')
                    }
                )

            });
        });
        //Delete Template
        $(row.parentElement).on('click', '.action-delete', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            const templatename = $(this).closest('ul').data('templatename');
            const templateid = $(this).closest('ul').data('templateid');

            ///[\\/\\]/.test(templatename) - v3 breaks with / and \ in url
            if (/[\\/\\]/.test(templatename) || !ee.session.can.ViewAutomations) {
                html.modal.confirm(ee.t.deletetemplate, ee.t.removetemplate, function () {
                    ee.api.deleteTemplate(templateid, 0, ee.api.loadTemplates);
                }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
            } else {
                //Resource has 4 possible value, Templates = 0, Lists = 1, Campaign = 2, Automation = 3
                return API_V3.Journeys.GetAutomationUsage(templatename, {Resource: 0})
                .then(function(data) {
                    if (data.length > 0) {
                        const automatedCampaignName = data.join(', ');
                        if (ee.session.can.ModifyAutomations) {
                            html.modal.confirm(ee.t.deletetemplateusageinautomation.replace(/{{AutomatedCampaignName}}/g, "<span class='automationsName'>" + automatedCampaignName + "</span>"),
                            ee.t.removetemplate, function () {
                                ee.api.deleteTemplate(templateid, 0, ee.api.loadTemplates);
                            }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
                        } else {
                            html.modal.info(ee.t.noaccesstodeletetemplateusageinautomation.replace(/{{AutomatedCampaignName}}/g, "<span class='automationsName'>" + automatedCampaignName + "</span>"));
                        }
                    } else {
                        html.modal.confirm(ee.t.deletetemplate, ee.t.removetemplate, function () {
                            ee.api.deleteTemplate(templateid, 0, ee.api.loadTemplates);
                        }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
                    }
                })
                .catch(function(err) {
                    if (err && err.responseJSON) {
                        html.modal.error(err.responseJSON.Error);
                    }
                    console.log(err);
                })
            }
        });
    }
    var _generateLandingPageScreen = function (simpleview, startTab, insideCampaignCreator) {
        dropdownMenuIsClicked = false;
        ee.tools.setupMutationObserver('.templatesdrop', dropdownMenuIsClicked, setupOnClickMenuElements);
        ee.templateFilters = [2];
        if (startTab === "show") startTab = "";
        let parentcompanynameisnotelastic  = (ee.data.account.parentcompanyname !== "Elastic Email");
        let companyname;
        let parentcompanyname = cutcompanyname();
        ee.data.account.parentcompany ? companyname = parentcompanyname : companyname = ee.t.company;
        const isGallery = location.hash.includes('gallery');
        const hasTemplates = ee.data.templates.filter('templatetype', 2).data.length !== 0;
        $screen = html.get('Landingpages', { simpleview: simpleview, subaccount: ee.data.account.issub, brandedsubacc: parentcompanynameisnotelastic, companyname: companyname, isgallery: isGallery, hastemplates: hasTemplates });
        ee.t.dynamicnotemplates = ee.t.notemplates;
        //Default load always my templates
        pagination = new html.Pagination([], {
            template: (!simpleview) ? 'LandingPagesListPreview' : 'LandingPagesListPreviewSimple',
            searchparameters: ['name'],
            showbytags: ['tags'],
            resultcontainer: $screen.paginationresult,
            footercontainer: $screen.resultfooter,
            perpage: 12,
            paginationinfo: false,
            param: {  issub: ee.data.account.issub, pricing2018: ee.data.account.pricing2018 },
            firstlast: false,
            inFrame: insideCampaignCreator
        });

        pagination.eventChangePage(function (e) {
            e.stopPropagation();
            const $currTypeTab = $('#etemplatetypetabs li.active');
            const currType = $currTypeTab.data("type");
            const currTemplateType = parseTemplateTypeToFetchedNames(currType);
            const templatesType = parseTemplateTypeToCountNames(currTemplateType);

            if ($currTypeTab.data('searched')) {
                templatesType = "searchresults";
            }

            if ( (pagination.getCurrentPage() === pagination.getPagesCount()) &&
            !(pagination.getItemsCount() === ee.data[templatesType + "count"])) {
                ee.api.request('/template/getlist', { offset: pagination.getItemsCount(), limit: 500, templateTypes: ee.templateFilters }, function (data) {
                    ee.indiOff();
                    if (!data.success) return html.modal.error(data);

                    for(var i = 0; i < data.data[currTemplateType].length; i++) {
                        data.data[currTemplateType][i].thumbnailpath = ee.api.path + "/userfile" + data.data[currTemplateType][i].thumbnail;
                        data.data[currTemplateType][i].mediumscreenshotpath = ee.api.path + "/userfile" + data.data[currTemplateType][i].mediumscreenshot;
                    }
                    const templatesType = parseTemplateTypeToCountNames(currTemplateType);

                    ee.data[templatesType] = new Collection.Data(ee.data[templatesType].data.concat(data.data[currTemplateType]));
                    pagination.updateData(filterTemplates(ee.data[templatesType].data));
                    pagination.refresh();
                    html.widget.TemplateThumbnail.init($screen);
                });
            }
            html.widget.TemplateThumbnail.init($screen);
            
            
        });


        //Bind Create
        $screen.reateLandingPage.on('click', () => {
            ee.logEvent('Landing Page - Load editor.');
            ee.goTo("#/landingpages/create/landingpage");
        });
        
        
        //Bind Tabs
        $screen.templatetypetabs.on('click', 'li', function (e) {
            e.stopPropagation();
            $screen.templatetypetabs.find('li').removeClass('active');
            $screen.searchinputtemplates.val("");
            pagination.setPage(1);
            const $this = $(this);
            const type = $this.data("type");
            $this.addClass('active');
            if (type === 'gallery') {
                ee.t.dynamicnotemplates = ee.t.notemplatesglobal;
                pagination.updateData(filterTemplates(ee.data.templatesglobal.getAll()));
            } else if (type === 'drafts') {
                ee.t.dynamicnotemplates = ee.t.notemplatedrafts;
                pagination.updateData(filterTemplates(ee.data.templatesdraft.getAll()));
            } else if (type === 'parent') {
                let companyname;
                ee.data.account.parentcompany ? companyname = ee.data.account.parentcompany : companyname = ee.t.company;
                ee.t.dynamicnotemplates = `<h4> ${companyname} ${ee.t.notemplateparents}<br/>&nbsp; </h4>`;
                pagination.updateData(filterTemplates(ee.data.templatesparent.getAll()));
            } else {
                ee.t.dynamicnotemplates = ee.t.notemplates;
                pagination.updateData(filterTemplates(ee.data.templates.getAll()));
            }
            if (!simpleview) ee.Router.updateHash(type ? '#/landingpages/' + type : '#/landingpages', !e.originalEvent);
            html.widget.TemplateThumbnail.init($screen);


        });

        //Bind Search
        html.bindSearch($screen.searchtemplates, function (searchstring) {
            const $currTypeTab = $('#etemplatetypetabs li.active');
            const currType = $currTypeTab.data("type");
            const currTemplateType = parseTemplateTypeToFetchedNames(currType);
            const typeNumber = currTemplateType === "templates" ? 0 :
            currTemplateType === "globaltemplate" ? 1 :
            2;

            if(searchstring !== "") {
                $currTypeTab.attr("data-searched", true);

                EE_API.Template.Search({ query: searchstring, templateType: typeNumber, templateTypes: ee.templateFilters })
                .then(function(data) {
                    for(let i = 0; i < data.templates.length; i++) {
                        data.templates[i].thumbnailpath = `${ee.api.path}/userfile${data.templates[i].thumbnail}`;
                        data.templates[i].mediumscreenshotpath = `${ee.api.path}/userfile${data.templates[i].mediumscreenshot}?timestamp=${(new Date().getTime())}`;

                        if (currTemplateType === "globaltemplate") {
                            data.templates[i].ispublic = true;
                        }
                    }

                    ee.data.searchresults = new Collection.Data(data.templates);
                    ee.data.searchresultscount = data.templates.length;
                        
                    let searchcount = ee.data.searchresultscount;
                    let newData;
                    let allDoubleTemplates = [];
                    let searchResults = (ee.data.searchresults.data).slice();

                    _.forEach (ee.data.searchresults.data, (foundTemplate) => {
                        let templateidToCheck = foundTemplate.templateid;
                        //find all templates which are in ee.data.templates and search results -> all of them are in allDoubleTemplates array
                        newData = ee.data.templates.data.filter(item => {
                            return item.templateid === templateidToCheck;
                        });
                        if (newData.length) {
                            allDoubleTemplates.push(newData[0])
                        } 
                    } ) 
                    _.forEach (allDoubleTemplates, (template) => {
                        //find index of every template from allDoubleTemplates 
                        let indexToRemove = searchResults.map((e) => { return e.templateid; }).indexOf(template.templateid);
                        
                        //remove every template from allDoubleTemplates from searchResults - now we have in searchResults only this templates which are not in ee.data.templates
                        searchResults.splice(indexToRemove, 1);
                    }) 
                    _.forEach (searchResults, (searchResult) => {
                        ee.data.templates.data.push(searchResult)
                    }) 
                    
                    pagination.updateData(data.templates);
                    pagination.refresh();
                    html.widget.TemplateThumbnail.init($screen);
                });
            } else {
                $currTypeTab.removeAttr("data-searched");
                const templatesType = parseTemplateTypeToCountNames(currTemplateType);
                pagination.updateData(filterTemplates(ee.data[templatesType].data));
                pagination.refresh();
                html.widget.TemplateThumbnail.init($screen);
            }
        }, {inFrame: insideCampaignCreator}
        );
        if (!startTab) {
            if (ee.data.templates.data.length === 0 && ee.data.templatesglobal.data.length > 0 && firstLoad) {
                $screen.templatetypetabs.find('li[data-type=gallery]').trigger('click');
                ee.Router.updateHash('#/landingpages/gallery');
                firstLoad = false;
            } else {
                $screen.templatetypetabs.find('li[data-type]:first').trigger('click');
            }
        } else {
            $screen.templatetypetabs.find('li[data-type=' + startTab + ']').trigger('click');
        }
        $('#templates-menu-item').on ('click', () => {
            const changeTemplateTab = () => {
                if (ee.data.templates.data.length === 0 && ee.data.templatesglobal.data.length > 0) {
                    $screen.templatetypetabs.find('li[data-type=gallery]').trigger('click');
                    ee.Router.updateHash('#/landingpages/gallery');
                    firstLoad = true;
                } else {
                    $screen.templatetypetabs.find('li[data-type]:first').trigger('click');
                }
            }
            if (!ee.data.templates) {
                ee.api.loadTemplates(changeTemplateTab);
                    
            } else {
                changeTemplateTab();
            }
            
            
        })
        
        return $screen;
    };
    /*--- START GLOBAL METHODS ---*/
    const _previewContent = function (templateid, isPublic) {
        var template = _getTemplate(templateid, isPublic);
        
        ee.indiOn();
        var doc, minHeight, resizetInterval, $content = html.get("PreviewTemplate", template), $iframe = $content.iframe;
        $content.moible.click(function () {
            if ($content.moible.hasClass("active")) { return; }
            $content.phone.animate({ "opacity": 0 }, 200, function () {
                $content.phone.addClass("iPhone5");
                $iframe.attr("class", "iPhone5frame enoborder");
                if (doc.body) { doc.body.style.overflow = 'auto'; }
                $content.phone.animate({ "opacity": 1 }, 400);
            });
        });
        
        
        
        //Desktop Previow
        $content.desktop.click(function () {
            if ($content.desktop.hasClass("active")) { return; }
            $content.phone.animate({ "opacity": 0 }, 200, function () {
                $content.phone.removeClass("iPhone5");
                $iframe.attr("class", "efullwidth efullheight enoborder");
                if (doc.body) { doc.body.style.overflow = 'hidden'; }
                $content.phone.animate({ "opacity": 1 }, 400);
            });
        });

        EE_API.Template.GeneratePreview({ templateid: templateid, ispublic: (isPublic || false) }).then(function (template) {

            new html.Frameoverlay($content).on({
                'beforeShow': function () {
                    minHeight = window.innerHeight - ($iframe.offset().top - window.scrollY) - parseInt($iframe.css("margin-top"), 10);
                    $iframe.height(minHeight);
                    resizetInterval = setInterval(resizeIframe, 600);
                    $iframe.on('load', function () {
                        clearInterval(resizetInterval);
                        resizeIframe();
                    });
                    doc = $iframe[0].contentWindow.document;
                    doc.open();
                    doc.write(template);
                    doc.close();
                    if (doc.body) {
                        doc.body.style.overflow = 'hidden';
                        $(doc).find("html").height("100%");
                    }
                    _findBrokenImageInFrame($iframe);
                },
                'afterShow': function () { ee.indiOff(); 
                }
            });

            function resizeIframe() {
                if ($iframe[0].contentWindow) {
                    let height = $iframe[0].contentWindow.document.body.scrollHeight;
                    if (height < minHeight) height = minHeight;
                    $iframe.height(height);
                    
                }
            }
        });
        return;
    };
    const _findBrokenImageInFrame = function ($iframe) {
        $iframe.contents().find('img').on("error", function () {
            const parent = $(this).parent();
            if (parent) {
                parent.addClass('dnoimg');
            }
            $(this).off("error").replaceWith(html.getraw("ImageNotFound"));
        });
    };
    const _getTemplate = function (templateid, scope) {
        var templateid = parseInt(templateid, 10);
        //change this to public scopename
        if ((scope === 'true' || scope === true) && ee.data.templatesglobal) {
            return ee.data.templatesglobalandparent.get('templateid', templateid)
            
        }
        
        if (scope === 'snapshot' && ee.data.templateSnapshots) {
            return ee.data.templateSnapshots.get('templateid', templateid)
        }
        let template;
        if (ee.data.templates) template = ee.data.templates.get('templateid', templateid);

        if (!template && ee.data.templatesdraft) {
            template = ee.data.templatesdraft.get('templateid', templateid);
        }
        return template;
    };
    /*--- END GLOBAL METHODS ---*/
    /*--- START PRIVATE HELPFUL METHODS ---*/

    let _isTemplateLoading = false;
    let popstateListener = (element, event, listener) => {
            element.addEventListener(event, popstateListenerEvent = () => {
                element.removeEventListener(event, popstateListenerEvent);
                return listener();
            });
        }
        
    const _getCustomDefaultTemplate = function (templatetype) {
        ee.data.selectedtemplate = false;

        let template;
        ee.api.request('/template/getlist', {}, (data) => {  
            ee.data.templates = new Collection.Data(data.data.templates)
        if (templatetype === "landingpage") {
            template = (ee.data.templates.filter('templatetype', 2).get('isdefault', true)) ? ee.data.templates.filter('templatetype', 2).get('isdefault', true) : ee.data.templatesglobal.filter('templatetype', 2)?.get('isdefault', true);
        } else {
            return ee.goTo("#/dashboard");
        }
        if (!template) {
            return _runEditor(templatetype);
        }

        var query = { templateid: template.templateid, ispublic: (template.ispublic || false) };
        
        ee.indiOn();
        return EE_API.Template.LoadTemplate(query)
    
        .then(function(loadedTemplate) {
            loadedTemplate.typename = ee.editors.core._editorsTypeName[template.templatetype];

            if (loadedTemplate.thumbnail) { loadedTemplate.thumbnailpath = ee.api.path + "/userfile" + loadedTemplate.thumbnail; }
            if (loadedTemplate.mediumscreenshot) { loadedTemplate.mediumscreenshotpath = ee.api.path + "/userfile" + loadedTemplate.mediumscreenshot; }
            if (loadedTemplate.screenshot) { loadedTemplate.screenshotpath = ee.api.path + "/userfile" + loadedTemplate.screenshot; }
            if (_.isNil(loadedTemplate.bodytext)) { loadedTemplate.bodytext = ""; }
            if (_.isNil(loadedTemplate.css)) { loadedTemplate.css = ""; }
            if (_.isNil(template.editormetadata) || template.editormetadata === "null") { delete template.editormetadata; }



            return loadedTemplate;
        })
        .then(function(parsedTemplate) {
            parsedTemplate.contentLoaded = true;

            if (parsedTemplate.ispublic) {
                if (ee.data.templatesglobal.replace('templateid', template.templateid, parsedTemplate) === null)  {
                    ee.data.templatesglobal.add(parsedTemplate);
                }
            } else if (parsedTemplate.templatescope === 2) {
                if (ee.data.templatesdraft.replace('templateid', template.templateid, parsedTemplate) === null) {
                    ee.data.templatesdraft.add(parsedTemplate);
                }
            } else {
                if (ee.data.templates.replace('templateid', template.templateid, parsedTemplate) === null)  {
                    ee.data.templates.add(parsedTemplate);
                }
            }

            ee.data.selectedtemplate = parsedTemplate;

            //ee.data.selectedtemplate = ee.editors.core._defaultTemplate;
            let dateFormat = /:([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
            let originalTemplateName = parsedTemplate.name;
            if ( dateFormat.test(parsedTemplate.name)) {
                let indexOfDateInTemplateName = originalTemplateName.search(dateFormat);
                originalTemplateName = parsedTemplate.name.substring(0, indexOfDateInTemplateName)
                ee.data.selectedtemplate.name = `${originalTemplateName}:${moment().format('YYYY-MM-DD HH:mm:ss')}`;
            } else {
                ee.data.selectedtemplate.name = `${originalTemplateName}:${moment().format('YYYY-MM-DD HH:mm:ss')}`;
            }

            ee.data.selectedtemplate.bodyhtml = parsedTemplate.bodyhtml;
            ee.data.selectedtemplate.css = parsedTemplate.css;
            ee.data.selectedtemplate.templatetype = parsedTemplate.templatetype;
            ee.data.selectedtemplate.originaltemplateid = parsedTemplate.templateid;
            ee.data.selectedtemplate.templateid = 0;
            ee.data.selectedtemplate.templatescope = 0;
            ee.indiOff();

            return _runEditor(templatetype);
        })
        

        .catch((err) => {
            html.modal.error(err, { path: path });
        })

        });
    };
    const parseTemplateTypeToFetchedNames = function(tabTypeData) {
        switch (tabTypeData) {
            case "gallery":
                return "globaltemplate";
                break;
            case "parent":
                return "parenttemplate";
                break;
            default:
                return "templates"
                break;
        }
    }
    const parseTemplateTypeToCountNames = function (tabTypeData) {
        switch (tabTypeData) {
            case "templates":
                return "templates";
            case "parenttemplate":
                return "templatesparent";
            default:
                return "templatesglobal";
                break;
        }
    }
    const _copyTemplateToSelected = function (template, callback) {
        ee.data.selectedtemplate = $.extend(true, {}, template);
        if (callback) {
            callback();
        } else {
            ee.view.show();
        }
    }
    const _setSelectedTemplate = (templateIdOrName, ispublic, callback) => {
        let possibleId;
        if (!isNaN(templateIdOrName)) {
            possibleId = parseInt(templateIdOrName, 10)
        }
        let template;
        if (possibleId) {
            templateIdOrName = possibleId;
            ee.data.selectedtemplate = false;
            template = _getTemplate(templateIdOrName, ispublic);
        }

        if (template) {
            if (!template.contentLoaded) {
                ee.api.loadTemplateContent(template, (completeTemplate) => { 
                    _copyTemplateToSelected(completeTemplate, callback);
                });
                return;
            }
            _copyTemplateToSelected(template, callback);
            return;
        }
        if (!template) {
            if (!possibleId || isNaN(possibleId)) {
                const query = { name: ee.tools.decodeBase64Url(templateIdOrName), ispublic: ispublic}
                EE_API.Template.LoadTemplate(query).then((resp)=>{
                    template = resp;
                
                    ee.api.loadTemplateContent(template, (completeTemplate) => { 
                    _copyTemplateToSelected(completeTemplate, callback);
                });
                return;

                })
              
            } else {
                html.modal.info("Error: Template not found", 'Error', false, function () { ee.goTo("#/" + 'templates/new')})
                return;
            }

        }
    };
    const _setSelectedDraft = function (templateid, callback) {
        ee.data.selectedtemplate = false;
        let template;
        if (ee.data.templatesdraft) {
            template = ee.data.templatesdraft.get('templateid', templateid);
        }
        if (!template) {
            template = { templateid: templateid, ispublic: false, contentLoaded: false, templatescope: 2 };
        }
            ee.api.loadTemplateContent(template, function (completeTemplate) {
                template.contentLoaded = true;
                _copyTemplateToSelected(completeTemplate, callback);
            });
            return;
    };
    var _getCopyTemplate = function (templateIdOrName, ispublic, callback) {
        _setSelectedTemplate(templateIdOrName, ispublic, function () {
            const copyid = ee.data.selectedtemplate.templateid;
            ee.data.selectedtemplate.templateid = 0;
            ee.data.selectedtemplate.templatescope = 0;
            ee.data.selectedtemplate.originaltemplateid = copyid;
            ee.data.selectedtemplate.isdirty = true;
            ee.data.selectedtemplate.ispublic = false;
            ee.data.selectedtemplate.isdefault = false;
            ee.data.selectedtemplate.screenshot = false;
            ee.data.selectedtemplate.screenshotpath = false;
            ee.data.selectedtemplate.name = `${ee.data.selectedtemplate.name}:${moment().format('YYYY-MM-DD HH:mm:ss')}`;
            
            callback();
        });
    };
    const updateSelectedLandingPage = function () {
        if (!ee.data.selectedtemplate.bodyhtml.includes('<meta name="viewport" content="width=device-width, initial-scale=1.0">') && ee.data.selectedtemplate.templateid > 0) {
            ee.data.selectedtemplate.bodyhtml = ee.data.selectedtemplate.bodyhtml.replace('<head>','<head> <meta name="viewport" content="width=device-width, initial-scale=1.0">') 
            EE_API.Template.Update(ee.data.selectedtemplate)
        } else {
            ee.data.selectedtemplate.removeScreenshot = false;
        }
    };
    const _runEditor = function (editor) {
        if(ee.base && window.hj) {
            window.hj('trigger', 'rawhtml');
        }

        if (!editor && ee.data.selectedtemplate.templatetype === 2) {
            editor = 'landingpage';

            let selectedtemplatehtml = ee.data.selectedtemplate.bodyhtml;
            
            let tempHtml = document.createElement("html")
            tempHtml.innerHTML = selectedtemplatehtml
            $(tempHtml).find('input[name="publicaccountid"]').val(ee.data.account.publicaccountid);
            ee.data.selectedtemplate.bodyhtml = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"> ${tempHtml.innerHTML}`;
            if (ee.data.selectedtemplate.templateid > 0) {
                ee.data.selectedtemplate.removeScreenshot = false;
                EE_API.Template.Update(ee.data.selectedtemplate);
            }
            
        }

        if (editor === "landingpage") {
            if(ee.base && window.hj) {
                window.hj('trigger', 'dragdrop');
            }

            ee.editor = ee.editors.etemplate;
            if (!ee.data.selectedtemplate) {
                ee.data.selectedtemplate = ee.editor.getNewEmptyLandingpage();
                EE_API.Template.Save(ee.data.selectedtemplate)
                .then( (response) => {
                    ee.data.selectedtemplate.templateid = response
                    ee.changeHashQuietly(`#/landingpages/edit/${response}`)
                    if (location.hash.includes("/landingpages/edit/")) {
                        popstateListener(window, "popstate", () => {
                            if (location.hash === "#/landingpages/create/landingpage") {
                                ee.goTo('#/landingpages');
                            }
                            delete ee.data.templates;
                        })
                    }
                    return EE_API.Template.LoadTemplate({ templateid: ee.data.selectedtemplate.templateid, ispublic: !!ee.data.selectedtemplate.ispublic})
                    .then((response) => {
                        ee.data.selectedtemplate = response;
                        updateSelectedLandingPage();
                        ee.editor.load();
                    })

                })

            } else {
                updateSelectedLandingPage();
                ee.editor.load();
            }
        } else {
            ee.editor = ee.editors.raweditor;
            ee.editor.show();
        }
    };
    /*--- END PRIVATE HELPFUL METHODS ---*/
    /*--- VIEW METHODS ---*/
    const show = function () {
        if (ee.Router.action() === 'new') {
            return ee.initNextGen();
        }
        
        if (!ee.session.can.ViewTemplates) {
            ee.goTo("#/dashboard");
            return;
        }

        ee.data.selectedtemplate = false;
        ee.frame.content.empty();
        if (!ee.data.templates) {
            return ee.api.loadTemplates();
        }
        if (!ee.data.globallandingpagetags) {
            query = {type:1}; //type 1 is for landing page tags
            EE_API.Template.GetTagList(query)
            .then((response) => {
                let data = response;
                ee.data.globallandingpagetags = data.globaltags;
            })
        }
        if (!ee.data.landingpagetags) {
            query = {type:1}; //type 1 is for landing page tags
            EE_API.Template.GetTagList(query)
            .then((response) => {
                const landingpageresponse = response
                ee.data.landingpagetags = new Collection.Data(landingpageresponse.tags);
            })
        }
        
        switch (ee.Router.action()) {
            case 'create':
                if (!ee.session.can.ModifyTemplates) { return ee.goTo("#/dashboard"); }
                ee.logEvent('Landing Page - create new landing page.');
                
                var editorType = ee.Router.parameter('node0');

                if ($.isNumeric(editorType)) { 
                    return _getCopyTemplate(parseInt(editorType, 10), true, _runEditor); 
                } else if (typeof editorType === 'string' && editorType !== 'landingpage') {
                    return _getCopyTemplate(editorType, true, _runEditor);
                }
                return _getCustomDefaultTemplate(editorType);
  
            case 'edit':
                ee.logEvent('Landing Page - edit landing page.');
                if (!ee.session.can.ModifyTemplates) { return ee.goTo("#/dashboard"); }
                const templateIdOrName = ee.Router.parameter("node0");
                return _setSelectedTemplate(templateIdOrName, false, function () {
                    if (ee.Router.parameter('node1') === 'rawhtml') { return  _runEditor('rawhtml'); }
                    return _runEditor();
                });
            default:
                ee.logEvent('Landing Pages screen loaded.');
                showLandingpages(ee.Router.action());
                break;
        }
    };
    const showLandingpages = function (startTab) {
        _generateLandingPageScreen(false, startTab)
        refreshScreen();
        
        $screen.appendTo(ee.frame.content);
        /*--- BIND OPTIONS LIST ---*/
        $screen.on('click', '.action-options', function (e) {
            e.stopPropagation();
            $(this).closest('.edeadcenter').addClass('active')
        });
        $('#epaginationresult').on('click', '.templateinside', function (e) {
            e.stopPropagation();
            const type = $screen.templatetypetabs.find('li.active').data('type') || "";
            
            if (type === 'gallery' || type === 'parent') {
                ee.goTo("#/landingpages/create/" + $(this).closest('.gallery-item').data('templateid'));
            } else {
                ee.goTo('#/landingpages/edit/' + $(this).closest('.gallery-item').data('templateid'));
            }
        })
        

        $screen.on('mouseleave', '.gallery-item', function () {
            $(this).find('.edeadcenter').removeClass('active');
        });

        $screen.on('click', '.gallery-container', function (e) {
            e.stopPropagation();
            $(this).find('.edeadcenter.active').removeClass('active');
        });

        /*-- EVENTS FROM LIST --*/
        //Refresh button
        if ($screen.refresh) {
            $screen.refresh.off().click(() => {
                refreshScreen();
                $('#sortbytitlebutton').text(ee.t.newest);
            });
        }

        //Landing Page tags
        $ ( () => {
            let i;
            let tags;

            if ($('#etemplatetypetabs').find('li[data-type=gallery]')["0"].className == 'active') {
                if (ee.data.globallandingpagetags) {
                    tags = ee.data.globallandingpagetags;
                } else {
                    tags = [];
                }
                
            } else {
                $('#selecttemplatetags').find('span').remove();
                if (ee.data.landingpagetags) {
                    tags = ee.data.landingpagetags.data;
                } else {
                    tags = [];
                }
                
            }

            for (i = 0; i < tags.length; i++) {
                let tag = tags[i].name;
            $('#selecttemplatetags').append($('<span>', {
                value: tag,
                text: tag,
                class: 'elem_tags'
            }),'<span>&nbsp;</span>');
        }
        });
        //sorting templates
        if (!location.hash.includes('gallery')) {
            const $currTypeTab = $('#etemplatetypetabs li.active');
            const currType = $currTypeTab.data("type");
            const currTemplateType = parseTemplateTypeToFetchedNames(currType);
            ee.api.sortTemplatesBy(currTemplateType, pagination, filterTemplates, $screen);
        }

        $('#templateTags').on('click', () => {
            refreshScreen();
            $('#templateTags').toggleClass('tagslist');
            $('#categoryselect').toggle();

        })
        let showLandingPagesWithSelectedTags = (e) => {
            let selectedtag;
            let tagName = $(e.target)["0"].innerText;
            if (!e.target.attributes.value) {
                if (!e.target.parentElement.attributes.value) {
                    return;
                }
                selectedtag = e.target.parentElement.attributes.value.value;
                $(e.target.parentElement).toggleClass('selected');
                tagName = $(e.target.parentElement)["0"].innerText;
                $(e.target.parentElement)["0"].innerHTML = tagName;
            } else {
                selectedtag = e.target.attributes.value.value;
                $(e.target).toggleClass('selected');
            }

            if ($(e.target).hasClass('selected')) {
                $(e.target)["0"].innerHTML = `<i class="fas fa-check check-padding"></i>${tagName}`;
                selectedTagsArray.push(selectedtag)
            } else {
                $(e.target)["0"].innerHTML = tagName;
                _.remove(selectedTagsArray, (elementtoremove) => {
                    return elementtoremove === selectedtag;
                });
            }
            const noTemplatesWithTag = () => {
                ee.t.dynamicnotemplates = ee.t.desclistnotfound;
            }
            if ($('#etemplatetypetabs').find('li[data-type=gallery]')["0"].className == 'active') {
                let landingpageglobal = ee.data.templatesglobal.filter('templatetype', 2, false, false, true);
                let filter = landingpageglobal.filter('tags', selectedTagsArray, false, false, true);

                if (selectedTagsArray.length === 0) {
                    pagination.refresh();
                    pagination.updateData(landingpageglobal.getAll());
                    html.widget.TemplateThumbnail.init($screen);
                } else {
                    noTemplatesWithTag();
                    pagination.refresh();
                    pagination.updateData(filter.getAll());
                    html.widget.TemplateThumbnail.init($screen);
                }
            } else if ($('#etemplatetypetabs').find('li[data-type=drafts]')["0"].className == 'active') {
                let landingpagesdraft = ee.data.templatesdraft.filter('templatetype', 2, false, false, true);
                let filter = landingpagesdraft.filter('tags', selectedTagsArray, false, false, true);
                if (selectedTagsArray.length === 0) {
                    pagination.refresh();
                    pagination.updateData(landingpagesdraft.getAll());
                    html.widget.TemplateThumbnail.init($screen);
                } else {
                    noTemplatesWithTag();
                    pagination.refresh();
                    pagination.updateData(filter.getAll());
                    html.widget.TemplateThumbnail.init($screen);
                }

            } else {
                let landingpages = ee.data.templates.filter('templatetype', 2, false, false, true);
                let filter = landingpages.filter('tags', selectedTagsArray, false, false, true);

                if (selectedTagsArray.length === 0) {
                    pagination.refresh();
                    pagination.updateData(landingpages.getAll());
                    html.widget.TemplateThumbnail.init($screen);
                } else {
                    noTemplatesWithTag();
                    pagination.refresh();
                    pagination.updateData(filter.getAll());
                    html.widget.TemplateThumbnail.init($screen);


                }
            }
        }
        let selectedTagsArray = [];
        $('#selecttemplatetags').click((e) => {
            showLandingPagesWithSelectedTags(e);
        });
        $('#assignedTagsDiv').click((e) => {
            showLandingPagesWithSelectedTags(e);
        });
        let tagsToDelete = [];
        const deleteGlobalTags = () => {
            $('.icontodelete').on('click', (e) => {
                const selectedTag = e.target;
                const tagToDelete = selectedTag.textContent;

                tagsToDelete.push(tagToDelete)
                $(() => {
                    $(selectedTag).next().remove();
                    $(selectedTag).remove()
                })
                refreshTags();
            })

            const refreshTags = () => {
                window.onbeforeunload = saveTagsBeforeExit;
                const saveTagsBeforeExit = () => {
                    tagsToDelete.forEach(element => {
                        query = {tag: element, type: 1};
                        EE_API.Template.DeleteTag(query)
                        .then(() => {
                            const data = response;
                            ee.data.deletedtag = element;
                        })
                    });
                }
                window.addEventListener('hashchange', (e) => {
                    saveTagsBeforeExit();
                    location.reload();
                }, {once: true});

                $('#manageTagsInTemplates').on('click', (e) => {
                    saveTagsBeforeExit();
                    location.reload();
                })
            }

        }
        $('#manageTagsInTemplates').on('click', () => {
            if (ee.data.landingpagetags.data.length != 0 && !($('#etemplatetypetabs').find('li[data-type=gallery]')["0"].className == 'active')) {
                $("#tagsToManage").toggle()
                $('#selecttemplatetags').toggle();
                $('#assignedTagsDiv').empty()
                $('#unassignedTagsDiv').empty();
            }

            $('#manageTagsInTemplates').find('i').toggleClass('fa-chevron-down fa-chevron-up');


            let templates = ee.data.templates.data;
            let allTags = ee.data.landingpagetags.data;
            let i;
            let t;

                for (t = 0; t < allTags.length; t++) {
                    let tagToFind = allTags[t].name;
                    for (i = 0; i < templates.length; i++) {
                        let templateToSearch = ee.data.templates.data[i].tags;

                        if (templateToSearch.indexOf(tagToFind) === -1) {
                            let tag = allTags[t].name;
                            if (i === (templates.length - 1)) {
                                $('#unassignedTagsDiv').append($('<span>', {
                                    value: tag,
                                    text: tag,
                                    class: 'elem_tags icontodelete',
                                    id: 'deleteTag'+ t
                                }), '<span>&nbsp;</span>');
                            }

                        } else {
                            let tag = allTags[t].name;
                            $('#assignedTagsDiv').append($('<span>', {
                                value: tag,
                                text: tag,
                                class: 'elem_tags'
                            }), '<span>&nbsp;</span>');
                            break;
                        }

                    }
            }
            deleteGlobalTags();
        })
            
        
    };
    var saveWithScreenshot = function (template, $iframe) {

        var defaultOpt = {
            target: '.gallery-item',
            height: 850,
            width: 1179,
            background: '#E5E4EC',
            format: 'image/jpeg',
            quality: 0.3,
            canvasheight: false,
            canvaswidth: false
        }, templatedata = false;
        //generate screenshot from iframe available in editor = #dtemplateframe or #erawpreview
        
        var apirequest = template.templateid ? EE_API.Template.Update(template) : EE_API.Template.Save(template);
        var campaigncreator = window.location.hash.indexOf("campaigncreator") != -1;
        const hash = window.location.hash;
        const isRawHtmlEditorOpened = (hash.includes('templates/create') || hash.includes('templates/edit'));
        

        return apirequest
        .then(function(response) {
            //ee.indiOn();
            $('#templateChanges').text(ee.t.saving);
            if (campaigncreator) {
                $('.return-button').attr('disabled', true).css({'opacity': 0.8, 'cursor': 'not-allowed'})
            }
            //ee.api.loadTemplates(function(){}, true); //reload
            if (typeof(response) == "number") {
                templatedata = response;
            }
            var promise = $.Deferred();
            
            let result;
            
            if (!template.bodyhtml) {
                result = html.widget.Thumbnail.getCanvasFromIframe($iframe, defaultOpt, promise)
            } else {
                result = html.widget.Thumbnail.generate(template.bodyhtml, defaultOpt)
            }
            
            return result;
        }).then(function(image) {
            return EE_API.Template.SaveScreenshot({ base64image: image.split(',')[1], templateid: template.templateid > 0 ? template.templateid : templatedata, templateName: template.name });
        })
        .then(function (result) {
            if (!campaigncreator) { //reload templates if there is a anew one and not in campaigncreator
                delete ee.data.templates;
            }
            ee.data.selectedtemplate.isdirty = false;
            var templateid = template.templateid > 0 ? template.templateid : templatedata;
            if (ee.data.selectedtemplate.templateid === 0) {
                template.templateid = templateid;
                template.isdefault = false;
                EE_API.Template.Update(template)
            }
            
            $('#templateChanges').text(ee.t.allchangessaved);
            
            if (!isRawHtmlEditorOpened) { that.refreshScreen(); }
            if (templatedata) { //only D&D editor has EXITEDITOR method
                ee.editor.exitEditor ? ee.editor.exitEditor({},  `${ee.Router.controller()}/edit/${templatedata}`) : ee.changeHashQuietly('#/' + `${ee.Router.controller()}/edit/${templatedata}`);
                let popstateListener = (element, event, listener) => {
                    element.addEventListener(event, function popstateListenerEvent() {
                        element.removeEventListener(event, popstateListenerEvent);
                        return listener();
                    });

                }
                if (location.hash.includes("/landingpages/edit/")) {
                    popstateListener(window, "popstate", function () {
                        if (location.hash === "#/landingpages/create/landingpage") {
                        ee.goTo('#/landingpages');
                        }
                    })
                }
            }
            //refreshScreen();
            ee.data.selectedtemplate.isdirty = false;
            
        })
        .catch(function(msg) {
            console.log("EE: saveWithScreenshot -> msg", msg)
            console.log('Error: ' + msg.error ? msg.error : msg);
            if(msg instanceof Error) {
                ee.captureStripeException(msg);
            } else {
                ee.captureStripeMsg(msg.error ? msg.error : msg);
            }
        });
    };
    const filterTemplates = function (templatesArray) {
        return _.filter(templatesArray, function (template) {
            let isCorrect = false;
            // For each given template
            _.forEach(ee.templateFilters, function (filter) {
                // Check if its templatetype is one of checked filters
                if (filter === template.templatetype) {
                    isCorrect = true;
                }
            });
            return isCorrect;
        });
    };
    const setGlobalDefaultOptions = function(template) {
        if (template.templateid > 0) {
            if (template.templatescope === 2) {
                ee.data.templatesdraft.replace('templateid', template.templateid, template);
            } else {
                if (template.isdefault === true) { 
                    template.originaltemplateid = (template.originaltemplateid === 0) ? -1 : Math.abs(template.originaltemplateid) * -1

                    ee.data.templates.each(function () {
                        if (this.isdefault && this.templateid !== template.templateid && this.templatetype == template.templatetype) {
                            this.isdefault = false;
                            this.originaltemplateid = Math.abs(this.originaltemplateid);
                        }
                    });
                }
                ee.data.templates.replace('templateid', template.templateid, template);
            }
        } else {
            if (template.templatescope === 2) {
                if (ee.data.templatesdraft) { ee.data.templatesdraft.add("unshift", template); }
            } else {
                if (ee.data.templates) { ee.data.templates.add("unshift", template); }
                ee.data.selectedtemplate = template;
            }
        }
        return template;

    }
    const refreshScreen = function () {
        delete ee.data.channels, ee.data.templates;
        
        pagination.get$Container().html(html.getraw('TemplateListLoading', {}));
        ee.api.loadTemplates(function () {
            const type = $('#etemplatetypetabs').find('li.active').data('type') || "";
            let companyname;
            if (type === 'gallery') {
                ee.t.dynamicnotemplates = ee.t.notemplatesglobal;
                pagination.updateData(filterTemplates(ee.data.templatesglobal.getAll()));
            } else if (type === 'drafts') {
                ee.t.dynamicnotemplates = ee.t.notemplatedrafts;
                pagination.updateData(filterTemplates(ee.data.templatesdraft.getAll()));
            } else if (type === 'parent') {
                ee.data.account.company ? companyname = ee.data.account.company : companyname = ee.t.company;
                ee.t.dynamicnotemplates = `<h4> ${companyname} ${ee.t.notemplateparents}<br/>&nbsp; </h4>`;
                pagination.updateData(filterTemplates(ee.data.templatesparent.getAll()));
            } else {
                ee.t.dynamicnotemplates = ee.t.notemplates;
                pagination.updateData(filterTemplates(ee.data.templates.getAll()));
            }
            $screen.searchinputtemplates.val('');
            html.widget.TemplateThumbnail.init($screen);
        }, true);
    };
    that.show = show;
    that.getTemplate = _getTemplate;
    that.generateLandingPageScreen = _generateLandingPageScreen;
    that.findBrokenImageInFrame = _findBrokenImageInFrame;
    that.previewContent = _previewContent;
    that.getCopyTemplate = _getCopyTemplate;
    that.setSelectedTemplate = _setSelectedTemplate;
    that.setSelectedDraft = _setSelectedDraft;
    that.refreshScreen = refreshScreen;
    that.setGlobalDefaultOptions = setGlobalDefaultOptions;
    that.saveWithScreenshot = saveWithScreenshot;
    that.pagination = pagination;
    return that;
})();
