
ee.views["campaigncreator"] = (function ViewCampaignCreator() {
    var that = {}; //object to return
    const MEDIA_MANAGER_DELETED_ID = '0';
    /*-- Private Property --*/
    var screen,
        templatescount = 0,
        defaultChannelCampaign = { channelid: -1, isEditable: true, name: "", targets: [], status: 0, campaigntemplates: [], triggertype: 1, triggercount: 1, triggerdelay: 0, triggerfrequency: 0, triggerdata: null, triggerchannelid: 0, splitoptimizationminutes: 1, triggerdate: "", timingoption: 1 },
        defaultChannelTemplate = { channelid: -1, templateid: -1, status: 0, mailerid: null, poolname: null, templatesubject: "", templatefromemail: "", templatefromname: "", templatereplyemail: "", templatereplyname: "", templateattachments: [] },
        isDirty = false,
        ignoreNotriggerdelayClick = false,
        selectedCampaign,
        frameoverlay = false,
        segmentsselected = 0,
        triggerselected = false;
    /*-- Private methods --*/
    var checkEnableIPpools = function () {
        return (!ee.data.account.issub || (ee.data.account.issub && ee.data.account.enableprivateiprequest)) && ee.data.account.mailerid > 0;
    };

    var _isDirty = function() {
        return isDirty;
    }

    var _isDataCampaignReady = function () {
        if (!ee.data.contactgroups) {
            ee.api.loadContactOverview();
            return;
        }
        if (!ee.data.templates) {
            ee.api.loadTemplates();
            return false;
        }
        if (!ee.data.channels) {
            ee.api.loadcampaigns();
            return false;
        }
        if (ee.data.account.enablebounceshandling === true && !ee.data.validemails) {
            dataModel.create('ValidEmails').require().then(function () {
                ee.view.show();
            });
            return false;
        }
        //pirvate ip pools
        if (checkEnableIPpools() && !ee.data.account.pools) {
            ee.api.loadPools();
            return false;
        }
        return true;
    };

    /*-- AUDIENCE EVENTS --*/
    var _bindEventTo = function () {
        var $items = $('#esupertolist').find('.superto-list-items:first'), allLi = $items.find(".row"),
            allLists = new Collection.Data(allLi.map(function (obj) {
                var $li = $(this);
                return { id: $li.attr("data-value"), name: $li.attr("data-name"), $li: $li };
            }).get()),
            selected = [],
            delayTimer = false,
            _toggleList = function (mode, delayTime) {
                if (!delayTime && delayTime < 0) delayTime = 200;
                allLi.filter(".selected").removeClass("selected");
                if (mode === "show") {
                    $('#esupertolist').slideDown(delayTime);
                    screen.superlisttoggleicon.addClass("rotate180");
                    return;
                }
                if (mode === "hide") {
                    $('#esupertolist').slideUp(delayTime);
                    screen.superlisttoggleicon.removeClass("rotate180");
                    return;
                }
            },
            _filterList = function (searchString) {
                allLi.hide();
                var showed = 0;
                $('#esupertolistheader').show();
                $('#esupertolistnoresult').hide();
                var triggertype = parseInt(screen.triggertype.val(), 10);
                allLists.filter(["name"], searchString).each(function () {
                    if (selected.indexOf(this.id) === -1) {
                        showed++;
                        this.$li.show();
                    }
                });
                if (showed === 0) {
                    $('#esupertolistheader').hide();
                    $('#esupertolistnoresult').show();
                }
            };

        //Show/hide list
        $('#esuperto').on("click", ".superto-input-toggle", function (e) {
            e.stopPropagation();
            $('#esupertoinput').val("")
            if ($('#esupertolist') && $('#esupertolist').css("display") === "block") {
                _toggleList('hide');
                $('#esupertoinput').trigger("blur");
            } else {
                _filterList("");
                _toggleList('show');
                $('#esupertoinput').trigger("focus");
            }

        });

        //Hide list when clicked outside of lists panel
        $('body').on('click', function () {
            $('.panel:first-of-type').on('click', function (e) {
                e.stopPropagation();
            })
            if ($('#esuperto') && $('#esupertolist').css("display") === "block") {
                _toggleList('hide');
                $('#esupertoinput').trigger("blur");
            }
        })

        //Add Audience
        $items.on("click", ".row", function (event, skipcount, skipdirty) {
            event.stopPropagation();
            var $li = $(this), id = $li.data("value"),
                $to = html.get("CampaignCreatorSelectedTo", { value: id, name: $li.data("name"), type: $li.data('type') });
            if ($li.hasClass('disabled')) return;
            if (id === "s0") {
                //All contacts
                $('#esuperto').find('span').remove();
                selected = [];
                allLi.show();
            }
            if (id !== "s0" && id.indexOf("s") > -1) {
                segmentsselected++;
            }
            //Normal behavior
            selected.push(id);
            $li.hide();
            if ($('#esuperto').find("span[data-value=s0]").length === 1) {
                $('#esuperto').find("span[data-value=s0] .action-removeto").trigger('click');
            }
            if ($('#esupertoinput').val() !== "") {
                $('#esupertoinput').val("");
                _toggleList('hide', 0);
            }
            $('#esupertoinput').before($to);
            $('#esupertoinput').trigger("resize.input");
            $('#esuperto').data("changed", "true");
            if (!skipcount) {
                _countAudiences();
                if (!skipdirty) {
                    _dirty()
                }
            }
            _resolveAudienceTriggerConflict();
        });
        //Remove Audience
        $('#esuperto').on("click", ".action-removeto", function () {
            var $span = $(this).closest("span"), value = $span.data("value");
            $('#esupertolist').find("li[data-value=" + value + "]").show();
            $span.remove();
            $('#esupertoinput').trigger("resize.input");
            selected.splice(selected.indexOf(value), 1);
            var item = allLists.get('id', value);
            if (item) {
                item.$li.show();
            }
            if (value !== "s0" && value.indexOf("s") > -1) {
                segmentsselected--;
                _resolveAudienceTriggerConflict();
            }
            $('#esuperto').data("changed", "true");
            _countAudiences();
            _dirty();
        });

        //Resize fake input
        $('#esupertoinput').on("resize.input", function () {
            const lastSpan = $('#esuperto').find(".superto-input > span:last");
            const maxWidth = $('#esuperto').find('.superto-input').width();
            let offset;

            if (document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl") {
                offset = (lastSpan.length === 1) ? lastSpan.width() + 70 : 0; //70 - parent's + span's + input's paddings
            } else {
                offset = (lastSpan.length === 1) ? lastSpan.position().left + lastSpan.outerWidth(true) : 24;
            }

            const newWidth = maxWidth - offset;
            
            if (!html.isMobile()) {
                if (newWidth < 120) {
                    $(this).css("width", maxWidth);
                } else {
                    $(this).css("width", newWidth)
                }
            }
        });

        $('#countAudience').click(_loadContactsCount);

        if(ee.Router.controller() === 'campaigncreator' && ee.Router.action() !== 'show' && ee.Router.action() !== 'template') {
            if(selectedCampaign.trackclicks) {
                $('#etrackclicks').prop('checked', true);
            }
            if(selectedCampaign.sendstats) {
                $('#esendstats').prop('checked', true);
            }
            if(selectedCampaign.trackopens) {
                $('#etrackopens').prop('checked', true);
            }
        } else {
            $('#etrackclicks').prop('checked', true);
            $('#etrackopens').prop('checked', true);
            $('#esendstats').prop('checked', true);
        }
        screen.accordion.on('change', "input[name=fromemail]", function () {
            $('#validemailadress').hide();
            $(this).css('border', '0.1rem solid #CFD6DB');
        });

        //Search with autosugestion
        $('#esupertoinput').on("keyup", function (e) {
            clearTimeout(delayTimer);
            //wait 300 ms
            if (e.keyCode === 13) {
                return;
            }
            setTimeout(function () {
                var searchString = $('#esupertoinput').val().trim();
                if (searchString === "") {
                    if ($('#esupertolist') && $('#esupertolist').css("display") === "block") {
                        allLi.hide();
                        _toggleList('hide', 0);
                    }
                    return;
                }
                _filterList(searchString);
                $('#euploadcontactbutton').hide();
                if ($('#esupertolist') && $('#esupertolist').css("display") === "none") {
                    _toggleList('show', 0);
                }
            }, 300);
        });
        $('#esupertoinput').focus(function () { $('#esuperto').addClass("superto-focus") });
        $('#esupertoinput').blur(function () { $('#esuperto').removeClass("superto-focus"); });
        //navigate by results list
        $(window).on("keydown", function (e) {
            //up
            if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
                return;
            }
            var current = allLi.filter(".selected:first"), next = false;
            //Enter (selected)
            if (e.keyCode === 13) {
                if (current.length !== 0) {
                    current.trigger("click");
                }
                else if (allLi.filter(":visible").length === 1) {
                    allLi.filter(":visible:first").trigger("click");
                }
                return;
            }
            //Key arrow Up
            if (e.keyCode === 38) {
                next = current.prevAll(':visible:first');
                if (current.length === 0 || next.length === 0) {
                    next = allLi.filter(":visible:last");
                }
            }
            //Key Arrow down
            if (e.keyCode === 40) {
                next = current.nextAll(':visible:first');
                if (current.length === 0 || next.length === 0) {
                    next = allLi.filter(":visible:first");

                }
            }
            current.removeClass("selected");
            next.addClass("selected");
        });
    };
    var countRequest;
    var countSendable;

    var _loadContactsCount = () => {
        const rules = _getRules();
        $('#audienceTooltip').hide();
        if(_.isEmpty(rules)) {
            $('#eaudience').html(`0 ${ee.t.contacts}`);
            return;
        }

        $('#emainbutton').addClass("disabled");
        $('#eaudience').html('<i class="fa fa-spinner fa-pulse fa-fw on-right" style="float: none;font-size:16px;"></i>');
        $('#countAudience').attr('disabled', true);
        
        return _countActive(rules)
        .then((resp) => {
            const count = (_.isInteger(resp)) ? resp : resp.data;
            $('#eaudience').html(count.numberFormat() + " " + ee.t.contacts);
            !!count && $('#audienceTooltip').show();
            $('#esuperto').data("changed", "false");
            countRequest = false;
            $('#countAudience').attr('disabled', false);
            _isSendButtonDisabled();
        })
        .catch((err) => {
            $('#esuperto').data("changed", "false");
            countRequest = false;
            $('#countAudience').attr('disabled', false);
            $('#eaudience').html('');
            $('#audienceTooltip').hide();
            _isSendButtonDisabled();
        })
    };

    var _getRules = () => {
        const rules = [];

        $('#esuperto').find("span").each(function() {
            const id = this.dataset.value;
            let selectedSet;

            if (id === "s0") {
                rules.push("");
                return
            }
            if (id[0] !== "") {
                if (id[0] === "s") {
                    selectedSet = ee.data.contactgroups.get('segmentid', parseInt(id.substr(1), 10));
                } else if (id[0] === "l") {
                    selectedSet = ee.data.contactgroups.get('listid', parseInt(id.substr(1), 10));
                }
                if (selectedSet) {
                    rules.push(selectedSet.rule.trim());
                }
            }
        });

        return rules;
    };

    var _countAudiences = function () {
        if (countRequest) {
            countRequest.abort();
            countRequest = false;
        }
        var rules = [], totalCount = 0, spanCount = 0;
        //TODO: We should using data collection
        $('#esuperto').find("span").each(function () {
            spanCount++;
            var id = this.dataset.value, selectedset = false;
            if (id === "s0") {
                rules = [""];
                return false;
            }
            if (id[0] !== "") {
                if (id[0] === "s") {
                    selectedset = ee.data.contactgroups.get('segmentid', parseInt(id.substr(1), 10));
                } else if (id[0] === "l") {
                    selectedset = ee.data.contactgroups.get('listid', parseInt(id.substr(1), 10));
                }
                if (selectedset) {
                    rules.push(selectedset.rule.trim());
                }
            }
        });
        if (spanCount > 0) {
            $('#emainbutton').addClass("disabled");
            $('#esuperto').data("changed", "false");
            _isSendButtonDisabled();

            /*countRequest = ee.api.getContactCounts({}, (rules.length === 1 && rules[0] === "") ? '' : '(' + rules.join(") OR (") + ')', function (response) {
                screen.audience.html(response.sendable.numberFormat() + " " + ee.t.contacts);
                $('#esuperto').data("changed", "false");
                countRequest = false;
                countSendable = response.sendable;
                _isSendButtonDisabled();
            }, true);
            */

        } else {
            _isSendButtonDisabled();
            $('#eaudience').html("");
            $('#audienceTooltip').hide();
        }
        return spanCount;
    };

    var _countActive = function(rules) {

        var rule = (rules.length === 1 && rules[0] === "") ? null : '(' + rules.join(") OR (") + ')';
        var query = (rule) ? { rule: rule } : {};

        return EE_API.Contact.CountActive(query)
        .then(function(data){
            return data;
        });

    };
    var _resolveAudienceTriggerConflict = function () {
        $('#etriggertype').find('option').prop('disabled', false).prop("title", "");
        $('#esupertolist').find('.superto-list-items .row').removeClass('disabled').prop("title", "");

        if (segmentsselected) {
            var triggers = $('#etriggertype').find('option');
            triggers.each(function () {
                if ($(this).val() >= 3)
                    $(this).prop("disabled", true).prop("title", ee.t.unabletotriggerforsegments);
            })
            return;
        }
        if (triggerselected) {
            var rows = $('#esupertolist').find('.superto-list-items .row');
            rows.each(function () {
                if ($(this).data("value").indexOf("s") > -1 && $(this).data("value") !== "s0")
                    $(this).addClass("disabled").prop("title", ee.t.unabletotriggerforsegments);
            })
            return;
        }
        $('[data-toggle="tooltip"]').tooltip();
    };
    var _disabledSupperTo = function () {
        if (ee.data.account.enablecontactfeatures === false || selectedCampaign.isEditable === false) {
            $('#esupertoinput').remove();
            $('#esuperlisttoggle').off();
            $('#esuperto').find(".action-removeto").remove();
            $('#esuperto').addClass("superto-disabled").removeClass("superto-focus  ");
            $('#esuperlisttoggleicon').hide();
            $('#esupertolist').remove();
            return true;
        }
        return false;
    };
    /*-- TEMPLATE EVENTS --*/
    var _mergeTemplate2Channel = function () {
        ee.data.selectedChannelTemplate.templatesubject = ee.data.selectedtemplate.subject;
        ee.data.selectedChannelTemplate.templatefromemail = ee.data.selectedChannelTemplate.templatereplyemail = ee.data.selectedtemplate.fromemail;
        ee.data.selectedChannelTemplate.templatefromname = ee.data.selectedChannelTemplate.templatereplyname = ee.data.selectedtemplate.fromname;
        ee.data.selectedtemplate.name = `${ee.t.campaign}: ${ee.api.formatDate(new Date(), "yy-mm-dd hh:ii:ss")}`;
    };
    var _templateSetDefault = function () {
        var template = ee.data.selectedtemplate; var account = ee.data.account;
        if (template.fromemail === "") {
            template.fromemail = account.email;
        }
        if (!template.fromname) {
            if(!account.firstname && !account.lastname && account.company) {
                template.fromname = account.company;
            } else if (account.firstname && account.lastname) {
                template.fromname = (account.firstname + " " + account.lastname);
            } else if (!account.firstname && account.lastname) {
                template.fromname = (account.lastname);
            } else if (account.firstname && !account.lastname) {
                template.fromname = (account.firstname);
            }
            //template.fromname = ((!account.firstname && !account.lastname && account.company) ? account.company : account.firstname + " " + account.lastname).trim();
        }
        if (template.subject === "") {
            template.subject = template.name;
        }
    };

    const _goToWithLegacyMode = function(url) {
        if (ee.parameters.legacyMode) {
          top.window.location.replace(url);
          return;
        }
      ee.goTo(url)
    }

    var _bindChannelTemplateTabs = function (channelTemplate) {
        if (screen.addtemplatetab) {
            screen.addtemplatetab.on("click", function () {
                _addNewChannelTemplateTab();
            });
        }
        //Select Subject / content
        screen.templatetabscontent.on('click', '.action-contentselect', function () {
            var mode = $(this).data('target');
            var $tabpane = $(this).closest('.tab-pane');

            ee.data.selectedChannelTemplate = $.extend(true, {}, defaultChannelTemplate);
            if (mode === "gallery") {
                var $fragment = ee.views.templates.generateTemplateScreen(true, false, true);

                $fragment.on('click', '.gallery-item .btn', function () {
                    var $this = $(this);
                    var $item = $this.closest('.gallery-item');

                    if ($this.parent().parent().hasClass('clicked')) {

                        var templateid = parseInt($item.data('templateid'), 10);
                        const templateName = $item.data('templatename');
                        var ispublic = $item.data('ispublic');
                        ee.views.templates.getCopyTemplate(templateid, ispublic, function () {
                            if (!ee.data.selectedtemplate) {
                                html.modal.info("Error: Template not found", 'Error', false);
                                return;
                            }
                            _templateSetDefault();
                            _mergeTemplate2Channel();
                        
                            if ($this.data('target') === 'select') {
                                _saveDraft(false);
                                $("#utmscheckedcheckbox").css("display", "none");
                            } else if ($this.data('target') === 'preview'){
                                let $galleryitem = $this.closest('.gallery-item');
                                ee.views.templates.previewContent($galleryitem.data('templateid'), $galleryitem.data('ispublic'))
                            } else {
                                //Enable Edit and pre-save draft template
                                _saveDraft(true);
                                ee.data.selectedtemplate.isdirty = false;
                                _showChannelTemplateEditor();
                            }
                        });
                    }
                });

                $fragment.on('mouseleave', '.gallery-item .eover', (e) => {
                    $(e.target.closest('.eover')).removeClass('clicked')
                })
                
                $fragment.on('mouseenter', '.gallery-item .eover', (e) => {
                    if ($(e.target.closest('.eover')).hasClass('clicked')) {
                        return;
                    } else {
                        if ($(e.target).hasClass('btn-circle')) {
                            setTimeout(() => {
                                $(e.target.closest('.eover')).addClass('clicked')
                            }, 100)
                        } else {
                            $(e.target.closest('.eover')).addClass('clicked')
                        }
                    }
                })

                frameoverlay = new html.Frameoverlay($fragment).on('afterClose', function () { frameoverlay = false; });
            } else {

              

                //Create New Template and Save As Draft
                ee.data.selectedtemplate = (mode === 'dragdrop') ? ee.editors.etemplate.getNewEmptyTemplate() : ee.editors.raweditor.getNewEmptyTemplate();

                _templateSetDefault();
                _mergeTemplate2Channel();
             
                //Editor
                _showChannelTemplateEditor();
            }
        });
        //Edit Template
        screen.templatetabscontent.on('click', '.action-edit', function () {
            var $tabpane = $(this).closest('.tab-pane');
            $tabpane.find('.savedToTemplates').text(``)
            $tabpane.find(".addtomytemplates-button").prop('disabled', false);
            var $this = $(this);
            var $tab = $this.closest('.tab-pane');
            const templateID = $tab.find('.gallery-item').data('originaltemplateid');
            const templateName = ee.data?.templates?.data?.find(el => el.templateid === templateID)?.name;

            ee.data.selectedChannelTemplate = _collectChannelTemplate($tab);
            ee.views.templates.setSelectedDraft(ee.data.selectedChannelTemplate.templateid, () => _showChannelTemplateEditor());
            return;
        });

        //Send Test message
        screen.templatetabscontent.on('click', '.action-sendtest', function () {
            var poolname = checkEnableIPpools() ? $(this).closest('.tab-pane').find('.poolnameinput').val() : null;
            var channelTemplate = _collectChannelTemplate($(this).closest('.tab-pane'));
            var storageOBJ = ( !(_.isNil(window.localStorage)) && window.localStorage['SendTestEmail']) ? JSON.parse(window.localStorage['SendTestEmail']) : {};
            var email = ee.data.account.email;
            var to = "";
            var fromemail = channelTemplate.templatefromemail || email;

            if (storageOBJ.to) {
                to = storageOBJ.to
            } else {
                to = email;
            }
            if (!ee.data.account.accountaccesslevel.includes('SendHttp')) {
                html.modal.info(ee.t.sendtestwithoutaccesslevel, ee.t.warning);
                return;
            }
            if (channelTemplate.templatefromname) {
                fromemail = '"' + channelTemplate.templatefromname + '" <' + fromemail + '>';
            }
            var win = html.get("SendTest", { fromemail: fromemail, subject: channelTemplate.templatesubject, to: to, mobile: html.isMobile() });
            var modal = html.modal.create(win, {
                title: ee.t.sendtestemail, buttons: [{
                    name: ee.t.send,
                    css: 'btn_primary btn_md',
                    callback: function () {
                        ee.indiOn();
                        const data = {
                            api_key: ee.session.apikey(),
                            template: channelTemplate.templateid,
                            to: win.toemail.val(),
                            from: win.fromemail.val(),
                            reply_to: channelTemplate.templatereplyemail,
                            reply_to_name: channelTemplate.templatereplyname,
                            subject: win.subject.val(),
                            encodingtype: ee.data.account.contenttransferencoding,
                            fromdraft: true,
                            poolname,
                            channel: "Test Mail",
                            trackClicks: $('#etrackclicks')?.[0]?.checked ?? true,
                            trackOpens: $('#etrackopens')?.[0]?.checked ?? true,
                            sendStats:  $('#esendstats')?.[0]?.checked,
                        };

                        if (channelTemplate.templateattachments.length) {
                            data.attachments = "";
                            $.each(channelTemplate.templateattachments, function (i, v) {
                                data.attachments += ";" + v.filename;
                            });
                            data.attachments = data.attachments.substr(1);
                        }

                        const parsedEmails = (win.toemail.val().split(';')).map(email => {
                            if (/<.+>/.test(email)) {
                                const firstBracket = email?.indexOf('<');
                                const lastBracket = email?.indexOf('>');
                                return email?.slice?.(firstBracket+1,lastBracket);
                            }
                            return _.trim(email);
                        });
                        const emails = parsedEmails.filter(email => email);
                        const isEmailValid = _.every(emails, email => ee.tools.isEmail(email));

                        if (isEmailValid) {
                            const contactsToLoad = [];
                            const invalidContacts = [];

                            emails.forEach(email => {
                                contactsToLoad.push(EE_API.Contact.LoadContact({ email }, true).then((resp) => {
                                    const { status } = resp;
                                    if (status > 0) {
                                        invalidContacts.push(email);
                                    }
                                }).catch((rej) => {
                                    if (!rej.error.includes('Could not find contact:')) {
                                        console.error(rej.error);
                                        
                                    }
                                    invalidContacts.push(email);
                                }));
                            });

                            Promise.all(contactsToLoad).then(() => {
                                if (_.isEmpty(invalidContacts) || !ee.data.account?.billingdetails?.activeplan?.typedesc?.includes("ContactSubscription")) {
                                    //temporary fix, we need adding using userfile as attachment in campaign creator
                                    ee.api.send(data);
                                    if (!_.isNil(window.localStorage)) {
                                        const localData = { to: data.to };
                                        store.local.set('SendTestEmail', localData);
                                    };
                                    modal.close();
                                } else {
                                    ee.indiOff();
                                    win.find("#ecannotsend").show();
                                    win.find("#ehelpinfo").hide();
                                    setErrorOnToInput();
                                }
                            });
                        } else {
                            ee.indiOff();
                            win.find("#eemailinvalid").show();
                            win.find("#ehelpinfo").hide();
                            setErrorOnToInput();
                        }
                    }
                }]
            });
            win.find("input#efromemail").prop('disabled', true);
            win.on('keyup', "input#etoemail", function () {
                $("#eemailinvalid").hide();
                $("#ecannotsend").hide();
                $("#ehelpinfo").show();
                $(this).css('border', '0.1rem solid #CFD6DB');
            });
            const setErrorOnToInput = function () {
                win.find("input#etoemail").addClass("has-error").css('border', '2px solid #e80229');
            }
        });
        //Remove ChannelTemplate Tab
        screen.templatecontainer.on('click', '.action-removechanneltemplate', function (e) {
            e.stopPropagation();
            _removeChannelTemplateTab($(this).data('tabid'));

        });
        //Preview Template
        screen.templatetabscontent.on('click', '.action-previewtemplate', function() {
            var $this = $(this);
            var $tab = $this.closest('.tab-pane');
            ee.data.selectedChannelTemplate = _collectChannelTemplate($tab);
            ee.views.templates.previewContent(ee.data.selectedChannelTemplate.templateid);
            
        });
        
        //Bind Attachments event
        if (selectedCampaign.isEditable === true) {
            var $templateTab;
            var _regFileName = new RegExp('(\\\|\\/|:|\\*|\\?|"|<|>|\\||\\+|#|&|%|\\^|\\(|\\)|\\s+)', 'g');
            const attachments = [];
            screen.uploadinputanytemplate.on("change", function (e) {
                var $input = $(this), $attachmentContainer = $templateTab.find(".attachment:first"), $attachmentList = $attachmentContainer.find(".attachments-list:first");
                var files = this.files, inprogress = 0;
                ee.indiOn();

                for (var i = 0; i < files.length; i++) {
                    (function() {
                        var file = files[i];
                        inprogress++;
                        var newName = file.name.replace(_regFileName, '_');
                        var newFile = ee.tools.createFile(newName, file);
                        if (attachments.includes(newName)) {
                            ee.indiOff();
                            html.modal.info(ee.t.cantattachfileswiththesamename, ee.t.cantattachfile, false);
                            $input.val("");
                            return;
                        }
                        if (newFile.name !== file.name) {
                            newFile.warning = ee.t.wrongfilename;
                        }
                        ee.api.uploadAttachment(newFile, function (attachment) {
                            $attachmentContainer.show();
                            $attachmentList.append(html.get("CampaignCreatorAttachmentRow", $.extend(attachment, newFile)));
                            attachments.push(newName);
                            if (ee.data.selectedtemplate) {
                                ee.data.selectedtemplate.isdirty = true;
                            }
                            inprogress--;
                            newFile.warning = "";

                            if (inprogress === 0) {
                                ee.indiOff();
                                $input.val("");
                            }
                        });
                    })();
                }
            });
            screen.templatetabscontent.on('click', '.action-addattachment', function () {
                $templateTab = $(this).closest('.echanneltemplate');
                screen.uploadinputanytemplate.trigger("click");
            });
            //Remove Attachment
            screen.templatetabscontent.on("click", ".action-removeattachment", function () {
                _dirty();
                var $li = $(this).closest("li"),
                    filename = $li.find('input[name="filename"]');
                var $ul = $li.closest(".attachments-list");
                $li.remove();
                if ($ul.find("li").length === 0) {
                    $ul.closest('.attachment').hide();
                }
                _.remove(attachments, (fileName) => {
                    return fileName === filename.val();
                });
                ee.api.removeAttachment(filename.val());
            });
        }
        //Add First Tab
        _addNewChannelTemplateTab();
    };
    var _removeChannelTemplateTab = function (id) {
        var $tabContent = screen.templatecontainer.find("#" + id);
        var $li = screen.templatecontainer.find("#li" + id);
            //utm removal

        if ($tabContent.length === 0) { return }
        
        const channelTemplate = _collectChannelTemplate($tabContent);
        
        
        html.modal.confirm(ee.t.removetemplatechannel, ee.t.info, function () {
            $li.remove();
            $tabContent.remove();
            _recalculateChanneltemplateTab();
            var $tabsLi = screen.templatetabs.find('li:not(:first)');
            if (channelTemplate.templateid > 0) {
                ee.api.deleteTemplate(channelTemplate.templateid, 2, function () { }, true);
            }
            screen.addtemplatetab.show();
            if ($tabsLi.length === 0) {
                screen.templatetabs.hide();
                _addNewChannelTemplateTab();
            }
            if ($tabsLi.length === 1) {
                screen.split.hide(); 
                $('.tooltiptimeoption').removeClass('absolutetooltipposition');
            }

            if($tabsLi.length === 0){
                $("#utmscheckedcheckbox").removeAttr('style');
                $('#eenabletemplateutms').removeAttr('checked');
            }

            if ($tabsLi.filter(".active").length === 0) {
                $tabsLi.find("a:last").tab('show');
            }
            _isSendButtonDisabled();
            _dirty();
            _removeUTMTabs(id);
        }, false, { confirmTitle: ee.t.remove, confirmCSS: "btn_lg btn_destructive btn-danger" });
    }

    var _saveDraftCampaign = function () {
        ee.logEvent('Campaign creator - save draft');
        var templateName = $('#ename')
        var campaignname;
        if ($('#ecampaignname').val() == "") {
            campaignname = ee.t.campaign;
        } else {
            campaignname = $('#ecampaignname').val();
        }

        templateName.val(campaignname + ":" + moment().format('YYYY-MM-DDThh:mm:ss'))
        var channelTemplate = _collectChannelTemplate(_templateChannel);
        ee.data.selectedChannelTemplate = channelTemplate;
        ee.editor.save(_saveDragDropTemplateCallback, (html.isMobile()) ? false : ee.data.selectedtemplate.isdirty);
        $("#utmscheckedcheckbox").css("display", "none");

    };

    var _templateChannel = null;

    var _addNewChannelTemplateTab = function () {
        var tabid = html.generateUniqueId(4),
            $tabLi = html.get('CampaignCreatorTemplateTab', { tabid: tabid }),
            $tabPanel = html.get("CampaignCreatorTemplateTabPanel", { tabid: tabid, templates: ee.data.templates, mailerids: ee.data.account.mailerids, isemptymailerids: ((ee.data.account.mailerids && ee.data.account.mailerids.length > 0) ? false : true), mobile: html.isMobile() }),
            $template = undefined, $templatescreenshot = $tabPanel.find("img:first"), firstrun = true;
        //Add Tab
        screen.templatetabs.append($tabLi);
        //Add TabPanel
        screen.templatetabscontent.append($tabPanel);

        _recalculateChanneltemplateTab();
        //Show Tabs ?
        var $tabsLi = screen.templatetabs.find('li:not(:first)');
        if ($tabsLi.length > 0) {
            $tabsLi.show();
        } else {
            $tabsLi.hide();
        }
        if ($tabsLi.length === 19) {
            screen.addtemplatetab.hide();
        }
        if ($tabsLi.length > 1) {
            $('.tooltiptimeoption').addClass('absolutetooltipposition');
        }
        $tabLi.find("a").tab('show');

    };
    var _recalculateChanneltemplateTab = function () {
        //Recalculate Tabs Name
        screen.templatetabs.find("li:not(:first) a").each(function (i) {
            this.innerText = (("abcdefghijklmnoprst")[i]);
        });
        screen.utmtabs.find("li a").each(function (i) {
            this.innerText = (("abcdefghijklmnoprst")[i]);
        });
    }
    var _insertChannelTemplateToTab = function (prevSelectedTemplate) {
        let channelTemplate = prevSelectedTemplate || ee.data.selectedChannelTemplate;
        let enableippools = checkEnableIPpools();
        let name;
        let description;
        let $tabpane = screen.templatetabscontent.find(".tab-pane.active:first");
            //tabid = $tabpane.attr("id"),
            let template = ee.views.templates.getTemplate(prevSelectedTemplate?.templateid ?? channelTemplate.templateid);
          
            channelTemplate.UseForReplyTo = false;
      

        if (channelTemplate.templatereplyemail === channelTemplate.templatefromemail && channelTemplate.templatereplyname === channelTemplate.templatefromname) {
            channelTemplate.UseForReplyTo = true;
        }
        var $template = html.get("CampaignCreatorTemplateDetails", {
            pools: (enableippools) ? ee.data.account.pools.filter(['isempty'], true, true, true).getAll() : [],
            isEditable: selectedCampaign.isEditable,
            channelTemplate: channelTemplate,
            template: template,
            enableippools: enableippools,
            tabid: $tabpane.attr("id"),
            mobile: html.isMobile(),
            canSendTest: ee.data.account.grantedSendingPermission.api
        });
        //ipools
        if (selectedCampaign.isEditable && enableippools) {

            var selectedpool = ee.data.account.pools.get('name', channelTemplate.poolname || 'default');
            if (!selectedpool) {
                selectedpool = ee.data.account.pools.get('value', 'default');
            }
            $template.dropdowntext.html('<b>' + selectedpool.name + '</b> | ' + selectedpool.description);
            $template.poolname.val(selectedpool.name);
            if (selectedCampaign.isEditable) {
                $template.ippoolsselect.on('click', 'li', function () {
                    var $this = $(this);
                    var name = $this.data('name');
                    var description = $this.data('description');

                    $template.dropdowntext.html('<b>' + name + '</b> | ' + description);
                    $template.poolname.val(name);
                });
            }
        }
        $tabpane.empty();
        $tabpane.append($template);
        html.widget.TemplateThumbnail.init($template,{templateScope: template?.templatescope});

        $tabpane.closest('#etemplatecontainer').find("#etemplatetabs").show();

        if (screen.templatetabscontent.find(".action-tempaltedetails-tab").length > 1) {
            screen.split.show();
        }
        //Add emoji panel
        if (selectedCampaign.isEditable) {
            html.EmojiInput($template.find('input[name=subject]'));
            html.inittooltip($template, '.etooltip', {
                title: function () { return ($(this).data('mastertooltip')); },
                container: $template,
                html: true
            });
        }

        $template.find('.ereplytocheckbox').change(function () {
            var $replayto = $template.find('.ereplyto');
            if ($(this).is(":checked") === true) {
                $replayto.removeClass('hide');
            } else {
                $replayto.addClass('hide');
            }
            $template.find('input[name=templatereplyemail]').val($template.find('input[name=templatefromemail]').val());
            $template.find('input[name=templatereplyname]').val($template.find('input[name=templatefromname]').val());
        });

        const parseCopyTemplateResponse = (response) => {
            ee.indiOff();
            //Add new tempate to list
            response.data = ee.api._parseTemplate(response, true);
            ee.data.templates.add("unshift", response);
            html.modal.closeAll();
        };

        $tabpane.find('.addtomytemplates-button').on('click', () => {
            return EE_API.Template.LoadTemplate({templateID: $tabpane.find(".gallery-item").data("templateid"), ispublic: false})
            .then ( (response) => {
                let template = response;
                (template.bodyamp) ? template.bodyamp : template.bodyamp = '';
                (template.bodytext) ? template.bodytext : template.bodytext = '';

                let win = html.get("TemplateDefaultOptions", { template: template, hideoptions: true, islanding: template.templatetype === 2 });
                let modal;
                html.widget.SelectFromEmail(win.fromemail, template.fromemail)
                .then(function(){
                    modal = html.modal.create(win, {
                        title: ee.t.copytemplate,
                        size: "emodal-md",
                        buttons: [{
                            name: ee.t.savecopy,
                            title: ee.t.ttsavecopy,
                            css: "btn_primary btn_lg",
                            callback: function() {
                                ee.indiOn();
                                let query = {templateID:template.templateid, name: win.name.val(), subject: win.subject.val(), fromname: win.fromname.val(), fromemail: win.find('input[name=fromemail]').val(), scope: 0, ispublic: template.ispublic};
                                EE_API.Template.CopyTemplate(query)
                                .then(parseCopyTemplateResponse)
                                .then ( () => {
                                    $tabpane.find('.savedToTemplates').text(`${query.name} ${ee.t.wasadded}`)
                                    $tabpane.find(".addtomytemplates-button").prop('disabled', true);
                                    $tabpane.find(".gallery-item").addClass("template-saved");
                                })
                                .catch((err) => {html.modal.info(err)});
                            }
                        }]
                    })
                })
            })
        })
        _addUTMTabs($tabpane.attr("id"), channelTemplate)
        _isSendButtonDisabled();
    };

    var _addUTMTabs = function(id, template) {
        id = id.split('-')[1] //we are interested only in 4-letters ID
        var $utmtemplate = html.get("CampaignUTMTab", {
            tabid: id,
            template: template
        });
        var $utmtab = html.get("CampaignUTMLi", {
            tabid: id
        });
        $utmtab.appendTo(screen.utmtabs)
        $utmtemplate.appendTo(screen.utmcontent)
        screen.utmtabs.find('li:last a').click();
        templatescount++;
        _recalculateChanneltemplateTab();
    };

    var _removeUTMTabs = function(id) {
        id = id.split('-')[1] //we are interested only in 4-letters ID
        $("#eutmcontent").find('#utm-' +id).remove();
        $("#eutmtabs").find('#utm-' +id+ '-li').remove();
        templatescount--;
        _recalculateChanneltemplateTab();
    }

    var _showChannelTemplateEditor = function () {
        var channelTemplate = ee.data.selectedChannelTemplate;
        var template = ee.data.selectedtemplate;

        template.templatescope = 2; //Always overwrite scope to DRAFT.

    
        var beforeClose = function () {
            if (ee.data.selectedtemplate && ee.data.selectedtemplate.isdirty === true) {
                _saveDraftCampaign();
                if (ee.editor) { ee.editor.destroy(); }
                destroyEditor(ee.data.selectedtemplate.templatetype);
            } else {
                let screenshotpath;
                if (ee.data.selectedtemplate.mediumscreenshotpath) {
                    if (!ee.data.selectedtemplate.mediumscreenshotpath.includes('?timestamp')) {
                        screenshotpath = `${ee.data.selectedtemplate.mediumscreenshotpath}?timestamp=${(new Date()).getTime()}`;
                    } else {
                        screenshotpath = ee.data.selectedtemplate.mediumscreenshotpath;
                    }
                }
                if (ee.editor) { ee.editor.destroy(); }
                destroyEditor(ee.data.selectedtemplate.templatetype);
                html.widget.TemplateThumbnail.init(screen);
            }

            html.modal.closeAll();

            ee.indiOff();
            frameoverlay = false;
        }
        var afterShow = function () {
            if (template.originaltemplateid <= 0 && template.templateid <= 0) {
                $templateChannel.find("input[name=templatename]").focus();
            } else {
                $templateChannel.find("input[name=templatesubject]").focus();
            }
        };

        var $templateChannel = html.get("CampaignCreatorChannelTemplateEditor", { channel: channelTemplate, template: template, mobile: html.isMobile() });
        _templateChannel = $templateChannel;

        //html.widget.SelectFromEmail($templateChannel.fromemail, channelTemplate.templatefromemail, true);

        if (frameoverlay === false) {
            frameoverlay = new html.Frameoverlay($templateChannel).on({
                'beforeClose': beforeClose,
                'afterShow': afterShow
            });
        } else {
            frameoverlay.on({
                'beforeClose': beforeClose,
                'afterUpdate': afterShow
            }).changeContent($templateChannel);
        }
        //Collapse Default options
        // $templateChannel.collapsedefaulttemplate.click(function () {
        //     $(this).toggleClass("rotate180");
        //     $templateChannel.channeltemplatedefault.collapse('toggle');

        // });

        //Toggle Reply to options
        // $templateChannel.replytocheckbox.on('change', function () {
        //     if ($(this).is(":checked") === true) {
        //         $templateChannel.replyto.removeClass('hide');
        //     } else {
        //         $templateChannel.replyto.addClass('hide');
        //     }
        //     $templateChannel.replytomail.val($templateChannel.fromemail.val());
        //     $templateChannel.replytoname.val($templateChannel.fromname.val());
        // });
        //Display replyto and replytoname inputs?
        // if (channelTemplate && channelTemplate.templatereplyemail !== channelTemplate.templatefromemail || channelTemplate.templatereplyname !== channelTemplate.templatefromname) {
        //     $templateChannel.replytocheckbox.prop('checked', 'checked');
        //     $templateChannel.replyto.removeClass('hide');
        // }
        /*-- Save template draft --*/

        var saveDraftCampaign = function () {
            ee.logEvent('Campaign creator - save draft');
            var templateName = $('#ename')
            var campaignname;
            if ($('#ecampaignname').val() == "") {
                campaignname = ee.t.campaign;
            } else {
                campaignname = $('#ecampaignname').val();
            }

            // templateName.val(campaignname + ":" + moment().format('YYYY-MM-DDThh:mm:ss'))
            // var channelTemplate = _collectChannelTemplate($templateChannel);
            // ee.data.selectedChannelTemplate = channelTemplate;
            ee.editor.save(_saveDragDropTemplateCallback, ((html.isMobile() === true) ? false : ee.data.selectedtemplate.isdirty));
            $("#utmscheckedcheckbox").css("display", "none");

        };

        // ee.tools.validateEntityName(
        //     {
        //         screen: $templateChannel,
        //         inputname: '#esubject',
        //         submit: $templateChannel.savedraft
        //     }
        // )

        //InitEditor
        if (html.isMobile() === false) {
            if (template.templatetype === 1) {

                //Drag&Drop
                ee.editor = ee.editors.etemplate;
                ee.editor.load($templateChannel.templateeditor, {
                    screenHandle: $templateChannel,
                    workSpaceMaxWidth: 750,
                    defaultSettings: true,
                    defaultButtons: false,
                    menuWindow: ".eframeoverlay:first",
                    isDirty: function () {
                        ee.data.selectedtemplate.isdirty = true;
                        saveDraftCampaign();
                    },
                    autoSaveNewTemplateCallback: function (result) {
                        ee.data.selectedChannelTemplate.templateid = result.data;
                        _insertChannelTemplateToTab();
                        $templateChannel.find('input[name=templateid]').val(result.data);
                        _dirty();
                    }
                });
                if (ee.data.account.iseelogorequired && ee.data.selectedtemplate.templatetype === 1 && !ee.data.account.issub) {
                    const badge = html.get('MockupEEBadge');
                    const workspace = $('#eworkspace');
                        setTimeout(()=>{
                            badge.css('max-width', ee.editor.core.layoutStruct.cssStyle[".layer_1"]["max-width"])
                            $('#eworkspace').css('padding-bottom', '8rem');
                        }, 1000)
                        
                        
                        badge.appendTo(workspace);
                        badge.hover(() => {
                            $('#buttonEEBadge').toggleClass('badgehover');
                            $('#deleteEEBadge').toggle();
                            $('#deleteEEBadge').on('click', () => {
                                $('#trialSidebar').show();
                                $('#dsidebar').scrollTop($('#dsidebar').prop("scrollHeight"))
                            })
                        })
    
                }
                $templateChannel.find('input[name=name]').attr('value', ee.data.selectedtemplate.name);
                $('input[name="name"]').val(ee.data.selectedtemplate.name);
                $(".js-template-name").html(template.name)
                var $templateDragDropInCampaings = html.get("OnlyForDragAndDropInCampaigns", {channel: channelTemplate, template: template} );
                $('#hiddenOptions').html($templateDragDropInCampaings);

                $(".template__name-input").on('change', function () {
                    let text = $(".template__name-input").val();
                    ee.data.selectedtemplate.name = text;
                    $templateChannel.find('input[name=name]').attr('value', ee.data.selectedtemplate.name);
                    if (ee.data.selectedtemplate.templateid !== 0) {
                        saveDraftCampaign();
                    }
                })

            } else {
                //RawEditor
                ee.editor = ee.editors.raweditor;
                const $templateDragDropInCampaings = html.get(
                    "OnlyForDragAndDropInCampaigns",
                    {
                        channel: channelTemplate,
                        template: template
                    }
                );
                $('#hiddenOptions').html($templateDragDropInCampaings);
                $('.eframeoverlay-close').hide();

                ee.editor.show($templateChannel.templateeditor, {
                    screenHandle: $templateChannel,
                    defaultSettings: false,
                    defaultButtons: false,
                    menuWindow: ".eframeoverlay:first",
                    isDirty: function () {
                        ee.data.selectedtemplate.isdirty = true;
                    },
                    autoSaveNewTemplateCallback: function (result) {
                        if (result.data) {
                            ee.data.selectedChannelTemplate.templateid = result.data;
                        } else {
                            ee.data.selectedChannelTemplate.templateid = result;
                        }
                        
                        _insertChannelTemplateToTab();
                        $templateChannel.find('input[name=templateid]').val(result.data);
                        _dirty();
                    }
                });

                $templateChannel.find('input[name=name]').attr('value', ee.data.selectedtemplate.name);
                let $saveinRawEditor = html.get("OnlyRawEditor", {channel: channelTemplate, template: template} );
                $('#saveButtonInRaw').html($saveinRawEditor);
                if ($('#esavedraft')){

                    $("#esavedraft").on('click', function () {
                        ee.indiOn();
                        saveDraftCampaign();
                    });
                    }
            }
        } else {
            var iframe = html.get("EmptyIframe", {});
            iframe.attr("id", "campagincreatorpreview");
            iframe.removeClass("enoborder");
            iframe.on('load', function () {
                var doc = iframe[0].contentWindow.document;
                doc.open();
                doc.write(template.bodyhtml);
                doc.close();
                $(iframe[0].contentWindow).ready(function () {
                    iframe.height(iframe[0].contentWindow.screen.height);
                });
            });
            $templateChannel.templateeditor.append(iframe);
            return;
        }

        /*-- Make Dirty when anything has changed in a template --*/
        $templateChannel.find(".defaulttemplateoptions").on("keyup", "input", function (e) {
            if (e.keyCode == 27) {
                return;
            }
            ee.data.selectedtemplate.isdirty = true;
        });

        /*-- Attachments events --*/
        //Attachment Upload
        // if (selectedCampaign.isEditable === true) {
        //     var _regFileName = new RegExp('(\\\|\\/|:|\\*|\\?|"|<|>|\\||\\+|#|&|%|\\^|\\(|\\)|\\s+)', 'g');
        //     $templateChannel.uploadinput.on("change", function () {
        //         var $input = $(this), $attachmentContainer = $templateChannel.channeltemplatedefault.find(".attachments:first"), $attachmentList = $attachmentContainer.find(".attachments-list:first");
        //         var files = this.files, inprogress = 0;

        //         ee.indiOn();
        //         for (var i = 0; i < files.length; i++) {
        //             (function() {
        //                 var file = files[i];
        //                 inprogress++;

        //                 var newName = _.replace(file.name, _regFileName, '_');
        //                 var newFile = ee.tools.createFile(newName, file);

        //                 if (newFile.name !== file.name) {
        //                     newFile.warning = ee.t.wrongfilename;
        //                 }

        //                 ee.api.uploadAttachment(newFile, function (attachment) {
        //                     $attachmentContainer.show();
        //                     $attachmentList.append(html.get("CampaignCreatorAttachmentRow", $.extend(attachment, newFile) ));
        //                     ee.data.selectedtemplate.isdirty = true;
        //                     inprogress--;
        //                     newFile.warning = "";

        //                     if (inprogress === 0) {
        //                         ee.indiOff();
        //                         $input.val("");
        //                     }
        //                 });
        //             })(i);
        //         }
        //     });
        //     $templateChannel.buttonaddattachment.click(function () {
        //         ee.logEvent('Campaign creator - add attachment');
        //         $templateChannel.uploadinput.trigger("click");
        //     });
        //     //Attachment Remove
        //     $templateChannel.channeltemplatedefault.on("click", ".action-removeattachment", function () {
        //         $attachmentContainer = $templateChannel.channeltemplatedefault.find(".attachments:first");
        //         $(this).closest("div").remove();
        //         ee.data.selectedtemplate.isdirty = true;
        //         if ($attachmentContainer.find(".attachments-list:first li").length === 0) {
        //             $attachmentContainer.hide();
        //         }
        //     });
        //     //Add emoji panel
        //     html.EmojiInput($templateChannel.find('input[name=subject]'));
        //     html.inittooltip($templateChannel, '.etooltip', {
        //         title: function () { return ($(this).data('mastertooltip')); },
        //         container: $templateChannel,
        //         html: true
        //     })
        // } else {
        //     $templateChannel.buttonaddattachment.remove();
        //     $templateChannel.uploadinput.remove();
        //     $templateChannel.channeltemplatedefault.find(".action-removeattachment").remove();
        // }
    }
    var _saveDraft = function (isPresave) {
        if (ee.data.selectedtemplate.templateid > 0) return;
        html.widget.TemplateThumbnail.init(screen);
        ee.data.selectedtemplate.templatescope = 2;
        ee.data.selectedtemplate.name = ee.t.campaign + ":" + ee.api.formatDate(new Date(), "yy-mm-dd hh:ii:ss");
        ee.data.selectedtemplate.subject = ee.tools.subStringUtf8mb4(ee.data.selectedtemplate.subject, 200);
        const templateCalback = isPresave ? _presaveTemplateCallback : _saveTemplateCallback;
        ee.api.saveTemplate(ee.data.selectedtemplate, templateCalback, ((html.isMobile() === true) ? false : ee.data.selectedtemplate.isdirty));
    };
    var _saveTemplateCallback = function (result) {
        if (!result) {return;}
        ee.data.selectedChannelTemplate.templateid = result.data ? result.data : result;
        ee.data.selectedtemplate.templateid = result.data ? result.data : result;
        _insertChannelTemplateToTab();
        if (frameoverlay) {
            frameoverlay.close();
        }
        if (ee.editor) {
            ee.editor.destroy();
        }

        _dirty();
    };

    var _presaveTemplateCallback = function (result) {
        if (!result) {return;}
        ee.data.selectedChannelTemplate.templateid = result.data ? result.data : result;
        ee.data.selectedtemplate.templateid = result.data ? result.data : result;
        _insertChannelTemplateToTab();
        _dirty();
    };

    let _saveDragDropTemplateCallback = function (result) {
        if (!result) {return;}
        ee.data.selectedChannelTemplate.templateid = result.data ? result.data : result;
        ee.data.selectedtemplate.templateid = result.data ? result.data : result;
        _insertChannelTemplateToTab();
        if (ee.data.selectedtemplate.templatetype === 0) {
            if (frameoverlay) {
                //frameoverlay.close();
            }
            if (ee.editor) {
                ee.editor.destroy();
            }
        }

        _dirty();
    };
    var destroyEditor = function (templatetype) {
        if (html.isMobile() === true) {
            return;
        }
        ee.data.selectedtemplate = undefined;
        if (!ee.editor) return;
        if (templatetype === 1) {
            ee.editor.destroy();
        }
        ee.editor = false;
    }
    var _disabledTemplates = function () {
        if (ee.data.account.enablecontactfeatures === false || selectedCampaign.isEditable === false) {
            screen.addtemplatetab.off().hide();
            if (selectedCampaign.isEditable === false) {
                screen.templatetabscontent.find("input").attr("disabled", true); //.addClass("ereadonly")
                screen.templatetabscontent.find(".action-edit").remove();
                screen.templatetabscontent.find(".action-removeattachment").remove();
                screen.templatetabscontent.find(".action-sendtest").remove();
                screen.templatetabs.find(".tabremove.action-removechanneltemplate").remove();
            }
        }
    };
    /*-- OPTIONS EVENTS --*/
    var _eventTriggerType = function (e) {

        var $liSegments = ($('#esupertolist')) ? $('#esupertolist').find(".row[data-type=segment]") : $(),
            value = parseInt($(this).val());

        screen.triggeroptions.find(".group-options").hide();

        $liSegments.removeClass('hidden');
        if (value === 1) {
            screen.mainbuttontext.html(ee.t.sendcampaign);
        } else if (value === 2) {
            screen.optiontriggerdate.show();
            screen.optiontriggercount.show();
            screen.mainbuttontext.html(ee.t.schedule);
            screen.find('input:radio[name=optiontriggerfrequency][value=0]').trigger("click");
        } else {
            screen.mainbuttontext.html(ee.t.savecampaign);
            screen.optiontriggerdelay.show();
            /*NOTE ignored screen.notrigerdelay once when campaign is edited*/
            (!ignoreNotriggerdelayClick) ? screen.notriggerdelay.trigger("click") : ignoreNotriggerdelayClick = false;
            if (value === 3) {
                if ($('#esuperto').find("span[data-type=segment]").remove().length > 0) {
                    _countAudiences();
                }
            }
            if (value > 3) {
                screen.optiontriggerchannel.show();
            }
            if (value > 4) {
                screen.optiontriggerdata.show();
            }
        }
    };
    var _bindTriggerOptions = function () {
        var triggeroptions = screen.triggeroptions;
        var triggertype = screen.triggertype;
        // Enable Datepicker for Schedule Campaigns
        screen.scheduledate.datepicker({ dateFormat: "dd-M-yy", minDate: 0 });

        //Set Max Count For Schedule Campaigns
        triggeroptions.find("input[name=optiontriggerfrequency]").change(function () {
            var value = $(this).val();
            if (value === "0") {
                screen.triggercount.val("1").removeAttr("disabled");
                screen.triggerfrequency.val("0").attr("disabled", "disabled");
                screen.triggerfrequencyunits.attr("disabled", "disabled");
            } else {
                screen.triggercount.val("0").attr("disabled", "disabled");
                screen.triggerfrequency.val("1").removeAttr("disabled");
                screen.triggerfrequencyunits.removeAttr("disabled");
            }
        })

        triggeroptions.find("input[name=triggercount]").change(function () {
            var value = $(this).val();
            if (value === "1") {
                screen.triggerfrequency.val("0");
            } else {
                screen.triggerfrequency.val("1");
            }
        })

        //Set Time After Event
        triggeroptions.find("input[name=eoptiontriggerdelay]").click(function () {
            var method = "attr";
            if ($(this).val() === "1") {
                method = "removeAttr";
            } else {
                screen.triggerdelay.val(0);
            }
            screen.triggerdelay[method]("disabled", "disabled");
            screen.triggerdelayunits[method]("disabled", "disabled");
        });

        //check for triggertype changes
        triggertype.on('change', function () {
            if (triggertype.val() > 2)
                triggerselected = true;
            else
                triggerselected = false;
            //check audience/trigger conflict
            _resolveAudienceTriggerConflict();
        });

        //blur campaign name
        var timerCampaignName = false;
        screen.campaignname.keyup(function () {
            clearTimeout(timerCampaignName);

            timerCampaignName = setTimeout(function () {
                screen.campaignname.removeClass("has-error");
                screen.campaignnameerror.hide();

                var campagin = ee?.data?.channels?.get("name", screen?.campaignname?.val());
                if (!campagin) {
                    return;
                }
                var channelid = parseInt(screen.channelid.val(), 10);
                if (campagin.channelid === channelid) {
                    return;
                }
                _setErrorCampaignName();
            }, 300);
            _isSendButtonDisabled();
        });
    };
    var _setErrorCampaignName = function () {
        screen.campaignname.addClass("has-error");
        screen.campaignnameerror.show();

        if (screen.campaignoptions.hasClass("in") === false) {
            screen.accordion.find(".panel-header[data-target=#ecampaignoptions]").trigger("click");
        }
    }
    var _setErrorOnInput = function () {
        screen.accordion.find("input[name=fromemail]").addClass("has-error").css('border', '2px solid #e80229');
        $('#validemailadress').show();
    };

    var _cheackScheduledfor = function (triggerdate) {
        if (!triggerdate || isNaN(triggerdate.getTime()) || triggerdate.getTime() < (new Date()).getTime()) {
            return false;
        }
        return true;
    };
    var _disabledOptions = function () {
        if (ee.data.account.enablecontactfeatures === false || selectedCampaign.isEditable === false) {
            screen.campaignname.attr("disabled", true); //.addClass("ereadonly")
            screen.triggeroptions.find("input, select, checkbox, radio").off().addClass("disabled").attr("disabled", true);
            screen.triggertype.addClass("disabled").off().attr("disabled", "disabled", true);

            var campaign = ee.data.channels.get("name", screen.campaignname.val());
            screen.campaignoptions.find('select').each(function () {

                var $this = $(this);
                var input = '<input type="text" name="' + this.name + '" value="' + $this.find("option:selected").text() + '" class="' + $this.attr("class") + '" id="' + $this.attr("id") + '" disabled="disabled"/>';
                $this.replaceWith(input);
            });
            if (selectedCampaign.isEditable === false) {
                screen.campaignoptions.find('input').each(function () {
                    var $this = $(this);
                    $this.attr("disabled", true);
                });
            } else {
                screen.campaignoptions.find('input:not(.openclicktrack)').each(function () {
                    var $this = $(this);
                    $this.attr("disabled", true);
                });
            }

            return true;
        }
        html.inittooltip(screen, '.tooltiptimeoption', {
            title: function () { return ($(this).data('mastertooltip')); },
            container: screen,
            html: true
        });


        return false;
    };
    /*-- COLLECT DATA AND SAVE/SEND CAMPAIGNS --*/
    var _saveCampaignData = function (status,returnValue = false,sendAction = false) {
        //START COLLECT DATA
        var cp = $.extend(true, {}, defaultChannelCampaign);
        cp.channelid = screen.channelid.val();
        cp.name = screen.campaignname.val();
        //Override status
        cp.status = status;
        cp.triggertype = parseInt(screen.triggertype.val());
        //Set trigger data
        var options = screen.triggeroptions.elasticCollectForm();
        //Collect Trigger Options
        if (cp.triggertype === 2) {
            options.triggerdate_date = screen.scheduledate.datepicker("getDate");
            var timeparts = options.triggerdate_time.split(":");
            options.triggerdate_date.setHours(timeparts[0]);
            options.triggerdate_date.setMinutes(timeparts[1]);
            cp.triggerdate = ee.tools.time(options.triggerdate_date).toUTC();//ee.api.formatDate(options.triggerdate_date, "yy-mm-dd hh:ii:ss", true, false);
            cp.triggercount = options.triggercount;
            cp.triggerfrequency = options.triggerfrequency * parseInt(options.triggerfrequency_units);
            if (_cheackScheduledfor(options.triggerdate_date) === false) {
                html.modal.info(ee.t.invalidscheduletime);
                return;
            }
        } else if (cp.triggertype > 2) {
            cp.triggerdelay = options.triggerdelay * parseInt(options.triggerdelay_units);
            if (cp.triggertype > 3) {
                cp.triggerchannelid = options.triggerchannelid;
            }
            if (cp.triggertype === 5) {
                cp.triggerdata = options.triggerdata;
            }
        }
        //Collect Audience
        cp.targets = $('#esuperto').find("span").map(function () { return this.dataset.value }).get();
        //Collect Template
        if (cp.name === "" && screen.templatetabscontent.find(".echanneltemplate").length === 0) {
            cp.name =  moment().format('YYYY-MM-DDThh:mm:ss');
        }
        screen.templatetabscontent.find(".echanneltemplate").each(function () {
            var $tab = $(this);
            var channeltemplate = _collectChannelTemplate($tab);
            if (cp.name === "") {
                cp.name = channeltemplate.templatesubject + " " + ee.api.formatDate(new Date(), "yy-mm-dd HH:ii:ss", false, true);
            }
            if (channeltemplate.poolname === 'default') {
                channeltemplate.poolname = null;
            }
            // add campaigntemplateid. Without it Backend sometimes rebuilds template and duplicates it.
            if (!channeltemplate.campaigntemplateid) {
                channeltemplate.campaigntemplateid = channeltemplate.channelid;
            }
            //if UTMs - add them to templateobject.
            if ($('#eenabletemplateutms:checked').length) {
                var utmtab = $('#utm-' + $tab.data("tabid").split('-')[1]);
                if (utmtab.find('.UtmSource').val())
                    channeltemplate.UtmSource = utmtab.find('.UtmSource').val();
                if (utmtab.find('.UtmMedium').val())
                    channeltemplate.UtmMedium = utmtab.find('.UtmMedium').val();
                if (utmtab.find('.UtmCampaign').val())
                    channeltemplate.UtmCampaign = utmtab.find('.UtmCampaign').val();
                if (utmtab.find('.UtmContent').val())
                    channeltemplate.UtmContent = utmtab.find('.UtmContent').val();
            }

            const attachmentExitst = _validateTemplatesAttachements(channeltemplate.templateattachments)
            if(!attachmentExitst){
               cp.missingattachments = true
            }
            cp.campaigntemplates.push(channeltemplate);


        });
        //Split Optymalization
        if (cp.campaigntemplates.length > 1) {
            cp.splitoptimization = screen.split.find("input[name=eoptimize]:checked").val();
            const inputToCheck = screen.splitoptimizationminutesunits;
            if(!inputToCheck.val()){
                return showMissingValueModal(inputToCheck,sendAction ? ee.t.missinginputonsend:ee.t.missinginputonsave);
             }
            if (cp.triggertype < 3) {
                cp.splitoptimizationminutes = parseInt(screen.splitoptimizationminutes.val()) * parseInt(screen.splitoptimizationminutesunits.val());
            }
        }
        //Timing option
        cp.timingoption = parseInt(screen.timeoption.val(), 10);
        //track opens
        cp.trackopens = screen.trackopens ? screen.trackopens[0].checked : true;
        //track clicks
        cp.trackclicks = screen.trackclicks ? screen.trackclicks[0].checked : true;
        //track stats
        cp.sendstats = screen.sendstats ? screen.sendstats[0].checked : false;
        if(returnValue){
            return cp;
        }

        
        
      if(cp.missingattachments){
          return html.modal.info(ee.t.attachmentmissing);
      }

        ee.api.saveCampaign(cp, function () {
            ee.logEvent('Send campaign.');
            segmentsselected = 0,
                triggerselected = false;
            _disableDirty();
            const legacyDraftsPath = `${ee.parameters.bootedAsChild ? '/marketing' : ''}/campaigns/new/drafts`;
            if (status === 0) {
                _sendSuccess(cp);
            }
            else if (status === 6) {
                if (ee.parameters.legacyMode) {
                    top.window.location.replace(legacyDraftsPath);
                } else {
                    ee.goTo('#/campaigns/new/drafts');
                }
            } else {
                if (ee.parameters.legacyMode) {
                    top.window.location.replace(`${ee.parameters.bootedAsChild ? '/marketing' : ''}/campaigns/new${cp.triggertype > 2 ? '/autoresponders' : '/'}`);
                } else {
                    // ee.goTo('#/campaigns/new' + ((cp.triggertype > 2) ? '/automations' : '/'));
                    ee.goTo('#/campaigns/new' + ((cp.triggertype > 2) ? '/autoresponders' : '/'));
                }
            }
        });
    };

   


    var _sendSuccess = function (cp) {
        let description = html.render(ee.t.desccampaignsendsuccess, { name: cp.name });
        let showInprogressLink = true;
        if (cp.triggertype === 2) {
            description = html.render(ee.t.desccampaignschedulesuccess, { date: ee.tools.time(cp.triggerdate).fromUTC(), name: cp.name });
            showInprogressLink= false;
        } else if (cp.triggertype > 2) {
            description = html.render(ee.t.desccampaignautorespondersuccess, { name: cp.name });
            showInprogressLink= false;
        }

        ee.frame.content.empty();
        screen.empty();
        screen = html.get("CampaignCreatorSendSuccess", { desccampaginsendsuccess: description, base: ee.base, showInprogressLink });
        if (showInprogressLink) {
            screen.goToCampaignInprogress.click(function () {
                const encodedCampaignName = ee.tools.encodeBase64Url(cp.name);
                if (ee.parameters.legacyMode) {
                    top.window.location.replace(`${ee.parameters.bootedAsChild ? '/marketing' : ''}/campaigns/new/SendNow/${encodedCampaignName}/report/inprogress/overview`);
                } else {
                    ee.goTo(`#/campaigns/new/SendNow/${encodedCampaignName}/report/inprogress/overview`);
                }
            });
        }
        screen.exit.off().click(function () {
            if (ee.parameters.legacyMode) {
                // top.window.location.replace('/campaigns/new' + ((cp.triggertype > 2) ? '/automations' : '/'));
                top.window.location.replace(`${ee.parameters.bootedAsChild ? '/marketing' : ''}/campaigns/new${((cp.triggertype > 2) ? '/autoresponders' : '/')}`);
            } else {
                // ee.goTo('#/campaigns/new' + ((cp.triggertype > 2) ? '/automations' : '/'));
                ee.goTo('#/campaigns/new' + ((cp.triggertype > 2) ? '/autoresponders' : '/'));
            }
        });
        screen.createnewcampaign.off().click(function () {
            if (ee.Router.currentPath() === "campaigncreator") {
                ee.logEvent('Campaing creator - create new');
                ee.view.show();
            } else {
                ee.goTo("#/campaigns")
            }
        });
        //This function changes image everytime user sends campaign successfully
        $(function() {
            var loaded = parseInt(localStorage.getItem('loaded'), 10),
            loaded_numb = loaded?loaded+1:1;
            localStorage.setItem('loaded', loaded_numb);
            var loaded_modulo = loaded_numb % 3;
            var backgroundsuccess = 'backgroundsuccess' + loaded_modulo;
            $('#ecampaignsuccesssendcheck').addClass(backgroundsuccess);
        });
        if(ee.base && window.hj && !ee.isReseller()) {
            window.hj('event','success_campaign_creator');
        }
        ee.frame.content.append(screen);
    };
    var _collectChannelTemplate = function ($tab) {
        var inputs = $tab.find('.defaulttemplateoptions:first').elasticCollectForm(),
            channeltemplate = $.extend({}, defaultChannelTemplate, inputs);
            
        if (Object.entries(inputs).length > 0) {
            channeltemplate.templatesubject = ee.tools.subStringUtf8mb4(inputs.subject, 200);
            if (inputs.poolname === 'Default') channeltemplate.poolname = '';
            channeltemplate.templatefromemail = inputs.fromemail;
            channeltemplate.templatefromname = inputs.fromname;

            channeltemplate.templateattachments = [];
        }
        
        $tab.find(".attachments-list li").each(function () {
            channeltemplate.templateattachments.push($(this).elasticCollectForm());
        });
        if (inputs.fromemail === inputs.templatereplyemail && inputs.fromname === inputs.templatereplyname) {
            inputs.UseForReplyTo = true;
        }
        //Clean reply to if we don't use diffrent reply to
        if (channeltemplate.UseForReplyTo === false) {
            channeltemplate.templatereplyemail = channeltemplate.templatefromemail;
            channeltemplate.templatereplyname = channeltemplate.templatefromname;
        }

        delete channeltemplate.fromemail, channeltemplate.fromname, channeltemplate.subject;

        return channeltemplate;
    };
    var _isSendButtonDisabled = function () {
        screen.mainbutton.addClass("disabled");
        if (ee.tools.isSlashOrBackslash($('#ecampaignname').val().trim())) {
            $('.validation_error_text').text(ee.t.backslashesinname);
            $('#ecampaignname').addClass('incorrect_input_value');
            screen.exitbutton.addClass("disabled");
            return true;
        } else {
            screen.exitbutton.removeClass("disabled")
            $('#ecampaignname').removeClass('incorrect_input_value');
            $('.validation_error_text').text("");
        }
        if ($('#esuperto').find("span").length <= 0) return true;
        if (screen.templatetabscontent.find(".echanneltemplate").length <= 0) return true;
        screen.mainbutton.removeClass("disabled");
        return false;
    }
    var _dirty = function () {
        isDirty = true;
    };
    var _disableDirty = function () {
        isDirty = false;
    }
    var _setEditedCampaign = function (cp) {
        /* SET CAMPAIGN OPTIONS */
        screen.triggertype.val(cp.triggertype).trigger("change");
        //settriggerdelay
        screen.triggerdelay.val(cp.triggerdelayConverted.value);
        screen.triggerdelayunits.val(cp.triggerdelayConverted.time);
        //set trigger campaign
        screen.triggerchannelid.val(cp.triggerchannelid);
        // set option trigger frequency
        var $optiontriggerfrequency = $('input:radio[name=optiontriggerfrequency]');
        if (cp.triggercount >= 1) {
            $optiontriggerfrequency.filter('[value=0]').prop('checked', true);
            screen.triggercount.attr("disabled", false);
            screen.triggerfrequency.attr("disabled", true);
            screen.triggerfrequencyunits.attr("disabled", true)
        } else {
            $optiontriggerfrequency.filter('[value=1]').prop('checked', true);
            screen.triggercount.attr("disabled", true);
            screen.triggerfrequency.attr("disabled", false);
            screen.triggerfrequencyunits.attr("disabled", false);
        }
        //set triggerfrequency
        screen.triggerfrequency.val(cp.triggerfrequencyConverted.value);
        screen.triggerfrequencyunits.val(cp.triggerfrequencyConverted.time);
        /* SET AUDIENCE */
        var $items = $('#esupertolist').find(".superto-list-items");
        if (cp.targets) {
            for (var i = 0, n = cp.targets.length; i < n; i++) {
                $items.find(".row[data-value=" + cp.targets[i] + "]").trigger("click", true);
            }
        }

        if (cp.isEditable === true) {
            _countAudiences();
        } else if (typeof selectedCampaign.recipientcount ==="string"){
            screen.audience.html(": " + parseInt(selectedCampaign.recipientcount)?.numberFormat() + " " + ee.t.contacts.toLowerCase());
        } else {
            screen.audience.html(": " + selectedCampaign.recipientcount?.numberFormat() + " " + ee.t.contacts.toLowerCase());
        }
        const isOldAbSplit = ee.tools.isOldAbSplitCampaign(cp);
        /* SET TEMPLATE CHANNEL */
        for (var i = 0; i < cp.templatechannels?.length; i++) {
            if (isOldAbSplit) {
                if (i > 0) {
                    _addNewChannelTemplateTab();
                }
                ee.data.selectedChannelTemplate = cp.templatechannels[i];
                _insertChannelTemplateToTab();
            } else {
                if (!cp.templatechannels[i].splitwinner) {
                    if (i > 0) {
                        _addNewChannelTemplateTab();
                    }
                    ee.data.selectedChannelTemplate = cp.templatechannels[i];
                    _insertChannelTemplateToTab();
                }
            }
        }
        
        screen.splitoptimizationminutes.val(cp.splitoptimizationminutesConverted.value);
        screen.splitoptimizationminutesunits.val(cp.splitoptimizationminutesConverted.time)
        //Time Option
        screen.timeoption.val(cp.timingoption);
        //Disabled save button and hide status elements
        screen.accordion.find('.collapse:first').collapse("show");

        if (cp.status === 6 || cp.status === 4) {
            screen.exitbutton.data("status", cp.status).html(ee.t.saveandexit);
            screen.mainbutton.data("status", 0);
            return;
        }
        screen.exitbutton.removeAttr("data-status").data("status", "").html(ee.t.exit);
        screen.mainbutton.html(ee.t.savechanges);

        if (cp.status > 0 && cp.status < 4 || cp.status === 5) {
            screen.mainbutton.hide();
        }
    };
    var _useThisTemplate = function () {
        if (!ee.data.selectedtemplate) {
            html.modal.info("Error: Template not found", 'Error', false);
            return;
        }
    
        ee.data.selectedChannelTemplate = $.extend(true, {}, defaultChannelTemplate);
        _templateSetDefault();
        _mergeTemplate2Channel();
        _saveDraft(false);
        _dirty();
    };

    const _getLocalTimeZone = function() {
   
        const guesstedTimeZone = moment.tz.guess();
        const currentTime = new Date();
        const timeZoneOffset = currentTime.getTimezoneOffset();
        return moment.tz.zone(guesstedTimeZone).abbr(timeZoneOffset);
    }


    


    const _validateTemplatesAttachements =  (templateattachments) => {
        return _.every(templateattachments,o => o.id !== MEDIA_MANAGER_DELETED_ID)
    }

    const showMissingValueModal = (element =$('#esplitoptimizationminutesunits'), translation = ee.t.missinginputonsend) => {
        
            modal = html.modal.info(translation);
            element?.addClass('input--error');
    }

    /**
    * Display create/edit campaign screen
    * @param {int} channelid - id campaign we want to edit
    */
    var show = function () {
        //decide previousurl
        let breadcrumb = {}

        breadcrumb.url = "#/" + ee.Router.prevPath();
        switch (ee.Router.prevPath()) {
            case "templates":
                breadcrumb.name = ee.t.templates;
                break;
            case "templates/gallery":
                breadcrumb.name = ee.t.templategallery;
                break;
            case "campaigns/automations":
                breadcrumb.name = ee.t.autoresponder;
                break;
            case "campaigns/drafts":
                breadcrumb.name = ee.t.draft;
                break;
            default:
                breadcrumb.url = `${ee.parameters.legacyMode ? ee.parameters.bootedAsChild ? '/marketing/' : '/' : '#/'}${ee.Router.prevPath() || 'campaigns/new'}`;
                breadcrumb.name = ee.t.campaigns;
                break;

        }
        
        if(ee.base && window.hj) {
            window.hj('trigger', 'campaigncreator');
        }

        ee.logEvent('Campaign creator loaded');

        ee.indiOn();
        if (!_isDataCampaignReady()) {
            return;
        }
        var isSendTestCampaignTutorial = !!(ee.data.account.tutorialsList && ee.data.account.tutorialsList.current && ee.data.account.tutorialsList.current.tutorial == "sendTestCampaign");
        let now = new Date();
        let profileComplete = isSendTestCampaignTutorial ? ee.views.account._checkProfileCompleteWithoutSMS() : ee.views.account._checkProfileComplete();
        
        //check if Router.action() is NOT one of the below - if so, Campaign already exists and we should load it's content
        if (ee.Router.action() 
            && ee.Router.action() !== "show" //is not a new camapign
            && ee.Router.action() !== "target" //is not a campaign created from Templates screen
            && ee.Router.action() !== "template") { //is not a campaign created from Cotnacts screen
            const campaignId = ee.Router.action();
            if (isNaN(parseInt(campaignId, 10))) {
                selectedCampaign = ee.data.channels.get('name', ee.tools.decodeBase64Url(campaignId))
            } else {
                selectedCampaign = ee.data.channels.get('channelid', parseInt(campaignId, 10))
                if (!selectedCampaign) {
                    selectedCampaign = ee.data.channels.get('name', ee.tools.decodeBase64Url(campaignId))
                }
            }
        } else {
            selectedCampaign = $.extend(true, {}, defaultChannelCampaign)
        }
        now.setHours(now.getHours() + 1);

        //Error Campaign not found
        if (!selectedCampaign) {
            html.modal.info("Error: Campaign not found", 'Error', false);
            console.log("Error: Campaign not found");
            return;
        }
        selectedCampaign.isUTMEnabled = false;
        $(selectedCampaign.templatechannels).each(function() {
            if (!selectedCampaign.isUTMEnabled) {
                var that = this;
                if (that.utmcampaign|| that.utmcontent || that.utmmedium || that.utmsource) {
                    selectedCampaign.isUTMEnabled = true;
                }
            }
        })
        var triggerdate = (!selectedCampaign.triggerdate) ? (ee.api.formatDate(now, "dd-M-yy hh:ii", true, true)).split(" ") : ee.api.formatDate(selectedCampaign.triggerdate, "dd-M-yy hh:ii").split(" "),
            enableippools = checkEnableIPpools();
        if (selectedCampaign.isEditable && selectedCampaign.triggerdelay) ignoreNotriggerdelayClick = true;
        let campaignReportLink = ee.parameters.legacyMode ? `${ee.parameters.bootedAsChild ? '/marketing' : ''}/campaigns/new` : '#/campaigns/new';

        const isAutomated = (selectedCampaign.triggertype === 3 /*'OnAdd'*/ || selectedCampaign.triggertype === 4 /*'OnOpen'*/ || selectedCampaign.triggertype === 5 /*'OnClick'*/) || (selectedCampaign.TriggerType === 2 /*'FutureScheduled'*/ && selectedCampaign.TriggerFrequency > 0);
        const isAbSplit = selectedCampaign?.templatechannels?.length > 1;

        if (isAutomated) {
            campaignReportLink += `/Automated/${ee.tools.encodeBase64Url(selectedCampaign.name)}/report/overview`;
        } else if (isAbSplit) {
            campaignReportLink += `/AbSplit/${ee.tools.encodeBase64Url(selectedCampaign.name)}/report/abresults`;
        } else {
            campaignReportLink += `/SendNow/${ee.tools.encodeBase64Url(selectedCampaign.name)}/report/overview`;
        }

        screen = html.get("CampaignCreator", { 
            isEditable: selectedCampaign.isEditable, 
            isNewCampaign: ((selectedCampaign.channelid > 0) ? false : true), 
            campaign: selectedCampaign, 
            account: ee.data.account, 
            profileComplete: profileComplete, 
            openclickcampaigns: ee.data.openclickcampaigns, 
            contactsgroups: ee.data.contactgroups.getAll(), 
            mobile: html.isMobile(), 
            enableippools: enableippools,
            campaginNameEncoded: ee.tools.encodeBase64Url(selectedCampaign.name),
            breadcrumb,
            legacyMode: ee.parameters.legacyMode,
            campaignReportLink,
            isContactsHistoryEnabled: ee.session.hasOption("EnableContactHistory"),
        })
        that.screen = screen; ///remove this?

       

        //append new screen to container
        $('body').css("background-color", "#f5f5f5");//Temporary
        ee.frame.content.empty();
        screen.appendTo(ee.frame.content);
        ee.indiOff();
        /* BIND COMMON EVENTS */
        //AUTO SETUP CHANNEL NAME ENABLE ONLY FOR NEW CAMPAIGN
        screen.accordion.find('.collapse').on('show.bs.collapse', function () {
            var $panel = $(this).closest(".panel"), $panelHeader = $panel.find(".panel-header");
            $panelHeader.find(".panel-header-more").addClass("rotate180");
            $panel.addClass("panel-active");
            $panelHeader.removeClass("einactive");

            if (selectedCampaign.channelid <= 0 && screen.campaignname.val() === "") {
                var campaigname = screen.templatetabscontent.find('input[name=templatesubject]:first');
                if (campaigname.length === 1) {
                    screen.campaignname.val(ee.tools.StringRemoveEmoji(campaigname.val()) + " " + ee.api.formatDate(new Date(), "yy-mm-dd HH:ii:ss", false, true));
                    screen.campaignname.trigger("keyup");

                }
            }
        });
        $(() => {
            $('.ecampaigncreator').closest('.container').addClass('campaignContainer');
            $(window).on('hashchange', () => {
                if (!location.hash.includes('campaigncreator')) {
                    $('.container').removeClass('campaignContainer');
                }
            })
        })

        screen.accordion.find('.collapse').on('hidden.bs.collapse', function () {
            var $panel = $(this).closest(".panel"), $panelHeader = $panel.find(".panel-header");
            $panelHeader.find(".panel-header-more").removeClass("rotate180");
            $panel.removeClass("panel-active");
            $panelHeader.addClass("einactive");
            //APP-611 collapsing the options should not remove campaignname
            // if ($panelHeader.data('target') === "#ecampaignoptions" && selectedCampaign.channelid <= 0) {
            //     screen.campaignname.val("")
            // }
        });

        //focus panel
        screen.on('click', '.panel', function () {
            var $this = $(this), $that = screen.find('.panel.panel-focus');
            if ($this.is($that)) {
                return;
            } else {
                $that.removeClass('panel-focus');
                $this.addClass('panel-focus');
            }
        });
 
        $('#ecampaigncreator').on('click', '#eenabletemplateutms', function() {
            if (!templatescount) {
                var modalenableutmschecked = function (){
                    var $content = html.get("EnableUTMsWithoutTemplate", {});
                    modal = html.modal.info ($content, ee.t.error, undefined, undefined, {
                        buttons: [{
                        name: ee.t.ok,
                        css: 'btn_primary btn_lg',
                        callback: function () {
                            $('#eenabletemplateutms').removeAttr('checked');
                            $('#eutmtrackingoptions').hide();
                            modal.close();
                            }
                        }]
                    });
                }
                modalenableutmschecked();
            } else {
                $('#eenabletemplateutms:checked').length ? $('#eutmtrackingoptions').show() : $('#eutmtrackingoptions').hide();
            }

        });

        html.frame.bindConfirm(function () {
            if (screen.channelid && screen.channelid.val() === '-1') {
                //remove draft
                screen.templatetabscontent.find('.action-tempaltedetails-tab .gallery-item').each(function () {
                    if (!$(this).hasClass("template-saved")) {
                        ee.api.deleteTemplate(this.dataset.templateid, 2, function () { }, true)
                    }
                });
            }
        }, function () {
          return !isDirty;
        })

        //2. Bind Super To input
        _bindEventTo();
        //3. Bind Add Template
        _bindChannelTemplateTabs();
        //4. Bind Trigger options
        _bindTriggerOptions();
        //Set trigger date
        screen.scheduledate.val(triggerdate[0]);
        screen.scheduletime.val(triggerdate[1]);
        
        screen.scheduletimezone.append(_getLocalTimeZone());
 
        screen.triggertype.change(_eventTriggerType).trigger("change");
        //5. Bind udpate legal event
        if (profileComplete === false) {
            html.widget.ProfileUI.showProfileSection(screen.legaldata, { nofocus: true });
        }
        //Focus on to input
        if (ee.data.account.enablecontactfeatures === true) {
            $('#esupertoinput').focus();
        }
        //Prepare screen for Audiences and Triggers
        _resolveAudienceTriggerConflict();
        //6. Bind save
        screen.mainbutton.click(function () {
            var $button = $(this);
            if ($button.hasClass("disabled")){ return };
            

            if (screen.triggertype.val() == 1) {
                html.modal.confirm(ee.t.sendcampaingconfim, ee.t.confirmation, function () {
                    _saveCampaignData(parseInt($button.data("status"), 10),false,true);
                }, undefined, { confirmTitle: ee.t.confirm });
            } else {
                var countOfLists = $('#esuperto').find("span").length;
                html.modal.confirm(ee.t.sendautomationcampaignconfirmationtext + " " + countOfLists + " " + ee.t.contactlists + "?", ee.t.confirmation, function () {
                    _saveCampaignData(parseInt($button.data("status"), 10),false,true);
                }, undefined, { confirmTitle: ee.t.confirm });
            }
        });

        screen.splitoptimizationminutesunits.on('change',function(){
            $(this).removeClass('input--error');
        })

        screen.exitbutton.click(function () {
        

            if ($(this).hasClass('disabled')){
                return false;
            }else {
                const status = parseInt($(this).data("status"), 10);
                let prevPath = (ee.parameters.legacyMode ? ee.parameters.bootedAsChild ? '/marketing/' : '/' : "#/") + ((!ee.Router.prevPath()) ? 'campaigns/new' : ee.Router.prevPath());
                if (isNaN(status) === true) {
                    if (ee.parameters.legacyMode) {
                        top.window.location.replace(prevPath);
                    } else {
                        ee.goTo(prevPath);
                    }
                    return;
                }
                //Save as draft and exit
                _saveCampaignData(status);
                
            }

        });

        //Edit selected campaign
        if (selectedCampaign.channelid > 0) {
            _setEditedCampaign(selectedCampaign);
        } else {
            let id = ee.Router.parameter('node0')
            let propname;

            if (_.isNumber(id?.substring(1))) {
                propname = "value";
            } else {
                propname = "name";
                id = ee.tools.decodeBase64Url(id);
            }
            //Set default options for new camapaign
            if (ee.Router.action() !== 'target') {
                // $('#esupertolist').find(".row[data-value=s0]").trigger('click', [false, true]);
            } else {
                $('#esupertolist').find(`.row[data-${propname}="${id}"]`).trigger('click', [false, true]);
            }
        }
        isDirty = false;//Reset dirty
        screen.off("change").on("change", "input, select", function () { _dirty(); })
        if (screen.enablecontactfeatures) {
            screen.enablecontactfeatures.off().on("click", ee.views.account._enablepremiumtools);
        }
        _disabledOptions();
        _disabledSupperTo();
        _disabledTemplates();
        //Set Default Template
        if (ee.Router.action() === 'template' && ee.Router.parameter('node1')) {
            ee.views.templates.getCopyTemplate(ee.Router.parameter('node0'), (ee.Router.parameter('node1') === 'public') ? true : false, _useThisTemplate);
        }
        delete ee.data.channels;

    }
    /*-- Assign private methods to public --*/
    that.insertChannelTemplateToTab= _insertChannelTemplateToTab
    that.show = show;
    that.setErrorCampaignName = _setErrorCampaignName;
    that.setErrorOnInput = _setErrorOnInput;
    that.saveDraftCampaign = _saveDraftCampaign;
    that.isDirty = _isDirty;
    return that;
}());
