(function Stars(root) {
    "use strict";
    // var _doTwitterConversion = function () {
    //     $.getScript("https://platform.twitter.com/oct.js", function () {
    //         twttr.conversion.trackPid('nyvdt', { tw_sale_amount: 0, tw_order_quantity: 0 });
    //     });
    // },
    //     _doLinkedinConversion = function () {
    //         _linkedin_data_partner_id = "203761";
    //         (function () {
    //             var s = document.getElementsByTagName("script")[0];
    //             var b = document.createElement("script");
    //             b.type = "text/javascript";
    //             b.async = true;
    //             b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    //             s.parentNode.insertBefore(b, s);
    //         })();
    //     },
    // _doFaceBookConversion = function () {
    //     !function (f, b, e, v, n, t, s) {
    //         if (f.fbq) return; n = f.fbq = function () {
    //             n.callMethod ?
    //                 n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    //         };
    //         if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    //         n.queue = []; t = b.createElement(e); t.async = !0;
    //         t.src = v; s = b.getElementsByTagName(e)[0];
    //         s.parentNode.insertBefore(t, s)
    //     }(window, document, 'script',
    //         'https://connect.facebook.net/en_US/fbevents.js');
    //     fbq('init', '1493750390654638');
    //     fbq('track', 'CompleteRegistration');
    // },
    //Not used
    // doGAnalyticsConversion = function () {
    //     $.getScript("https://www.googletagmanager.com/gtag/js?id=UA-84667640-1");
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag() { dataLayer.push(arguments); }
    //     gtag('js', new Date());
    //     gtag('config', 'UA-84667640-1');
    // },
    var _doGtagConversion = function () {
        $.getScript("https://www.googletagmanager.com/gtag/js?id=AW-878227499");
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-878227499');
        function gtag_report_conversion(url) {
            gtag('event', 'conversion', {
                'send_to': 'AW-878227499/3QqGCNTku2oQq-DiogM',
            });
            return false;
        }
        gtag_report_conversion();
    };

    root.Marketing = {
        getClientSource: function () {
            /* Cookies.get("eeclientsource"):
           Unknown = 0,                // all accounts before we implemented this enum have this. Also - subaccounts & resellers
           ElasticEmail = 1,           // https://elasticemail.com/
           EE_EmailMarketing = 2,      // https://elasticemail.com/email-marketing/
           EE_TransactionalEmail = 3,  // https://elasticemail.com/transactional-email/
           EE_SmallBusinesses = 4      // https://elasticemail.com/small-business/
           Mobile = 6
           Desktop = 7 */
            //return ((!Cookies.get("eeclientsource")) ? (ee.base ? 1 : 0) : Cookies.get("eeclientsource"));
            return (html.isMobile()) ? 6 : 7;
        },

        getSourceId: function () {
            if (localStorage.getItem("marketing_source")) {
                return localStorage.getItem("marketing_source")
            }

            if (ee.Router.urlParameter("utm_source")) {
                return ee.Router.urlParameter("utm_source");
            }

            if (Cookies.get("marketing_source")) {
                return Cookies.get("marketing_source");
            }
        },
        doConversions: function () {
            try {
                _doGtagConversion();
            } catch (err) { console.error(err) }
        },
        runHj: function(name){
            if (ee.base && window.hj) {
                window.hj('trigger', name);
            }
        }
    };
}(html.widget));
