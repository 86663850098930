(function (root, namespace) {
    var _generateProfileObj = function _generateProfileObj() {
        var profile = {},
            account = ee.data.account,
            accountPropToCopy = [
                "firstname", "lastname", "phone", "address1", "zip", "city",
                "state", "website", "taxcode", "logourl", "company", "address2"
            ],
            isEmpty = true;
        for (var i = 0; i < accountPropToCopy.length; i++) {

            var prop = profile[accountPropToCopy[i]] = account[accountPropToCopy[i]];

            if (prop) {
                isEmpty = false;
            };

        };

        profile["countryName"] = account["countryName"];
        profile["countryid"] = account["countryid"];
        profile.isEmpty = isEmpty;
        var needSMS = ee.data.account.needssmsverification;
        return profile;

    },
    _showSMSInfoModal = function ($container, profile) {
       var $content = html.get('SMSInfoModal', {}),
            modal = html.modal.create($content, {
                css: 'emodal-sms',
                cancel: { title: ee.t.cancel, css: "eeform_btn_cancel" },
                buttons: [{
                    name: 'Next', css: 'btn_primary btn_lg pull-right btn-add_sub',
                    callback: function () {
                        modal.close();
                        _showSMSPhoneModal();
                    }
                }]
            });
    },
    _showSMSCodeModal = function (val, typed) {

        if (!ee.data.account.smsverificationphone) ee.data.account.smsverificationphone = val;

        var $content = html.get('SMSCodeModal', { phonenumber: val }),
            modal = html.modal.create($content, {
                css: 'emodal-sms-phone',
                cancel: { title: ee.t.cancel, css: "eeform_btn_cancel" },
                title: 'SMS profile verification',
                buttons: [{
                    name: 'Verify', css: 'btn_lg btn_primary pull-right btn-send_code',
                    callback: function () {
                        var val = $content.find("#ecodevalid").val();
                        if (val.length !== 4) {
                            html.modal.info(ee.t.wrongcodeformat);
                            return false;
                        }
                        ee.api.request('/account/VerifySMSUpdateDailyLimit', { smsCode: val }, function (data) {
                            ee.indiOff();
                            if (!data.success) return html.modal.error(data);
                            modal.close();

                            ee.data.account.needssmsverification = false;

                            var profile = _generateProfileObj();

                            _showProfile($('#elegaldata'), profile);

                            _showSMSSuccessModal();
                        });
                    }
                },
                {
                    name: 'Resend', css: 'btn-default pull-left',
                    callback: function () {
                        modal.close();
                        _showSMSPhoneModal(typed);
                    }
                }
                ]
            });
        $content.find("#ecodevalid").focus();
        new html.Validator($content, {
            submit: modal.$footer.find('.btn-send_code'),
            inputs: [
                { name: 'smsCode', validFunc: function () { return this.val().length === 4 }, msg: ee.t.wrongcodeformat }
            ]
        })
    },
    _showSMSSuccessModal = function () {
        var $content = html.get('SMSSuccessModal', {username: ee.data.account.firstname}),
            modal = html.modal.create($content, {
                css: 'emodal-sms',
                cancel: { title: ee.t.close, css: "btn-default" },
            });

    },
    _showSMSPhoneModal = function (val) {
        var $content = html.get('SMSPhoneModal', { phone: !val ? ee.data.account.phone : val, countries: ee.tools.CountryList() }),

            modal = html.modal.create($content, {
                css: 'emodal-sms-phone',
                cancel: { title: ee.t.cancel, css: "eeform_btn_cancel" },
                title: 'SMS profile verification',
                buttons: [{
                    name: 'Send', css: 'btn_primary btn_lg pull-right btn-add_sub',
                    callback: function () {
                        //add countrycode before number
                        var typed = $content.find("#ephonenumber").val();
                        var val = $content.find("#ephonecountry").find(":selected").data("prefix") + $content.find("#ephonenumber").val();

                        val = _testPhoneNr(val);

                        if (!val) return html.modal.info(ee.t.wrongnumberformat);

                        ee.api.request('/account/sendverificationcode', { phone: val }, function (data) {
                            ee.indiOff();
                            if (!data.success) {
                                html.modal.error(data);
                                return false;
                            }
                            modal.close();
                            _showSMSCodeModal(val, typed);
                        });
                    }
                }]
            });
        $content.find("#ephonenumber").focus();
        $content.find("#ephonecountry").val(ee.data.account.countryid),

        new html.Validator($content, {
            submit: modal.$footer.find('.btn-add_sub'),
            inputs: [
                { name: "phone", validFunc: function () { return !!_testPhoneNr($content.find("#ephonecountry").find(":selected").data("prefix") + this.val()) }, msg: ee.t.wrongnumberformatshort }
            ]
        })

        function _testPhoneNr(val) {
            var val = val.replace(/[() -]/g, '');
            if (val.substr(0, 2) === "00") val = '+' + val.slice(2);

            if (!(/^\+[0-9]+$/.test(val)) || val.substr(0, 3) === "+00") { return false; }
            return val;
        }
    },
    _focusSection = function _focusSection($container) {
        ee.frame.content_wrapper.addClass('eblur');
        $container.addClass('efocus');
    },
    _unfocusSection = function _unfocusSection($container) {
        ee.frame.content_wrapper.removeClass('eblur');
        $container.removeClass('efocus');
    },
    _saveProfile = function _saveProfile(form, $container, opt) {
        if (ee.data.account.deliveryreason) {
            form.deliveryreason = ee.data.account.deliveryreason;
        }

        return EE_API.Account.UpdateProfile(form)
        .then(function(data) {
            return EE_API.Account.Load()
        })
        .then(function(data) {
            data.countryName = form.countryName;
            $.extend(ee.data.account, data)
            var profile = _generateProfileObj();
            if (!opt.nofocus) _unfocusSection($container);
            _showProfile($container, profile);
        })
        .catch(function(err) {
            console.log(err);
        })
    },
    _savePassword = function (form, callback) {
        try {
            if ((sessionStorage["parentemail"] || ee.session.isInspection()) && sessionStorage.subaccountemailtouse) {
                delete form.currentpassword;
            }
        } catch(err) {
            console.log(err);
            console.log("You need to enable session storage");
        }

        if (ee.data.account.isuser) {
            return EE_API.User.ChangePassword(form)
            .then(function() {
                callback();
            })
            .catch(function(err) {
                return html.modal.error(err);
            })
        } else {
            return EE_API.Account.ChangePassword(form)
            .then(function() {
                callback();
            })
            .catch(function(err) {
                return html.modal.error(err);
            })
        }
    },
    _showEmailChange = function _showEmailChange($container) {
        let $content;
        if (ee.data.account.isuser) {
            $content = html.get('ProfileEmailSection', { profile: { email: ee.data.account.username }, readonly: true });
        } 
        else {
            $content = html.get('ProfileEmailSection', { profile: { email: ee.data.account.email }, readonly: true });
        };
        $container.empty();
        $content.appendTo($container);
    },
    _editEmailChange = function _editEmailChange($container) {
        let $content;
        if (ee.data.account.isuser) {
            $content = html.get('ProfileEmailSection', { profile: { email: ee.data.account.username }});
        } 
        else {
            $content = html.get('ProfileEmailSection', { profile: { email: ee.data.account.email }});
        }
        $container.empty();
        $content.appendTo($container);

        new html.Validator($content, {
            submit: '.esave_profile',
            inputs: [
                { name: 'newemail', validFunc: function () { return ee.tools.isValidEmail(this.val().trim()) }, msg: ee.t.eeform_error_email },
                {
                    name: 'confirmemail', validFunc: function (inputs) {
                        var email = inputs.get('name', 'newemail');
                        if (email && email.$elem) return this.val() === email.$elem.val();
                    }, msg: ee.t.eeform_email_confirm
                },
            ]
        });
    },
    _showPasswordChange = function _showPasswordChange($container) {
        var $content = html.get('ProfilePasswordSection', { profile: { email: ee.data.account.email }, readonly: true});

        $container.empty();
        $content.appendTo($container);
    },
    _editPasswordChange = function _editPasswordChange($container) {
        var $content,
            inputs = [
                    { name: 'newpassword', validFunc: function () { return this.val().length >= 6 }, msg: ee.t.eeform_error_password },
                    {
                        name: 'confirmpassword', validFunc: function (inputs) {
                            var password = inputs.get('name', 'newpassword');
                            if (password && password.$elem) return this.val() === password.$elem.val();
                        }, msg: ee.t.eeform_error_confirm
                    }
            ];

        try {
            $content = html.get('ProfilePasswordSection', { profile: { email: ee.data.account.email }, inspect: ee.session.isInspection(), parent: sessionStorage["parentemail"] });
            if (!ee.session.isInspection() && !sessionStorage["parentemail"]) {
                inputs.push({ name: 'currentpassword', validFunc: function () { return this.val() !== '' }, msg: ee.t.donotleaveblank });
            }
        } catch(err) {
            console.log(err);
            console.log("You need to enable session storage");
        }

        $container.empty();
        $content.appendTo($container);

        new html.Validator($content, {
            submit: '.esave_profile',
            inputs: inputs
        });
    },
    _showProfile = function _showProfile($container, profile) {
        var $content = html.get('ProfileDetails', { profile: profile, readonly: true, isEmpty: profile.isEmpty, needSMS: ee.data.account.needssmsverification, fullAccess: ee.session.can.ModifyProfile });

        $container.empty();
        $content.appendTo($container);
    },
    _editProfile = function _editProfile($container, profile) {
        var countries = ee.tools.CountryList(),
            $content = html.get('ProfileDetails', { profile: profile, countries: countries, isEmpty: false });
        $container.empty();
        $content.find("#eprofile_countries").val(profile.countryid);
        $content.appendTo($container);

       new html.Validator($content, {
           submit: '.esave_profile',
           inputs: [
            {
                name: 'firstname', validFunc: function (inputs) {
                    //var companyInput = inputs.get('name', 'company');
                    return this.val().trim() !== ''; //|| companyInput.$elem.val().trim() !== '';
                }
            },
            {
                name: 'lastname', validFunc: function (inputs) {
                   // var companyInput = inputs.get('name', 'company');
                    return this.val().trim() !== ''// || companyInput.$elem.val().trim() !== '';
                }
            },
            { name: 'address1', validFunc: function () { return this.val().trim() !== '' } },
            { name: 'zip', validFunc: function () { return this.val().trim() !== '' } },
            { name: 'city', validFunc: function () { return this.val().trim() !== '' } },
            /*{
                name: 'company', validFunc: function (inputs) {
                    var firstnameInput = inputs.get('name', 'firstname');
                    var lastnameInput = inputs.get('name', 'lastname');
                    return this.val().trim() !== '' || (firstnameInput.$elem.val().trim() !== '' && lastnameInput.$elem.val().trim() !== '');
                }
            },*/

            { name: 'countryid', validFunc: function () { return this.val() !== '0' }, eventType: 'change', msg: ee.t.invalidcountry }
        ]});
    },
    _showNewsletter = function _showNewsletter($container) {
        var $content = html.get('ProfileNewsletterSection', { newsletter: ee.data.account.marketingconsent });
        $container.empty();
        $content.appendTo($container);
    };
    const _showDeleteAccount = function ($container) {
        let $content = html.get('DeleteAccountSection', { });
        $container.empty();
        $content.appendTo($container);
    };
    const _showDeleteAccSurvey = function ($container) {
        let $content = html.get("DeleteAccountSurvey", {isGoogleAccount: ee.data.account.isgoogleaccount});

        $container.empty();
        $content.appendTo($container);

        _showPassword($container);

        $()

        $("input[value='notoolsIntegration']").on("change", function() {
            if ($(this).is(':checked')) {
                $("#notoolsintegrations").removeClass('hide');
            } else {
                $("#notoolsintegrations").addClass('hide');
            }
        });

        $("input[value='notUsingAnymore']").on("change", function() {
            if ($(this).is(':checked')) {
                $("#notUse").removeClass('hide');
            } else {
                $("#notUse").addClass('hide');
            }
        });

        $("input[type='checkbox']").on("change", function() {
            let checkedInputsList = $("#deleteaccountreasons").find("input:checked");

            if (checkedInputsList.length >= 3) {
                $("input[type='checkbox']").not(':checked').prop('disabled', true);
            } else {
                $("input[type='checkbox']").not(':checked').prop('disabled', false);
            }
        });

        if (!ee.data.account.isgoogleaccount) {
            $("#deleteAccountpassword").on('keyup keydown', function () {
                if (this.value !== "") {
                    $("#deleteaccount").prop('disabled', false);
                } else {
                    $("#deleteaccount").prop("disabled", true);
                }
            });
        }

        $("#deleteaccount").on("click", function() {
            const surveyScreen = $("#deleteaccountreasons");
            const query = {};
            let checkedInputsList = surveyScreen.find("input:checked");
            checkedInputsList.each(function() {
                if (this.value === "notoolsIntegration") {
                    query.notoolsIntegration = $("#notoolsintegrations").val();
                } else if ( this.value === "notUsingAnymore" ) {
                    query.notUsingAnymore = $("#notUse").val();
                } else {
                    query[this.value] = true;
                }
            });

            if ( surveyScreen.find("#improvements").val() !== "" ) {
                query.improvements = surveyScreen.find("#improvements").val();
            }

            if (!ee.data.account.isgoogleaccount) {
                query.password = $("#deleteaccountpasswordSection").find("#deleteAccountpassword").val();
            }

            if (ee.data.account.subaccountscount > 0) {
                const subaccountsModal = html.get("DeleteAccountSubAccountsModal", { subaccountcounts: ee.data.account.subaccountscount });
                let modal = html.modal.create(subaccountsModal, {
                    title: ee.t.deleteaccount,
                    buttons: [{
                        name: ee.t.delete,
                        css: 'btn btn_lg_basic btn_destructive',
                        callback: function () {
                            _fetchDeleteAccount(query);
                            modal.close();
                        }
                    }],
                    size: 'emodal-sm',
                })
            } else {
                let modal = html.modal.create(ee.t.deleteaccountconfirm, {
                    title: ee.t.deleteaccount,
                    buttons: [{
                        name: ee.t.delete,
                        css: 'btn btn_lg_basic btn_destructive',
                        callback: function () {
                            _fetchDeleteAccount(query);
                            modal.close();
                        }
                    }],
                    size: 'emodal-sm',
                })
            }
        })

    };

    const _showPassword = function ($screen) {
        $screen
        .on('mousedown', '.action-showpassword', function () {
            $(this).closest('.form-group').find('input').prop('type', 'text');
        })
        .on('mouseup mouseleave', '.action-showpassword', function () {
            $(this).closest('.form-group').find('input').prop('type', 'password');
        })
        .on('touchstart', '.action-showpassword', function () {
            let passwordinput = $(this).closest('.form-group').find('input');
            passwordinput.prop('type', passwordinput.prop('type') == "password" ? "text" : "password");
        })
    };

    const _fetchDeleteAccount = function (query) {
        return EE_API.Account.DeleteAccount(query)
        .then(() => {
            ee.session.can = {};
            ee.changeHashQuietly(`/account-delete-confirmation`);
            ee.views["account-delete-confirmation"].logout();
        })
        .catch(err => {
            console.error(err);
        })
    }
    let that = {
        show: function ($screen) {
            if (!$screen) $screen = ee.frame.content;
            if (ee.Router.action() === "profile") {
                var $content = html.get("Profile", { isUser: ee.data.account.isuser, isSub: ee.data.account.issub, isReseller: ee.isReseller() });
                $screen.empty();
                $content.appendTo($screen);
            } else {
                var $content = $screen;//html.get("AccountSecurityProfile", { isSub: ee.data.account.issub });
            }

            if ($content.find("#eprofile_section")) {
                that.showProfileSection($content.find("#eprofile_section"));
            }
            if (ee.session.can.ModifyProfile) {
                that.showEmailSection($content.find("#eemail_section"));
                if (!ee.data.account.isgoogleaccount) {
                    that.showPasswordSection($content.find("#epassword_section"));
                }

                if ($content.find("#enewsletter_section")) {
                    if (!ee.data.account.issub || ee.isReseller()) {
                        that.showNewsletterSection($content.find("#enewsletter_section"));
                    }
                }

                if ($content.find("#edeleteaccount_section")) {
                    if (!ee.data.account.issub) {
                        that.showDeleteAccountSection($content)
                    }
                }
            }


        },
        showEmailSection: function ($container) {
            _showEmailChange($container);
            $container.on('click', '.eedit_profile', function () {
                if (ee.frame.content_wrapper.hasClass('eblur')) return false;
                _focusSection($container);
                _editEmailChange($container);
            });
            $container.on('click', '.ecancel_profile', function () {
                _unfocusSection($container);
                _showEmailChange($container);
            });
            $container.on('click', '.esave_profile', function () {
                var $this = $(this),
                    query = $container.elasticCollectForm();
                    query.sourceurl = ee.baseurl;
                    if (ee.data.account.isuser) {
                        return EE_API.User.ChangeEmail(query)
                        .then((data) => {
                            ee.indiOff();
                            html.modal.info(ee.t.checkyourinbox);
                            _unfocusSection($container);
                            return _showEmailChange($container);
                        })
                        .catch((error) => {
                            html.modal.error(error)
                        })
                    } else {
                        return EE_API.Account.ChangeEmail(query)
                        .then((data) => {
                            ee.indiOff();
                            if (ee.data.account.issub) {
                                html.modal.info(ee.t.emailchanged);
                                ee.data.account.email = query.newemail;
                                $container.find('input[name="curentemail"]').val(query.newemail);
                            } else {
                                html.modal.info(ee.t.checkyourinbox);
                            }
                            _unfocusSection($container);
                            return _showEmailChange($container);
                        })
                        .catch((error) => {
                            html.modal.error(error)
                        })
                    }
            });
        },
        showPasswordSection: function ($container) {
            _showPasswordChange($container);
            $container.on('click', '.eedit_profile', function () {
                if (ee.frame.content_wrapper.hasClass('eblur')) return false;
                _focusSection($container);
                _editPasswordChange($container);
            });
            $container.on('click', '.ecancel_profile', function () {
                _unfocusSection($container);
                _showPasswordChange($container);
            });
            $container.on('click', '.esave_profile', function () {
                var $this = $(this),
                    form = $container.elasticCollectForm();
                    if ($('#expireDashboardSessions').is(':checked')) {
                        form.expireDashboardSessions = true;
                        html.modal.confirm(`${ee.t.passwordchangedlogout} </br></br><div class='apichange-alert'><i class="fas fa-exclamation-circle error-icon"></i>${ee.t.apichanged}</div>`, 'Account password', () => {
                            _savePassword(form, ee.logout);
                        }, null, {
                            size: 'emodal-md',
                            confirmTitle: 'Log Out'
                        });
                    } else {
                        _savePassword(form, function () {
                            _unfocusSection($container);
                            _showPasswordChange($container);
                            html.modal.info(ee.t.passwordchangednologout, 'Account password');
                        });
                    }
            });
        },
        showProfileSection: function ($container, opt) {
            var profile = _generateProfileObj(),
                opt = opt || {};

            _showProfile($container, profile);

            $container.on('click', '.eedit_profile', function () {
                if (!ee.session.can.ModifyProfile) return false;
                if (ee.frame.content_wrapper.hasClass('eblur')) return false;
                if (!opt.nofocus) _focusSection($container);
                profile = _generateProfileObj();
                _editProfile($container, profile);
            });
            $container.on('click', '.ecancel_profile', function () {
                if (!opt.nofocus) _unfocusSection($container);
                _showProfile($container, profile);
            });

            $container.on('click', '#logoUpload', () => {
                html.widget.FileManager.init((url, listname) => {
                    if (url) {
                        $container.find('.logo__input').val(url);
                        $container.find('.profile_img').attr('src', url);
                    }
                }, "images");
            });

            $container.on('click', '.esave_profile', function () {
                var $this = $(this);
                var form = $container.elasticCollectForm();
                form.countryName = $('#eprofile_countries :selected').text();
                form.logoUrl = $('.logo__input').val();

                _saveProfile(form, $container, opt)
            });

            $container.on('click', '.eshow_smsveryfication', function () {
                that.showSmsVeryfication();
            });
            if (ee.Router.controller() != 'campaigncreator' && ee.views.account._checkProfileCompleteWithoutSMS() && ee.data.account.needssmsverification) {
                that.showSmsVeryfication();
            }
        },
        showSmsVeryfication: function () {
            var phonenumber = ee.data.account.smsverificationphone;
            if (!phonenumber) {
                _showSMSInfoModal();
            } else {
                _showSMSCodeModal(phonenumber);
            }
        },
        showNewsletterSection: function ($container) {
            _showNewsletter($container);
            $container.on('click', 'input[type=radio]', function () {
                if (ee.frame.content_wrapper.hasClass('eblur')) return false;
                var $this = $(this),
                    val = !!Number($this.val());
                    ee.api.request('/account/UpdateMarketingOptions', { marketingOptions: val ? "NewsletterAlertDisplayed,NewsletterSubscribed" : "NewsletterAlertDisplayed,NewsletterUnsubscribed"}, function() {
                        ee.indiOff();
                    });
                //#3508 - remove /account/UpdateMarketingConsent when we moved entirely to MarketingOptions
                ee.api.request('/account/UpdateMarketingConsent', { marketingConsent: val }, function (data) {
                    ee.indiOff();
                    if (!data.success) {
                        _showNewsletter($container);
                        return html.modal.error(data);
                    }
                    ee.data.account.marketingconsent = val;
                    _showNewsletter($container);
                });
            })
        },
        showDeleteAccountSection: function ($container) {
            _showDeleteAccount($container.find("#edeleteaccount_section"));

            $("#deleteaccGotoSurvey").on("click", function() {
                _showDeleteAccSurvey($container);
            })
        }
    }


    namespace.ProfileUI = that;
}(this, html.widget));
