(ee.views["dashboard"] = function () {

    let that = {
        show: () => {
            

                return ee.initNextGen();
        }
        };
        return that;
    }());
