(function (root) {
    var $content, _filestoupload, _uploadModal, _userfiles, _countUploadedFiles = 0,
        _maxFileSize = 20971520, //20 MB
        _regFileName = new RegExp('(\\\|\\/|:|\\*|\\?|"|<|>|\\||\\+|#|&|%|\\^|\\(|\\)|\\s+)', 'g'),
        _extBlock = new RegExp("\\.(ade|adp|bat|chm|cmd|com|cpl|exe|hta|ins|isp|jar|js|jse|lib|lnk|mde|msc|msi|msp|mst|nsh|pif|scf|scr|sct|shb|svg|sys|vb|vbe||vbs|vxd|ws|wsc|wsf|wsh)$", 'i'),
        _QueueUpload = {
            onProgress: function (e) {
                this.file.loadRatio = e.loaded / e.total;
                _QueueUpdateProgressBar();
            },
            onSuccess: function (data) {
                var that = this;
                var replaceFilename;
                this.done('success', 'fa-check-circle', ee.t.fileuploadsuccessful);
                _QueueUpdateProgressBar();
                //Update _usersFIles
                if (_userfiles) {
                    _userfiles.each(function () {
                        if (this.filename.toLowerCase() === that.file.newName.toLowerCase()) {
                            replaceFilename = this.filename;
                        }
                    });

                    if (replaceFilename) {
                        _userfiles.replace('filename', replaceFilename, data.data); // TODO doesnt work!
                    } else {
                        _userfiles.add(data.data);
                    }
                }
            },
            onError: function () {
                this.done('error', 'fa-times-circle', ee.t.fileuploadunsuccessful);
            },
            done: function (css, icon, msg) {
                this.loadRatio = 1;
                _countUploadedFiles++;
                var $item = $content.find("#" + this.file.id + ":first");
                if ($item.length <= 0) return;
                $item.addClass(css).find('.infoSelectedFileToUpload').prepend('<div class="' + css + '"><i class="fa ' + icon + '" aria-hidden="true"></i> ' + msg + '</div>');
                if (_countUploadedFiles === _filestoupload.data.length) {
                    $content.progresscontainer.hide();
                    _uploadModal.$footer.find('#emodalCancel').text(ee.t.close);
                }
            }
        },
        _QueueUpdateProgressBar = function () {
            var totalRatio = 0, filesCount = 0;
            _filestoupload.each(function () {
                totalRatio += this.loadRatio;
                filesCount++;
            });
            $content.progressBar.width(((totalRatio / filesCount) * 100) + '%');
        },
        _showOverlay = function (files, shownMedia) {
            _filestoupload = new Collection.Data([]);
            _uploadModal = html.modal.create($content, {
                sticktop: true,
                css: 'uploadmodalcontainer',
                title: ee.t.uploadmedia,
                size: "emodal-lg",
                buttons: [{
                    name: ee.t.confirmupload,
                    css: 'uploadconfirm',
                    callback: function () {
                        $(this).hide();
                        $content.progresscontainer.show();
                        $content.formBtn.hide();
                        $content.fileslisttoupload.find('.edel').remove();
                        _uploadModal.$title.removeClass('line');
                        _uploadModal.$footer.find('#emodalCancel').show();

                        _filestoupload.each(function () {
                            this.uploaded = (_uploadFiles(this, _QueueUpload));
                            if (this.uploaded) {
                                this.uploaded.start();
                            }
                        });
                    }
                }],
                onClose: function () {
                    _filestoupload.each(function () {
                        var file = this;
                        if (file.duplicate) {
                            _userfiles.each(function () {
                                if (this.filename.toLowerCase() === file.newName.toLowerCase()) {
                                    var button = document.querySelector("[data-id='" + this.fileid + "']");
                                    button.setAttribute('data-url', this.url + "?" + new Date().getTime())
                                    this.url = this.url + "?" + new Date().getTime();
                                    this.mediumscreenshotfullpath = this.mediumscreenshotfullpath + "?" + new Date().getTime(); //used for refreshing view of image, because our wonderful dataModels don't allow to reload
                                }
                            });
                        }
                        if (this.uploaded && this.uploaded.xhr)
                            this.uploaded.xhr.abort()
                    });
                    _filestoupload = false;
                    _countUploadedFiles = 0;
                    html.widget.FileManager.refresh(_userfiles, shownMedia);
                },
                cancel: {
                    css: 'cancelbtn',
                    title: ee.t.Cancel
                }
            });

            //--modifying modal to customize the look
            _uploadModal.$container.addClass('uploadmodalcontainer');
            _uploadModal.$title.addClass('uploadmodaltitle line').append('<div class="emodal-close closeModal crossUpload"><i class="fa fa-times"></i></div>');
            _uploadModal.$footer.find('#emodalCancel').hide();
            _uploadModal.$footer.addClass("uploadmodalfooter");

            //-- Button event
            $content.browserfilesBtn.on('click', function (e) {
                e.preventDefault();
                $content.fileselectbrowserBtn.trigger('click');
            });

            $content.fileselectbrowserBtn[0].addEventListener('change', function (e) {
                if (e.target.files.length) {
                    _addFilesToQueue(e.target.files, shownMedia);
                }
                $content.fileselectbrowserBtn.val("");
            });

            //delete upload files
            $content.fileslisttoupload.on("click", '.edel', function (e) {
                var that = $(this);
                _filestoupload.remove('id', that.data('id'));
                that.closest('.file').remove();
                if (!(_filestoupload.get('isImg', true))) {
                    $content.imagestoupload.hide();
                };
                if (!(_filestoupload.get('isImg', false))) {
                    $content.filestoupload.hide();
                };
            });

            _addFilesToQueue(files, shownMedia);
        },
        _addFilesToQueue = function (files, shownMedia) {
            var tooBigImages = [];
            var tooBigImageNames = [];
            for (var i = 0, file; file = files[i]; i++) {
                if (file.type.indexOf("image") == 0 && ee.tools._isImgTooBig(file)) { // File is an image & is > 2MB
                    tooBigImages.push(file); // Collect all images > 2MB
                    tooBigImageNames.push(file.name); // Collect all images > 2MB names
                } else { // Images < 2MB & other files
                    _filestoupload.add(file);
                    _parseFile(file, shownMedia);
                    var $item = html.get('QueueItem', { file: file });
                    if (file.isImg) {
                        _loadImagePreview(file, $item.find('img'))
                    } else {
                        html.widget.FileManager.getIcon(file.name, $item.find('img'));
                    }

                    if ($item.hasClass('img')) {
                        $content.imagestoupload.show();
                        $content.imagestoupload.append($item);
                    } else {
                        $content.filestoupload.show();
                        $content.filestoupload.append($item);
                    }
                }
            }
            // Ask to compress images > 2MB
            if(tooBigImages.length > 0) {
                var modalTitle = tooBigImages.length > 1 ? ee.t.imagestoolarge : ee.t.imagetoolarge;
                var modalContent = (tooBigImages.length > 1 ? ee.t.weresizethem : ee.t.weresizeit) +"<br><br>"+ tooBigImageNames.join("<br>");
                html.modal.confirm(modalContent, modalTitle,
                    function() { // On accept
                        for(var i = 0, file; file = tooBigImages[i]; i++) {
                            ee.tools.compressImage(file).then(function (file) {
                                _filestoupload.add(file);
                                _parseFile(file, shownMedia);
                                var $item = html.get('QueueItem', { file: file });
                                _loadImagePreview(file, $item.find('img'))
                                if ($item.hasClass('img')) {
                                    $content.imagestoupload.show();
                                    $content.imagestoupload.append($item);
                                } else {
                                    $content.filestoupload.show();
                                    $content.filestoupload.append($item);
                                }
                            });
                        }
                    },
                    function () { // On refuse
                    }, { confirmTitle: ee.t.accept, confirmCSS: "btn_primary btn_lg", cancelTitle: ee.t.cancel, cancelCSS: "btn_lg btn_destructive btn-danger" }
                );
            }
            return false;
        },
        _loadImagePreview = function (file, $img) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $img.attr('src', e.target.result);
            }
            reader.readAsDataURL(file);
        },
        _parseFile = function (file, shownMedia) {
            file.loadRatio = 0;
            file.id = html.generateUniqueId(8);
            file.newName = file.name.replace(_regFileName, '_');
            file.errors = [];
            file.warning = [];
            file.isImg = file.type.indexOf("image") == 0;
            file.humanreadablesize = ee.tools.bytesToSize(file.size);
            _userfiles.each(function () {
                if (this.filename.toLowerCase() === file.newName.toLowerCase()) {
                    file.warning.push(ee.t.filenamealreadyexists);
                    file.duplicate = true;
                }
            });
            if (file.newName !== file.name) {
                file.warning.push(ee.t.filenamecontainsunallowedcharacters);
            }
            if (file.name.match(_extBlock) || (shownMedia === "images" && !file.isImg)) {
                file.errors.push(ee.t.filecantbeuploaded);
            }
            if (file.size > _maxFileSize) {
                file.errors.push(ee.t.filesizeexceedesmaximumallowed);
            }
        },
        _uploadFiles = function (file, options) {
            var formData = new FormData(),
                opt = $.extend(options, {
                    file: file,
                    silent: true,
                    params: {
                        version: 2,
                        apikey: ee.session.apikey(),
                        expiresAfterDays: 0,
                        name: file.newName
                    }
                });
            if (file.errors.length > 0) {
                opt.onError()
                return false;
            }
            formData.append('file', file);
            return ee.api.uploadFile(formData, '/file/upload', opt);
        },
        that = {
            init: function (files, shownMedia) {
                _userfiles = dataModel.create("UserFiles");
                $content = html.get("FileManagerUpload", {});
                _showOverlay(files, shownMedia);
            },
            quickUpload: function (file, $el) {
                //TODO: refactoring this
                if (!file) return;
                var newName = file.name.replace(/(\\|\/|:|\*|\?|"|<|>|\||\+|#|&|%|\s+)/g, '_');
                file.newName = newName;

                if (file.size > 20971520 || file.name.match(/\.(ade|adp|bat|chm|cmd|com|cpl|exe|hta|ins|isp|jar|js|jse|lib|lnk|mde|msc|msi|msp|mst|nsh|pif|scf|scr|sct|shb|svg|sys|vb|vbe||vbs|vxd|ws|wsc|wsf|wsh)$/i)) {
                    return;
                }

                var formData = new FormData();
                formData.append('file', file);
                var uploadRequest = ee.api.uploadFile(formData, '/file/upload', {
                    silent: true,
                    params: {
                        version: 2,
                        apikey: ee.session.apikey(),
                        expiresAfterDays: 0,
                        name: file.newName
                    },
                    onSuccess: function (data) {
                        data.data.url = ee.api.apiurl + "/userfile/" + (ee.data.account.publicaccountid || ee.data.account.affiliatelink) + "/" + data.data.filename;
                        that.callbackImg(data.data.url, $el);
                    },
                    onError: function (err) {
                        html.modal.error(err);
                    }
                });
                uploadRequest.start();
            },
            callbackImg: null,
            callbackFiles: null
        }

    root.FileManagerUpload = that;
}(html.widget));
