ee.views.newwebform = (function () {
    const that = {}; let screen; let form = ''; let $form; let  _contactFieldsData; let formid;
        let show = function () {
            ee.frame.content.empty();
            if (typeof CodeMirror === "undefined") {
                $.cachedScript(`${ee.tools.getLibPath()}lib/codemirror.js?version=${  ee.libVersion.codeMirror}`, { async: false, success () { ee.view.show(); } });
                return;
            }

            if (!ee.data.templates) {
                ee.api.loadTemplates();
                return;
            }
            //check form by formid and load content if possible
            screen = html.get("NewWebForm", { account: ee.data.account, templates: ee.data.templates.getAll(), webform: ee.data.webform, form: ee.data.selectedform, admin: ee.session.can.AdminView, formid });
            screen.appendTo(ee.frame.content);
            _contactFieldsData = dataModel.create("ContactFields"),
            _listsData = dataModel.create("Lists");

            $.when(_contactFieldsData.require()).then(function (data) {
                screen.choosefieldslist.empty();
                data.each(function () {
                    html.get('NewWebFormCustomField', this).appendTo(screen.choosefieldslist);
                });
            });
            $.when(_listsData.require()).then(function (data) {
                screen.formtargetlist.empty();
                _addAudience();
            });

            //ReturnUrl input
            screen.FormReturnUrl.off().on('change blur', function () {
                _updateSettings('name', 'returnUrl', screen.FormReturnUrl, attributesColl);
            });

            //Confirmation url input
            screen.FormActivationReturnUrl.off().on('change blur', function () {
                _updateSettings('name', 'activationReturnUrl', screen.FormActivationReturnUrl, attributesColl);
            });

            //AlreadyActiveReturnUrl Change
            screen.FormAlreadyActiveUrl.off().on('change blur', function () {
                _updateSettings('name', 'alreadyactiveurl', screen.FormAlreadyActiveUrl, attributesColl);
            });

            //ActivationReturnUrl Change
            screen.FormActivationTemplate.off().on('change blur', function () {
                _updateSettings('name', 'activationTemplate', screen.FormActivationTemplate, attributesColl);
            });
            if (ee.data.account.enablecontactfeatures) {
                
                screen.allowselectinglist.off().on('change blur', function () {
                    _visibleOptionalLists(!this.checked);
                });
                
                screen.notifyEmail.off().on('change blur', function () {
                    _updateSettings('name', 'notifyEmail', screen.notifyEmail, attributesColl);
                });
            }
            //Style change
            screen.style.find('select, input, textarea').on('change input', function () {
                _setStyles();
            });
            //hide palettes for not selected style
            screen.style.find('#eformstyle').change(function () {

                const selectedstyle = screen.style.find('#eformstyle').val();
                screen.style.find('#eformstylecolorpalette option').hide();
                screen.style.find(`#eformstylecolorpalette .${  selectedstyle}`).show();
                const availablecolors = screen.style.find(`#eformstylecolorpalette .${  selectedstyle}`);
                screen.style.find('#eformstylecolorpalette').val(availablecolors[0].value).change();

            });
            //open properties
            screen.choosefieldslist.on("click", "#eproperties", function (e) {
                const $that = $(this);
                    let $title = $that.find('span.title');

                if ($title.hasClass('active')) {
                    $title.removeClass('active');
                    $that.parent().next().hide();
                } else {
                    screen.choosefieldslist.find('span.title').removeClass('active');
                    screen.choosefieldslist.find('.propContainer').hide();

                    $title.addClass('active');
                    $that.parent().next().show();
                };
            });
            //change description
            screen.choosefieldslist.on('keyup', '#edescriptionProperties', function () {
                let value;
                if ($(this).val() === '') {
                    value = $(this).data('description').trim();
                } else {
                    value = $(this).val().trim();
                }
                _setProperties('description', $(this).data('description'), value);
            });
            //change title
            screen.choosefieldslist.on('keyup change', '#etitleProperties', function () {
                _setProperties('title', $(this).data('titlefield'), $(this).val());
            });
            //change placeholder
            screen.choosefieldslist.on('keyup change', '#eplaceholderProperties', function () {
                _setProperties('placeholder', $(this).data('placeholder'), $(this).val());
            });
            //change reqired param
            screen.choosefields.on("change", ".requiredLabel input", function (e) {
                e.stopPropagation();
                const check = $(this);
                const $form = _getContent();
                if (check.prop('checked')) {
                    if (check[0].value === "consenttracking") {
                        $form.find(`#${  check.prop("value")}`).prop('required', true);
                    } else {
                        $form.find(`input[name="field_${  check.prop("value")  }"]`).prop('required', true);
                    }
                    screen.find(`label[for="e${  check.prop("value")  }"] .req`).show();
                } else {
                    if (check[0].value === "consenttracking") {
                        $form.find(`#${  check.prop("value")}`).prop('required', false);
                    } else {
                        $form.find(`input[name="field_${  check.prop("value")  }"]`).prop('required', false);
                    }
                    screen.find(`label[for="e${  check.prop("value")  }"] .req`).hide();
                }
                _updateRaw($form);
            });
            /*-- Choose fields to include in form --*/
            screen.choosefields.on("change", ".mainlabel input", function (e) {
                e.stopPropagation();
                const check = $(this);
                    let propertiesOpen = screen.find(`div[data-name="${  check.prop("name")  }"]`);
                
                if (!(check.prop('checked'))) {
                    if (check[0].value === "consenttracking") {
                        _removeFormConsent(check);
                    } else {
                        _removeInput(check);
                    }
                    propertiesOpen.find('span.title').removeClass('active');
                    propertiesOpen.hide();
                    screen.find(`div[data-propContainername="${  check.prop("name")  }"]`).hide();
                    screen.find(`label[for="e${  check.prop("value")  }"] .req`).hide();
                    screen.find(`#required_${  check.prop("name")}`).prop('checked', false);
                    screen.find(`input[data-titlefield="${  check.prop("name")  }"]`).val(check.prop("name"));
                    screen.find(`textarea[data-placeholder="${  check.prop("name")  }"]`).val("").attr('placeholder', "Placeholder");
                    screen.find(`textarea[data-description="${  check.prop("name")  }"]`).text(check.prop("name"));
                } else {
                    propertiesOpen.show();
                    if (check.attr("name") === "consenttracking") {
                        _addFormConsent(check.val(), check.attr("name"));
                    } else if (check.data("type") === 'boolean') {
                        _addBoolean(check.val(), check.attr("name"));
                    } else {
                        _addInput(check.val(), check.attr("name"), (check.data('type') || "text"), "");
                    }
                }
            });
            screen.preview.on("change", ".webform-lists input", function (e) {
                const that = $(this);
                    let value = that[0].value;
                    let $form = _getContent();

                if (that[0].checked) {
                    $form.find(`.webform-lists input[value=${  value  }]`).attr('checked', true);
                } else {
                    $form.find(`.webform-lists input[value=${  value  }]`).removeAttr('checked');
                }
                _updateRaw($form);
            });
            //Add field
            screen.managecontactfields.click(function () {
                const contactFields = dataModel.create("ContactFields");

                $.when(contactFields.require())
                    .then(function () {
                        html.widget.ContactFieldsUI.init();
                    });
            });
            screen.enableCaptcha.click(function () {
                const $form = _getContent();
                const check = $(this);
                //if SCRIPTS changes make sure that backend whitelist for scripts has both old and new version of script
                if (check.prop('checked')) {
                    $form.find('input#eesubmit').before(html.get("NewWebformCaptcha", { formid}, {}, true));
                    $form.find('input#eesubmit').before('<script id="recaptchaScript" src="https://www.google.com/recaptcha/api.js?onload=grecaptchaready&render=explicit" async defer></script>');
                    $form.find('input[type=submit]').prop('disabled', 'disabled');
                    $form.find('input[name=captcha]').val("true");
                } else {
                    $form.find('#recaptchaScript').remove();
                    $form.find('#captcha').remove();
                    $form.find('input[type=submit]').prop('disabled', '');
                    $form.find('input[name=captcha]').val("false");
                }
                _updateRaw($form);
            });

            //copy button
            screen.copyhtmlcode.on("click", function () {
                screen.webformcopy.text(editor.getValue());
            });
            html.clickToCopy.init();

            //Clear form
            screen.clear.click(function () {
                if (ee.data.selectedform) {
                    ee.goTo(window.location.hash);
                } else {
                    ee.goTo(window.location.hash);
                    screen.formtargetlist.find('select').remove();
                    screen.choosefields.find('input').prop('checked', false);
                    screen.esendActivation.prop('checked', true);
                    screen.enableCaptcha.prop('checked', false);
                    screen.FormReturnUrl.val('');
                    screen.FormActivationReturnUrl.val('');
                    screen.FormAlreadyActiveUrl.val('');
                    screen.FormActivationTemplate.val('');
                    _setDefaultForm();
                    _addAudience();
                    _visibleOptionalLists(true);
                }
            });
            /*-- EDITOR EVENTS ---*/
            editor = CodeMirror.fromTextArea(screen.rawcode[0], {
                mode: 'htmlmixed',
                tabMode: 'indent',
                width: "100%",
                lineWrapping: true
            });
            let rawdelay;
            editor.on("change", function (cm) {
                clearTimeout(rawdelay);
                rawdelay = setTimeout(function () {
                    _updatePreview(cm.getValue());
                }, 300);
            });
            screen.webformnav.find('a[data-toggle="tab"]')
                .on('click', function () {
                    if (this.id === "ehtmlbutton" || this.id === "eoptionsbutton") {
                        if (window.innerWidth > 1800) {
                            screen.previewsection.animate({ width: '50%' }, 500);
                            screen.datasection.animate({ width: '50%' }, 500);
                        } else {
                            screen.previewsection.animate({ width: '33%' }, 500);
                            screen.datasection.animate({ width: '67%' }, 500);
                        }

                    } else {
                        screen.datasection.animate({ width: '33%' }, 500);
                        screen.previewsection.animate({ width: '67%' }, 500);
                    }
                }).on('shown.bs.tab', function (e) {
                    e.target; // newly activated tab
                    e.relatedTarget; // previous active tab
                    if (e.target.id === 'ehtmlbutton') { 
                        editor.refresh();
                    }

                });
            screen.webformnav.find('a#ehtmlbutton').trigger('click');

            //Start form editor
            _setDefaultForm();
            var attributesColl = _getAttributesOpt();

            //bind save controls
            screen.save.click(function () {
                _saveWithScreenshot();
            });
        };
    const showStaticWebForm = function () {
        screen = html.get("NewWebFrom", { account: ee.data.account, templates: ee.data.templates.getAll(), webform: ee.data.webform });
        screen.appendTo(ee.frame.content);
    };
    var _setProperties = function (field, name, value) {
        const $form = _getContent();

        if (field === "title") {
            $form.find(`input[name="field_${  name  }"]`).prev().text(value);
        } else if (field === 'placeholder') {
            $form.find(`input[name="field_${  name  }"]`).attr('placeholder', value);
        } else {
            $form.find(`input[name="field_${  name  }"]`).parent().find('span').text(value);
        }
        _updateRaw($form);
    };
    var _setStyles = function () {
        //lock/unlock delay on non-static display
        const $form = _getContent();
        screen.style.find('#eformposition').val() === "Inline" ? screen.style.find('#eformdelaycontainer').hide() : screen.style.find('#eformdelaycontainer').show();
        const delay = screen.style.find('#eformdelay').val();

        //disable sticky form for bigger forms and hide lists on sticky forms and reset it if it was selected
        if ($form.find('.form-control').length > 1) {
            screen.style.find('#eformposition option[value="Sticky"]').prop("disabled", true);
            screen.style.find('#eformposition').val() === "Sticky" ? screen.style.find('#eformposition').val("Inline").change() : "";
        } else {
            screen.style.find('#eformposition option[value="Sticky"]').prop("disabled", false);
        }
        //check selected style
        let style = "";
        $form.removeClass();
        $form.find("#ewf_style").removeClass();
        screen.style.find('select').each(function () {
            style += html.getraw(`NewWebFormStyle${  this.value}`, { delay, formid: formid ? formid : "" });
            $form.find("#ewf_style").addClass(this.value);
            $form.addClass(this.value);
        });

        //add/remove headers
        if (screen.style.find('#eshowformheader').prop('checked') === true) {
        //    //add header and heading text in form and options
            screen.style.find('#eformheadercontainer').show();
            $form.find('.headers').show();
            $form.find('#ewf_formheader').html(screen.style.find('#eformheadercontainer input').val());
            $form.find('#ewf_formdesc').html(screen.style.find('#eformheadercontainer textarea').val());
        } else {
        //    //clear both header and heading in form and options
            screen.style.find('#eformheadercontainer').hide();
            $form.find('.headers').hide();
            $form.find('#ewf_formheader').html('');
            $form.find('#ewf_formdesc').html('');
        }
        $form.find("#ewf_style").html(style);
        _updateRaw($form);
    };
    var _saveWithScreenshot = function () {
        const $form = _getContent();
        //generate screenshot
        // split whole HTML to HTML and style
        const formCss = $form.find('#ewf_style').html();
        $form.find('#ewf_style').html("");
        const query = {
            formName: screen.name ? screen.name.val() : ee.t.newform,
            formScope: screen.scope ? screen.scope.prop('checked') ? 1 : 0 : 0, //public : private
            formType: 0, //0 - embedded, 1 - webpage
            formHtml: $form[0].outerHTML,
            css: formCss
        };
        //_updateRaw($form);
        // save if new form (no formid), update if otherwise
        if (ee.data.selectedform.formid) {
            query.formid = ee.data.selectedform.formid;
            EE_API.Form.Update(query).then(function () {
                //removescreenshot
                delete ee.data.forms;
                ee.goTo('#/forms');
            });
        } else {
            EE_API.Form.Add(query).then(function () {
                delete ee.data.forms;
                ee.goTo('#/forms');
            });
        }
    };
    const showShardWebForm = function () { };

    /*-- PRIVATE METHODS --*/
    //The default settings
    const _setDefault = function () {
        ee.data.webform = {};
        ee.data.webform.fields = ["firstname", "lastname"];
    };
    var _visibleOptionalLists = function (hide, $form) {
        if(!$form) var $form = _getContent();
        
        if (hide === true) {
            $form.find('.webform-lists').hide();
            $form.find('.webform-lists input').attr('checked', true);
        } else {
            $form.find('.webform-lists').show();
            $form.find('.webform-lists input').removeAttr('checked');
        }
        _updateRaw($form);
    };
    const _hideSelectedList = function () {
        const $form = _getContent();
        screen.formtargetlist.find("option").show();
        $form.find('input').each(function () { 
            screen.formtargetlist.find(`option[value='${  this.value  }']`).hide();
        });

    };
    var _addAudience = function () {
        let $select = html.get("NewWebformGroupSelect", { lists: ee.data.lists.getAll() });
            let currentPubliclistId = null;

        $select.appendTo(screen.formtargetlist);

        $select.on("change", function (e, skipAppend) {
            const value = $select.val();
                let text = $select.find('option:selected').text();
                let $form = _getContent();
                let $webformlists = $form.find('div.webform-lists');

            if (currentPubliclistId === null) {
                //insert new
                const $list = html.get('NewWebFormAudience', { publiclistid: value, name: text, id: html.generateUniqueId(8) });
                $select.find('option[value=add]').remove();
                $select.find('option[value=remove]').removeClass('hidden');
                _addAudience(); //Add New trigger
                if (!skipAppend) $webformlists.append($list);
            } else if (value === 'remove') {
                //Remove 
                $webformlists.find(`input[value="${  currentPubliclistId  }"]`).closest('div').remove();
                $select.remove();
                $select = null;
            } else {
                //changedata
                const $input = $webformlists.find(`input[value="${  currentPubliclistId  }"]`);
                $input.val(value);
                $input.parent().find('label').html(text);
            }

            currentPubliclistId = value;
            if ($webformlists.find("input[name=publiclistid]").length > 1) {

                screen.multiplelistpossibility.show();
                screen.allowselectinglist.prop("checked", true);
            } else {
                screen.allowselectinglist.prop("checked", false);
                screen.multiplelistpossibility.hide();
            }

            _visibleOptionalLists(!screen.allowselectinglist.prop("checked"), $form);
            _hideSelectedList();
        });
    };
    //Add input field
    var _addInput = function (text, id, type, required) {
        //translations for most popular fields
        let title; let placeholder; let fieldname;
            let $form = _getContent();
            let $target = $form.find('.inputs');
        
        if (id !== 'email') {
            id = `field_${  id}`;

            title = screen.find(`input[data-titlefield="${  text  }"]`).val();
            placeholder = screen.find(`textarea[data-placeholder="${  text  }"]`).attr('placeholder');
        } else {
            title = ee.t.email;
            placeholder = title;
        }

        $(`<div><label for="${  id  }">${  title  }</label><input maxlength="60" class="form-control" name="${  id  }" size="20" type="${  type  }" ${  required  } placeholder="${  placeholder }"></div>`).appendTo($target);
        _updateRaw($form);
    };

    var _addBoolean = function (text, id) {
        const $form = _getContent();
        
            let $target = $form.find('.checkboxes');
            let description = screen.find(`textarea[data-description="${  text  }"]`).text();

        $(`<div><label for="${  id  }"><input id="${  id }" type="checkbox" name="field_${  id  }" value="true"><span style="display: inline;">${  description  }</span></label></div>`).appendTo($target);
        _updateRaw($form);
    };
    var _addFormConsent = function (text, id) {
        const $form = _getContent();
            let $target = $form.find('.checkboxes');
            let script = document.createElement('script');
            let code = `document.querySelector("#${  id  }").addEventListener("change", function (e) { if (document.querySelector("#${  id  }").checked) { document.querySelector("#hiddenInput").disabled = true; } else { document.querySelector("#hiddenInput").disabled = false;} });`;
        script.type = 'text/javascript';
        
        $(`<div><label for="${  id  }"><input type="checkbox" data-name="id" id="${  id  }" name="${  id  }" value="1">${  ee.t.formtrackingconsent  }</label></div>`).appendTo($target);
        $(`<div><input id="hiddenInput" type="hidden" value="2" name="${  id  }"></div>`).appendTo($target);
        script.appendChild(document.createTextNode(code));
        $form.append(script);
        _updateRaw($form);
        
    };
    //Remove input fied
    var _removeInput = function (check) {
        const $form = _getContent();
            let $input = $form.find(`input[name="field_${  check.prop("name")  }"]`);
            let $parent = $input.closest('div');
        
        if ($parent.length === 0) {
            $input.remove();
        } else {
            $parent.remove();
        }        
        _updateRaw($form);
    };
    var _removeFormConsent = function (check) {
        const $form = _getContent();
            let $input = $form.find(`input[name="${  check.prop("name")  }"]`);
            let $parent = $input.closest('div');
        
        if ($parent.length === 0) {
            $input.remove();
        } else {
            $parent.remove();
        }
       // $input[1].parent().remove();
        $form.find('script').remove();
        _updateRaw($form);
    };
    const _preSelectFields = function ($form) {
        const selectedfields = $form.inputs[0].children;

        for (let i = 0; i < selectedfields.length; i++) {
            const name = $(selectedfields[i]).find("input")[0].name.replace("field_", "");
            if (screen.choosefieldslist.find(`#e${  name}`)) {
                const that = screen.choosefieldslist.find(`#e${  name}`);
                that.attr('checked', true);
            }
        }
    };
    const _preSelectCheckboxes = function ($form) {
        const selectedcheckboxes = $form.checkboxes[0].children;
        
        for (let i = 0; i < selectedcheckboxes.length; i++) {
           
            const name = $(selectedcheckboxes[i]).find("input")[0].name.replace("field_", "");
            if (screen.choosefieldslist.find(`#e${  name}`)) {
                const that = screen.choosefieldslist.find(`#e${  name}`);
                that.attr('checked', true);
            }
        }
    };
    const _preProperties = function ($form) {
        const selectedinputproperties = $form.inputs[0].children;
        const selectedcheckboxproperties = $form.checkboxes[0].children;
        const combined = [].concat(Array.prototype.slice.call(selectedinputproperties), Array.prototype.slice.call(selectedcheckboxproperties));

        for (let i = 0; i < combined.length; i++) {
            const name = $(combined[i]).find("input")[0].name.replace("field_", "");
                let required = $(combined[i]).find("input")[0].required;
                let label = $(combined[i]).find('label').text().trim();
                let placeholder = $(combined[i]).find("input")[0].placeholder;

            if (name === 'email' || $(combined[i]).find("input")[0].type === "hidden") {
                continue;
            }
            
            if (screen.choosefieldslist.find(`#e${  name}`)) {
                const that = screen.choosefieldslist.find(`#e${  name}`);
                    let containerField = that.parent().parent();
                    let properties = containerField.next();

                containerField.find('#eproperties').show();
                properties.find('#etitleProperties').val(label);
                properties.find('#eplaceholderProperties').attr('placeholder', placeholder);
                properties.find('#edescriptionProperties').val(label);

                if (required) {
                    containerField.next().find('input[name="required"]').attr('checked', 'true');
                    that.parent().find('span').show();
                }
            }
        }
    };
    const _preSelectOptions = function ($form) {
        //lists 
        const selectedlists = $form.lists[0].children;
        for (let i = 0; i < selectedlists.length; i++) {

            const listId = $(selectedlists[i]).find('input').val();
                let select = $('.etargets')[i];

            $(select).val(listId).trigger('change', true);
        }

        //check if option to select lists is selected
        if (selectedlists.length > 1) {
            screen.multiplelistpossibility.show();
            if ($form.lists[0].style.display !== 'none') {
                screen.allowselectinglist.attr('checked', true);
            } else {
                screen.allowselectinglist.attr('checked', false);
            }
        }
        //URLs and templates
        screen.FormReturnUrl.val($form.find('input[name=returnUrl]').val());
        screen.FormActivationReturnUrl.val($form.find('input[name=activationReturnUrl]').val());
        screen.FormAlreadyActiveUrl.val($form.find('input[name=alreadyactiveurl]').val());
        screen.FormActivationTemplate.val($form.find('input[name=activationTemplate]').val());
        screen.notifyEmail.val($form.find('input[name=notifyEmail]').val());

        //recaptcha - we don;t store it, but generate it each time
        if ($form.find('input[name=captcha]').val() === "true") {
            screen.enableCaptcha.attr('checked', true);
        }
    };
    var _setDefaultForm = function () {
        if (ee.data.selectedform) {
            form = ee.data.selectedform.html;
            $form = $(form);
            formid = $form.data('id');
            //make sure that form will always have proper publicaccountid and formid
            $form.find('input[name="publicaccountid"]').val(ee.data.account.publicaccountid);
            //$form.find('input[name="publicformid"]').val(ee.data.selectedform.formid);
            $form.find('#ewf_style').html(ee.data.selectedform.css);

            //make sure that form will have proper action api url
            $form.attr("action", ee.api.apiurl + '/contact/add?version=2');

            if (ee.data.account.enablecontactfeatures) $form.lists = $($form.find('div.webform-lists:first'));
            $form.inputs = $($form.find('div.inputs:first'));

            if (!($form.find('div.checkboxes').length)) {
                $('<div class="checkboxes"></div>').insertAfter($form.find('.inputs'));
                _updateRaw($form);
            }
            
            $form.checkboxes = $($form.find('div.checkboxes'));            

            _updateRaw($form);
            //select fields used in form
            $.when(_contactFieldsData.require()).then(function (data) {
                _preSelectFields($form);                
                _preSelectCheckboxes($form);
                _preProperties($form);
            }).catch(function (err) { console.log(err); });

            $.when(_listsData.require()).then(function (data) {
                _preSelectOptions($form);
            });

            let styleClasses = $form.find('#ewf_style').attr("class").split(' ');
            $form.addClass(styleClasses);
            for (let i = 0; i < styleClasses.length; i++) {
                screen.style.find(`option[value="${  styleClasses[i]  }"]`).attr('selected', 'selected').trigger('change');
            }

            //add headers if they exist
            let formheadercontent = $form.find('#ewf_formheader').text().trim();
            let formdescriptioncontent = $form.find('#ewf_formdesc').text().trim();
            if (formheadercontent.length > 0 || formdescriptioncontent.length > 0) {
                screen.style.find('input[name="eshowformheader"]').prop("checked", true);
                screen.style.find('input[name="eformtitle"]').val(formheadercontent).trigger('change');
                screen.style.find('textarea[name="eformdescription"]').val(formdescriptioncontent).trigger('change');
            }

            return;
        }
        formid = html.generateUniqueId(10);
        form = `
        <form action="${  ee.api.apiurl  }/contact/add?version=2" data-id="${  formid  }" method="post" id="ewf_subscriptionForm_${  formid  }" ><div class="headers" style="display:none"><h4 id="ewf_formheader"></h4><p id="ewf_formdesc"></p></div><fieldset style="border:none;" class="webform-custominputs"><div class="inputs"></div><div class="checkboxes"></div>
        <div class="webform-lists"></div></fieldset><fieldset class="webform-options" style="border:none;">
        <input type="submit" name="submit" value="${  ee.t.subscribe  }" id="eesubmit">
        <input type="hidden" name="publicaccountid" value="${  ee.data.account.publicaccountid  }">
        <input type="hidden" name="returnUrl" value="${  screen.FormReturnUrl.val()  }">
        <input type="hidden" name="activationReturnUrl" value="${  screen.FormActivationReturnUrl.val()  }">
        <input type="hidden" name="alreadyactiveurl" value="${  screen.FormAlreadyActiveUrl.val()  }">
        <input type="hidden" name="activationTemplate" value="${  screen.FormActivationTemplate.val()  }">
        <input type="hidden" name="source" value="WebForm">';
        <input type="hidden" id="ewf_captcha" name="captcha" value="false">';
        `

        if (ee.data.account.enablecontactfeatures) form += `<input type="hidden" name="notifyEmail" value="${  screen.notifyEmail.val()  }">`;
        form += `
        <button id="ewf_dismissForm" style="display:none; position: absolute; top: 0; right: 0; float: right">X</button>
        </fieldset><div id="ewf_style" class="Inline GrayScale LabelsAndPlaceholders"></div></form>
        `
        editor.setValue(form);
        _formatRaw();
        _addInput("Email", "email", "email", "required");
        
        //set default style
        screen.style.find('.default').attr('selected', 'selected').trigger('change');

        

    };
    
    var _formatRaw = function () {
        editor.autoFormatRange({ line: 0, ch: 0 }, { line: editor.lineCount(), ch: editor.getTextArea().value.length }, true);
        editor.setSelection({ line: 0, ch: 0 }, { line: 0, ch: 0 });
    };
    var _getAttributesOpt = function () {
        const $form = _getContent();
            let $formopt = $form.find('.webform-options');
            let attributesArr = [];
            let attributesCollection;
            let attributesObj;

        $formopt.find('input').each(function (index, elem) {
            attributesArr[index] = {};
            $.each(this.attributes, function (ind, el) {
                attributesArr[index][this.name] = this.value;
            });
        });
        attributesCollection = new Collection.Data(attributesArr);

        return attributesCollection;
    };

    var _updateSettings = function (key, value, input, attributesCollection) {
        const $form = _getContent();
            let $formopt = $form.find('.webform-options');
            let attributesObj = attributesCollection.get(key, value);

        if ($formopt.length > 0) {

            const $htmlInputEl = $form.find(`input[${  key  }='${  value  }']`);
            let $inputValue = $(input).val();
            if (input[0] === $('input#enotifyEmail')[0])
                $inputValue = $inputValue.replace(/\s+/g, '');

            if ($htmlInputEl.length > 0) {
                $htmlInputEl.val($inputValue);
            } else {
                attributesObj.value = $inputValue;

                const $input = $('<input/>').attr(attributesObj);
                $formopt.append($input);
            }
        } else {
            const documentFragment = document.createDocumentFragment();
            $formopt = $('<fieldset class="webform-options" style="border:none;"></fieldset>');

            attributesCollection.each(function (elem) {
                const $input = $('<input/>').attr(attributesCollection.data[elem]);
                documentFragment.appendChild($input[0]);
            });

            $formopt.append(documentFragment);
            $form.append($formopt);
        }

        _updateRaw($form);
    }; 

    var _updateRaw = function ($form) {
        if (!$form) $form = _getContent();
        editor.setValue($form[0].outerHTML);
        _formatRaw();
    };
    var _updatePreview = function (html) {
        screen.rawpreview.html(`<div>${  html  }</div>`);
    };
    var _getContent = function () {
        const $form = $(editor.getValue());
        return $form;
    };
    that.show = show;
    return that;
}());