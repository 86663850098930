/**
* Controller from the template screen, allows to manage templates and run editors.
* @namespace
* @memberof ee.views
*/
ee.views['diagnostic'] = (function ViewDiagnostic() {
    "use strict";

    var that, $screen
        /*-- Private Methods --*/

    /*-- PUBLIC --*/
    that = {
        show: function () {
            var action = ee.Router.action();
            switch (action) {
                case 'details':
                    break;
                default:
                    break;
            }
        }
    };

    return that;
})();