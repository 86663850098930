(function (root) {
    let $content;
    let _overlay;
    let _url = null;
    let _lazyThreshold;
    let _elemsLoaded;
    let _orderSort = 'desc';
    let _propertyNameSort = 'fileid';

    let pagination;
    let activeTab;
    let that = {

        toggleStyle: (elem, formId, useStyle) => {

            let switchClosure = () => {
                let all = ee.data.forms.data.concat(ee.data.globalforms.data)
                let form = null;

                formId = formId.substr(4, formId.length-14).replace(/:/g, '_');
                formDate = formId.replace(/_/g, ':');
                all.forEach(function(i) {
                    if (i.dateadded == formDate) {
                        form = i;
                    }
                });
                let css = form.css;
                let html = form.html;
                addIdToCssClasses(css, formId, html, elem, useStyle)
            }
            if (!ee.data.forms) {
                that._loadForms(true).then(function () {
                    switchClosure();
                });
            } else {
                switchClosure();
            }



        },


        init: function (chooseFormCallback, closeCallback) {
            // that.show();
            that._loadForms();

            that.chooseFormCallback = chooseFormCallback;
            $content = html.get("FormSelector", {});
            $content.appendTo(ee.frame.content);
            ee.t.dynamicnoforms = ee.t.noforms;
            pagination = new html.Pagination([], {
                template: 'FormsListPreviewInTemplates',
                searchparameters: ['name'],
                resultcontainer: $content.paginationresult,
                footercontainer: $content.resultfooter,
                perpage: 12,
                paginationinfo: false,
                firstlast: false
            });
            _overlay = new html.Frameoverlay($content, null, closeCallback);
            _overlay.$container.addClass("FormSelectoverlay");

           //Change page event
            pagination.eventChangePage((e) => {
                e.stopPropagation();
                html.widget.FormThumbnail.init($content);
            });
            //Bind Tabs
            $content.formtypetabs.on('click', 'li', function (e) {
                e.stopPropagation();
                $('#eformtypetabs').find('li').removeClass('active');
                $content.searchinputforms.val("");
                pagination.setPage(1);
                let $this = $(this);
                let type = $this.data("type");
                $this.addClass('active');

                if (type === 'gallery') {
                    ee.t.dynamicnoforms = ee.t.notemplatesglobal;
                    pagination.updateData(ee.data.globalforms.getAll());
                } else {
                    ee.t.dynamicnoforms = ee.t.noforms;
                    pagination.updateData(ee.data.forms.getAll());
                }

                activeTab = type;
                html.widget.FormThumbnail.init($content);

            });
            //Bind Search
            html.bindSearch($content.searchforms, (searchstring) => {
                ee.t.dynamicnoforms = ee.t.nosearchresults;
                pagination.searchfilter(searchstring);
                html.widget.FormThumbnail.init($content);
            });

            //Refresh button
            $content.refresh.off().click(() => {
                that._loadForms().then(() => {
                    $content.searchforms.find('input').trigger('keyup');
                });
            });
            //Create button
            $content.on('click', '#ecreateform', () => {
                ee.goTo('#/forms/create');
            });

            $('#epaginationresult').on('click', 'div.forminside', function (e) {
                e.stopPropagation();

                let all = ee.data.forms.data.concat(ee.data.globalforms.data)
                let formId = $(this).closest('.gallery-item').data('dateadded').replace(/:/g, '_');
                formDate = formId.replace(/_/g, ':');
                var form = null;
                all.forEach(function(i){
                    if (i.dateadded == formDate) {
                        form = i;
                    }
                });

                that.chooseFormCallback(formId ,form.html, form.css);

                _overlay.$container.find('.eframeoverlay-close').click();
            })
            //Bind Options
            $content.on('click', '.action-options', function (e) {
                e.stopPropagation();
                $(this).closest('.edeadcenter').addClass('active')

            });
            $content.on('mouseleave', '.gallery-item', function () {
                $(this).find('.edeadcenter').removeClass('active');
            });
            $content.on('click', '.gallery-container', function (e) {
                e.stopPropagation();
                $(this).find('.edeadcenter.active').removeClass('active');
            });

            //Preview
            $content.on('click', '.gallery-preview', function (e) {
                e.stopPropagation();
                let $galleryitem = $(this).closest('.gallery-item');
                _previewContent($galleryitem.data('formid'), $galleryitem.data('ispublic'));
            });
        },
        _switchViews: () => {
            var formid = ee.Router.parameter('node0');
            switch (ee.Router.action()) {
                case 'create':
                    ee.logEvent('Forms - create new form');
                    ee.plugins.webform.init();
                    return;
                case 'copy':
                    if (formid) {
                        ee.logEvent('Forms - copy');
                        ee.data.selectedform = _copyForm(formid, false);
                        ee.plugins.webform.init();
                        return;
                    }
                case 'gallerycopy':
                    if (formid) {
                        ee.logEvent('Forms - gallery copy');
                        ee.data.selectedform = _copyForm(formid, true);
                        ee.plugins.webform.init();
                        return;
                    }
                case 'edit':
                    ee.logEvent('Forms - edit form');
                    _setSelectedForm(ee.Router.parameter('node0'), false, function () {
                        ee.plugins.webform.init();
                    });
                    return;
                default:
                    activeTab = ee.Router.action();
                    if (activeTab === 'show') activeTab = '';
                    ee.logEvent('Forms screen loaded');
                    _showForms();
                    break;
            }
        },
        show : () => {
            ee.data.selectedform = false;
            ee.frame.content.empty();
            if (!ee.data.forms) {
                _loadForms().then(function () {
                    _switchViews();
                });
                return;
            } else {
                _switchViews();
            }
        },
        _loadForms : function (skipUI) {
            return EE_API.Form.List().then(function (data) {
                ee.data.forms = new Collection.Data(data.forms);
                ee.data.forms.each(function () {
                    if (this.screenshot) {this.screenshotpath = ee.api.path + "/userfile" + this.screenshot;}
                    if (this.mediumscreenshot) {this.mediumscreenshotpath = ee.api.path + "/userfile" + this.mediumscreenshot;}
                    if (this.thumbnail) {this.thumbnailpath = ee.api.path + "/userfile" + this.thumbnail;}
                })
                ee.data.globalforms = new Collection.Data(data.globalforms);
                ee.data.globalforms.each(function () {
                    if (this.screenshot) {this.screenshotpath = ee.api.path + "/userfile" + this.screenshot;}
                    if (this.mediumscreenshot) {this.mediumscreenshotpath = ee.api.path + "/userfile" + this.mediumscreenshot;}
                    if (this.thumbnail) {this.thumbnailpath = ee.api.path + "/userfile" + this.thumbnail;}
                })

                if (skipUI) {
                    return;
                }

                if (!activeTab) {
                    if (ee.data.forms.getLength() === 0 && ee.data.globalforms.getLength() > 0) {
                        $content.formtypetabs.find('li[data-type=gallery]').trigger('click');
                    } else {
                        $content.formtypetabs.find('li[data-type]:first').trigger('click');
                    }
                } else {
                    $content.formtypetabs.find('li[data-type=' + activeTab + ']').trigger('click');
                }

            }).catch(function (err) { console.log(err) });
        },
    }
    root.FormSelect = that;
}(html.widget));

