(function (root, namespace) {
    /* New payments available for accounts on Unlimited and Unlimited Pro plans.
     * These accounts can make purchases, that will immediately charge their accounts for reduced fee for existing builling period, and fully for next ones.
     * Methods used in API: https://elasticemail.lighthouseapp.com/projects/113844-development/tickets/3265-api#ticket-3265-6
     * The same methods are used for plan/card changes/purchase in payments.js - there we are using plan and maxcontacts params during checkout
     * register is used for registering card VALIDATED with other methods - both new and edited.
     * checkout sends list of items (plans, ips, contacts, premiumsupport) and returns specified cost of this purchase, with the unique id. This ID is used in charge.
     * charge - finishes the purchase
     *
     *
     * Two flows here, one for account with existing payment and (second one for registering new card)
     * 0 Payment/billing/cart data is stored in buyerPersona - one aggregate for all the data we gather from different sources and use for realisation of transaction
     * 1 html.widget.PaymentPanel.init() - used for generating payment panel for selected conditions. Panel usually contains two Actionbuttons (next and back), that move user around. Between steps we are checking and registering data to show in next steps
     *
     *
     * 2 _purchaseScreen - showing items available for sale. If user doesn't have CC registered, we push them to:
     * 3 (_showBillingData) - shows form with personal data. Form is getting validated, and validated form can be registered
     * 4 (_register &_getStripeData) - same stuff, but this time with both user and card data. Both of these are validated to create Payment/Register API request, which connects dashboard and card
     * _checkout - mandatory. Checks stuff user wants to buy. Might contain PrivateIp,Premium support, Plans and number of contacts. It returns cost (reduced, if able) of these items to be charged for the end of actual billing month.
     *  _charge - mandatory.using data from checkout, this method sends information to us/Stripe to send us money.
     *
     * Helper methoids:
     * _checkCardInput, _GetCardType, _ValidateInputs, _getBillingData - getting and manipulating data.
     *
     * _finishPayment, _unsuccesfulPayment, _showProcessing - showing state of payment
     *
     * TO DO:
     * styling for panel during Premium Support purchase
     * double-checking with Seba that user can safely switch/upgrade plans (requests are send are responded to properly)
     *
     * */
    let cart;

    let container;
    let content;
    let paymentdata = { default: true };
    let actionbuttons;
    let nextbutton;
    let backbutton;
    let cardform;
    let buyerPersona;
    let _validMarks = { success: 'has-success', error: 'has-error' };
    var that = {
        /*
         * Method for checking provided card number for one of the recognized cards. Thanks to that input gets class and image for card found;
         */
        _checkCardInput: function ($container) {
            $container.find('#epaycardnumber').on('change keydown', function () {
                if ($(this).val().length >= 3) {
                    $(this)
                        .parent()
                        .removeClass('visa mastercard amex')
                        .addClass('cc')
                        .addClass(that._GetCardType($(this).val()));
                }
            });
        },

        _checkout: function (content) {
            //WORKS FOR UNLIMITED, FOR OTHER IMMEDIATELY GO TO that._finishpayment()
            //add card details
            content.off().on('click', '.action-edit', function () {
                //if payment exists - ask them to go to another screen for payment edition
                if (ee.data.account.autocreditstatus == 'on') {
                    var paymentexistsmodal = html.modal.confirm(
                        ee.t.ccinusetext,
                        ee.t.ccinusetitle,
                        function () {
                            ee.api.loadProviderData(function () {
                                ee.goTo('#/payments');
                            });
                        },
                        '',
                        {
                            confirmTitle: ee.t.ccinuseconfirm,
                            cancelTitle: ee.t.ccinusecancel,
                            size: 'emodal-md',
                        }
                    );
                    return;
                } else {
                    //create new view from fields
                    //var paymentpart = html.get("PaymentCheckoutEditable", {purchase: buyerPersona});
                    var paymentbillingpart = html.get('PaymentBillingData', { account: ee.data.account });
                    var paymentcardpart = html.get('NewPaymentTypeRegistration', { account: ee.data.account });
                    content.empty().append(paymentbillingpart).append(paymentcardpart);
                    that._ValidateInputs($(content), $('.action-next'));
                    that._checkCardInput(content);
                    var $countrylist = html.get('CountryDropdown', {
                        countries: ee.tools.CountryList,
                        readonly: false,
                    });
                    $countrylist.appendTo(content.find('#epaycountry'));
                    $countrylist.on('click', 'input', function () {
                        $countrylist
                            .find('#ecountrydropdown')
                            .html(
                                $(this).parent().text() +
                                    " <i style='position: absolute;right: 10px;top: 12px;' class='fa fa-caret-down'></i>"
                            );
                    });
                    $countrylist
                        .find('[value=' + ee.data.account.countryid + ']')
                        .prop('checked', true)
                        .click();
                    //$('.action-next').addClass('disabled');
                    var checkoutpart = html.get('CheckoutTable', { data: buyerPersona.Cart });
                    content.prepend(checkoutpart);
                }
            });

            const { provider } = ee.data.account.billingdetails;

            const _showCheckout = (data) => {
                var cardforms = content;
                var paymentpart = html.get('PaymentCheckout', { purchase: buyerPersona });
                content.empty().append(paymentpart);
                cardforms.appendTo(content);
                buyerPersona.Cart = {};
                data.dedicatedsupport = data.dedicatedsupportcost > 0;
                data.privateips = data.privateipcount > 0;
                data.totalcost = data.sum = data.dedicatedsupportcost + data.privateipcost;
                if (data.privateipregion === "NA") {
                    data.privateipregiondisplay = "North America"
                };
                if (
                    ee.data.account.discountpercent &&
                    (moment().diff(ee.data.account.discountexpiry, 'days') <= 0 || !ee.data.account.discountexpiry)
                ) {
                    //discount expiry in the future or non-expiring one
                    data.discount = (
                        (data.dedicatedsupportcost + data.privateipcost) *
                        ee.data.account.discountpercent
                    ).toFixed(2);
                    data.percent = ee.data.account.discountpercent ? ee.data.account.discountpercent * 100 : 0;
                    data.discounttime = ee.data.account.discountexpiry
                        ? moment(ee.data.account.discountexpiry).format('L')
                        : null;
                    data.totalcost = (data.sum * (1 - ee.data.account.discountpercent)).toFixed(2);
                }
                $.extend(buyerPersona.Cart, data);
                var checkoutpart = html.get('CheckoutTable', { data: buyerPersona.Cart });
                content.prepend(checkoutpart);
                //interactions - EDIT should open form in same screen
                content.find('#echangepayment').on('click', function () {
                    that._register($('#eregisteredpayments'), cart, true);
                });

                nextbutton
                    .removeClass('hide')
                    .text(ee.t.makepayment)
                    .off()
                    .on('click', function () {
                        //if card was registered moment ago - register. if not - charge right away
                        //here we're registering payment
                        that._showProcessing(content);
                        //show "processing" screen

                        if (provider.name === 'Stripe') {
                            const { checkouttoken } = data;
                            const type = ee.views.payments.getPaymentType('Charge');
                            return EE_API.Payment.preparePayment({ checkouttoken, type })
                                .then((resp) => {
                                    if (resp.clientsecret) {
                                        return ee.views.payments.chargeByStripe(resp.clientsecret);
                                    }

                                    return true;
                                })
                                .then(() => {
                                    that._finishPayment(content, data);
                                })
                                .catch(function (error) {
                                    that._unsuccesfulPayment($container);
                                });
                        }

                        return EE_API.Payment.Charge({ checkoutToken: buyerPersona.Cart.checkouttoken })
                            .then(function (data) {
                                that._finishPayment(content, data);
                            })
                            .catch(function (error) {
                                that._unsuccesfulPayment($container);
                            });
                    });
                if (ee.data.account.autocreditstatus == 'off') {
                    backbutton
                        .removeClass('hide')
                        .text(ee.t.backtobillingdetails)
                        .off()
                        .on('click', function () {
                            that._showBillingData(content, cart);
                        });
                } else {
                    backbutton
                        .removeClass('hide')
                        .text(ee.t.backtopurchase)
                        .off()
                        .on('click', function () {
                            that._purchaseScreen(container, cart);
                        });
                }
            };

            var checkoutCart = _.pick(cart, ['privateipcount', 'privateipregion', 'supportPlan']);

            return EE_API.Payment.Checkout(checkoutCart)
                .then(_showCheckout)
                .catch(function (error) {
                    console.log(error);
                });
        },
        _finishPayment: function ($container, data) {
            var receipturl = false;
            //we don't have data for receipt ATM, so we're just showing them success
            // if (data)
            //     receipturl = ee.api.path + "/account/GetReceipt?version=2&apikey=" + ee.data.account.apikey +"&paymentID=" + data
            actionbuttons.addClass('hide');
            var successtext = cart.privateip ? ee.t.privateiprequestsuccesful : ee.t.premiumsupportrequestsuccesful;
            var content = html.get('PaymentFinish', { receipturl: receipturl, successtext: successtext });
            $container.empty().append(content);
            $('.emodal-footer').show();
            nextbutton
                .off()
                .removeClass('btn_primary hide action-next')
                .addClass('emodal-close btn-default')
                .text(ee.t.close)
                .on('click', function () {
                    ee.session.refreshUserData(true);
                    if (ee.bootedAsChild) {
                        ee.goTo('/account');
                    } else {
                        ee.goTo('/settings/privateips');
                    }
                });
        },
        _unsuccesfulPayment: function ($container) {
            actionbuttons.addClass('hide');
            var content = html.get('PaymentFailure', { account: ee.data.account });
            $container.empty().append(content);
            $('.emodal-footer').show();
            nextbutton
                .removeClass('btn_primary hide action-next')
                .addClass('emodal-close btn-default')
                .text(ee.t.close);
        },
        _showProcessing: function (container) {
            var progress = 0;
            var content = html.get('PaymentProcess', { account: ee.data.account });
            container.empty().append(content);
            $('.emodal-footer').hide();

            var timer = setInterval(function () {
                progress = progress + 3;
                container.find('#eprogresspercent').attr('data-progress', progress);
                if (progress >= 60) {
                    clearInterval(timer);
                }
            }, 400);
        },
        _ValidateInputs: function ($container, button) {
            new html.Validator($container, {
                submit: button,
                inputs: [
                    {
                        name: 'epayemailcc',
                        css: _validMarks,
                        validFunc: function () {
                            return ee.tools.isValidEmail(this.val().trim());
                        },
                        msg: ee.t.eeform_error_email,
                    },
                    {
                        name: 'cc-number',
                        css: _validMarks,
                        validFunc: function () {
                            var cardnumber = this.val().replace(/\D/g, '');
                            cardnumber = cardnumber.replace(/(.{4})/g, '$1 ');
                            cardnumber = cardnumber.substr(0, 19);
                            this.val(cardnumber);
                            return (
                                this.val().trim().length >= 12 &&
                                this.val().trim().length <= 20 &&
                                that._GetCardType(this.val().replace(/\D/g, ''))
                            );
                        },
                        msg: ee.t.ercreditnumber,
                    },
                    {
                        name: 'cc-name',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length > 0;
                        },
                        msg: ee.t.ercreditname,
                    },
                    {
                        name: 'exp_month',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().trim() > 0 && this.val().trim() <= 12;
                        },
                        msg: ee.t.ermonth,
                    },
                    {
                        name: 'exp_year',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().trim() > 17;
                        },
                        msg: ee.t.eryear,
                    },
                    {
                        name: 'cc-exp',
                        css: _validMarks,
                        validFunc: function () {
                            var expdate = this.val().replace(/\D/g, '');
                            expdate = expdate.replace(/(.{2})/g, '$1/');
                            expdate = expdate.substr(0, 5);
                            this.val(expdate);

                            return new RegExp('^(0[1-9]|1[0-2])/([0-9]{2})$').test(this.val().trim());
                        },
                        msg: ee.t.erccdate,
                    },

                    {
                        name: 'cc-csc',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length >= 3 && this.val().length < 5;
                        },
                        msg: ee.t.ercreditcvv,
                    },
                    {
                        name: 'given-name',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length > 0;
                        },
                        msg: ee.t.erfirstname,
                    },
                    {
                        name: 'family-name',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length > 0;
                        },
                        msg: ee.t.erlastname,
                    },
                    {
                        name: 'epayaddress1',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length > 10;
                        },
                        msg: ee.t.eraddress,
                    },
                    {
                        name: 'epaycity',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length >= 3;
                        },
                        msg: ee.t.ercity,
                    },
                    {
                        name: 'epaystate',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length >= 3;
                        },
                        msg: ee.t.erprovince,
                    },
                    {
                        name: 'epayzip',
                        css: _validMarks,
                        validFunc: function () {
                            return this.val().length > 0;
                        },
                        msg: ee.t.erzip,
                    },
                ],
            });
            //get data for buyerPersona from form
        },
        _getStripeData: function ($container, callback) {
            //Note: we need double load this lib?
            Stripe.setPublishableKey(ee.api.publickey);
            nextbutton.addClass('disabled').text(ee.t.processing);
            stripe.handleCardPayment(XXXXXX, cardform).then((response) => {
                if (response.error) {
                    console.log(response.error.message);
                    that._unsuccesfulPayment($container);
                } else {
                    const data = {};
                    data.token = response.id;
                    data.email = cardform.email;
                    data.firstname = cardform.firstname;
                    data.lastname = cardform.lastname;
                    data.fingerprint = response.card.fingerprint;

                    //showing state

                    EE_API.Payment.Register({
                        token: response.id,
                        email: cardform.email,
                        firstname: cardform.firstname,
                        lastname: cardform.lastname,
                        fingerprint: response.card.fingerprint,
                    })
                        .then(function () {
                            //checkPaymentProvider
                            //updatePersona
                            ee.api.loadProviderData(function () {
                                buyerPersona.Billing = {
                                    email: data.email,
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    address1: cardform.address1,
                                    address2: cardform.address2,
                                    city: cardform.city,
                                    state: cardform.state,
                                    zip: cardform.zip,
                                    countryName: cardform.countryName,
                                };
                                nextbutton.removeClass('disabled');

                                if (callback) callback(data);
                                else that._checkout($container);
                            });
                        })
                        .catch(function (error) {
                            that._unsuccesfulPayment($container);
                        });
                }
            });
        },
        _register: function ($container, cart, self) {
            var paymentpart = html.get('NewPaymentTypeRegistration', { account: ee.data.account });
            $container.find('#epaymentbillingdata').hide();
            $container.append(paymentpart);
            that._ValidateInputs($($container), $('.action-next'));
            that._checkCardInput($container);
            //add card type verification inside card form
            nextbutton
                .text(ee.t.completepayment)
                .off()
                .on('click', function () {
                    that._getBillingData($container, function () {
                        that._checkout($container);
                    });
                    //grab data from form after validation
                });
            backbutton
                .text(ee.t.backtobillingdetails)
                .off()
                .on('click', function () {
                    nextbutton.attr('disabled', false).removeClass('disabled');
                    that._showBillingData(content, cart);
                });

            //move to checkout screen
            // if (self) {
            //     //generate internal NEXT/SAVE button
            //     that._getStripeData($container, true)
            // }
            // nextbutton.text(ee.t.completepayment).off().on('click', function() {
            //     that._getStripeData($container, false)

            // });
            // backbutton.text(ee.t.backtobillingdetails).off().on('click', function() {
            //     that._purchaseScreen($container, cart, true);
            // });
        },
        _GetCardType: function (number) {
            number = number.replace(/\D/g, '');

            // visa
            var re = new RegExp('^4');
            if (number.match(re) != null) return 'visa';

            //mastercard
            re = new RegExp('^(5[1-5]|2[2-7])');
            if (number.match(re) != null) return 'mastercard';

            // AMEX
            re = new RegExp('^3[47]');
            if (number.match(re) != null) return 'amex';

            return false;
        },
        _getBillingData: function ($container, callback) {
            var expirydate;

            if ($container.find('#epaycardexp').val()) {
                expirydate = $container.find('#epaycardexp').val();
            } else {
                expirydate = $container.find('#epaycardmonth').val() + '/' + $container.find('#epaycardyear').val();
            }
            cardform = {
                number: $container.find('#epaycardnumber').val().replace(/\s/g, ''),
                cvc: $container.find('#epaycardcvv').val(),
                exp: expirydate,

                //exp: $screen.paycardexp.val(),
                name: $container.find('#epaycardname').val(),
                address_line1: $container.find('#epayaddress1').val(),
                address_line2: $container.find('#epayaddress2').val(),
                address_city: $container.find('#epaycity').val(),
                address_state: $container.find('#epaystate').val(),
                address_zip: $container.find('#epayzip').val(),
                address_country: $container.find('#epaycountry').find(':selected').html(),
                email: $container.find('#epayemailcc').val(),
                firstname: $container.find('#epayfirstname').val(),
                lastname: $container.find('#epaylastname').val(),
            };
            if (buyerPersona) {
                buyerPersona.Payment = {
                    brand: that._GetCardType(cardform.number),
                    details: '**** **** **** ' + cardform.number.substr(cardform.number.length - 4),
                    exp: cardform.exp,
                    name: cardform.name,
                };
            }
            if (callback) callback();
        },
        _showBillingData: function ($container, cart, fullpanel) {
            $container.empty().append(paymentpart);
            //check if user filled the fields previously. If so, pre-select "another billing data and fill it"
            //if (paymentdata)

            actionbuttons.removeClass('hide');
            var paymentpart = html.get('PaymentBillingData', { account: buyerPersona.Billing });
            $container.empty().append(paymentpart);
            var $countrylist = html.get('CountryDropdown', { countries: ee.tools.CountryList, readonly: false });
            $countrylist.appendTo($container.find('#epaycountry'));
            $countrylist.on('click', 'input', function () {
                $countrylist
                    .find('#ecountrydropdown')
                    .html(
                        $(this).parent().text() +
                            " <i style='position: absolute;right: 10px;top: 12px;' class='fa fa-caret-down'></i>"
                    );
            });
            $countrylist
                .find('[value=' + ee.data.account.countryid + ']')
                .prop('checked', true)
                .click();
            that._ValidateInputs($($container), $('.action-next'));
            //that._checkCardInput($container);
            $container.find('.billingtab').on('click', function () {
                $container.find('.billingtab').closest('label').removeClass('active');
                $(this).closest('label').addClass('active');
                $container.find('.billingpane').hide();
                $container.find($(this).data('target')).show();
            });

            nextbutton
                .text(ee.t.next)
                .off()
                .on('click', function () {
                    //that._getBillingData($container, function() {
                    that._register($container, cart);
                    //});
                    // ee.api.loadProviderData(function() {
                    //     if (ee.data.account.autocreditstatus == "on" && $('#existingbillingdata').is(':checked')) {
                    //         that._checkout($container, cart);
                    //     } else {
                    //         that._register($container, cart);
                    //     }
                    // });
                });
            backbutton
                .text(ee.t.backtopurchase)
                .off()
                .on('click', function () {
                    nextbutton.attr('disabled', false).removeClass('disabled');
                    that._purchaseScreen(container, cart);
                });
        },
        _purchaseScreen: function ($container, cart) {
            var content = $container.$content;
            const { activePlan } = ee.data.account;
            
            switch (cart.type) {
                case 'privateip':
                    cart.privateipcount ??= 2;
                    cart.privateIPOne = +cart.privateipcount === 1;
                    cart.privateIPTwo = +cart.privateipcount ===2;
                    
                    var paymentpart = html.get('PurchasePrivateIPSidePanel', {
                        cart: cart,
                        ipcost: +cart.privateipcount * activePlan.privateipcost,
                    });

                   

                    content.empty().append(paymentpart);
                    $container.$title.empty().append(ee.t.privateips);
                    $(".show-intercom").on('click',function(){
                        try{
                        window.top.Intercom('show');
                        }catch{
                            console.warn('no Intercom detected')
                        }
                    })
                    var ipquantity = $('#eipquantity');
                    var ipprice = $('#emonthlyvalue');
                    var oneipnotice = $('#eoneipnotice');
                    ipquantity.on('blur change input', function () {
                        if (ipquantity.val() == 1) {
                            oneipnotice.show();
                        } else {
                            oneipnotice.hide();
                        }
                        ipprice.text(ipquantity.val() * activePlan.privateipcost);
                    });
                    html.setIncrementButtons(true);
                    break;
                case 'supportPlan':
                    var paymentpart = html.get('RequestPremiumSupportSidePanel', { cart: cart });
                    content.empty().append(paymentpart);

                    $('.esidepanel>.emodal-footer').hide();
                    $('.esidepanel>.emodal-title').hide();
                    content.off().on('click', '.action-next', function () {
                        $('.esidepanel>.emodal-footer').show();
                        $container.$title.show().empty().append(ee.t.premiumsupport);
                        nextbutton.click();
                    });
                    break;
            }
            //cart.plan = ee.data.account.pricingplan;
            //set next and back buttons
            actionbuttons.addClass('hide');
            nextbutton
                .text(ee.t.next)
                .removeClass('hide')
                .off()
                .on('click', function () {
                    switch (cart.type) {
                        case 'privateip':
                            cart.privateip = true;
                            cart.privateipcount = $container.$content.find('#eipquantity').val();
                           
                            cart.privateIPOne = +cart.privateipcount === 1;
                            cart.privateIPTwo = +cart.privateipcount === 2;
                            cart.privateipregion = "";
                            //cart.plan = ee.data.account.pricingplan;
                            //cart.maxcontacts = ee.data.account.maxcontacts;

                            break;
                        case 'supportPlan':
                            cart.type = 'supportPlan';
                            cart.supportPlan = 2;
                            //show emodal-footer and emodal-header
                            break;
                    }
                    if (activePlan.typedesc === 'ContactSubscription') {
                        //that._showBillingData(content, cart, true);
                        if (ee.data.account.autocreditstatus == 'on') {
                            that._checkout(content);
                        } else {
                            that._showBillingData(content, cart);
                        }
                    } else if (cart.type == 'privateip') {
                        ee.api.purchasePrivateIP(cart.privateipregion, cart.privateipcount, function () {
                            that._finishPayment(content);
                        });
                    } else if (cart.type == 'supportPlan') {
                        ee.api.requestPremiumSupport(function () {
                            that._finishPayment(content);
                        });
                    }
                });
        },
        redirectToBilling: function () {
            if (ee.bootedAsChild) {
                ee.goTo('#/account?goBack=/billing');
            }
        },
        init: function (shopdata, callbackfunction) {
            buyerPersona = {};
            buyerPersona.Billing = {
                address1: ee.data.account.address1,
                address2: ee.data.account.address2,
                city: ee.data.account.city,
                email: ee.data.account.email,
                firstname: ee.data.account.firstname,
                lastname: ee.data.account.lastname,
                zip: ee.data.account.zip,
                state: ee.data.account.state,
                countryName: ee.data.account.state,
            };
            buyerPersona.Payment = {};

            if (
                ee.data.account.autocreditstatus == 'on' ||
                (ee.data.account.issub && ee.data.account.enableprivateiprequest)
            ) {
                ee.api.loadProviderData(function () {
                    $.extend(buyerPersona.Payment, ee.data.account.payments);
                });
            } else if (
                ee.data.account.autocreditstatus != 'on' ||
                (ee.data.account.issub && !ee.data.account.enableprivateiprequest)
            ) {
                return html.modal.confirm(
                    ee.t.paymentnotactive,
                    '',
                    function () {
                        ee.goTo('#/payments');
                    },
                    function () {
                        that.redirectToBilling();
                    },
                    { size: '600px', confirmTitle: ee.t.check }
                );
            }
            cart = shopdata;
            //don't remove the form, hide it instead, so we will be using data from it for registration
            var opt = {
                sidePanel: true,
                draggable: false,
                fullScreen: !!ee.bootedAsChild, // as those panel on the new app should be full screen
                buttons: [
                    {
                        name: ee.t.next,
                        css: 'btn btn_primary pull-right action-next action hide',
                        callback: function (e) {
                            e.stopPropagation();
                        },
                    },
                    {
                        name: ee.t.back,
                        css: 'btn pull-left action-back action hide',
                        callback: function (e) {
                            e.stopPropagation();
                        },
                    },
                ],
                onClose: () => that.redirectToBilling(),
            };

            container = html.modal.create(html.get('CheckoutModal'), opt);

            //first step - showing visuals for element we are buying in the same modal
            actionbuttons = container.$footer.find('.action');
            nextbutton = container.$footer.find('.action-next');
            backbutton = container.$footer.find('.action-back');
            that._purchaseScreen(container, cart);
        },
    };

    namespace.PaymentPanel = that;
})(this, html.widget);
