ee.views["help"] = (function () {
    "use strict";

    var that;
    var $screen;
    var $tabContent;
    var $helpSvg;
    const priceVariableTranslationKey = '{{price}}'
    const ADD_PAYMENT_METHOD_SCREEN = `${window.parent.location.origin}/billing/payment-methods`;
    const selectPlanScreen = (productType = 'marketing') => `${window.parent.location.origin}/billing/${productType}/plans`;
    //Dedicated support tab
    var _dedicatedSupportTab = function() {

        $('#helpscreen').empty();
        $tabContent = html.get("DedicatedSupport", {legacyMode: ee.legacyMode});
        $tabContent.appendTo('#helpscreen');
        var $contactLink = $('.help-link').clone();

        var providerData = {};
        ee.api.loadProviderData(function(data) {
            providerData = data;
            providerData.data.brand = _.upperFirst(providerData.data.brand.toLowerCase());
        });

        const goToNewPaymentsScreensOrBuySupport = (type) => {
            if(ee.data.account?.istrialbillingplan){
                const isMarketing = ee.data.account.activePlan.typedesc === "ContactSubscription";
                const productType = isMarketing ? 'marketing': 'emailapi';
                window.top.location.assign(selectPlanScreen(productType))
                return;
            }
            if(!providerData.data?.expirationdate){
                window.top.location.assign(ADD_PAYMENT_METHOD_SCREEN);
                return;
            }
            ee.api.addDedicatedSupport(type);
        }

        //Function which is displaying modal with description about dedicated support
        //Confirm button runs ee.api.addDedicatedSupport
        //Only for pay as you go pricing plans
        var supportPurchaseGo = function(type, desc, title) {
           
          
            html.modal.confirm(desc, title, function() {
                if(ee.bootedAsChild){
                    return goToNewPaymentsScreensOrBuySupport(type);
                }
                ee.api.addDedicatedSupport(type);
            }, null, {
                size: '600px'
            });
        }

        var supportPurchaseUnlimited = function (plan) {
            var $overlay = $('<div id="help-overlay" class="emodal-overlay"></div>');
            var $drawer = $('<div class="help-drawer"></div>');
            !!ee.bootedAsChild && $drawer.addClass('fullScreen');
            var isPremium = plan === 2 ? true : false;
            var $drawerContent = html.get("RequestPremiumSupportSidePanel", {
                isPremium: isPremium,
            });

            $drawerContent.find('.emodal-title').text(isPremium ? ee.t.addpremiumsupport : ee.t.addprioritysupport);
            $drawerContent.find('#goCheckout').text(isPremium ? ee.t.addpremiumsupport : ee.t.addprioritysupport);
            $overlay.appendTo('body');
            $drawer.appendTo('body');
            $drawerContent.find('.support-desc').text(isPremium ? ee.t.buypremiumsupportdesc : ee.t.buyprioritysupportdesc);

            const {activePlan} = ee.data.account;

            const buypremiumsupportdescprice = (ee.t.buypremiumsupportdescprice).replace(priceVariableTranslationKey, activePlan.premiumsupportcost);
            const buydedicatedsupportdescprice = (ee.t.buydedicatedsupportdescprice).replace(priceVariableTranslationKey,activePlan.prioritysupportcost);
            $drawerContent.find('.support-desc-price').text(isPremium ? buypremiumsupportdescprice : buydedicatedsupportdescprice);

            $drawer.append('<i class="fa fa-times fa-lg close-support"></i>');
            $drawer.append($drawerContent);

            var paymentInfo = {
                supportPlan: plan
            }

            $('#goCheckout').on('click', function() {
                EE_API.Payment.Checkout(paymentInfo)
                .then(function(data) {

                    data.dedicatedsupport = data.dedicatedsupportcost > 0;
                    data.sum = data.dedicatedsupportcost + data.privateipcost;
                    data.email = ee.data.account.email;
                    data.name = ee.data.account.firstname + " " + ee.data.account.lastname;
                    data.providerData = providerData;

                    if (ee.data.account.discountpercent) {
                        if (moment().diff(ee.data.account.discountexpiry, 'days') <= 0 || !ee.data.account.discountexpiry) { //discount expiry in the future or non-expiring one
                            data.discount = ee.data.account.discountpercent;
                            data.discountfactor = 1 - ee.data.account.discountpercent;
                            data.percent = ee.data.account.discountpercent * 100;
                            data.discountsum = (data.sum * ee.data.account.discountpercent).toFixed(2);
                            data.discounttime =  ee.data.account.discountexpiry ? (moment(ee.data.account.discountexpiry).format('L')) : null;
                            data.sum = (data.sum * data.discountfactor).toFixed(2);
                        }
                    }

                    var checkout = html.get('HelpCheckout', {
                        data: data,
                        isPremium: isPremium,
                        activeProvider: data.providerData.data.details ? true : false
                    });


                    $drawer
                    .empty()
                    .append(checkout);

                    var footer = checkout.find('.emodal-footer').clone();

                    const { provider } = ee.data.account.billingdetails;

                    const _charge = () => {
                        const { checkouttoken } = data;
                        const type = ee.views.payments.getPaymentType('Charge');

                        if ( provider.name !== 'Stripe' ) {
                            return EE_API.Payment.Charge({checkouttoken})
                        }
                        return EE_API.Payment.preparePayment({checkouttoken, type})
                        .then((resp) => {

                            if (resp.clientsecret) {
                                return  ee.views.payments.chargeByStripe(resp.clientsecret);
                            }

                            return true;
                        })
                    }

                  

                    $('#makePayment').on('click', function() {
                        //var charge = html.get("CheckoutModal");
                        var success = html.get('PaymentFinish', {
                            receipturl: false,
                            successtext: isPremium ? ee.t.premiumsupportrequestsuccesful : ee.t.prioritysupportrequestsuccesful
                        });
                        var process = html.get('PaymentProcess', {account: ee.data.account});
                        var fail = html.get('PaymentFailure', {account: ee.data.account});

                        //Charge progress animation
                        //$drawer.empty().append(charge);
                        var progress = 0;
                        $drawer.empty().append(process);
                        var timer = setInterval(function () {
                            progress = progress + 3;
                            $drawer.find("#eprogresspercent").attr('data-progress', progress);
                            if (progress >= 60) {
                                clearInterval(timer);
                            }
                        }, 400);
                        //Charge function init
                        return _charge()
                        .then(function() {
                            process.remove();
                            $drawer.append(success);
                        })
                        .then(function() {
                            setTimeout(function() {
                                location.reload()
                            }, 4000)
                        })
                        .catch(function(err) {
                            process.remove();
                            $drawer.append(fail);
                            $drawer.append(footer);
                            $('#makePayment').removeClass('btn-success hide action-next').addClass('emodal-close btn-default').text(ee.t.close);
                            $('#paymentBack').remove();
                            $('.emodal-close').on('click', function() {
                                $('.emodal-overlay').remove();
                                $('.help-drawer').remove();
                            })
                        })
                    });
                    $('#paymentBack').on('click', function() {
                        $('.help-drawer')
                        .empty()
                        .append($drawerContent);
                        $drawer.append('<i class="fa fa-times fa-lg close-support"></i>');
                    });

                    $('#choosePaymentHelp').on('click', function() {
                        if(ee.bootedAsChild){
                            window.top.location.assign(ADD_PAYMENT_METHOD_SCREEN);
                            return;
                        }
                        ee.goTo('#/payments')
                    })
                })
                .catch(function(err) {
                    console.log(err);
                })
            });

            $(document).on('click', '#choosePaymentHelp', function() {
                setTimeout(function() {
                    const epaymentscreen2 = document.getElementById('epaymentscreen2');

                    if (epaymentscreen2) {
                        epaymentscreen2.scrollIntoView();
                    }
                }, 0)
            })

            $(document).on('click', '#help-overlay, #choosePaymentHelp, #emodalCancel, .close-support', function() {
                $('#help-overlay').remove();
                $('.help-drawer').remove();
            });
        }

        $('#contactUs').on('click',function(){
            window?.top?.Intercom && window?.top?.Intercom?.('show');
        });
        $('#goToHelp').on('click',function(){
            //search param goBack should be valid url from top application
            ee.goTo('/help?goBack=/help')
        })

        //Shows which support is active (if any). If priority or premium support is active then buttons switch to contact us link.
        if (ee.data.account.supportcost) {
            const {supportplan} = ee.data.account.billingdetails.plan;

            if (supportplan === 'Priority') {
                $('.active-label').eq(1).removeClass('hidden');
                $('.support-description').eq(2).append('<br />').append($contactLink);
                $('#helpprioritysupport').remove();
            }
            if (supportplan === 'Premium') {
                $('.active-label').eq(2).removeClass('hidden');
                $('.support-description').eq(1).append('<br />').append($contactLink);
                $('#helpprioritysupport, #helppremiumsupport').remove();
            }
        } else {
            $('.active-label').eq(0).removeClass('hidden');
        }
        const {premiumsupportcost,prioritysupportcost} = ee?.data?.account?.activePlan || {};
        var prioMessage = ee.t.prioritysupportdeschelp?.replace?.(priceVariableTranslationKey,prioritysupportcost);
        var premiumMessage = ee.t.premiumsupportdeschelp?.replace?.(priceVariableTranslationKey,premiumsupportcost);
        var pricingPlan = ee.getPricingPlanName(ee.data.account.pricingplan);
        var acceptedPlans = ['Classic', 'Pricing2015', 'Basic', 'Advanced', 'Premium'];

        const {activePlan} = ee.data.account

        if (_.includes(acceptedPlans ,pricingPlan)) {
            prioMessage = prioMessage;
            premiumMessage = premiumMessage;
        }


        if(activePlan.typedesc === "ContactSubscription") {
            $('#helpprioritysupport').on('click', function() {
                supportPurchaseUnlimited(1);
            });
            $('#helppremiumsupport').on('click', function() {
               supportPurchaseUnlimited(2);
            });
        } else {
            $('#helpprioritysupport').on('click', function() {
                supportPurchaseGo(1, prioMessage, 'Add priority support');
            });
            $('#helppremiumsupport').on('click', function() {
                supportPurchaseGo(2, premiumMessage, 'Add premium support');
            });
        }
    }


    //Conctact sales tab
    var _contactSalesTab = function() {
        $('#helpscreen').empty();
        $tabContent = html.get("ContactSales");
        $tabContent.appendTo('#helpscreen');
    }


    //Video Tutorials tab
    var _videoTutorialsTab = function () {

        $('#helpscreen').empty();
        var videos = {};
        var loaded = false;
        videos.ids = [];
        videos.titles = [];
        $('#helpscreen').addClass('video-tab')
        ee.indiOn();

        //Creates and close modal with video
        var handleModal = function(element) {
            $('<div id="emodal-overlay" class="emodal-overlay"></div>').appendTo('body');
            $(element).appendTo('body');
            $('<div class="close-video"></div>').appendTo('body');

            $('.emodal-overlay, .close-video').on('click', function() {
                $('.emodal-overlay').remove();
                $(element).remove();
                $('.close-video').remove();
            });
        }

        var renderVideo = function(id, title, index) {
            if(ee.Router.action() === 'videotutorials'){
                var source = 'https://img.youtube.com/vi/'+ id +'/maxresdefault.jpg';
                $('<div class="ytvideo-container"><h4 class="video-title">'+ title +'</h4></div></div>').appendTo('#helpscreen')
                var image = new Image(255, 143);
                image.src = source;
                image.addEventListener('load', function(){
                    $('.ytvideo-container')[index].prepend(image);
                });
                ee.indiOff();

                //Create iframe and run handleModal function.
                $('.ytvideo-container').eq(index).on('click', 'img', function() {
                    var iframe = document.createElement('iframe');
                    iframe.setAttribute('frameborder', '0');
                    iframe.setAttribute('allowfullscreen', "");
                    iframe.setAttribute('width', "640");
                    iframe.setAttribute('height', "480");
                    iframe.setAttribute('src', 'https://www.youtube.com/embed/'+ id +'?rel=0&showinfo=0&autoplay=1');

                    $(iframe).addClass('youtube-video');
                    handleModal(iframe);
                });
            }
        }

        //Splitting whole playlist into smaller chunks
        var chunkVideos = function(array, chunkSize) {
            var results = [];
            while (array.length) {
                results.push(array.splice(0, chunkSize));
            }
            return results;
        }

        var arrayChunk;
        if (ee.Router.allParameter().length > 0) {
            arrayChunk = ee.Router.allParameter()[0].value;
        } else {
            arrayChunk = 1;
        }

        //Pagination
        var videosPagination = function(containerClass, array, itemsPerPage) {
            var pages = _.ceil(array.length / itemsPerPage);
            var pagination = html.get("HelpPagination", {
                pages: pages
            });

            for (var i = pages; i >= 1; i--) {
              $('.' + containerClass).after('<a class="help-pagination" href="#/help/videotutorials?page='+ i +'">' + i + '</a>');
            }

            var paginationIndex;
            if (!ee.Router.allParameter().length || !ee.Router.allParameter()[0].value) {
                paginationIndex = 0;
            } else {
                paginationIndex = ee.Router.allParameter()[0].value - 1;
            }
            $('.help-pagination').eq(paginationIndex).addClass('active-pagination');
        }


        //Function which is picking ids, titles etc. of all videos from a playlist
        //Youtube API gives you only 5 items per request and a token for the next 5 items
        // that is why this function is recursive
        var getVideos = function (pageToken) {
            if(!pageToken) { pageToken = null; }
            $.get(
                'https://www.googleapis.com/youtube/v3/playlistItems', {
                    part: 'snippet,contentDetails',
                    playlistId: 'PLd8cr6DzMHI23lbGK1-PHlzooWlx5QLmb',
                    key: 'AIzaSyB7AuyoWrzyW-9c2FksukALIlk62eAWJOY',
                    pageToken: pageToken
                }
            )
            .then(function(data){
                _.forEach(data.items, function(el) {
                    videos.ids.push(el.contentDetails.videoId);
                    videos.titles.push(el.snippet.title);
                })
                if (data.nextPageToken){
                    getVideos(data.nextPageToken);
                }
                if (!data.nextPageToken) {
                    loaded = true;
                }
            })
            .then(function(){
                if(loaded) {
                    videos.ids = chunkVideos(videos.ids, 16);
                    videos.titles = chunkVideos(videos.titles, 16);
                    for (var i = 0; i < videos.ids[arrayChunk - 1].length; i++) {
                        renderVideo(videos.ids[arrayChunk - 1][i], videos.titles[arrayChunk - 1][i], i);
                    }
                    var numberOfItems = _.flatten(videos.ids).length;
                        while(numberOfItems % 4 !== 0) {
                            $('<span class="fill-videostab"></span>').appendTo('#helpscreen');
                            numberOfItems++;
                        }
                    videosPagination('board', _.flatten(videos.ids), 16);
                }
            })
            .fail(function(err) {
                console.error('Error: ' + err.responseText);
            })
        }
        getVideos();
    }


    //Function for Help Center tab. Due to internie coms policy we can't use iframe.
    //LoadHelpPage gives html of http://help.elasticemail.com/ which is sliced (we don't need the whole site) and appended to the screen.
    var _helpCenterTab = function() {
        $('#helpscreen').empty();
        $screen = html.get("HelpCenter");
        $screen.appendTo('#helpscreen');

        var $markup;

        EE_API.Account.LoadHelpPage()
        .then(function(data){
            $markup = $(data).find('.educate_content').clone();
            if(ee.Router.action() === 'helpcenter') {
                $markup.appendTo('#helpscreen');
            }
        })
        .then(function() {
            $('a[href="/en/collections/416577-contacts"]').attr({
                'href': 'http://help.elasticemail.com/contacts',
                'target': '_blank'
            });
            $('a[href="/en/collections/416591-templates"]').attr({
                'href': 'http://help.elasticemail.com/templates',
                'target': '_blank'
            });
            $('a[href="/en/collections/416585-api"]').attr({
                'href': 'http://help.elasticemail.com/api',
                'target': '_blank'
            });
            $('a[href="/en/collections/416607-campaigns"]').attr({
                'href': 'http://help.elasticemail.com/campaigns',
                'target': '_blank'
            });
            $('a[href="/en/collections/416617-activity"]').attr({
                'href': 'http://help.elasticemail.com/activity',
                'target': '_blank'
            });
            $('a[href="/surveys"]').attr({
                'href': 'http://help.elasticemail.com/surveys',
                'target': '_blank'
            });
            $('a[href="/en/collections/416459-account-settings"]').attr({
                'href': 'http://help.elasticemail.com/account-settings',
                'target': '_blank'
            });
        })
        .fail(function(err){
            console.log(err);
        })
    }

    var _changeActive = function(e) {
        if (ee.data.account.issub) {
            if(ee.Router.action() === 'show') {
                $('#helpcenter').addClass('active');
            } else {
                $('.presentation').removeClass('active');
                $('#' + ee.Router.action()).addClass('active');
            }
        } else {
            if(ee.Router.action() === 'show') {
                $('#dedicatedsupport').addClass('active');
            } else {
                $('.presentation').removeClass('active');
                $('#' + ee.Router.action()).addClass('active');
            }
        }
    }

    that = {
        show: function() {
            ee.frame.content.empty();
            $screen = html.get("Help", {
                isSub: ee.data.account.issub,
                legacyMode: ee.legacyMode
            });
            $screen.appendTo(ee.frame.content);

            $('#contactsales').off().on('click', function(e) {
                ee.goTo('#/help/contactsales');
            });
            $('#dedicatedsupport').off().on('click', function(e) {
                ee.goTo('#/help/dedicatedsupport');
            })
            $('#videotutorials').off().on('click', function(e) {
                ee.goTo('#/help/videotutorials');
            })
            $('#helpcenter').off().on('click', function(e) {
                ee.goTo('#/help/helpcenter');
            })

            switch(ee.Router.action()){
                case 'contactsales':
                    _contactSalesTab();
                    break;
                case 'helpcenter':
                    _helpCenterTab();
                    break;
                case 'videotutorials':
                    _videoTutorialsTab();
                    break;
                default:
                    _dedicatedSupportTab();
                    break;
            }
            _changeActive();
        }
    }
    return that;
})();
