(function (root) {
    let $screen;
    let Contact;
    let ContactFields;
    let fieldInputs;
    let initialSettings = [];
    let _showContactDetails = (opt) => {
            var frame;
            const listOrSegmentId = ee.tools.getUrlParameter("fromList");
            const segmentOrList = ee.tools.getUrlParameter("segmentOrList");
            const listName = decodeURIComponent(ee.tools.getUrlParameter("name"));

            $screen = html.get('ContactDetails', $.extend({ isadmin: ((ee.session.can && ee.session.can.AdminLoadAccount) || ee.session.can.AdminLoadAccount), isactivity: location.hash.includes('activity') }, Contact.data, {
                consenttracking: Contact.data.consenttrackingformated,
                enablecontactfeatures: ee.data.account.enablecontactfeatures,
                issub: ee.data.account.issub,
                listOrSegmentId,
                segmentOrList,
                listName
            }));

            $screen.find("#goBackToList").on("click", e => {
                e.preventDefault();
                e.stopPropagation();

                if (listOrSegmentId && segmentOrList) {
                    ee.goTo(`#/contacts/${segmentOrList}/${listOrSegmentId}`);
                } else {
                    ee.goTo('#/contacts');
                }
            });

            if (opt && opt.frame) {
                frame = opt.frame;
                frame.empty();
                $screen.appendTo(frame);
            } else {
                frame = new html.Frameoverlay($screen);
                if (opt && opt.beforeClose) frame.on('beforeClose', opt.beforeClose);
            }

            $("#esavecontact").addClass("disabled");

            $screen.on('keydown change','input, select', function () {
                $("#esavecontact").removeClass("disabled").removeAttr("disabled");
                $("#cancelContact").removeAttr("disabled");



                $screen.savecontact.off().on('click', function () {

                    var fields = $.extend({},
                        $screen.contactdatageneral.elasticCollectForm(),
                        $screen.usercustomfields.elasticCollectForm(),
                        $screen.userconsentfields.elasticCollectForm());
                    delete fields.email;
                    ee.indiOn();
                    return EE_API.Contact.Update({ email: Contact.data.email, customfields: JSON.stringify(fields) })
                        .then(data => {
                            ee.indiOff();
                            $("#esavecontact").attr("disabled", true);
                            $("#cancelContact").attr("disabled", true);

                            fieldInputs = $screen.find("#econtactdata input, #econtactdata select");

                            initialSettings = [];

                            _.forEach(fieldInputs, input => initialSettings.push([input, input.value]));
                        });
                });
            });

            // @TODO: To be removed as soon as #EE-1155 gets resolved
            // $("#esavecontactandclose").addClass("disabled");
            // $(document).on('keydown change','input, select', function () {
            //     $("#esavecontactandclose").removeClass("disabled");

            //     $screen.savecontactandclose.off().on('click', function () {

            //         var fields = $.extend({},
            //             $screen.contactdatageneral.elasticCollectForm(),
            //             $screen.usercustomfields.elasticCollectForm(),
            //             $screen.userconsentfields.elasticCollectForm());
            //         delete fields.email;
            //         ee.indiOn();
            //         ee.goToPreviousLocation();
            //         return EE_API.Contact.Update({ email: Contact.data.email, customfields: JSON.stringify(fields) })
            //             .then(function (data) { ee.indiOff(); });
            //     });


            // });

            //Return when contactfeatures prop is false
            if (!ee.data.account.enablecontactfeatures) return;

            $screen.maintablist.on("click", "li", function (e) {
                if (this === e.target) $(this).find('a').trigger('click');

                if($('#ui-datepicker-div').length && !$('#ui-datepicker-div').css('display', 'none')) {
                    $('#ui-datepicker-div').css('display', 'none');
                }
            })

            Contact.loadContactLatestMails()
                .then(function () {
                    var inprogress = [],
                        emails = [];

                    Contact.logs.each(function () {
                        if (this.status === "ReadyToSend" || this.status === "WaitingToRetry") {
                            inprogress.push(this);
                        } else {
                            emails.push(this);
                        };
                    })

                    return {
                        inprogress: inprogress,
                        emails: emails
                    }
                })
                .then(function (logs) {

                    _showLatestEmails(logs.emails);
                    _showInProgressEmails(logs.inprogress);
                });

            Contact.loadContactClicks()
                .then(function () {
                    _showLatestClicks();
                }).catch(function (err) { console.error(err) });

            Contact.loadContactHistory()
                .then(function (more) {
                    _showContactHistory(more);
                }).catch(function (err) { console.error(err) });;

            Contact.findContact().then(function () {
                var $segmentssection = html.get('ContactsDetailsSegmentsSection', {
                    contactsegmentslength: Contact.data.segments.length,
                    contactlistslength: Contact.data.lists.length,
                    contactsegments: Contact.data.segments,
                    contactlists: Contact.data.lists
                });
                $screen.segmentssection.empty();
                $segmentssection.appendTo($screen.segmentssection);

            }).catch(function (err) { console.error(err) });

            $screen.on('click', '.js-managefields_btn', function () {
                html.widget.ContactFieldsUI.init(function () { _showCustomFields(); });
            });

            $screen.usercustomfields.on("focusin", "input.datepicker", function (e) {
                e.stopPropagation();
                $(this).datepicker({
                    dateFormat: ee.tools.localDateFormatDatepicker(),
                    timeFormat: "HH:mm",
                    yearRange: '-150:+90',
                    changeMonth: true,
                    changeYear: true,
                    controlType: 'select',
                    oneLine: true
                });
            });
            _showCustomFields();
        },
        _showContactHistory = function (notAll) {
            var historypagination = new html.Pagination(Contact.activity.data, {
                template: 'HistoryPagination',
                resultcontainer: '#econtacthistoryresult',
                footercontainer: '.econtacthistorynav',
                firstlast: false,
                perpage: 10,
                paginationinfo: false,
            }),
            requestMore = notAll,
            requestInProgress = false;

            if (notAll) {
                historypagination.eventChangePage(function () {

                    var offset = Contact.activity.data.length;
                    if (requestMore && !requestInProgress && (historypagination.getOffset() > offset - 100)) {

                        Contact.loadContactHistory(offset)
                            .then(function (more) {
                                requestMore = more;
                                historypagination.updateData(Contact.activity.data);
                                requestInProgress = false;
                            }).catch(function (err) { console.error(err) });
                    }

                });
            };
        },
        _showLatestClicks = function () {
            var latestclicks = Contact.clicks.each(_formatClicksDate).sort('date');

            var latestclickspagination = new html.Pagination(latestclicks.getAll(), {
                template: 'LatestClicksPagination',
                resultcontainer: '#elatestclicksresult',
                footercontainer: '.elatestclicksnav',
                firstlast: false,
                perpage: 10,
                paginationinfo: false,
            });
            $('.egotochannel').on('click', function () {
                ee.goTo("#/activity/" + $(this).data('channel'));
            });
        },
        _formatClicksDate = function () {
            if (!this.date && this.dateclicked) this.date = ee.tools.time(this.dateclicked).fromUTC("L LT");
        },
        /* NOTE: Marge together _showLatestEmails & _showInProgressEmails (M.N.) */
        _showLatestEmails = function (emails) {
            ee.Router.setParameter('page', 1);

            var latest = new Collection.Data(emails),
                contact = Contact.data;
            let latestpagination = new html.Pagination(latest.getAll(), {
                template: 'LatestEmailsPagination',
                resultcontainer: '#elatestemailsresult',
                footercontainer: '.elatestemailsnav',
                firstlast: false,
                perpage: 10,
                paginationinfo: false,
            });

            $screen.latestemailsresult.on('click', '.ebouncemsg', function () {
                var bounce = latest.get('msgid', $(this).data('msgid'));
                if (bounce) {
                    bounce.to = contact.email;
                    ee.views.activity.showBounceDetail(bounce);
                }
            });
            $screen.latestemailsresult.on('click', '.action-emailview', function () {
                var email = latest.get('msgid', $(this).data('msgid'));
                email.fromemail = email.from;
                email.to = contact.email;
                dataModel.create("Log", {
                    data: email,
                    callback: function (email) {
                        ee.views.activity.showViewEmail(email);

                    }
                });
            });

        },
        _showInProgressEmails = function (emails) {
            var latest = new Collection.Data(emails),
                contact = Contact.data,
                latestpagination = new html.Pagination(latest.getAll(), {
                    template: 'LatestEmailsPagination',
                    resultcontainer: '#einprogressemailsresult',
                    footercontainer: '.einprogressemailsnav',
                    firstlast: false,
                    perpage: 10,
                    param: {
                        inprogress: true,
                    },
                    paginationinfo: false,
                });

            $screen.inprogressemailsresult.on('click', '.ebouncemsg', function () {
                var bounce = latest.get('msgid', $(this).data('msgid'));
                if (bounce) {
                    bounce.to = contact.email;
                    ee.views.activity.showBounceDetail(bounce);
                }
            });
            $screen.inprogressemailsresult.on('click', '.action-emailview', function () {
                var email = latest.get('msgid', $(this).data('msgid'));
                email.fromemail = email.from;
                email.to = contact.email;
                dataModel.create("Log", {
                    data: email,
                    callback: function (email) {
                        ee.views.activity.showViewEmail(email);
                    }
                });
            });
        },
        _showCustomFields = function () {
            //Note: this $when should be depende from contact.load and ContactFields...
            $.when(ContactFields.require()).then(function () {
                let contact = Contact.data;
                let skip = ['firstname', 'lastname', 'consenttracking', 'email'];
                contact.customfields = [];
                contact.consentfields = [];
                if (ContactFields && ContactFields.data.length > 0) {
                    var consentFieldRegExp = /^consent.+/g;
                    ContactFields.each(function () {
                        if ( skip.indexOf(this.name) === -1 ) {
                            let value = "";
                            if (contact.customfieldsObj[this.name] && contact.customfieldsObj[this.name] !== '1/1/0001 12:00:00 AM') {
                                value = (this.typename === 'datetime') ? ee.tools.time(contact.customfieldsObj[this.name]).toDate("L LT") : contact.customfieldsObj[this.name];
                            }

                            var fieldstype = (this.name.match(consentFieldRegExp)) ? "consentfields" : "customfields";
                            contact[fieldstype].push({
                                fielddata: ContactFields.getFieldData(this.typename, contact.customfieldsObj[this.name]),
                                name: this.name.toLowerCase(),
                                value: (this.typename === 'datetime' && value.indexOf(" 00:00") > 0) ? value.split(' ')[0] : value,
                            });
                        }
                    });
                }
                html.get("ContactCustomField", { customfields: contact.customfields, editionmode: false }).appendTo($screen.usercustomfields.empty());
                html.get("ContactStaticCustomField", {
                    list: [
                        {
                            name: "consentdate",
                            value: contact.consentdate,
                            tooltip: "Consent date is the date when the user agreed on receiving emails from you."

                        },
                        {
                            name: "consentip",
                            value: contact.consentip,
                            tooltip: "Consent IP is the IP source from which you got the permission for sending emails to this contact."

                        },
                        {
                            name: "consenttracking",
                            value: contact.consenttrackingformated,
                            tooltip: "Tracking consent means that this contact allows you to track the emails and links that you send to him."
                        }

                    ]
                }).appendTo($screen.systemconsentfields.empty());
                html.get("ContactCustomField", { customfields: contact.consentfields, editionmode: false }).appendTo($screen.userconsentfields.empty());

                fieldInputs = $screen.find("#econtactdata input, #econtactdata select");

                _.forEach(fieldInputs, input => initialSettings.push([input, input.value]));

                $screen.find("#cancelContact").on("click", e => {
                    _.forEach(initialSettings, element => element[0].value = element[1]);
                    $("#cancelContact").attr("disabled", true);
                    $("#esavecontact").attr("disabled", true);
                });

            }).catch(function (err) { console.error(err) });
        },
        that = {
            init: function (contact, opt) {

                Contact = dataModel.create("Contact", contact);
                ContactFields = dataModel.create("ContactFields");
                ee.indiOn();
                $.when(Contact.load(), ContactFields.require()).then(function () {
                    ee.indiOff();
                    _showContactDetails(opt);
                }).catch(function (err) { console.error(err) });
            }
        };

    root.ContactDetailsUI = that;
}(html.widget));
