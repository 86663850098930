//
// Etemplate - ElasticEmail Drag&drop editor to create fabulous email template
// @2014-2018
// Version: 2.31
//

ee.editors["etemplate"]['ddeditor'] = (function () {
    var thats = {
        version: "2.31"
    };
    var init = function (bodyhtml, iframe) {
        /*--- inheriting methods from the core editor for the object That ---*/
        function DragDropEditor() { };
        DragDropEditor.prototype = Object.create(ee.editors.core);
        DragDropEditor.prototype.constructor = DragDropEditor;
        /*--- END ---*/
        var that = new DragDropEditor();
        //local variables
        var content = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae urna egestas.",
            showlayerborder = false,
            minCellWidth = 20,
            iframejs = iframe[0].contentWindow.window,
            /* Elementary blocks for template*/
            templateItem = {
                newRow: '<tr><td class="drow" {{#background}}style="background-color:{{background}}"{{/background}} valign="top" align="center"></td></tr>',
                wraper: '<div class="dwrap" style="{{#width}}max-width:{{width}}{{/width}}">{{& content}}</div>',
                rowDropArea: '<div class="addnewrow {{css}}" data-x="{{x}}" data-y="{{y}}"data-dir="{{dir}}">&nbsp;</div>',
                cellDropArea: '<div class="addnewcell {{css}}" data-y="{{y}}" data-x="{{x}}" id="{{id}}" data-dir="{{dir}}" style="{{style}}">&nbsp;</div>'
            },
            /*Outlook hiden table*/
            outlook = {
                layerStart: '<!--[if (gte mso 9)|(IE)]><table align="center" style="max-width:{{width}}px" width="{{width}}" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->',
                layerEnd: '<!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->',
                cellStart: '<!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->',
                cellBetween: '<!--[if (gte mso 9)|(IE)]></td><td valign="top"><![endif]-->',
                cellEnd: '<!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->'
            }
        /*Content Clean Rule*/
        var allowedAttr = ["class", "style", "href", "target", "id"];
        var tagsToClean = [".style1", ".style2", ".style3", ".style4", "a", "p", "span", "strong", ".edtext div"];
        var removeEmpty = ["span", "a", "strong", "b", "del", "em", "u"];

        var createLayoutStruct = function (css) {

            if (css.indexOf(".rss-block__paragraph") < 0) {
                // Add css for rss block for backwards compatibility
                // since updating DefaultCssStyle Mustache template works
                // for newly created templates only.
                css += '.rss-block__paragraph { font-size: 14px; font-family: Mulish; word-break: break-word; }';
            }

            var layoutStruct = {
                layer1: {},
                rows: [],
                minWidth: 320,
                maxWidth: 900,
                cssStyle: {},
                newCell: {}
            }
            that.layoutStruct = layoutStruct;
            //LOAD STYLESHEET STYLE CSS
            $("<style type='text/css' data-editor='yes'>" + css + "</style>").prependTo(iframejs.$("head"));
            //SET GLOBAL ACCES TO STYLESHEET
            that.styleSheet = iframejs.document.styleSheets[0];
            //Cheack and update template version
            __parseIncompatible();
            //Remove innline style
            __removeInlineStyle();
            //Add First layer
            layoutStruct.layer1 = that.body.find(".layer_1");
            //Create layoutStruct Rows
            that.body.find('.drow').each(function (y) {
                var row = $(this);
                row.cells = row.find(".layer_2").map(function (x) {
                    var layer2 = $(this);
                    //Add wraper to layer_2
                    layer2.wrap(Mustache.render(templateItem.wraper, { width: layer2.css("max-width") }));
                    layer2.parent().prepend(html.getraw("CellTools"));
                    // cell.prepend();
                    layer2.css("max-width", "");
                    return layer2.parent();
                }).get();
                layoutStruct.rows.push(row);
            });
            that.layoutStruct.cssStyle[".layer_1"]["border-width"] = parseInt(that.body.find(".layer_1:first").css('border-top-width')) * 2;
            //Bind Layout events (resize, add new cells)
            __bindDropMethod();
            //Init Plugin Events
            for (var pluginName in pluginList) {
                that.plugins[pluginName] = pluginList[pluginName]();
            };
            //Add Drop area first time
            __dropFieldAdd();

            //bind drag&drop events
            const containsFiles = (event) => {
                if (event.originalEvent.dataTransfer.types) {
                    const isFile = _.find(event.originalEvent.dataTransfer.types, (o) => { return o === 'Files' });
                    if (isFile) {
                        return true;
                    }
                }
                return false;
            }
            //bind drag&drop events
            that.body.off('drop.namespace').on('drop.namespace', '.esample', function (e) {
                e.stopPropagation();
                e.preventDefault();
                $(this).removeClass('hover');

                if (e.dataTransfer) {
                    var file = e.dataTransfer.files;
                }
                else if (e.originalEvent.dataTransfer) {
                    var file = e.originalEvent.dataTransfer.files;
                }
                html.widget.FileManagerUpload.quickUpload(file[0], $(this));
            });
            that.body.off('dragover.namespace').on('dragover.namespace', '.esample', function (e) {
            if (!containsFiles(e)) {
                return;
            } else {
                e.stopPropagation();
                e.preventDefault();
                $(this).addClass('hover');
            }
                
            });
            that.body.off('dragleave.namespace').on('dragleave.namespace', '.esample', function (e) {
                e.stopPropagation();
                e.preventDefault();
                $(this).removeClass('hover');
            });
        };

        var __refreshLayoutStruct = function () {
            //TODO: Refresh style and stylesheet
            that.layoutStruct.rows = [];
            that.layoutStruct.layer1 = that.body.find(".layer_1");
            that.body.find('.drow').each(function (y) {
                var row = $(this);
                row.cells = row.find(".dwrap").map(function (x) { return $(this); }).get();
                that.layoutStruct.rows.push(row);
            });
            __dropFieldAdd();
        };

        thats.__refreshLayoutStruct = __refreshLayoutStruct;

        //CREATED JQUERYOBJECT EASY TO GET FOR THE SELECTED ROW OR CELL
        var __updateRowStructure = function (row) {
            if (row) {
                //Update row structure
                row.cells = row.find(".dwrap").map(function () { return $(this) }).get();
                if (row.cells.length > 0) {
                    //NOTE: Trouble widh image rescaling....
                    setWidth(row);
                } else {
                    //Remove row when there is no cells
                    var rows = that.layoutStruct.rows;
                    $.each(rows, function (index, row) {
                        if (row.cells.length === 0) {
                            rows.splice(index, 1);
                            row.parent().remove();
                            return false;
                        }
                    });
                }
            }
        };

        //Add NewCell and NewRow divs, bind resize event and drag&drop new blocks
        var __dropFieldAdd = function () {
            //Remove old drop area
            that.body.find(".addnewrow, .addnewcell").remove();
            /* ADD NEW ROW FIELD */
            var layoutStruct = that.layoutStruct,
                n = that.layoutStruct.rows.length,
                widthLayout_1 = parseInt(layoutStruct.cssStyle[".layer_1"]["max-width"]);
            $(layoutStruct.rows[0]).prepend(Mustache.render(templateItem.rowDropArea, { x: 0, y: -1, dir: "before", css: (showlayerborder) ? "" : " hideborder" }));
            for (var y = 0; y < n; y++) {
                var row = layoutStruct.rows[y], cellsCount = row.cells.length, rowHeight = row.height();

                //ADD ROW DROP AREA
                row.append(Mustache.render(templateItem.rowDropArea, { x: 0, y: y, dir: "after", css: (showlayerborder) ? "" : " hideborder" }));
                //ADD CELL DROP AREA
                $.each(row.cells, function (x, cell) {
                    cell.attr("data-y", y);
                    cell.attr("data-x", x);
                    //give the column a unique id so we know where we are if the user drops
                    $(Mustache.render(templateItem.cellDropArea,
                        {
                            y: y,
                            x: x,
                            id: "addnewcell" + y + "-" + x,
                            dir: "before",
                            style: "height:" + rowHeight + "px;top:" + cell.offset().top + "px;left:" + (cell.offset().left - 5) + "px",
                            css: ((x < cellsCount && x > 0) ? "ew-resize" : "") + ((showlayerborder) ? "" : " hideborder")
                        })).appendTo(that.body);
                    if (x + 1 === cellsCount) {
                        $(Mustache.render(templateItem.cellDropArea,
                            {
                                y: y,
                                x: x,
                                id: "addnewcell" + y + "-" + (x + 1),
                                dir: "after",
                                style: "height:" + rowHeight + "px;top:" + cell.offset().top + "px;left:" + (cell.offset().left + cell.outerWidth(true) - 5) + "px",
                                css: (showlayerborder) ? "" : "hideborder"
                            })).appendTo(that.body);
                    }
                });
            }

            //Set Iframe Height
            setFrameHeight();
        };

        var __bindDropMethod = function () {
            var body = that.body, ewResize = that.body.find(".ew-resize");

            //BIND EVENTS ON DROP AREA
            body.on("dragenter", ".addnewrow, .addnewcell", function () {
                $(this).css({ "background-position": "-9999px -9999px", "border-width": "1px" });
                return false;
            });
            body.on("dragleave", ".addnewrow, .addnewcell", function (e) {
                $(this).css({ "background-position": "center", "border-width": "0px" });
                return false;
            });
            body.on("dragover", ".addnewrow, .addnewcell", function (e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
            body.on("drop", ".addnewrow, .addnewcell", function (e) {
                expandDropAreaToggle();
                //e.preventDefault();
                e.stopPropagation();
                var div = $(this),
                    dragobj = that.dragobj;
                //TODO: Protection against adding cell in the same place
                //Create new cells
                var $newCells = $();
                if (!dragobj.isMove) {
                    //Drop new Cell Content
                    $.each(dragobj.mockup, function (i, block) {
                       
                        var cell = $(Mustache.render(templateItem.wraper, { content: html.getraw("Mockup" + block, { cellpadding: that.layoutStruct.cssStyle['.edcontent>tbody>tr>td']?.padding || 0 }) }));
                        cell.prepend(html.getraw("CellTools"));
                        $newCells = $newCells.add(cell[0]);
                        
                        if (dragobj.mockup[0] === 'Socialfollow') {
                            const oldTemplateHeight = $('#dtemplateframe').height();
                            setTimeout(() => {
                                const cellHeight = cell.height();
                                const newTemplateHeight = oldTemplateHeight + cellHeight;
                                $('#dtemplateframe').height(newTemplateHeight)

                            }, 1000)
                        }
                        
                    });
                } else {
                    //Move Existing Cell
                    $newCells = dragobj;
                }
                _buffer.set();
                //Add new row or finde editing row
                var row = false;
                if (div.hasClass("addnewrow")) {
                    row = __addRow(div, $newCells);
                } else {
                    row = __addCell(div, $newCells);
                }
                if (dragobj.rowObj) {
                    //Rebuild the row cells struct which the cell was taken
                    __updateRowStructure(dragobj.rowObj);
                }
                isChange(true);
                //Refresh Drop area
                __dropFieldAdd();
                return false;
            });
            body.on("dragend", function () {
                if (that.dragobj)
                    that.dragobj = null;
                if (that.isExpanded === true) {
                    expandDropAreaToggle();
                }

                return false;
            });
            //BIDN EVENTS FOR RESIZE CELLS
            //Width Cells
            body.on("touchstart mousedown", ".ew-resize", function (e) {
                e.stopPropagation();
                _disableTools();
                _buffer.set();
                var div = $(this), x = parseInt(div.attr('data-x')), y = parseInt(div.attr('data-y'));
                var row = that.layoutStruct.rows[y];
                that.dragobj = { rowY: y, x: e.clientX, holdElement: this, leftCell: row.cells[(x - 1)], rightCell: row.cells[x], y: y, $rightImg: row.cells[x].find(".edimg img:first"), $leftImg: row.cells[(x - 1)].find(".edimg img:first") };
                that.dragobj.leftCell.addClass("dwrap-nohover");
                that.dragobj.rightCell.addClass("dwrap-nohover");
                body.attr('unselectable', 'on').css('UserSelect', 'none').css('MozUserSelect', 'none');
                body.on("touchmove mousemove", __eventResizeCell);
            });
      
            body.on("touchend mouseup", function () {
                if (that.dragobj != null) {
                    body.off("touchmove mousemove");
                    that.dragobj.leftCell.removeClass("dwrap-nohover");
                    that.dragobj.rightCell.removeClass("dwrap-nohover");
                    that.dragobj = null;
                    isChange();
                }
                body.css("cursor", "").removeAttr('unselectable').css('UserSelect', '').css('MozUserSelect', '');
            });

            //Move and Remove Cell "cell Tools"
            body.on("click", ".cellOptions .ico-trash", function (e) {
                e.stopPropagation();
                var $wrap = $(this).closest(".dwrap"), y = parseInt($wrap.attr("data-y")), x = parseInt($wrap.attr("data-x"));
                _buffer.set();
                __eventRemoveCell(y, x);
            });
            body.on("dragstart", ".cellOptions .ico-move, .cellOptions .ico-copy", function (e) {
                e.stopPropagation();
                
                _disableTools(true);
                expandDropAreaToggle();
                var $wrap = "";
                if ($(this).hasClass("ico-copy")) {
                    $wrap = $(this).closest('.dwrap').clone();
                } else {
                    $wrap = $(this).closest('.dwrap');
                }
                // $(this).closest('.dwrap')

                var drow = $(this).closest(".drow")[0];

                //Disabled other tools
                that.dragobj = $wrap;
                that.dragobj.isMove = true;
                that.dragobj.backgroundRow = drow.style.backgroundColor;
                that.dragobj.x = parseInt($wrap.attr("data-x"));
                that.dragobj.y = parseInt($wrap.attr("data-y"));
                that.dragobj.rowObj = that.layoutStruct.rows[parseInt($wrap.attr("data-y"))];
                if (ee.tools.isFF()) {
                    e.originalEvent.dataTransfer.setData('text/plain', 'Only required to start drag on ff.');
                }
                return true;
            });
            
            //Hover cellTools and border
            body.on("mouseenter", ".dwrap", function () { if ($(this).find(".edited").length === 0) { $(this).addClass("dwrap-hover"); } });
            body.on("mouseleave", ".dwrap", function () { $(this).removeClass("dwrap-hover"); });
        }

        var __addRow = function (div, $newCells) {
            var bg = "";

            if ($newCells.isMove) {
                var edcontent = $newCells.find(".edcontent")[0];
                if ($newCells.backgroundRow != "" && edcontent.style.backgroundColor === "") {
                    bg = $newCells.backgroundRow;
                } else {
                    bg = edcontent.style.backgroundColor;
                    edcontent.style.backgroundColor = "";
                }
            }

            var row = $(html.render(templateItem.newRow, { background: bg }));
            var structRow = row.children();
            //Insert new Row;
            if (div.data('dir') === "before") {
                row.insertBefore(that.layoutStruct.rows[0].parent());
                that.layoutStruct.rows.unshift(structRow);
            } else {
                row.insertAfter(that.layoutStruct.rows[div.attr('data-y')].parent());
                that.layoutStruct.rows.splice(parseInt(div.attr('data-y')) + 1, 0, structRow);
            }
            //Insert new Cells to row
            row.find('td').append($newCells);
            //Create cells structure in row
            __updateRowStructure(structRow);
            return structRow;
        };

        var __addCell = function (div, $newCells) {
            if ($newCells.isMove) {
                var $edcontent = $newCells.find(".edcontent");
                if ($edcontent[0].style.backgroundColor === "" && $newCells.backgroundRow !== "") {
                    $edcontent.css("background-color", $newCells.backgroundRow);
                }
            }
            var row = that.layoutStruct.rows[div.attr('data-y')], x = parseInt(div.attr('data-x'));
            if (div.data('dir') === "before") {
                $newCells.insertBefore(row.cells[x]);
            } else {
                $newCells.insertAfter(row.cells[x]);
            }
            //Create cells structure in row
            __updateRowStructure(row);
            return row;
        };

        var __eventRemoveCell = function (y, x) {
            if (typeof y === "undefined" || typeof x === "undefined" || (that.layoutStruct.rows.length === 1 && that.layoutStruct.rows[0].cells.length === 1)) {
                console.log("error");
                return;
            }
            var row = that.layoutStruct.rows[y];
            row.cells[x].remove();
            row.cells.splice(x, 1);
            if (row.cells.length === 0) {
                that.layoutStruct.rows[y].parent().remove();
                that.layoutStruct.rows.splice(y, 1);
            } else {
                setWidth(row);
            }
            isChange(true);
            __dropFieldAdd();
        };

        var __eventResizeCell = function (e) {
            if (!that.dragobj) {// && !rowresize) {
                console.log("Empty Resize");
                return;
            }
            //resize the columns
            e = e.originalEvent;
            var dragobj = that.dragobj, dx = e.clientX - that.dragobj.x;
            dragobj.x = e.clientX;
            if (Math.abs(dx) > 0) {
                var maxWidth = dragobj.leftCell.width() + dragobj.rightCell.width(),
                    left = (parseInt(dragobj.leftCell.width()) + dx),
                    right = (parseInt(dragobj.rightCell.width()) - dx);
                if (left < minCellWidth) {
                    left = minCellWidth;
                    right = maxWidth - minCellWidth;
                    dx = 0;
                } else if (right < minCellWidth) {
                    right = minCellWidth;
                    left = maxWidth - minCellWidth;
                    dx = 0;
                }
                $(dragobj.holdElement).css("left", (parseInt($(dragobj.holdElement).css("left")) + dx));
                dragobj.rightCell.css("max-width", right + "px");
                dragobj.leftCell.css("max-width", left + "px");
                if (dragobj.$rightImg) {
                    var newWidth = (parseInt(dragobj.$rightImg.css("max-width")) - dx);
                    dragobj.$rightImg.css("max-width", newWidth + "px").attr("width", newWidth);
                }
                if (dragobj.$leftImg) {
                    var newWidth = (parseInt(dragobj.$leftImg.css("max-width")) + dx);
                    dragobj.$leftImg.css("max-width", newWidth + "px").attr("width", newWidth);
                }
                heightAdjustment(dragobj.y);
            }
        };

        //height adjustment for all addnewcell div, staretd from row_y to last rows.
        var heightAdjustment = function (row_y) {
            if (!row_y) row_y = 0;
            for (var y = row_y, n = that.layoutStruct.rows.length; y < n; y++) {
                var row = that.layoutStruct.rows[y];
                var rowHeight = row.height();

                for (var x = 0, m = row.cells.length; x < m; x++) {
                    var offset = row.cells[x].offset();
                    that.body.find("#addnewcell" + y + "-" + x).height(rowHeight).css({
                        height: rowHeight + "px",
                        top: offset.top + "px",
                        left: ((x == m) ? offset.left + row.cells[x].outerWidth(true) - 5 : offset.left - 5) + "px",
                    });
                    if (x + 1 === m) {
                        that.body.find("#addnewcell" + y + "-" + (x + 1)).height(rowHeight).css({
                            height: rowHeight + "px",
                            top: offset.top + "px",
                            left: (offset.left + row.cells[x].outerWidth(true) - 5) + "px",
                        });

                    }
                }
            }
            setFrameHeight();
        }

        // Change page width
        var setWidth = function (row, pageWidth) {
            var layout = that.layoutStruct;
            if ($.type(row) === "object") {
                //Align the width of the cells in a row
                var pageWidth = parseInt(that.layoutStruct.cssStyle[".layer_1"]["max-width"]) - that.layoutStruct.cssStyle[".layer_1"]["border-width"];
                var cellwidth = (pageWidth / row.cells.length);
                row.find('.dwrap').css("max-width", cellwidth + "px");
                row.find(".edimg img").each(function () { ee.editor.core.plugins.imageeditor.scaleimage($(this)); });
            } else {
                setStyle(".layer_1", "max-width", pageWidth + "px");
                pageWidth -= that.layoutStruct.cssStyle[".layer_1"]["border-width"];
                for (var y = 0, n = layout.rows.length; y < n; y++) {
                    var row = layout.rows[y];
                    var cellwidth = (pageWidth / row.cells.length);
                    for (var x = 0, m = row.cells.length; x < m; x++) {
                        row.cells[x].css("max-width", cellwidth + "px");
                    }
                    row.find("img").each(function () {
                        ee.editor.core.plugins.imageeditor.scaleimage($(this));
                    });
                }
            }
            return true;
        }

        var setPaddingTop = function (paddingTop) {
            setStyle("#dbody", "padding-top", paddingTop + "px");
            setStyle("#dbody", "padding-bottom", paddingTop + "px");
            isChange();
        }

        var setCellPadding = function (newpadding) {
            var body = that.body.find("#dbody");
            var oldPadding = parseInt(that.layoutStruct.cssStyle[".edcontent>tbody>tr>td"]["padding"]);
            body.find(".edcontent>tbody>tr>td").each(function () {
                var $td = $(this);
                if (parseInt($td.css('padding'), 10) === oldPadding) {
                    $td.css('padding', newpadding + "px")
                }
                that.setStyle(".edcontent>tbody>tr>td", "padding", newpadding + "px");
            });
            isChange();
        }

        var toggleLayoutBorder = function () {
            that.body.find(".addnewrow, .addnewcell").toggleClass("hideborder");
            showlayerborder = !showlayerborder;
        }

        /*--- STYLES METHODS ---*/
        var doInlineStyle = function (body) {
            var strCss = "",
                rules = that.styleSheet.cssRules || that.styleSheet.rules,
                i, j, n;
            for (i = rules.length - 1; i >= 0; i--) {
                var rule = rules[i],
                    selector = rule.selectorText,
                    elements = body.find(selector),
                    strCss = rule.cssText + strCss,
                    objCss = (__StyleTextToObj(rule.style.cssText.trim()));
                for (j = elements.length - 1; j >= 0; j--) {
                    var element = elements[j];
                    for (var styleName in objCss) {
                        if (objCss.hasOwnProperty(styleName) === true && element.style[styleName] === '') {
                            element.style[styleName] = objCss[styleName];
                        }
                    }
                }
                //Special method for preserving button color
                if (selector === ".edbutton a") {
                    for (j = elements.length - 1; j >= 0; j--) {
                        var element = elements[j];
                        if (element.children.length === 1) {
                            element.children[0].style.color = element.style.color;
                        }
                    }
                }
            }
            return strCss;
        };

        var __removeInlineStyle = function () {
            var body = that.body;
            var strCss = "",
                rules = that.styleSheet.cssRules || that.styleSheet.rules,
                n = rules.length
            body.find("table:first").css("background-color", "");
            for (var i = 0; i < n; i++) {
                var rule = rules[i], selector = rule.selectorText;
                var objCss = __cssTextToObj(rule.style.cssText.trim()), elements = body.find(selector);
                elements.each(function () {
                    var element = $(this),
                        inlinestyle = __cssTextToObj(element.attr("style"));
                    element.removeAttr("style");
                    $.each(inlinestyle, function (sel, val) {
                        if (val === objCss[sel]) {
                            delete inlinestyle[sel];
                        }
                    });
                    element.css(inlinestyle);
                });
                that.layoutStruct.cssStyle[selector.replace(/\s+/g, '')] = objCss;
            }
            //Remove button width and height
            body.find(".edbutton table").removeAttr("width height");
            body.find(".edbutton table td").removeAttr("width height");
        };

        // Clean up the style sheet from not necessary rule
        var __cleanUpStylSheet = function (ruleToRemove) {
            for (var i = 0, n = ruleToRemove.length; i < n; i++) {
                __removeStyle(ruleToRemove[i]);
            }
        };

        var RGBToHex = function (color) {
            var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color);
            if (!nums || nums.length < 4) { return "" };
            var r = parseInt(nums[2], 10).toString(16),
                g = parseInt(nums[3], 10).toString(16),
                b = parseInt(nums[4], 10).toString(16);
            return "#" + (
                (r.length == 1 ? ("0" + r) : r) +
                (g.length == 1 ? ("0" + g) : g) +
                (b.length == 1 ? ("0" + b) : b)
            );

        };
        //Note: deprecated
        var __cssTextToObj = function (cssText) {
            var objCSS = {};
            if (cssText && typeof cssText === 'string') {
                cssText = (cssText + ";").replace(/;+$/, ';').split(";");
                var style = "";
                for (var j = 0, m = cssText.length - 1; j < m; j++) {
                    style = cssText[j].split(":");
                    if (style.length < 2) {
                        continue;
                    }
                    style[1] = style[1].trim();
                    //Calculate rgb to hex
                    if (/rgb/.test(style[1])) {
                        style[1] = RGBToHex(style[1]);
                    }
                    objCSS[style[0].trim()] = style[1].trim().replace(/"/g, "'").replace(/, /g, ",");
                }
            }
            return (objCSS);
        };
        var __StyleTextToObj = function (cssText) {
            var objCSS = {};
            if (cssText && typeof cssText === 'string') {
                cssText = (cssText + ";").replace(/;+$/, ';').split(";");
                var styleName = "";
                for (var j = 0, m = cssText.length - 1; j < m; j++) {
                    styleName = __parseCssNameStyle(cssText[j].substring(0, cssText[j].indexOf(":")).trim());
                    if (!styleName) continue;
                    objCSS[styleName] = cssText[j].substring(cssText[j].indexOf(":") + 1).trim().replace(/"/g, "'").replace(/, /g, ",");
                }
            }
            return (objCSS);
        };
        // Correct style name in javascript are such as: fontSize, borderWidth, BorderLeftColor etc.
        // cssProperties - string - example "font-size"
        // return string - example "fontSize"
        var __parseCssNameStyle = function (cssProperties) {
            if (!cssProperties) { return "" };
            var tmp = cssProperties.split("-");
            var n = tmp.length;
            for (var i = 1; i < n; i++) {
                tmp[i] = tmp[i].charAt(0).toUpperCase() + tmp[i].substr(1);
            }
            return tmp.join("");
        };
        // Find a rule in the stylesheet for the indicated selector
        // selector - string - CSS selector (example: h1, #page, .element)
        // return element - object - CSS Rule object
        var __getCssRule = function (selector) {
            var rules = that.styleSheet.cssRules || that.styleSheet.rules;
            var element = null;
            var n = rules.length, regexp = / > /g;
            for (var i = 0; i < n; i++) {

                if (selector == rules[i].selectorText.replace(regexp, '>')) {
                    element = rules[i];
                    break;
                }
            }
            return element;
        };
        // Set style for selected stylsheet rule
        var setStyle = function (selector, cssProperties, cssValue) {
            var element = __getCssRule(selector);
            element.style[__parseCssNameStyle(cssProperties)] = cssValue;  //Change Rule
            that.layoutStruct.cssStyle[selector.replace(" ", "")][cssProperties] = cssValue;
        };
        var compareStyleValue = function (selector, cssProperties, cssValue) {
            return that.layoutStruct.cssStyle[selector.replace(" ", "")][cssProperties] === cssValue;
        }
        var __insertRule = function (selector, styleValues) {
            if (typeof styleValues === "object") {
                var styleString = "";
                $.each(styleValues, function (key, val) {
                    styleString += key + ":" + val + ";";
                });
                styleValues = styleString;
            }
            that.styleSheet.insertRule(selector + " { " + styleValues + " }", (that.styleSheet.rules || that.styleSheet.cssRules).length);
            var cssObject = __cssTextToObj(styleValues);
            that.layoutStruct.cssStyle[selector.replace(" ", "")] = cssObject;
        }
        // Remove style by css selector
        var __removeStyle = function (selector) {
            var rules = that.styleSheet.cssRules || that.styleSheet.rules;
            var element = null;
            var n = rules.length;
            for (var i = 0; i < n; i++) {
                if (selector == rules[i].selectorText) {
                    if (that.styleSheet.deleteRule) {
                        // all browsers, except IE before version 9
                        that.styleSheet.deleteRule(i);
                    } else {
                        // Internet Explorer before version 9
                        that.styleSheet.removeRule(i);
                    }
                    break;
                }
            }
        };
        var __removeDuplicateRule = function (selector) {
            var rules = that.styleSheet.cssRules || that.styleSheet.rules, elements = [], regexp = / > /g;
            for (var i = 0, n = rules.length; i < n; i++) {
                if (selector == rules[i].selectorText.replace(regexp, '')) {
                    rules[i]._index = i;
                    elements.push(rules[i]);
                }
            }
            if (elements.length == 2) {
                that.styleSheet.deleteRule(elements[1]._index);
            }
        };
        /*---- STYLE END ----*/
        // Return prepered template body to save.
        var getCleanBody = function () {
            //Prepare body to save
            let $body = that.body.parent().clone();
            let style = that.layoutStruct.cssStyle;
            let fontLinks = $body.find("head > link[data-name]");
            let plugins;
            if (ee.editor.core) {
                plugins = ee.editor.core.plugins;
            }
            let usedFontFamily;
            if (ee.editor && ee.editor.listOfUsedFonts()) {
                usedFontFamily = ee.editor.listOfUsedFonts();
            }

            $body.removeAttr('style class id');
            $body.find("[data-editor=yes], .addnewcell, .addnewrow, .cellOptions, .sp-container, .redactor-link-tooltip, .redactor-dropdown, .redactor-invisible-space").remove();
            $body.find('body').removeAttr('class');
            var $dbody = $body.find("#dbody:first");

            if (!isOutlokCommentAppended($dbody[0], html.render(outlook.layerStart, { 'width': parseInt(style['.layer_1']['max-width']) }))) {
                $dbody.prepend(html.render(outlook.layerStart, { 'width': parseInt(style['.layer_1']['max-width']) }));
            }
            if (!isOutlokCommentAppended($dbody[0], outlook.layerEnd)) {
                $dbody.append(outlook.layerEnd);
            }
            let $drows = $body.find(".drow");
            for (var i = 0, n = $drows.length; i < n; i++) {
                let $drow = $($drows[i]);
                let $dwraps = $drow.find(".dwrap");
                let countCols = $dwraps.length;
                let LastColsIndex = countCols - 1;

                for (var j = 0; j < countCols; j++) {
                    let $dwrap = $($dwraps[j]);
                    let $layer2 = $dwrap.children();
                    $layer2.css("max-width", $dwrap.css("max-width"));
                    if (j === 0) {
                        if (!isOutlokCommentAppended($drow[0], outlook.cellStart)) {
                            $dwrap.prepend(outlook.cellStart);
                        }
                    } else {
                        if (!isOutlokCommentAppended($drow[0], outlook.cellBetween)) {
                            $dwrap.prepend(outlook.cellBetween);
                        }
                    }
                    if (j === LastColsIndex) {
                        if (!isOutlokCommentAppended($drow[0], outlook.cellEnd)) {
                            $dwrap.append(outlook.cellEnd);
                        }
                    }
                    $layer2.unwrap();
                }
            }
            for (var pluginName in plugins) {
                if (plugins.hasOwnProperty(pluginName) === true && plugins[pluginName].beforeSave) {
                    plugins[pluginName].beforeSave($body);
                }
            }
            //Remove unused font family
            for (i = fontLinks.length - 1; i >= 0; i--) {
                var link = fontLinks[i];
                if (usedFontFamily && usedFontFamily.indexOf(link.dataset.name) === -1) {
                    link.parentNode.removeChild(link);
                }
            }
            //Inherit background color by main template tables
            $body.find("table:first").css("background-color", that.layoutStruct.cssStyle['#dbody']['background-color']);
            //Remove unnecessary attributes
            cleanAttrFromTags($body);
            return $body;
        };
        var isOutlokCommentAppended = function (parentNode, commentString) {
            let isAppended = false;
            _.forEach(parentNode.childNodes, el => {
                if (typeof el.nodeValue === "string" && ("<!--"+el.nodeValue.trim()+"-->") === commentString.trim()) {
                    isAppended = true;
                }
            });
            return isAppended;
        };
        let setFrameHeight = function () {
            setTimeout(function() {
                let newHeight = that.layoutStruct.layer1[0].clientHeight;
                let tablePaddings = parseInt(that.layoutStruct.cssStyle["#dbody"]['padding-top']) + parseInt(that.layoutStruct.cssStyle["#dbody"]['padding-bottom']);

                newHeight = newHeight + tablePaddings;
                iframe.height(newHeight); 
            }, 1200);
        };
        thats.setFrameHeight = setFrameHeight;
        var __parseIncompatible = function () {
            var $dbody = that.body.find("#dbody"),
                templateVersion = parseFloat($dbody.data("version"));
            if (isNaN(templateVersion) === true) templateVersion = 0;
            if (templateVersion < 1.6) {
                //Break text in cell
                var edtextStyle = __getCssRule('.edtext');
                that.body.find(".edtext").css("word-break", "");
                if (edtextStyle) {
                    edtextStyle.style[__parseCssNameStyle('word-break')] = "break-word";
                }
            }
            if (templateVersion < 1.7) {
                __insertRule("p", "margin-top:0;margin-bottom:0;padding:0");
            }
            if (templateVersion < 2.0) {
                var $layer1 = that.body.find(".layer_1:first");
                var $OldDbody = that.body.find("#dbody:first");
                var styleLayer2 = __getCssRule('.layer_2');
                var cellBackground = (styleLayer2) ? styleLayer2.style.backgroundColor : "#fff";

                var $OldDbodyStyle = {
                    'padding-top': $OldDbody.attr('data-top') + "px",
                    'padding-bottom': $OldDbody.attr('data-top') + "px",
                    'background-color': $OldDbody.css("background-color"),
                    'width': "100%"
                }
                $dbody = $OldDbody.find("td:first");
                $OldDbody.removeAttr("style data-top data-version id");
                $OldDbody.attr("style", "height: 100%; width: 100%;");

                $dbody.attr("id", "dbody").removeAttr("style");

                //update CSS
                __insertRule(".layer_1", "max-width:" + $layer1.attr("width") + "px;width:100%;box-sizing:border-box;");
                __insertRule("#dbody", $OldDbodyStyle);
                __insertRule(".drow", { 'background-color': cellBackground, 'box-sizing': 'border-box', 'font-size': '0px' });
                __insertRule(".edimg", "box-sizing:border-box;text-align:center;");
                var edtextStyle = __getCssRule('.edtext');
                var a = __getCssRule('a');
                var style1 = __getCssRule('.style1');
                var style2 = __getCssRule('.style2');
                var style3 = __getCssRule('.style3');
                var style4 = __getCssRule('.style4');

                a.style["lineHeight"] = "";
                style1.style["lineHeight"] = "";
                style2.style["lineHeight"] = "";
                style3.style["lineHeight"] = "";
                style4.style["lineHeight"] = "";
                edtextStyle.style["textAlign"] = "left";
                edtextStyle.style["direction"] = "ltr";
                edtextStyle.style["boxSizing"] = "border-box";
                edtextStyle.style["lineHeight"] = "";
                styleLayer2.style["border"] = "";
                styleLayer2.style["backgroundColor"] = "";
                styleLayer2.style["width"] = "100%";
                styleLayer2.style["display"] = "inline-block";
                styleLayer2.style["verticalAlign"] = "top";
                styleLayer2.style["margin"] = "0 auto";
                //Add Breakline block class
                $layer1.find(".edcontent td > hr").each(function (i, el) {
                    var $el = $(el);
                    var $edcontent = $el.closest('.edcontent');
                    var verticalMargin = parseInt($edcontent.attr("height")) - parseInt($el.css("border-top-width"));
                    var horizontalMargin = parseInt($edcontent.attr("cellpading"));
                    $el.css({ "margin-top": verticalMargin + "px", "margin-bottom": verticalMargin + "px" });
                    $el.css({ "margin-left": horizontalMargin + "px", "margin-right": horizontalMargin + "px" });
                    $el.parent().addClass("breakline");
                    $edcontent.attr("cellpading", "0");
                });
                //Remove attr and change layer_2 to div
                $layer1.removeAttr("width");
                var layer1BorderWidth = parseInt($layer1.css("border-top-width")) * 2;
                if (isNaN(layer1BorderWidth)) layer1BorderWidth = 0;
                $layer1.find(".drow").each(function (i, row) {
                    var $row = $(row);
                    $row.attr("align", "center");
                    $row.removeAttr("height");
                    var rowBackground = "", currnet = "";
                    $row.find(".layer_2").each(function (j, cell) {
                        var $cell = $(cell), width = parseInt($cell.attr("width")) + 2 - layer1BorderWidth, background = $cell.css("background-color"), $edcontent = $cell.find(".edcontent:first");
                        $cell.html($edcontent);
                        $edcontent.unwrap();
                        $wrap = $('<div class="layer_2"></div>');
                        $wrap.css({ "max-width": width + "px" });
                        $edcontent.removeAttr("width height").attr("style", "border-collapse: collapse;width:100%");
                        $edcontent.css("background-color", (cellBackground === background) ? "" : background);
                        $edcontent.wrap($wrap);
                    });
                    var $edcontents = $row.find(".edcontent");
                    var bgArr = $edcontents.map(function () { return this.style.backgroundColor }).get();
                    if (bgArr.allValuesSame() === true && bgArr.length > 0) {
                        $edcontents.css("background-color", "");
                        $row.css("background-color", bgArr[0]);
                    }

                });
                //Fix block elements
                $layer1.find(".edimg div").each(function (i, el) {
                    var $edimg = $(el), $img = $edimg.find("img");
                    $img.removeAttr("height width");
                    $img.css({ "height": "", "max-width": $img.width() + 2 });
                    ($edimg.children()).unwrap();
                });

                //Add empty block class
                $layer1.find(".edcontent tr > td:empty").addClass("emptycell");

                $layer1.find(".edit").each(function () {
                    $(this).replaceWith(this.childNodes);
                });
                var $style = $((ee.editor.core.body.parent()).find("style")[1]);
                $style.html(html.getraw("MobileStyle", {}));
            }
            if (templateVersion < 2.1) {
                that.body.find("img").each(function () {
                    var $img = $(this);
                    $img.attr("width", parseInt($img.css("max-width")));
                });
            }
            if (templateVersion < 2.14) {
                //Remove 0 lin-height from image (outlook issue)
                var edimg = __getCssRule('.edimg');
                edimg.style["lineHeight"] = "";
                //Update Paragraph rulse (outlook issue)
                var p = __getCssRule('p');
                p.style[__parseCssNameStyle('padding')] = "0";
                p.style[__parseCssNameStyle('margin-top')] = "0";
                p.style[__parseCssNameStyle('margin-bottom')] = "0";
                //Change break line hr to div tag (outlook issue)
                that.body.find(".breakline > hr").each(function () {
                    $(this).changeElementType('div');
                });
                //Remove border-collapse from layer_1 (ie and outlook issue)
                var layer1CSS = __getCssRule('.layer_1');
                layer1CSS.style[__parseCssNameStyle('border-collapse')] = "";
                var $layer1 = that.body.find(".layer_1:first");
                $layer1.css("border-collapse", "");

                //UPDATE MOBILE STYLE
                $html = $(ee.editor.core.body.parent());
                var $style = $($html.find("style")[1]);
                $style.html(html.getraw("MobileStyle", {}));
                //Add OUTLOOK STYLE
                $("<!--[if gte mso 9]>" + html.getraw("OutlookStyle", {}) + "<![endif]-->").appendTo($html.find("head:first"));
                //Remove old line-hegight 125% from text element
                that.body.find(".edtext").each(function () {
                    $(this).find("div, a, p, .style1, .style2, .style3, .style4").each(function () {
                        var el = this;
                        if (el.style.lineHeight === "125%") {
                            el.style.lineHeight = "";
                        }
                    });
                });
                //Remove border-collapse from body template(outlook issue)
                that.body.find("table:first").attr("style", "width:100%;height:100%;");
                //Remove style from edimg and edtext elements;(IE, EDGE issue)
                that.body.find(".edtext").removeAttr("style");

                that.body.find(".edimg").each(function () {
                    var $edimg = $(this), $img = $edimg.find("img:first");
                    $edimg.removeAttr("style");
                    var maxWidth = $edimg.width() - (parseInt($img.css("border-top-width")) * 2);
                    var imgWidth = parseInt($img.css("max-width"));
                    if (imgWidth > maxWidth) {
                        $img.css("max-width", maxWidth).attr("width", maxWidth);
                    } else {
                        $img.attr("width", imgWidth);
                    }
                });
            }
            
            if (templateVersion < 2.20) {
                __insertRule(".edbutton td", "background:#FFBD03;");
                __insertRule(".edbutton a", "color: rgb(255, 255, 255); font-size: 16px; font-family: Helvetica,Arial,sans-serif; font-weight:normal;text-decoration: none;width: 100%;display: inline-block;word-break: break-word;");
            }
            if (templateVersion < 2.21) {
                $dbody.find(".edbutton a").each(function () {
                    var $this = $(this), padding = $this.css('padding');
                    $this.closest('td').css('padding', padding);
                    $this.css('padding', 0);
                    __getCssRule('.edbutton a').style['padding'] = 0;
                });
            }
            if (templateVersion < 2.23) {
                __insertRule(".text-center", "text-align:center");
                __insertRule(".text-justify", "text-align:justify");
                __insertRule(".text-right", "text-align:right");
                __insertRule(".text-left", "text-align:left");
            }
            if (templateVersion < 2.24) {
                var cssText = __getCssRule('.edbutton a').cssText;
                __removeStyle('.edbutton a');
                __insertRule('.edbutton a', cssText.substring(cssText.indexOf('{') + 1, cssText.indexOf('}')).trim());
            }
            if (templateVersion < 2.25) {
                var layer_1 = __getCssRule('.layer_1');
                layer_1.style.margin = "0 auto";
                var $edcontents = that.body.find(".edcontent");
                for (var i = $edcontents.length - 1; i >= 0; i--) {
                    var $edcontent = $($edcontents[i]), padding = parseInt($edcontent.attr("cellpadding"), 10);
                    $edcontent.find("tbody:first > tr > td").css("padding", padding);
                    $edcontent.attr("cellpadding", "0");
                }
            }
            if (templateVersion < 2.26) {
                $dbody.find(".edbutton > table").css("margin", "0 auto");
            }
            if (templateVersion < 2.27) {
                var cellpading = __getCssRule('.cellpading');
                if (cellpading) {
                    __removeStyle('.cellpading');
                    __insertRule(".edcontent>tbody>tr>td", "padding:" + cellpading.style.padding + ";");
                }
            }
            if (templateVersion < 2.28) {
                var ahref = __getCssRule('a');
                __removeStyle('a');
                __insertRule(".edtext a", "color: #3498db; text-decoration:none;");
            }
            if (templateVersion < 2.29) {
                $dbody.find('.edbutton a').removeAttr('target').attr('target', '_blank');
            }
            if (templateVersion < 2.30) {
                __removeDuplicateRule(".edtext a");
            }
            if (templateVersion < 2.31) {
                (function () {
                    var mobileStyle = that.body.head.find('style:not(:first)');
                    if (mobileStyle.length === 0) return;
                    var rules = mobileStyle[0].sheet.cssRules || mobileStyle.sheet.rules, cssText = '';
                    for (var i = 0, n = rules.length; i < n; i++) {
                        var rule = rules[i];
                        if (rule.media && rule.media.mediaText === "only screen and (max-width: 480px)") {
                            rule.insertRule('.edsocialfollowcontainer table{max-width:25% !important;}', (rule.rules || rule.cssRules).length);
                            rule.insertRule('.edsocialfollowcontainer table td{padding:10px !important;}', (rule.rules || rule.cssRules).length);
                        }
                        cssText += rule.cssText
                    }
                    $(mobileStyle[0]).html(cssText);
                })()
            }
            that.body.find('.redactor-link-tooltip, .redactor-dropdown, .sp-container, .redactor-invisible-space').remove();
            //Remove style from span button ahref
            $dbody.find(".edbutton a span").removeAttr("style");
            $dbody.find('.edited').removeClass('edited');
            $dbody.find('.esample').closest('.edimg').addClass("dnoimg");
            $dbody.find(".edbutton a").each(function () { 
                if (!$(this).attr('href')) { 
                    $(this).removeAttr('href'); 
                } 
                $(this).css('word-break','break-word');
            })
            cleanAttrFromTags();
            //SET CURRENT VERSION
            $dbody.attr('data-version', thats.version);
        };
        var cleanAttrFromTags = function ($body) {
            if (!$body) {
                $body = that.body;
            }
            $body.find(tagsToClean.join(",")).each(function () {
                var el = this, attrs = el.attributes;
                for (var index = attrs.length - 1; index >= 0; --index) {
                    var name = attrs[index].nodeName;
                    if (allowedAttr.indexOf(name) === -1) {
                        el.removeAttribute(name);
                    }
                }
            });
        };
        var removeEmptyTags = function ($body) {
            if (!$body) {
                $body = that.body;
            }
            for (var i = removeEmpty.length - 1; i >= 0; i--) {
                $body.find(removeEmpty[i]).each(function () {
                    var el = $(this);
                    if (el.html().trim() === "") {
                        el.remove();
                    };
                });
            }
        };
        var isChange = function (skipSetHeight) {
            if (that.changeCallback) {
                that.changeCallback();
            }
            if (skipSetHeight !== true) {
                heightAdjustment();
            }
        }
        var expandDropAreaToggle = function () {
            if (that.isExpanded === false) {
                //expand
                that.body.find(".addnewrow").css({ "height": "20px", "bottom": "-10px" });
                that.body.find(".addnewrow:first").css({ "height": "20px", "top": "-10px" });
                that.body.find(".addnewcell ").each(function () {
                    var newCellArea = $(this);
                    newCellArea.css({ "width": "20px", "left": (parseFloat(newCellArea.css("left")) - 5) + "px" });
                });
            } else {
                //cancel expand
                that.body.find(".addnewrow").css({ "height": "", "bottom": "", "top": "" });
                that.body.find(".addnewcell ").each(function () {
                    var newCellArea = $(this);
                    newCellArea.css({ "width": "10px", "left": (parseFloat(newCellArea.css("left")) + 5) + "px" });
                });
            }
            that.isExpanded = !that.isExpanded;
        };
        var getTemplateContent = function () {
            var template = { bodyhtml: getCleanBody(), css: '' };
            _removeTools(template.bodyhtml);
           
            template.css = doInlineStyle(template.bodyhtml);
            template.bodyhtml = template.bodyhtml[0].outerHTML;
            
            const backgroundValue = $('#ebackgroundcolor')[0].value;
            const backgroundImage = $('#backgroundImage')[0].value;
            
            if (backgroundValue && !backgroundImage) {
                template.bodyhtml = template.bodyhtml.replace('body style="padding:0; margin: 0;', `body style="padding:0; margin: 0;background: ${backgroundValue}`)
            } else if (backgroundImage) {
                template.bodyhtml = template.bodyhtml.replace('body style="padding:0; margin: 0;', `body style="padding:0; margin: 0;background: url('${backgroundImage}')`)
            }
            
            return template;
        };
        var _removeTools = function ($body) {
            if ($body && $body instanceof jQuery) {
                var plugins;
                if (ee.editor.core) {
                    plugins = ee.editor.core.plugins;
                }
                for (var pluginName in plugins) {
                    if (plugins.hasOwnProperty(pluginName) === true && plugins[pluginName].remove) {
                        plugins[pluginName].remove($body);
                    }
                }
            }
        }
        var _disableTools = function (isMoved) {
            if (ee.editor.core && ee.editor.core.body) {
                if (!isMoved) {
                    ee.editor.core.body.find(".dwrap-hover").removeClass("dwrap-hover");
                }
                ee.editor.core._$SpectrumInputs?.spectrum("hide");
                var plugins = ee.editor.core.plugins;
                for (var pluginName in plugins) {
                    if (plugins.hasOwnProperty(pluginName) === true && plugins[pluginName].disable) {
                        plugins[pluginName].disable();
                    }
                }
            }
        };

        /*----- HOTKEYS -----*/
        var _hotKeys = (function () {
            var _specialKeys = { "TAB": 9 },
                _handler = function (key, holdKey, callback) {
                    $(this).on('keydown.etemplate', function (e) {
                        var c = e.which || e.keyCode,
                            mainKeyPress = (String.fromCharCode(c) === key || c === _specialKeys[key]);//Get key code
                        if (e.ctrlKey && e.altKey) {
                            return true;
                        } else if (((holdKey && e[holdKey + 'Key'] === true) || (!holdKey && e['shiftKey'] === false && e['ctrlKey'] === false)) && mainKeyPress) {
                            if (c === _specialKeys[key] && that.body.find('.edited').length === 0) {
                                return true;
                            }
                            e.preventDefault();
                            e.stopPropagation();
                            callback();
                            return false;
                        }
                    });
                },
                _bufferHotKeys = function () {
                    $(this).on('keydown.etemplate', function (e) {
                        var key = e.which || e.keyCode;
                        if (e.ctrlKey && key === 90 && !e.shiftKey && !e.altKey) // z key
                        {
                            e.stopPropagation();
                            e.preventDefault();
                            if (parent.ee.editor.core.body.find('.edited').length > 0) {
                                parent.ee.editor.core.plugins.buffer.undo();
                            } else {
                                ee.editor.core.bufferGlobal.undo();
                            }
                            return;
                        }
                        // redo
                        else if (e.ctrlKey && key === 90 && e.shiftKey && !e.altKey) {
                            e.stopPropagation();
                            e.preventDefault();
                            if (parent.ee.editor.core.body.find('.edited').length > 0) {
                                parent.ee.editor.core.plugins.buffer.redo();
                            } else {
                                ee.editor.core.bufferGlobal.redo();
                            }
                            return;
                        }
                    });
                };
            //Init buffer hotkeys
            _bufferHotKeys.apply(iframejs);
            _bufferHotKeys.apply(window);

            return {
                add: function (keys, callback) {
                    keys = keys.split('+');
                    var secondkey, key = keys[0].toUpperCase();
                    if (keys.length > 1) {
                        secondkey = keys[0];
                        key = keys[1].toUpperCase();
                    }
                    _handler.apply(iframejs, [key, secondkey, callback]);
                    _handler.apply(window, [key, secondkey, callback]);
                }
            }
        })();

        /*----- BUFFER ----- */
        var _buffer = (function () {
            var undobuffer = [], redobuffer = [], maxbuffer = 200,
                _getContent = function () {
                    var $body = that.body.clone();
                    $body.find(".addnewcell, .addnewrow, .sp-container, .redactor-link-tooltip, .redactor-dropdown, .redactor-invisible-space").remove();
                    $body.removeAttr('class');
                    _removeTools($body);
                    return $body[0];
                },
                _restoreStatus = function (buffer, method) {
                    switch (buffer.type) {
                        case "html":
                            _buffer.set(method);
                            that.body.html(buffer.body.innerHTML);
                            __refreshLayoutStruct();
                            break;
                        case "style":
                            buffer.input.val(buffer.value).trigger(buffer.trigger || 'change', [method])
                            break;
                        case "spectrum":
                            var newValue = buffer.value;
                            buffer.value = buffer.input.spectrum("get");
                            _buffer.set(method, buffer);
                            buffer.input.spectrum("set", newValue).trigger('change', [newValue]);
                            break;
                        case "fontfamily":
                            buffer.input.trigger('click', [method]);
                            break;
                    }
                };

            return {
                set: function (type, obj) {
                    if (!type) {
                        redobuffer = [];
                    }
                    if (!obj) {
                        obj = { body: _getContent(), type: 'html' };
                    }
                    if (!type || type === 'undo') {
                        var n = undobuffer.length - 1;
                        if (n > -1) {
                            var last = undobuffer[n];
                            if (obj.type === 'html' && last.type === 'html' && last.body.outerHTML === obj.body.outerHTML) {
                                return;
                            } else if (obj.type === 'style' && obj.type === last.type && last.input.is(obj.input) && last.value === obj.value) {
                                return;
                            }
                        }
                        undobuffer.push(obj);
                        //buffer overflow protection
                        if (maxbuffer < undobuffer.length) {
                            undobuffer.shift();
                        }
                    } else if (type === 'redo') {
                        redobuffer.push(obj);
                    }
                },
                haveUndo: function () {
                    if (undobuffer.length > 0) {
                        return true;
                    }
                    return false;
                },
                undo: function () {
                    if (undobuffer.length === 0) {
                        return;
                    }
                    _restoreStatus(undobuffer.pop(), 'redo')
                },
                haveRedo: function () {
                    if (redobuffer.length > 0) {
                        return true;
                    }
                    return false;
                },
                redo: function () {
                    if (redobuffer.length === 0) {
                        return;
                    }
                    _restoreStatus(redobuffer.pop(), 'undo')
                },
                removeLast: function () {
                    undobuffer.pop();
                }
            }
        })();
        /*----- -----*/

        /* ---- BIND IFRAME EVENTS -----*/
        (function () {
            this.$('body').on("click.disabledlink", "a", function (e) {
                e.preventDefault();
            });
            this.$('html').on('mouseup.disabledtoolbar', function (e) {
                var $target = $(e.target);
                if ($target.closest(".cellOptions,.sp-container,.breakline,.edbutton,.emptycell,.edtext,.edimg,.ee-toolbar,.edited,.redactor-dropdown,#redactor-modal-box").length >= 1) {
                    return;
                }
                window.ee.editor.core.disableTools();
            });
        }).call(iframejs);
        /* ---- END -----*/
        //Assigning parameters
        that.plugins = {};
        that.bufferGlobal = _buffer;
        that.hotkeys = _hotKeys;
        that.dragobj = null;
        that.body = $(iframe[0].contentWindow.document).find('body');
        that.body.head = $(iframe[0].contentWindow.document).find('head');
        //Assigning methods
        that.disableTools = _disableTools;

        that.heightAdjustment = heightAdjustment;
        that.layoutStruct = {};
        that.createLayoutStruct = createLayoutStruct;
        that.setStyle = setStyle;
        that.compareStyleValue = compareStyleValue;
        that.setWidth = setWidth;
        that.setPaddingTop = setPaddingTop;
        that.setCellPadding = setCellPadding;
        that.toggleLayoutBorder = toggleLayoutBorder;
        that.RGBToHex = RGBToHex;
        that.isChange = isChange;
        that.changeCallback;//Callback using when anything was changed in the template
        that.expandDropAreaToggle = expandDropAreaToggle;
        //Get Clean copy of template
        that.getTemplateContent = getTemplateContent;
        //Constructor
        that.iframejs = iframejs;  //remove this later
        iframejs.imageLoad();
        that.init = false;
        that.isExpanded = false;
        return that;
    };
    var pluginList = {};
    var addplugin = function (name, method) {
        if (pluginList[name]) {
            console.log("Plugin with name '" + name + "' already exists");
            return;
        }
        pluginList[name] = method;
    };
    thats.addplugin = addplugin;
    thats.init = init;
    return thats;
})();
