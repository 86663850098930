
//TODO: We need refactoring code handling this screens...

(ee.views["settings"] = function () {
    let addWebhookClicke;

    const that = {
            //show this screen
            formmodified: false,
            show: function () {
            if(ee.Router.action() ==='new'){
                return ee.initNextGen();
            }
                if (!ee.session.can.ViewSettings)
                    return ee.goTo("#/dashboard");
                if (!ee.data.account) {
                    ee.api.loadAccount();
                    return;
                }
                /*NOTE: Temporary for new account screen, In future that global shoud be use on whole dashboard */
                delete ee.data.ippools;

                    if (!ee.data.account.weboptions) {
                        EE_API.Account.LoadWebhook()
                        .then(function(data) {
                            ee.data.account.weboptions = data;
                        })
                        .catch(function(err) {
                            html.modal.error(err);
                        });
                    }

                if (!ee.data.account.ipaddresses && (ee.data.account.mailerid > 0 && ee.data.account.enableprivateiprequest || ee.session.can.AdminViewIP)) {
                    ee.api.loadIPAddresses();
                    return;
                }

                if (!ee.data.account.advancedoptions) {
                    ee.api.loadAdvancedoptions();
                    return;
                }
                if (!ee.data.account.inboundoptions) {
                    EE_API.Account.LoadInboundOptions()
                    .then(function(data) {
                        ee.data.account.inboundoptions = data;
                    })
                    .catch(function(err) {
                        html.modal.error(err);
                    });
                }

                if (ee.languages.isListedLanguages() === false) {
                    ee.languages.list(ee.view.show);
                    return;
                }

                let settingsTab;
                let poolsCollection;
                let enableipmanagment = (!ee.data.account.issub || (ee.data.account.issub && ee.data.account.enableprivateiprequest)) && ee.data.account.mailerid > 0;

                if (!ee.data.account.pools && enableipmanagment) {
                    ee.api.loadPools();
                    return;
                }
                if (enableipmanagment) {
                    poolsCollection = ee.data.account.pools;
                }
                //empty the content area
                ee.frame.content.empty();

                if (ee.base && window.hj) {
                    window.hj('trigger', 'settings');
                }
                //direct load subscreens
                switch (ee.Router.action()) {
                    //Diagnostic need refactor
                    case 'domains':
                        settingsTab = 'edomainstab';
                        ee.logEvent('Settings - show domains');
                        let dialoginfo = ee.Router.parameter('node0');
                        if (dialoginfo === "modalhelp") {
                            html.modal.create(html.get("ValidationDom", {}), { width: "720px", cancel: { title: ee.t.close, css: "buttonClose" } });
                        } else if (dialoginfo === "verificationsuccess") {
                            html.modal.create(html.get("VerificationDomainSuccess", { domain: ee.Router.parameter('domain'), reseller: ee.isReseller(), domainhelplink: "https://help.elasticemail.com/en/articles/2281323-how-to-verify-your-domain" }), { title: '', width: "520px", cancel: { title: ee.t.close, css: 'btn-default' } });
                        }
                        break;
                    case "sending":
                        ee.logEvent('Settings screen loaded');
                        settingsTab = 'esendingtab';
                        break;
                    case "unsubscribe":
                        ee.logEvent('Settings - show unsubscribe');
                        settingsTab = 'eunsubscribetab';
                        break;
                    case "privateips":
                        ee.logEvent('Settings - show private ips');
                        settingsTab = 'eprivateipstab';
                        break;
                    case "notifications":
                        ee.logEvent('Settings - show notifications');
                        settingsTab = 'enotificationstab';
                        break;
                    case "accountconfiguration":
                        ee.logEvent('Settings - show account configuration');
                        settingsTab = 'eaccountconfigurationtab';
                        break;
                    case "smtp":
                        ee.logEvent('Settings - show smtp');
                        if (!ee.Router.parameter('node0')) {
                            settingsTab = 'esmtpconfigurationtab';
                        } else {
                            html.widget.AccessManager.init("fullscreen", "smtp", ee.Router.parameter('node1'));
                            return;
                        }
                        break;
                    case "apikey":
                        ee.logEvent('Settings - show api configuration');
                        if (!ee.Router.parameter('node0')) {
                            settingsTab = 'eapikeyconfigurationtab';
                        } else {
                            html.widget.AccessManager.init("fullscreen", "apikey", ee.Router.parameter('node1'));
                            return;
                        }
                        break;
                    case "ipmanagment":
                        if (enableipmanagment) {
                            ee.logEvent('Settings - show ip managment');
                            settingsTab = 'eipmanagment';
                            break;
                        } else {
                            window.location.hash = '/settings/sending';
                            return;
                        }
                    case "diagnostics":
                        if (ee.Router.parameter('node0') === "details" && ee.Router.parameter('node1')) {
                            ee.view.diagnosticsDetails(ee.Router.parameter('node1'));
                            return;
                        }
                        delete ee.data.diagnosticsdetails;
                        ee.logEvent('Settings - load diagnostics');
                        ee.view.diagnostics();
                        return;
                    default:
                        window.location.hash = '/settings/sending';
                        return;
                }
                //main screen
                ee.data.account.enableipmanagment = enableipmanagment;
                let view = "Settings";
                let screen = html.get(view, {
                    acc: ee.data.account,
                    languages: ee.languages.getAll(),
                    admin: ee.session.can.AdminView,
                    hidemanageapikeys: ee.parameters.hideManageAPIKeys || !ee.session.can.AdminPrivacy,
                    enableipmanagment: ee.data.account.enableipmanagment,
                    advancedoptions: ee.data.account.advancedoptions,
                    weboptions: ee.data.account.weboptions,
                    inboundoptions: ee.data.account.inboundoptions,
                    hidePurchaseIPButton: ee.data.account.activePlan.typedesc === "ContactSubscription" && ee.data.account.issub,
                    apipricingplan: ee.data.account.pricingplan === 5 || ee.data.account.pricingplan === 6 || ee.data.account.pricingplan === 9 || ee.data.account.pricingplan === 10
                });
                screen.appendTo(ee.frame.content);
                ee.view.screen = screen;
              
                let ipadresses;

                if (screen.enablecontactfeatures) {
                    screen.enablecontactfeatures.off().on("click", ee.views.account._enablepremiumtools);
                }

                if (screen.requestpremiumsupport) {
                    screen.requestpremiumsupport.off().on("click", ee.views.account._adddedicatedsupport);
                }

                if (screen.showapikey) {
                    screen.showapikey.on("click", function () {
                        let showapikey = html.get("ShowAPIKey", ee.data.account),
                            buttons = [{
                                name: ee.t.confirm,
                                css: "btn_lg btn_primary",
                                callback: function () {
                                    if (showapikey.apiconsentconfirm.is(':checked')) {
                                        ee.api.apiConsent(function () {
                                            screen.apikeycontainer.html(`<div data-copy_target="${ee.session.apikey()}">${ee.session.apikey()}</div>`);
                                            screen.apikeyclicktocopycontainer.html(`<button class="esettingsclicktocopy" data-title="${ee.t.copy}" data-copy="${ee.session.apikey()}" id="eapikeyclicktocopy"><i class="fa fa-copy"></i></button>`);
                                        });
                                        html.modal.closeAll();
                                    }
                                    else
                                        html.modal.info(ee.t.apinotconsentedinfo);
                                }
                            }];

                        //Display modal
                        let modal = html.modal.create(showapikey, {
                            width: "450px", title: ee.t.confirm, buttons: buttons
                        });

                    });
                }
                if (screen.showdiagnostics) screen.showdiagnostics.on("click", function () { ee.goTo("#/settings/diagnostics"); });

                
                ee.view.screen.find(".erdnsconfigurationinfo").on("click", function () {
                    let data = {};
                    data.ipaddress = $(this).data("ipaddress")
                    let rdnsInfo = html.get("rDNSConfigurationInfo", data),
                        modal = html.modal.create(rdnsInfo, {
                            title: "Custom rDNS Configuration", cancel: { title: ee.t.close }
                        });
                });

                if (ee.view.screen.addip) {
                    ee.view.screen.addip.on("click", function () {
                        ee.views.account._purchaseIP();
                    });
                    if (ee.data.account.mailerid > 0) {
                        ee.view.screen.addip.text(ee.t.requestadditionalprivateip);
                    }
                }

                //IP POOLS

                if (enableipmanagment) {
                    let ips = ee.data.account.ipaddresses;
                    __showIPadressesWithPools()

                    screen.ipaddresslist.on('change', '.eselect', function () {
                        let $this = $(this),
                            ip = $this.data('ip'),
                            pool = $this.val();
                        if (pool === 'Default') pool = pool.toLowerCase();
                        ee.api.request('/pool/moveip', { ips: ip, namenew: pool }, function (data) {
                            ee.indiOff();
                            if (!data.success) {
                                let oldVal = ips.get('ipaddress', ip).poolname;
                                $this.val(oldVal);
                                html.modal.error(data);
                            }
                            ips.get('ipaddress', ip).poolname = pool;
                            ee.api.loadPools(function () {
                                poolsCollection.update(ee.data.account.pools.data);
                                __showIPadressesWithPools();
                            });
                        })
                    })

                    function __showIPadressesWithPools() {
                        poolsCollection.each(function () {
                            let poolip = this.ip,
                                leng = poolip.length,
                                i;
                            for (i = 0; i < leng; i++) {
                                try {
                                    ips.get('ipaddress', poolip[i]).poolname = this.name;
                                } catch (e) { if (window.Bugsnag) Bugsnag.notify('PoolsError', "Cant find " + poolip[i] + "from pools in ipadresses list.") }
                            }
                        });
                        let poollist = poolsCollection.filter(['name'], 'Default', true, true).getAll();
                        ipadresses = html.get('IPadressesResult', { showPools: true, displayipdetails: ee.data.account.displayipdetails, list: ee.data.account.ipaddresses.getAll(), poollist: poollist, poollength: poollist.length });
                        screen.ipaddresslist.empty();
                        ipadresses.appendTo(screen.ipaddresslist);
                        screen.ipaddresslist.find('.eselect').each(function () {
                            let $this = $(this);
                            $this.val(_.toLower(_.toString($this.data('pool'))));
                        });
                    };

                    screen.managepools.on('click', function () {
                        let $content = html.get('PoolManage', {});

                        new html.Frameoverlay($content).on({
                            beforeClose: function () {
                                ee.api.loadPools(function () {
                                    poolsCollection.update(ee.data.account.pools.data);
                                    __showIPadressesWithPools();
                                });
                            },
                            beforeShow: function () {
                                __getTableResult();
                                //EVENTS
                                $content.createpool.on('click', function () {
                                    let $cont = html.get('PREMIUMcreatePool', {}),
                                        modal = html.modal.create($cont, {
                                            title: 'Create Pool', buttons: [{
                                                name: 'Create',
                                                title: ee.t.ttsavedomain,
                                                css: 'btn_primary btn_lg',
                                                callback: function () {
                                                    let obj = {
                                                        name: $cont.find('#einputname').val(),
                                                        description: $cont.find('#einputdescription').val(),
                                                        ip: [],
                                                        ipsObj: []
                                                    }
                                                    if (obj.name && obj.name.toLowerCase() === "default") return html.modal.info('Please choose different name!');
                                                    ee.api.request('/pool/add', { name: obj.name, description: obj.description }, function (data) {
                                                        ee.indiOff();
                                                        if (!data.success) {
                                                            html.modal.error(data);
                                                            return;
                                                        }
                                                        poolsCollection.add(obj);
                                                        __getTableResult();
                                                        modal.close();
                                                    });
                                                }
                                            }]
                                        });
                                });

                                $content.ippoolslist.on('click', '.edelete', function () {
                                    let $this = $(this),
                                        name = $this.data('name');

                                    html.modal.confirm('Are you sure?', 'Delete "' + name + '" pool?', function () {
                                        EE_API.Pool.Delete({ name: name }).then(function () {
                                            let newData = poolsCollection.filter(['name'], name, true, true);
                                            poolsCollection.update(newData.getAll());
                                            __getTableResult();
                                        })
                                    })
                                });

                                $content.ippoolslist.on('click', '.eedit', function () {
                                    let pool = poolsCollection.get('name', $(this).data('name'));
                                    let $cont = html.get('PREMIUMcreatePool', pool);
                                    let oldName = pool.name;
                                    let modal = html.modal.create($cont, {
                                        title: 'Edit "' + oldName + '"', buttons: [
                                            {
                                                name: 'Save',
                                                title: 'Save Edited Pool',
                                                css: 'btn_primary btn_lg',
                                                callback: function () {
                                                    let name = $cont.find('#einputname').val();
                                                    let description = $cont.find('#einputdescription').val();

                                                    ee.api.request('/pool/update', { newName: name, description: description, oldName: oldName }, function (data) {
                                                        ee.indiOff();
                                                        if (!data.success) {
                                                            html.modal.error(data);
                                                            return;
                                                        }

                                                        pool.name = name;
                                                        pool.description = description;
                                                        __getTableResult();
                                                        modal.close();
                                                    })
                                                }
                                            }
                                        ]
                                    });
                                });

                                //PRIVATES
                                function __getTableResult() {
                                    $content.ippoolslist.empty();
                                    let result = html.get('PoolManageResult', { pools: poolsCollection.filter(['name'], "Default", true, true).getAll() });
                                    result.appendTo($content.ippoolslist);
                                }
                            }
                        });
                    });
                } else {
                    if (screen.ipaddresslist) {
                        ipadresses = html.get('IPadressesResult', { displayipdetails: ee.data.account.displayipdetails, list: (ee.data.account.ipaddresses) ? ee.data.account.ipaddresses.getAll() : [] });
                        screen.ipaddresslist.empty();
                        ipadresses.appendTo(screen.ipaddresslist);
                    }
                }

                // }
                //Render Domain
                //ee.view.setupDomain();
                //Advanced Options
                ee.view.advancedOptions();


                //manage pages visibility after load/reload
                //settingsTab = ee.data.settingsTab;//(window.sessionStorage) ? window.sessionStorage.settingsTab : ee.data.settingsTab;

                if (settingsTab != undefined) {
                    ee.view.screen.settingstabs.find('a[href="#' + settingsTab + '"]').trigger('click');
                }
                ee.view.manageSettingsTabs(settingsTab);
                ee.view.screen.settingstabs.find('li a').off().on("click", function () {

                    let target = $(this).attr('href').substring(1);
                    ee.view.manageSettingsTabs(target);
                });
                ee.view.screen.settingstabs.on('click', 'li', function (e) {
                    if ($(e.target).data('toggle') !== 'tab') $(this).find('[data-toggle="tab"]').click();
                });
                if (html.clickToCopy.test()) {
                    html.clickToCopy.init();
                }

                //select language from dropdown
                $('#elanguageselect').val(ee.session.get('locale'));

            },
            manageSettingsTabs: function (settingsTab) {

                try {
                    window.sessionStorage.settingsTab = settingsTab;
                } catch(err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                    ee.data.settingsTab = settingsTab;
                }

                let buttons = ee.view.screen.settingsbuttons;
                let enableipmanagment = ee.data.account.enableipmanagment;
                buttons.find('button').hide();
                ee.view.screen.searchdomain.hide();
                if (settingsTab == undefined) settingsTab = 'esendingtab';
                if (buttons.find('#eenablecontactfeatures') != undefined) {
                    buttons.find('#eenablecontactfeatures').hide();
                }
                if (buttons.find('#erequestpremiumsupport') != undefined) {
                    buttons.find('#erequestpremiumsupport').show();
                }
                if (settingsTab == 'esendingtab') {
                    buttons.find('#esaveoptions').show();
                    window.location.hash = '/settings/sending';
                }
                if (settingsTab == 'eunsubscribetab') {
                    buttons.find('#esaveoptions').show();
                    buttons.find('#epreviewunsubscribe').show();
                    window.location.hash = '/settings/unsubscribe';
                }
                if (settingsTab == 'edomainstab') {
                    ee.view.setupDomain();
                    window.location.hash = '/settings/domains';
                }
                if (settingsTab == 'eprivateipstab') {
                    buttons.find('#eaddip').show();
                    if (enableipmanagment) {
                        buttons.find('#emanagepools').show();
                    }
                    window.location.hash = '/settings/privateips';
                }
                if (settingsTab == 'enotificationstab') {
                    buttons.find('#esaveoptions').show();
                    window.location.hash = '/settings/notifications';
                }
                if (settingsTab == 'esmtpconfigurationtab') {
                    buttons.find('#eshowdiagnostics').show();
                    ee.view.access("smtp", ee.data.account.apiconsented);
                    window.location.hash = '/settings/smtp';
                }
                if (settingsTab == 'eipmanagment') {
                    window.location.hash = '/settings/ipmanagment';
                }
                if (settingsTab == 'eaccountconfigurationtab') {
                    buttons.find('#esaveoptions').show();
                    window.location.hash = '/settings/accountconfiguration';
                }
                if (settingsTab == 'eapikeyconfigurationtab') {
                    ee.view.access("apikey", ee.data.account.apiconsented);
                    window.location.hash = '/settings/apikey';
                }
                if (!ee.session.can.ModifySettings) {
                    ee.view.screen.settingsscreen.find(".tab-pane input, .tab-pane select").attr("disabled", true);
                }
                if (that.formmodified) {
                    buttons.find('#esaveoptions').removeClass("disabled");
                }
            },
            notificationsScreen: function () {
                let webhooksNumber = ee.data.account.weboptions.length;
                let currentWebhook = 1;

                //Setting active on first webhook
                $('.webhook-name').first().addClass('active-hook');
                $('#webhook-1').addClass('active');


                //Show only first 3 webhooks
                for (let i = 3; i < webhooksNumber; i++) {
                    $('.webhook-name').eq(i).css('display', 'none');
                }

                //Visibility of arrows
                if(webhooksNumber > 1) {
                    $('.change-webhook').css('display', 'inline-block');
                    $('#enexthook').removeAttr('disabled');
                }

                //Changing dynamic attributes for each copy of webhook
                for (let i = 1; i <= webhooksNumber; i++) {
                    $('.webhook-name').eq(i - 1).attr('data-hooknumber', i);
                    $('.webhook-name').eq(i - 1).attr('href', '#webhook-' + i);
                    $('div[data-name="webhook-form"]').eq(i - 1).attr('id','webhook-' + i);
                    $('.notification-options').eq(i).find('input').attr('id', function(){
                        return $(this).attr('id') + '-' + (i + 1);
                    });
                    $('.notification-options').eq(i).find('label').attr('for', function(){
                        return $(this).attr('for') + '-' + (i + 1);
                    });
                }

                if($(document).find('input[name="WebNotificationUrl"]').val() === '') {
                    $('#eaddwebhook').attr('disabled', true);
                }

                //Notification Link validation
                $(document).on('keyup', 'input[name="WebNotificationUrl"]', function() {
                    let regex =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
                    _.forEach($('input[name="WebNotificationUrl"]'), el => {
                        if($(el).val().match(regex) === null) {
                            $(this).css('border-color', 'red');
                            $('#esaveoptions').attr('disabled', true);
                            $('#eaddwebhook').attr('disabled', true);
                            $(this).next().removeClass('hidden');
                        } else {
                            $(this).css('border-color', '#D0D0D0');
                            $('#esaveoptions').attr('disabled', false);
                            $('#eaddwebhook').attr('disabled', false);
                            $(this).next().addClass('hidden');
                        }
                    });
                });

                let updateBasedOnWebhooksState = function() {
                    //Visibility of arrows
                    if(webhooksNumber > 1) {
                        $('.change-webhook').css('display', 'inline-block');
                    } else {
                        $('.change-webhook').css('display', 'none');
                    }

                    //Arrows state
                    if (currentWebhook === 1) {
                        $('#eprevioushook').attr('disabled', true);
                    } else {
                        $('#eprevioushook').removeAttr('disabled');
                    }
                    if (currentWebhook === $('.webhook-name').length) {
                        $('#enexthook').attr('disabled', true);
                    } else {
                        $('#enexthook').removeAttr('disabled', false);
                    }
                }

                //Mouse click
                $(document).on('click', '.webhook-name', function() {
                    $('.webhook-name').removeClass('active-hook');
                    $(this).addClass('active-hook');
                    currentWebhook = $('.webhook-name.active-hook').index() + 1;
                    updateBasedOnWebhooksState();
                });

                //Add new webhook
                addWebhookClicked = () => {
                        //New webhook label
                        $('.webhook-tabs')
                            .append("<a data-toggle='tab' data-hooknumber='" + (webhooksNumber + 1) + "' href='#webhook-" + (webhooksNumber + 1) + "' class='webhook-name'><input type='text' value='New Webhook' readonly><i class='fas fa-pencil-alt edit-webhook-name'</i></a>");
                        //Form for a new webhook + clear inputs
                        let $newTab = $('#webhook-1').clone();
                        $('#notifications-container')
                            .find('.tab-pane.active')
                            .removeClass('active');
                        $newTab
                            .attr('id', 'webhook-' + (webhooksNumber + 1))
                            .addClass('active');
                        $newTab
                            .find('input[type="checkbox"]')
                            .removeAttr('checked');
                        $newTab
                            .find('#NotificationURL')
                            .val('');
                        $newTab
                            .find('label')
                            .attr('for', function() {
                                return $(this).attr('for') + '-' + (webhooksNumber + 1);
                            })
                        $newTab
                            .find('input')
                            .attr('id', function() {
                                return $(this).attr('id') + '-' + (webhooksNumber + 1);
                            })
                        $('#notifications-container')
                            .append($newTab);
                        $('.webhook-name')
                            .removeClass('active-hook')
                            .last()
                            .addClass('active-hook');
                        $('.delete-webhook').css('display', 'inline-block');

                        webhooksNumber++;

                        //Webhook visibility
                        if($('.webhook-name').length > 3) {
                            let nameList = $('.webhook-name');
                            for (let i = 0; i < nameList.length - 3; i++) {
                                $('.webhook-name')
                                .eq(i)
                                .css('display', 'none');
                            }
                            for(let i = nameList.length - 3; i < nameList.length; i++) {
                                $('.webhook-name')
                                .eq(i)
                                .css('display', 'inline-block');
                            }
                        }
                        currentWebhook = $('.webhook-name').length;
                        updateBasedOnWebhooksState();
                }

                //Delete webhook
                $('.delete-webhook').off().on('click', function() {
                    html.modal.confirm('Are you sure you want to delete this webhook? This action cannot be undone.', 'Remove', function() {
                        let id = $('.webhook-name.active-hook').attr('data-id');
                        if (id) {
                            //Remove from DB and the screen
                            EE_API.Account.DeleteWebhook({webhookID: id})
                            .then(function(){
                                if (webhooksNumber > 1) {
                                    $('.webhook-name.active-hook').remove();
                                    $('.webhook-name:first').addClass('active-hook');
                                    $('.webhook-form.tab-pane.active').remove();
                                    $('.webhook-form:first').addClass('active');
                                    webhooksNumber--;
                                    currentWebhook = 1;
                                    for (let i = 0; i < 3; i++) {
                                        $('.webhook-name')
                                        .eq(i)
                                        .css('display', 'inline-block');
                                    }
                                    for (let i = 3; i < webhooksNumber; i++) {
                                        $('.webhook-name')
                                        .eq(i)
                                        .css('display', 'none');
                                    }
                                    updateBasedOnWebhooksState();
                                } else {
                                    $('#eHTTPWebNotifications').empty();
                                    ee.data.account.weboptions = [];
                                    notificationsForm = html.get('EmptyWebhookForm');
                                    $('#eHTTPWebNotifications').append(notificationsForm);
                                }
                            });
                        } else {
                            //Remove only from the screen
                            if (webhooksNumber > 1) {
                                $('.webhook-name.active-hook').remove();
                                $('.webhook-name:first').addClass('active-hook');
                                $('.webhook-form.tab-pane.active').remove();
                                $('.webhook-form:first').addClass('active');
                                webhooksNumber--;
                                currentWebhook = 1;
                                for (let i = 0; i < 3; i++) {
                                    $('.webhook-name')
                                    .eq(i)
                                    .css('display', 'inline-block');
                                }
                                for (let i = 3; i < webhooksNumber; i++) {
                                    $('.webhook-name')
                                    .eq(i)
                                    .css('display', 'none');
                                }
                                updateBasedOnWebhooksState();
                            } else {
                                $('#eHTTPWebNotifications').empty();
                                ee.data.account.weboptions = [];
                                notificationsForm = html.get('EmptyWebhookForm');
                                $('#eHTTPWebNotifications').append(notificationsForm);
                            }
                        }
                    });
                });


                //Edit webhook name
                $(document).on('click', '.edit-webhook-name', function() {
                    let $value = $(this).prev('input').val();
                    $(this)
                        .prev('input')
                        .attr('readonly', false)
                        .val('')
                        .val($value)          //Focus at the end of the input
                        .focus()
                        .on('focusout', function() {
                            $(this).attr('readonly', true);
                    });
                });

                //Previous hook
                $('#eprevioushook').off().on('click', function() {
                    $('.webhook-name.active-hook')
                        .removeClass('active-hook')
                        .prev()
                        .addClass('active-hook');
                    $('#notifications-container')
                        .find('.tab-pane.active')
                        .removeClass('active')
                        .prev()
                        .addClass('active');
                        currentWebhook = $('.webhook-name.active-hook').index() + 1;
                        if (webhooksNumber > 3 && $('.webhook-name.active-hook').css('display') === 'none') {
                            $('.webhook-name.active-hook').css('display', 'inline-block');
                            $('.webhook-name').eq(currentWebhook + 2).css('display', 'none');
                        }
                        updateBasedOnWebhooksState();
                });
                //Next hook
                $('#enexthook').off().on('click', function() {
                        $('.webhook-name.active-hook')
                            .removeClass('active-hook')
                            .next()
                            .addClass('active-hook');
                        $('#notifications-container')
                            .find('.tab-pane.active')
                            .removeClass('active')
                            .next()
                            .addClass('active');
                        currentWebhook =  $('.webhook-name.active-hook').index() + 1;
                        if ($('.webhook-name.active-hook').css('display') === 'none') {
                            $('.webhook-name.active-hook').css('display', 'inline-block');
                            $('.webhook-name').eq(currentWebhook - 4).css('display', 'none');
                        }
                        updateBasedOnWebhooksState();
                });
            },
            advancedOptions: function () {
                let screen = ee.view.screen, advancedoptions = ee.data.account.advancedoptions;
                screen.find(".etoggle").click(function () {
                    let button = $(this);
                    if (button.data('state') === "show") {
                        button.html(ee.t.hideadvanced).data('state', 'hide');
                    } else {
                        button.html(ee.t.showadvanced).data('state', 'show');
                    }
                    screen[button.data('toggle')].slideToggle('slow');
                });

                //Filemanger for LogoURL
                ee.view.screen.filemanager.click(function () {
                    html.widget.FileManager.init(function (url, listname) {
                        ee.view.screen.logoUrl.val(url);
                        $('#esaveoptions').removeClass("disabled");
                        $('#esaveoptions').off().on('click', saveAdvancedOptions);
                    }, "images");
                });

                $(document).on('keydown', "input[type='text'], textarea", function () {
                    $('#esaveoptions').removeClass("disabled");
                    $('#esaveoptions').off().on('click', saveAdvancedOptions);
                });
                $(document).on('change', "input[type='checkbox'], select, input[type=number]", function () {
                    if ($(this).hasClass('notsaveable')) return;
                    $('#esaveoptions').removeClass("disabled");
                    $('#esaveoptions').off().on('click', saveAdvancedOptions);
                });


                screen.ContentTransferEncoding.val(advancedoptions.contenttransferencoding);
                if (screen.nableStaleContactScoreValue) {
                    screen.nableStaleContactScoreValue.val(advancedoptions.stalecontactscore * -1);
                }
                if (screen.nableStaleContactDayValue) {
                    screen.nableStaleContactDayValue.val(advancedoptions.stalecontactinactivedays * 1);
                }
                let webNotificationOptions = function() {

                    let formArr = [];
                    for (let i = 1; i <= $('div[data-name="webhook-form"]').length; i++) {
                        let notifications = $(document).find('#webhook-' + i).elasticCollectForm();
                        notifications.id = $('.webhook-name').eq(i - 1).data('id');
                        notifications.name = $('.webhook-name').eq(i - 1).find('input').val();
                        if (notifications.id && notifications.WebNotificationUrl === ee.data.account.weboptions[i - 1].url) {
                            delete notifications.WebNotificationUrl;
                        }
                        formArr.push(notifications);
                    }
                    if(window.location.href.indexOf('notifications') > -1) {
                        formArr.forEach(function(el) {
                            if (!el.id) {
                                    EE_API.Account.AddWebhook({
                                    webNotificationUrl: el.WebNotificationUrl,
                                    notifyonceperemail: el.WebNotificationNotifyOncePerEmail,
                                    notificationforsent: el.WebNotificationForSent,
                                    notificationforopened: el.WebNotificationForOpened,
                                    notificationforclicked: el.WebNotificationForClicked,
                                    notificationforunsubscribed: el.WebNotificationForUnsubscribed,
                                    notificationforabusereport: el.WebNotificationForAbuseReport,
                                    notificationforerror: el.WebNotificationForError,
                                    name: el.name
                                })
                                .then(function() {
                                    EE_API.Account.LoadWebhook()
                                        .then(function(data){
                                            for(let i = 0; i < data.length; i++) {
                                                $('.webhook-name').eq(i).attr('data-id', data[i].webhookid);
                                            }
                                        })
                                })
                                .catch((err) => {
                                    return html.widget.Toast.info(err.error, null, {timeOut: 0})
                                })
                            } else {
                                EE_API.Account.UpdateWebhook({
                                    webhookID: el.id,
                                    webNotificationUrl: el.WebNotificationUrl,
                                    notifyonceperemail: el.WebNotificationNotifyOncePerEmail,
                                    notificationforsent: el.WebNotificationForSent,
                                    notificationforopened: el.WebNotificationForOpened,
                                    notificationforclicked: el.WebNotificationForClicked,
                                    notificationforunsubscribed: el.WebNotificationForUnsubscribed,
                                    notificationforabusereport: el.WebNotificationForAbuseReport,
                                    notificationforerror: el.WebNotificationForError,
                                    name: el.name
                                })
                                .catch((err) => {
                                    return html.widget.Toast.info(err.error, null, {timeOut: 0})
                                })
                            }
                        });
                    }
                }
                let inboundOptions = function() {
                    let screen = ee.view.screen;
                    let InboundNotifications = screen.InboundNotifications.elasticCollectForm();

                    return EE_API.Account.UpdateInboundNotifications({
                        inboundContactsOnly: InboundNotifications.InboundContactsOnly,
                        hubCallBackUrl: InboundNotifications.HubCallbackUrl,
                        inboundDomain: InboundNotifications.InboundDomain
                    })
                    .then(function(data) {
                        ee.data.account.inboundoptions = data;
                    })
                    .catch(function(err) {
                        html.modal.error(err);
                    });
                };
                let saveAdvancedOptions = function () {
                    let SendingOptions = screen.SendingOptions.elasticCollectForm(),
                        BounceOptions = screen.BounceOptions.elasticCollectForm(),
                        AccountNotifications = screen.AccountNotifications.elasticCollectForm();

                    $.extend(SendingOptions, screen.UnsubscribeOptions.elasticCollectForm());

                    if (ee.session.can.ModifyWebNotifications) {
                        webNotificationOptions();
                        inboundOptions();
                    }

                    const apipricingplan = ee.data.account.pricingplan === 5 || ee.data.account.pricingplan === 6 || ee.data.account.pricingplan === 9 || ee.data.account.pricingplan === 10

                    if (apipricingplan) {
                        SendingOptions.DisableStoreContact = !SendingOptions.DisableStoreContact;
                    } else {
                        SendingOptions.DisableStoreContact = false;
                    }

                    // as this options is hidden if account is not trusted we have to set it to true ,cause api cannot handle undefined
                    SendingOptions.EnableOpenTracking ??= true;
                    SendingOptions.EnableClickTracking ??= true;

                    ee.api.updateAdvancedOptions(SendingOptions, BounceOptions, AccountNotifications, function () {
                        if (screen.saveoptions) screen.saveoptions.addClass("disabled");
                        ee.data.account.enableuitooltips = false;
                        ee.data.account.enableopentracking = SendingOptions.EnableOpenTracking;
                        ee.data.account.enableclicktracking = SendingOptions.EnableClickTracking;
                        html.enableuitooltips = ee.data.account.enableuitooltips;
                        if (screen.enablecontactfeatures) {
                            if (AccountNotifications.EnableContactFeatures === false) {
                                screen.enablecontactfeatures.removeClass('hidden');
                            } else {
                                screen.enablecontactfeatures.addClass('hidden');
                            }
                        }
                    });

                    const disableStoreContactCheckbox = screen.DisableStoreContact && screen.DisableStoreContact.find('input').prop('checked');
                    const disableGlobalContactsCheckbox = screen.DisableGlobalContacts.find('input').prop('checked');

                    if (apipricingplan && !disableStoreContactCheckbox && disableGlobalContactsCheckbox){
                        screen.DisableGlobalContacts.find('input').prop('checked', false);
                    };
                }

                let addButtonHandler = () => {
                    $('#eaddwebhook').off().on('click', function() {
                        saveAdvancedOptions();
                        setTimeout(() => {
                            addWebhookClicked();
                            $('#eaddwebhook').attr('disabled', true);
                         }, 0)
                    });
                }

                $(document).on('click', '#addWebhook', () => {
                    $('#eHTTPWebNotifications').empty();
                    if(!ee.data.account.weboptions.length) {
                        ee.data.account.weboptions.push({
                            name: 'New Webhook'
                        });
                    }
                    notificationsForm = html.get('WebhookForm', {
                        weboptions: ee.data.account.weboptions
                    });
                    $('#eHTTPWebNotifications').append(notificationsForm);
                    ee.view.notificationsScreen();
                    addButtonHandler();
                });

                if(ee.Router.action() === 'notifications') {
                    $("#enotificationstab .eproccessing").show();
                    EE_API.Account.LoadWebhook()
                    .then(function(data) {
                        ee.data.account.weboptions = data;
                        let notificationsForm = html.get(ee.data.account.weboptions.length ? 'WebhookForm' : 'EmptyWebhookForm', {
                            weboptions: ee.data.account.weboptions
                        });
                        $("#enotificationstab .eproccessing").hide();
                        $("#eInboundNotifications").show();
                        notificationsForm.appendTo('#eHTTPWebNotifications');
                        ee.view.notificationsScreen();
                        addButtonHandler();
                    })
                    .catch(function(err) {
                        html.modal.error(err);
                    });
                }

                if(ee.Router.action() !== 'notifications') {
                    $('#eaddwebhook').bind('click');
                }

                if(ee.Router.action() === 'notifications') {
                    $('#eaddwebhook').bind('click');
                }

                $('#elanguageselect').on('change', function() {
                    $('#changelanguage').attr('disabled', false);
                });
                $('#changelanguage').on('click', function() {
                    ee.languages.load($('#elanguageselect').val(), function () {
                        ee.tools.recognizeRTLLanguage();
                        ee.showFrame(); });
                });
                screen.previewunsubscribe.on("click", function () {
                    ee.view.showPreviewUnsubscribe(ee.api.path + "/tracking/unsubscribe?msgid=" + advancedoptions.previewmessageid);
                });

                if (advancedoptions.emailnotificationforerror)
                    screen.bouncetospecific.show();
                if (advancedoptions.notificationsemails)
                    screen.notificationbcc.show();
                if (advancedoptions.managesubscriptions)
                    screen.managesubscribedonly.show();
                if (advancedoptions.unsubscribenotificationemails && ee.data.account.enablecontactfeatures)
                    screen.unsubemails.show();

                //Confirm modal when user try to turn off contact delivery tools
                if (screen.nablecontactfeatures) {
                    screen.nablecontactfeatures.on('click', function () {
                        let $this = $(this);
                        if (!$this.prop('checked')) {
                            return html.modal.confirm(ee.t.alertadvancedtoolsoff, undefined, undefined, function () {
                                $this.prop('checked', true);
                            });
                        }
                    });
                }
                screen.BounceOptions.find('#emailnotificationforerrorcheckbox').on("click", function () {
                    $('#ebouncetospecific').toggle();
                    new html.Validator(screen, {
                        submit: "#esaveoptions",
                        inputs: [
                            {
                                name: "EmailNotificationEmail",
                                css: { success: "has-success", error: "has-error" },
                                msg: ee.t.erroremail,
                                messageSelector: "#bouncereturnspecific",
                                validFunc: function () {
                                    if ($('#emailnotificationforerrorcheckbox:checked').length) {
                                        if (this.val() !== "") {
                                            return ee.tools.isValidEmail(this.val().trim());
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        ]
                    });
                });
                screen.unsubscribetab.find('#managesubscriptionscheckbox').on("click", function () { $('#emanagesubscribedonly').toggle(); });
                screen.AccountNotifications.find('#EnableNotifiCationCopy').on("click", function () { $('#enotificationbcc').toggle(); $('#EmailNotificationBCC').val(""); });
                if (ee.data.account.enablecontactfeatures) screen.unsubscribetab.find('#eenableunsubscribenotificationsemails').on("click", function () { $('#eunsubemails').toggle(); $('#eunsubemailslist').val(""); });

                if (screen.DisableStoreContact) {
                    screen.DisableStoreContact.find('#DisableStoreContactcheckbox').on("click", function () {
                        $('#eDisableGlobalContacts').toggle();
                    });
                    screen.DisableStoreContact.off().on('change', 'input', function (e) {
                        e.preventDefault();
                        let checkbox = (screen.DisableStoreContact.find('input').prop('checked'));

                        screen.DisableStoreContact.find('input').prop('checked', checkbox);
                    })
                };

                if (screen.DisableStoreContact && advancedoptions.disablestorecontact) {
                    screen.DisableGlobalContacts.hide();
                }

                if (screen.DisableGlobalContacts) {
                    screen.DisableGlobalContacts.off().on('change', 'input', function (e) {
                        e.preventDefault();
                        let checkbox = (screen.DisableGlobalContacts.find('input').prop('checked'));

                        let DisableGlobalContactsModal = html.modal.confirm(ee.t.disableglobalcontactsmodaldesc, ee.t.disableglobalcontactsmodal, function () {
                            screen.DisableGlobalContacts.find('input').prop('checked', checkbox);
                            EE_API.Account.SetDisableGlobalContacts({ disableGlobalContacts: !checkbox }) //property is called "disable, so we're working with negation"

                        }, function () {
                            screen.DisableGlobalContacts.find('input').prop('checked', !checkbox);
                        }, { size: "emodal-lg" })
                    })
                };
            },
            showPreviewUnsubscribe: function (path) {
                let data = {};
                data.path = path;

                let win = html.get("PreviewUnsubscribe", data);

                let modal = html.modal.create(win, {
                    title: ee.t.previewunsubscribe,
                    size: "emodal-lg",
                    alignToScreenHeight: true,
                    onShow: function ($modal) {
                        let $content = $modal.$content;
                        $('#epreviewunsubscribecontent iframe').height($content.height() - 2);
                    },
                    cancel: { title: ee.t.close }
                });
                ee.indiOn();
                $('#epreviewunsubscribecontent iframe').on('load', function () { ee.indiOff(); })
            },
            diagnostics: function () {
                if (!ee.data.diagnostics) {
                    ee.api.listDiagnostics(0, 300, ee.view.diagnostics, "dd/mm/yy<br/>HH:ii:ss");
                    return;
                }
                if (!ee.data.templates) {
                    ee.api.loadTemplates(ee.view.diagnostics);
                    return;
                }
                ee.frame.content.empty();
                //Load diagnostics ticket list
                let diagnostics = html.get("Diagnostics", ee.data);
                diagnostics.appendTo(ee.frame.content);
                ee.view.screen = diagnostics;
                //Set the data to display in a table
                let pagination = new html.Pagination(ee.data.diagnostics.getAll(), {
                    template: 'DiagnosticsTable',
                    resultcontainer: diagnostics.paginationresult,
                    footercontainer: diagnostics.find('.paginationlist'),
                    perpage: 25,
                    paginationinfo: false,
                    firstlast: false,
                });

                diagnostics.newticket.off().on("click", function () { ee.api.getDiagnosticTicket(ee.view.diagnosticsTicketGenerate); });
                diagnostics.paginationresult.on("click", '.click', function () {
                    ee.goTo("#/settings/diagnostics/details/" + $(this).data("ticketid"));
                });
                diagnostics.refresh.off().click(function () {
                    delete ee.data.diagnostics;
                    ee.view.diagnostics();
                });
            },
            diagnosticsDetails: function (ticketID) {
                if (!ee.data.diagnosticsdetails) {
                    ee.api.loadDiagnosticsDetails(ticketID, ee.view.diagnosticsDetails);
                    return;
                }
                let diagDetails = html.get("DiagnosticsDetails", ee.data.diagnosticsdetails);
                diagDetails.appendTo(ee.frame.content);
                diagDetails.cancel.on("click", function () { ee.goTo("#/settings/diagnostics"); });
                diagDetails.statusmenu.find(".efilter-box").on("click", function () {
                    diagDetails.statusmenu.find(".esel").removeClass("esel");
                    let li = $(this);
                    li.addClass("esel");
                    let type = li.data("details");
                    ee.view.diagnosticsDetailsContent(diagDetails, type);
                });
                diagDetails.statusmenu.find(".efilter-box:first").trigger("click");
            },
            diagnosticsDetailsContent: function (diagdetails, typedetails) {
                diagdetails.detailscontent.empty();
                ee.data.diagnosticsdetails.activetab = typedetails;
                switch (typedetails) {
                    case 0:
                        html.get("DiagnosticsTransaction", ee.data.diagnosticsdetails.transaction).appendTo(diagdetails.detailscontent);
                        break;
                    case 1:
                        html.get("DiagnosticsMoreDetails", ee.data.diagnosticsdetails.details).appendTo(diagdetails.detailscontent);
                        break;
                    case 2:
                        html.get("DiagnosticsMimeMessage", ee.data.diagnosticsdetails).appendTo(diagdetails.detailscontent);
                        break;
                    case 3:
                        html.get("DiagnosticsHistory", ee.data.diagnosticsdetails).appendTo(diagdetails.detailscontent);
                        break;
                    case 4:
                        html.get("DiagnosticsDNSLog", ee.data.diagnosticsdetails.dnslog).appendTo(diagdetails.detailscontent);
                        break;
                    case 5:
                        html.get("DiagnosticsOutboundSmtp", ee.data.diagnosticsdetails.outboundsmtp).appendTo(diagdetails.detailscontent);
                        break;
                }
            },
            diagnosticsTicketGenerate: function () {
                ee.data.diagnosticsticket.admin = ee.session.can.AdminView;
                ee.data.diagnosticsticket.templates = ee.data.templates.getAll();
                let diagTicket = html.get("DiagnosticsTicket", ee.data.diagnosticsticket),
                    saveTicket = function () {
                        let ip = "", toemail = "", template = "", send = false;
                        if (diagTicket.diagnosticssend.is(":checked")) {
                            if (ee.data.diagnosticsticket.admin) {
                                ip = diagTicket.diagnosticsip.val();
                            }
                            send = true;
                            toemail = diagTicket.diagnosticstoemail.val();
                            template = diagTicket.diagnosticstemplate.val();
                            if (!template) {
                                html.modal.info("Please selected the template", ee.t.error);
                                return;
                            }
                            if (ee.tools.isValidEmail(toemail) === false) {
                                html.modal.info("<b>" + ee.t.admintoemail + "</b>: " + ee.t.invalidemailaddress, ee.t.error);
                                return;
                            }
                        }
                        ee.api.addDiagnosticTicket(diagTicket.diagnosticsemail.val(), ip, send, toemail, template);
                        modal.close();
                    },
                    modal = html.modal.create(diagTicket, { title: ee.t.diagnosticticket, buttons: [{ css: "btn-info", name: ee.t.save, callback: saveTicket }] });

                diagTicket.diagnosticssend.change(function () {
                    diagTicket.diagopt.toggle();
                });


            },
            setupDomain: function () {
                //New Domains
                if (ee.data.account.newdomainscreen) {
                    ee.view.screen.domainstab.empty();
                    html.widget.DomainUI.init().then(function ($container) {
                        ee.view.screen.domainstab.append($container)
                    });
                    return;
                }
                //Old domains
                let pagination, domains = dataModel.create('Domains'),
                    $setupdomain = html.get("SetupDomain", { issub: ee.data.account.issub }),
                    TimeoutIsCleared,
                    $screen = ee.view.screen,
                    updateDomainsList = function (reload) {
                        ((reload === true) ? domains.reload() : domains.require()).then(function () {
                            if (!$setupdomain.is(":visible")) return;
                            let search = $screen.searchinputcampaign.val(),
                                data = (search !== '') ? domains.filter(['domain'], search).getAll() : domains.getAll();
                            pagination.updateData(data);
                        }).always(function () { ee.indiOff(); });
                    };
                $screen.setupdomain.empty();
                $screen.searchinputcampaign.val('');
                if (ee.session.can.ModifySettings)
                    $screen.adddomain.show();
                $screen.searchdomain.removeClass('search__active').show();
                pagination = new html.Pagination([], {
                    template: 'SetupDomainTable',
                    resultcontainer: $setupdomain.paginationresult,
                    footercontainer: $setupdomain.find('.paginationlist'),
                    perpage: 25,
                    paginationinfo: false,
                    firstlast: false,
                    param: {}
                });

                //First load domains
                domains.require().then(function (data) {
                    if (!$setupdomain.is(":visible")) return;
                    pagination.updateData(data.getAll());
                });



                //Info about not valid domain
                if (ee.data.account.actualdailysendlimit == -500 && !ee.data.account.istrialbillingplan) {
                    ee.data.account.urldomainsetup = (ee.data.account.supportlink != undefined ? ee.data.account.supportlink : "https://help.elasticemail.com/en/articles/2281323-how-to-verify-your-domain");
                    $screen.setupdomain.append(html.get("StartAlertWarning", { msg: ee.t.validdomainwarning, url: ee.data.account.urldomainsetup }));
                }
                //Table select focus
                html.addDropdownFocus($setupdomain.senderdomainlist);
                //Search
                html.bindSearch($screen.searchdomain, function (search) {
                    let searchdata = ee.data.domains;
                    if (search !== '') {
                        searchdata = searchdata.filter(['domain'], search);
                    };
                    pagination.setPage(1);
                    pagination.updateData(searchdata.getAll());
                });
                //Question mark events
                $setupdomain.on('click', '[data-help]', function () {
                    let $content = html.get("Help" + $(this).data('help'), {}),
                        modal = html.modal.create($content, { width: "1040px", maxToScreenHeight: true, cancel: { title: ee.t.close, css: "" }, css: 'emodal-helpcontent' });
                    html.clickToCopy.init($content, true);
                    $content.on('click', '[data-toggle=collapse]', function () {
                        let $this = $(this), collapsein = ($this.hasClass('chevron-down')) ? true : false;
                        $content.find('.collapse.in').collapse('hide');
                        $content.find('.chevron-down').removeClass('chevron-down');
                        $content.find($this.data('target')).collapse('toggle');
                        if (collapsein === false) { $this.addClass("chevron-down"); }
                    });
                });
                //Add domains
                if ($screen.adddomain) {
                    $screen.adddomain.click(function () {
                        let addDomain = html.get("AddDomain", {reseller: ee.isReseller()}), modal = html.modal.create(addDomain, {
                            title: ee.t.adddomain, buttons: [{
                                name: ee.t.add,
                                title: ee.t.ttsavedomain,
                                css: 'btn_primary btn_lg',
                                callback: function () {
                                    let domain = addDomain.name.val().trim();
                                    let trackingType = addDomain.httpstracking.val().trim();


                                    if (ee.tools.isValidDomain(domain) === false) {
                                        html.modal.info(ee.t.wrongdomain, ee.t.error);
                                        return;
                                    }

                                    if (!_.includes(domain,'www.')) {
                                        ee.indiOn();
                                        let adddomain = domains.add(domain, trackingType);
                                        return adddomain
                                        .then(function () {
                                            modal.close();
                                            updateDomainsList(true);
                                        });
                                    }

                                    let domainwithoutwww = domain.substring('www.'.length);
                                        ee.indiOn();
                                        let adddomainwithoutwww = domains.add(domainwithoutwww, trackingType);
                                        return adddomainwithoutwww
                                        .then(function () {
                                            modal.close();
                                            updateDomainsList(true);
                                        });

                                },
                            }]
                        });
                    });
                }
                //Delete domain
                $setupdomain.on('click', "[data-id='removedomain']", function () {
                    let val = $(this).attr('data-domain');
                    let isDefault = ee.data.domains.get("domain", val)['defaultdomain'];
                    let msg = (isDefault) ? ee.t.deletedefaultdomain.replace(/{domain}/, val) : ee.t.deletedomain.replace(/{domain}/, val);
                    html.modal.confirm(msg, ee.t.removedomain, function () {
                        ee.indiOn();
                        if (ee.data.account.admin && ee.session.can.AdminModify) {
                            $.when(domains.delete(val, ee.data.account.publicaccountid)).then(updateDomainsList);
                        } else {
                            $.when(domains.delete(val)).then(updateDomainsList(true));
                        }
                    }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_destructive btn_lg" });
                });
                //Verify Action
                $setupdomain.on('click', "[data-id='verify']", function () {
                    let domain = domains.get('domain', $(this).data('domain')),
                        validateDomains = html.get("ValidateDomain", {}),
                        modal = html.modal.create(validateDomains, {
                            title: ee.t.domainvalidation,
                            maxToScreenHeight: true,
                            onClose: function () { updateDomainsList(true); },
                            cancel: { title: ee.t.close }
                        });
                    let spfErrors = [];
                    let spfValid;
                    let putInfo = function (element) {
                        let data = { ico: 'fa-check-circle', color: 'green', text: ee.t.valid };
                        if (element === 'spf' && domain[element] == false && !spfValid) {
                            data = { ico: 'fa-times-circle ', color: 'red', text: spfErrors };
                        }
                        if (domain[element] == false && element !== 'spf') {
                            data = { ico: 'fa-times-circle ', color: 'red', text: ee.t.errornotfoundcorrectdns };
                        }
                        validateDomains[element].find('.content:first').html('<div class="pre"><i class="fa ' + data.ico + ' on-left" style="color:' + data.color + ';" aria-hidden="true"></i> ' + data.text + '</div>');
                    };
                    EE_API.Domain.VerifySpf(domain)
                    .then(function(data){
                        spfValid = data.isvalid;
                        _.forEach(data.errors, function(el) {
                            if (!spfErrors.includes(el.error)){
                                spfErrors.push(el.error);
                            }
                        });
                        spfErrors = _.join(spfErrors, '<br /><i class="fa fa-times-circle on-left" style="color: red" aria-hidden="true"></i> ');
                    })
                    .then(function(){
                        putInfo("spf");
                    });
                    domains.verifyDKIM(domain).then(function () { putInfo("dkim"); });
                    domains.verifyTracking(domain).then(function () { putInfo("isrewritedomainvalid"); });
                    domains.verifyMX(domain).then(function () { putInfo("mx"); });
                    domains.verifyDMARC(domain).then(function () { putInfo("dmarc"); });
                });
                //Set as Default
                $setupdomain.on('click', "[data-id='setasdefault']", function () {
                    let $this = $(this), domain = $this.data('domain'), sender = $this.data('sender'),
                        defaultDomain = html.get("DefaultDomain", { sender: (sender) ? sender : 'mailer@' + domain }),
                        modal = html.modal.create(defaultDomain, {
                            title: (sender) ? ee.t.editdefaultsender : ee.t.setdefaultdomain, buttons: [{
                                name: ee.t.save,
                                title: ee.t.setdefaultdomain,
                                css: 'btn_lg btn_primary lastofright',
                                callback: function () {
                                    ee.indiOn();
                                    modal.close();
                                    $.when(domains.setDefault(defaultDomain.defaultemail.val())).then(function () {
                                        updateDomainsList(true);
                                    });
                                }
                            },
                            { name: ee.t.cancel, css: 'btn-link', callback: function () { modal.close(); } }]
                        });
                    //temporary
                    modal.$container.modalCancel.hide();

                });
                //-- START Admin methods
                $setupdomain.on('click', '[data-id="edittrackingtype"]', function () {
                    let $this = $(this), modal,
                        tracking = $this.data('tracktype'),
                        domain = $this.data('domain'),
                        $content = html.get("SetHttps", {});
                    $content.httpstracking.val(String(tracking));
                    modal = html.modal.create($content, {
                        title: "Set HTTPS",
                        buttons: [
                            {
                                name: ee.t.save,
                                css: 'btn_primary btn_lg',
                                callback: function () {
                                    $.when(domains.adminSetTrackingType(domain, $content.httpstracking.val())).then(function () {
                                        ee.indiOn();
                                        modal.close();
                                        updateDomainsList(true);
                                    }).always(function () { ee.indiOff(); });
                                }
                            }
                        ]
                    });
                });
                //Edit Tracking only for admin
                $setupdomain.on('click', "[data-id='edittracking']", function () {
                    let domain = $(this).attr('data-domain');
                    $.when(domains.adminGetTrackingDomain(domain, ee.data.account.publicaccountid)).then(function (data) {
                        let trackingSetup = html.get("TrackingSetup", { trackingdomain: data.trackingdomain }),
                            modal = html.modal.create(trackingSetup, {
                                title: "Edit Tracking", buttons: [{
                                    name: ee.t.save,
                                    css: 'btn_primary btn_lg',
                                    callback: function () {
                                        ee.indiOn();
                                        $.when(domains.adminSaveTrackingDomain(trackingSetup.defaulttracking.val(), domain, ee.data.account.publicaccountid)).then(function () {
                                            ee.indiOn();
                                            modal.close();
                                        }).always(function () { ee.indiOff(); });
                                    }
                                }]
                            });
                    });
                });
                //-- END Admin methods
                $screen.setupdomain.append($setupdomain);
            },
            smtpSettings: () => {
                let $smtpscreen = $('#esmtpconfigurationtab');
                $smtpscreen.find('.btn_primary').on('click', () => {
                    ee.goTo("#/settings/smtp/add")
                });

                if (!ee.data.account.apiconsented) {
                    $('.apiconsent').on('click', () => {
                        consentAPI($smtpscreen)
                    })
                } else {
                    html.widget.apikeys.show("smtp");
                    $smtpscreen.find('.editaccess').on('click', (e) => {
                        ee.goTo("#/settings/smtp/edit/" + e.target.dataset.name)
                    });
                }
            },
            consentAPI: function (target) {

                const consent = $(target).find('.apiconsent')[0];
                const createaccess = $(target).find('.createaccess')[0];

                $(createaccess).prop('disabled', !$(consent).prop('checked'))
                $(createaccess).unbind();
                $(createaccess).on('click', () => {
                    ee.indiOn();
                    return EE_API.Account.SetAPIConsent()
                    .then(() => {
                        ee.indiOff();
                        ee.data.account.apiconsented = true;
                        return EE_API.Account.Load();
                    })
                    .then(() => {
                        ee.goTo(location.hash)
                    })
                })
            },
            access: function (type, consent) {
                let $screen = $(`#e${type}configurationtab`);
                $screen.find('.btn_primary').on('click', () => {
                    ee.goTo(`#/settings/${type}/add`)
                });
                $screen.find('.eeditapikey').on('click', (e) => {
                    ee.goTo(`#/settings/${type}/edit/${e.target.dataset.name}`)
                });
                if (!consent) {
                    $('.consent').unbind();
                    $screen.find('.consent').each(function() {
                        $(this).on('click', () => {
                            ee.view.consentAPI($screen)
                        })
                    })
                } else {
                    html.widget.apikeys.show(type);
                }
            },
        }
    return that;
}());
