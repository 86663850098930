(function SelectFromEmail(root) {
    "use strict";
    var _validEmails = false,
        _verpFromEmail = function ($input, selectedemail, options) {
            var fromEmailInputName = $input.attr('name'),
              //  selectedemail = data[fromEmailInputName],
                $select = html.get('SelectFromEmail', {
                    tabindex: $input.attr('tabindex'),
                    fromEmailInputName: fromEmailInputName,
                    fromemails: _validEmails.filter(['validated'], 'true').getAll(),
                    strictmode: options.strictmode,
                    fromemail: selectedemail
                }),
                $ul = $select.find('ul:first'),
                triggerSelector = options.strictmode === true ? '' : 'caret',
                $focusContainer = $select.find('.dropdown-select-container');

            //Setup default value for strict mode
            if (options.strictmode === true) {
                if (_validEmails.get('email', selectedemail)) {
                    $select.find('input:first').val(selectedemail);
                }
            }
            //Bind change value on click element from drodown list
            $ul.on('click', 'li', function () {
                var $this = $(this), value = $this.data('value');
                $select.find('input:first').val(value).trigger('keyup');
            });
            //Bind where dropdown should be trigger
            $select.click(function (e) {
                e.stopPropagation();
                if (options.strictmode === true || e.target.className.includes(triggerSelector)) {
                    $ul.dropdown('toggle');
                }
            });
            //Focus
            if (options.focus === true) {
                var $i = $focusContainer.find('input:first');
                $select.on('show.bs.dropdown', function () {
                    $focusContainer.addClass('focus');
                }).on('hidden.bs.dropdown', function () {
                    $focusContainer.removeClass('focus');
                });
                $focusContainer.on('focusin', 'input', function () {
                    $focusContainer.addClass('focus');
                });

                $focusContainer.on('focusout', 'input', function (e) {
                    $focusContainer.removeClass('focus');
                });
            }
            //Replace input
            $input.replaceWith($select);
        },

        SelectFromEmail = function ($input, selectedemail, focus) {
            var def = $.Deferred(), options = {
                verp: ee.data.account.enablebounceshandling,
                strictmode: ee.data.account.domainrestricted,
                focus: focus
            }
            //Init template
            if (options.verp === true && !location.hash.includes('landingpages')) {
                _validEmails = dataModel.create('ValidEmails');
                _validEmails.require().then(function () {
                    _verpFromEmail($input, selectedemail, options);
                    def.resolve();
                }).catch(function (err) { console.log(err) });
            } else {
                def.resolve();
            }
            return def;
        }
    root.SelectFromEmail = SelectFromEmail;
}(html.widget));