(function () {
    /**
     * Namespace for Router constructor
     * @namespace ee.Router
    */
    ee.Router = (function Router() {
        var that,
            _defaultController = "start",
            _patterns = {
                'create-account': { target: 'register', auth: false },
                'resetpassword': { target: 'login/resetpassword', auth: false },
                'activate': { target: 'login/activate', auth: false },
                'register': { target: 'register/user', auth: false },
                'joomla-acymailing': { target: 'register/joomla-acymailing', auth: false },
                'wordpress-mailpoet': { target: 'register/wordpress-mailpoet', auth: false },
                'webform': { target: 'forms/create', auth: true },
                'xtreeme': { target: 'register/xtreeme', auth: false },
                'followupstarter': { target: 'register/followupstarter', auth: false },
                'open-classifieds': { target: 'register/open-classifieds', auth: false },
            },
            _dashboardPatterns = {
                'reports': { target: 'activity' }
            },
            _routes = { prevpath: "", currentpath: "", controller: "", action: "", parameters: new Collection.Data([]), auth: true },
            _matchGet = function (getQuery) {
                if (!getQuery) return;
                getQuery.replace(/([^=&]+)=([^&]*)/gi, function (m, key, value) {
                    if (value.length > 0)
                        _routes.parameters.add({ key: key, value: value });
                });
            },
            _matchNodes = function (uri) {
                _routes.auth = true;

                if (_patterns[uri]) {
                    _routes.auth = _patterns[uri]['auth'];
                    uri = _patterns[uri]['target'];
                }
                var uriParts = uri.split('/'), n = uriParts.length;
                _routes.controller = (uriParts[0]) ? _dashboardURIRewrite(uriParts[0], uriParts[1]) : _defaultController;
                _routes.action = (uriParts[1]) ? uriParts[1] : "";

                if (n > 2) {
                    for (var i = 2; i < n; i++) {
                        _routes.parameters.add({ key: "node" + (i - 2), value: uriParts[i] });
                    }
                }
            };
            $(window).on('hashchange', () => {
                if($('#ui-datepicker-div').length && !$('#ui-datepicker-div').css('display', 'none')) {
                    $('#ui-datepicker-div').css('display', 'none');
                }
            });
            var _dashboardURIRewrite = function(controller, action) {
            /*
            *   Method for redirects inside dashboard. Created while we wanted to move links that go to REPORTS screen to go to ACTIVITY.
            */
                if(_dashboardPatterns[controller]) {
                    location.hash = action ? '#/' + _dashboardPatterns[controller].target + "/" + action : '#/' + _dashboardPatterns[controller].target; //rewrites URL
                    return _dashboardPatterns[controller].target; //selects proper view
                } else
                    return controller;
            }
        return that = {
            /*
             * Extract the route from the current url
             * link construction: #/CONTROLLER_NAME/ACTION/NODE1/.../NODEn?KEY1=VALUE1&...KEYm=VALUEm
             * NODEn- is stored in _router.parameters.data, available under the method ee.Router.getParameter('node'+N), where N is the node number counted from left
             * KEY1=VALUE1 - are key pair values and stored in _router.parameters.data, available under the method ee.Router.getParameter('keyM'), where "keyM" is key name of pairs.
             * @memberof ee
            */
            match: function () {
                var pathparts, oldPath = _routes.currentpath;
                //when nodes change - we are changing view, always.
                //when params change - we are still in the same view, but we're manipulating it locally
                //we have to make sure that manipulation by user is something else than manipulation mde by reloading screen by our system
                _routes.currentpath = location.hash.replace(/#\//, "");
                _routes.parameters.update([]);

                pathparts = _routes.currentpath.split('?');

                //if nodes change, the whole view changes - change history for that and refresh view
                //if only params changes - update histry, but don't refresh the view'
                _matchGet(pathparts[1] || "");
                // Nodes are more important than the get parameters
                _matchNodes(pathparts[0] || "");

                if (oldPath !== _routes.currentpath) {
                    _routes.prevpath = oldPath;
                }

                if (!ee.session.isInspection() && ee.session.apikey() && window.Intercom && window.Intercom.booted) {
                    var data = _.cloneDeep(ee.data.account);

                    data = _.pick(data, ee.session.getAllowedAccountFields());
                    data.currentUrl = _routes.currentpath || "/";

                    data.datecreated_at = moment(data.datecreated).unix();
                    data.lastactivity_at = moment(data.lastactivity).unix();
                    data.lasteventlogload_at = moment(data.lasteventlogload).unix();
                    data.email = _.toLower(data.email);
                    data.username = _.toLower(data.username);

                    delete data.datecreated;
                    delete data.lastactivity;
                    delete data.lasteventlogload;

                    window.Intercom("update", data);
                }
            },
            /*-- Accessors --*/
            controller: function () {
                return _routes.controller;
            },

            action: function (defaultAction) {
                if (!defaultAction) defaultAction = 'show'
                return (_routes.action) ? _routes.action : defaultAction;
            },

            parameter: function (name) {
                var item = _routes.parameters.get('key', name);
                return (item) ? item.value : undefined;
            },
            setParameter: function (key, value) {
                //change param if exists, create if not
                if (ee.Router.parameter(key) === undefined) {
                    _routes.parameters.add({ key: key, value: value });
                } else {
                    var param = _routes.parameters.get('key', key);
                    param.value = value;
                }
                ee.Router.updateRouter();
            },
            updateRouter: function (quiet) {
                //rewrite whole hash
                var hash = _routes.action ? "/" + ee.Router.controller() + "/" + ee.Router.action() : "/" + ee.Router.controller();
                var keyvalues = false;
                var hashfirstparam = true;
                var nodes = "";
                var querystring = ""
                //add after / if it's node, otherwise use key-value syntax'
                for (var i = 0; i < _routes.parameters.data.length; i++) {
                    var param = _routes.parameters.data[i];
                    //for parameters called node we add slash and don't change the flag of params'
                    if (param.key.includes('node')) {
                        nodes += "/" + param.value;
                    } else if (param.value) {

                        if (param.key != "page" || param.value != "1") { //don't generate view for first page of pagination
                            querystring += hashfirstparam ? "?" : "&";
                            hashfirstparam = false;
                            querystring += param.key + "=" + param.value;

                        }
                        keyvalues = true;
                    }
                }

                hash = hash + nodes + querystring;
                if (keyvalues || quiet) { //update hash without reloading data
                    var quietchange = true
                    ee.changeHashQuietly(hash, "", quietchange);
                } else {
                    ee.goTo(hash);
                }
            },
            removeParameter: function (key, quiet) {
                _routes.parameters.remove('key', key);
                ee.Router.updateRouter(quiet);
            },
            allParameter: function () {
                return _routes.parameters.getAll();
            },
            requiredAuth: function () {
                return _routes.auth;
            },
            currentPath: function () {
                return _routes.currentpath;
            },

            prevPath: function () {
                return _routes.prevpath;
            },
            updateHash: function (view, originalEvent, startedbysearch) {
                //we are adding new items to history by creating artificial clicks on screen. Second parameter = true just selects view on the same screen and hash, without creating new one.
                if (view === window.location.hash) {
                    ee.hashChangeEnabled = true;
                    return;
                }
                if (!originalEvent) // we use !e.originalEvent for that, to check if screen change was made by human or us.
                    window.location.hash = view;
                if (startedbysearch)
                    ee.hashChangeEnabled = false;
                ee.Router.match();
                if (originalEvent) // we use !e.originalEvent for that, to check if screen change was made by human or us.
                    html.clearSearch();
            },
            urlParameter: function (name) {
                var url = window.location.href;
                name = name.replace(/[\[\]]/g, "\\$&");
                var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';

                return decodeURIComponent(results[2].replace(/\+/g, " "));
            }, 
            getBreadCrumb: function () {
                let prevURL = _routes.prevpath ? _routes.prevpath : _routes.controller;
                let breadCrumb = {};
                breadCrumb.url = prevURL;
            },
            setPrevpath: function (path) {
                _routes.prevpath = path;
            }
        }
    })();
})();
