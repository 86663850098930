/**
* Controller from the template screen, allows to manage templates and run editors.
* @namespace
* @memberof ee.views
*/
ee.views['newtemplates'] = (function ViewTemplates() {
    "use strict";

    var that, editor, templateData, $screen, editorFrame,
        versionOfEditor = { designer: '3.0.0', rawhtml: '2.0.0' },
        /*-- Private Methods --*/

        /**
        * Safe handling of the error in the editor during initialization
        * @param {obj} error - 
        */
        _errorEditor = function (error) {
            console.log(error);
            //API ERROR
            if (error.success === false) {
                editorFrame.off('beforeClose').close();
            } else {
                html.modal.error({ error: error.toString() }, { onClose: function () { editorFrame.off('beforeClose').close(); } });
            }
        },

        /**
         * Initialization of email template editing
         * @param {string} type - Type name of editor to run
         * @param {int} templateid - Id of template to editing, if is empty then create new template
         */
        _runEditor = function (type, templateid, isPublic) {
            //that.editor = that.Frame = null; ??
            //1. Run overlay
            editorFrame = new html.Frameoverlay('', {
                templateName: 'FrameOverlayEditor',
                animation: false,
                css: 'editor-withsidebar designer'
            }).on({
                'afterResize': function () {
                    var $editorbody = editorFrame.$container.find('.editor-body:first');
                    $editorbody.height(window.innerHeight - $editorbody.offset().top);
                },
                'beforeClose': function () {
                    //Destroy editor
                    if (editor) {
                        editor.destroy();
                    }
                    //Unset editor and frame
                    editor = editorFrame = null;
                },
                'afterClose': function () {
                    ee.goTo('#/templates');
                }
            });
            //2. Create TemplateData model
            templateData = dataModel.create('Template', { templateid: templateid, ispublic: isPublic });
            if (!templateid) {
                templateData.data.templatetype = templateData.getTypeByName(type);
            }
            editorFrame.$container.loadingdescription.text('Pay no attention to the man behind the curtain');
            //3. Load Template content 
            $.when(templateData.load(), html.loadLibrary('plugins/' + type + '-editor.js', versionOfEditor[type])).then(function () {
                //4. Init plugin editor
                editor = new ee.plugins.template[type](editorFrame.$container, templateData.data);
                editor.on({
                    'afterInit': function () {
                        editorFrame.$container.loading.remove();
                        //Forced resize content in overlay
                        editorFrame.trigger('afterResize');
                    },
                    'exit': function (event, forced) {
                        if (forced) {
                            editorFrame.close();
                            return true;
                        }
                        if (ee.tools.compareShallowObject(editor.template, editor.pullTemplate()) === false) {
                            html.modal.confirm(ee.t.exiwithoutsaving, ee.t.info, function () {
                                editorFrame.close();
                            }, false, { confirmTitle: ee.t.exit });
                            return false;
                        }
                        editorFrame.close();
                        return true;
                    },
                    'save': function (event, quiet) {
                        if (quiet !== true) ee.indiOn()
                        var newTemplate = editor.pullTemplate();
                        //Note:  Note:limit the saves call?
                        ///if (ee.tools.compareShallowObject(editor.template, newTemplate) === false) 
                        templateData.update(newTemplate).save().then(function (templateid) {
                            //TODO: In campagincreator should not change hash
                            if (templateid > 0) ee.changeHashQuietly('#/newtemplates/' + type + '/' + templateid);
                        }).always(function () { ee.indiOff(); });
                    }
                });
            }).catch(_errorEditor);
        };
    /*-- PUBLIC --*/
    that = {
        show: function () {
            var action = ee.Router.action();
            switch (action) {
                case 'designer':
                case 'rawhtml':
                    _runEditor(action, ee.Router.parameter('node0'), ee.Router.parameter('node1') === 'public');
                    break;
                default:
                    // showTemplates(ee.Router.action());
                    break;
            }
        }
    };

    return that;
})();