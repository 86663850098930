//NOTE: the form editor load elements such as customfields and templates from backend,
// all this api call used ee.view.show.as a callback method, which causes repeated calling of some initializing functions from forms.js
// It will require repair

ee.views['forms'] = (function ViewForms() {
    /*-- Returned object of view class--*/
    var that = {}, pagination, $screen, limit = 50, offset = 0, activeTab = '';
    var _copyForm = function (name, scope) {
        var form = $.extend(true, {}, _getForm(name, scope));
        form.ispublic = false;
        form.scope = 0;
        form.screenshot = '';
        form.screenshotpath = '';
        form.mediumscreenshotpath = '';
        form.thumbnailpath = '';
        form.dateadded = '';
        form.name = name;
        delete ee.data.selectedform;
        return form;
    };
    let dropdownMenuIsClicked;
    const setupMenuItemsOnClick = (row) => {
        dropdownMenuIsClicked = true;
        if (row == null) {return;}
        $(row.parentElement).off('click');
        //Options list events
        //Edit form
        $(row.parentElement).on('click', '.action-edit', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            ee.goTo("#/forms/edit/" + $(this).closest('ul').data('formname'));
            return;
        });
        //Copy and edit
        $(row.parentElement).on('click', '.action-copy', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            ee.goTo("#/forms/copy/" + $(this).closest('ul').data('formname'));
            return;
        });
        $(row.parentElement).on('click', '.action-gallerycopy', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            ee.goTo("#/forms/gallerycopy/" + $(this).closest('ul').data('formname'));
            return;
        });
        //Delete form
        $(row.parentElement).on('click', '.action-delete', function (e) {
            dropdownMenuIsClicked = false;
            e.stopPropagation();
            var formname = $(this).closest('ul').data('formname');
            html.modal.confirm(ee.t.deleteform, ee.t.removeform, function () {
                EE_API.Form.Delete({ formName: formname }).then(function () {
                    ee.data.forms.remove('name', formname);
                    pagination.updateData(ee.data.forms.getAll());
                    if ($screen.searchinputforms.val()) {
                        $screen.searchinputforms.trigger('keyup');
                    }
                    html.widget.FormThumbnail.init($screen);
                });
            }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
        });
    }
    var _getForm = function (formname, scope) {
        //change this to public scopename
        if (scope === 'true' || scope === true) {
            return ee.data.globalforms.get('name', formname);
        }
        var form = ee.data.forms.get('name', formname);

        ee.data.selectedform = form;
        return form;
    };
    var _previewContent = function (formname, isPublic) {;
        var form = _getForm(formname, isPublic);
        if (!form) {
            html.modal.info(ee.t.templatenotfound, ee.t.codeerror);
            return;
        };
        ee.indiOn();
        var doc, minHeight, resizetInterval, $content = html.get("PreviewForm", form), $iframe = $content.iframe;
        $content.moible.click(function () {
            if ($content.moible.hasClass("active")) { return; }
            $content.phone.animate({ "opacity": 0 }, 200, function () {
                $content.phone.addClass("iPhone5");
                $iframe.attr("class", "iPhone5frame enoborder");
                if (doc.body) { doc.body.style.overflow = 'auto'; }
                $content.phone.animate({ "opacity": 1 }, 400);
            });
        });
        //Desktop Previow
        $content.desktop.click(function () {
            if ($content.desktop.hasClass("active")) { return; }
            $content.phone.animate({ "opacity": 0 }, 200, function () {
                $content.phone.removeClass("iPhone5");
                $iframe.attr("class", "efullwidth efullheight enoborder");
                if (doc.body) { doc.body.style.overflow = 'hidden'; }
                $content.phone.animate({ "opacity": 1 }, 400);
            });
        });

        function resizeIframe() {
            var height = $iframe[0].contentWindow.document.body.scrollHeight;
            if (height < minHeight) height = minHeight;
            $iframe.height(height);
        }
        var $formBody = $('<div style="margin:auto;">'+form.html+'</div>');
        $formBody.append('<style>body{background:#FFFFFF;display: flex;justify-content: center;height: 100 %;}</style><style>form.Modal {animation-duration: 2s !important;-webkit-animation-duration:2s!important;-moz-animation-duration: 2s !important;-o-animation-duration: 2s !important;-ms-animation-duration: 2s !important;}form.Sticky {animation-duration: 2s !important;-webkit-animation-duration:2s!important;-moz-animation-duration: 2s !important;-o-animation-duration: 2s !important;-ms-animation-duration: 2s !important;}</style>' + form.css);
        var frameoverlay = new html.Frameoverlay($content).on({
            'beforeShow': function () {
                minHeight = window.innerHeight - ($iframe.offset().top - window.scrollY) - parseInt($iframe.css("margin-top"), 10);
                $iframe.height(minHeight);
                resizetInterval = setInterval(resizeIframe, 600);
                $iframe.on('load', function () {
                    clearInterval(resizetInterval);
                    resizeIframe();
                });
                doc = $iframe[0].contentWindow.document;
                doc.open();
                doc.write($formBody[0].outerHTML);
                doc.close();
                if (doc.body) {
                    doc.body.style.overflow = 'hidden';
                    $(doc).find("html").height("100%");
                }
            },
            'afterShow': function () { ee.indiOff(); }
        });
    };
    
    let firstLoad = true;
    var _showForms = function () {
        dropdownMenuIsClicked = false;
        ee.tools.setupMutationObserver('.formsdrop', dropdownMenuIsClicked, setupMenuItemsOnClick);
        $screen = html.get('Forms');
        $screen.appendTo(ee.frame.content);
        ee.t.dynamicnoforms = ee.t.noforms;
        //Default load always my forms
        pagination = new html.Pagination([], {
            template: 'FormsListPreview',
            searchparameters: ['name'],
            resultcontainer: $screen.paginationresult,
            footercontainer: $screen.resultfooter,
            perpage: 12,
            paginationinfo: false,
            firstlast: false
        });
        //Change page even
        pagination.eventChangePage(function (e) {
            e.stopPropagation();
            html.widget.FormThumbnail.init($screen);
        });
        //Bind Tabs
        $screen.formtypetabs.on('click', 'li', function (e) {
            e.stopPropagation();
            $('#eformtypetabs').find('li').removeClass('active');
            $screen.searchinputforms.val("");
            pagination.setPage(1);
            var $this = $(this), type = $this.data("type");
            $this.addClass('active');
            if (type === 'gallery') {
                ee.t.dynamicnoforms = ee.t.notemplatesglobal;
                pagination.updateData(ee.data.globalforms.getAll());
                ee.Router.updateHash('#/forms/gallery');
            } else {
                ee.t.dynamicnoforms = ee.t.noforms;
                pagination.updateData(ee.data.forms.getAll());
            }
            ee.Router.updateHash(type ? '#/forms/' + type : '#/forms', !e.originalEvent);
            activeTab = type;
            html.widget.FormThumbnail.init($screen);

        });
        //Bind Search
        html.bindSearch($screen.searchforms, function (searchstring) {
            ee.t.dynamicnoforms = ee.t.nosearchresults;
            pagination.searchfilter(searchstring);
            html.widget.FormThumbnail.init($screen);
        });
        
        if (!activeTab) {
            if (ee.data.forms.getLength() === 0 && ee.data.globalforms.getLength() > 0 && firstLoad) {
                $screen.formtypetabs.find('li[data-type=gallery]').trigger('click');
                ee.Router.updateHash('#/forms/gallery')
                firstLoad = false;
            } else {
                $screen.formtypetabs.find('li[data-type]:first').trigger('click');
            }
            
        } else {
            $screen.formtypetabs.find('li[data-type=' + activeTab + ']').trigger('click');
            
        }
        $('#forms-menu-item').on ('click', () => {
            if (ee.data.forms.getLength() === 0 && ee.data.globalforms.getLength() > 0) {
                $screen.formtypetabs.find('li[data-type=gallery]').trigger('click');
                ee.Router.updateHash('#/forms/gallery')
            } else {
                $screen.formtypetabs.find('li[data-type]:first').trigger('click');
            }
        })

        //Refresh button
        $screen.refresh.off().click(function () {
            _loadForms().then(function () {
                $screen.searchforms.find('input').trigger('keyup');
            });
        });
        //Create button
        $screen.on('click', '#ecreateform', function () {
            ee.goTo('#/forms/create');
        });

        $('#epaginationresult').on('click', 'div.forminside', function (e) {
            e.stopPropagation();
            var type = $screen.formtypetabs.find('li.active').data('type') || "";
            if (type == 'gallery') {
                ee.goTo("#/forms/gallerycopy/" + ee.tools.encodeBase64Url($(this).closest('.gallery-item').data('formname')));
            } else {
                ee.goTo('#/forms/edit/' + ee.tools.encodeBase64Url($(this).closest('.gallery-item').data('formname')));
            }
        })
        //Bind Options
        $screen.on('click', '.action-options', function (e) {
            e.stopPropagation();
            $(this).closest('.edeadcenter').addClass('active')

        });
        $screen.on('mouseleave', '.gallery-item', function () {
            $(this).find('.edeadcenter').removeClass('active');
        });
        $screen.on('click', '.gallery-container', function (e) {
            e.stopPropagation();
            $(this).find('.edeadcenter.active').removeClass('active');
        });

        //Preview
        $screen.on('click', '.gallery-preview', function (e) {
            e.stopPropagation();
            var $galleryitem = $(this).closest('.gallery-item');
            _previewContent($galleryitem.data('formname'), $galleryitem.data('ispublic'));
        });

        
    };
    var _setSelectedForm = function (formname, ispublic, callback) {
        ee.data.selectedform = false;
        var form = _getForm(formname, ispublic);
        if (form) {
            ee.data.selectedform = form;
            callback();
        }
        if (!form) {
            //html.modal.info("Error: Template not found", 'Error', false, function () { ee.goTo("#/" + ((ee.Router.controller() === "campaigncreator") ? ee.Router.currentPath() : ee.Router.controller())); });
            return;
        }
    };
    var _loadForms = function () {
        return EE_API.Form.List().then(function (data) {
            ee.data.forms = new Collection.Data(data.forms);
            ee.data.forms.each(function () {
                if (this.screenshot) this.screenshotpath = ee.api.path + "/userfile" + this.screenshot;
                if (this.mediumscreenshot) this.mediumscreenshotpath = ee.api.path + "/userfile" + this.mediumscreenshot;
                if (this.thumbnail) this.thumbnailpath = ee.api.path + "/userfile" + this.thumbnail;
            })
            ee.data.globalforms = new Collection.Data(data.globalforms);
            ee.data.globalforms.each(function () {
                if (this.screenshot) this.screenshotpath = ee.api.path + "/userfile" + this.screenshot;
                if (this.mediumscreenshot) this.mediumscreenshotpath = ee.api.path + "/userfile" + this.mediumscreenshot;
                if (this.thumbnail) this.thumbnailpath = ee.api.path + "/userfile" + this.thumbnail;
            })
        }).catch(function (err) { console.log(err) });
    };
    var _switchViews = function () {
        var formname = !!ee.Router.parameter('node0') && ee.tools.decodeBase64Url(ee.Router.parameter('node0'));
        switch (ee.Router.action()) {
            case 'create':
                ee.logEvent('Forms - create new form');
                ee.plugins.webform.init();
                return;
            case 'copy':
                if (formname) {
                    //TO DO - another mechanism for copying forms
                    ee.logEvent('Forms - copy');
                    _setSelectedForm(formname, false, function () {
                        ee.plugins.webform.init();
                    });
                    return;
                }
            case 'gallerycopy':
                if (formname) {
                    ee.logEvent('Forms - gallery copy');
                    ee.data.selectedform = _copyForm(formname, true);
                    ee.plugins.webform.init();
                    return;
                }
            case 'edit':
                ee.logEvent('Forms - edit form');
                _setSelectedForm(formname, false, function () {
                    ee.plugins.webform.init();
                });
                return;
            default:
                ee.logEvent('Forms screen loaded');
                _showForms();
                break;
        }
    },
        show = function () {
            if (ee.Router.action() === 'new') {
                return ee.initNextGen();
            }
                
            ee.data.selectedform = false;
            ee.frame.content.empty();
            if (!ee.data.forms) {
                //Mix of old and new way.
                //TODO: create models/forms.js
                _loadForms().then(function () {
                    _switchViews();
                });
                return;
            } else {
                _switchViews();
            }
        };
    that.show = show;
    that.loadForms = _loadForms
    return that;
})();
