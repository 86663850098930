/**
* Controller for the apikeys screen, allows to manage apikeys.
* @namespace
* @memberof html.widget
*/
html.widget['apikeys'] = (function ViewApikeys() {
    "use strict";

    var that, $screen, Apikeys, pagination,
    _showTable = function (data, type) {
        //show screen, then show table inside
        let isapikey = (type == "apikey");
        let options = { 
            server: ee.data.account.smtp,
            isapikey: isapikey,
            privateIP: ee.data.account.mailerid > 0, 
            hasfullaccess: ee.session.can.Security,
            itemlength: data.length >= 3 ? 4 : 12/data.length,
            data: data,
            type: isapikey ? "APIKey" : "SMTPCredential"
        }
        let AccessList = html.get("AccessList", options);
        $screen.apikeyresultlist.empty().append(AccessList)
        if (data.length) {
            $('#eshowaddapikey').removeClass('hide');
            $('#alternateportsinfo').removeClass('hide');
        } else {
            $('#eshowaddapikey').addClass('hide');
            $('#alternateportsinfo').addClass('hide');
        }
        $(".eshowaddapikey").off().on('click', function () {
            ee.goTo(`#/settings/${type}/add`)
        });
        //-- Edit apikey
        $screen.apikeyresultlist.off().on('click', '.eeditaccess', function () {
            ee.goTo(`#/settings/${type}/edit/${  $(this).data('name').toString()}`)
        });

        //-- Change password
        $screen.apikeyresultlist.on('click', '.echangepassword', function () {
            //let container = $(this).closest('.accesstoken');
            let tokenName = $(this).data('name').toString();
            return EE_API.Access.ChangePassword({tokenName, type: options.type })
            .then((data) =>{
                let apikey = data;
                let changepassworddata = html.get("ChangeAccessPassword", {data, isapikey: options.isapikey});
                if (isapikey) {
                    html.modal.info(changepassworddata,ee.t.apikeychanged,"emodal-md", false,
                        {
                            confirmTitle: ee.t.close,
                            confirmCSS: "btn_basic"
                        }
                    ) 
                } else {
                    html.modal.info(changepassworddata,ee.t.passwordchanged,"emodal-md", false,
                        {
                            confirmTitle: ee.t.close,
                            confirmCSS: "btn_basic"
                        }
                    )
                }
                if (html.clickToCopy.test()) {
                    html.clickToCopy.init(changepassworddata);
                }
                return apikey;
            })
            .then((apikey) => {
                //get last 5 characters of apikey and build a mask on it's base;
                ee.view.access(type, true);
                // let mask = `**********${apikey.slice(-5)}`;
                // container.find('.maskedtoken').text(mask);
            })
            .catch((err) => {
                console.log(err)
            })
        });

        //-- Delete apikey
        $screen.apikeyresultlist.on('click', '.edeleteaccess', function () {
            let apikeyname = $(this).data('name').toString();
                confirm = html.modal.confirm(options.isapikey ? ee.t.apikeydelete : ee.t.smtpdelete, options.isapikey ? ee.t.apikeydeleteheader : ee.t.smtpdeleteheader, function () {
                    ee.indiOn();
                    return Apikeys.removeApikey({tokenName: apikeyname, type: options.type})
                    .then(() => {
                        return _showTable(Apikeys.getAll(), type)
                    }).always(() => {
                        ee.indiOff();
                        confirm.close();
                        if (location.hash.includes('smtp')) {
                            $('#esmtpconfigurationtabheader').click();
                        } else {
                            $('#eapiconfigurationtabheader').click();
                        }
                    });
                }, false,{
                    confirmTitle: ee.t.delete
                });
        });
    };
    /*-- PUBLIC --*/
    that = {
        show: function (type) {
            Apikeys = dataModel.create("ApikeysList");
            return Apikeys.load(type)
            .then((data) => {
                $screen = html.get(`AccessMain`, {existing: Apikeys.data.length, isapikey: type == "apikey", isreseller: ee.isReseller(), isCustomBranding: ee.data.account.privatebrandingurl});
                var $container = $(`#${type}container`);
                $container.empty();
                $screen.appendTo($container);
                return;
            })
            .then(function (data) {
                return _showTable(Apikeys.getAll(), type)
            })
            .catch(function (msg) {
                console.log(msg);
            });
        }
    };

    return that;
})();
