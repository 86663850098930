(function () {
    /**
    * Extension of the class HTML, adds methods associated with the display modal window.
    * @namespace
    * @memberof html
    */
    html.modal = function () {
        var activeCount = 0, init = true;
        var $modalOverlay = $('<div id="emodal-overlay" class="emodal-overlay" style="display:none"></div>');
        var $window = $(window), infoModal = false;
        var hideOverlay = function () {
            activeCount--;
            if (activeCount <= 0) {
                $modalOverlay.hide().empty();
                activeCount = 0;
            }

            if (activeCount > 0) {
                $(".emodal[data-layer=" + (2499 + activeCount) + "]").addClass("active")
            }
        };
        var setActive = function (e) {
            e.stopPropagation();
            if ($(e.target).hasClass("btn") === true) {
                return;
            }
            var $activeModal = $(this), startZindex = parseInt($activeModal.css("z-index"));

            $modalOverlay.find(".emodal").each(function (index, el) {
                var $modalContainer = $(this), zindex = parseInt($modalContainer.css("z-index"));
                if (zindex > startZindex) {
                    $modalContainer.css("z-index", zindex - 1).attr("data-layer", zindex - 1);
                }
            });
            $modalOverlay.find(".emodal.active").removeClass("active");
            $activeModal.addClass("active").css("z-index", 2499 + activeCount).attr("data-layer", 2499 + activeCount);
        };
        var addButtons = function ($footer, buttons) {
            for (var i = 0, n = buttons.length; i < n; i++) {
                (function (j) {
                    var buttonSetting = buttons[j],
                        /**
                        * @typedef html.modal~buttonObjects
                        * @prop name {String} - text on button
                        * @prop css {String} - extra css class for button
                        * @prop buttonID {String} - button css id
                        * @prop title {String} - data-title value
                        * @prop callback {function} - function to invoke after button click
                        */
                        $button = html.get("ModalButton", buttonSetting, true);
                    $footer.prepend($button);
                    if (typeof buttonSetting.callback === "function") {
                        $button.click(_.debounce(buttonSetting.callback, 2000, {
                            'leading': true,
                            'trailing': false
                        }));
                    }
                }(i));
            }

        };
        var initModals = function () {
            $('body').append($modalOverlay);
            $modalOverlay.on("click dragstart", ".emodal", setActive);
            $modalOverlay.click(function (e) {
                if ($(e.target).hasClass("emodal-overlay") === true) {
                    $modalOverlay.find(".emodal.active:not(.strictclose) .emodal-close:first").trigger("click");
                }
            });
            $(document).keyup(function (e) {
                if (e.keyCode == 27 && activeCount > 0) {
                    e.stopPropagation()
                    $modalOverlay.find(".emodal.active:not(.strictclose) .emodal-close:first").trigger("click");
                }
            });
        }

        var that = {
            /**
            * Create modal
            * @memberof html.modal
            * @param $content {jQuery.Object} - modal content
            * @param options {Object} - Options object
            * @param options.title {String} - modal title
            * @param options.width {String} - max width f.e '400px'
            * @param options.size {String} - modal size: emodal-lg, emodal-md, emodal-sm, emodal-xl
            * @param options.css {String} - extra css classes
            * @param options.onShow {function} - after show modal callback(modal, $container)
            * @param options.onHide {function} - after hide modal callback
            * @param options.onClose {function} - after close modal callback
            * @param options.buttons {Array} - array with {@link html.modal~buttonObjects}
            * @param options.defaultEvent {String} - deafult action after click on close (default: "close")
            * @param options.draggable {Boolean} - is modal draggable (default: true)
            * @param options.zindex {Number} - modal zindex
            * @param options.alignToScreenHeight {Boolean} - align modal to screen height (default: false)
            * @param options.maxToScreenHeight {Boolean} - max modal height to screen height (default: false)
            * @param options.instantShow {Boolean} - show modal (default: true),
            * @param options.cancel {Object} - cancel button object
            * @param options.strictClose {Boolean} - if is true then modal can be closed only by clicking on the button
            * @param options.sidePanel {Boolean} - if true, generate sliding panel instead of modal and ignore positioning
            * @param options.fullScreen {boolean} - modal in full screen
            * @return {this}
            */
            create: function ($content, options) {
                if (activeCount >= 100) {
                    console.log("Warring: Modal window limit reached");
                    return {};
                }
                if (init === true) {
                    initModals();
                    init = false;
                }
                // Close overlay on ESC keydown
                document.addEventListener("keydown", function escHandler(ev) {
                    if (ev.keyCode === 27) {
                        hideOverlay();
                        document.removeEventListener("keydown", escHandler);
                    }
                });
                //   if (options && !options.buttons) options.buttons = [];
                var modal = { active: false, show: false, hide: false, close: false };
                var settings = $.extend(true, {
                        title: "",
                        width: false,
                        size: "",
                        css: "",
                        onShow: false,
                        onHide: false,
                        onClose: false,
                        onCancel: false,
                        buttons: [],
                        defaultEvent: "close",
                        manualClose: false,
                        draggable: true,
                        zindex: 2500 + activeCount,
                        alignToScreenHeight: false,
                        maxToScreenHeight: false,
                        instantShow: true,
                        cancel: { title: ee.t.cancel, css: "" },
                        strictClose: true,
                        sticktop: false,
                        customHeader: false,
                        footerCSS: false
                    }, options);
                var $container = settings.sidePanel? html.get("SidePanel", settings, true) : html.get("ModalBox", settings, true);

                //Remove old active
                if (activeCount > 0) {
                    $modalOverlay.find(".emodal.active").removeClass("active");
                }
                $container.addClass("active");
                //Add methods
                var close = function close() {
                    if (settings.onClose !== false) {
                        settings.onClose();
                    }
                    $container.remove();
                    //if is info modal then remove it.
                    if (settings.css.indexOf("emodal-info") >= 0) {
                        infoModal = false;
                    }
                    hideOverlay();
                }
                var show = function show() {
                    $container.show();
                    if (activeCount === 0) {
                        $modalOverlay.show();
                    }
                    if (!options.sidePanel)
                        modal.center();

                    activeCount++;
                    if (settings.onShow !== false) {
                        settings.onShow(modal, $container);
                    }
                }
                var hide = function hide() {
                    $container.hide();
                    activeCount--;
                    if (activeCount <= 0) {
                        activeCount = 0;
                        $modalOverlay.hide();
                    }
                    if (settings.onHide !== false) {
                        settings.onHide();
                    }
                }
                var setContent = function (title, $content) {
                    modal.$title.html(title);
                    $container.find(".emodal-content:first").html($content);
                }
                var center = function center() {
                    if (settings.alignToScreenHeight === true || settings.maxToScreenHeight === true) {
                        var height = $window.outerHeight(true) - $container.find(".emodal-title").outerHeight(true) - modal.$footer.outerHeight(true) - 40;
                        $container.addClass("emodal-alignheight");
                        $container.find(".emodal-content").css((settings.alignToScreenHeight === true) ? "height" : "max-height", height + "px");
                    }
                    var top;
                    if (settings.sticktop !== false) {
                        top = 20;
                    }else{
                        top = ($window.scrollTop() + ($window.outerHeight(true) - $container.outerHeight(true)) / 4);
                    }
                    $container.css("top", ((top < 20) ? 20 : top) + "px");
                    var leftOffset = (($window.width() - $container.outerWidth(true)) / 2);
                    $container.css("left", ((leftOffset < 0) ? 0 : leftOffset) + "px");
                };


                //Set public varible
                modal.active = true;
                modal.show = show;
                modal.setContent = setContent;
                modal.hide = hide;
                modal.close = close;
                modal.center = center;
                modal.$content = $content;
                modal.$container = $container;
                modal.$title = $container.find(".emodal-title");
                modal.$footer = $container.find(".emodal-footer");
                if (settings.buttons && settings.buttons.length > 0) {
                    addButtons(modal.$footer, settings.buttons);
                    modal.$footer.removeClass("hidden");
                }

                //Add Content to modal
                $container.find(".emodal-content").append($content);

                //Bind close/hide event
                $container.on("click", ".emodal-close", function (e) {
                    e.stopPropagation();
                    if (settings.onCancel) {
                        settings.onCancel();
                    }
                    modal[settings.defaultEvent]();
                });

                if (options.customHeader) {
                    var $modalHeader = $container.find('.emodal-title');
                    $modalHeader.removeClass('emodal-title');
                    $modalHeader.empty().append(options.customHeader);
                }

                //Add Modal to overlay
                $modalOverlay.append($container);

                //Draggable
                if (settings.draggable) {
                    $container.draggable(
                        {
                            handle: ".emodal-title",
                            stop: function (event, ui) { ui.helper.css('height', '');; }
                        });
                }

                if (settings.instantShow === true) {
                    modal.show();
                } else {
                    $container.hide();
                }


                return modal;
            },
            /**
            * Return cuont of active modals
            * @return {Number}
            * @memberof html.modal
            */
            getActiveCount: function getActiveCount() {
                return activeCount;
            },
            /**
            * Show error modal it is kind a wraper
            if data has errordata it shows ErrorModal with {@link html.clickToCopy} posibility,
            other case it show just html.modal.info
            * @param data {Object} - data from backend
            * @param opt {Object} - Options object for html.modal.info (No mater when data has errordata)
            * @param opt.title {String} - Title of modal.info
            * @param opt.size {String} - Size of modal.info
            * @param opt.onClose {function} - function to invoke after close modal.info
            * @memberof html.modal
            */
            error: function (data, opt) {
                ee.indiOff();
                if (data.errordata) {
                    var clickToCopy = html.clickToCopy.test(),
                        $content = html.get("ErrorModal", { err: data.errordata, clickToCopy: clickToCopy }),
                        modal,
                        buttons = [];

                    if (ee.data.account && !ee.data.account.issub) {
                        buttons.push({
                            name: 'Contact support',
                            css: 'btn_primary btn_lg',
                            callback: function () {
                                window.open("http://support.elasticemail.com/", '_blank');
                            }
                        });
                    };

                    modal = that.create($content, { title: ee.t.unexpectederror, buttons: buttons });
                    if (clickToCopy) html.clickToCopy.init($content, true);
                } else {
                    if (!opt) opt = {};
                    that.info(data.error, opt.title, opt.size, opt.onClose);
                };

                /* handle bugsnag notification */
                if (window.Bugsnag) {
                    if (!opt) opt = {};
                    var metadata = {
                        "groupingHash": opt.path,
                        "request": {
                            path: opt.path
                            //, query: opt.query
                        },
                        "special info": {
                            error: data.error,
                            error_data: data.errordata
                        }
                    }
                    if (ee.data.account) metadata.user = { email: ee.data.account.email };
                    Bugsnag.notify("API Error", data.error, metadata, "info");
                }
            },
            /**
            * Show info modal
            * @param content {String | jQuery.Object} - modal content
            * @param title {String} - Title of modal
            * @param size {String} - Size of modal
            * @param onClose {function} - function to invoke after close modal
            * @param extOptions {Object} - addtional params
            * @memberof html.modal
            */
            info: function (content, title, size, onClose, extOptions) {
                if (infoModal != false) {
                    infoModal.close();
                    infoModal = false;
                }
                var $content = (typeof content === "string") ? $("<div>" + content + "</div>") : $(content);
                var options = {
                    title: title,
                    size: size || "emodal-sm",
                    css: "emodal-info",
                    onClose: onClose,
                    buttons: [
                        {
                            name: extOptions && extOptions.confirmTitle ? extOptions.confirmTitle : "OK",
                            css: extOptions && extOptions.confirmCSS ? `${extOptions.confirmCSS} btn_lg emodal-close` : "btn_primary btn_lg emodal-close",
                            callback: false
                        }]
                };

                if (extOptions && !$.isEmptyObject(extOptions)) {
                    $.extend(true, options, extOptions);
                }
                infoModal = that.create($content, options);
                infoModal.$container.modalCancel.remove();
                return infoModal;
            },
            /**
            * show confirm modal
            * @param text {String} - confirmation text
            * @param title {String} - modal title
            * @param confirm_cb {function} - callback after confirm
            * @param cancel_cb {function} - callback after cancel
            * @param opt {Object} - Options object
            * @param opt.size {String} - modal size (default: "emodal-sm")
            * @param opt.confirmTitle {String} - text on confirm button (default: ee.t.yes)
            * @param opt.confirmCSS {String} - css class of confirm button (default: 'btn_primary')
            * @param opt.cancelTitle {String} -  text on cancel button (default: ee.t.cancel)
            * @param opt.cancelCSS {String} - css class of cancel button (default: 'btn-default')
            * @memberof html.modal
            * @return {modalConfirm}
            */
            confirm: function confirm(text, title, confirm_cb, cancel_cb, opt) {

                var options = $.extend(true, { size: "emodal-sm", confirmTitle: ee.t.yes, confirmCSS: 'btn_lg btn_primary', cancelTitle: ee.t.cancel, cancelCSS: 'btn_lg_basic btn_basic btn-default' }, opt);

                var content =   typeof text === 'string' ?
                                $("<div>" + text + "<div>") :
                                $("<div>").html(text);
                var modalConfirm = that.create(content, {
                    title: title,
                    size: options.size,
                    css: "confirmmodalcontainer",
                    sidePanel: options.sidePanel,
                    buttons: [{
                        name: options.cancelTitle,
                        css: options.cancelCSS,
                        callback: function (e) {
                            e.stopPropagation();
                            if (!options.manualClose) {
                                modalConfirm.close();
                            }
                            if (cancel_cb) { cancel_cb(e, modalConfirm); } }
                    }, {
                        name: options.confirmTitle,
                        css: options.confirmCSS,
                        callback: function (e) {
                            e.stopPropagation();
                            if (!options.manualClose) {
                                modalConfirm.close();
                            }
                            if (confirm_cb) { confirm_cb(e, modalConfirm); }
                        }
                    }]
                });
                if (!options.sidePanel)
                    modalConfirm.$container.modalCancel.remove();
                return modalConfirm;
            },
                        /**
            * show sidePanel, used for payments
            * @param text {String} - confirmation text
            * @param title {String} - modal title
            * @param confirm_cb {function} - callback after confirm
            * @param cancel_cb {function} - callback after cancel
            * @param opt {Object} - Options object
            * @param opt.size {String} - modal size (default: "emodal-sm")
            * @param opt.confirmTitle {String} - text on confirm button (default: ee.t.yes)
            * @param opt.confirmCSS {String} - css class of confirm button (default: 'btn_primary')
            * @param opt.cancelTitle {String} -  text on cancel button (default: ee.t.cancel)
            * @param opt.cancelCSS {String} - css class of cancel button (default: 'btn-default')
            * @memberof html.modal
            * @return {modalConfirm}
            */
            sidePanel: function sidePanel(text, opt) {
                var options = $.extend(true, { confirmTitle: ee.t.yes, confirmCSS: 'btn_primary btn_lg', cancelTitle: ee.t.cancel, cancelCSS: 'btn_lg_basic btn_basic', strictClose: true }, opt);
                var sidePanel = that.create($("<div>" + text + "<div>"), {
                    size: options.size,
                    draggable: options.draggable,
                    strictClose: options.strictClose,
                    sidePanel: true,
                    buttons: options.buttons,
                    title: options.title
                });
                return sidePanel;
            },
            /**
            * Close all active modals
            * @memberof html.modal
            */
            closeAll: function () {
                $modalOverlay.hide().empty();
                activeCount = 0;
                infoModal = false;
            }
        }

        return that;
    }();
}());
