(function (root, namespace) {
    "use strict";
    var $screen, $target, TwoFactorAuth, phoneValid = "smsNotification,phoneIsValid,", emailValid = "emailNotification,emailIsValid,", newSettings = "",
        _getBrowserList = function (showedWholeList) {
            return EE_API.Account.GetBrowserTokens()
                .then(function (data) {

                    _.forEach(data, function (el) {
                        el.lastloginShort = moment(el.lastlogin).format('LL');

                        el.lastlogin = moment(el.lastlogin).format('LLL');
                        el.newdevice = true;
                    })
                    ee.data.account.listbrowsers = data;
                    _showBrowserList(showedWholeList);
                })
                .catch(function (err) { console.log(err) });
        },
        _bindTrustedListEvents = function ($screen, showedWholeList) {
            //show Advanced info
            $screen.find(".mainInfo").on("click", function (e) {
                e.stopPropagation();
                $(this).parent().find(".advancedInfo").slideToggle();
            });

            //sort by date, trusted, untrusted
            $("#filterByTrusted").off().change(function() {
                var sortingDirection = $(this).find('option:selected').data('order');
                if(sortingDirection == "date") {
                    ee.data.account.listbrowsers = ee.data.account.listbrowsers.sort(function(a,b){
                        return new Date(b.lastlogin).getTime() - new Date(a.lastlogin).getTime()
                    });
                } else {
                    ee.data.account.listbrowsers = _.orderBy(ee.data.account.listbrowsers, 'trusted', sortingDirection);
                }
                $screen.empty();
                _showBrowserList(showedWholeList);
            });

            //toggle btn to (not)send notification alert
            $screen.find("#sendNotificationAlert").on("click", function() {
                var $that = $(this);
                var alertsDisabled;

                if($that.hasClass('sliderOn')) {
                    alertsDisabled = true;
                    ee.data.account.untrusteddevicealertdisabled = true;
                    $that.removeClass('sliderOn');
                    $that.addClass('sliderOff');
                } else {
                    alertsDisabled = false;
                    ee.data.account.untrusteddevicealertdisabled = false;
                    $that.removeClass('sliderOff');
                    $that.addClass('sliderOn');
                }
                return EE_API.Account.UpdatedDeviceAlert({alertsDisabled: alertsDisabled})
                .catch(function(err) {console.log(err)});
            });

            //show full list of devices
            $screen.find("#reviewallsessions").on("click", function () {
                ee.goTo("#/account/security/sessions");
            });

            //set device as trusted/ untrusted
            $screen.find(".settrustedstate").on("click", function () {
                var $that = $(this);
                var browserId = '' + $that.data("browserid");
                var trusted;

                if($that.hasClass('startSession')) {
                    trusted = true;
                } else {
                    trusted = false;
                }
                EE_API.Account.SetTrusted({ browserTokenID: browserId, trusted: trusted })
                .then(function () {
                    if($that.hasClass('startSession')) {
                        $that.removeClass('startSession');
                        $that.addClass('endSession');
                    } else {
                        $that.removeClass('endSession');
                        $that.addClass('startSession');
                    }
                    _.forEach(ee.data.account.listbrowsers, function (value) {
                        if (value.browsertokenid == browserId) {
                            value.trusted = trusted;
                        };
                    });

                    $screen.empty();
                    _showBrowserList(showedWholeList);
                })
                .catch(function (err) {
                    console.log(err);
                });
            });
        },
        _showSecurityEnable = function () {
            if ($screen.$steps) $screen.$steps.remove();
            var $content = html.get('AccountSecurityEnable');
            _displaySection($content);
            $content.on('click', '.action-enable', _showSecurityMethods);
        },
        _showSecurityStatus = function () {

            $screen?.maintitle?.text(ee.t.twofactorauthentication);
            var statusParts = $.map(ee.data.account.twofactorauthsetting.split(','), function (text) { return text.toLowerCase().trim(); }),
                sms = (statusParts.indexOf("smsnotification") > -1 && statusParts.indexOf("phoneisvalid") > -1) ? true : false,
                mail = (statusParts.indexOf("emailnotification") > -1 && statusParts.indexOf("emailisvalid") > -1) ? true : false,
                data = {};
            if (mail === false && sms === false) {
                _showSecurityEnable();
                return;
            }
            switch (true) {
                //-- Only mail
                case (mail && !sms):
                    data.title = ee.t.email;
                    data.desc = ee.t.twofactorauthentication_email_desc;
                    break;
                //-- Only sms
                case (!mail && sms):
                    data.title = ee.t.twofamethodsms;
                    data.desc = ee.t.twofactorauthentication_sms_desc;
                    break;
                //-- Both
                case (mail && sms):
                    data.title = ee.t.twofamethodboth;
                    data.desc = ee.t.twofamethodboth_desc;
                    break;
            }
            var $content = html.get('AccountSecurityStatus', data);
            $content.on('click', '.action-disabled', function () {
                let confirmationWithoutPassword = html.get('2faDisableWithPass');
                html.modal.confirm(confirmationWithoutPassword, ee.t.twofadisabledtitle, function () {
                    TwoFactorAuth.Disable().then(function () {
                        _showSecurityEnable();
                    });
                }, function () { }, { confirmTitle: ee.t.disable, cancelTitle: ee.t.cancel });
            });
            _displaySection($content);
        },
        _showSecurityMethods = function () {
            var $content = html.get('AccountSecuritySelect'), method;
            if ($screen.$steps) $screen.$steps.remove();
            _displaySection($content);
            $content.on('change', 'input[type=radio]', function () {
                method = this.value;
                $content.find('.btn.action-next').removeAttr('disabled');
            });
            $content.on('click', '.btn.action-next', function () {
                newSettings = "";
                var stepsCount = 3;
                switch (method) {
                    case 'sms':
                        _showSecuritySetupsms();
                        break;
                    case 'email':
                        _showSecuritySetupemail();
                        break;
                    case 'both':
                        stepsCount = 5;
                        _showSecuritySetupBoth();
                        break;
                }
                $screen.$steps = html.get("AccountSecuritySteps", { stepsCount: new Array(stepsCount) });
                _setStep();
                $screen.maintitle.append($screen.$steps);
            });

        },
        _showSecuritySetupsms = function (verifyCallback) {
            var $content = _getSetupContainer('AccountSecuritySMSVerify', { country: new Collection.Data(ee.tools.CountryList()).filter('ID', [230, 38, 229, 73, 30, 100], true, true).getAll() });
            var $form = $content.$form;

            if (ee.data.account.countryid > 0) {
                $form.countrycode.find('option[data-id="' + ee.data.account.countryid + '"]').prop('selected', true);;
            }
            function isValid() {
                var userphone = $form.phonenumber.val(), countrycode = $form.countrycode.val(), phone = '+' + countrycode + ' ' + userphone, $feedback = $form.find('.form-control-feedback');
                if (userphone.length >= 6 && countrycode != '' && ee.tools.isValidPhone(phone)) {
                    $feedback.removeClass('hide');
                    $content.find('.btn.action-next').removeAttr('disabled');
                } else {
                    $feedback.addClass('hide');
                }
                return;
            };
            /*-- Binds events --*/
            $content.on('change', '#ecountrycode', isValid);
            $content.on('keyup blur', '#ephonenumber', isValid);
            //-- Next step
            $content.on('click', '.btn.action-next',  (e) => {
                var $btnNext = $(e.target);
                ee.indiOn();
                TwoFactorAuth.SetPhone(`+${$form.countrycode.val()}${$form.phonenumber.val()}`).then(function () {
                    _setStep();
                    $content.passcodeform.fadeIn(200);
                    $btnNext.hide();
                    $content.find('.action-verify').show();
                    $form.phonenumber.attr('disabled', true);
                    $form.countrycode.attr('disabled', true);
                    $form.dontmobilephone.css('opacity', '0');
                    $content.passcodeinput.focus();
                }).always(ee.indiOff);
            });
            //-- Prev step
            $content.on('click', '.btn.action-prev', function () {
                if (!$form.phonenumber.attr('disabled')) {
                    _showSecurityMethods();
                } else {
                    $content.passcodeform.fadeOut(200);
                    $content.find('.btn.action-next').show();
                    $content.find('.action-verify').hide();
                    $form.phonenumber.removeAttr('disabled');
                    $form.countrycode.removeAttr('disabled');
                    $form.dontmobilephone.css('opacity', '1');
                    _setStep(true);
                }
            });
            //-- Verify passcode
            $content.on('click', '.action-verify', function () {
                ee.indiOn();
                TwoFactorAuth.Verify($content.passcodeinput.val(), 'phone').then(function () {
                    newSettings += phoneValid;
                    if (verifyCallback) {
                        //-- Note: if user try set up both then bind alert when he try escape flow
                        html.frame.bindConfirm(function () {
                            TwoFactorAuth.Disable();
                        }, function () { return false }, { infomessag: ee.t.twofabreaksetupboth });
                        _setStep();
                        verifyCallback(true);
                        return;
                    }
                    _showSecurityBackupCode();
                }).catch(function (response) {
                    $content.ShowVerifyError(response.error);
                }).always(ee.indiOff);
            });
            //-- Don't have mobile phone
            $content.on('click', '#edontmobilephone', function () {
                html.modal.confirm(ee.t.donthavemobilephonenumber_desc, ee.t.authenticatewithemail, function () {
                    _showSecuritySetupemail();
                }, function () { }, { confirmTitle: ee.t.proceed, cancelTitle: ee.t.cancel })
            });
            //-- Resend passcode
            $content.on('click', '.resendpasscode', function () {
                TwoFactorAuth.VerifyResend('phone').then(function () {
                    html.widget.Toast.info(ee.t.twofapasscoderesendsms);
                });

            });
        },
        _showSecuritySetupemail = function (isSecondStep) {
            var $content = _getSetupContainer('AccountSecurityEmailVerify', { isSecondStep: isSecondStep });
            var $form = $content.$form;
            /*-- Binds events --*/
            $content.on('keyup blur', '#eemailaddress', function () {
                if (ee.tools.isValidEmail(this.value)) {
                    $form.find('.form-control-feedback').removeClass('hide');
                    $content.find('.btn.action-next').removeAttr('disabled');
                } else {
                    $form.find('.form-control-feedback').addClass('hide');
                    $content.find('.btn.action-next').attr('disabled', 'disabled');
                }
            });
            //-- Next step
            $content.on('click', '.btn.action-next', function () {
                ee.indiOn();
                var $btnNext = $(this);
                TwoFactorAuth.SetEmail($form.emailaddress.val()).then(function () {
                    _setStep();
                    $content.passcodeform.fadeIn(200);
                    $btnNext.hide();
                    $content.find('.action-verify').show();
                    $form.emailaddress.attr('disabled', true);
                    $content.passcodeinput.focus();
                }).always(ee.indiOff);
            });
            //-- Previus step
            $content.on('click', '.btn.action-prev', function () {
                if (!$form.emailaddress.attr('disabled')) {
                    _showSecurityMethods();
                } else {
                    $content.passcodeform.fadeOut(200);
                    $content.find('.btn.action-next').show();
                    $content.find('.action-verify').hide();
                    $form.emailaddress.removeAttr('disabled');
                    _setStep(true);
                }
            });
            //-- Verify passcode
            $content.on('click', '.action-verify', function () {
                ee.indiOn();
                //If success then
                TwoFactorAuth.Verify($content.passcodeinput.val(), 'mail').then(function () {
                    newSettings += emailValid;
                    _showSecurityBackupCode();
                }).catch(function (response) {
                    $content.ShowVerifyError(response.error);
                }).always(ee.indiOff);
            });
            //-- Resend passcode
            $content.on('click', '.resendpasscode', function () {
                TwoFactorAuth.VerifyResend('mail').then(function () {
                    html.widget.Toast.info(ee.t.twofapasscoderesendemail);
                });
            });
            //-- Skip setup mail
            $content.on('click', '.action-cancel', function (e) {
                e.stopPropagation();
                html.modal.confirm(ee.t.twofabreaksetupboth, ee.t.info, function () {
                    html.frame.unbindConfirm();
                    TwoFactorAuth.Disable().then(function () {
                        _showSecurityEnable();
                    });
                });
            });
        },
        _showSecuritySetupBoth = function () {
            _showSecuritySetupsms(_showSecuritySetupemail);
        },
        _showSecurityBackupCode = function () {
            html.frame.unbindConfirm();
            _setStep();
            ee.indiOn();
            ee.data.account.twofactorauthsetting = newSettings;
            TwoFactorAuth.GenerateDisableCode().then(function (backupCode) {
                var $content = html.get('AccountSecurityBackupCode', { backupCode: backupCode });
                $content.on('click', '.action-exit', _showSecurityStatus);
                _displaySection($content);
                html.widget.Toast.info(ee.t.twofaaccountsecured);
            }).always(ee.indiOff);
        },
        _showBrowserList = function (showedWholeList) {
            var firstBatchToShow = ee.data.account.listbrowsers.slice(0, 3);

            if (showedWholeList) {
                var $screen = html.get("AllRecentlyUsedDevices", {
                    list: ee.data.account.listbrowsers,
                    deviceAlertState: ee.data.account.untrusteddevicealertdisabled,
                    modifySettings: ee.data.account.can.ModifySettings
                 });
            } else {
                var $screen = html.get("RecentlyUsedDevices", {
                    list: firstBatchToShow,
                    showReviewAllBtn: ee.data.account.listbrowsers.length > 3 ,
                    deviceAlertState: ee.data.account.untrusteddevicealertdisabled,
                    modifySettings: ee.data.account.can.ModifySettings
                });
            }
            $screen.appendTo($target.find("#etrustedbrowser"));
            _bindTrustedListEvents($screen, showedWholeList);
        },
        _getSetupContainer = function (template, data) {
            var $container = html.get('AccountSecuritySetup', data), autoVerify = true;
            $container.$form = html.get(template, data);
            $container.method.prepend($container.$form);
            $container.on('keyup blur', '#epasscodeinput', function () {
                var val = this.value;
                if (val.length === 6 && $.isNumeric(val)) {
                    $container.passcodeform.find('.form-control-feedback').removeClass('hide');
                    $container.verify.removeAttr('disabled');
                    if (autoVerify === true) {
                        $container.trigger(jQuery.Event('click', { target: $container.find('.action-verify')[0] }));
                        autoVerify = false;
                    }

                } else {
                    $container.passcodeform.find('.form-control-feedback').addClass('hide');
                    $container.verify.attr('disabled', 'disabled');
                    $container.HideVerifyError();
                }
            });
            $container.ShowVerifyError = function (error) {
                $container.passcodeinputerror.text(error).removeClass('hide');
                $container.passcodeform.find('.fa-check.form-control-feedback').addClass('hide')
            };
            $container.HideVerifyError = function () {
                $container.passcodeinputerror.text('').addClass('hide');
            };
            _displaySection($container);
            return $container;
        },
        _displaySection = function ($toActivating) {
            var $active = $screen.sectioncontainer.find('section.step');
            //Append new section to screen
            $screen.sectioncontainer.append($toActivating).height($toActivating.height());

            if ($active.length === 0) {
                $toActivating.show();
            } else {
                $active.fadeIn(180, function () { $active.remove(); });
                $toActivating.addClass('active').fadeIn(200);
            }
        },
        _setStep = function (prev) {
            var $next,
                $current = $screen.$steps.find('.twofasteps-item__active'),
                itemSelector = '.twofasteps-item';
            if ($current.length === 0) {
                $next = $screen.$steps.find(itemSelector + ':first')
            } else {
                if (prev === true) {
                    $next = $current.prev(itemSelector);
                } else {
                    $next = $current.next(itemSelector);
                }
            }
            $next.addClass(itemSelector.substr(1) + '__active');
            $current.removeClass(itemSelector.substr(1) + '__active');
        };
    namespace.AccountSecurityUI = function () {
        if (!ee.session.can.Modify2FAEmail && !ee.session.can.Security && !ee.session.can.ModifySettings && !ee.session.can.ViewSettings) {
            return ee.goTo("#/dashboard");
        }
            $target = ee.frame.content;
            $target.empty();

        if (ee.Router.currentPath() === "account/security/sessions") {
            if (!ee.data.account.can.ViewSettings) {
                return ee.goTo("#/dashboard");
            }
            $screen = html.get("AllRecentlyUsedDevicesContainer");
            $screen.appendTo($target);
            _getBrowserList(true);
        } else {
            TwoFactorAuth = dataModel.create("TwoFactorAuth");
            $screen = html.get("AccountSecurity", {modify2fa: ee.data.account.can.Modify2FAEmail || ee.data.account.can.Modify2FASms, viewSettings: ee.data.account.can.ViewSettings, publicaccountid: ee.data.account.publicaccountid});
            $screen.appendTo($target);

            if (ee.session.can.Security) {
                html.widget.ProfileUI.show($target);
            }
            if (ee.session.can.ViewSettings) {
                _getBrowserList();
            }

            if (!ee.data.account.twofactorauthsetting || ee.data.account.twofactorauthsetting.toLowerCase() === 'none') {
                if (ee.data.account.can.Modify2FAEmail || ee.data.account.can.Modify2FASms) {
                    _showSecurityEnable();
                }
            } else {
                _showSecurityStatus();
            }
            /*-- Global events --*/
            $screen.on('click', '.btn.action-cancel', function () {
                _showSecurityEnable();
            });

            if (!ee.session.can.ViewUsers || ee.data.account.issub) {
                $('#addusersbutton').hide();
            }
            $('#addusersbutton').on('click', function() {
                ee.goTo('#/account/users');

            })
        }
    };
}(this, html.widget));
