ee.views['account-delete-confirmation'] = (function ViewDeleteAccountConfirm() {
    "use strict";
    let $logo;

    /*-- PUBLIC --*/
    const that = {
        show: function() {
            if (!$logo) {
                $logo = html.getraw('authFrameLogo', {
                    logourl: ee.parameters.logourl,
                    base: !ee.isReseller(),
                });
            }

            const $screen = html.get("AccountDeleteConfirm", {
                logo: $logo,
                base: !ee.isReseller()
            });
            ee.container.html($screen);
        },
        logout: function (callLogout = true) {
            const Action = (callLogout) ? EE_API.Account.Logout : Promise.resolve;

            ee.tools.clearAllTimeouts();

            Action()
            .then(() => {
                ee.session.destroy();
                ee.data = {};
                try {
                    sessionStorage.clear();
                    store.local.remove('remember');
                } catch (err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
                clearTimeout(ee.api.eventTimeout);
                ee.views["account-delete-confirmation"].show();
            });
        }
    };
    return that;
})()