/**
 * Controller from the login screen when user is not auth
 * @namespace
 * @memberof ee.views
 */
ee.views.register = (function ViewRegister() {
    
    let $frame
    
    /*-- PUBLIC --*/
    const that = {
        show() {
            $frame = html.get("authFrame");
            
            ee.container.html($frame);
            
            return ee.initNextGen(false,true);
        },
       
    }
    return that
})()
