ee.views["exports"] = (function ViewExports() {
    /*-- Returned object of view class--*/
    let filesList = [];
    let promises = [];
    let exportsData = [];
    var that = {},
        $screen, _exportList, currentData, pagination, updateTable,

        /*-- Private methods --*/
        _search = function(searchstring) {
            var filter = $screen.statusmenu.find('.efilter-box.esel').map(function() {
                return this.dataset.type.toLowerCase();
            }).get();
            currentData = _exportList.filter(['name', 'info', 'dateadded'], searchstring);
            _updateFilterStats(currentData.getAll());
            if ($('#epaginationresult').find('.eblure').length > 0) return updateTable = true;
            if (pagination) pagination.updateData(currentData.filter(filter, true, true).getAll());
        },
        _updateFilterStats = function(obList) {
            if (!obList) obList = _exportList.getAll();
            var ob = {
                    Log: 0,
                    Contact: 0,
                    Campaign: 0,
                    LinkTracking: 0,
                    Survey: 0,
                    Channel: 0,
                },
                listLength = obList.length;

            for (var i = 0; i < listLength; i++) {
                var type = obList[i].type;
                ob[type] += 1;
            }
            var $statusmenu = $screen.statusmenu;
            $.each(ob, function(type, value) {
                $statusmenu.find('.efilter-box[data-type=' + type + '] span:first').html(value);
            });
        },
        _autoRefresh = function() {
            var notready = _exportList.filter(['status'], "Loading", true);
            if (notready.data.length === 0) return;
            $.when(_exportList.load())
            .then(function() {
                _search($screen.searchinputexports.val());
                ee.setSynchronousRefresh(4, _autoRefresh);
            })
            .catch(err => {
                console.log(err);
            });
        },
        _render = function() {
            $screen = html.get("Exports");
            $screen.appendTo(ee.frame.content);
            if (_exportList.getLength()) {
                $screen.exportlist.show();
                pagination = new html.Pagination(_exportList.getAll(), {
                    template: 'ExportsTable',
                    resultcontainer: $screen.paginationresult,
                    footercontainer: $screen.resultfooter,
                    perpage: 25,
                    paginationinfo: false,
                    firstlast: false,
                });
                _autoRefresh();
            } else {
                $screen.statusmenu.hide();
                $('#hrexports').hide();
                var noResult = html.get('NoResultTemplate', {
                    src: 'https://d16keoxacgvopf.cloudfront.net/img/activity-exportshistory.svg',
                    noresulttext: ee.t.noresult_export,
                    noresultdesc: ee.t.noresult_export_desc
                });
                noResult.appendTo($screen.exportlist.parent())
            }
            _updateFilterStats();
        },
    _events = function() {
        $screen.statusmenu.find('.efilter-box').off().click(function() {
            $(this).toggleClass("esel");
            _search($screen.searchinputexports.val());
        });
        html.bindSearch($screen.searchexports, _search);
        html.addDropdownFocus($screen.exportlist);
        $screen.exportlist.on('hidden.bs.dropdown', '.dropdown', function() {
            if (updateTable) {
                updateTable = false;
                var filter = $screen.statusmenu.find('.efilter-box.esel').map(function() {
                    return this.dataset.type.toLowerCase();
                }).get();
                pagination.updateData(currentData.filter(filter, true, true).getAll());
            }
        });

        $('.ecbox').on('click',function() {
            if($(this).prop('checked')) {
                filesList.push($(this).data('name'));
                $('#ecboxnavi').fadeIn();
            } else {
                _.pull(filesList, $(this).data('name'));
            }
            $('.ecboxcount').text(filesList.length);
            if(!filesList.length) {
                $('#ecboxnavi').fadeOut();
            }
        });

        $('#epagination').on('click', () => {
            $('.ecbox').prop('checked', false);
            $('#export-all').prop('checked', false);
            filesList = [];
            $('#ecboxnavi').fadeOut();
        })

        $('#export-all').on('click', function() {
            let allChecboxSelector = $(document).find('.ecbox');
            if($(this).prop('checked')) {
                $('#ecboxnavi').fadeIn();
                $('.ecbox').prop('checked', true);

                _.forEach(allChecboxSelector, el => {
                    if($(el).prop('checked') && !_.includes(filesList, $(el).data('name'))) {
                        filesList.push($(el).data('name'));
                        $('.ecboxcount').text(filesList.length);
                    }
                });
            } else {
                $('.ecbox').prop('checked', false);
                filesList = [];
                $('#ecboxnavi').fadeOut();
            }
        });


        $('#ecboxexport').text('Download');
        $('#ecboxexport').on('click', function(e) {
            e.preventDefault();
            let link = document.createElement('a');
            link.href = `${ee.api.path}/v2/export/downloadbulk?apikey=${ee.session.apikey()}&publicExportIDs=${filesList}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });

        $('#ecboxdeselect').on('click', function() {
            $('.ecbox').prop('checked', false);
            $('#export-all').prop('checked', false);
            $('#ecboxnavi').fadeOut();
            filesList = [];
        });



        $screen.on("click", ".edeleteexport", function() {
            var filename = $(this).data('filename').toString().toUpperCase();
            var exportid = $(this).data("publicexportid");
            html.modal.confirm(ee.t.deleteexportmsg + filename + "?", ee.t.deleteexportheader, function() {
                $.when(_exportList.delete(exportid))
                .then(function() {
                    _search($screen.searchinputexports.val());
                    html.modal.info(ee.t.file + " " + filename + " " + ee.t.wasdeleted, ee.t.file + " " + ee.t.deleted)
                })
            }, "", { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });

        });
        $screen.on('click', '.ecopylink', function() {
            var $this = $(this),
                $content = html.get('CopyLinkModal', {
                    link: $this.data('link'),
                    filename: $this.data('filename')
                }),
                modal = html.modal.create($content, {
                    size: 'emodal-lg',
                    cancel: {
                        title: ee.t.close,
                        css: "eeform_btn_close"
                    }
                });
            html.clickToCopy.init($content, true);
        });
        if (html.clickToCopy.test()) {
            html.clickToCopy.init();
        }
    }
    that.show = () => {
        
        // if (!ee.isLive() && !ee.isReseller()) {
            return ee.initNextGen();
            
        // }
    //     if (!ee.session.can.Export) {
    //         ee.goTo("#/dashboard");
    //         return;
    //     }
    //     ee.indiOn();
    //     ee.frame.content.empty();
    //     _exportList = dataModel.create("ExportList");
    //     return EE_API.Export.List()
    //     .then(data => {
    //         _exportList.update(data)
    //         _render();
    //         _events();
    //         ee.indiOff();
    //     })
    //     .catch((e) => {
    //         html.modal.error(e);
    //     })
    };
    return that;

})();
