(function (root) {
    "use strict";

    var defaultOpt = {
        target: '.gallery-item',
        height: 850,
        width: 860,
        background: "#FFF",
        format: 'image/jpeg',
        quality: 0.3,
        canvasheight: false,
        canvaswidth: false
    };

    const delay = (t, v)  => {
        return new Promise(function(resolve) { 
            setTimeout(resolve.bind(null, v), t)
        });
    };

    let that = {
            getCanvasFromIframe: function($iframe, options, promise) {
                const savedIframe = $iframe;
                const doc = savedIframe[0].contentWindow.document;
                return delay(1000)
                .then(() => {
                    const body = $(doc.body)[0] || {};

                    savedIframe.contents().find('img').on("error", function () {
                        $(this).off("error").replaceWith(html.getraw("ImageNotFound"));
                    });
                    const bodyhtml = doc.body.parentElement.outerHTML;
                    return this.generate(bodyhtml, options);
                })
            },

            generate: function (bodyhtml, options) {
                var promise = $.Deferred();
                setTimeout(function () {
                    try {
                        var $body;
                        var doc;
                        var $iframe = $('<iframe id="eiframe' + html.generateUniqueId(12) + '" class="thumbali_widget" style="z-index:-1000;position:absolute;left:-1000px;top:-1000px;opacity:0;width:' + options.width + 'px;height:' + options.height + 'px;background: #FFF"></iframe>');
                        $('body').append($iframe);
                        $iframe.on('load', function () {
                            try {
                                html2canvas(doc.body, {
                                    async: true,
                                    height: options.height,
                                    width: options.width,
                                    background: $body.css("background-color"),
                                    useCORS: true,
                                    imageTimeout: 0,
                                    logging: false,
                                    //allowTaint: true //setting this to true sets canvas to tainted in Drag&Drop editor, disallowing canvas from working properly
                                }).then(function (canvas) {
                                    //FORCED BACKGROUND COLOR
                                    $iframe.remove();
                                    var context = canvas.getContext('2d');
                                    context.getImageData(0, 0, options.width, options.height);
                                    context.globalCompositeOperation = "destination-over";
                                    context.fillStyle = options.background;
                                    context.fillRect(0, 0, options.width, options.height);
                                    promise.resolve(canvas.toDataURL(options.format, options.quality));
                                }).catch(function (e) {
                                    $iframe.remove();
                                    promise.reject(e.message);
                                });
                            } catch (e) {
                                $iframe.remove();
                                promise.reject(e.message);
                            }
                        });
                        doc = $iframe[0].contentWindow.document;
                        doc.open();
                        doc.write(bodyhtml);
                        $body = $(doc.body);
                        $body.css({ margin: 0, height: options.height });
                        $iframe.contents().find('img').on("error", function () {
                            $(this).off("error").replaceWith(options.brokenimage);
                        });
                        doc.close();
                    } catch (e) {
                        $iframe.remove();
                        promise.reject(e);
                    }
                }, 0);
                return promise;
            }

        }
    root.Thumbnail = that;
}(html.widget));
