(function (root) {
    let editor;
        let isSavingForm = true;
        let autosaveTimerID;
        let screen;
        let form = ''; let $form; let formid;
        let isNewFormCreated;
        let isGalleryFormCreated;
        let isCopyCreated;
        let stepsTracker;
        let lastSavedForm;
        let shouldSaveForm;
        let isFirstSave;
        let previousName;
        let formLink;
        const _init = function () {
            isNewFormCreated = !ee.data.selectedform;
            isGalleryFormCreated = window.location.href.indexOf("gallery") >= 0;
            isCopyCreated = window.location.href.indexOf("forms/copy") >= 0;
            isFirstSave = true;
            shouldSaveForm = false;
            formLink = (!isNewFormCreated && !isGalleryFormCreated && !isCopyCreated) ? ee.data.selectedform.link : '';

            if (!!ee.data.lists) {
                delete ee.data.lists;
            }
            if (!!ee.data.availablefields) {
                delete ee.data.availablefields;
            }
            const Lists = dataModel.create("Lists");
            const ContactFields = dataModel.create("ContactFields");

            const isVerifyCodeAppended = $(ee.data.selectedform.html).find("#EWFVerifyEmailsCode").length > 0;
            const isAccessTokenPassed = !!$(ee.data.selectedform.html).find('input[name="publicaccountid"]').attr("data-access-token");
            const accessToken = !isAccessTokenPassed ? null : $(ee.data.selectedform.html).find('input[name="publicaccountid"]').val();
            /* Just a quick solution to hide a feature from #EE-783 until it gets tested */
            const isUsersPlanUnlimited = false/* ee.data.account.pricingplan === 7 || ee.data.account.pricingplan === 8 */;
            const isReseller = ee.data.account.enableprivatebranding;
            const isResellersSub = ee.data.account.parentcompanyname !== "Elastic Email";
            const isResellerOrResellersSub = isReseller || isResellersSub;
            const legacyMode = ee.parameters.legacyMode;
            // const productUrl = ee.productCode === 'EmailApi' ? '/api' : '/marketing';
            const breadcrumbUrl = `${legacyMode ? '' : '#'}/forms/new`;

            ee.frame.content.empty();
            if (typeof CodeMirror === "undefined") {
                $.cachedScript(`${ee.tools.getLibPath()}lib/codemirror.js?version=${  ee.libVersion.codeMirror}`, { async: false, success () { ee.view.show(); } });
                return;
            }

            if (!ee.data.templates) {
                ee.api.loadTemplates();
                return;
            }
            //check form by formid and load content if possible
            screen = html.get("NewWebForm", {
                account: ee.data.account,
                templates: ee.data.templates.getAll(),
                webform: ee.data.webform,
                form: ee.data.selectedform,
                formLink,
                admin: ee.session.can.AdminView,
                formid,
                pricing2018: ee.data.account.pricing2018,
                fontSizeList: html.getraw("WebFormFontSizeList"),
                fontWeightList: ["normal", "bold"],
                fontStyleList: ["normal", "italic"],
                borderRadiusList: html.getraw("WebFormBorderRadiusList"),
                btnWidthList: html.getraw("WebFormBtnWidthList"),
                textAlignmentList: html.getraw("WebFormTextAlignmentList"),
                paddingList: html.getraw("WebFormPaddingList"),
                fontFamilyList: html.getraw("eListFontFamily"),
                isVerifyCodeAppended,
                isAccessTokenPassed,
                accessToken,
                isUsersPlanUnlimited,
                isResellerOrResellersSub,
                isReseller,
                breadcrumbUrl, 
                legacyMode
            });
            screen.appendTo(ee.frame.content);

            $('#egobackbreadcrumb').on('click',function(e){
                if(!legacyMode || !window?.parent) return;
                e.preventDefault();
                e.stopPropagation();
                
                    const event = new CustomEvent('navigateBack', { detail: {breadcrumbUrl} })
                    window.parent.document.dispatchEvent(event)
                
            });
           
            // Undo/Redo functionality
            const initStepsTracker = () => {
                stepsTracker = {
                    step: -1,
                    steps: []
                }

                const $undo = screen.find("#webformsUndo");
                const $redo = screen.find("#webformsRedo");

                const isUndoPossible = () => stepsTracker.step > 0
                    && stepsTracker.step <= (stepsTracker.steps.length - 1);

                const isRedoPossible = () => stepsTracker.step < (stepsTracker.steps.length - 1);

                const setButtonsClasses = () => {
                    if (isUndoPossible()) {
                        $undo.removeClass("disabled");
                    } else {
                        $undo.addClass("disabled")
                    }

                    if (isRedoPossible()) {
                        $redo.removeClass("disabled");
                    } else {
                        $redo.addClass("disabled");
                    }
                }

                const setButtonsListeners = () => {
                    if (isUndoPossible()) {
                        $undo.off()
                        $undo.on("click", () => {
                            setStep(stepsTracker.step - 1);
                            isSavingForm = true;
                        });
                    } else {
                        $undo.off();
                    }

                    if (isRedoPossible()) {
                        $redo.off()
                        $redo.on("click", () => {
                            setStep(stepsTracker.step + 1);
                            isSavingForm = true;
                        });
                    } else {
                        $redo.off();
                    }
                }

                const disableAllActions = () => {
                    $undo.off();
                    $redo.off();
                    $undo.addClass("disabled")
                    $redo.addClass("disabled");
                }

                const addStep = (stepObject) => {
                    if (_.isEqual(_.omit(stepsTracker.steps[stepsTracker.step], 'name'), _.omit(stepObject, 'name')) || _.isEqual(_.omit(stepsTracker.steps[stepsTracker.step - 1], 'name'), _.omit(stepObject, 'name')) || _.isEqual(_.omit(stepsTracker.steps[stepsTracker.step + 1], 'name'), _.omit(stepObject, 'name')) ) {
                        setButtonsClasses();
                        setButtonsListeners();

                        return;
                    }

                    const stepsLength = stepsTracker.steps.length;

                    if (stepsTracker.step < (stepsLength - 1)) {
                        stepsTracker.steps.splice(stepsTracker.step + 1, 0, stepObject);
                        stepsTracker.step++;
                        setButtonsClasses();
                        setButtonsListeners();

                        return;
                    }
                    
                    stepsTracker.steps.push(stepObject);
                    stepsTracker.step++;
                    setButtonsClasses();
                    setButtonsListeners();
                }

                const addInitialStep = (stepObject) => {
                    const form = {
                        ispublic: false,
                        scope: stepObject.scope,
                        screenshot: '',
                        screenshotpath: '',
                        mediumscreenshotpath: '',
                        thumbnailpath: '',
                        dateadded: '',
                        name: stepObject.name,
                        html: stepObject.html,
                        css: stepObject.css,
                        isInitial: true,
                    };

                    stepsTracker.steps.push(form);
                    stepsTracker.step++;
                    setButtonsClasses();
                    setButtonsListeners();
                }

                const setStep = (stepNumber, initialSetStep=false) => {
                    if (stepNumber === stepsTracker.step) {
                        return;
                    }

                    let $formHtml;

                    currentStep = stepsTracker.steps[stepNumber];

                    stepsTracker.step = stepNumber;
                    ee.data.selectedform = stepsTracker.steps[stepNumber];

                    $formHtml = $(ee.data.selectedform.html);
                    $(ee.data.selectedform.css).appendTo($formHtml.find("#ewf_style"));

                    $formHtml.checkboxes = $($formHtml.find('div.checkboxes'));
                    $formHtml.inputs = $($formHtml.find('div.inputs:first'));
                    $formHtml.lists = $($formHtml.find('div.webform-lists:first'));

                    isSavingForm = true;
                    disableAllActions();
                    _loadCustomForm(true);
                    setButtonsClasses();
                    setButtonsListeners();
                    isSavingForm = false;

                    saveForm(!initialSetStep);
                }

                stepsTracker.$undo = $undo;
                stepsTracker.$redo = $redo;
                stepsTracker.isUndoPossible = isUndoPossible;
                stepsTracker.isRedoPossible = isRedoPossible;
                stepsTracker.setButtonsClasses = setButtonsClasses;
                stepsTracker.setButtonsListeners = setButtonsListeners;
                stepsTracker.addStep = addStep;
                stepsTracker.addInitialStep = addInitialStep;
                stepsTracker.setStep = setStep;
                stepsTracker.disableAllActions = disableAllActions;

                setButtonsClasses();
                setButtonsListeners();

                ee.data.stepsTracker = stepsTracker;
            }

            ee.tools.validateEntityName(
                {
                    screen,
                    inputname: '#ename',
                    submit: "#esave"
                }
            )

            const formNameH4 = screen.find(".form-name");
            const formNameText = screen.find(".js-form-name");
            const formNameInput = screen.find("#ename");
            const formNameEdit = screen.find(".js-edit-form-name");
            formNameEdit.on("click", function() {
                formNameH4.addClass("hidden");
                formNameInput.removeClass("hidden");
                formNameEdit.addClass("clicked")
                formNameInput.focus();
            });
            formNameInput.blur(function() {
                if (!$(this).hasClass('incorrect_input_value')) {
                    formNameInput.addClass("hidden");
                    formNameH4.removeClass("hidden");
                    formNameText.text(formNameInput.val());
                }
            });
            
            // Init Fields tab
            ContactFields.require()
            .then(function (data) {
                data.data.map((el) => {
                    const form = $(editor.getValue());
                    const fieldName =  el.consenttracking ? `[name=${el.name}]` : `[name=field_${el.name}]`;
                    const number = el.consenttracking ? 1 : 0;
                    el.required = form.find(fieldName)?.[number]?.required === true;
                  
                    return el
                });

                _showCustomFieldsList(data, screen.choosefieldslist);
            });

            Lists.require().then(function () {
                _preSelectOptions();
            });

            //ReturnUrl input
            screen.FormReturnUrl.off().on('change blur', function () {
                _updateSettings('name', 'returnUrl', screen.FormReturnUrl, attributesColl);
            });

            //Confirmation url input
            screen.FormActivationReturnUrl.off().on('change blur', function () {
                _updateSettings('name', 'activationReturnUrl', screen.FormActivationReturnUrl, attributesColl);
            });

            //AlreadyActiveReturnUrl Change
            screen.FormAlreadyActiveUrl.off().on('change blur', function () {
                _updateSettings('name', 'alreadyactiveurl', screen.FormAlreadyActiveUrl, attributesColl);
            });

            //ActivationReturnUrl Change
            screen.FormActivationTemplate.off().on('change blur', function () {
                _updateSettings('name', 'activationTemplate', screen.FormActivationTemplate, attributesColl);
            });
            if (ee.data.account.enablecontactfeatures) {

                screen.allowselectinglist.off().on('change blur', function () {
                    _visibleOptionalLists(!this.checked);
                });

                screen.notifyEmail.off().on('change blur', function () {
                    _updateSettings('name', 'notifyEmail', screen.notifyEmail, attributesColl);
                });
            }

            // Fields tab: open accordion panel
            screen.choosefieldslist.on("click", ".customfieldpanel", function (e) {
                const $that = $(this);
                    const $icon = $that.find('i');

                if ($icon.hasClass('fa-caret-down')) {
                    $that.next().show();
                    $icon.removeClass('fa-caret-down');
                    $icon.addClass('fa-caret-up');
                } else {
                    $that.next().hide();
                    $icon.removeClass('fa-caret-up');
                    $icon.addClass('fa-caret-down');
                }
            });
            //Fields tab: open properties
            screen.choosefieldslist.on("click", ".propertiesOpen", function (e) {
                const $that = $(this);
                    const $icon = $that.find('i');

                if ($icon.hasClass('fa-caret-down')) {
                    $that.parent().next().show();
                    $icon.removeClass('fa-caret-down');
                    $icon.addClass('fa-caret-up');
                } else {
                    $that.parent().next().hide();
                    $icon.removeClass('fa-caret-up');
                    $icon.addClass('fa-caret-down');
                }
            });
            //Fields tab: change description
            screen.choosefieldslist.on('keyup', '#edescriptionProperties', function () {
                let value;
                if ($(this).val() === '') {
                    value = $(this).data('description').trim();
                } else {
                    value = $(this).val().trim();
                }
                _setProperties('description', $(this).data('description'), value);
            });
            //Fields tab: change title
            screen.choosefieldslist.on('keyup change', '#etitleProperties', function () {
                _setProperties('title', $(this).data('titlefield'), $(this).val());
            });
            //Fields tab: change placeholder
            screen.choosefieldslist.on('keyup change', '#eplaceholderProperties', function () {
                _setProperties('placeholder', $(this).data('placeholder'), $(this).val());
            });
            //change reqired param
            screen.choosefields.on("change", ".requiredLabel input", function (e) {
                e.stopPropagation();
                const check = $(this);
                const name = check.data("requiredfield");
                const $form = $(editor.getValue());

                if (check.prop('checked')) {
                    if (name === "consenttracking") {
                        $form.find(`#${  name}`).prop('required', true);
                        //TODO: Add style for .requiredInput!!!!!!!!!!! (red asterisk after label and legend for this at the bottom of the form)
                        $form.find(`#${  name}`).parent().addClass('requiredInput');
                    } else {
                        $form.find(`input[name="field_${  name  }"]`).prop('required', true);
                        $form.find(`input[name="field_${  name  }"]`).parent().addClass('requiredInput');
                    }
                    screen.find(`.js-checkbox__required-indicator--${  name}`).show();
                } else {
                    if (name === "consenttracking") {
                        $form.find(`#${  name}`).prop('required', false);
                        $form.find(`#${  name}`).parent().removeClass('requiredInput');
                    } else {
                        $form.find(`input[name="field_${  name  }"]`).prop('required', false);
                        $form.find(`input[name="field_${  name  }"]`).parent().removeClass('requiredInput');
                    }
                    screen.find(`.js-checkbox__required-indicator--${  name}`).hide();
                }
                _updateRaw($form);
            });
            /*-- Choose fields to include in form --*/
            screen.choosefields.on("change", ".mainlabel input", function (e) {
                e.stopPropagation();
                const check = $(this);
                const required = (screen.find(`#required_${  check.prop("name")}`)[0].checked ? "required" : '');
                const $titlefield = screen.find(`input[data-titlefield="${  check.prop("name")  }"]`);
                const placeholder = screen.find(`textarea[data-placeholder="${  check.prop("name")  }"]`).val();
                const description = screen.find(`textarea[data-description="${  check.prop("name")  }"]`).val();
                let title;
                
                if (!$titlefield.val()) $titlefield.val($titlefield.data('titlefield'));

                title = $titlefield.val();


                if (!(check.prop('checked'))) {
                    if (check[0].value === "consenttracking") {
                        _removeFormConsent(check);
                    } else {
                        _removeInput(check);
                    }
                    check.closest('.webforms__custom-field').find('.propContainer').find('input,textarea').attr('disabled', 'disabled');
                } else {
                    if (check.attr("name") === "consenttracking") {
                        _addFormConsent(check.val(), required);
                    } else if (check.data("type") === 'boolean') {
                        _addBoolean(description, check.attr("name"), required);
                    } else if  (check.data("type") === 'datetime') {
                        _addInput(title, check.attr("name"), "datetime-local", "dd.mm.yyyy HH:mm", required, {"class": "datetime-text", "pattern": "([0-9]{2}.[0-9]{2}.[0-9]{4} [0-9]{2}:[0-9]{2})"}); //datetime local, with fallback to text input
                    }else if  (check.data("type") === 'date') {
                        _addInput(title, check.attr("name"), "date", "dd.mm.yyyy", required, {"class": "datetime-text", "pattern": "([0-9]{2}.[0-9]{2}.[0-9]{4})"}); //datetime local, with fallback to text input
                    } else {
                        _addInput(title, check.attr("name"), (check.data('type') || "text"), placeholder, required, '', check.data('length'));
                    }
                    check.closest('.webforms__custom-field').find('.propContainer').find('input,textarea').removeAttr('disabled');
                }
            });

            screen.preview.on("change", ".webform-lists input", function (e) {
                const that = $(this);
                    const value = that[0].value;
                    const $form = $(editor.getValue());

                if (that[0].checked) {
                    $form.find(`.webform-lists input[value=${  value  }]`).attr('checked', true);
                } else {
                    $form.find(`.webform-lists input[value=${  value  }]`).removeAttr('checked');
                }
                _updateRaw($form);
            });
            //Add field
            if (screen.managecontactfields) {
                screen.managecontactfields.click(function () {
                    const contactFields = dataModel.create("ContactFields");
                    const $selectedFields = $(".js-webform__field-checkbox:checked");

                    contactFields.require()
                        .then(function () {
                            html.widget.ContactFieldsUI.init(function () {
                                _showCustomFieldsList(contactFields, screen.choosefieldslist);

                                $selectedFields.each((index, checkbox) => {
                                    $(`#${  checkbox.id}`).prop('checked', true);
                                    $('.webforms__custom-field .mainlabel input:checked')?.closest('.webforms__custom-field')?.find('.propContainer')?.find('input,textarea')?.removeAttr('disabled');
                                });
                            });
                        });
                });
            }

            screen.enableCaptcha.click(function () {
                const $form = $(editor.getValue());
                _updateRaw($form);
            });
            screen.sendActivation.click(function () {
                const $form = $(editor.getValue());
                _updateRaw($form);
            });
            //Form tab: disclaimer textarea
            screen.formsettings.on("input", "#edatadisclaimermsg", function () {
                const $form = $(editor.getValue());
                let descContainer = $form.find('#ewf_datadisclaimer');

                if (!descContainer.length) {
                    descContainer = $("<p id='ewf_datadisclaimer'></p>");
                    $form.find(".webform-custominputs").append(descContainer);
                }

                descContainer.html($(this).val());

                _updateRaw($form);
            });
            //Form tab: change submit button text
            screen.formsettings.on('keyup change', '#webforms__button-text-input', function () {
                _setProperties("submit", "submit", $(this).val());
            });
            //Fields tab: consent tracking
            screen.customfields.on("change", ".consenttracking_description", function () {
                const $consenttracking = screen.customfields.find('#econsenttracking');
                if ($consenttracking.is(':checked')) {
                    _removeFormConsent($consenttracking);
                    const required = screen.customfields.find('#required_consenttracking').is(':checked') ? "required" : '';
                    _addFormConsent($consenttracking.val(), required);
                };

            });

            //Publish tab: copy html button
            screen.copyhtmlcode.on("click", function () {
                screen.webformcopy.text(editor.getValue());
            });
            html.clickToCopy.init();

            /*-- EDITOR EVENTS ---*/
            editor = CodeMirror.fromTextArea(screen.rawcode[0], {
                mode: 'htmlmixed',
                tabMode: 'indent',
                width: "100%",
                lineWrapping: true,
                autofocus: true,
                readOnly: true,
                maxHighlightLength: 0
            });
            let rawdelay;
            editor.off("changes");
            editor.on("changes", function (cm) {
                saveForm(shouldSaveForm);
                const form = editor.getValue();

                _updatePreview(form);
            });
          
            const htmlEditButton = screen.find("#editWebform");
            const isWarningConfirmed = Cookies.get("htmlEditWarningConfirmed");
            const $codeEditorWrapper = screen.find("#ewebformoutput");

            htmlEditButton.on("click", function(e) {
                if (isWarningConfirmed) {
                    editor.setOption("readOnly", false);
                    editor.setOption("maxHighlightLength", 10000);
                    $codeEditorWrapper.removeClass("webforms__editor-wrapper--read-only");
                } else {
                    // Inform user about HTML editing risk
                    html.modal.confirm(
                        "Making changes directly to the HTML code can cause the form to no longer work properly are you sure you want to continue?", "Edit HTML",
                        function () {
                            Cookies.set("htmlEditWarningConfirmed", true, { expires: 1 });
                            editor.setOption("readOnly", false);
                            editor.setOption("maxHighlightLength", 10000);
                            $codeEditorWrapper.removeClass("webforms__editor-wrapper--read-only");
                        },
                        function () {
                        },
                        { confirmTitle: "Yes", confirmCSS: "btn-danger btn_lg_basic" }
                    );
                }
            });

            //Temporary improvment TODO: Rebuild tabs styles or bind events on li insted a
            screen.webformnav.on('click', 'li', function (e) {
                if (e.target !== this) return;
                $(this).find('a').trigger("click");
            });

            screen.webformnav.find('a[data-toggle="tab"]')
            .on('shown.bs.tab', function (e) {
                e.target; // newly activated tab
                e.relatedTarget; // previous active tab
                if (e.target.id === 'ehtmlbutton') {
                    editor.refresh();
                    $('.CodeMirror-scroll').scrollTop(0);
                }

            });

            // Default tab on screen load
            screen.webformnav.find('a#eformsettingsbutton').trigger('click');

            //Start form editor
            if (ee.data.selectedform) {
                const newSelectedForm = ee.data.selectedform;
                _loadCustomForm();
                initStepsTracker();
                if (isGalleryFormCreated || isCopyCreated) {
                    let datecheck = /\d{4}-[01]\d-[0-3]\d [0-2]?\d:[0-5]\d:[0-5]\d/
                    let copyname = datecheck.test(ee.data.selectedform.name) ? ee.data.selectedform.name.replace(datecheck, moment().format('YYYY-MM-DD h:mm:ss')) : ee.data.selectedform.name + " " + moment().format('YYYY-MM-DD h:mm:ss');
                    const formName = isCopyCreated ? copyname : `${ee.t.newform} ${moment().format('YYYY-MM-DD h:mm:ss')}`;
                    newSelectedForm.name = formName;
                    $("#ename").val(formName);
                    $(".js-form-name").html(formName);
                }
                stepsTracker.addInitialStep(newSelectedForm);
                stepsTracker.setStep(0, true);
                isSavingForm = false;
                shouldSaveForm = true;
            } else {
                _loadDefaultForm();
                initStepsTracker();
                stepsTracker.addInitialStep({
                    html: $(editor.getValue()),
                    css: $(editor.getValue()).find('#ewf_style').html(),
                    name: screen.name.val(),
                    scope: screen.scope ? screen.scope.prop('checked') ? 1 : 0 : 0,
                });
                stepsTracker.setStep(0, true);
                isSavingForm = false;
                shouldSaveForm = true;
            }

            var attributesColl = _getAttributesOpt();

            //Form tab: Inline/Modal select
            screen.formsettings.find("#eformposition").on('change', function () {
                const $form = $(editor.getValue());
                _setStyles($form);
                _updateRaw($form);
            });
            //Form tab: delay input (for Modal option selected above)
            screen.formsettings.find("#eformdelay").on('change', function () {
                const $form = $(editor.getValue());
                _setStyles($form);
                _updateRaw($form);
            });

            //Form tab: title & description inputs
            screen.formsettings.on("input", "#eformtitle, #eformdescription", function () {
                const $form = $(editor.getValue());
                _setHeaders($form);
                _updateRaw($form);
            });

            //lock/unlock delay on non-static display
            screen.formsettings.find('#eformposition').val() === "Inline" ? screen.formsettings.find('#eformdelaycontainer').hide() : screen.formsettings.find('#eformdelaycontainer').show();

            // Style tab: Init all inputs
            screen.style.find("input, select").on("input", function inputHandler() {
                changeElementStyle($(this));
            });

            // Init autosave on change/input
            $("#ename").on("input", e => {
                saveForm(true, true);
            });
            $("#ewebformscreen input[type=checkbox]").on("change", e => {
                saveForm(true);
            });

            $("#ewebformscreen input:not([type=checkbox])").on("input", e => {
                saveForm(true);
            });

            // Publish Tab: Init copy link button
            $(".js-webforms__copy-form-link").on("click", function (e) {
                e.preventDefault();

                const linkSpan = $(this).closest(".js-webforms__settings-controls").find(".js-webforms__form-link");
                ee.tools.copyTextToClipboard(linkSpan.text(), ee.t.webformlinkcopied);
            });

            // Form tab: init "Allow verifying emails" checkbox
            // $('#webform__verify-emails-checkbox').on("change", function (e) {
            //     // If user checked option
            //     if ($(this).is(":checked")) {
            //         // Warn there is a $ charge
            //         html.modal.confirm("This option causes charge. \nOne verification cost: $0.001", "Warning",
            //         () => {
            //             $(this).prop('checked', true);
            //             // Add Verify Emails script
            //             _updateRaw();
            //         },
            //         () => {
            //             $(this).prop('checked', false);
            //             // Remove Verify Emails script
            //             _updateRaw();
            //         }, { confirmTitle: ee.t.accept, confirmCSS: "btn_lg btn_destructive btn-danger" });
            //     } else {
            //         // Remove Verify Emails script
            //         _updateRaw();
            //     }
            // });

            // Form tab: init Access Token text input
            $("#webform__verify-emails-input").on("input", function () {
                const $form = $(editor.getValue());
                const value = $(this).val();
                const $publicAccountIdInput = $form.find('input[name="publicaccountid"]');

                if (value) {
                    $publicAccountIdInput.val(value);
                    $publicAccountIdInput.attr('data-access-token', true);
                } else {
                    $publicAccountIdInput.val(ee.data.account.publicaccountid);
                    $publicAccountIdInput.removeAttr('data-access-token');
                }

                _updateRaw($form);
            });

            // Quickfix so editor isn't all gray on first "HTML code" bar expand
            $(".js-webforms__refresh-code").on("click", function firstTimeEditorRefresh(e) {
                if (!$(this).hasClass("open")) {
                    setTimeout(() => {
                        editor.refresh();
                    }, 0);
                }
                // Remove this handler since this error occurs only once per screen
                $(this).off("click", firstTimeEditorRefresh);
            });

            _updateRaw(editor.getValue());
        };
        const addVerificationCode = function ($form) {
            const currentVerifyCode = $form.find("#EWFVerifyEmailsCode");
            const isVerifyCodeAppended = currentVerifyCode.length > 0;

            const verifyCode = html.getraw("WebformVerifyEmailCode", {
                apiPath: ee.api.path
            });

            if (isVerifyCodeAppended) {
                currentVerifyCode.remove();
            }

            $form.append(verifyCode);
        };
        const removeVerificationCode = function ($form) {
            const currentVerifyCode = $form.find("#EWFVerifyEmailsCode");
            const isVerifyCodeAppended = currentVerifyCode.length > 0;

            if (isVerifyCodeAppended) {
                currentVerifyCode.remove();
            }
        };
        var saveForm = function (canSaveForm, updateName=false) {
            if (!isSavingForm) {
                clearTimeout(autosaveTimerID);
                stepsTracker.disableAllActions();

                autosaveTimerID = setTimeout(() => {
                        if (canSaveForm) {
                            isSavingForm = true;
                            _saveWithScreenshot(updateName)
                            .then(savedForm => {
                                if (!savedForm) {
                                    stepsTracker.setButtonsClasses();
                                    stepsTracker.setButtonsListeners();
                                    isSavingForm = false;
                                    return;
                                }
                                savedForm.isUpdated = true;
                                const currFormIndex = _.findIndex(ee.data.forms.data, form => {
                                    return form.name === savedForm.name;
                                });
                                if (currFormIndex) {
                                    ee.data.forms.data[currFormIndex] = savedForm;
                                    ee.data.selectedform = savedForm;
                                }
                                if (!_.isEqual(_.omit(lastSavedForm, 'name'),_.omit(savedForm, 'name')) && !updateName) {
                                    stepsTracker.addStep(savedForm);
                                    lastSavedForm = savedForm;
                                }
                                stepsTracker.setButtonsClasses();
                                stepsTracker.setButtonsListeners();
                                isSavingForm = false;
                            })
                            .catch(err => {
                                isSavingForm = false;
                                console.err(err);
                            });
                        } else {
                            stepsTracker.setButtonsClasses();
                            stepsTracker.setButtonsListeners();
                        }
                }, 2000);
            }
        };
        var changeElementStyle = function ($elementInput, cssValue) {
            const $form = $(editor.getValue());
            // Get webform's controlled element name & css rule
            const inputControlsData = $elementInput.data("controls").split("_");
            let cssRule = inputControlsData[1];
            const controlledElementsName = inputControlsData[0];
            // Get current styles code from webform
            let styles = $form.find("style#webform__main-styles").text();

            let $colorpickerHexDisplay;
            let cssSelector;

            // Set precise selector & css rule
            if (controlledElementsName === "form") {
                switch (cssRule) {
                    case "font-size":
                    case "font-family":
                    case "color":
                    case "text-align":
                    case "line-height":
                    case "font-weight":
                    case "font-style":
                        cssSelector = "form.EWF__form p";
                        break;
                    default:
                        cssSelector = "form.EWF__form";
                        break;
                }
            } else if(controlledElementsName === "subscribe" && cssRule === "alignment") {
                // Subscribe button alignment is set through text-align of its paret
                cssSelector = ".EWF__form .webform-options";
                cssRule = "text-align";
            } else if(controlledElementsName === "input" || controlledElementsName === "label" || controlledElementsName === "a") {
                // Grab all inputs/labels
                cssSelector = `form.EWF__form ${  controlledElementsName}`;
            } else {
                // Grab one specific element
                cssSelector = `form.EWF__form .EWF__${  controlledElementsName}`;
            }

            const value = cssValue ? cssValue : $elementInput.val();

            switch (cssRule) {
                case "background":
                case "color":
                    $colorpickerHexDisplay = $elementInput.parent().parent().find(".js-colorpicker-box__hex");
                    $colorpickerHexDisplay.text(value);
                    if (controlledElementsName === "input") {
                        // Set inputs placeholder styles
                        styles = _addToStyles(styles, `${cssSelector}::placeholder`, cssRule, value);
                    }
                    break;
                case "font-size":
                    if (controlledElementsName === "input") {
                        // Set inputs placeholder styles
                        styles = _addToStyles(styles, `${cssSelector}::placeholder`, "font-size", `${Number(value.replace("px", "")) - 2  }px`);
                    }
                    styles = _addToStyles(styles, cssSelector, 'line-height', `${Number(value.replace("px", "")) + 4  }px`);
                    break;
                case "padding":
                case "border-radius":
                default:
                    break;
            }

            styles = _addToStyles(styles, cssSelector, cssRule, value);
            const styleTag = $form.find("style#webform__main-styles");
            styleTag.text(styles);
            _updateRaw($form);
        };
        // Function created for backwards compatibility
        const _addWebformsV2Classes = function($form) {
            if (!$form) {
                $form = $(editor.getValue());
            }

            if (!$form.find(".EWF__form").length) {
                $form.addClass("EWF__form");
            }

            if (!$form.find(".EWF__header").length) {
                $form.find("h4").addClass("EWF__header");
            }

            if (!$form.find(".EWF__subscribe").length) {
                $form.find("input[type='submit']").addClass("EWF__subscribe");
            }

            $form.find(".form-control").addClass("webform__form-control");
        };
        const removeElasticEmailClassNames = function ($form) {
            const ElasticEmailClassNameElements = $form.find('*[class*="ElasticEmail"]');
            // Remove class from FORM element
            if ($form[0].className.indexOf("ElasticEmail") >= 0) {
                $form.removeClass(_.find($form[0].classList, className => className.indexOf("ElasticEmail") >= 0));
            }
            // Remove class from inner elements
            if (ElasticEmailClassNameElements.length > 0) {
                _.forEach(ElasticEmailClassNameElements, el => {
                    $(el).removeClass(_.find(el.classList, className => className.indexOf("ElasticEmail") >= 0));
                });
            }
        };
        // Function created for backwards compatibility
        const addNewInputs = function ($form) {
            if (!$form) {
                $form = $(editor.getValue());
            }

            if (!$form.find("input[name=verifyemail]").length) {
                const $verifyEmailInput = $("<input>");
                $verifyEmailInput.attr("id", "ewf_verifyEmails");
                $verifyEmailInput.attr("type", "hidden");
                $verifyEmailInput.attr("name", "verifyemail");
                $verifyEmailInput.attr("value", false);

                $form.find(".webform-options").append($verifyEmailInput);
            }
        };
        const _rgb2hex = function (rgb) {
            rgb = rgb.match(/^rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\)$/);
            if (rgb) {
                return `#${  _hex(rgb[1])  }${_hex(rgb[2])  }${_hex(rgb[3])}`;
            }
            return "transparent";
        };
        var _hex = function (x) {
            const hexDigits = new Array ("0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f");
            return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
        };
        var _addToStyles = function (currentStyles, selector, rule, value) {
            const selectorIndex = currentStyles.indexOf(selector);
            let rulesEnd;
            let ruleIndex;
            let singleRuleEnd;
            let singleValueStart;

            if (selectorIndex >= 0) { // Add to existing selector
                rulesEnd = currentStyles.indexOf("}", selectorIndex);
                ruleIndex = currentStyles.indexOf(`${rule}:`, selectorIndex);
                // Check if rule already exists
                if ((ruleIndex >= 0) && (ruleIndex < rulesEnd)) { // Switch value
                    singleValueStart = currentStyles.indexOf(":", ruleIndex);
                    singleRuleEnd = currentStyles.indexOf(";", ruleIndex);
                    currentStyles = currentStyles.slice(0, singleValueStart + 1) + value + currentStyles.slice(singleRuleEnd);
                } else { // Add to the end of block
                    currentStyles = `${currentStyles.slice(0, rulesEnd) + rule  }: ${  value  };${  currentStyles.slice(rulesEnd)}`;
                }
            } else { // Add new selector
                currentStyles += `${selector  } {${  rule  }: ${  value  };}`;
            }

            return currentStyles;
        };
        var _initStylesTab = function($form, shouldntRefreshStylesTab) {
            if (!$form) {
                $form = $(editor.getValue());
            }

            const $styleWrapper = $form.find("#ewf_style");
            const $popupStyleTag = $styleWrapper.find("#ewf_popup");
            const $inputs = screen.style.find("input, select");
            const $selectInputs = screen.style.find(".select-input");
            const $dropDowns = screen.style.find(".dropdown-menu");
            let isFormStylesUpdated = $form.find(".webform-options").hasClass("style-updated");
            let newStyles = "";

            if (isFormStylesUpdated) {
                newStyles = $form.find("#webform__main-styles").text();
            }

            $form.find(".webform-options").addClass("style-updated");

            if (isNewFormCreated) {
                $styleWrapper.html(html.get("NewFormStyles"));
                newStyles = html.get("NewFormStyles").text() + html.get("DefaultFormStyles").text();
                isFormStylesUpdated = true;
                _updateRaw($form, false);
            }

            // Prefill all style inputs
            _.forEach($inputs, function(input) {
                const $input = $(input);
                const inputControlsData = $(input).data("controls").split("_");
                const controlledElementsName = inputControlsData[0];
                let cssRule = inputControlsData[1];
                const isElementLabel = controlledElementsName === "label"
                let $controlledElement = isElementLabel ? screen.find(".EWF__form label") : screen.find(`.EWF__${  controlledElementsName}`);
                let $controlledElementFormHtml;
                let cssSelector;

                if (isElementLabel) {
                    $controlledElementFormHtml = $form.find("label");
                    cssSelector = "form.EWF__form label";
                } else if (controlledElementsName === "form") {
                    $controlledElementFormHtml = $form;
                    switch (cssRule) {
                        case "font-size":
                        case "font-family":
                        case "color":
                        case "text-align":
                        case "line-height":
                        case "font-weight":
                        case "font-style":
                            cssSelector = "form.EWF__form p";
                            $controlledElement = screen.find(".EWF__form p");
                            break;
                        default:
                            cssSelector = "form.EWF__form";
                            break;
                    }
                } else if(controlledElementsName === "subscribe" && cssRule === "alignment") {
                    $controlledElementFormHtml = $form.find(".webform-options");
                    cssSelector = ".EWF__form .webform-options";
                    $controlledElement = screen.find(".EWF__form .webform-options");
                    cssRule = "text-align";
                } else if(controlledElementsName === "input" || controlledElementsName === "a") {
                    $controlledElementFormHtml = $form.find(`form.EWF__form ${controlledElementsName}`);
                    cssSelector = `form.EWF__form ${controlledElementsName}`;
                    $controlledElement = screen.find(`form.EWF__form ${  controlledElementsName}`);
                } else {
                    $controlledElementFormHtml = $form.find(`.EWF__${controlledElementsName}`);
                    cssSelector = `form.EWF__form .EWF__${controlledElementsName}`;
                }

                let currentStyle = $controlledElement.css(cssRule);

                let fontFamilyValue;
                let ruleValue;
                let firstRgbIndex;
                let rgbValue;
                let hexValue;
                let $colorpickerHexDisplay;

                switch (cssRule) {
                    case "font-weight":
                        if (currentStyle !== "normal" || currentStyle !== "bold") {
                            if (!currentStyle || currentStyle < 600) {
                                currentStyle = "normal";
                                ruleValue = currentStyle;
                                break;
                            }

                            currentStyle = "bold";
                            ruleValue = currentStyle;
                        }
                        break;
                    case "background":
                        firstRgbIndex = currentStyle.indexOf("rgb(");
                        // Check for rgb() value in possible bg properties
                        if (firstRgbIndex < 0) {
                            // Fix for Firefox
                            // since background-image returns "" for gradients
                            currentStyle = $controlledElement.css("background-image");
                            firstRgbIndex = currentStyle.indexOf("rgb(");
                            if (firstRgbIndex < 0) {
                                currentStyle = $controlledElement.css("background-color");
                                firstRgbIndex = currentStyle.indexOf("rgb(");
                            }
                        }

                        if (firstRgbIndex >= 0) {
                            rgbValue = currentStyle.slice(firstRgbIndex, currentStyle.indexOf(")", firstRgbIndex) + 1);
                            hexValue = _rgb2hex(rgbValue);
                            ruleValue = hexValue;
                        } else {
                            ruleValue = "transparent";
                        }
                        // Initialize colorpicker
                        $(`#${  $input.attr("id")}`).spectrum({
                            color: ruleValue,
                            preferredFormat: "hex",
                            showInitial: true,
                            showInput: true,
                            allowEmpty: true,
                            change(color) {
                                $input.val(color);
                                $input.trigger("input");
                            }
                        });
                        $input.show();
                        $input.on("change", () => {
                            $(`#${  $input.attr("id")}`).spectrum("set", $input.val())
                        });
                        break;
                    case "font-size":
                        if (controlledElementsName === "form") {
                            $controlledElement = screen.find(".EWF__form p")
                            $controlledElementFormHtml = $form.find("p");
                            currentStyle = $controlledElement.css(cssRule);
                        }

                        ruleValue = currentStyle;
                        if (controlledElementsName === "input") {
                            // Set inputs placeholder styles
                            newStyles = _addToStyles(newStyles, `${cssSelector}::placeholder`, "font-size", `${Number(ruleValue.replace("px", "")) - 2  }px`);
                        }
                        if (!ruleValue) {
                            ruleValue = '12px';
                        }
                        // Set line height 2px bigger than element's font size
                        newStyles = _addToStyles(newStyles, cssSelector, "line-height", `${Number(ruleValue.replace("px", "")) + 2  }px`);
                        break;
                    case "color":
                        // Grab all paragraphs inside form to set form's text color
                        if (controlledElementsName === "form") {
                            $controlledElement = screen.find(".EWF__form p")
                            $controlledElementFormHtml = $form.find("p");
                            currentStyle = $controlledElement.css(cssRule);
                        }

                        rgbValue = currentStyle;

                        if (controlledElementsName === "a" && !rgbValue) {
                            rgbValue = "rgb(84,87,255)";
                        }

                        hexValue = _rgb2hex(rgbValue);
                        ruleValue = hexValue;
                        if (controlledElementsName === "input") {
                            ruleValue = ruleValue === "transparent" || "#000000" ? "#000000" : ruleValue;
                            // Set inputs placeholder styles
                            newStyles = _addToStyles(newStyles, `${cssSelector}::placeholder`, "color", ruleValue);
                        }
                        // Initialize colorpicker
                        $(`#${  $input.attr("id")}`).spectrum({
                            color: ruleValue,
                            preferredFormat: "hex",
                            showInitial: true,
                            showInput: true,
                            allowEmpty: true,
                            change(color) {
                                $input.val(color);
                                $input.trigger("input");
                            }
                        });
                        $input.show();
                        $input.on("change", () => {
                            $(`#${  $input.attr("id")}`).spectrum("set", $input.val())
                        });
                        break;
                    case "padding":
                        ruleValue = currentStyle.match(/(\d+px)*/)[0];
                        break;
                    case "text-align":
                        ruleValue = currentStyle === "start" ? "left" : currentStyle;
                        break;
                    case "border-radius":
                        if (currentStyle === "") {
                            // Fix for firefox since it hasn't generic border-radius
                            currentStyle = $controlledElement.css("borderBottomLeftRadius");
                        }
                        ruleValue = currentStyle;
                        break;
                    case "font-family":
                        fontFamilyValue = !currentStyle ? "inherit" : currentStyle.split(',')[0].replace(new RegExp('"', "g"), "").replace(new RegExp("'", "g"), "").trim();
                        ruleValue = !currentStyle ? "inherit" : currentStyle;
                        break;
                    default:
                        ruleValue = currentStyle;
                        break;
                }
                $input.val(fontFamilyValue ? fontFamilyValue : ruleValue);

                newStyles = _addToStyles(newStyles, cssSelector, cssRule, ruleValue);

                // Display hex next to colorpicker
                if (cssRule === "color" || cssRule === "background") {
                    $colorpickerHexDisplay = $input.parent().parent().find(".js-colorpicker-box__hex");
                    $colorpickerHexDisplay.text(ruleValue);
                }
            });

            // Nullify form headers margin
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__header", "margin", "0");
            // Normalize paragraphs margin
            newStyles = _addToStyles(newStyles, "form.EWF__form p", "margin", "12px 0");
            // Remove subscribe buttons border
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__subscribe", "border", "0");
            // Set buttons display to inline-block so alignment works
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__subscribe", "display", "inline-block");
            // Set buttons width to auto
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__subscribe", "width", "auto");
            // Buttons max width
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__subscribe", "max-width", "100%");
            // Buttons cursor
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__subscribe", "cursor", "pointer");
            newStyles = _addToStyles(newStyles, "form.EWF__form .EWF__subscribe:disabled", "cursor", "default");
            // Add margin bottom to each input for backwards compatibility
            newStyles = _addToStyles(newStyles, "form.EWF__form .inputs > div", "margin-bottom", "1rem");
            // Remove border from inputs
            newStyles = _addToStyles(newStyles, "form.EWF__form input", "border", "0");
            // Make inputs width 100%
            newStyles = _addToStyles(newStyles, "form.EWF__form input", "width", "100%");
            newStyles = _addToStyles(newStyles, "form.EWF__form input", "max-width", "100%");
            newStyles = _addToStyles(newStyles, "form.EWF__form input", "display", "block");
            newStyles = _addToStyles(newStyles, "form.EWF__form", "box-sizing", "border-box");
            newStyles = _addToStyles(newStyles, "form.EWF__form *", "box-sizing", "border-box");
            // Make labels width 100%
            newStyles = _addToStyles(newStyles, "form.EWF__form label", "display", "block");
            newStyles = _addToStyles(newStyles, "form.EWF__form label", "margin-bottom", "5px");
            newStyles = _addToStyles(newStyles, "form.EWF__form fieldset", "display", "block");
            // Remove padding from fieldset
            newStyles = _addToStyles(newStyles, "form.EWF__form fieldset", "padding", "0");
            // Remove margin from fieldset
            newStyles = _addToStyles(newStyles, "form.EWF__form fieldset", "margin", "0");
            
            if (!newStyles.includes('@supports (-ms-ime-align')) {
                newStyles += "@supports (-ms-ime-align:auto) {form.EWF__form .checkboxes input[type='checkbox'], form.EWF__form .consent input[type='checkbox'] {width: 16px !important; margin-right: 5px;}}";
            }
            
            // Add checkbox styles
            if (!isFormStylesUpdated) {
                newStyles += html.get("DefaultFormStyles").text();
            }

            // Handle dropdowns
            $selectInputs.off();
            $selectInputs.on("click", function(e) {
                if (e.target.nodeName === "BUTTON" || e.target.nodeName === "SPAN") {
                    const $this = $(this);
                    const $dropDown = $this.find(".dropdown-menu");

                    if ($dropDown.hasClass("open")) {
                        $dropDown.removeClass("open");
                    } else {
                        $dropDown.addClass('open');
                    }
                }
            });
            $selectInputs.on("blur", function(e) {
                // Hide all .dropdown-menu
                const $this = $(this);
                const $dropDown = $this.find(".dropdown-menu");
                $dropDown.removeClass("open");
            });
            $dropDowns.off();
            $dropDowns.on("click", function(e) {
                if (e.target.nodeName !== "LI") {
                    return;
                }

                const $this = $(this);

                if ($(e.target).hasClass("dseparator")) {
                    $this.removeClass("open");
                    return;
                }

                const isFontFamilyDropDown = $this.hasClass('js-webforms__font-family-dropdown');
                const $target = $(e.target);
                const value = $target.data("value");
                const $input = $target.parent().prev().prev();

                changeElementStyle($input, value);

                $input.val(value);
                saveForm(true);

                if (isFontFamilyDropDown) {
                    $input.val($target.text());
                }

                $this.removeClass("open");
            });

            // Attach categories collapse handler on styles tab
            const $styleControls = $(".js-webforms__settings-controls");
            const $styleControlsTitles = $(".js-webforms__settings-drop-down");

            if (!shouldntRefreshStylesTab) {
                $styleControls.slideUp();
                // Open first tab
                $(".js-webforms__settings-drop-down.open + .js-webforms__settings-controls").slideDown();
            }
            // Style panels sliding
            $styleControlsTitles.off();
            $styleControlsTitles.on("click", function() {
                const $this = $(this);
                const isHtmlCode = $this.data("ishtmlcode");
                const $thisIcon = $this.find("i");
                const isThisOpened = $(this).hasClass("open");
                let $currentlyOpened;
                let $currentlyOpenedIcon;

                if (isThisOpened) {
                    $this.removeClass("open");
                    $this.next(".js-webforms__settings-controls").slideUp();
                    $thisIcon.removeClass();
                    $thisIcon.addClass("fas fa-caret-down cursorpointer");

                } else {
                    $currentlyOpenedIcon = $(".js-webforms__settings-drop-down.open i");
                    $currentlyOpened = $(".js-webforms__settings-drop-down.open");
                    $currentlyOpened.removeClass("open");
                    $currentlyOpened.next(".js-webforms__settings-controls").slideUp();
                    $currentlyOpenedIcon.removeClass();
                    $currentlyOpenedIcon.addClass("fas fa-caret-down cursorpointer");

                    $this.addClass("open");
                    $this.next(".js-webforms__settings-controls").slideDown({
                        complete: () => {
                            $thisIcon.removeClass();
                            $thisIcon.addClass("fas cursorpointer fa-caret-up");
                            if (isHtmlCode) {
                                const $editorParent = $(editor.getWrapperElement()).parent();

                                editor.setSize($editorParent.innerWidth, $editorParent.innerHeight);
                            }
                        }
                    });
                }
            });

            // Remove old styles
            $styleWrapper.html("");

            if ($popupStyleTag.length > 0) {
                newStyles = _addToStyles(newStyles, "form.EWF__form", "box-shadow", "0px 0px 5px rgba(0, 0, 0, 0.13)");
                $("#eformposition").val("Modal").change();
                $("#eformdelay").val(getDelayValueFromStyles($popupStyleTag.html()));
                $styleWrapper.append($popupStyleTag)
            }

            $styleWrapper.prepend($(`<style id='webform__main-styles'>${  newStyles  }</style>`));

            removeElasticEmailClassNames($form);

            _updateRaw($form, false);
        };
        var getDelayValueFromStyles = function (stylesString) {
            const ruleStartIndex = stylesString.indexOf(":", stylesString.indexOf("ewfPopupDelay"));
            return Number(stylesString.substring(ruleStartIndex + 1, stylesString.indexOf(";", ruleStartIndex)).trim().replace("s", ""));
        };
        const _addCaptcha = function ($form) {
            $form.find('div#captcha').remove();
            $form.find('input#eesubmit').before(html.get("NewWebformCaptcha", { formid }, {}, true));
            $form.find('input#eesubmit').before('<script id="recaptchaScript" src="https://www.google.com/recaptcha/api.js?onload=grecaptchaready&render=explicit" async defer></script>');
            $form.find('input[type=submit]').prop('disabled', 'disabled');
            $form.find('input[name=captcha]').val("true");
        };
        const _removeCaptcha = function ($form) {
            $form.find('div#captcha').remove();
            $form.find('script#recaptchaScript').remove();
            $form.find('input[type=submit]').removeAttr("disabled");
        };

        const _toggleSendActivation = function ($form, value) {
            $form.find('input[name=sendActivation]').val(`${value}`);
        };

        const _addDismissForm = function ($form) {
            $form.find('div#ewf_style').before(html.get("WebFormDismiss", { formid }, {}, true));

        };
        var _showCustomFieldsList = function (fieldsDataModel, $container) {
            const fields = [];
            $container.empty();

            fieldsDataModel.each(function () {
                fields.push(this);
            });

            const $template = html.get('WebFormCustomFieldSections', $.extend({}, fields));

            if (fields.length) {
                html.get('WebFormCustomFields', { list: fields }).appendTo($template.find("#einputSection"));
            }

            $template.appendTo($container);
        };
        var _setProperties = function (field, name, value) {
            const $form = $(editor.getValue());
            let selector;

            switch (name) {
                case "email":
                case "submit":
                    selector = `input[name="${  name  }"]`;
                    break;
                case "consenttracking":
                    selector = `input#${  name}`;
                    break;
                default:
                    selector = `input[name="field_${  name  }"]`;
                    break;
            }

            switch (field) {
                case "title":
                    if ($form.find(selector).parent().is("label")) {
                        $form.find(selector).parent().contents().last()[0].textContent = value;
                    } else {
                        $form.find(selector).prev().text(value);
                    }
                    break;
                case "placeholder":
                    $form.find(selector).attr('placeholder', value);
                    break;
                case "submit":
                    $form.find(selector).val(value);
                    break;
                default:
                    $form.find(selector).parent().find('span').text(value);
                    break;
            }

            _updateRaw($form);
        };
        var _loadDefaultStyles = function () {
            const $form = $(editor.getValue());
            const ContactFields = dataModel.create("ContactFields");
            _setStyles($form);
            _setHeaders($form);
            _setPersonalDataDisclaimer($form);
            //select fields used in form
            $.when(ContactFields.require())
            .then(function (data) {
                _preSelectFields($form);
                const inputs = $('.webforms__custom-field .mainlabel input');
                inputs?.filter(':not(:checked)')?.closest('.webforms__custom-field')?.find('.propContainer')?.find('input,textarea')?.attr('disabled', 'disabled');
                inputs?.filter(':checked')?.closest('.webforms__custom-field')?.find('.propContainer')?.find('input,textarea')?.removeAttr('disabled');
            })
            .catch(function (err) { console.log(err); });

            _updateRaw($form, false);
        };
        const clearDismissCookie = () => {
            document.cookie = "ewf_storage=; expires=Sat, 01 Jan 2000 00:00:00 GMT";
        };
        var _setStyles = function ($form) {
            //lock/unlock delay on non-static display
            screen.formsettings.find('#eformposition').val() === "Inline" ? screen.formsettings.find('#eformdelaycontainer').hide() : screen.formsettings.find('#eformdelaycontainer').show();
            const delay = screen.formsettings.find('#eformdelay').val();

            //disable sticky form for bigger forms and hide lists on sticky forms and reset it if it was selected
            if ($form.find('.form-control').length > 1) {
                screen.formsettings.find('#eformposition option[value="Sticky"]').prop("disabled", true);
                screen.formsettings.find('#eformposition').val() === "Sticky" ? screen.formsettings.find('#eformposition').val("Inline").change() : "";
            } else {
                screen.formsettings.find('#eformposition option[value="Sticky"]').prop("disabled", false);
            }
            //check selected style
            let style = "";
            $form.removeClass();
            $form.addClass("EWF__form");
            $form.find("#ewf_style").removeClass();

            // Remove forms box shadow
            const $currStyles = $form.find("style#webform__main-styles");
            let currStyles = $currStyles.text();
            currStyles = _addToStyles(currStyles, "form.EWF__form", "box-shadow", "0px 0px 0px rgba(0, 0, 0, 0)");
            $currStyles.text(currStyles)

            const formPosition = screen.formsettings.find("#eformposition").val();
            style += html.getraw(`NewWebFormStyle${  formPosition}`, { delay, formid: formid ? formid : "" });
            $form.find("#ewf_style").addClass(formPosition);
            $form.addClass(formPosition);
            $form.find("#ewf_popup").remove();

            if (screen.formsettings.find('#eformposition').val() === "Modal") {
                currStyles = _addToStyles(currStyles, "form.EWF__form", "box-shadow", "0px 0px 5px rgba(0, 0, 0, 0.13)");
                $currStyles.text(currStyles);
                $form.find("#ewf_style").html($form.find("#ewf_style").html() + style);
            }
        };
        var _setPersonalDataDisclaimer = function ($form) {
            const disclaimerMsg = screen.formsettings.find("#edatadisclaimermsg").val();

            if (!$form.find("#ewf_datadisclaimer").length) {
                $form.find('.webform-custominputs').append('<p id="ewf_datadisclaimer"></p>');
            }
            if (disclaimerMsg) {
                $form.find("#ewf_datadisclaimer").html(disclaimerMsg)
            } else {
                screen.find("#edatadisclaimermsg").val(disclaimerMsg);
            }
            if (isNewFormCreated || isGalleryFormCreated) {
                let personalDataDisclaimer;
                const isReseller = ee.data.account.enableprivatebranding;
                const isResellersSub = ee.data.account.issub && ee.data.account.isownedbyreseller;
                const isResellerOrResellersSub = isReseller || isResellersSub;
                // Set personal disclaimer according to account type
                personalDataDisclaimer = isResellerOrResellersSub ? ee.t.form_resellers_disclaimer : ee.t.form_data_desc;
                if (isGalleryFormCreated && !isResellersSub) {
                    personalDataDisclaimer = isReseller ? ee.t.form_resellers_disclaimer : ee.t.form_data_desc;
                }
                $form.find("#ewf_datadisclaimer").html(personalDataDisclaimer)
                screen.find("#edatadisclaimermsg").val(personalDataDisclaimer);
            }
        };
        const _handleHeader = function ($form, id, text) {
            if (text) {
                $form.find(id).show();
                $form.find(id).text(text);
            } else {
                $form.find(id).hide();
                $form.find(id).text("");
            }
        };
        var _setHeaders = function ($form) {
            const title = screen.formsettings.find('#eformheadercontainer input').val();
            const description = screen.formsettings.find('#eformheadercontainer #eformdescription').val();

            if (title || description) {
                // Show container on the form
                $form.find('.headers').show();
                // Show/hide title
                _handleHeader($form, '#ewf_formheader', title)
                // Show/hide description
                _handleHeader($form, '#ewf_formdesc', description);
            } else {
                $form.find('.headers').hide();
            }
        };
        var _saveWithScreenshot = function (updateName) {
            const $form = $(editor.getValue());
            const saveInfoDiv = $(".js-webforms__saving-information");
            saveInfoDiv.text(ee.t.saving);

            if ((isNewFormCreated || isGalleryFormCreated || isCopyCreated) && isFirstSave) {
                const formCss = $(editor.getValue()).find('#ewf_style').html();
                const $formHTML = $(editor.getValue());
                const formInputName = screen.name.val();

                const query = {
                    // append current date to newly created form
                    formName: formInputName,
                    formScope: screen.scope ? screen.scope.prop('checked') ? 1 : 0 : 0, //public : private
                    formHtml: $formHTML[0].outerHTML,
                    css: formCss
                };

                return EE_API.Form.Add(query)
                .then((form) => {
                    ee.data.forms.data.unshift(form);
                    ee.data.selectedform = form;
                    isFirstSave = false;
                    formLink = form.link;
                    screen.webformslink.text(form.link);
                    screen.webformslinkpreview.attr("href", form.link);
                    _saveScreenshot(query);
                    return form;
                })
                .then(savedForm => {
                    let encodedname = ee.tools.encodeBase64Url(savedForm.name)
                    ee.changeHashQuietly(`#/forms/edit/${encodedname}`);
                    saveInfoDiv.text(ee.t.allchangessaved);
                    previousName = formInputName;
                    return savedForm;
                })
                .catch(error => {
                    console.log(error);
                    const legacyDraftsPath = `${ee.parameters.bootedAsChild ? '/marketing' : ''}/forms/new`;
                    if (isCopyCreated) {
                        if (ee.parameters.legacyMode) {
                            top.window.location.replace(legacyDraftsPath);
                        } else {
                            ee.goTo('#/forms/new');
                        }
                    } else if (isGalleryFormCreated) {
                        if (ee.parameters.legacyMode) {
                            top.window.location.replace(`${legacyDraftsPath}?tab=1`);
                        } else {
                            ee.goTo('#/forms/new?tab=1');
                        }
                    }
                });
            }

            // split whole HTML to HTML and style
            const formCss = $form.find('#ewf_style').html();
            $form.find('#ewf_style').html("");
            const formInputName = screen.name.val();
            const query = {
                formName: ee.data.selectedform ? previousName || ee.data.selectedform.name : formInputName ? formInputName : `${ee.t.newform  } ${  moment().format('YYYY-MM-DD h:mm:ss A')}`,
                formScope: screen.scope ? screen.scope.prop('checked') ? 1 : 0 : 0, //public : private
                formHtml: $form[0].outerHTML,
                css: formCss
            };
            if (updateName) {
                query.formNewName = formInputName;
            }

            return EE_API.Form.Update(query)
            .then(function (updatedForm) {
                saveInfoDiv.text(ee.t.allchangessaved);
                _saveScreenshot(query);
                isFirstSave = false;
                previousName = query.formName;
                if (query.formNewName) {
                    previousName = query.formNewName;
                    return ee.view.loadForms().then(function () {
                        let encodedname = ee.tools.encodeBase64Url(query.formNewName)
                        ee.changeHashQuietly(`#/forms/edit/${encodedname}`)
                    });
                }
                return updatedForm;
            })
            .catch((err) => {
                console.log(err);
            });

        };
        var _saveScreenshot = function (query) {
            const formInputName = screen.name.val();
            const currentIndex = _.findIndex(ee.data.forms.data, form => {
                return ee.data.selectedform.name === form.name;
            });

            ee.data.forms.data[currentIndex] = ee.data.selectedform;
            //generate screenshot 
            const defaultOpt = {
                target: '.gallery-item',
                height: 672,
                width: 1000,
                background: "#fafafa",
                format: 'image/png',
                quality: 0.3,
                canvasheight: false,
                canvaswidth: false
            }
            const basicFormInfo = { formName: formInputName, ispublic: query.formScope };
          
            const trimmedcss = query.css.replace(/(:root {([^}]*)})/g, "");
            const thumbnaildata = '<div style="margin:auto;">' + query.formHtml + '</div>' + '<style>body{background:#FFFFFF;display: flex;justify-content: center;height: 100 %;}</style>' + trimmedcss;
            
            const options = $.extend({}, defaultOpt, basicFormInfo);
            html.widget.Thumbnail.generate(thumbnaildata, options).then((image) => {
                EE_API.Form.SaveScreenshot({ base64image: image.split(',')[1], formName : formInputName })
            })
        };
        //The default settings
        var _visibleOptionalLists = function (hide, $form, shouldSave=true) {
            if (!$form) var $form = $(editor.getValue());

            if (hide === true) {
                $form.find('.webform-lists').hide();
                $form.find('.webform-lists input').attr('checked', true);
            } else {
                $form.find('.webform-lists').show();
                $form.find('.webform-lists input').removeAttr('checked');
            }
            _updateRaw($form, shouldSave);
        };
        const _hideSelectedList = function () {
            const $form = $(editor.getValue());
            screen.formtargetlist.find("option").show();
            $form.find('input').each(function () {
                screen.formtargetlist.find(`option[value='${  this.value  }']`).hide();
            });

        };
        var _addAudience = function () {

            const $select = html.get("NewWebformGroupSelect", { lists: ee.data.lists.getAll() });
            let currentPubliclistId = null;

            $select.appendTo(screen.formtargetlist);

            let selectedBefore;

            $select.on("focus", function(){
                selectedBefore = $(this).find('option:selected').text();
            }).change("change", function (e, shouldSave=false) {
                // skipAppend ??
                let $select = $(this);
                let selectedListId = $select.val();
                const selectedListName = $select.find('option:selected').text();
                const $form = $(editor.getValue());
                var $list;
                const $webformlists = $form.find('div.webform-lists');
                const $selected = $select.find('option:selected');

                if (currentPubliclistId === null) {
                    if (selectedListId === "new") {
                        var lists = html.widget.CreateContactsListUI.init({
                            origin: "form",
                            callback(data) {
                                const $selectedoption = $select.find('#newlist');

                                $list = html.get('NewWebFormAudience', {
                                    publiclistid: data.publiclistid,
                                    name: data.name,
                                    id: html.generateUniqueId(8)
                                });

                                $selectedoption.attr( {
                                    value: data.publiclistid,
                                    id: data.listid
                                }).text(data.name);

                                $select.find('option[value=add]').remove();

                                $select.find('option[value=remove]').removeClass('hidden');

                                _addAudience(); //Add New trigger

                                selectedListId = data.publiclistid;
                                _updateSelectedLists(screen, $webformlists, $form, shouldSave);


                                if ($webformlists.find("input[name=publiclistid]").length > 1) {

                                    screen.multiplelistpossibility.show();
                                    screen.allowselectinglist.prop("checked", true);
                                } else {
                                    screen.allowselectinglist.removeAttr("checked");
                                    screen.multiplelistpossibility.hide();
                                }

                                _visibleOptionalLists(!screen.allowselectinglist.prop("checked"), $form, !shouldSave);
                                // Hide selected list in every other select
                                _hideSelectedList();

                                _updateRaw($form);
                            },
                            onCancel() {
                                $select.remove();
                                _addAudience();
                            }
                        });
                    } else {
                        var $list = html.get('NewWebFormAudience', { publiclistid: selectedListId, name: selectedListName, id: html.generateUniqueId(8) });
                        $select.find('option[value=add]').remove();
                        $select.find('option[value=remove]').removeClass('hidden');
                        _addAudience(); //Add New trigger
                    }


                } else if (selectedListId === 'remove') {
                    //Remove
                    $webformlists.find(`input[value="${  currentPubliclistId  }"]`).closest('div').remove();
                    $select.remove();
                    $select = null;
                } else {
                    if (selectedListId === "new") {
                        var lists = html.widget.CreateContactsListUI.init({
                            origin: "form",
                            callback(data) {
                                const $selectedoption = $select.find('#newlist');

                                $list = html.get('NewWebFormAudience', {
                                    publiclistid: data.publiclistid,
                                    name: data.name,
                                    id: html.generateUniqueId(8)
                                });

                                $selectedoption.attr( {
                                    value: data.publiclistid,
                                    id: data.listid
                                }).text(data.name);

                                $select.find('option[value=add]').remove();

                                $select.find('option[value=remove]').removeClass('hidden');

                                selectedListId = data.publiclistid;

                                _updateSelectedLists(screen, $webformlists, $form, shouldSave);

                                if ($webformlists.find("input[name=publiclistid]").length > 1) {

                                    screen.multiplelistpossibility.show();
                                    screen.allowselectinglist.prop("checked", true);
                                } else {
                                    screen.allowselectinglist.removeAttr("checked");
                                    screen.multiplelistpossibility.hide();
                                }

                                _visibleOptionalLists(!screen.allowselectinglist.prop("checked"), $form, !shouldSave);
                                // Hide selected list in every other select
                                _hideSelectedList();

                                _updateRaw($form);
                            },
                            onCancel() {
                                $select.remove();
                                _addAudience();
                            }
                        });
                    } else {
                        $list = html.get('NewWebFormAudience', { publiclistid: selectedListId, name: selectedListName, id: html.generateUniqueId(8) });

                        //changedata
                        const $input = $webformlists.find(`input[value="${  currentPubliclistId  }"]`);
                        $input.val(selectedListId);
                        $input.parent().find('label').html(selectedListName);
                    }
                }
                currentPubliclistId = selectedListId;

                _updateSelectedLists(screen, $webformlists, $form, shouldSave);

                if ($webformlists.find("input[name=publiclistid]").length > 1) {

                    screen.multiplelistpossibility.show();
                    screen.allowselectinglist.prop("checked", true);
                } else {
                    screen.allowselectinglist.removeAttr("checked");
                    screen.multiplelistpossibility.hide();
                }

                _visibleOptionalLists(!screen.allowselectinglist.prop("checked"), $form, !shouldSave);
                // Hide selected list in every other select
                _hideSelectedList();
                // _checkAudienceSelection(screen, $webformslists)

                // Update selected before value after change
                selectedBefore = $(this).find('option:selected').text();
            });
        };
        var _updateSelectedLists = function (screen, $webformlists, $form, shouldSave=true) {
            const selectedLists = screen.find("#eformtargetlist select");
            const formListsContainer = $form.find(".webform-lists");

            formListsContainer.empty();

            _.forEach(selectedLists, function(select) {
                const selectedListId = $(select).val();

                if (
                    selectedListId !== "add" &&
                    selectedListId !== "remove" &&
                    selectedListId !== "new"
                ) {
                    const selectedListName = $(select).find('option:selected').text();
                   
                    const $list = html.get('NewWebFormAudience', { publiclistid: selectedListId, name: selectedListName, id: html.generateUniqueId(8) });

                    formListsContainer.append($list);
                }
            });
           
            _updateRaw($form, !shouldSave);
        };
        const _checkAudienceSelection = function(screen, $webformlists) {
            if ($webformlists.find("input[name=publiclistid]").length > 1) {

                screen.multiplelistpossibility.show();
                screen.allowselectinglist.prop("checked", true);
            } else {
                screen.allowselectinglist.prop("checked", false);
                screen.multiplelistpossibility.hide();
            }

            _visibleOptionalLists(!screen.allowselectinglist.prop("checked"), $form);
            _hideSelectedList();

        };
        //Add input field
        var _addInput = function (title, id, type, placeholder, required, opt, length, shouldSave=true) {
            //translations for most popular fields
            const $form = $(editor.getValue());
            const $target = $form.find('.inputs');
            let $input;
            let pattern;
            const checked = checked ? " checked " : " ";
            if (id !== 'email') {
                id = `field_${  id}`;
            }
            if (type === 'bignumber') {
                pattern = "^-?[0-9]{0,18}$";
            }
            $input = $(`<div><label for="${  id  }${required === "required" ? '" class="requiredInput">' : '">'  }${title  }</label>
            <input ${length && `maxlength="${ length }"` || ''} class="form-control" ${pattern && `pattern="${ pattern }"` || ''} name="${  id  }" size="20"${  checked  }type="${  type  }" ${  required  } placeholder="${  placeholder  }" /></div>`);
            if (opt) {
                _.forEach(opt, (value, key) => {
                    $input.find("input").prop(key, value);
                })
            }
            $input.appendTo($target);
            _updateRaw($form, shouldSave);
        };
        var _addBoolean = function (description, id, required) {
            const $form = $(editor.getValue());
                const $target = $form.find('div[class="checkboxes"]');
            $(`<div data-test="ASDASD"><label for="${  id  }"><input id="${  id  }" type="checkbox" name="field_${  id  }" value="true"${  required  }><span style="display: inline;">${  description  }</span></label></div>`).appendTo($target);
            _updateRaw($form);
        };
        const _addFormConsentScript = function($form) {
                //NOTE: please remember that this script on a back-end list of scripts that shouldn't be trimmed. If you want to modify it, please contact with backend devs
                $form.find('div#trackconsentscript').remove();
                $form.find('input#eesubmit').before(html.get("WebFormTrackConsent", {}, {}, true));
        };
        var _addFormConsent = function (id, required) {
            const $form = $(editor.getValue());
                const $target = $form.find('.consent');
                let text;
            if (!screen.customfields.find('.consenttracking_description').val()) {
                screen.customfields.find('.consenttracking_description').val(ee.t.formtrackingconsent)
            } 
            $(`<div class="consentcontainer">
            <div>
                <input id="hiddenInput" type="hidden" value="2" name="consenttracking">
            </div>
            <div>
                <label for="consenttracking">
                    <input type="checkbox" data-name="id" id="consenttracking" name="consenttracking" value="1" ${  required  }>
                    ${  screen.customfields.find('.consenttracking_description').val()  }
                </label>
            </div>
            </div>`).appendTo($target); //hidden input needs to be put in front of visible one to get overwritten
            _addFormConsentScript($form);
            _updateRaw($form);

        };
        //Remove input fied
        var _removeInput = function (check) {
            const $form = $(editor.getValue());
                const $input = $form.find(`input[name="field_${  check.prop("name")  }"]`);
                const $parent = $input.closest('div');

            if ($parent.length === 0) {
                $input.remove();
            } else {
                $parent.remove();
            }
            _updateRaw($form);
        };
        var _removeFormConsent = function (check) {
            const $form = $(editor.getValue());
                const $input = $form.find(`input[name="${  check.prop("name")  }"]`);
                const $parent = $input.closest('.consentcontainer');

            $parent.empty();

            $form.find('script').remove();
            _updateRaw($form);
        };
        const _preSelectFields = function ($form) {
            if (!$form) {
                $form = $(editor.getValue());
            }
            addNewInputs($form);
            const selectedfields = $form.find(".inputs")[0].children;
            const personalDisclaimer = $form.find('#ewf_datadisclaimer').html();
            const isEmailVerificationPicked = $form.find('#ewf_verifyEmails').data("value") === "true";

            if (selectedfields.length) {
                screen.choosefieldslist.find("input[type=checkbox]").prop("checked", false);
            }

            for (let i = 0; i < selectedfields.length; i++) {
                let name = $(selectedfields[i]).find("input")[0].name.replace("field_", "");
                name = name.replace("field_", "");
                const that = screen.choosefieldslist.find(`#e${name}`);
                const required = $(selectedfields[i]).find("input")[0].required;
                const check = screen.choosefieldslist.find(`#required_${name}`);

                if (that) {
                    that.prop('checked', true);
                }

                if (required && i > 0) {
                    const name = check.data("requiredfield");
                    screen.find(`.js-checkbox__required-indicator--${name}`).show();
                    check.prop('checked', true);
                }
            }

            screen.find("#edatadisclaimermsg").val(personalDisclaimer);

            if (isEmailVerificationPicked) {
                $("#webform__verify-emails-checkbox").click();
                $("#webform__verify-emails-input").val(isEmailVerificationPicked);
            }

            screen.find("#webforms__button-text-input").val($form.find("#eesubmit").val());
        };
        const _preSelectCheckboxes = function ($form) {
            if (!$form) {
                $form = $(editor.getValue());
            }
            const selectedcheckboxes = $form.checkboxes[0].children;

            for (let i = 0; i < selectedcheckboxes.length; i++) {

                const name = $(selectedcheckboxes[i]).find("input")[0].name.replace("field_", "");
                const $that = screen.choosefieldslist.find(`#e${  name}`);
                if ($that) {
                    $that.prop('checked', true);
                }
            }
        };
        const _preSelectConsent = function ($form) {
            if (!$form) {
                $form = $(editor.getValue());
            }
            const consent = $form.find('#consenttracking');
                if (consent.length) {
                    screen.find('#econsenttracking').prop("checked", true)
                }

        };
        const _preProperties = function ($form) {
            const selectedinputproperties = $form.inputs[0].children;
            const selectedcheckboxproperties = $form.checkboxes[0].children;
            const combined = [].concat(Array.prototype.slice.call(selectedinputproperties), Array.prototype.slice.call(selectedcheckboxproperties));

            for (let i = 0; i < combined.length; i++) {
                const name = $(combined[i]).find("input")[0].name.replace("field_", "");
                const required = $(combined[i]).find("input")[0].required;
                const label = $(combined[i]).find('label').text().trim();
                const placeholder = $(combined[i]).find("input")[0].placeholder;

                if ($(combined[i]).find("input")[0].type === "hidden") {
                    continue;
                }

                if (screen.choosefieldslist.find(`#e${  name}`)) {
                    const that = screen.choosefieldslist.find(`#e${  name}`);
                    const containerField = that.parent().parent();
                    const properties = containerField.next();

                    containerField.find('#eproperties').show();
                    properties.find('#etitleProperties').val(label);
                    properties.find('#eplaceholderProperties').val(placeholder);
                    properties.find('#edescriptionProperties').val(label);

                    if (required) {
                        containerField.next().find('input[name="required"]').attr('checked', 'true');
                        that.parent().find('span').show();
                    }
                }
            }
            $('.webforms__custom-field input[name="required"]:not(:checked)').closest('.webforms__custom-field').find('span.req').hide();

            const inputs = $('.webforms__custom-field .mainlabel input'); 
            inputs.filter(':not(:checked)').closest('.webforms__custom-field').find('.propContainer').find('input,textarea').attr('disabled', 'disabled');
            inputs.filter(':checked').closest('.webforms__custom-field').find('.propContainer').find('input,textarea').removeAttr('disabled');
        };
        const _preSelectOptions = function ($form) {
            screen.formtargetlist.empty();
            _addAudience();
            
            //lists
            const selectedlists = $form.lists[0].children;
            for (let i = 0; i < selectedlists.length; i++) {

                const listId = $(selectedlists[i]).find('input').val();
                const select = $('.etargets')[i];

                $(select).val(listId).trigger('change', true);
            }

            //check if option to select lists is selected
            if (selectedlists.length > 1) {
                screen.multiplelistpossibility.show();
                if (($form.lists[0].style.display).trim() !== 'none') {
                    screen.allowselectinglist.attr('checked', true);
                } else {
                    screen.allowselectinglist.removeAttr("checked");
                }
            }
            //URLs and templates
            screen.FormReturnUrl.val($form.find('input[name=returnUrl]').val());
            screen.FormActivationReturnUrl.val($form.find('input[name=activationReturnUrl]').val());
            screen.FormAlreadyActiveUrl.val($form.find('input[name=alreadyactiveurl]').val());
            screen.FormActivationTemplate.val($form.find('input[name=activationTemplate]').val());
            screen.notifyEmail.val($form.find('input[name=notifyEmail]').val());
        };
        var _loadCustomForm = function (shouldntRefreshStylesTab) {
                const Lists = dataModel.create("Lists");
                const ContactFields = dataModel.create("ContactFields");
                form = ee.data.selectedform.html;
                $form = $(form);
                formid = $form.data('id');
                const $publicAccountIdInput = $form.find('input[name="publicaccountid"]');

                //make sure that form will always have proper publicaccountid and formid
                $publicAccountIdInput.val($publicAccountIdInput.attr('data-access-token') ? $publicAccountIdInput.val() : ee.data.account.publicaccountid);
                //$form.find('input[name="publicformid"]').val(ee.data.selectedform.formid);
                $form.find('#ewf_style').html(ee.data.selectedform.css);

                //make sure that form will have proper action api url
                $form.attr("action", ee.api.apiurl + '/contact/add?version=2');

                if (ee.data.account.enablecontactfeatures) {
                    $form.lists = $($form.find('div.webform-lists:first'));
                }
                $form.inputs = $($form.find('div.inputs:first'));

                if (!($form.find('div.checkboxes').length)) {
                    $('<div class="checkboxes"></div>').insertAfter($form.find('.inputs'));
                }

                $form.checkboxes = $($form.find('div.checkboxes'));

                //select fields used in form
                $.when(ContactFields.require())
                .then(function (data) {
                    _preSelectFields($form);
                    _preSelectCheckboxes($form);
                    _preProperties($form);
                    _preSelectConsent($form);
                    if ($form.find('#consenttracking').parent() && $form.find('#consenttracking').parent().text().trim()) {
                        screen.customfields.find(".consenttracking_description").val($form.find('#consenttracking').parent().text().trim())
                    }

                })
                .then(function() {
                    _addWebformsV2Classes($form);
                    _updateRaw($form, false);
                    _initStylesTab($form, false);
                })
                .catch(function (err) { console.log(err); });

                $.when(Lists.require()).then(function () {
                    _preSelectOptions($form);
                });

                //add headers
                const formheadercontent = $form.find('#ewf_formheader').text().trim();
                const formdescriptioncontent = $form.find('#ewf_formdesc').text().trim();
                screen.formsettings.find('input[name="eformtitle"]').val(formheadercontent).trigger('change');
                screen.formsettings.find('textarea[name="eformdescription"]').val(formdescriptioncontent).trigger('change');

                const formdatadisclaimer = $form.find('#ewf_datadisclaimer');

                //recaptcha - we don;t store it, generate it depending on the html we receive
                if ($form.find('#recaptchaScript').length > 0) {
                    screen.enableCaptcha.attr('checked', true);
                }
                
                const sendActivationInput = $form.find('input[name=sendActivation]');
                if (sendActivationInput.length > 0) {
                    screen.sendActivation.attr('checked', sendActivationInput.val() === 'true');
                } else {
                    // update forms without this input on load because true is default value
                    $form.append('<input type="hidden" name="sendActivation" value="true">');
                    screen.sendActivation.attr('checked', true);
                }

                if (formdatadisclaimer.text().trim().length > 0) {
                    screen.datadisclaimermsg.text(formdatadisclaimer.text().trim());
                }

                _addWebformsV2Classes($form);
                addNewInputs($form);
                if (isGalleryFormCreated) {
                    _setPersonalDataDisclaimer($form);
                }
                _updateRaw($form, false);
        };
        var _loadDefaultForm = function () {
            formid = html.generateUniqueId(10);
            form = `
            <form action="${  ee.api.apiurl  }/contact/add?version=2" data-id="${  formid  }" method="post" id="ewf_subscriptionForm_${  formid  }" class="EWF__form">
            <div class="headers" style="display:none">
                <h4 id="ewf_formheader" class="EWF__header"></h4>
                <p id="ewf_formdesc"></p>
            </div>
            <fieldset style="border:none;" class="webform-custominputs">
            <div class="inputs"></div>
            <div class="checkboxes"></div>
            <div class="webform-lists"></div>
            <div class="consent"></div>
            </fieldset>
            <fieldset class="webform-options" style="border:none;">
            <input type="submit" name="submit" value="${  ee.t.subscribe  }" id="eesubmit" class="EWF__subscribe">
            <input type="hidden" name="publicaccountid" value="${  ee.data.account.publicaccountid  }">

            <input type="hidden" name="returnUrl" value="${  screen.FormReturnUrl.val()  }">
            <input type="hidden" name="activationReturnUrl" value="${  screen.FormActivationReturnUrl.val()  }">
            <input type="hidden" name="alreadyactiveurl" value="${  screen.FormAlreadyActiveUrl.val()  }">
            <input type="hidden" name="activationTemplate" value="${  screen.FormActivationTemplate.val()  }">
            <input type="hidden" name="source" value="WebForm">
            <input type="hidden" name="verifyemail" value=false id="ewf_verifyEmails">
            <input type="hidden" id="ewf_captcha" name="captcha" value="false">
            <input type="hidden" name="sendActivation" value="true">
            `

            if (ee.data.account.enablecontactfeatures) form += `<input type="hidden" name="notifyEmail" value="${  screen.notifyEmail.val()  }">`;

            form += `
            <button type="button" name="dismissForm" id="ewf_dismissForm" style="display:none; position: absolute; top: 0; right: 0; float: right">X</button>
            </fieldset><div id="ewf_style" class="Inline GrayScale LabelsAndPlaceholders"></div></form>
            `
            shouldSaveForm = false;
            editor.setValue(form);

            if (isNewFormCreated) {
                _addInput("Email", "email", "email", "Please enter your email address", "required", false, 81, false);
                _addInput(ee.t.firstname, "firstname", "text", ee.t.erfirstname, false, true, 100, false);
                _addInput(ee.t.lastname, "lastname", "text", ee.t.erlastname, false, true, 100, false);
            }
            const formName = `${ee.t.newform} ${moment().format('YYYY-MM-DD h:mm:ss')}`;

            // Set correct value for form name input
            $("#ename").val(formName);
            $(".js-form-name").html(formName);
            _formatRaw();

            //set default style
            screen.style.find('.default').attr('selected', 'selected').trigger('change');
            _loadDefaultStyles();
            _initStylesTab();
        };
        var _formatRaw = function () {
            editor.autoFormatRange({ line: 0, ch: 0 }, { line: editor.lineCount(), ch: editor.getTextArea().value.length }, true);
            editor.setSelection({ line: 0, ch: 0 }, { line: 0, ch: 0 });
        };
        var _getAttributesOpt = function () {
            const $form = $(editor.getValue());
                const $formopt = $form.find('.webform-options');
                const attributesArr = [];
                let attributesCollection;
                let attributesObj;

            $formopt.find('input').each(function (index, elem) {
                attributesArr[index] = {};
                $.each(this.attributes, function (ind, el) {
                    attributesArr[index][this.name] = this.value;
                });
            });
            attributesCollection = new Collection.Data(attributesArr);

            return attributesCollection;
        };
        var _updateSettings = function (key, value, input, attributesCollection) {
            const $form = $(editor.getValue());
                let $formopt = $form.find('.webform-options');
                const attributesObj = attributesCollection.get(key, value);

            if ($formopt.length > 0) {

                const $htmlInputEl = $form.find(`input[${  key  }='${  value  }']`);
                let $inputValue = $(input).val();
                if (input[0] === $('input#enotifyEmail')[0])
                    $inputValue = $inputValue.replace(/\s+/g, '');

                if ($htmlInputEl.length > 0) {
                    $htmlInputEl.val($inputValue);
                } else {
                    attributesObj.value = $inputValue;

                    const $input = $('<input/>').attr(attributesObj);
                    $formopt.append($input);
                }
            } else {
                const documentFragment = document.createDocumentFragment();
                $formopt = $('<fieldset class="webform-options" style="border:none;"></fieldset>');

                attributesCollection.each(function (elem) {
                    const $input = $('<input/>').attr(attributesCollection.data[elem]);
                    documentFragment.appendChild($input[0]);
                });

                $formopt.append(documentFragment);
                $form.append($formopt);
            }
            _updateRaw($form);
        };
        var _updateRaw = function ($form, saveForm=true) {
            if (!$form) $form = $(editor.getValue());
            const outerHtml = $form[0].outerHTML;

            if (!outerHtml) return;

            const safeHtml = ee.tools.XSSProtectHtml($form[0].outerHTML);
            $form = $(safeHtml);
            if (screen.enableCaptcha.is(':checked') && ($form.find("#recaptchaScript").length === 0)) {
                _addCaptcha($form);
            } else {
                _removeCaptcha($form);
            }

            _toggleSendActivation($form, screen.sendActivation.is(':checked'));
            
            //add script for dismissing form
            if (screen.formposition.val() != "Inline") {
                _addDismissForm($form);
            }
            if (screen.find('#econsenttracking') && screen.find('#econsenttracking').is(":checked")) {
                _addFormConsentScript($form);
            }
            // Just a quick solution to hide a feature from #EE-783 until it gets tested
            // if (screen.find("#webform__verify-emails-checkbox").is(":checked")) {
            //     addVerificationCode($form, screen.find("#webform__verify-emails-input").val());
            //     $form.find('input[name="verifyemail"]').val(true);
            // } else {
            //     removeVerificationCode($form);
            //     $form.find('input[name="verifyemail"]').val(false);
            // }
            removeVerificationCode($form);
            if (saveForm) {
                shouldSaveForm = true;
                isSavingForm = false;
            } else {
                shouldSaveForm = false;
            }

            editor.setValue($form[0].outerHTML);
            _formatRaw();
            _updatePreview($form[0].outerHTML);
        };
        var _updatePreview = function (html) {
            if (screen.formposition.val() !== "Inline") {
                clearDismissCookie();
            }

            screen.rawpreview.html(`<div>${  html  }</div>`);
        }

    //Public API
    root.webform = {
        init: _init
    };

}(ee.plugins));
