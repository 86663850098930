(function (root) {
    /*NOTE: Needs to improve */
    var _widgetPriv = {
        // this should be as ContactsOverviewGraphsWidget instance
        createSegmentsNavigation: function (segments) {
            var that = this,
                drpdwnlist = html.get('SegmentsListDropDown', { list: segments.getAll() }),
                $ulcontainer = that.$navigation.find('.segmentslist'),
                $btn = that.$navigation.find('#elistdropdown'),
                $range = that.$navigation.find('#daterangelist');
            $ulcontainer.empty();
            drpdwnlist.appendTo($ulcontainer);
            $btn.removeClass('disabled')
                .prop('disabled', false)
                .find('.etext')
                .text(ee.t.segments);
            $range.removeClass('disabled')
                .prop('disabled', false)
                .find('.etext')
                .text(ee.t.daterange);

            _bindDatePickerOverview.call(that);
        },
        getDateRange: function (range) {
            let rangeObj = {
                    to: moment(),
                    from: moment()
                };

            switch (range) {
                case 'week':
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'weeks');
                    break;
                case '2weeks':
                    rangeObj.from = moment(rangeObj.to).subtract(2, 'weeks');
                    break;
                case 'month':
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'months');
                    break;
                case '3months':
                    rangeObj.from = moment(rangeObj.to).subtract(3, 'months');
                    break;
                case '6months':
                    rangeObj.from = moment(rangeObj.to).subtract(6, 'months');
                    break;
                case 'year':
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'years');
                    break;
                default:
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'weeks');
            }
            rangeObj.to = rangeObj.to.format(ee.tools.determinateDateFormatDatepicker())
            rangeObj.from = rangeObj.from.format(ee.tools.determinateDateFormatDatepicker())
            return $.extend(this, rangeObj);
        },
        loadGraphsData: function (names, timeformatted) {
            var update = !names,
                that = this;

            if (update) names = that.segments.join(',');

            var dates = {
                to: that.to,
                from: that.from
            };
            let query = {...dates}

            if (!that.timeformatted) {
                query.to = ee.tools.time(query.to + " 23:59:59").toDate();
                query.from = ee.tools.time(query.from).toDate();    
            }
            return EE_API.Contact.Overview(query)
                .then(function (data) {
                    var emailsSent = data.emailssent;
                    var newContactsToday = data.newcontactstoday;
                    var totalContacts = data.totalcontacts;
                    var averageOpenRate = data.averageopenrate;
                    var averageClickRate = data.averageclickrate;
                    var availableContacts = data.availablecontacts;

                    if (update) {
                        that.data.update(data);
                        that.$graphs.empty();
                        that.data.data = new Graph(data, query.from, query.to);
                        that.data.data.render(that.$graphs);

                    } else {
                        data = new Graph(data[0]);
                        that.data.add(data);
                        data.render(that.$graphs);
                    }
                    $('#newcontactstoday').html(newContactsToday.numberFormat());
                    $('#totalcontacts').html(totalContacts.numberFormat());
                    $('#availablecontacts').html(availableContacts.numberFormat());
                    $('#emailssent').html(emailsSent.numberFormat());
                    $('#openrate').html(averageOpenRate.toFixed(2) + " %");
                    $('#clickrate').html(averageClickRate.toFixed(2) + " %");

                })
                .catch(err => {
                    console.log(err)
                });
        },
        bindEvents: function () {
            var that = this;

            that.$navigation.on('click', '#etimeselect li', function () {
                var $this = $(this),
                    range = $this.data('range') || {};
                if ($this.hasClass('drop-first')) {
                    that.$navigation.find('#efromto').removeClass('hide');
                } else {
                    that.$navigation.find('#efromto').addClass('hide');
                }
                that.$navigation.find("#etimeselecttitle").html($this.html());

                var $btn = that.$navigation.find('button');

                $btn.addClass('disabled').prop('disabled', true);

                that.$graphs.html(that.$graphsLoading.html());


                _widgetPriv.getDateRange.call(that, range);
                _widgetPriv.loadGraphsData.call(that).then(function () {
                    $btn.removeClass('disabled').prop('disabled', false);
                    that.$navigation.find('#datefrom').datepicker("setDate", new Date(that.from));
                    that.$navigation.find('#dateto').datepicker("setDate", new Date(that.to));
                });
            });

        }
    },
        _generateGraph = function (bindto, data) {
            data[0] = _.map(data[0], date => {
                if (date === 'x') {
                    return date;
                } else {
                    let formatteddate = ee.tools.time(date, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate("L");
                    return formatteddate.replace(/\//g, '-');
                }
            });
            return c3.generate({
                bindto: bindto,
                padding: {
                    top: 30,
                    right: 70,
                    bottom: 20,
                    left: 70,
                },
                size: {
                    height: 250
                },
                legend: {
                    show: true,
                },
                data: {
                    x: 'x',
                    hide: false,
                    xFormat: ee.tools.localDateFormatGraph(),
                    type: 'bar',
                    columns: data,
                    color: function () { return '#5457FF' },
                    order: null
                },

                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            format: ee.tools.localDateFormatGraph()
                        },
                        label: {
                            text: ee.t.date,
                            position: 'outer-center',
                        }
                    },
                    y: {
                        padding: { bottom: 0 },
                        tick: {
                            format: function (x) {
                                return (x == Math.floor(x)) ? x : "";
                            }
                        }
                    }
                },
                zoom: {
                    enabled: true
                }
            });
        },
        _keyFromDate = function (date) {
            return date.toISOString().substring(0, 10);

        },
        _generateColumns = function (data,from,to) {
            //prepare data for graph, and if there are no data - mock it with 0s
            let collection = new Collection.Data(data.netcontacts);
            let columns = [
                ['x'],
                [data.name]
            ]
            //this code creates dates in the past, to prevent empty graph
            let fromdate = moment(from);
            let todate = moment(to);
            while (todate.diff(fromdate, "days") >= 0) {
                ee.tools.time(fromdate).toDate("L")
                columns[0].push(moment(fromdate).format('YYYY-MM-DD'));
                columns[1].push(0);
                fromdate.add(1, "days");
            }
            //this code is for actual dates
            _.forEach(collection.data,(elem) => {
                let date = columns[0].findIndex((selecteddate) => {
                    return selecteddate === moment(elem.day).format('YYYY-MM-DD')
                })
                columns[1][date] = elem.count;
            })

            return columns;
        },
        _bindDatePickerOverview = function () {
            var that = this,
                $from = that.$navigation.find('#datefrom'),
                $to = that.$navigation.find('#dateto');
            $from.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", new Date(that.from)).change(function () {
                return _changeTimeRange.call(that, $from, $to);
                //_bulkGenerateGraphs(true);
            });
            $to.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", new Date(that.to)).change(function () {
                return _changeTimeRange.call(that, $from, $to);
                //_bulkGenerateGraphs(true);
            });
        },
        _changeTimeRange = function ($from, $to) {
            var that = this,
                from = $from.datepicker('getDate'),
                to = $to.datepicker('getDate');
            if (from.getTime() < to.getTime()) {
                that.from = ee.tools.time(from).toDate();
                that.to = ee.tools.time(to).toDate();
                that.timeformatted = true;
                var $btn = that.$navigation.find('button, input');

                $btn.addClass('disabled').prop('disabled', true);
                that.$graphs.html(that.$graphsLoading.html());

                _widgetPriv.loadGraphsData.call(that).then(function () {
                    $btn.removeClass('disabled').prop('disabled', false);
                });
            } else {
                return false;
            }
        };
    //Private Graph Object constructor
    function Graph(segment,from,to) {

        this.data = segment;
        this.data.name = "New contacts";
        this.$container = $('<div id="newContactsGraph"></div>');
        this.columns = _generateColumns(this.data,from,to);
    }
    Graph.prototype = {
        render: function (container) {
            container.append(this.$container);
            _generateGraph('#newContactsGraph', this.getColumns())
        },
        getColumns: function () { return this.columns },
        remove: function () {
            this.$container.remove();
        }
    };


    //Widget constructor
    function ContactsOverviewGraphsWidget(dependency, opt) {
        var that = this;

        //Duck typing for opt object
        if (!opt)
        {
            throw new Error('option object is required');
        }
        if (!opt['$navigation'] || !(opt['$navigation'] instanceof jQuery))
        {
            throw new Error('$navigation parameter in option object is required and must be a jQuery object');
        }
        if (!opt['$graphs'] || !(opt['$graphs'] instanceof jQuery)) {
            throw new Error('$graphs parameter in option object is required and must be a jQuery object');
        }

        that.$navigation = opt.$navigation;
        that.$graphsLoading = opt.$graphs.clone();
        that.$graphs = opt.$graphs;

        _widgetPriv.getDateRange.call(that, 'week');

        that.segments = ['New contacts'];
        that.data = new Collection.Data([]);

        /*NOTE apply to jQ.when a dependency array */
        $.when.apply($, dependency).then(function () {
            //Step 1 create Segments list
            _widgetPriv.createSegmentsNavigation.call(that, arguments[0]);
            //Step 2 generate Graphs
            _widgetPriv.loadGraphsData.call(that);
            //Step3 bind navigation events
            _widgetPriv.bindEvents.call(that);
        });

        return that;
    }

    root.ContactsOverviewGraphsWidget = ContactsOverviewGraphsWidget;

}(html.widget));
