(function HistoryGraphsWidget(root) {
    /*NOTE: Needs to improve */

    const _widgetPriv = {
        // this should be as HistoryGraphsWidget instance
        createSegmentsNavigation: function () {
            const that = this;
            const $range = that.$navigation.find('#edaterangelist');

            $range.removeClass('disabled')
                .prop('disabled', false)
                .find('.etext')
                .text(ee.t.daterange);

            _bindDatePicker.call(that);
        },
        getDateRange: function (range) {
            const that = this;
            let rangeObj = {
                to: moment()
            };

            switch (range) {
                case 'week':
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'weeks');
                    break;
                case '2weeks':
                    rangeObj.from = moment(rangeObj.to).subtract(2, 'weeks');
                    break;
                case 'month':
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'months');
                    break;
                case '3months':
                    rangeObj.from = moment(rangeObj.to).subtract(3, 'months');
                    break;
                case '6months':
                    rangeObj.from = moment(rangeObj.to).subtract(6, 'months');
                    break;
                case 'year':
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'years');
                    break;
                default:
                    rangeObj.from = moment(rangeObj.to).subtract(1, 'weeks');
            }

            rangeObj.to = rangeObj.to.format(ee.tools.determinateDateFormatDatepicker());
            rangeObj.from = rangeObj.from.format(ee.tools.determinateDateFormatDatepicker());

            return $.extend(that, rangeObj);
        },
        loadGraphsData: function () {
            var that = this;
            that.to = that.to.replace(/\//g, '-');

            let queryTo = ee.tools.time(that.to + " 23:59:59").toDate();
            let queryFrom = ee.tools.time(that.from + " 00:00:00").toDate();

            const query = {
                includeHistory: true,
                to: queryTo,
                from: queryFrom
            };
            const queryLoadByName = {
                includeHistory: true,
                segmentnames: 'All Contacts',
                to: queryTo,
                from: queryFrom
            };

            return EE_API.Segment.LoadByName(queryLoadByName)
                .then(function (data) {
                    that.$graphs.find("#loadinggraphs").empty();
                    that.$segmentsgraphs.html(`<h3 style="margin-left: 2rem">${ee.t.segments}</h3>`);
                    that.$listsgraphs.html(`<h3 style="margin-left: 2rem">${ee.t.lists}</h3>`);
                    data = new Graph(data[0]);
                    that.data.add(data);
                    data.render(that.$segmentsgraphs);
                })
                .then(() => {
                    return EE_API.Segment.LoadTrackedHistory(query)
                    .then((data) => {
                        $(data).each(function (idx) {
                            data[idx] = new Graph(this);

                            if (data[idx].data.rule) {
                                data[idx].render(that.$segmentsgraphs);
                            } else {
                                data[idx].render(that.$listsgraphs);
                            }
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                })
                .catch((error) => {
                console.log(error)
            });
        },
        bindEvents: function () {
            var that = this;

            that.$navigation.on('click', '#etimeselect li', function () {
                const $this = $(this);
                const range = $this.data('range') || {};
                const $btn = that.$navigation.find('button');

                that.$navigation.find("#etimeselecttitle").html($this.html());

                if ($this.hasClass('drop-first')) {
                    that.$navigation.find('#efromto').removeClass('hide');
                } else {
                    that.$navigation.find('#efromto').addClass('hide');
                }

                $btn.addClass('disabled').prop('disabled', true);

                that.$segmentsgraphs.empty();
                that.$listsgraphs.empty();
                that.$graphs.find("#loadinggraphs").append(that.$graphsLoading.html());

                _widgetPriv.getDateRange.call(that, range);
                _widgetPriv.loadGraphsData.call(that)
                .then( () => {
                    $btn.removeClass('disabled').prop('disabled', false);
                    that.$navigation.find('#edatefrom').datepicker("setDate", new Date(that.from));
                    that.$navigation.find('#edateto').datepicker("setDate", new Date(that.to));
                })
                .catch((error) => {
                    console.log(error)
                });
            });
        }
    };
    const _generateGraph = function (bindto, data) {
        data[0] = _.map(data[0], date => {
            if (date === 'x') {
                return date;
            } else {
                let formatteddate = ee.tools.time(date, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).toDate("L");
                return formatteddate.replace(/\//g, '-');
            }
        });
        return c3.generate({
            bindto: bindto,
            padding: {
                top: 30,
                right: 70,
                bottom: 20,
                left: 70,
            },
            size: {
                height: 250
            },
            legend: {
                show: true,
            },
            data: {
                x: 'x',
                hide: false,
                xFormat: ee.tools.localDateFormatGraph(),
                type: 'line',
                columns: data,
                color: function () { return '#24cc82' },
            },

            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: ee.tools.localDateFormatGraph()
                    },
                    label: {
                        text: ee.t.date,
                        position: 'outer-center',
                    }
                },
                y: {
                    padding: { bottom: 0 },
                    tick: {
                        format: function (x) {
                            return (x == Math.floor(x)) ? x : "";
                        }
                    }
                }
            },
            zoom: {
                enabled: true
            }
        });
    };
    const _generateColumns = function (data) {
        var collection = new Collection.Data(data.history),
            columns = [
                ['x'],
                [data.name]
            ]

        collection.each(function () {
            var day = String(this.day);
            day = day.substring(0, 4) + '-' + day.substring(4, 6) + '-' + day.substring(6, 8);
            columns[0].push(day);
            columns[1].push(this.count);
        })

        return columns;
    };
    const _bindDatePicker = function () {
        const that = this;
        const $from = that.$navigation.find('#edatefrom');
        const $to = that.$navigation.find('#edateto');

        $from.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", new Date(that.from)).change(function () {
            return _changeTimeRange.call(that, $from, $to);
            //_bulkGenerateGraphs(true);
        });
        $to.datepicker({ dateFormat: ee.tools.localDateFormatDatepicker() }).datepicker("setDate", new Date(that.to)).change(function () {
            return _changeTimeRange.call(that, $from, $to);
            //_bulkGenerateGraphs(true);
        });
    };
    const _changeTimeRange = function ($from, $to) {
        const that = this;
        const from = $from.datepicker('getDate');
        const to = $to.datepicker('getDate');

        if (from.getTime() < to.getTime()) {

            that.from = moment(from).format(ee.tools.determinateDateFormatDatepicker());
            that.to = moment(to).format(ee.tools.determinateDateFormatDatepicker());

            const $btn = that.$navigation.find('button, input');

            $btn.addClass('disabled').prop('disabled', true);
            that.$segmentsgraphs.empty();
            that.$listsgraphs.empty();
            that.$graphs.find("#loadinggraphs").append(that.$graphsLoading.html());

            _widgetPriv.loadGraphsData.call(that)
            .then(function () {
                $btn.removeClass('disabled').prop('disabled', false);
            })
            .catch((error) => {
                console.log(error)
            });
        } else {
            return false;
        }
    };
    //Private Graph Object constructor
    function Graph(segment) {
        this.data = segment,
        this.id = segment.segmentid,
        this.$container = $('<div id="s' + this.id + '"></div>');
        this.columns = _generateColumns(this.data);
    }
    Graph.prototype = {
        render: function (container) {
            container.append(this.$container);
            _generateGraph('#s' + this.data.segmentid, this.getColumns())
        },
        getColumns: function () { return this.columns },
        remove: function () {
            this.$container.remove();
        }
    };


    //Widget constructor
    function HistoryGraphsWidget(dependency, opt) {
        var that = this;

        //Duck typing for opt object
        if (!opt) throw new Error('option object is required');
        if (!opt['$navigation'] || !(opt['$navigation'] instanceof jQuery)) throw new Error('$navigation parameter in option object is required and must be a jQuery object');
        if (!opt['$graphs'] || !(opt['$graphs'] instanceof jQuery)) throw new Error('$graphs parameter in option object is required and must be a jQuery object');

        that.$navigation = opt.$navigation;
        that.$graphs = opt.$graphs;
        that.$graphsLoading = opt.$graphs.find("#loadinggraphs").clone();
        that.$segmentsgraphs = opt.$graphs.find("#segmentsgraphs");
        that.$listsgraphs = opt.$graphs.find("#listsgraphs");

        _widgetPriv.getDateRange.call(that, 'month');

        that.data = new Collection.Data([]);

        /*NOTE apply to jQ.when a dependency array */
        $.when.apply($, dependency)
        .then(function () {
            //Step 1 create Segments list
            _widgetPriv.createSegmentsNavigation.call(that);
            //Step 2 generate Graphs
            _widgetPriv.loadGraphsData.call(that);
            //Step3 bind navigation events
            _widgetPriv.bindEvents.call(that);
        })
        .catch((error) => {
            console.log(error)
        });
        return that;
    }

    root.HistoryGraphsWidget = HistoryGraphsWidget;

}(html.widget));
