(function (root) {
    let _showCreateListModal = function ( obj ) {
        if (!obj) obj = {};
        if (!obj.additionalQuery) obj.additionalQuery = {};

        let $createnewlist = html.get('CreateNewList', { amount: obj.additionalQuery.amount});
        let modal = html.modal.create($createnewlist, {
            title: ee.t.createnewlist, size: '600px',
            buttons: [{
                name: ee.t.save,
                css: 'btn_md btn_primary',
                buttonID: 'savelistbutton',
                callback: function () {
                    let name = $createnewlist.listname.val();
                    let allowunsubscribe = $createnewlist.allowunsubscribe.is(':checked');
                    let listCollection = ee.data.lists;

                    if (!name) { // name not provided
                        html.modal.info(ee.t.errorlistname, ee.t.errormsg);
                        return;
                    } else if (name.indexOf("'") >= 0) { // name contains apostrophe
                        // Show listname can't contain apostrophe
                        html.modal.info(ee.t.listnamerestriction, ee.t.errormsg);
                        return;
                    }

                    if (listCollection.get("listname", name)) {
                        html.modal.info(ee.t.thesamelistname, ee.t.errormsg);
                        return;
                    }

                    const query = {
                        listname: name,
                        allowunsubscribe: allowunsubscribe
                    }

                    delete obj.additionalQuery.amount;

                    if (obj.additionalQuery && Object.keys(obj.additionalQuery).length) {

                        $.extend(query, obj.additionalQuery);

                    } else {
                        query.createemptylist = true;
                    }

                    if (!query.createemptylist && !query.rule && !query.emails) {
                        query.allcontacts = true;
                        delete query.rule;
                    }

                    listCollection.add(query)
                        .then(data => {
                            if (obj.origin == "form") {
                                let singlelist = listCollection.get("listname", query.listname);
                                modal.close();
                                obj.callback(singlelist);
                            } else {
                                let listid = listCollection.get("listname", query.listname).listid;
                                ee.goTo("#/contacts/list/" + listid);
                            }
                        });

                }
            }],
            onClose: obj.onClose ? obj.onClose : false,
            onCancel: obj.onCancel ? obj.onCancel : false
        });

        html.inittooltip($createnewlist, '.tooltipcreatenewlist', {
            title: () => $(this).data('mastertooltip'),
            container: $createnewlist,
            html: true
        });

        ee.tools.validateEntityName(
            {
                screen: $createnewlist,
                inputname: '#elistname',
                submit: modal.$footer.find('#savelistbutton')
            }
        );
    };
    let that = {
            init: obj => {
                var list = dataModel.create("Lists");
                $.when(list.require())
                    .then(function () {
                        _showCreateListModal(obj)
                    })
                    .catch(function (err) {
                        console.log(err)
                    });
            }
        };

    root.CreateContactsListUI = that;
}(html.widget));
