(function (root, $, options) {
    var opt = options || {},
        _parameterize = function (obj) {
            var params = "";
            if (obj == null)
                return params;
            for (var id in obj) {
                var val = obj[id] + "";
                params += "&" + encodeURIComponent(id) + "=" + encodeURIComponent(val);
            }
            return params.substring(1);
        },
        _customQueryFormatter = opt.customQueryFormatter,
        that = {
            request: function (target, data, options) {
                if (ee.session.isSessionExpired) {
                    ee.indiOff()
                    return;
                }

                /* set default api _path and give a chance to change it */
                let _path = ee.api.path;
                let def = $.Deferred();
                let apikey = false;

                data = $.extend({}, data) || {};
                apikey = data.apikey || ee.session.apikey();
                if (apikey) {
                    data.apikey = apikey;
                }
                if (typeof _customQueryFormatter === "function") _customQueryFormatter(data);

                options = options || {};
                options.type = options.type || "POST";
                options.version = options.version || 2;
                options.timeout = options.timeout || 30000;
                if(!options.datatype){
                    options.datatype = (options.type !== "POST") ? "jsonp" : "json";// || syntax was replacing orginal datatype
                }

                if (target.charAt(0) !== '/') {
                    console.warn('Missing "/" in API request "' + target + '"');
                    target = "/" + target;
                }

                ee.session.setImpersonation(data);

                var currentLanguage = Cookies.get("elocale");
                if (!currentLanguage) {
                    if(ee.parameters.locale){
                        currentLanguage = ee.parameters.locale;
                    } else {
                        currentLanguage = 'en'
                    }
                }

                var browsertokenidcookie = Cookies.get("browsertokencookie");
                var headers = {'Accept-Language': currentLanguage};
                if (browsertokenidcookie !== null) {
                    headers["X-ElasticEmail-BrowserToken"] = browsertokenidcookie;
                }

                const logoutAction = () => {
                    const defaultClickAction = () => {
                        ee.views['login'].logout(false);
                    };

                    html.modal.info(
                        ee.t.apikeyexpiredtext,
                        ee.t.apikeyexpiredtitle,
                        '600px',
                        defaultClickAction,
                        { strictClose: true }
                    );
                };

                $.ajax({
                    type: options.type,
                    url: `${_path}${target}?version=${options.version}${((options.type !== "POST") ? "&jsoncallback=?" : "")}`,
                    dataType: options.datatype,
                    xhrFields: options.xhrFields,
                    cache: false,
                    headers: headers,
                    data: _parameterize(data),
                    success: function (data, textStatus, request) {
                        const isBinaryRequested = options.datatype === 'binary';

                        if (data.error && data.error.toLowerCase().indexOf("apikey expired") >= 0) {
                            //automatic logout for dashboardsession expir
                            localStorage.removeItem('remember');
                            sessionStorage.removeItem('remember');

                            logoutAction();

                            ee.indiOff();
                            ee.session.isSessionExpired = true;

                            return
                        }

                        //handle raw content responses
                        if(options.datatype === "text"){
                            def.resolve(data);
                            return;
                        }

                        if (!data.success && !isBinaryRequested) {
                            if (options.manualerrorhandle === true || (data.messageparts && data.messageparts[0] == 1181917)) { //hack for handling specific errors
                                return def.reject(data);
                            }
                            /*We need error handler specified as parameter*/
                            html.modal.error(data, { onClose: function () { def.reject(data); } });
                            return;
                        };
                        if (data.data) {
                            if (target === "/account/login" || target === '/account/createfromdashboard') {
                                var xToken = request.getResponseHeader('x-elasticemail-browsertoken');

                                if (xToken && Cookies.get("browsertokencookie") !== xToken) {
                                    Cookies.set('browsertokencookie', xToken, { expires: 365, path: '/' });
                                }

                                //FIXME: remove this after full migration from cookies
                                if (!xToken && Cookies.get("browsertokencookie") && (!store.local.has('browserToken') || store.local.get('browserToken') !== Cookies.get("browsertokencookie"))) {
                                    store.local.set('browserToken', Cookies.get("browsertokencookie"))
                                }

                                if (xToken && store && (!store.local.has('browserToken') || store.local.get('browserToken') !== xToken)) {
                                    store.local.set('browserToken', xToken)
                                }
                            }
                            def.resolve(data.data);
                            return;
                        }
                        /* Temporary solution because not all API responses are properly formatted,
                         * and sometimes there is no data object */
                        var rs = {};

                        for (var prop in data) {
                            if (prop != 'success' && prop != 'error' && data.hasOwnProperty(prop)) {
                                rs[prop] = data[prop];
                            }
                        }
                        def.resolve(isBinaryRequested ? data : rs);
                    },
                    timeout: options.timeout,
                    error: function (jqXHR, textStatus, errorThrown) {
                        def.reject(errorThrown);
                    }
                }) // end of $.ajax
                return def.promise();
            },
            setCustomQueryFormatter: function (func) {
                if (typeof func !== "function") throw new Error("Custom Query Formatter must be a function!");
                _customQueryFormatter = func;
            }
        };



    window.EE_API = that;

}(this, jQuery));
