ee.views["verifications"] = (function ViewVerifications() {
    /*-- Returned object of view class--*/
    const that = {};
    /*-- Private property --*/
    let $screen;
    /*-- Private methods --*/
    
    that.show = function () {

        if (ee.data.account.issub) {
            return ee.goTo('#/dashboard');
        }
        
        ee.logEvent('Verifications screen loaded.');
        ee.frame.content.empty();

        //Display screen
        $screen = html.get("Verifications", {});
        $screen.appendTo(ee.frame.content);
        $screen.find('.js-verifications__btn--upgrade').on("click", () => ee.goTo('#/payments'))
        /*-- Bind Events --*/

    };

    return that;
})();
