(function () {
    var _dragdrop_ = ee.editors.etemplate['ddeditor'];
    /*editor.addplugin("examplePlugin", function () {
        var editor = ee.editor;
        editor.body.on("click.pluginName", ".blockClass", function (e) {
            console.log("EVENT CLICK!");
        });
    });*/

    _dragdrop_.addplugin("buffer", function () {
        var core = ee.editor.core, texteditor = core.plugins.texteditor, undobuffer = [], redobuffer = [], $edcontent = false,
            _replaceContent = function ($newContent) {
                $edcontent.replaceWith($newContent);
                $edcontent = $newContent;
                $edcontent.addClass('revoked');
                $edcontent.find('.edited').removeClass('edited').mousedown();
            },
            _changeRowColor = function (bgcolor) {
                if (bgcolor === core.layoutStruct.cssStyle['.drow']['background-color']) { bgcolor = "" }
                $edcontent.closest('.drow').css('background-color', bgcolor);
            },
            _getContent = function () {
                var $content = $edcontent.clone(), $lastedtext = $content.find('.edtext .redactor-in:last');
                if ($lastedtext.length === 1) {
                    $lastedtext.closest(".edtext").html($lastedtext.html());
                }
                $content.find('.redactor-selection-marker, textarea').remove();
                return $content;
            },
        that = {
            set: function (type, obj) {
                if (!$edcontent) {
                    return;
                }
                if (!type) {
                    redobuffer = [];
                }
                if (!obj) {
                    obj = [_getContent(), core.RGBToHex($edcontent.closest('.drow').css('background-color'))];
                }
                if (!type || type === 'undo') {
                    undobuffer.push(obj);
                } else if (type === 'redo') {
                    redobuffer.push(obj);
                }
            },
            undo: function () {
                if (undobuffer.length === 0) {
                    return;
                }
                var buffer = undobuffer.pop();
                //Exception for text editor
                if (buffer[2] && buffer[2] === 'redactor' && core.plugins.texteditor.$editor) {
                    core.plugins.texteditor.$editor.redactor('buffer.undo', buffer);
                    return;
                }
                //Default event path
                that.set('redo');
                _changeRowColor(buffer[1]);
                _replaceContent(buffer[0]);
            },
            redo: function () {
                if (redobuffer.length === 0) {
                    return;
                }
                var buffer = redobuffer.pop();
                //Exception for text editor
                if (buffer[2] && buffer[2] === 'redactor' && core.plugins.texteditor.$editor) {
                    core.plugins.texteditor.$editor.redactor('buffer.redo', buffer);
                    return;
                }
                //Default event path
                that.set('undo');
                _changeRowColor(buffer[1]);
                _replaceContent(buffer[0]);
            },
            init: function ($content) {
                that.destroy();
                $edcontent = $content;
            },
            destroy: function () {
                undobuffer = [], redobuffer = [],
                $edcontent = false;
            },
            //Helpful on colorpicker events.
            removeLastElement: function () {
                if (undobuffer.length === 0) {
                    return;
                }
                undobuffer.pop();
            }
        };
        return that;
    });

    _dragdrop_.addplugin("commonHotkeys", function () {
        var core = ee.editor.core,
            _selectTab = function (mode) {
                var $edited = core.body.find(".edited");
                if ($edited.length === 1) {
                    var next, all = core.body.find(".edcontent>tbody>tr>td"), i = all.index($edited);
                    if (mode === "+") {
                        i = (i >= (all.length - 1)) ? 0 : i + 1;
                    } else {
                        i = (i < 1) ? (all.length - 1) : i - 1;
                    }
                    next = all.get(i);
                    if (next) { $(next).mousedown(); }
                }
            };
        core.hotkeys.add("ctrl+s", ee.editor.save);
        core.hotkeys.add("tab", function () { _selectTab("+") });
        core.hotkeys.add("shift+tab", _selectTab);
        return {};
    });

    _dragdrop_.addplugin("colorpicker", function () {
        var core = ee.editor.core, iframejs = core.iframejs,
        _options = $.extend({}, ee.editor.spectrumOptions, {
            theme: "sp-dark",
            show: function (color) {
                ee.data.lastcolor = (!color) ? "" : color.toHexString();
                core.plugins.buffer.set();
            },
            hide: function (color) {
                color = (!color) ? "" : color.toHexString();
                if (ee.data.lastcolor !== color && ee.editor && ee.editor.core) {
                    core.isChange(true);
                } else {
                    core.plugins.buffer.removeLastElement();
                }
            }
        }),
        that = {
            init: function (id, callback) {
                iframejs.$(id).spectrum(_options).off().change(callback);
            },
            disable: function () {
                iframejs.$(".espectrum").spectrum("destroy");
            },
            hide: function () {
                iframejs.$(".espectrum").spectrum("hide");
            },
            remove: function ($body) {
                $body.find(".sp-container").remove();
            }
        };
        return that;
    });

    _dragdrop_.addplugin("texteditor", function () {
        var core = ee.editor.core,
        //Namespace of initRedactor should be always is ee.core.iframejs (js from windows)
        _tidyHTML = function ($content) {
            $content.find("span:empty, p:empty").remove();
            //TODO: This place will be add a tidy algorytm
        },
        _initRedactor = function (node, $toolBar, lang, direction) {
            var namespace = this,
                $RedactorEditor,
                selection = namespace.document.getSelection(),
                range = { start: selection.anchorOffset, end: selection.focusOffset, endNode: selection.focusNode, startNode: selection.anchorNode },
                $edtext = namespace.$(node).wrapInner('<div id="redactor-run"></div>');
            //--------//
            $RedactorEditor = $edtext.children();
            if ($RedactorEditor.length === 1) {
                $RedactorEditor.redactor({
                    direction: direction,
                    lang: lang,
                    buttons: ['bold', 'italic', 'underline', 'deleted', 'link'],
                    plugins: ['elasticemail', 'etemplatebuffer', 'source', 'format', 'alignment', 'fontsize', 'fontcolor', 'lineheight', 'mergefields'],
                    keepStyleAttr: ['span', 'p'],
                    keepInlineOnEnter: true,
                    focus: false,
                    linkTooltip: false,
                    toolbarExternal: $toolBar['toolbar-redactor'][0],
                    callbacks: {
                        init: function () {
                            $toolBar.show();
                            try {
                                var sel = this.selection.get(), newRange = this.selection.range(sel);
                                if (newRange) {
                                    if (range.start > range.end) {
                                        newRange.setStart(range.endNode, range.end);
                                        newRange.setEnd(range.startNode, range.start);
                                    } else {
                                        newRange.setStart(range.startNode, range.start);
                                        newRange.setEnd(range.endNode, range.end);
                                    }
                                    this.selection.update(sel, newRange);
                                }
                            } catch (e) {
                                //Do nothing
                                console.log('warring: wrong selected range.');
                            }
                            this.observe.customFields();
                            this.$element[0].focus();
                        },
                        destroy: function () {
                            this.selection.remove();
                            if (this.$toolbar) {
                                this.$toolbar.find("input.spectrum").spectrum('destroy');
                            }
                            $edtext.html(this.code.cleaned(this.code.get()));
                            _tidyHTML($edtext);
                            $RedactorEditor = $edtext = false;
                            core.heightAdjustment();
                        },
                        change: function () {
                            core.isChange();
                        },
                        click: function (e) {
                            this.observe.customFields();
                        }
                    },
                    spectrumTheme: 'sp-dark',
                    etemplate: ee.editor.core,
                    mediaManager: html.widget.FileManager,
                    mediaManagerUpload: html.widget.FileManagerUpload
                });
            } else {
                console.log("Error: redactor can find container to start")
            }
            return $RedactorEditor;
        },
        that = {
            disable: function () {
                if (that.$editor) {
                    that.$editor.redactor('core.destroy');
                    that.$editor = false;
                }
            },
            remove: function ($body) {
                $body.find('.edtext .redactor-in:last').each(function () {
                    var $redactor = $(this);
                    $redactor.closest(".edtext").html($redactor.html());
                });
                $body.find('.redactor-selection-marker').remove();
            },
            $editor: false
        };
        core.body.on("mouseup", ".edtext:not(.edited)", function (e) {
            e.stopPropagation();
            this.className = this.className + " edited";
            that.$editor = _initRedactor.apply(core.iframejs, [
                this,
                core.plugins.toolbar.init($(this).closest(".dwrap"), "RedactorTools", {
                    fontsize: html.template("eOptionsFontSize"),
                    css: "dtoolbar-text"
                }),
                ee.t._locale,
                core.layoutStruct.cssStyle[".edtext"]['direction']]);

        });
        core.body.on("mouseup", "form p", function (e) {
            //if form has class 'withoutCss' 
            e.stopPropagation();
            this.className = this.className + " edited";
            that.$editor = _initRedactor.apply(core.iframejs, [
                this,
                core.plugins.toolbar.init($(this).closest(".dwrap"), "RedactorTools", {
                    fontsize: html.template("eOptionsFontSize"),
                    css: "dtoolbar-text"
                }),
                ee.t._locale,
                core.layoutStruct.cssStyle["p"]['direction']]);

        });
        return that;
    });

    _dragdrop_.addplugin("toolbar", function () {
        let core = ee.editor.core;
        let plugins = core.plugins;
        let buffer = plugins.buffer;
        let $toolBar;
        let $cell;

        var _render_ = function () {
            let CSSclass = "arrow_top";
            let offset = $cell.offset();
            let toolbarHeight = $toolBar.outerHeight(true);
            let ww = ee.editor.screen.templateframe.width();
            
            let leftOffset = 8;
            let templateElements = core.layoutStruct.layer1[0].children[0].children.length;
            
            if (offset.left + $toolBar.outerWidth(true) > ww) {
                leftOffset = (offset.left + $toolBar.outerWidth(true)) - ww;
                $toolBar.arrow.css("left", leftOffset);
            }
            
            if ($($cell.closest('tr')).is(':first-child')) {
                $(ee.editor.screen.templateframe[0].contentDocument).find('.dtoolbar.ee-toolbar').css({position: 'sticky'});
                $toolBar.css({ "top": "-" + toolbarHeight + "px", left: leftOffset * -1 });
            } else if ((offset.top - 80) < 0 && templateElements > 1 && !$($cell.closest('tr')).is(':last-child')) {
                CSSclass = "arrow_bottom";
                $toolBar.css({ bottom: "-" + toolbarHeight + "px", left: leftOffset * -1 });
                $toolBar.arrow.css("bottom", toolbarHeight);
            } else if (templateElements === 1) {
                const pxToSubtract = offset.top < toolbarHeight ? (toolbarHeight - offset.top -1) : 14 ;
                $toolBar.css({ "top": "-" + (toolbarHeight - pxToSubtract) + "px", left: leftOffset * -1 });
            } else {
                $toolBar.css({ "top": "-" + toolbarHeight + "px", left: leftOffset * -1 });
            }
            $toolBar.arrow.addClass(CSSclass);
        },
        _commonEvents_ = function () {
            //Row backgroundColor
            if ($toolBar.rowbackground) {
                var $drow = $cell.closest(".drow"), styleDrow = $drow.attr("style") || "";
                $toolBar.rowbackground.val($drow[0].style.backgroundColor);
                $toolBar.find("#drowbackground").spectrum({
                    preferredFormat: "hex",
                    showInitial: true,
                    showPalette: true,
                    showInput: true,
                    allowEmpty: true,
                    change: function(color) {
                        if (!color) {
                            $drow.css("background-color", "");
                            return false;
                        }
                        color = color.toString();
                        if (core.layoutStruct.cssStyle['.drow']['background-color'] !== color) {
                            $drow.css("background-color", color);
                        } else {
                            $drow.css("background-color", "");
                        }
                    },
                    show: function(color) {
                        positionColorpicker($toolBar, "#drowbackground");
                    }
                });
            }
            // Cell BackgroundColor
            if ($toolBar.backgroundcolor) {
                var $edcontent = $cell.find(".edcontent:first"), styleEdcontent = $edcontent.attr("style") || "";
                $toolBar.backgroundcolor.val($edcontent[0].style.backgroundColor);


                $toolBar.find("#dbackgroundcolor").spectrum({
                    preferredFormat: "hex",
                    showInitial: true,
                    showInput: true,
                    showPalette: true,
                    allowEmpty: true,
                    change: function(color) {
                        if (!color) {
                            $edcontent.css("background-color", "");
                            return false;
                        }
                        color = color.toString();
                        if (core.layoutStruct.cssStyle['.drow']['background-color'] !== color) {
                            $edcontent.css("background-color", color);
                        } else {
                            $edcontent.css("background-color", "");
                        }
                    },
                    show: function(color) {
                        positionColorpicker($toolBar, "#dbackgroundcolor");
                    }
                });
            }
            //CellPadding
            if ($toolBar.cellpadding) {
                var $tds = $cell.find(".edcontent:first > tbody:first > tr > td"), $firstTD = $($tds[0]);
                $toolBar.cellpadding.val($firstTD.css('padding-top')).change(function () {
                    buffer.set();
                    var newCellPading = parseInt($(this).val()),
                        currentPadding = parseInt($firstTD.attr("padding")),
                        dx = (currentPadding - newCellPading) * 2;
                    $tds.css("padding", newCellPading);
                    $tds.filter(".edimg img").each(function () {
                        core.plugins.imageeditor.resizeimage($(this), dx, true);
                    });
                    core.isChange();
                });
            }
            //Close ToolBar
            $toolBar.close.click(function (e) {
                core.disableTools();
                
            });
        },
        that = {
            init: function (cell, templateName, options) {
                core.disableTools();
                $cell = cell;//.addClass("dtoolbar-enable");
                $toolBar = html.get(templateName, options);
                $cell.prepend($toolBar);
                var $edcontent = $cell.find('.edcontent');
                if ($edcontent.hasClass('revoked') === false) {
                    core.bufferGlobal.set();
                    buffer.init($edcontent);
                }
                $edcontent.removeClass('revoked');
                _render_();
                _commonEvents_();
                return $toolBar;
            },
            disable: function () {
                if ($toolBar) {
                    $toolBar.empty().remove();
                    $toolBar = false;
                    $cell.find(".edited").removeClass("edited");
                    var $edcontent = $cell.find('.edcontent');
                    if ($edcontent.hasClass('revoked') === false) {
                        buffer.destroy();
                    }
                }
            },
            remove: function ($contents) {
                if ($toolBar && $contents) {
                    $contents.find('.edited').removeClass("edited");
                    $contents.find('.dtoolbar').remove();
                }
            }
        };
        return that;
    });

    _dragdrop_.addplugin("imageeditor", function () {
        var core = ee.editor.core;
        var that = {};
        var $toolBar = null;
        var $edimg = null;
        var buffer = core.plugins.buffer;
        var _bindOptions = function () {
            var $img = $edimg.find("img"), $edcontent = $edimg.closest(".edcontent"), $layer2 = $edimg.closest(".layer_2");
            $img.on('dragstart', (e) => {e.preventDefault();})

            //Resize use slidebar, init in iframe
            core.iframejs.resizeImg($img[0], $toolBar[0]);
            //Full fill cell by image
            $toolBar.fill.click(function () {
                that.scaleimage($img);
                core.iframejs.recalculateSlid($img.width());
            });
            //Link
            $toolBar.link.click(function () {
                var $ahref = $img.closest('a'), isWrap = ($ahref.length === 1);
                var $win = html.get("ImageLink", { url: $ahref.attr("href"), alt: $img.attr("alt") });
                var modal = html.modal.create($win, {
                    title: ee.t.insertlink,
                    buttons: [{
                        name: ee.t.insertlink,
                        css: 'btn_lg btn_primary',
                        callback: function () {
                            buffer.set();
                            var url = $win.link.val(), alt = $win.alt.val();
                            if (url.length > 0) {
                                if (isWrap) {
                                    $ahref.attr({ 'href': url, 'title': alt });
                                } else {
                                    $img.wrap('<a href="' + url + '" title="' + alt + '"></a>');
                                }
                            } else if (isWrap) {
                                $img.unwrap();
                            }
                            $img.attr("alt", alt);
                            modal.close();
                            core.isChange(true);
                        }
                    }]
                });
                modal.$title.addClass("modalButtonTitle");
                modal.$container.css("border-radius", "2px");
                $win.mediamanager.addClass("modalButtonMediaLink");
                $win.mediamanager.on("click", function () {
                    var $el = $win.link;
                    window.openMediaManager(oldMediaManager, $el, html.widget.FileManagerUpload.callbackFiles, 'files');
                    
                    function oldMediaManager() {
                        html.widget.FileManager.init(function (url, listname) {
                            html.widget.FileManagerUpload.callbackFiles(url, $el);
                        }, "files");
                    }
                });
            });
            
            //File browser
            $toolBar.filebrowser.click(function () {
                const oldImgHeight = $img.height();
                const oldTemplateHeight = $('#dtemplateframe').height();

                function changeImage(url, $img) {
                    html.widget.FileManagerUpload.callbackImg(url, $img);
                    setTimeout(() => {
                        let newImageHeight = $img.height();
                        let newHeight = oldTemplateHeight - oldImgHeight + newImageHeight;
                        $('#dtemplateframe').height(newHeight);
                    }, 1000)
                }
                window.openMediaManager(oldMediaManager, $img, changeImage, 'images');
                
                function oldMediaManager() {
                    html.widget.FileManager.init(function (url, listname) {
                        changeImage(url, $img)
                    }, "images");
                }

            });
            //Bordercolor
            $toolBar.bordercolor.val($img.css("border-top-color"));

            $toolBar.find("#dbordercolor").spectrum({
                preferredFormat: "hex",
                showInitial: true,
                showInput: true,
                showPalette: true,
                allowEmpty: true,
                change: function(color) {
                    if (!color) {
                        $img.css("border-color", "");
                    } else {
                        $img.css("border-color", color.toString());
                    }
                },
                show: function(color) {
                    positionColorpicker($toolBar, "#dbordercolor");
                }
            });
            //Border style
            $toolBar.borderstyle.val($img.css("border-left-style")).change(function () {
                buffer.set();
                $img.css("border-style", $(this).val());
                core.isChange(true);
            });
            //Border weight
            $toolBar.borderwidth.val(parseInt($img.css("border-top-width")) + "px").change(function () {
                buffer.set();
                var val = parseInt($(this).val()), dx = (parseInt($img.css("border-top-width")) - val) * 2;
                $img.css("border-width", $(this).val());
                that.resizeimage($img, dx, true);
                core.isChange();
            });
        };

        that = {
            scaleimage: function ($img, toWidth) {
                if ($img.length === 0) return;
                setTimeout(function() {
                    var maxWidth = $img.closest("td").width() - (parseInt($img.css("border-top-width")) * 2), imgWidth = $img.width();
                    if (!toWidth) {
                        if (imgWidth > maxWidth) {
                            toWidth = maxWidth;
                        } else {
                            toWidth = imgWidth;
                        }
                    }
                    $img.css({ "max-width": toWidth + "px", "width": "100%" }).attr("width", toWidth);
                }, 0)
            },
            resizeimage: function ($img, dx, skipbuffer) {
                if (!skipbuffer) buffer.set();
                setTimeout(function() {
                    var imgWidth = parseInt($img.css("max-width")) + dx,
                    maxWidth = $img.closest(".edimg").width() - (parseInt($img.css("border-top-width")) * 2);
                    if (imgWidth > maxWidth) {
                        imgWidth = maxWidth;
                    }
                    $img.css({ "max-width": imgWidth + "px", "width": "100%" }).attr("width", imgWidth);
                }, 0);
            },
        };
        // Create image promise
        const createImagePromise = function (url, $el) {
            return new Promise(function (resolve, reject) {
                let img = new Image();
                let $container = $el.closest(".edimg");
                img.addEventListener('load', e => {
                    resolve(img);
                });
                img.addEventListener('error', () => {
                    reject(new Error(`Failed to load image's URL ${url}`));
                });
                img.src = url;
                img.setAttribute("alt", ee.t.image);
                $el.replaceWith(img);

                $container.removeClass('dnoimg');
            });
        }
        //Callback for adding image
        html.widget.FileManagerUpload.callbackImg = function (url, $el, resolve, reject) {            
            buffer.set();
            ee.indiOn();
            var $img;
            //$el = this.element;
            if ($el.is("img") === true) {
                //Replace Image
                $el.attr("src", url).on('load', function (e) {
                    $el.css({ "max-width": "", "width": "" });
                    that.scaleimage($el, $el.width());
                    core.iframejs.imageLoad();
                    $el.off();
                });
            } else {
                $img = $("<img/>").css({ "border-width": 0, "border-color": "", "border-style": "none" });
                let oldImgHeight = $img.height();
                if (oldImgHeight === 0) {
                    oldImgHeight = 126;
                }
                let oldIframeHeight = $('#dtemplateframe').height();
                $img.off().on('load', function (e) {
                    that.scaleimage($img, $img.width());
                    core.iframejs.recalculateSlid($img.width());
                    core.iframejs.imageLoad();
                    $img.off();
                    if (resolve) {
                        resolve($img);
                    }
                    setTimeout(() => {
                        let newImageHeight = e.currentTarget.height;
                        let newHeight = oldIframeHeight - oldImgHeight + newImageHeight;
                        $('#dtemplateframe').height(newHeight);
                    }, 1000)
                    
                });
                var $container = $el.closest(".edimg");
                
                $container.html($img).removeClass('dnoimg');
                $img.attr("src", url).attr("alt", ee.t.image);
            }
            core.isChange();
            if (this.modalwin) {
                this.modalwin.close();
            }
            if ($img) {
                return $img;
            }
        };
        //Common event when user click on edimg cells
        core.body.on("mousedown", ".edimg:not(.edited):not(.dnoimg)", function (e) {
            e.stopPropagation();
            $edimg = $(this);
            $edimg.addClass('edited');
            var $dwrap = $edimg.closest(".dwrap");
            $toolBar = core.plugins.toolbar.init($dwrap, "ImageTools", { fontsize: html.template("eOptionsFontSize"), css: "dtoolbar-image" }).show();
            _bindOptions();
        });
        core.body.on("mousedown", ".rss-block__paragraph", function (e) {
            const $thisCell = $(this);
            const $thisRow = $thisCell.parents(".drow").parent();
            const isEdited = $thisCell.hasClass("rss-edited");

            // Display Modal to setup RSS
            let $modal = html.modal.create(html.get("RSSSetupModal", {
                isEdited,
                rssURL: $thisCell[0].dataset.rssUrl,
                showCondition: $thisCell[0].dataset.showCondition,
                count: $thisCell[0].dataset.count,
                itemsToShowCounter: html.getraw("RSSSetupModalItemsToShowCounter", {isOnlyNewItemsSelected: $thisCell[0].dataset.showCondition === "new"})
            }), {
                title: 'RSS Feed Content',
                width: '40%',
                css: 'rss-modal',
                footerCSS: 'rss-modal__footer',
                buttons: [{
                    name: 'Continue',
                    css: 'btn btn_lg btn_primary js-rss-modal__btn-confirm',
                    disabled: true,
                    callback: e => {
                        $modal.close();
                    },
                }],
                onShow: (modal, $container) => {
                    const $btnGrabRSS = $container.find(".js-rss-modal__btn--grab-rss");
                    const $btnConfirm = $container.find(".js-rss-modal__btn-confirm");
                    const $postsShowConditionRadio = $container.find("input[type=radio][name=show-condition]");
                    const $rssLinkInput = $("#rss-url");
                    const $feedItemsToShowSelectorWrapper = $container.find("#feed-items-to-show-wrapper");
                    let postsShowCondition = $container.find("input[type=radio][name=show-condition]:checked").val();
                    let pickedTitles;
                    let rssLink;

                    const grabRssFeed = event => {
                        $container.find(".js-rss-modal__error").html("");

                        disableAllRSSModalInputs($container[0].querySelectorAll("input, select"));

                        $btnGrabRSS.attr("disabled", true);
                        $btnGrabRSS.width($btnGrabRSS.width() - 4);
                        $btnGrabRSS.html('<i class="fa fa-spinner fa-pulse fa-5x rss-modal__spinner--btn"></i>');

                        let parser = new RSSParser({
                            customFields: {
                                item: [
                                    ['media:content', 'image', {keepArray: true}],
                                ]
                            }
                        });

                        return new Promise(function(resolve, reject) {
                            API_V3.Templates.FetchRss($rssLinkInput.val()).then((xml) => {
                                parser.parseString(xml, function (err, feed) {
                                    if (err) {
                                        $container.find(".js-rss-modal__error").html(ee.t.rssfetcherrormessage);
                                        reject(err);
                                    } else {
                                        if (!ee.data.rssFeeds) {
                                            ee.data.rssFeeds = {};
                                        }
                                        ee.data.rssFeeds[$rssLinkInput.val()] = feed;
                                        enableRSSModalInputs($container[0].querySelectorAll("input, select"));
                                    }

                                    enableRSSModalInputs($container[0].querySelectorAll("input#rss-url"));

                                    $btnGrabRSS.html(ee.t.successexclamationmark);
                                    setTimeout(() => {
                                        $btnGrabRSS.html(ee.t.grabrssfeedbutton);
                                        $btnGrabRSS.removeAttr("style");
                                        $btnGrabRSS.removeAttr("disabled");
                                        resolve(feed, $rssLinkInput.val());
                                    }, 500);
                                });
                            })
                        });
                    }

                    if (isEdited) {
                        // enableRSSModalInputs($container[0].querySelectorAll("input, select"));


                        grabRssFeed()
                        .then((rssFeed, rssLink) => {
                            $container.find("input[type=radio][name=show-condition][value=" + $thisCell[0].dataset.showCondition + "]").click();

                            $container.find("#rss-posts-to-show-count").val($thisCell[0].dataset.count);

                            $btnConfirm[0].removeAttribute('disabled');
                        })
                        .catch(err => console.error(err));
                    }

// https://www.nytimes.com/svc/collections/v1/publish/https://www.nytimes.com/section/world/rss.xml

                    $btnGrabRSS.on("click", grabRssFeed);

                    $postsShowConditionRadio.on("change", function () {
                        postsShowCondition = $(this).val();
                        rssLink = $rssLinkInput.val();

                        switch (postsShowCondition) {
                            case "specific":
                                // User entered feed url and clicked "Grab RSS Feed" button
                                if (rssLink && ee.data.rssFeeds && ee.data.rssFeeds[rssLink]) {
                                    const $rssItemsContent = html.get("RSSSetupModalItemsToShowSelector", {posts: ee.data.rssFeeds[rssLink].items});
                                    const $rssItemsCheckboxes = $rssItemsContent.find("input");

                                    $feedItemsToShowSelectorWrapper.html($rssItemsContent);

                                    $btnConfirm[0].disabled = true;

                                    $rssItemsCheckboxes.on("change", e => {
                                        pickedTitles = collectPickedRSSTitles($rssItemsCheckboxes);

                                        if (pickedTitles.length > 0) {
                                            $btnConfirm[0].removeAttribute("disabled");
                                        } else {
                                            $btnConfirm[0].disabled = true;
                                        }
                                    });
                                }
                                break;
                            case "all":
                            case "new":
                            default:
                                if (ee.data.rssFeeds && ee.data.rssFeeds[rssLink]) {
                                    $btnConfirm[0].removeAttribute("disabled");
                                } else {
                                    $btnConfirm[0].disabled = true;
                                }
                                $feedItemsToShowSelectorWrapper.html(html.get("RSSSetupModalItemsToShowCounter", { isOnlyNewItemsSelected: postsShowCondition === "new"}));
                                break;
                        }
                    });
                    $btnConfirm.on("click", function () {
                        const imagePromises = [];
                        let pickedRSSItems;
                        postsShowCondition = $container.find("input[type=radio][name=show-condition]:checked").val();

                        switch (postsShowCondition) {
                            case "specific":
                                // Generate email html from ee.data.rssFeeds items filtered by pickedTitles
                                pickedRSSItems = filterPickedRSSItems(ee.data.rssFeeds[rssLink], pickedTitles);
                                _.forEach(pickedRSSItems, rssItem => {
                                    let tempHTML;
                                    if (rssItem.image) {
                                        // Get image mockup
                                        tempHTML = html.get("DrowWrapper", {
                                            content: Mustache.render('<div class="dwrap">{{& content}}</div>', { content: html.getraw("MockupImg", { cellpadding: "20px" }) })
                                        });
                                        // Replace dummy image with the real one
                                        imagePromises.push(createImagePromise(rssItem.image[rssItem.image.length-1]["$"].url, tempHTML.find(".esample")));

                                        // Prepend editor functionality div
                                        tempHTML.find(".dwrap").prepend(html.getraw("CellTools"));

                                        $thisRow.before(tempHTML);
                                    }
                                    if (rssItem.title || rssItem.content) {
                                        tempHTML = html.get("DrowWrapper", {
                                            content: Mustache.render('<div class="dwrap">{{& content}}</div>', { content: html.getraw("MockupText", { cellpadding: "20px", title: rssItem.title, content: rssItem.content, link: rssItem.link }) })
                                        });

                                        // Prepend editor functionality div
                                        tempHTML.find(".dwrap").prepend(html.getraw("CellTools"));

                                        $thisRow.before(tempHTML);
                                    }
                                });

                                $thisRow.remove();

                                Promise.all(imagePromises)
                                .then(images => {
                                    ee.editors.etemplate.ddeditor.__refreshLayoutStruct();
                                });

                                break;
                            case "all":
                            case "new":
                            default:
                                // Generate pullcontent-rss merge tag
                                $thisCell.find("p").html(generateRSSMergeTag($container));
                                $thisCell.addClass("rss-edited");
                                $thisCell[0].removeAttribute("data-show-condition");
                                $thisCell[0].removeAttribute("data-count");
                                $thisCell[0].removeAttribute("data-rss-url");
                                $thisCell[0].dataset.showCondition = postsShowCondition;
                                $thisCell[0].dataset.count = $container.find("#rss-posts-to-show-count").val();
                                $thisCell[0].dataset.rssUrl = $container.find("#rss-url").val();
                                break;
                        }
                    });
                }
            });
        });
        // New image
        core.body.on("mousedown", ".edimg.dnoimg", function () {
            let $elem = $(this).find(".esample");
            let newImage = $(this)
            window.openMediaManager(oldMediaManager, $elem, html.widget.FileManagerUpload.callbackImg, 'images');
            
            function oldMediaManager() {
                html.widget.FileManager.init(function (url, listname) {
                    html.widget.FileManagerUpload.callbackImg(url, $elem);
                    
                }, "images");
            }
        });
        return that;
    });
    _dragdrop_.addplugin("formeditor", () => { 
        const core = ee.editor.core;
        let that = {};
        let $toolBar = null;
        let $edform = null;
        const buffer = core.plugins.buffer;
        const _bindOptions = () => {
            let $form = $edform.find("form");
            let $edcontent = $edform.closest(".edcontent");
            let $layer2 = $edform.closest(".layer_2");
            //Resize use slidebar, init in iframe
            core.iframejs.resizeImg($form[0], $toolBar[0]);
            //File browser
            $toolBar.filebrowser.click(function() {
                    let t = $(this);
                    let elem = $(t.parent().parent().parent().parent().find("td.edform"))
                    // html.widget.FormSelect.chooseFormCallback($form);
                    html.widget.FormSelect.init(function (formid, html, css) {

                        let formHtml = $(html)
                        formHtml.find('input[name="publicaccountid"]').val(ee.data.account.publicaccountid);
                        
                        html = formHtml[0].outerHTML;
                        addIdToCssClasses(css, formid, html, elem, true);
                        let newHeight = $('#dtemplateframe').height() + elem.height();
                        $('#dtemplateframe').height(newHeight);
                    });
            });
            
            $toolBar.showhtml.click( function() {
                let showhtmlbutton = $(this);
                let elem = $(showhtmlbutton.parent().parent().parent().parent().find("td.edform"));
                const formCode = elem.html();
                if (elem.html().includes('textarea')) {
                    const textArea = elem.find('textarea')[0];
                    const oldForm = $(textArea).val();
                    elem.html(oldForm);
                } else {
                    elem.html(`<textarea rows="10" cols="50" style="width: 100%; margin: 0px; background: rgb(29, 29, 29); box-sizing: border-box; color: rgb(204, 204, 204); font-size: 15px; outline: none; padding: 20px; line-height: 24px; font-family: Consolas, Menlo, Monaco, "Courier New", monospace; height: 70px;">${formCode}</textarea> `)
                    const textArea = elem.find('textarea')[0];
                    $(textArea).focus();
                    switchToForm = () => {
                        const changedForm = $(textArea).val();
                        elem.html(changedForm);
                    }
                    textArea.addEventListener('change', () => {
                        switchToForm();
                    })
                    $(textArea).on('focusout', () => {
                        switchToForm();
                    })
                }
            });
            
            // $toolBar.
            $toolBar.formStyles.prop("checked",$toolBar.parent().parent().find("style").length);
            $toolBar.on('change','#eformStyles', function() {
                
                let formstylecheckbox = $(this); 
                let elem = $(formstylecheckbox.parent().parent().parent().parent().find("span.formWithCss"))
                let wrapper = elem.parent();
                let form = elem.children("form[name='editor-attributes']");
                let formId = form[0].formid.value;
                
                if (!($toolBar.formStyles.is(':checked'))) {
                    html.widget.FormSelect.toggleStyle(wrapper, formId, false);
                } else {
                    html.widget.FormSelect.toggleStyle(wrapper, formId, true);
                }
            });
        };
        addIdToCssClasses = (css, formid, html, elem, useStyle) => {
            let currentDate = moment().unix();
            formid = `form${formid}${currentDate}`;
            let rulesForCssText = (styleContent) => {
                let doc = document.implementation.createHTMLDocument("");
                let styleElement = document.createElement("style");

                styleElement.textContent = styleContent;
                doc.body.appendChild(styleElement);
                return styleElement.sheet.cssRules;
            };

            css = css.replace(/<style id="ewf_popup"[\s\S]*[/]style>/g, ''); 
            css = css.replace(/<style id="ewf_sticky"[\s\S]*[/]style>/g, ''); 
            css = css.replace(/<style.*>/g, '');
            css= css.replace(/<[/]style>/g, '');
            let rules = rulesForCssText(css);
            
            const prefix = `#${formid}`;
            let cssWithPrefix = "";
            for (let i = 0; i < rules.length; i++) {
                let rule = rules[i];
                rule.selectorText = `${prefix} ${rule.selectorText}`;
                cssWithPrefix = `${cssWithPrefix}${rule.cssText} `;
            }
            
            let formHtml = $(html)
            formHtml.find('input[name="publicaccountid"]').val(ee.data.account.publicaccountid);
            html = formHtml[0].outerHTML;

            if(useStyle){ 
                elem.html(`<span class="formWithCss" id="${formid}"><form name="editor-attributes"><input type="hidden" name="formid" value="${formid}" /></form><style> label {font-weight: 600} ${cssWithPrefix}</style> ${html} </span>`);
            } else {
                elem.html(`<span class="formWithCss" id="${formid}"><form name="editor-attributes" class="withoutCss"><input type="hidden" name="formid" value="${formid}" /></form> ${html} </span>`);
            }
            elem.removeClass("dnoform");
            
        }
        that = {
            scaleimage: ($form, toWidth) => {
                if ($img.length === 0) return;
                setTimeout(() => {
                    let maxWidth = $form.closest("td").width() - (parseInt($form.css("border-top-width")) * 2), formWidth = $form.width();
                    if (!toWidth) {
                        if (formWidth > maxWidth) {
                            toWidth = maxWidth;
                        } else {
                            toWidth = formWidth;
                        }
                    }
                    $form.css({ "max-width": toWidth + "px", "width": "100%" }).attr("width", toWidth);
                }, 0)
            },
            resizeimage: ($form, dx, skipbuffer) => {
                if (!skipbuffer) buffer.set();
                setTimeout(() => {
                    var formWidth = parseInt($form.css("max-width")) + dx,
                    maxWidth = $img.closest(".edform").width() - (parseInt($form.css("border-top-width")) * 2);
                    if (formWidth > maxWidth) {
                        formWidth = maxWidth;
                    }
                    $form.css({ "max-width": formWidth + "px", "width": "100%" }).attr("width", formWidth);
                }, 0);
            },
        };
        //Callback for adding form
        html.widget.FormSelect.chooseFormCallback = ($el, form) => {
            buffer.set(); 
            core.isChange();
            
        };
        //Common event when user click on edform cells
        core.body.on("mousedown", ".edform:not(.edited):not(.dnoform)", function (e) {
            e.stopPropagation();
            $edform = $(this);
            $edform.addClass('edited');
            var $dwrap = $edform.closest(".dwrap");
            $toolBar = core.plugins.toolbar.init($dwrap, "FormTools", { fontsize: html.template("eOptionsFontSize"), css: "dtoolbar-form" }).show();
            _bindOptions();
        });
        core.body.on("mousedown", ".edform.dnoform", function () {
        var $elem = $(this);
        
        html.widget.FormSelect.init((formid, html, css) => {
            $elem.removeClass("dnoform");
            addIdToCssClasses(css, formid, html, $elem, true);
            let newHeight = $('#dtemplateframe').height() + $elem.height();
            $('#dtemplateframe').height(newHeight);
        });
    });
        return that;
    });
    _dragdrop_.addplugin("breakline", function () {
        const core = ee.editor.core;
        const buffer = core.plugins.buffer;
            that = {
                init: function (container) {
                    var $breakLine = $(container), $hr = $breakLine.find("p:first"),
                        $toolBar = core.plugins.toolbar.init($breakLine.closest(".dwrap"), "CellLineTools", { fontsize: html.template("eOptionsFontSize"), css: "toolbar-breakline" }).show();

                    $toolBar.verticalmargin.find("option:lt(1)").remove();
                    //Border style
                    $toolBar.borderstyle.val($hr.css("border-top-style")).change(function () {
                        buffer.set();
                        $hr.css("border-top-style", $(this).val());
                        core.isChange();
                    });
                    //Bordercolor
                    $toolBar.bordercolor.val($hr.css("border-top-color"));

                    $toolBar.find("#dbordercolor").spectrum({
                        preferredFormat: "hex",
                        showInitial: true,
                        showInput: true,
                        showPalette: true,
                        allowEmpty: true,
                        change: function(color) {
                            if (!color) {
                            $hr.css("border-top-color", "");
                            } else {
                                $hr.css("border-top-color", color.toString());
                            }
                        },
                        show: function(color) {
                            positionColorpicker($toolBar, "#dbordercolor");
                        }
                    });
                    //Border weight
                    $toolBar.borderwidth.val(parseInt($hr.css("border-top-width")) + "px").change(function () {
                        buffer.set();
                        $hr.css("border-top-width", $(this).val());
                        core.isChange();
                    });
                    //horizontalmargin
                    $toolBar.horizontalmargin.val($hr.css("margin-left")).change(function () {
                        buffer.set();
                        $hr.css({ "margin-left": $(this).val(), "margin-right": $(this).val() });
                        core.isChange();
                    });
                    //verticalmargin
                    $toolBar.verticalmargin.val($hr.css("margin-top")).change(function () {
                        buffer.set();
                        $hr.css({ "margin-top": $(this).val(), "margin-bottom": $(this).val() });
                        core.isChange();
                    });
                }
            };
        core.body.on("mousedown", ".breakline:not(.edited)", function (e) {
            e.stopPropagation();
            this.className = this.className + " edited";
            that.init(this);
        });

        return that;
    });

    _dragdrop_.addplugin("emptycell", function () {
        var core = ee.editor.core,
            that = {
                init: function (cell) {
                    var $toolBar = core.plugins.toolbar.init($(cell).closest(".dwrap"), "CellCommonTools", {
                        fontsize: html.template("eOptionsFontSize"),
                        css: "dtoolbar-common"
                    }).show();
                    $toolBar.cellpadding.find("option:lt(1)").remove();
                }
            };
        core.body.on("mousedown", ".emptycell:not(.edited)", function (e) {
            e.stopPropagation();
            this.className = this.className + " edited";
            that.init(this);
        });
        return that;
    });

    _dragdrop_.addplugin("buttons", function () {
        var core = ee.editor.core, $ahref, buffer = core.plugins.buffer,
            that = {
                init: function (cell) {
                    var $button = $(cell).find("td:first"),
                        $toolBar = core.plugins.toolbar.init($button.closest(".dwrap"), "CellButtonsTools", {
                            fontsize: html.template("eOptionsFontSize"),
                            fontfamily: html.template("eOptionsFontFamily"),
                            css: "dtoolbar-buttons"
                        }).show();
                    $ahref = $button.find("a:first");
                    $ahref.find("span:first").attr("contenteditable", "true");
                    //Change border-radius
                    $toolBar.buttonradius.change(function () {
                        buffer.set();
                        $button.css("border-radius", $(this).val());
                        core.isChange(true);
                    }).val($button.css('border-top-left-radius'));
                    //Change background-color
                    $toolBar.buttonbackground.val($button.css('background-color'));

                    $toolBar.find("#dbuttonbackground").spectrum({
                        preferredFormat: "hex",
                        showInitial: true,
                        showPalette: true,
                        showInput: true,
                        allowEmpty: true,
                        change: function(color) {
                            if (!color) { color = "transparent" };
                            $button.css("background", color.toString());
                        },
                        show: function(color) {
                            positionColorpicker($toolBar, "#dbuttonbackground");
                        }
                    });
                    //Change padding
                    $toolBar.buttonpadding.change(function () {
                        buffer.set();
                        $button.css("padding", $(this).val());
                        core.isChange();
                    }).val($button.css('padding-top'));
                    //Change alignment
                    $toolBar.on('change', '#dbuttonalignment', function () {
                        buffer.set();
                        var align = $(this).val();
                        $button.parent().parent().parent().attr('align', align).css('margin', ((align === 'center') ? '0 auto' : ''));
                        core.isChange();
                    });
                    $toolBar.buttonalignment.val($button.parent().parent().parent().attr('align'));
                    $toolBar.linkbutton.click(function () {
                        var $win = html.get("ButtonLink", { url: $ahref.attr("href") });
                        var modal = html.modal.create($win, {
                                title: ee.t.insertlink,
                                buttons: [{
                                    name: ee.t.insertlink,
                                    css: 'btn_lg btn_primary',
                                    callback: function () {
                                        buffer.set();
                                        var url = $win.link.val();

                                        if (!url) {
                                            $ahref.removeAttr('href target');
                                        } else {
                                            var isHttp = new RegExp("^(http|https|ftp|mailto):", "i");
                                            var isBracket = new RegExp('\{', 'i');
                                            if (!isHttp.test(url) && !isBracket.test(url)) {
                                                url = "http://" + url;
                                            }
                                            $ahref.attr({ 'href': url, 'target': '_blank' });
                                        }
                                        modal.close();
                                        core.isChange();
                                    }
                                }]
                        });
                        modal.$title.addClass("modalButtonTitle");
                        modal.$container.css("border-radius", "2px");
                        $win.mediamanager.addClass("modalButtonMediaLink");
                        $win.mediamanager.on("click", function () {
                            var $el = $win.link;
                            window.openMediaManager(oldMediaManager, $el, html.widget.FileManagerUpload.callbackFiles, 'files');
                            
                            function oldMediaManager() {
                                html.widget.FileManager.init(function (url, listname) {
                                    html.widget.FileManagerUpload.callbackFiles(url, $el);
                                }, "files");
                            }
                        });
                    });
                    $toolBar.boldbutton.click(function () {
                        buffer.set();
                        if ($ahref.css("font-weight") === "bold") {
                            $ahref.css("font-weight", "normal");
                        } else {
                            $ahref.css("font-weight", "bold");
                        }
                        core.isChange();
                    });
                    $toolBar.italicbutton.click(function () {
                        buffer.set();
                        if ($ahref.css("font-style") === "italic") {
                            $ahref.css("font-style", "normal");
                        } else {
                            $ahref.css("font-style", "italic");
                        }
                        core.isChange(true);
                    });
                    //Font Family
                    $toolBar.buttonfontfamily.change(function () {
                        buffer.set();
                        $ahref.css("font-family", $(this).val());
                        core.isChange();
                    }).val($ahref.css("font-family").replace(/"/g, '\'').replace(/, /g, ','));
                    //Font size
                    $toolBar.buttontextfontsize.change(function () {
                        buffer.set();
                        $ahref.css("font-size", $(this).val());
                        core.isChange();
                    }).val($ahref.css("font-size"));
                    //Text Color
                    $toolBar.buttontextcolor.val($ahref.css("color"));

                    $toolBar.find("#dbuttontextcolor").spectrum({
                        preferredFormat: "hex",
                        showInitial: true,
                        showPalette: true,
                        showInput: true,
                        allowEmpty: true,
                        change: function(color) {
                            if (!$ahref) return;
                            if (!color) { color = "#000000" };
                            $ahref.css("color", color.toString());
                        },
                        show: function(color) {
                            positionColorpicker($toolBar, "#dbuttontextcolor");
                        }
                    });
                    //Clean Past Content
                    $button.find("span[contenteditable=true]").off("paste").on("paste", function (e) {
                        buffer.set();
                        e.preventDefault();
                        var text = e.originalEvent.clipboardData.getData("text/plain") || "";
                        $(this).html(text);
                        core.isChange();
                        return false;
                    });
                },
                disable: function () {
                    if ($ahref) {
                        $ahref.find("span:first").removeAttr("contenteditable");
                        $ahref = false;
                    }
                },
            };
        html.widget.FileManagerUpload.callbackFiles = function (url, $el) {
            $el[0].value = url;
        }
        core.body.on("mousedown", ".edbutton:not(.edited)", function (e) {
            e.stopPropagation();
            this.className = this.className + " edited";
            that.init(this);
        });
        return that;
    });

    _dragdrop_.addplugin("socialfollow", function () {
        let core = ee.editor.core;
        let buffer = core.plugins.buffer;
        let $toolBar;
        let $edsocialfollow;
        let $edsocialfollowcontainer;
        let outlook = {
                start: '<!--[if mso]><table align="center" border="0" cellspacing="0" cellpadding="0"><tr><td align="center" valign="top"><![endif]-->',
                between: '<!--[if mso]></td><td align="center" valign="top"><![endif]-->',
                end: '<!--[if mso]></td></tr></table><![endif]-->'
            };
        let _setStarSettings = function () {
                var $linktext = $edsocialfollowcontainer.find('.linktext')
                var $img = $edsocialfollowcontainer.find('img'), $tableFirst = $edsocialfollowcontainer.find('table:first');
                var $textFirst = $($linktext[0]);
                var $imgFirst = $($img[0]);
                //display
                if ($linktext.length > 0) { $toolBar.socialfollowdisplay.val(($img.length === 0) ? 'Text' : 'Both'); }
                $toolBar.socialfollowdisplay.trigger('change', true);
                //align
                $toolBar.socialfollowalignment.val($edsocialfollowcontainer.attr('align'));
                //layout
                $toolBar.socialfollowlayout.val((($tableFirst.attr('align') === 'left' ? 'horizontal' : 'vertical') + (($toolBar.socialfollowdisplay.val() === 'Ico') ? '' : '-' + ($tableFirst.find('tr').length > 1 ? 'Below' : 'Inline'))));
                if ($imgFirst.length > 0) {
                    var parts = $imgFirst.attr('src').split('/').pop().split('_');
                    $toolBar.socialfollowicostyle.val(parts[0] + '_' + parts[1]);
                    $toolBar.socialfollowicofill.val(parts[2]);
                }
                //size ico
                $toolBar.socialfollowicosize.val(($imgFirst.css('max-width') || '32px'));
                //padding
                $toolBar.socialfollowicopadding.val($tableFirst.find('td:first').css('padding-left') || '10px');
                //text color
                $toolBar.socialfollowtextcolor.val(($textFirst.css('color') || core.layoutStruct.cssStyle['.edtexta']['color']));
                //text family
                $toolBar.socialfollowfontfamily.val(($textFirst.css('font-family') || core.layoutStruct.cssStyle['.edtext']['font-family']).replace(/"/g, '\'').replace(/, /g, ','));
                //text size
                $toolBar.socialfollowtextfontsize.val(($textFirst.css('font-size') || core.layoutStruct.cssStyle['.edtext']['font-size']));
            },
            _getSettings = function () {
                var layout = $toolBar.socialfollowlayout.val().split('-');
                return {
                    display: $toolBar.socialfollowdisplay.val(),
                    alignment: $toolBar.socialfollowalignment.val(),
                    direction: layout[0],
                    isVertical: (layout[0] === 'vertical'),
                    arrangement: (layout[1] ? layout[1] : ''),
                    directionalignment: layout[0] === 'horizontal' ? 'left' : 'center',
                    icostyle: $toolBar.socialfollowicostyle.val(),
                    icofill: $toolBar.socialfollowicofill.val(),
                    icosize: parseInt($toolBar.socialfollowicosize.val()),
                    icopadding: parseInt($toolBar.socialfollowicopadding.val()),
                    textcolor: $toolBar.socialfollowtextcolor.val(),
                    textfontfamily: $toolBar.socialfollowfontfamily.val(),
                    textfontsize: $toolBar.socialfollowtextfontsize.val(),
                }

            },
            _collectServices = function () {
                var items = [];
                $edsocialfollow.find(' > table table').each(function () {
                    var $this = $(this), $a = $this.find('a'), $linktext = $a.find('.linktext'), $img = $a.find('img'),
                        item = {
                            //img: $img,?
                            service: $this.data('service'),
                            linktext: ($linktext.length === 1) ? $linktext.text().trim() : $img.attr('alt'),
                            linkurl: ($a.length === 1) ? $a.attr('href') : $img.closest('a').attr('href'),
                            icourl: $img.attr('src')
                        };
                    items.push(item);
                });
                return items;
            },
            _changeIco = function () {
                buffer.set();
                var settings = _getSettings(), icopattern = 'https://api.elasticemail.com/userfile/a18de9fc-4724-42f2-b203-4992ceddc1de/' + settings.icostyle + '_' + settings.icofill + '_' + settings.icosize,
                    isTdWidth = (settings.arrangement === 'Inline' && settings.display === "Both");
                $edsocialfollowcontainer.find('table').each(function () {
                    var $table = $(this), $img = $table.find('img:first');
                    $img.attr('src', icopattern + '_' + $table.data('service') + '.png').css({ 'max-width': settings.icosize + 'px', 'width': settings.icosize + 'px' });
                    if (isTdWidth) {
                        var td = $img.closest('td')[0];
                        td.style.cssText = ((td.style.cssText.split(';')[0]) + ';width:' + settings.icosize + 'px!important');
                    }
                });
                core.isChange();
            },
            _changeLayout = function (items) {
                buffer.set();
                const $container = $edsocialfollowcontainer.find('> tbody > tr > td:first');
                const settings = _getSettings(); 
                items = (!items) ? _collectServices() : items; 
                const $content = $('<td>'); 
                if (settings.display === 'Text' && settings.arrangement === 'Inline') {
                    settings.arrangement = '';
                }
                let templatename = 'Socialfollow' + settings.display + settings.arrangement;
                for (var i = 0, n = items.length; i < n; i++) {
                    $content.append(html.get(templatename, { item: items[i], settings: settings }, true));
                }
                let socialContainerOldHeight = $edsocialfollowcontainer.height();
                if ($container.html() !== $content.html()) {
                    $edsocialfollowcontainer.width("");
                    $container.html($content.html());
                    const oldTemplateHeight = $('#dtemplateframe').height();
                    
                    if (settings.isVertical) {
                        $edsocialfollowcontainer.width(Math.ceil($edsocialfollowcontainer.width()));
                        
                        setTimeout(() => {
                            const containerNewHeight = $edsocialfollowcontainer.height();
                            let difference = containerNewHeight - socialContainerOldHeight;
                            const newTemplateHeight = oldTemplateHeight + difference;
                            $("#dtemplateframe").height(newTemplateHeight);
                            socialContainerOldHeight = $edsocialfollowcontainer.height();
                        }, 200)
                    } else {
                        setTimeout(() => {
                            const containerNewHeight = $edsocialfollowcontainer.height();
                            let difference = containerNewHeight - socialContainerOldHeight;
                            const newTemplateHeight = oldTemplateHeight + difference; 
                            $("#dtemplateframe").height(newTemplateHeight);
                            socialContainerOldHeight = $edsocialfollowcontainer.height();
                        }, 200)
                        
                    }
                
                    core.isChange();
                    return;
                }
                buffer.removeLastElement();
            };
            const isOutlokCommentAppended = (parentNode, commentString, beforeNode) => {
                let isAppended = false;
                let previousNode;
                _.forEach(parentNode.childNodes, el => {
                
                    let isBeforeNodeConditionMatched =  !beforeNode || (beforeNode == previousNode);
                    
                    if (isBeforeNodeConditionMatched &&_.isString(el.nodeValue) && (`<!--${el.nodeValue.trim()}-->` === commentString.trim())) {
                        isAppended = true;
                        
                    }
                    previousNode = el;
                });
                return isAppended;
            };
        var that = {
            init: function (cell) {
                $edsocialfollow = $(cell);
                $edsocialfollowcontainer = $edsocialfollow.find('.edsocialfollowcontainer');
                $toolBar = core.plugins.toolbar.init($edsocialfollow.closest(".dwrap"), "CellSocialfollowTools", {
                    fontsize: html.template("eOptionsFontSize"),
                    fontfamily: html.template("eOptionsFontFamily"),
                    css: "dtoolbar-socialfollow"
                }).show();
                // Layout
                $toolBar.on('change', '#dsocialfollowdisplay', function (e, skipChangeLayout) {
                    var layout = $toolBar.socialfollowlayout.val().split('-');
                    $toolBar.socialfollowlayout.find('option').hide();
                    if ($(this).val() === 'Both') {
                        $toolBar.socialfollowlayout.find('option:gt(1)').show();
                        if (layout.length === 1) {
                            $toolBar.socialfollowlayout.val(layout[0] + '-Inline')
                        }
                    } else {
                        $toolBar.socialfollowlayout.find('option:lt(2)').show();
                        if (layout.length === 2) {
                            $toolBar.socialfollowlayout.val(layout[0]);
                        }
                    }
                    if (skipChangeLayout) return;
                    _changeLayout();
                });
                //Setup toolbar start variable
                _setStarSettings();
                $toolBar.on('change', '#dsocialfollowlayout', function () {
                    _changeLayout();
                });
                $toolBar.on('change', '#dsocialfollowalignment', function () {
                    buffer.set();
                    var align = $(this).val();
                    $edsocialfollowcontainer.attr('align', align).css('margin', ((align === 'center') ? '0 auto' : ''));
                    core.isChange();
                });
                //Icon style
                $toolBar.on('change', '#dsocialfollowicostyle', function () { _changeIco(); });
                $toolBar.on('change', '#dsocialfollowicofill', function () { _changeIco(); });
                $toolBar.on('change', '#dsocialfollowicosize', function () { _changeIco(); });
                //Padding
                $toolBar.on('change', '#dsocialfollowicopadding', function () {
                    buffer.set();
                    var val = $(this).val();
                    $edsocialfollowcontainer.find('table').each(function () {
                        var $table = $(this), $tds = $table.find('td');
                        $tds.css('padding', val);
                        if ($tds.length === 1) return;
                        if ($table.find('tr').length === 2) {
                            $($tds[0]).css('padding', val + " " + val + " 0 " + val);
                            $($tds[1]).css('padding', "5px " + val + " " + val + " " + val);
                        } else {
                            $($tds[0]).css('padding', val + " 0 " + val + " " + val);
                            $($tds[1]).css('padding', val + " " + val + " " + val + " 5px");
                        }
                    });
                    core.isChange();
                });
                $toolBar.find("#dsocialfollowtextcolor").spectrum({
                    preferredFormat: "hex",
                    showInitial: true,
                    showPalette: true,
                    showInput: true,
                    allowEmpty: true,
                    change: function(color) {
                        if (!color) { color = "#000000" };
                        $edsocialfollow.find('a').css("color", color.toString());
                    }
                });
                $toolBar.on('change', '#dsocialfollowfontfamily', function () {
                    buffer.set();
                    $edsocialfollow.find('a').css("font-family", $(this).val());
                    core.isChange();
                });
                $toolBar.on('change', '#dsocialfollowtextfontsize', function () {
                    buffer.set();
                    $edsocialfollow.find('a').css("font-size", $(this).val());
                    core.isChange();
                });
                $toolBar.on('click', '#dcontenttoshare', function () {
                    const $win = html.get("SocialFollowShare");
                    const services = _collectServices();
                    const $addanotherservice = $(`<button class="btn btn-default pull-left btn_basic" id="eaddanotherservice" style="padding: 1.2rem">${ee.t.addanotherservice}</button>`);

                    _.forEach (services, (item) => {
                        const $item = html.get('SocialFollowShareItem', item);
                        $item.find('.dsocialfollowservice').val(item.service);
                        $item.appendTo($win.serviceslist);
                    })
                    $win.on('change', '.dsocialfollowservice', function () {
                        var $this = $(this), $li = $this.closest('.dsocialfollowitem'), $selected = $this.find('option:selected');
                        $li.find('img:first').attr('src', 'https://api.elasticemail.com/userfile/a18de9fc-4724-42f2-b203-4992ceddc1de/ro_sol_co_32_' + $this.val() + '.png').attr('alt', $selected.text())
                        $li.find('input[name="linkurl"]').val($selected.data('url'));
                        $li.find('input[name="linktext"]').val($selected.text());
                        $li.find('.dsocialfollowtitle').html($selected.data('title') || "Profile URL");
                    });
                    //add new service
                    $addanotherservice.click(function () {
                        if ($win.serviceslist.find('li').length >= 8) {
                            $(this).hide(); return;
                        }
                        let numberOfServices = services.length; 
                        const $item = html.get('SocialFollowShareItem', services[numberOfServices]);
                        $item.appendTo($win.serviceslist);
                        $item.find('.dsocialfollowservice').trigger('change');
                    });
                    //remove service
                    $win.on('click', '.removeitem', function () {
                        $addanotherservice.show();
                        $(this).closest('.dsocialfollowitem').remove();
                    });
                    var modal = html.modal.create($win, {
                        title: 'Social Follow',
                        width: '700px',
                        buttons: [{
                            name: ee.t.ok,
                            css: 'btn_primary btn_lg',
                            callback: function () {
                                var items = [];
                                $win.serviceslist.find('li').each(function () {
                                    var $li = $(this);
                                    items.push({
                                        service: $li.find('.dsocialfollowservice').val(),
                                        linktext: $li.find('input[name="linktext"]').val(),
                                        linkurl: $li.find('input[name="linkurl"]').val()
                                    });
                                });
                                _changeLayout(items);
                                modal.close();
                            },
                        }]
                    });
                    $addanotherservice.prependTo(modal.$footer);
                    $win.sortable({
                        items: '.dsocialfollowitem',
                        handle: '.dmovesocialfollow',
                        opacity: 0.5,
                        tolerance: "pointer",
                    });
                });
            },
            beforeSave: function ($body) {
                let $socialfollows = $body.find('.edsocialfollow .edsocialfollowcontainer');
                
                _.forEachRight ($socialfollows, (item) => {
                    var tables = $(item).find('table');
                    //Skip if social cell is empty or vertical style
                    if (tables.length <= 0 || tables[0].attributes.align.value === "center") return;
                    if (!isOutlokCommentAppended(tables[0].parentNode, outlook.start)) {
                        $(outlook.start).insertBefore(tables[0]);
                    }
                    let index;
                    let tableLength = tables.length - 1;
                    for (index = 0; index < tableLength; index++) {
                        if (!isOutlokCommentAppended(tables[index].parentNode, outlook.between, tables[index])) {
                            $(outlook.between).insertAfter(tables[index]);
                        }
                    }
                    if (!isOutlokCommentAppended(tables[tableLength].parentNode, outlook.end)) {
                        $(outlook.end).insertAfter(tables[tableLength]);
                    }
                })
            },
        };
        core.body.on("mousedown", ".edsocialfollow:not(.edited)", function (e) {
            e.stopPropagation();
            this.className = this.className + " edited";
            that.init(this);
        });
        return that;
    });

    const generateRSSMergeTag = $container => {
        const rssUrl = $container.find("#rss-url").val();
        const showCondition = $container.find("input[name=show-condition]:checked").val();
        const postsToShowCount = $container.find("#rss-posts-to-show-count").val();

        return `{pullcontent-rss:${rssUrl}${postsToShowCount !== "all" ? ",count=" + postsToShowCount : ""}${showCondition === 'new' ? ",newcontentonly" : ""}}`
    }

    const disableAllRSSModalInputs = inputs => _.forEach(inputs, input => input.disabled = true);

    const enableRSSModalInputs = inputs => _.forEach(inputs, input => input.removeAttribute("disabled"));

    const collectPickedRSSTitles = inputs => {
        let titles = [];
        _.forEach(inputs, input => {
            if ($(input).is(":checked")) {
                titles.push(input.dataset.title);
            }
        });

        return titles;
    }

    const filterPickedRSSItems = (feed, pickedTitles) => _.filter(feed.items, item => (pickedTitles.indexOf(item.title) >= 0));
    // To be used inside spectrum's init function's show property
    const positionColorpicker = ($toolBar, selectorString) => {
        const $colorpickerContainer = $toolBar.find(selectorString).spectrum("container");
        const $workspace = $(".workbox");
        const isPickerBelowTemplateHalf = $toolBar.offset().top > ($('#eworkspace').outerHeight()/2) ;
        let topCoordinate = ($toolBar.offset().top - $workspace.scrollTop() + $workspace.offset().top) + (isPickerBelowTemplateHalf ? $colorpickerContainer.outerHeight() * (-1) : Math.ceil($toolBar.outerHeight()))

        // If colorpicker shows up below viewport prevent it and stick it to the bottom of the viewport
        if (($('#econtent_wrapper').outerHeight() - (topCoordinate + $colorpickerContainer.outerHeight())) < 0) {
            topCoordinate = $('#econtent_wrapper').outerHeight() - $colorpickerContainer.outerHeight();
        }

        // Colorpicker positioning
        $colorpickerContainer.css("top", topCoordinate);
        $colorpickerContainer.css("left", $workspace.offset().left + $toolBar.offset().left);
    };
})();



window.openMediaManager = (oldMediaManager, elementToChange, changeImageFunc, fileType) => {
    if (window.parent.openMediaManager && window.parent.isNewDash) {
        window.parent.openMediaManager(elementToChange, changeImageFunc, fileType);
        return;
    } else {
        oldMediaManager();
    }
}