/**
* Controller for the users screen, allows to manage users.
* @namespace
* @memberof html.widget
*/
html.widget.UsersUI = (function ViewUsers() {
    "use strict";

    var that;
    var $screen;
    var Users;
    var pagination;
    var _showTable = function () {
        pagination = new html.Pagination(Users.getAll(), {
            template: 'UsersList',
            resultcontainer: $screen.paginationresulttable,
            footercontainer: '.paginationlist',
            perpage: 10,
            paginationinfo: false,
            firstlast: false,
            param: { privateIP: ee.data.account.mailerid > 0, hasfullaccess: ee.session.can.ViewUsers}
        }).eventChangePage(function () { html.reloadTooltip($screen); });

        /*-- BIND EVENTS --*/
        $('#eshowadduser').off().on('click',function() {
            ee.goTo('#/account/users/add')
        });

        //-- Edit user
        $screen.paginationresulttable.off().on('click', '.eedituser', function () {
            ee.goTo(`#/account/users/edit/${  $(this).data('email')}`)
        });

        //-- Delete user
        $screen.paginationresulttable.on('click', '.edeleteuser', function () {
            var username = $(this).data('email'),
                confirm = html.modal.confirm(ee.t.userdelete, ee.t.userdeleteheader, function () {
                    ee.indiOn();
                    Users.removeUser(username)
                    .then(function () {
                        pagination.update(Users.getAll());
                    })
                    .always(ee.indiOff);
                });
        });

    };
    /*-- PUBLIC --*/
    that = {
        show: function () {
            ee.frame.content.empty();
            $screen = html.get('UsersMain', {});
            $screen.appendTo(ee.frame.content);
            Users = dataModel.create("UsersList");
            Users.load()
            .then(_showTable)
            .catch(function (msg) {
                console.log(msg);
            });
        },
    };

    return that;
})();
