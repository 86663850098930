(function (root) {

    const successPathName = '/legacy/#/payments/paymentsuccessful';
    const failurePathName = '/legacy/#/payments/unsuccesfulpayment';

    // return url for new dash
    const getReturnUrls = () => new Promise((res,reject)=>{

        if(!ee.bootedAsChild){
            res(null);
            return;
        }
            res({
                successurl : btoa(`${window?.top?.location.origin}${successPathName}`),
                cancelurl  : btoa(`${window?.top?.location.origin}${failurePathName}`)
            })
        })

    root.Payment = {
        updateautocredit: function (query) { return root.request('/payment/updateautocredit', query); },
        disableautocredit: function (query) { return root.request('/payment/disableautocredit', query); },
        makeAutoPaypalPurchase: function (query) { return root.request('/payment/makeautopaypalpurchase', query); },
        makeAutoPurchase: function (query) { return root.request('/payment/makeautopurchase', query); },
        registerPayPal: function (query) { 
            return getReturnUrls().then(returnUrls=>{
                return root.request('/payment/registerpaypal', {...query,...returnUrls}); 
        
            });
        },
        makePayPalPurchase: function (query) { 
            return getReturnUrls().then(returnUrls=>{
                return root.request('/payment/makepaypalpurchase', {...query,...returnUrls}); 
        
            });
         },
        registerCard: function (query) { return root.request('/payment/registercard', query); },
        makePurchase: function (query) { return root.request('/payment/makepurchase', query); },
        Register: function (query) {
            return getReturnUrls().then(returnUrls=>{
                return root.request('/payment/register', {...query,...returnUrls}); 
        
            });
         },
        Checkout: function (query) { return root.request('/payment/checkout', query); },
        Charge: function (query) { return root.request('/payment/charge', query); },
        loadRegisteredPaymentProvider: function (query) {return root.request('/payment/loadRegisteredPaymentProvider', query)},
        setPlan: function (query) { return root.request('/payment/changeTrialBillingPlan', query, { timeout: 120000 }); },
        enableSuspendedPayment: function (query) { return root.request('/payment/enableautocredit', query); },
        paymentProviderStatus: function (query) {return root.request('/payment/providerstatus', query); },
        preparePayment: (query) => {
            return getReturnUrls().then(returnUrls=>{
                return root.request('/payment/init',{...query,...returnUrls}); 
        
            });
        },
        removePaymentMethod: (query) => { return root.request('/payment/remove', query); },
        getBillingPlans: (query) => { return root.request('/payment/getbillingplans', query);},
        upgradeLegacyBillingPlan: (query) => { return root.request('/payment/upgradelegacybillingplan')}
    };


}(EE_API));
