(ee.views["contacts"] = function ContactsScreen() {
    const _screenname = "contacts";
        let _selectedList;
        let _searchrule;
        let $screen;
        let segments;
        const CONTACTSPERPAGE = 25;
        const _hideDOMElement = ($this) => {
            $this.addClass('hidden');
        };
        const _showDOMElement = ($this) => {
            $this.removeClass('hidden');
        };
        const _capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        const _allContactsCount = (countByStatuses, statuses) => {
            let count = 0;
            //let hasOwn = Object.prototype.hasOwnProperty;

            if (statuses.length > 0) {
                $.each(statuses, (index, element) => {
                    let name = element.toString().toLowerCase();
                    if (name === "abuse") name = "complaint";
                    count += countByStatuses[name];
                });
            } else {
                count = countByStatuses["all"];
                /*for (let prop in countByStatuses) {
                    if (hasOwn.call(countByStatuses, prop)) {
                        console.log(prop)
                        count = count + countByStatuses[prop];
                    }
                }*/
            }
            return count;
        };
        const _generateRule = (query, statuses) => {
            if (statuses.length > 0) {
                _generateStatusesRule(query, statuses);
            }
            if ($screen && _searchrule) {
                _addSearchRule(query, _searchrule);
            }
            if (!query.rule) query.allContacts = true;

            return query;
        };
        let _generateStatusesRule = (query, statuses) => {
            if (!query.rule) {
                query.rule = `( Status = ${  statuses.join(" OR Status = " )  } )`;
            } else {
                query.rule = `( ${  query.rule  } ) AND ( Status = ${  statuses.join(' OR Status = ')  } )`;
            }

            return query;
        };
        let _addSearchRule = (query, rule) => {
            if (!query.rule) {
                query.rule = rule;
            } else {
                query.rule = `( ${  query.rule  } ) AND ${  rule}`;
            }
        };
        const _loadContactList = (statuses, callback) => {
            const query = {
                limit: (ee.Router.parameter('page') && (ee.Router.parameter('page') * CONTACTSPERPAGE) > 500) ? (ee.Router.parameter('page') * CONTACTSPERPAGE) : 500,
                rule: _selectedList.rule
            }

            _generateRule(query, statuses);

            $screen.paginationresult.find('tbody tr').css({ 'opacity': '.4' });

            return callback(query);
        };

        const _viewCampaigns = (target) => {
            EE_API.Campaign.GetCampaignsForTarget({ target: target })
                .then((data) => {
                    const modal = html.modal.info(html.get('TargetCampaignList', { campaignsfortarget: data }), ee.t.viewcampaigns, 'emodal-md ');
                    modal.$content.on('click', '.action-campaigndetails',  (element) => {
                        ee.goTo(`#/campaigncreator/${  $(element.currentTarget).data('id')}`);
                    });
                    modal.$content.on('click', '.action-campaignsummary', (element) => {
                        ee.goTo(`#/activity/${  $(element.currentTarget).data('id')}`);
                    });
                }, (err) => { throw new Error(err) });
        };

        const _generateAdditionalQuery = (query) => {
            const emails = [];

            if (query.all) query.rule = _generateRule({ rule: _selectedList.rule }, query.statuses).rule;
            if (query.all === false && query.amount > 0) {

                delete query.all;
                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

                query.emails = emails.join(',');
            }

            delete query.statuses;
            delete query.all;

            return query;
        };
        const _createList = (opt) => {

            if (!opt) opt = {};

            let createemptylist,
                rule,
                emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

            }

            createemptylist = (emails.length === 0 && typeof rule === 'undefined') ? true : false;

            const $createnewlist = html.get('CreateNewList', { amount: opt.amount }),
                modal = html.modal.create($createnewlist, {
                    title: ee.t.createnewlist, size: '600px',
                    buttons: [{
                        name: ee.t.save,
                        css: 'btn_lg btn_primary',
                        callback: () => {
                            const name = $createnewlist.listname.val(),
                                allowunsubscribe = $createnewlist.allowunsubscribe.is(':checked'),
                                listsdata = new Collection.Data(ee.data.lists.data);
                            if (name === '') {
                                html.modal.info(ee.t.errorlistname, ee.t.errormsg);
                                return;
                            } else if (name.indexOf("'") !== -1) {
                                html.modal.info(ee.t.listnamerestriction, ee.t.errormsg);
                                return;
                            }

                            const objectWithName = listsdata.get("listname", name);
                            if (objectWithName) {
                                html.modal.info(ee.t.thesamelistname, ee.t.listexists);
                                return;
                            }

                            const query = {
                                listname: name,
                                allowunsubscribe: allowunsubscribe,
                                createemptylist: createemptylist
                            }

                            if (emails.length > 0) query.emails = emails.join(',');
                            if (rule) query.rule = rule;
                            if (createemptylist) query.createemptylist = true;
                            if (!createemptylist && !query.rule && !query.emails) query.allcontacts = true;

                            ee.data.lists.add(query)
                            .then( (data) => {
                                const listid = ee.data.lists.get("listname", query.listname).listid;
                                ee.goTo(`#/${  _screenname  }/list/${  listid}`);
                            });
                        }
                    }]
                });

            html.inittooltip($createnewlist, '.tooltipcreatenewlist', {
                title: (element) => { return ($(element.currentTarget).data('mastertooltip')); },
                container: $createnewlist,
                html: true
            });
        };
        const _copyToList = (opt) => {

            if (!opt) opt = {};

            let createemptylist;
            const $copytolist = html.get("CopyToList", { lists: ee.data.lists.getAll(), amount: opt.amount });
            let rule;
            let modal;
            const emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

            };

                modal = html.modal.create($copytolist, {
                    title: ee.t.addcontactsctolist,
                    buttons: [{
                        name: ee.t.ok,
                        css: 'btn_lg btn_primary',
                        callback: () => {
                            let targetList = ee.data.lists.get('listid', Number($copytolist.copytolist.val())),
                                query;

                            if (!targetList) return console.error("Cannot find list!")

                            query = {
                                listName: targetList.listname
                            }

                            if (emails.length > 0) query.emails = emails.join(',');
                            if (rule) query.rule = rule;

                            if (!query.rule && !query.emails) query.allcontacts = true;

                            EE_API.List.AddContacts(query)
                                .then( () => {
                                    ee.data.lists.isLoaded = false;
                                    ee.goTo(`#/${  _screenname  }/list/${  targetList.listid}`);
                                })
                        }
                    }]
                });
        };
        const _moveToList = (opt) => {

            if (!opt) opt = {};

            let $copytolist = html.get("CopyToList", { lists: ee.data.lists.getAll(), amount: opt.amount }),
                rule,
                modal,
                emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                });

            };


            modal = html.modal.create($copytolist, {
                title: ee.t.movecontacts,
                buttons: [{
                    name: ee.t.move,
                    css: 'btn_lg btn_primary',
                    callback: () => {

                        let targetList = ee.data.lists.get('listid', Number($copytolist.copytolist.val())),
                            query;

                        if (!targetList) return console.error("Cannot find list!")

                        query = {
                            newlistName: targetList.listname,
                            oldlistname: _selectedList.listname
                        }

                        if (emails.length > 0) query.emails = emails.join(',');
                        if (rule) query.rule = rule;

                        if (!query.rule && !query.emails) query.moveall = true;

                        EE_API.List.MoveContacts(query)
                            .then( () => {
                                ee.data.lists.isLoaded = false;
                                $screen.refresh.trigger('click');
                                modal.close();
                            })
                    }
                }]
            });
        };
        const _changeStatus = (opt) => {

            if (!opt) opt = {};

            let $win,
                modal,
                rule,
                emails = [];

            let parsedRule = _selectedList.rule

            if (opt.all) rule = _generateRule({ rule: parsedRule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

            };
                $win = html.get("ChangeContactsStatus", { amount: opt.amount });
                modal = html.modal.create($win, {
                title: ee.t.changestatustitle, buttons: [{
                    name: ee.t.save,
                    css: 'btn_lg btn_primary',
                    callback: () => {
                        const query = {
                            status: $win.selectstatus.val()
                        }; 
                        if (rule) query.rule = rule;
                        if (emails.length > 0) query.emails = emails.join(',');

                        if (!query.rule && !query.emails) query.allcontacts = true;

                        EE_API.Contact.ChangeStatus(query).then( () => {
                            $screen.refresh.trigger('click');
                            modal.close();
                        })
                    }
                }]
            });
        };
        const _changeConsentTracking = (opt) => {
            if (!opt) opt = {};

            let $win,
                modal,
                rule,
                emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

            };
            $win = html.get("ChangeContactsConsentTracking", { amount: opt.amount });
            modal = html.modal.create($win, {
                title: ee.t.changeconsenttracking, buttons: [{
                    name: ee.t.save,
                    css: 'btn_lg btn_primary',
                    callback: () => {
                        const query = {
                            consent: $win.selecttrackingstatus.val()
                        };
                        if (rule) query.rule = rule;
                        if (emails.length > 0) query.emails = emails.join(',');

                        if (!query.rule && !query.emails) query.allcontacts = true;

                        EE_API.Contact.ChangeConsentTracking(query).then( () => {
                            $screen.refresh.trigger('click');
                            modal.close();
                        })
                    }
                }]
            });
        };
        const _removeContacts = (opt) => {

            if (!opt) opt = {};

            let rule,
                emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

            };

            const selected = $('#epaginationresult').find(':checked').length;
            const emailsonlistvalue = $('#epaginationresult').find('.contactlist').length;
            if (emailsonlistvalue <= selected) {
                html.modal.confirm(ee.t.removeallcontactsmsg, ee.t.remove, () => {
                    const query = {
                        listname: _selectedList.name,
                    }

                    if (rule) query.rule = rule;
                    if (emails.length > 0) query.emails = emails.join(',');
                    ee.indiOn();
                    EE_API.List.RemoveContacts(query)
                    .then( () => {
                        $screen.refresh.trigger('click');
                        ee.indiOff();
                    })
                    .catch(err => console.error(err));
                }, false, { confirmTitle: ee.t.remove, confirmCSS: "btn_lg btn_destructive" });
            } else {
            html.modal.confirm(
                ee.t.removecontactsmsg.replace(/{{amount}}/g, selected),
                ee.t.remove,
                () => {
                const query = {
                    listname: _selectedList.name,
                }

                if (rule) query.rule = rule;
                if (emails.length > 0) query.emails = emails.join(',');
                ee.indiOn();
                EE_API.List.RemoveContacts(query)
                .then( () => {
                    $screen.refresh.trigger('click');
                    ee.indiOff();
                })
                .catch(err => console.error(err));
            }, false, { confirmTitle: ee.t.remove, confirmCSS: "btn_lg btn_destructive" });
            }

        };
        const _deleteContacts = (opt) => {
            if (!opt) opt = {};

            let rule,
                emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {
                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })
            };
            const selected = $('#epaginationresult').find(':checked').length;
            const emailsonlistvalue = $('#epaginationresult').find('.contactlist').length;
            if (emailsonlistvalue <= selected) {
                html.modal.confirm(ee.t.deleteallcontactsmsg, ee.t.deletes, () => {
                    const query = {};
                    if (rule) query.rule = rule;
                    if (emails.length > 0) query.emails = emails.join(',');
                    if (!query.emails && !query.rule) query.allcontacts = true;
                    ee.indiOn();

                    EE_API.Contact.Delete(query).then( () => {
                        ee.data.lists.isLoaded = false;
                        ee.data.segments.isLoaded = false;
                        $screen.refresh.trigger('click');
                        ee.indiOff();
                    }).catch(err => console.error(err))
                }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" });
            } else {

                html.modal.confirm(ee.t.deletecontactsmsg.replace(/{{amount}}/g, selected), ee.t.deletes, () => {
                const query = {};
                if (rule) query.rule = rule;
                if (emails.length > 0) query.emails = emails.join(',');
                //if (!query.emails && !query.rule) query.allcontacts = true;
                ee.indiOn();

                EE_API.Contact.Delete(query).then( () => {
                    ee.data.lists.isLoaded = false;
                    ee.data.segments.isLoaded = false;
                    $screen.refresh.trigger('click');
                    ee.indiOff();
                }).catch(err => console.error(err))
            }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" });
            }
        };
        const _createRandomList = (rule) => {
            const info = false;
            const $randomlist = html.get('CreateRandomList');
            const modal = html.modal.create($randomlist, {
                title: ee.t.createrandomlist, buttons: [{
                    name: ee.t.save,
                    css: 'btn_lg btn_primary',
                    callback: (e) => {
                        let query;
                        e.stopPropagation();
                        /*Need to implement Validation instead solution below*/
                        if ($randomlist.listaddconsent && !$randomlist.listaddconsent.is(':checked')) {
                            html.modal.info(ee.t.descconsentsuberror, ee.t.descconsentsuberrortitle, '');
                            return false;
                        }

                        query = {
                            listname: $randomlist.listname.val(),
                            count: $randomlist.count.val(),
                            excludeblocked: $randomlist.excludeblocked.is(':checked'),
                            allowunsubscribe: $randomlist.allowunsubscribe.is(':checked')
                        };

                        rule ? query.rule = rule : query.allcontacts = true;

                        EE_API.List.CreateRandomList(query)
                        .then( (response) => {
                            ee.data.lists.isLoaded = false;
                            ee.goTo(`#/${  _screenname  }/list/${  response}`);
                        })
                        .then( () => {
                            html.modal.info(ee.t.processtimewarning)
                        });
                    }
                }]
            });
        };
        const _createNthSelectionLists = (rule) => {
            const $nthselectionlists = html.get('CreateNthSelectionLists');
            const modal = html.modal.create($nthselectionlists, {
                      title: ee.t.createnthselectionlists,
                      buttons: [{
                          name: ee.t.save,
                          css: 'btn_lg btn_primary',
                          callback: (e) => {
                              let query;

                              e.stopPropagation();
                              if ($nthselectionlists.listaddconsent && !$nthselectionlists.listaddconsent.is(':checked')) {
                                  html.modal.info(ee.t.descconsentsuberror, ee.t.descconsentsuberrortitle, '', () => { });
                                  return false;
                              }
                              query = {
                                  listname: $nthselectionlists.listname.val(),
                                  numberoflists: $nthselectionlists.numberoflists.val() == '' ? 0 : $nthselectionlists.numberoflists.val(),
                                  excludeblocked: $nthselectionlists.excludeblocked.is(':checked'),
                                  allowunsubscribe: $nthselectionlists.allowunsubscribe.is(':checked')
                              };

                              rule ? query.rule = rule : query.allcontacts = true;

                              EE_API.List.CreateNthSelectionLists(query)
                              .then( (response) => {
                                ee.api.loadContactOverview();
                                  modal.close();
                                  html.modal.info(ee.t.processtimewarning);
                              });
                          }
                      }]
            });
        };
        const _setActiveTab = ($this, selector) => {
            $this.parent().find(selector).removeClass('active');
            $this.addClass('active');
        };
        const _generateScreen = (template, data) => {
            const doc = ee.frame.content;
            const $screen = html.get(template, data);

            doc.empty()
            $screen.appendTo(doc);

            return $screen;
        };
        const _exportContacts = (listObj, opt) => {
            if (!opt) opt = {};
            let rule;
            let modal;
            const name = (listObj.listname) ? listObj.listname : listObj.name;
            const exportdialog = html.get('ExportDialog');
            const emails = [];

            if (opt.all) rule = _generateRule({ rule: _selectedList.rule }, opt.statuses).rule;
            if (opt.all === false && opt.amount > 0) {

                $screen.paginationresult.find('tbody input[type=checkbox]:checked').each( (index, element) => {
                    emails.push($(element).val())
                })

            }

                html.modal.create(exportdialog, {
                    title: ee.t.exportdata, size: 'emodal-sm',
                    buttons: [{
                        name: ee.t.exportdata,
                        css: 'btn_lg btn_primary',
                        callback: () => {
                            let compressionformat = 'None';
                            if (ee.data.account.isMac === false)
                                compressionformat = exportdialog.compressed.is(':checked') ? 'Zip' : 'None';
                            const query = {};
                            (listObj.rule === "") ? query.allcontacts = true : query.rule = listObj.rule;
                            query.fileformat = (exportdialog.fileformat.val()).replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_');
                            query.compressionformat = compressionformat;
                            query.filename = exportdialog.filename.val();
                            /*NOTE: Export invalid charts msg */
                            if (/[\\\/\:\*\?\"\<\>\+\#]/.test(query.filename)) return html.modal.info(ee.t.exportfilenamechartsmsg);

                            if (opt.all && rule) {
                                query.rule = rule;
                                delete query.allcontacts;
                            };
                            if (emails.length > 0) {
                                query.emails = emails.join(',');
                                delete query.allcontacts;
                                delete query.rule;
                            }

                            EE_API.Contact.Export(query)
                                .then( () => {
                                    ee.goTo("#/exports");
                                });
                        }
                    }]
                });
            if (ee.data.account.isMac === true) {
                exportdialog.compressedlist.hide();
                exportdialog.compressed.hide();
            }
            exportdialog.filename.val(name.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_'));
        };
        const _copyList = (prop, callback) => {
            const content = html.get("CopyListOrSegment", { name: prop.name }),
                modal = html.modal.create(content, {
                    title: ee.t.copylist,
                    buttons: [
                        {
                            name: 'Save',
                            css: 'btn_lg btn_primary',
                            callback: () => {
                                let query, method;

                                if (prop.action === "copylist") {
                                    query = {
                                        newListName: content.newname.val().trim(),
                                        sourceListName: prop.name
                                    }
                                    method = "List";
                                }
                                if (prop.action === "copysegment") {
                                    query = {
                                        newSegmentName: content.newname.val().trim(),
                                        sourceSegmentName: prop.name
                                    }
                                    method = "Segment";
                                }

                                EE_API[method].Copy(query)
                                    .then( () => {
                                        if (callback) callback(modal);
                                    });
                            }
                        }
                    ]
                })
        };
        const _deleteList = (listObj, callback) => {

            let query = {},
                method, data, msg, title;

            if (listObj.listid) {
                query.listname = listObj.listname;
                method = "List";
                data = ee.data.lists;
                msg = ee.t.deletelistmsg;
                title = ee.t.deletelistheader;
            }
            if (listObj.segmentid) {
                query.segmentname = listObj.name;
                method = "Segment";
                data = ee.data.segments;
                msg = ee.t.deletesegmentmsg;
                title = ee.t.deletesegmentheader;
            }
            if (listObj.segmentid) {
                html.modal.confirm(msg, title, function () {
                    EE_API[method].Delete(query)
                    .then(function () {
                        if (callback) callback(method);
                    });
                }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" })
                return;
            }
            ///[\\/\\]/.test(query.listname) - v3 breaks with / and \ in url
            if (/[\\/\\]/.test(query.listname) || !ee.session.can.ViewAutomations) {
                html.modal.confirm(msg, title, function () {
                    EE_API[method].Delete(query)
                    .then(function () {
                        if (callback) callback(method);
                    });
                }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" })
            } else {
                //Resource has 4 possible value, Templates = 0, Lists = 1, Campaign = 2, Automation = 3
                return API_V3.Journeys.GetAutomationUsage(encodeURIComponent(query.listname), {Resource: 1})
                .then(function(data) {
                    if (data.length > 0) {
                        const automatedCampaignName = data.join(', ');
                        if (ee.session.can.ModifyAutomations) {
                            html.modal.confirm(ee.t.deletelistusageinautomation.replace(/{{AutomatedCampaignName}}/g, `<span class='automationsName'>${  automatedCampaignName  }</span>`),
                            ee.t.removetemplate, function () {
                                EE_API[method].Delete(query)
                                .then(function () {
                                    if (callback) callback(method);
                                });
                            }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
                        } else {
                            html.modal.info(ee.t.noaccesstodeletelistusageinautomation.replace(/{{AutomatedCampaignName}}/g, `<span class='automationsName'>${  automatedCampaignName  }</span>`));
                        }
                    } else {
                        html.modal.confirm(msg, title, function () {
                            EE_API[method].Delete(query)
                            .then(function () {
                                if (callback) callback(method);
                            });
                        }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" })
                    }
                })
                .catch(function(err) {
                    if (data && data.responseJSON) {
                        html.modal.error(data.responseJSON.Error);
                    }
                    console.log(err);
                })
            }
        };
        const _editList = function (listid, data, callback) {
            listid = parseInt(listid, 10);
            const listtoedit = ee.data.lists.get('listid', listid);
            let $listdetailedit;
            let modal;
            const trackingCount = (data === "No data" ? "No data" : data.length);

            if (!listtoedit) {
                throw new Error("List not found")
            }

            $listdetailedit = html.get('ListDetailEdit', {listtoedit: listtoedit, trackingCount: trackingCount});
            modal = html.modal.create($listdetailedit, {
                title: ee.t.editlist,
                buttons: [{
                    name: ee.t.save,
                    css: 'btn_lg btn_primary',
                    callback: () => {
                        ee.indiOn();
                        const query = {
                            listname: listtoedit.listname,
                            newlistname: $listdetailedit.listname.val(),
                            allowunsubscribe: $listdetailedit.allowunsubscribe.is(':checked'),
                            trackhistory: $listdetailedit.tracklisthistory.is(':checked')
                        };

                        if (query.newlistname === query.listname) delete query.newlistname;

                        EE_API.List.Update(query)
                        .then( () => {
                            const listname = (query.newlistname) ? query.newlistname : query.listname;

                            ee.data.lists.updateList({
                                listid: listtoedit.listid,
                                listname: listname,
                                name: query.newlistname,
                                allowunsubscribe: query.allowunsubscribe,
                                trackhistory: query.trackhistory,
                                rulelocal: `listname = '${  listname  }'`
                            });
                            ee.indiOff();
                            if (callback) callback(modal)
                        });
                    }
                }]
            });

            if (data.length === 10 && !$listdetailedit.tracklisthistory.is(':checked')) {
                $listdetailedit.tracklisthistory.attr("disabled", true);
                $listdetailedit.find(".trackingHistoryBadge").addClass("disabled");
            }

            if (data !== "No data") {
                $listdetailedit.tracklisthistory.on("change", (e) => {
                    const trackingListCountElem = $listdetailedit.find("#trackingListCount");
                    let trackingListCount = trackingListCountElem.text();
                    if ($listdetailedit.tracklisthistory.is(':checked')) {
                        trackingListCount++;
                        trackingListCountElem.text(trackingListCount);
                    } else {
                        trackingListCount--;
                        trackingListCountElem.text(trackingListCount);
                    }
                })
            }

            ee.tools.validateEntityName(
                {
                    screen: $listdetailedit,
                    inputname: '#elistname',
                    submit: modal.$footer.find('.btn_primary')
                }
            )
        };
        const _showRule = (list, statuses) => {
            let rule = `Rule: ( ${  list.rulelocal  } )`,
                val = $screen.searchinputcontact.val().trim(),
                criteria = $screen.searchcriteriacontact.val();



            if (statuses && statuses.length > 0) {
                rule = `${rule  } AND ( Status = ${  statuses.join(" OR Status = ")  } )`
            }
            if (val) {
                const searchrule = `( Email ${  criteria  } '${  val  }')`;

                rule = `${rule  } AND ${  searchrule}`;
                _searchrule = searchrule;
            } else {
                _searchrule = null;
            }
            $screen.segmentquery.text(rule);

            return list;
        };
        const _setStatusDropdownBadges = (countByStatus) => {
            $screen.statusmenu.find('li[data-name]').each( (index, element) => {
                const $this = $(element),
                    name = $this.data('name');
                $this.find('.badge').text(`${  countByStatus[name]}`);
            });
        };
        const _setQueryCount = (countByStatus) => {
            $screen.querycount.text(countByStatus["all"].numberFormat());
        };
        const _activateButtons = ($buttonsArr) => {
            $.each($buttonsArr, (index, element) => {
                try {
                    $(element).removeClass('disabled').prop('disabled', false)
                } catch (err) {
                    // TO DO ee.data.account.enablecontactfeatures is false we are have error heare
                }
            })
        };
        const _bindListScreenDropdowns = (list) => {
            if (!$screen.pagemenu) return;
            $screen.pagemenu.on("click", "li[data-action]", (element) => {
                const $this = $(element.currentTarget);
                const isList = list.listid !== undefined;
                const action = $this.data("action");
                switch (action) {
                    case 'addcontacts':
                        html.widget.AddContactsUI.init(list.listid, {
                            closeCallback: () => {
                                $screen.refresh.trigger("click") ;
                            }
                        });
                        return;
                    case "viewcampaigns":
                        return _viewCampaigns(isList ? `l${  list.listid}` : `s${  list.segmentid}`);
                    case "delete":
                        return _deleteList(list, () => {
                            if (!isList) {
                                delete ee.data.segments;
                            } else {
                                delete ee.data.lists;
                            }
                            ee.goTo(`#/${  _screenname}`);
                        });
                    case 'sendcampaign':
                        return ee.goTo(`#/campaigncreator/target/${(isList) ? 'l' + list.listid : 's' + list.segmentid}`);
                    case 'editsegment':
                        ee.goTo(`#/querytool/edit/${  list.segmentid}`);
                        return;
                    case 'editlist':
                        return EE_API.Segment.LoadTrackedHistory({})
                        .then((data) => {
                            _editList(list.listid, data, (modal) => { modal.close(); _showRule(list) })
                        })
                        .catch((err) => {
                            console.log(err);
                            _editList(list.listid, "No data", (modal) => { modal.close(); _showRule(list) })
                        });
                    case 'createrandomlist':
                        _createRandomList(list.rule);
                        break;
                    case 'createnthlists':
                        _createNthSelectionLists(list.rule);
                        break;
                }
            });
            return list;
        };
        const that = {
            show: () => {
                switch (ee.Router.action()) {
                    case 'contact':
                        html.widget.ContactDetailsUI.init(ee.Router.parameter('node0'), {
                            frame: ee.frame.content
                        });
                        return;
                    case 'list':
                        that.showContactsList(ee.Router.parameter('node0'), 'listid');
                        return;
                    case 'segment':
                        that.showContactsList(ee.Router.parameter('node0'), 'segmentid');
                        return;
                    case 'managefields':
                        if (!ee.session.can.ViewContactFields) {
                            ee.goTo("/#start");
                        }
                        that.showMain( () => {
                            html.widget.ContactFieldsUI.init(null, {
                                closeCallback: () => {
                                    $screen.refresh.trigger("click") ;
                                }
                            });
                        });
                        break;
                    case 'addcontacts':
                        that.showMain( () => {
                            html.widget.AddContactsUI.init(null, {
                                closeCallback: () => {
                                    $screen.refresh.trigger("click") ;
                                }
                            });
                        });
                        return;
                    case 'new':
                        ee.initNextGen();
                        break;
                    default:
                        that.showMain();
                        break;
                }
            },
            showMain: (callback) => {
                ee.logEvent('Contacts screen loaded');
                const $loadingTemplate = html.get("TableLoading", {}).html();
                let History;
                let ContactsOverview;
                const pagination = {
                        segmentOpt: {
                            template: 'ContactGroupsPagination',
                            footercontainer: '.esegmentpagination',
                            firstlast: false,
                            perpage: 25,
                            paginationinfo: false,
                            param: {
                                contactfeatures: ee.data.account.enablecontactfeatures
                            }
                        },
                        listOpt: {
                            template: 'ContactListPagination',
                            footercontainer: '.esegmentpagination',
                            firstlast: false,
                            perpage: 25,
                            paginationinfo: false,
                            param: {
                                contactfeatures: ee.data.account.enablecontactfeatures
                            }
                        },
                        segments: undefined,
                        lists: undefined
                    }
                segments = dataModel.create("Segments");
                let lists; //Will be loaded by segments require method


                $screen = _generateScreen("Contacts", {
                    enablecontactfeatures: ee.data.account.enablecontactfeatures,
                    isadmin: ee.session.can.AdminView || (ee.session.can && ee.session.can.AdminView),
                    issub: ee.data.account.issub,
                    segments: segments,
                    pricing2018: ee.data.account.pricing2018,
                    totalcontacts: ee.data.account.contactscount,
                    availablecontacts: ee.data.account.availablecontacts,
                    emailssent: ee.data.account.totalemailssentformatted,
                    openrate: ee.data.account.averageopenrate,
                    clickrate: ee.data.account.averageclickrate,
                });

                if(ee.base && window.hj) {
                    window.hj('trigger', 'contacts');
                }

                $.when( segments.require() )
                    .then( () => {

                    lists = dataModel.create("Lists");

                    pagination.segments = new html.Pagination(segments.getAll(), $.extend({}, pagination.segmentOpt, {
                        resultcontainer: $screen.segmentscontactsgroups,
                    }));
                    pagination.lists = new html.Pagination(lists.getAll(), $.extend({}, pagination.listOpt, {
                        resultcontainer: $screen.listcontactsgroups,
                        footercontainer: '.elistpagination',
                    }));


                    html.Chart.init( () => {
                        new html.widget.HistoryGraphsWidget([segments], {
                            $navigation: $screen.historybuttons,
                            $graphs: $screen.graph
                        });
                    });

                    html.Chart.init( () => {
                        new html.widget.ContactsOverviewGraphsWidget([segments], {
                            $navigation: $('#eoverviewbuttons'),
                            $graphs: $('#egraphoverview')
                        });
                    });

                    if (callback) {
                        callback();
                    }
                    
                    
                })
                .catch((err) => {
                    console.error(err);
                })
                ;
                //Events
                $screen.tabs.on('click', 'li', (element) => {
                    const $this = $(element.currentTarget), hash = $this.data('hash');
                    _setActiveTab($this, 'li');
                    _hideDOMElement($screen.historybuttons);
                    _hideDOMElement($screen.overviewbuttons);
                    if (hash === 'history') {
                        _showDOMElement($screen.historybuttons);
                    }
                    if (hash === 'contactsoverview') {
                        _showDOMElement($screen.overviewbuttons);
                    }

                    if ((hash === 'contactsoverview') || (hash === 'history')) {
                        $('#esearchlists').hide();
                    } else {
                        $('#esearchlists').show();

                    }

                    _hideDOMElement($screen.tabsections.find('section'));
                    _showDOMElement($screen.tabsections.find(`section.etab-${  hash}`));
                    ee.changeHashQuietly(`#/${  _screenname  }/${  hash}`)
                });
                //Bind tables drpdwn events
                $screen.find('.table-wrapper').on('click', '.menu-action', (element) => {
                    const prop = $(element.currentTarget).data();
                    const action = prop.action;
                    const listObj = (prop.listid) ? ee.data.lists.get("listid", Number(prop.listid)) : ee.data.segments.get("segmentid", Number(prop.segmentid));
                    if (!action) return false;
                    switch (action) {
                        case 'showlist':
                            (prop.listid) ? ee.goTo(`#/${  _screenname  }/list/${  prop.listid}`) : ee.goTo(`#/${  _screenname  }/segment/${  prop.segmentid}`);
                            return;
                        case 'addcontacts':
                            html.widget.AddContactsUI.init(prop.listid, { closeCallback: () => { $screen.refresh.trigger("click") } });
                            return;
                        case 'quickadd':
                            html.widget.AddContactsUI.quickAdd(prop.listid, { closeCallback: () => { $screen.refresh.trigger("click") } });
                            return;
                        case 'editsegment':
                            ee.goTo(`#/querytool/edit/${  prop.segmentid}`);
                            return;
                        case 'editlist':
                            return EE_API.Segment.LoadTrackedHistory({})
                            .then((data) => {
                                _editList(prop.listid, data, (modal) => { pagination.lists.refresh(); modal.close() })
                            })
                            .catch((err) => {
                                console.log(err);
                                _editList(prop.listid, "No data", (modal) => { pagination.lists.refresh(); modal.close() })
                            });
                        case 'sendcampaign':
                            ee.goTo(`#/campaigncreator/target/${ (prop.listid) ? 'l' + prop.listid : 's' + prop.segmentid}`);
                            return;
                        case 'export':
                            _exportContacts(listObj);
                            return;
                        case 'copylist':
                        case 'copysegment':
                            _copyList(prop, (modal) => {
                                modal.close();
                                $screen.refresh.trigger('click');
                            });
                            return;
                        case 'delete':
                            _deleteList(listObj, (method) => {
                                if (method === "List") {
                                    ee.data.lists.remove('listid', listObj.listid);
                                    pagination.lists.update(ee.data.lists.getAll());
                                } else if (method === "Segment" ) {
                                    ee.data.segments.remove('segmentid', listObj.segmentid);
                                    pagination.segments.update(ee.data.segments.getAll());
                                }
                            })
                            return;
                        case 'createrandomlist':
                            _createRandomList(listObj.rule);
                            return;
                        case 'createnthlists':
                            _createNthSelectionLists(listObj.rule);
                            return;
                        case 'viewcampaigns':
                            _viewCampaigns( prop.listid ? `l${  prop.listid}` : `s${  prop.segmentid}` );
                            return;
                    }
                });
                //Bind common events
                if ($screen.managecontacts && ee.session.can.ModifyContacts) {
                    $screen.managecontacts.on('click', 'li', (element) => {
                        switch ($(element.currentTarget).data('action')) {
                            case 'addsegment':
                                ee.logEvent('Contacts - add segment.');
                                if (ee.base && window.ga) {
                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "CreateSegment"
                                    });
                                }
                                ee.goTo('#/querytool/add');
                                break;
                            case 'addlist':
                                ee.logEvent('Contacts - add list.');
                                if (ee.base && window.ga) {
                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "AddList"
                                    });
                                }
                                html.widget.CreateContactsListUI.init();
                                break;
                            case 'webform':
                                ee.logEvent('Contacts - create webform.');
                                if (ee.base && window.ga) {
                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "CreateWebform"
                                    });
                                }
                                ee.goTo('#/forms/create');
                                break;

                            case 'managefields':
                                ee.logEvent('Contacts - manage conctact fields.');
                                if (ee.base && window.ga) {
                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "ManageContactFields"
                                    });
                                }
                                html.widget.ContactFieldsUI.init();
                                break;
                            case 'addcontacts':
                                ee.logEvent('Contacts - add contacts.');
                                if (ee.base && window.ga) {

                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "AddContacts"
                                    });
                                }
                                html.widget.AddContactsUI.init(null, {
                                    closeCallback: () => {
                                        EE_API.List.List()
                                        .then((result) => {
                                            _.forEach(result, (list) => {
                                                list.dateadded = ee.api.formatDate(list.dateadded);
                                                list.countformatted = list.count.numberFormat();
                                                list.rule = `List = ${list.listid}`;
                                                list.name = list.listname;
                                                list.rulelocal = `listname = '${list.listname}'`;
                                            })
                                            
                                            ee.data.lists.data = result;
                                        })
                                        $screen.refresh.trigger("click");
                                    }
                                });
                                break;
                            case 'quickadd':
                                ee.logEvent('Contacts - quick add.');
                                if (ee.base && window.ga) {

                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "QuickAdd"
                                    });
                                }
                                html.widget.AddContactsUI.quickAdd(null, {
                                    closeCallback: () => {
                                        // $screen.refresh.trigger("click")
                                    }
                                })
                                .then( (modal) => {
                                    modal.$container
                                    .find(".emodal-footer")
                                    .prepend($("<label for='add_next_conact' class='quick-add__add-next'>Add another?<input id='add_next_conact' type='checkbox'></label>"));
                                });
                                break;
                            case 'exporthistory':
                                ee.logEvent('Contacts - export.');
                                ee.goTo("#/exports");
                                break;
                        }
                    });
                }
                if ($screen.addcontacts && ee.session.can.ModifyContacts) {
                    $screen.addcontacts.on('click', 'li', (element) => {
                        switch ($(element.currentTarget).data('action')) {
                            case 'addcontacts':
                                ee.logEvent('Contacts - add contacts.');
                                if (ee.base && window.ga) {
                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "AddContacts"
                                    });
                                }
                                html.widget.AddContactsUI.init(null, { closeCallback: () => { $screen.refresh.trigger("click") } });
                                break;
                            case 'quickadd':
                                ee.logEvent('Contacts - quick add.');
                                if (ee.base && window.ga) {
                                    window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'ContactDropDownMenu',
                                        eventAction: 'Click',
                                        eventLabel: "QuickAdd"
                                    });
                                }
                                html.widget.AddContactsUI.quickAdd(null, { closeCallback: () => { $screen.refresh.trigger("click") } });
                                break;
                        }
                    });
                }

                if ($screen.enablecontactfeatures) {
                    $screen.enablecontactfeatures.on("click", ee.views.account._enablepremiumtools);
                }

                $screen.refresh.on('click', () => {
                    that.refreshScreen(pagination);
                });

                html.bindSearch($screen.searchlists, () => {
                    if (!ee.data.lists && !ee.data.segments) { return; }

                    const searchSTR = $screen.searchinputlists.val();
                    let listsFilter = ee.data.lists;
                    let segmentsFilter = ee.data.segments;

                    if (searchSTR !== '') {
                        listsFilter = ee.data.lists.filter(['name', 'rule'], searchSTR);
                        segmentsFilter = ee.data.segments.filter(['name', 'rule'], searchSTR);
                    };
                    pagination.segments.update(segmentsFilter.getAll())
                    pagination.lists.update(listsFilter.getAll())
                    
                });
                //Set initial screen
                (function initialScren() {
                    const action = ee.Router.action();
                    if (action === 'staticlists' || action === 'history' || action === 'segmentslist' || action === 'contactsoverview') { $screen.tabs.find(`li[data-hash="${  action  }"]`).trigger('click') };
                    //Add animated focus to tables
                    html.addDropdownFocus($screen.segmentscontactsgroups);
                    html.addDropdownFocus($screen.listcontactsgroups);
                    $screen.segmentscontactsgroups.empty().append($loadingTemplate);
                    $screen.listcontactsgroups.empty().append($loadingTemplate);
                }());
            },//end of showMain
            showContactsList: (id, type) => {
                const segments = dataModel.create("Segments");
                let pagination;
                let selectedCount = 0;
                let selectedAll = false;
                let inProgress = true;
                let statuses = [];
                let fn;
                const path = ee.Router.currentPath();
                let cboxNavi;
                const contactsList = dataModel.create("ContactsList");
                const $loadingTemplate = html.get("TableLoading", { info: ee.t.loadingcontacts });
                let lists;

                ee.frame.content.empty();
                $screen = html.get('ContactsListScreen', {
                    list: {
                        islist: type === 'listid',
                        allcontacts: Number(id) === 0
                    },
                    enablecontactfeatures: ee.data.account.enablecontactfeatures,
                    isadmin: ee.session.can.AdminView || (ee.session.can && ee.session.can.AdminView),
                    privateIP: (ee.data.account.mailerid > 0) ? true : false,
                    issub: ee.data.account.issub,
                    pricing2018: ee.data.account.pricing2018
                });

                if ($screen.enablecontactfeatures) {
                    $screen.enablecontactfeatures.on("click", ee.views.account._enablepremiumtools);
                }

                $screen.appendTo(ee.frame.content.empty());
                //Load List Menu
                $.when(segments.require())
                    .then( () => {
                        lists = dataModel.create("Lists");

                        if (Number(id) || id === "0") {
                            _selectedList = (type === "segmentid") ? segments.get("segmentid", Number(id)) : lists.get("listid", Number(id));
                        } else {
                            _selectedList = (type === "segmentid") ? segments.get("name", decodeURIComponent(id)) : lists.get("listname", decodeURIComponent(id));
                        }

                        if (!_selectedList) {
                            console.log('Error: list/segment not found');
                            ee.goTo(`#/${  _screenname}`);
                            return;
                        }

                        $.when(_selectedList)
                            .then( (list) => {
                                //_showRule(list, statuses);
                                $screen.rule.text(list.name);
                                $("#listname").text(list.name);
                                if (_.includes(window.location.href, 'segment')) {
                                    $screen.segmentquery.text(`Rule: ( ${  list.rulelocal  } )`);
                                } else {
                                    $screen.segmentquery.text('');
                                }
                                return list;
                            })
                            .then( (list) => {
                                cboxNavi = html.get('ContactListCheckBoxNavi', {
                                    enablecontactfeatures: ee.data.account.enablecontactfeatures,
                                    list: _selectedList
                                });

                                cboxNavi.on("click", '#ecboxdeselect', () => {
                                    selectedCount = 0;
                                    selectedAll = false;
                                    $screen.paginationresult.find('input:checked').prop('checked', false);
                                    cboxNavi.hide();
                                })

                                cboxNavi.on("click", "li[data-action]", (element) => {
                                    const $this = $(element.currentTarget);
                                    const action = $this.data("action");
                                    const options = {
                                            amount: selectedCount,
                                            all: selectedAll,
                                            statuses: statuses
                                        };

                                    switch (action) {
                                        case 'makelist':
                                            return html.widget.CreateContactsListUI.init( {additionalQuery: _generateAdditionalQuery(options)} );
                                        case 'copytolist':
                                            return _copyToList(options);
                                        case 'movetolist':
                                            return _moveToList(options);
                                        case 'export':
                                            return _exportContacts(_selectedList, options);
                                        case 'removecontacts':
                                            return _removeContacts(options);
                                        case 'deletecontacts':
                                            return _deleteContacts(options);
                                        case 'changestatus':
                                            return _changeStatus(options);
                                        case 'changeconsenttracking':
                                            return _changeConsentTracking(options);
                                    }
                                })

                                return list;
                            })
                        .then( (list) => {
                            _activateButtons([$screen.createrandomlistbutton, $screen.listmenubutton])
                            return list;
                        })
                        .then(_bindListScreenDropdowns)
                        .then( (list) => {
                            return _loadContactList(statuses,
                                (query) => {
                                    if (list) {
                                        query.rule = list.rule;
                                    } else {
                                        query.rule = _selectedList.rule;
                                    }
                                    return contactsList.load({ query })
                                })
                        })// from that point Contacts Promise
                        .then( () => {
                            //_bindSearch()
                            _setStatusDropdownBadges(contactsList.getCountByStatus());
                            _setQueryCount(contactsList.getCountByStatus());
                            _activateButtons([$screen.statusmenubutton]);

                        })
                        .then( () => {
                            $screen.append(cboxNavi);

                            pagination = new html.Pagination(contactsList.getAll(), {
                                template: 'ContactsListTable',
                                resultcontainer: '#epaginationresult',
                                footercontainer: '.paginationlist',
                                firstlast: false,
                                perpage: CONTACTSPERPAGE,
                                paginationinfo: false,
                                param: {
                                    enablecontactfeatures: ee.data.account.enablecontactfeatures,
                                    privateIP: (ee.data.account.mailerid > 0) ? true : false
                                }
                            })
                            .eventBeforeChangePage( () => {
                                if (!selectedAll) {
                                    cboxNavi.cboxdeselect.trigger('click');
                                } else {
                                    $screen.paginationresult.find('input[type=checkbox]').prop('checked', false);
                                }
                            })
                            .eventChangePage( () => {
                                if (selectedAll) {
                                    $screen.paginationresult.find('input[type=checkbox]').prop('checked', true)
                                }

                                if(
                                    pagination.getOffset() < _selectedList.count &&
                                    pagination.getOffset() === pagination.getItemsCount()
                                ) {
                                    pullNextRecords();
                                }
                                
                                
                            });

                            if(
                                pagination.getOffset() < _selectedList.count &&
                                pagination.getOffset() === pagination.getItemsCount()
                            ) {
                                pullNextRecords();
                            }

                            function pullNextRecords() {
                                // Generate rule from curr rule and statuses
                                let rule = (_selectedList.rule ? `( ${  _selectedList.rule  } )` : '') + (statuses.length > 0 ? ` AND (  Status = ${  statuses.join(' OR Status = ')  } )` : '');
                                if (_searchrule) {
                                    rule.length ? rule += `AND ${  _searchrule}` : rule = _searchrule;
                                }
                                // Generate query with offset
                                const query = {
                                    offset: pagination.getOffset(),
                                    limit: CONTACTSPERPAGE,
                                    rule
                                };

                                // Get data
                                EE_API.Contact.List(query)
                                .then( (data) => {
                                    if (data.length > 0) {

                                        // Update contactsList
                                        contactsList.update(contactsList.data.concat(data));

                                        // Update pagination data
                                        pagination.update(contactsList.getAll());

                                        // Refresh pagination
                                        pagination.refresh();
                                    }
                                });
                            }

                        }).then( () => {
                            inProgress = false;
                        })
                        .catch(err => console.error(err));

                    //Search tool
                    html.bindSearch($screen.searchcontact, () => {
                        $screen.refresh.trigger("click");
                    }, { autoSearch: false });

                    //Events
                    $screen.refresh.on('click', (element) => {
                        const $this = $(element.currentTarget);
                        $this.attr('disabled', true);
                        $screen.paginationresult.empty().append($loadingTemplate);
                        $screen.find('.paginationlist').empty();
                        cboxNavi.hide();
                        selectedCount = 0;
                        selectedAll = false;
                        _showRule(_selectedList, statuses);
                        $screen.querycount.text("Counting...");

                        return _loadContactList(statuses, (query) => {
                            contactsList.load({ query }).then( () => {
                                pagination.update(contactsList.getAll());
                                _setStatusDropdownBadges(contactsList.getCountByStatus());
                                _setQueryCount(contactsList.getCountByStatus());
                                $this.removeAttr('disabled');
                            });
                        });
                    });

                    // Set pagination sorting to "neutral" -> not sorted
                    // 0 - neutral, 1 - ascending, 2 - descending
                    $screen.paginationresult.attr('data-sorted', 0);

                    // Sort contacts by Added column
                    // It's hooked to the result table's parent since
                    //      whole table gets rerendered on every sort action- all event handlers disappear
                    $screen.paginationresult.on("click", (e) => {
                        const tableParent = e.currentTarget;
                        if($(e.target).is("#econtactsaddedcolumn")) { // check if "Added" <th> has been clicked
                            return _loadContactList(statuses, (query) => {
                                tableParent.dataset.sorted = tableParent.dataset.sorted == 1 ? 2 : 1; // toggle current sorting order
                                query.sort = tableParent.dataset.sorted == 2 ? 2 : 1; // populate query with sort field. If it's 0 - assign 1
                                // Send request and refresh the list
                                contactsList.load({ query: query }).then( () => {
                                    pagination.update(contactsList.getAll());
                                    _setStatusDropdownBadges(contactsList.getCountByStatus());
                                    _setQueryCount(contactsList.getCountByStatus());
                                });
                            });
                        }
                    });

                    $screen.statusmenubutton.on("click", (element) => {
                        const $this = $(element.currentTarget);
                        const $drpdwn = $screen.statusmenudrpdwn;

                        if ($this.prop('disabled') === true) return false;


                        $drpdwn.find('input[type=checkbox]').prop('checked', false);
                        $.each(statuses, (index, element) => {
                            const name = element.toString();
                            $drpdwn.find(`input[value=${  name }]`).prop('checked', true);
                        });

                        if (statuses.length === 0) {
                            $drpdwn.find('input[type=checkbox].show-all').prop('checked', true);
                        }

                    })

                    $screen.paginationresult.on('click', '.eviewcontact', (event) => {
                        event.stopPropagation();
                        const isList = !!_selectedList.listid;

                        ee.goTo(`#/${_screenname}/contact/${$(event.currentTarget).data('email')}?fromList=${isList ? _selectedList.listid : _selectedList.segmentid}&segmentOrList=${isList ? "list" : "segment"}&name=${_selectedList.name}`);
                    });

                    $screen.paginationresult.on('click', '#eselectall', (event) => {
                        const $this = $(event.currentTarget);


                        if ($this.prop('checked')) {
                            selectedAll = true;
                            selectedCount = _allContactsCount(contactsList.getCountByStatus(), statuses);
                            $screen.paginationresult.find('tbody input[type=checkbox]').prop('checked', true);
                            if (selectedCount > 0) {
                                cboxNavi.selectedcontactcount.text(selectedCount);
                                cboxNavi.show();
                            } else { return false;}
                        } else {
                            cboxNavi.cboxdeselect.trigger('click');
                        };

                    });
                    $screen.paginationresult.on('click', 'tbody input[type=checkbox]', (e) => {

                        e.stopPropagation();

                        const $this = $(e.currentTarget);

                        const checkboxes = $.find('.echeckbox.contactlist:checked');

                        if (!checkboxes.length) { cboxNavi.hide(); }

                        if ($this.prop('checked')) {

                            cboxNavi.show();
                            cboxNavi.selectedcontactcount.text(++selectedCount);

                        } else if (selectedAll) {
                            selectedAll = false;
                            if (pagination.getCurrentData().length > pagination.getOffset()) {
                                selectedCount = pagination.getPerpage() - 1;
                            } else {
                                selectedCount = pagination.getCurrentData().length - 1;
                            }
                            cboxNavi.selectedcontactcount.text(selectedCount);
                            $screen.paginationresult.find('#eselectall').prop('checked', false);

                        } else {
                            cboxNavi.selectedcontactcount.text(--selectedCount);
                            if (selectedCount === 0) cboxNavi.hide();
                        };
                    })
                    $screen.paginationresult.on('click', 'tbody tr', (e) => {
                        const $this = $(e.currentTarget),
                            $input = $this.find('input[type=checkbox]');

                        $input.trigger('click');
                        return false;
                    });
                    $screen.find("#filter-contacts").on('click', e => {
                        const checkedInputs = $screen.find("#estatusmenudrpdwn input:checked:not(.show-all)");

                        statuses = [];

                        if ($screen.find('.fa-spinner').length === 0) {
                            $screen.paginationresult.empty().append($loadingTemplate);
                            $screen.find('.paginationlist').empty();
                        }

                        for (const input of checkedInputs) {
                            statuses.push(input.value);
                        }

                        if (selectedCount > 0) {
                            cboxNavi.hide();
                            selectedCount = 0;
                            selectedAll = false;
                        }

                        _showRule(_selectedList, statuses);
                        $screen.querycount.text("Counting...");

                        clearTimeout(fn);

                        fn = setTimeout( () => {

                            if (path !== ee.Router.currentPath() || inProgress) return false;

                            inProgress = true;

                            return _loadContactList(statuses, (query) => {
                                contactsList.load({ query }).then( () => {
                                    pagination.update(contactsList.getAll());
                                    if (selectedCount > 0) {
                                        cboxNavi.hide();
                                        selectedCount = 0;
                                        selectedAll = false;
                                    }
                                    //_setStatusDropdownBadges(contactsList.getCountByStatus());
                                    _setQueryCount(contactsList.getCountByStatus());
                                    inProgress = false;
                                });
                            });
                        }, 1000);


                    });
                    $screen.statusmenudrpdwn.on('click', 'input', (e) => {
                        e.stopPropagation();
                        const $thisInput = $(e.currentTarget);
                        const val = $thisInput.val();

                        if (val === "ShowAll") {
                            if ($thisInput.is(":checked")) {
                                $screen.find("#estatusmenudrpdwn input[type='checkbox']:not(.show-all)").prop("checked", false);
                            }
                        } else {
                            if (isNoneInputChecked($screen.find("#estatusmenudrpdwn input[type='checkbox']:not(.show-all)"))) {
                                $screen.find("#estatusmenudrpdwn input[type='checkbox'].show-all").prop("checked", true);
                            } else {
                                $screen.find("#estatusmenudrpdwn input[type='checkbox'].show-all").prop("checked", false);
                            }
                        }

                        // if ($screen.find('.fa-spinner').length === 0) {
                        //     $screen.paginationresult.empty().append($loadingTemplate);
                        //     $screen.find('.paginationlist').empty();
                        // }

                        // if ($this.prop('checked')) {
                        //     statuses.push(val);
                        // } else {
                        //     statuses.splice(statuses.indexOf(val), 1)
                        // }

                        // if (selectedCount > 0) {
                        //     cboxNavi.hide();
                        //     selectedCount = 0;
                        //     selectedAll = false;
                        // }
                        // _showRule(_selectedList, statuses);

                        // clearTimeout(fn);

                        // fn = setTimeout( () => {

                        //     if (path !== ee.Router.currentPath() || inProgress) return false;

                        //     inProgress = true;

                        //     return _loadContactList(statuses, (query) => {
                        //         contactsList.load({ query: query }).then( () => {
                        //             pagination.update(contactsList.getAll());
                        //             if (selectedCount > 0) {
                        //                 cboxNavi.hide();
                        //                 selectedCount = 0;
                        //                 selectedAll = false;
                        //             }
                        //             //_setStatusDropdownBadges(contactsList.getCountByStatus());
                        //             inProgress = false;
                        //         });
                        //     });
                        // }, 1000);

                    });

                    $screen.statusmenudrpdwn.on('click', 'li', (element) => {
                        const $this = $(element.currentTarget),
                            $input = $this.find('input');

                        $input.trigger('click');
                        return false;
                    });

                }).catch(err => console.error(err));

                const isAllInputsChecked = $inputs => {
                    let answer = true;

                    for (const input of $inputs) {
                        if (!$(input).is(":checked")) {
                            answer = false;
                        }
                    }

                    return answer;
                }

                const isNoneInputChecked = $inputs => {
                    let answer = true;

                    for (const input of $inputs) {
                        if ($(input).is(":checked")) {
                            answer = false;
                        }
                    }

                    return answer;
                }
            },
            refreshScreen: (pagination) => {
                const $this = $('#erefresh');
                if (window.location.hash == '#/contacts/contactsoverview'){
                    new html.widget.ContactsOverviewGraphsWidget([segments], {
                        $navigation: $('#eoverviewbuttons'),
                        $graphs: $('#egraphoverview')
                    });
                } else {
                    if (ee.data.lists.data || ee.data.segments.data) {
                        $this.attr('disabled', true);
                        $screen.segmentscontactsgroups.empty().append(html.get("TableLoading", { info: ee.t.loadingcontacts }));
                        $screen.listcontactsgroups.empty().append(html.get("TableLoading", { info: ee.t.loadingcontacts }));
                        //ee.data.lists.isLoaded = false;
                        $.when(segments.reload() )
                        .then( () => {
                            pagination.segments = new html.Pagination(ee.data.segments.getAll(), $.extend({}, pagination.segmentOpt, {
                                resultcontainer: $screen.segmentscontactsgroups,
                            }));
                            pagination.lists = new html.Pagination(ee.data.lists.getAll(), $.extend({}, pagination.listOpt, {
                                resultcontainer: $screen.listcontactsgroups,
                                footercontainer: '.elistpagination',
                            }));
                            $this.removeAttr('disabled');
                        })
                        .catch((err) => {console.log(err)});
                    }
                }
            },
            //HELPERS Temporary needed by old methods,TODO:We should find new better place for them
            getType: (fieldtype) => {
                switch (fieldtype) {
                    case 1: return 'number';
                    case 2: return 'datetime';
                    case 3: return 'number';
                    case 4: return 'boolean';
                    case 5: return 'number';
                    default:
                        return 'string';
                }
            },
            getFieldData: (fieldtype, value) => {
                switch (fieldtype) {
                    case "number": return { min: "–2147483648", max: "2147483647", type: "number", placeholder: ee.t.placeholderinteger };
                    case "datetime": return { type: "datetime-local", placeholder: ee.t.placeholderdatetime };
                    case "currency": return { min: "–79228162514264337593543950335", max: "79228162514264337593543950335", type: "number", placeholder: ee.t.placeholderdecimal };
                    case "boolean": return { placeholder: ee.t.placeholderboolean, isboolean: true, istrue: value == true ? true : false };
                    case "bignumber": return { min: "–999999999999999999", max: "999999999999999999", type: "number", placeholder: ee.t.placeholderdecimal };
                    default:
                        return { type: "text", placeholder: ee.t.placeholdertext };;
                }
            }
        }

    return that;}());
