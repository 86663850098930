/**
 * Controller from the login screen when user is not auth
 * @namespace
 * @memberof ee.views
 */
ee.views["login"] = (function ViewLogin() {
    "use strict";

    let that;
        let $frame;
        let $screen;
        let $logo;
        const _validMarks = { success: "has-success", error: "has-error" };
        const _login = (query) => {
            $screen.find("input").blur();
            if ($screen.$alert) {
                $screen.$alert ? $screen.$alert.remove() : undefined;
                $screen.$alert = undefined;
            }
            ee.indiOn();
            const $loginButton = $(".action-login");
            $loginButton.attr('disabled', true);
            return EE_API.Account.Login(query)
                .then( (data) => {
                    if (store) {
                        if (store.session("loggedByOAuth") && window.ga) {
                            window.ga("send", {
                                hitType: "event",
                                eventCategory: "Action",
                                eventAction: "login",
                                eventLabel: "duplicateAccount"
                            });

                            store.remove("loggedByOAuth");
                        }
                    } else {
                        try {
                            if (
                                sessionStorage.getItem("loggedByOAuth") &&
                                window.ga
                            ) {
                                window.ga("send", {
                                    hitType: "event",
                                    eventCategory: "Action",
                                    eventAction: "login",
                                    eventLabel: "duplicateAccount"
                                });

                                sessionStorage.removeItem("loggedByOAuth");
                            }
                        } catch (err) {
                            console.log(err);
                            console.log("Cookies and sessionStorage disabled.");
                            throw { error: ee.t.enablecookies };
                        }
                    }

                    if (data.status && typeof data.status === "string") {
                        data.status = data.status.toLowerCase();
                        if (
                            data.status === "twofactorcoderequired" ||
                            (data.status === "twofactorcodeexpired" &&
                                !query.twofactorauthcode)
                        ) {
                            ee.indiOff();
                            that.showTwoFactorAuth(
                                query.username,
                                query.password,
                                query.rememberme
                            );
                            return;
                        }
                        if (query.disable2FA) {
                            data.status += "backupcode";
                        }
                        if ($screen.twofapasscode) {
                            //if login error occured during this step there must be some problem with 2fa code - remove it and show error.
                            $screen.twofapasscode.val("").trigger("blur");
                        }
                        throw new Error(data.status);
                    }
                    _loginSuccess(query, data);
                })
                .catch((err) => {
                    $loginButton ? $loginButton.removeAttr('disabled') : undefined;
                    _alertHandle(err);
                });
        };
        let _alertHandle = (obj) => {
            const data = {
                content: ee.t.unknowndesc,
                type: obj.type ? obj.type : "alert-danger"
            };

            if (obj === 'timeout') {
                data.content = ee.t.loginconnectionproblem
                data.type = "alert-danger"
            } else if (obj.error) {
                data.content = obj.error;
            } else if (obj.message) {
                data.content = ee.t[obj.message];
                if (obj.message === "twofactorcodeexpired")
                    data.type = "alert-info";
                }
            if (obj.messageid) {
                switch (obj.messageid) {
                    case "login_neversignedin":
                        var resendlinkstr = "<a class='action-resendactivationemail' style='display: inline-block; color: #2998F7'>" + ee.t.resendactivationemail + "</a> "
                        data.content = Mustache.render(ee.t[obj.messageid], {
                        email: obj.messageparts[0] ? obj.messageparts[0] : $('#eusername').val(),
                        resendlink: resendlinkstr
                    });
                    break;
                    case "login_invalidusernameorpassword":
                        data.content = ee.t.invalidusernameorpassword;
                    break;
                }
            }
            const $alert = html.get('AlertBody', data);
            $screen.$alert ? $screen.$alert.remove() : undefined;
            $screen.find(".authframe-form").prepend($alert);
            $screen.$alert = $alert;
            ee.indiOff();
            //bind interactions
            _bindAlertInteractions($alert, obj)

        };
        let _bindAlertInteractions = ($container, obj) => {
            switch (obj.messageid) {
                case "login_neversignedin":
                    $container.find('.action-resendactivationemail').on('click', () => {
                        _allowResend(obj);
                    });
                break;
            }
        };
        const _googleOauthCallback = function(resp, $screen) {
            const params = ee.session.getOauthParams(resp);
            return ee.session.login(params, $screen, _alertHandle);
        };
        const _ssoCallback = function(resp, $screen) {
            const params = ee.session.getSSOParams(resp);
            return ee.session.login(params, $screen, _alertHandle);
        };
        let _loginSuccess = function (query, data) {
            const apiKey = typeof data === "string" ? data : data.apikey;
            if (query.disable2FA) {
                try {
                    sessionStorage.setItem("disabled2FA", "true");
                } catch (err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }
            }
            if (ee.isEELoginShown() && (window.location.hash !== '#/dashboard' || window.location.hash !== '' || !window.location.hash.includes('#/create-account'))) {
                Cookies.set('website_redirect_url', window.location.hash, {expires:0.04})
            }

            ee.session.setAccount(
                apiKey,
                query.rememberme,
                "#/dashboard",
                query.username
            );
        };
        let _allowResend = function(obj) {
            ee.indiOn();
            const $btn = $('.action-resendactivationemail');
            $btn.attr('disabled', true);
            EE_API.Account.ResendActivation({ username: obj.messageparts[0] ? obj.messageparts[0] : $('#eusername').val()
            }).then(function () {
                $('.alert p').text(ee.t.emailhasbeenresent);
            }).catch(function () {
                $btn.attr('disabled', false);
            }).always(function () {
                $btn.blur();
                ee.indiOff();
            })
        };

    /*-- PUBLIC --*/
    that = {
        show: () => {
            $frame = html.get("authFrame");
            ee.container.html($frame);
            return ee.initNextGen();
        },

        showLogin: function (username) {
            html.widget.Marketing.runHj("signup");
            username = typeof username === "string" ? username : "";
            $screen = html.get("LoginScreen", {
                base: !ee.isReseller(),
                debug: ee.isDebug(),
                logo: $logo,
                username: username,
                gauth:ee.isOAuthEnabled(),
                host: window.location.origin,
                ssoauth: ee.isSSOEnabled(),
                eelogin: ee.isEELoginShown()
            });
            $screen.appendTo($frame.authframecontent.empty());

            if(!ee.isEELoginShown()) {
                $frame.authframetop.hide();
            }

            if (navigator.cookieEnabled) {
                ee.attachGoogleSignin(
                    "gSignInWrapper",
                    $screen,
                    _googleOauthCallback
                );
            } else {
                console.warn("Cookies disabled")
            }

            ee.attachSSOSignin(
                "ssoSignInWrapper",
                $screen,
                _ssoCallback
            );

            if (ee.isEELoginShown()) {
                $screen.loginform.on("submit", function (e) {
                    e.preventDefault();
                    $(this).find(".action-login")[0].click();
                })
            }

            $screen.on("click", ".action-forgot", () => {ee.goTo("#/login/forgot")});
            $screen.on("click", ".action-login", function () {
                _login($screen.elasticCollectForm());
            });

            html.showPasswordTip($screen);
            if (username) {
                _alertHandle({
                    message: "accountactivated",
                    type: "alert-success"
                });
                ee.changeHashQuietly("#/");
                $screen.passwd.focus();
            } else {
                ee.isEELoginShown() && $screen.username.focus();
            }
            if (!ee.isEELoginShown()) {
                ee.indiOn();
                const mgr = ee.session.ssoAuthSetup();
                const data = {
                    hash: location.hash.length > 0 ? location.hash : '#'
                };
                if (mgr) {
                    if (window.location.hash === ''){
                        mgr.signinRedirect().catch((error) => {
                            alert("SSO authorization \n" + error);
                        });
                    }
                    if (data.hash === window.location.hash) {
                        mgr.signinRedirectCallback().then((user) => {
                            return _ssoCallback(user, $screen);
                        }).catch((error) => {
                            alert("SSO authorization \n" + error);
                        });
                    }
                }
            }
        },
        showTwoFactorAuth: function (
            username,
            password,
            rememberme,
            googleIdToken,
            authProviderToken
        ) {
            const query = {
                username: username,
                password: password,
                rememberme: rememberme
            };
                let autoVerify = true;
            if (googleIdToken) {
                query.googleIdToken = googleIdToken;
            }
            if (authProviderToken) {
                query.authProviderToken = authProviderToken;
            }

            if(!ee.isEELoginShown()) {
                $frame.authframetop.show();
            }

            $screen = html.get("TwoFactorAuth", { base: ee.isLive() });

            const $authContent = $("#eauthframecontent");

            $screen.appendTo($authContent.empty());
            $screen.on("click", "#ecantlogin", function () {
                that.showTwoFactorAuthBackup(query.username, query.password);
            });
            $screen.on("click", ".action-login", function () {
                query.twofactorauthcode = $screen.twofapasscode.val();
                _login(query);
            });
            $screen.on("click", ".action-resend", function () {
                const $btn = $(this);
                if ($btn.attr("disabled")) {
                    return;
                }
                $btn.attr("disabled", true).html(
                    ee.t.twofapasscoderesendinprogress
                );
                const options = {}
                if (googleIdToken) options.googleIdToken = googleIdToken;
                else if (authProviderToken) options.authProviderToken = authProviderToken;
                else {
                    options.username = username;
                    options.password = password;
                }
                EE_API.Account.ResendTwoFactorAuthCode(options)
                    .then(function () {
                        $btn.html(ee.t.twofapasscoderesend);
                        setTimeout(function () {
                            $btn.removeAttr("disabled").html(
                                ee.t.twofaresendpasscode
                            );
                        }, 5000);
                    })
                    .catch(function () {
                        $btn.removeAttr("disabled").html(
                            ee.t.twofaresendpasscode
                        );
                    });
            });
            $screen.twofapasscode.focus();
            new html.Validator($screen, {
                submit: ".action-login",
                inputs: [
                    {
                        name: "passcode",
                        css: _validMarks,
                        msg: ee.t.errorpasscode,
                        messageSelector: ".help-block",
                        validFunc: function () {
                            const isValid =
                                this.val().length == 6 &&
                                $.isNumeric(this.val());
                            //Auto check
                            if (isValid === true && autoVerify === true) {
                                autoVerify = false;
                                query.twofactorauthcode = $screen.twofapasscode.val();
                                _login(query);
                            }
                            return isValid;
                        }
                    }
                ]
            });

            $screen.keyup(function (e) {
                if (e.keyCode === 13) {
                    $screen.trigger(
                        $.Event("click", {
                            target: $screen.find(".action-login")[0]
                        })
                    );
                }
            });
        },
        showTwoFactorAuthBackup: function (username, password) {
            const query = {
                username: username,
                password: password,
                disable2FA: true
            };
                let autoVerify = true;
            $screen = html.get("TwoFactorAuthBackup", {
                base: ee.isLive(),
                logo: $logo
            });
            $screen.appendTo($frame.authframecontent.empty());
            $screen.on("click", ".action-back", function () {
                that.showTwoFactorAuth(username, password);
            });
            $screen.on("click", ".action-login", function () {
                query.twofactorauthcode = $screen.twofabackupcode.val();
                _login(query);
            });
            $screen.twofabackupcode.focus();

            new html.Validator($screen, {
                submit: ".action-login",
                inputs: [
                    {
                        name: "backupcode",
                        css: _validMarks,
                        msg: ee.t.errorbackupcode,
                        messageSelector: ".help-block",
                        validFunc: function () {
                            const isValid =
                                this.val().length == 6 &&
                                $.isNumeric(this.val());
                            if (isValid === true && autoVerify === true) {
                                autoVerify = false;
                                query.twofactorauthcode = $screen.twofabackupcode.val();
                                _login(query);
                                return false;
                            }
                            return isValid;
                        }
                    }
                ]
            });

            $screen.keyup(function (e) {
                if (e.keyCode === 13) {
                    $screen.trigger(
                        $.Event("click", {
                            target: $screen.find(".action-login")[0]
                        })
                    );
                }
            });
        },
        showPasswordReminder: function () {
            $screen = html.get("PasswordReminder", {
                base: ee.isLive(),
                logo: $logo
            });
            $screen.appendTo($frame.authframecontent.empty());
            $screen.on("click", ".action-back", that.showLogin);
            $screen.on("click", ".action-login", function (e) {
                e.preventDefault();
                ee.indiOn();
                EE_API.Account.Forgot({
                    sourceurl: ee.baseurl,
                    email: $screen.username.val()
                })
                    .then(function () {
                        $screen.passwordremindersuccess.removeClass("hidden");
                        $screen.find(".authframe-header .action-back").hide();
                        $screen.passwordreminder.remove();
                        ee.indiOff();
                    })
                    .catch(_alertHandle);
            });
            $screen.username.focus();
            new html.Validator($screen, {
                submit: ".action-login",
                inputs: [
                    {
                        name: "username",
                        css: _validMarks,
                        msg: ee.t.emailinvalid,
                        messageSelector: ".help-block",
                        validFunc: function () {
                            return ee.tools.isValidEmail(this.val().trim());
                        }
                    }
                ]
            });
        },
        showResetPassword: function () {
            $screen = html.get("NewResetPassword", {
                base: ee.isLive(),
                logo: $logo
            });
            $screen.appendTo($frame.authframecontent.empty());
            $screen.on("click", ".action-back", function () {
                window.location = "";
            });

            $screen.on("click", ".action-restartpassword", function (e) {
                e.preventDefault();
                ee.indiOn();
                const query = {
                    verificationkey: ee.Router.parameter("tmpkey"),
                    password: $screen.confirmpassword.val(),
                    expireDashboardSessions: true
                };
                const resetAction =
                    ee.Router.parameter("option") === "user"
                        ? EE_API.User.ResetPassword(query)
                        : EE_API.Account.RestartPassword(query);
                resetAction
                    .then(function () {
                        $screen.passwordrestartsuccess.removeClass("hidden");
                        $screen.passowrdrestart.remove();
                        ee.indiOff();
                    })
                    .catch(_alertHandle);
            });
            $screen.password.focus();
            new html.Validator($screen, {
                submit: ".action-restartpassword",
                inputs: [
                    {
                        name: "password",
                        css: _validMarks,
                        msg: ee.t.passwordtooshort,
                        messageSelector: ".help-block",
                        validFunc: function () {
                            return this.val().length >= 6;
                        }
                    },
                    {
                        name: "confirmpassword",
                        css: _validMarks,
                        validFunc: function (inputs) {
                            const pswd = inputs.get("name", "password");
                            if (
                                pswd &&
                                pswd.$elem &&
                                this.val().length >= 6 &&
                                this.val() === pswd.$elem.val()
                            ) {
                                return true;
                            }
                            return false;
                        }
                    }
                ]
            });
        },
        logout: function (callLogout = true) {
            const Action = (callLogout && ee.session.apikey()) ? 
            () => { return EE_API.Account.Logout() } :
            () => { return Promise.resolve() }

            ee.tools.clearAllTimeouts();
            const loggedBySSO = ee.parameters.authProviderClientID && (store.session.has("loggedBySSO") || sessionStorage.getItem("loggedBySSO"));

            if (loggedBySSO){
                const mgr = ee.session.ssoAuthSetup();

                if (mgr) {
                    if (ee.parameters.authProviderLogoutUrl) {
                        mgr.signoutRedirect();
                    } else {
                        mgr.removeUser();
                    }
                }
            }

            Action()
            .then(() => {
                const inboundNotification = store.local.get('inboundNotification');
                const showExtendLogsBanner = store.local.get('showExtendLogsBanner');
                const browserToken = localStorage.getItem('browserToken');
                ee.session.destroy();

                ee.data = {};
                try {
                    store.session.clearAll();
                    store.local.remove('remember');
                } catch (err) {
                    console.log(err);
                    console.log("You need to enable session storage");
                }

                clearTimeout(ee.api.eventTimeout);
                localStorage.setItem('locale',ee.session.getLanguage())
                localStorage.setItem('browserToken',browserToken);
                inboundNotification && localStorage.setItem('inboundNotification',inboundNotification);
                showExtendLogsBanner && localStorage.setItem('showExtendLogsBanner', JSON.stringify(showExtendLogsBanner));
                if (!loggedBySSO || (loggedBySSO && !ee.parameters.authProviderLogoutUrl)) {
                 
                    if(ee.isStandalone || ee.bootedAsChild){
                        window.top.location.reload();
                        return;
                    }
                    if (window.location.hash === "") {
                        ee.showView("login");
                    } else {
                        ee.goTo("#");
                    }
                }
            })
            .catch((e) => {
                console.error(e)
            })
        }
    };
    return that;
})();
