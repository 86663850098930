(function (namespace) {
    var _format = function () {
            if (this.filename.match(/\.(bmp|gif|jpg|jpeg|png|tiff|jfif)$/i)) {
                this.type = "imagelist";
            } else {
                this.type = "filelist";
            }

        this.url = ee.api.apiurl + "/userfile/" + (ee.data.account.publicaccountid || ee.data.account.affiliatelink) + "/" + this.filename;
        this.mediumscreenshotfullpath = ee.api.apiurl + "/userfile/" + this.mediumscreenshotpath;
        this.thumbnailfullpath = ee.api.apiurl + "/userfile/" + this.thumbnailpath;
    },
        UserFiles = function UserFiles(dataArr) {
            if (dataModel.isModel("UserFiles", ee.data.filelists)) return ee.data.filelists;
            Collection.Data.apply(this, dataArr);
    };

    var options = {
        collection: true,
        storage: {
            name: "filelists",
            load: EE_API.UserFiles.UploadList
        }
    };

    var proto = {
        add: function (data) {
            Collection.Data.prototype.add.call(this, data).each(_format);
            return this;
        },
        replace: function (key, value, data) {
            Collection.Data.prototype.replace.call(this, key, value, data).each(_format);
            return this;
        },
        update: function (data) {
            Collection.Data.prototype.update.call(this, data).each(_format);
            return this;
        },
        delete: function (fileid) {
            ee.indiOn();
            var that = this,
                query = { fileID: fileid },
                del = EE_API.UserFiles.DeleteFile(query);

            return $.when(del).then(function (data) {
                ee.indiOff();
                return that;
            }).catch(function (err) {
                html.modal.error(err);
            });
        }
    };

    dataModel.inherit(UserFiles, proto, options).register("UserFiles", UserFiles);

})(window.dataModel);
