(function (namespace) {


    function _fillEmptyData(data) {
        let collection = new Collection.Data(data);
        let dates = [];

        //this code creates dates in the past, to prevent empty graph
        let todate = moment().startOf("day").hours("0");
        let fromdate = moment(todate).subtract(1, "months");
        while (todate.diff(fromdate, "days") >= 0) {
            let emptyReputation = {
                abusepercent: 0,
                unknownuserspercent: 0,
                openedpercent: 0,
                clickedpercent: 0,
                averagespamscore: 0,
                setupscore: 0,
                repemailssent: 0,
                reputation: ee.data.account.reputation,
                datecreated: ee.tools.time(fromdate).toDate("YYYY-MM-DDT00:00:00")
            }
            
            dates.push(emptyReputation);
            fromdate.add(1, "days");
        }
        //this code is for actual dates
        _.forEach(collection.data,(elem) => {
            _.find(dates, (singledate, index) => {
                if (elem.datecreated === singledate.datecreated) {
                    _.merge(singledate, elem)
                }
            })

        })
        return dates;
    }
    function _format(elem) {

        var stars = html.widget.Stars.get(elem.reputation);

        elem.averagespamscore = elem.averagespamscore.toFixed(2);
        elem.failedspampercent = elem.failedspampercent.toFixed(2);
        elem.abusepercent = elem.abusepercent.toFixed(4);
        elem.unknownuserspercent = elem.unknownuserspercent.toFixed(2);
        elem.openedpercent = elem.openedpercent.toFixed(2);
        elem.clickedpercent = elem.clickedpercent.toFixed(2);
        for (var key in elem.impact) {
            elem.impact[key] = elem.impact[key].toFixed(2);
        }
        elem.reputationformatted = elem.reputation.toFixed(2);
        elem.repscoreval = 0;
        $.map(elem.impact, function (value) { elem.repscoreval += parseFloat(value) });
        elem.repscoreval = elem.repscoreval.toFixed(2);
        elem.repstars = stars.count;
        elem.repstarhtml = stars.html;

        if (elem.reputation >= 85) elem.repscore = ("RepScoreGood");
        else if (elem.reputation >= 75) elem.repscore = ("RepScoreWarning");
        else if (elem.reputation >= 40) elem.repscore = ("RepScoreBad");
        else elem.repscore = ("RepScoreBlack");
    }
    function _formatReputationHistory() {
        var elem = this;
        elem.showabusepercent = elem.abusepercent.toFixed(4) || "0.0000";
        elem.showunknownuserspercent = elem.unknownuserspercent.toFixed(2) || "0.00";
        elem.showopenedpercent = elem.openedpercent.toFixed(2) || "0.00";
        elem.showclickedpercent = elem.clickedpercent.toFixed(2) || "0.00";
        elem.showaveragespamscore = elem.averagespamscore.toFixed(2) || "0.00";
        elem.showdatecreated = ee.tools.time(elem.datecreated).toDate('L');
        elem.repdeduction = (100 - elem.reputation).toFixed(2) || "0.00";
        elem.repstarhtml = html.widget.Stars.get(elem.reputation).html;
        elem.graphdate = ee.tools.time(elem.datecreated).toDate('DD/MM/YYYY');
        elem.abusepercent = parseFloat(elem.showabusepercent);
        elem.unknownuserspercent = parseFloat(elem.showunknownuserspercent);
        elem.openedpercent = parseFloat(elem.showopenedpercent);
        elem.clickedpercent = parseFloat(elem.showclickedpercent);
        elem.averagespamscore = parseFloat(elem.showaveragespamscore);
        elem.reputation = parseFloat((elem.reputation).toFixed(2));
    };
    function _formatSpamHistory() {
        var style = "egreenlist";
        var val = parseFloat(this.totalscore);
        if (val >= 1 && val < 2) style = "eyellowlist"
        if (val >= 2) style = "eredlist"
        this.style = style;
        this.date = ee.api.formatDate(this.date, ee.t.datetimeformat);
    }
    var Reputation = function Reputation(apikey) {
        var self = this;

        self.apikey = apikey || ee.session.apikey(),
        self.impact = {};
        self.spamcheck = {};
        self.history = {};
        return self;
    };

    var proto = {
        loadImpact: function () {
            var self = this;
            return EE_API.Account.LoadReputationImpact()
                .then(function (data) {
                    self.impact = data;
                    _format(self.impact);
                })
        },
        loadSpamCheck: function () {
            var self = this;
            var query = {
                offset: 0,
                limit: 50
            }

            return EE_API.Account.LoadSpamCheck(query)
                .then(function (data) {
                    var spamhistory = new Collection.Data(data);

                    spamhistory.each(_formatSpamHistory);

                    self.spamcheck = spamhistory;
                    return spamhistory;
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        loadReputationHistory: function () {
            var self = this;
            return EE_API.Account.LoadReputationHistory()
                .then(function (data) {
                    var historyData = _fillEmptyData(data);
                    self.history = new Collection.Data(historyData);
                    self.history.each(_formatReputationHistory);
                })
                .catch(err => {
                    console.log(err)
                });
        }
    };

    $.extend(Reputation.prototype, proto);

    dataModel.register("Reputation", Reputation);

}(window.dataModel));
