(function (root) {
    //Closure Variables
    var acceptableExtension = ['zip', 'csv', 'json', 'xml', 'txt'],
        $addcontactsoverlay = false,
        frameoverlay = false,
        $steps = [],
        _checkResponse = function (response) {

            var hasOwn = Object.prototype.hasOwnProperty,
                validate = false;

            for (var prop in response) {
                if (hasOwn.call(response, prop) && response[prop] > 0 && prop !== 'servicebusy' && prop !== 'userstatus') {
                    validate = true;
                    break;
                }
            }

            return validate;

        },
        __getPercForGraph = function (string) {
            var lastChart = string.charAt(string.length - 1);
            if (lastChart !== "0" && lastChart !== ".") return string;
            if (lastChart === '.') return string.slice(0, string.length - 1);
            return __getPercForGraph(string.slice(0, string.length - 1));
        },
        __showLegend = function (graph, statuses, colors) {

            var count = 0;
            for (var prop in statuses) if (statuses.hasOwnProperty(prop) && statuses[prop] > 0)++count;

            var $legend = html.get('UploadSuccessLegend', {
                list: [
                    { name: ee.t.active, val: statuses.activecount, color: colors.Active, valFormatted: statuses.activecount.numberFormat(0, ' ', ' ') },
                    { name: ee.t.inactive, val: statuses.inactivecount, color: colors.Inactive, valFormatted: (statuses.inactivecount) ? statuses.inactivecount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.invalid, val: statuses.bouncedcount, color: colors.Invalid, valFormatted: (statuses.bouncedcount) ? statuses.bouncedcount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.unsubscribed, val: statuses.unsubscribecount, color: colors.Unsubscribed, valFormatted: (statuses.unsubscribecount) ? statuses.unsubscribecount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.transactional, val: statuses.transactionalcount, color: colors.Transactional, valFormatted: (statuses.transactionalcount) ? statuses.transactionalcount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.complaint, val: statuses.abusecount, color: colors.Complaint, valFormatted: (statuses.abusecount) ? statuses.abusecount.numberFormat(0, ' ', ' ') : '0' },
                    // { name: "Bounced", val: statuses.bouncedcount, color: colors.Bounced, valFormatted: (statuses.bouncedcount) ? statuses.bouncedcount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.stale, val: statuses.stalecount, color: colors.Stale, valFormatted: (statuses.stalecount) ? statuses.stalecount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.duplicated, val: statuses.notuploadedduplicatedcount, color: colors.Duplicated, valFormatted: (statuses.notuploadedduplicatedcount) ? statuses.notuploadedduplicatedcount.numberFormat(0, ' ', ' ') : '0' },
                    { name: ee.t.syntaxerror, val: statuses.notuploadedsyntaxcount, color: colors['Syntax Error'], valFormatted: (statuses.notuploadedsyntaxcount) ? statuses.notuploadedsyntaxcount.numberFormat(0, ' ', ' ') : '0' }
                    // { name: "Abuse", val: statuses.abusecount, color: colors.Abused, valFormatted: (statuses.abusecount) ? statuses.abusecount.numberFormat(0, ' ', ' ') : '0' }
                ],
                twoOrFour: (count === 2 || count === 4) ? true : false
            });
            $('#egraphlegend').append($legend);
            $legend.on('mouseenter', '.legend_item', function () {
                var focus = $(this).data('focus');
                $legend.find('.legend_item').addClass('off');
                $(this).removeClass('off');
                graph.focus(focus);
            });
            $legend.on('mouseleave', '.legend_item', function () {
                $legend.find('.legend_item').removeClass('off');
                graph.focus();
            })
        },


        _eventFileUpload = function () {
            var dragging = 0;
            _autocompleteDomain();
            frameoverlay.$container[0].addEventListener('dragenter', function (e) {
                e.stopPropagation();
                e.preventDefault();
                if ($addcontactsoverlay.superaddinput.val() !== '') { return }
                dragging++;
                $addcontactsoverlay.dropzone.show();
            }, false);

            $addcontactsoverlay.dropzone[0].addEventListener('dragover', function (e) {
                e.stopPropagation();
                e.preventDefault();
            }, false);

            $addcontactsoverlay.dropzone[0].addEventListener('dragleave', function (e) {
                e.stopPropagation();
                e.preventDefault();
                //hack for dragleave on child elements
                dragging--;
                if (dragging === 1) {
                    dragging = 0;
                    $addcontactsoverlay.dropzone.hide();
                }
            }, false);
            //Upload file
            $addcontactsoverlay.dropzone[0].addEventListener('drop', function (e) {
                e.preventDefault();
                $addcontactsoverlay.dropzone.hide();
                dragging = 0;
                _fileUpload(e.dataTransfer.files[0])
                return false;
            }, false);
        },
        _fileUpload = function (fileUploaded) {
            if (fileUploaded == undefined) {
                html.modal.info(html.render(ee.t.contactuploadmissingfile, {}), ee.t.missingfile);
                return;
            }

            var fileExtension = (fileUploaded.name.split('.').pop()).toLowerCase(),
                maxFileSize = 20971520;
            if (acceptableExtension.indexOf(fileExtension) === -1) {
                html.modal.info(html.render(ee.t.contactuploadwrongformat, { name: fileUploaded.name }), ee.t.invalidfileformat);
                return;
            }
            if (fileUploaded.size > maxFileSize) {
                html.modal.info(ee.t.contactuploadfiletoolarge, ee.t.error);
                return;
            }
            $steps.hide();
            $addcontactsoverlay.contactuploadprogress.show();
            ee.api.contactFindPattern(fileUploaded, false, function (data) {
                $addcontactsoverlay.contactuploadprogress.hide();
                _patternRecognition(data);
            }, function (e) {
                var percent = (e.loaded / e.total) * 100;
                $addcontactsoverlay.uploadprogress.css('width', percent + '%');
                $addcontactsoverlay.uploadpercent.html(percent.toFixed(0) + '%');
            });
        },
        _goToStep = function (indexOfStep) {
            var stepcompleted = indexOfStep - 1;
            var $licompleted = false, $linext = false;
            if (stepcompleted > -1) {
                $steps[stepcompleted].style.display = 'none';
                $licompleted = $addcontactsoverlay.stepslist.find('.action-step' + stepcompleted);
                $licompleted.removeClass('current').addClass('done');
                $licompleted.find('i').addClass('fa fa-check').text('');
            }

            if ($steps[indexOfStep]) {
                $steps[indexOfStep].style.display = 'block';
                $linext = $addcontactsoverlay.stepslist.find('.action-step' + indexOfStep);

                $linext.addClass('current');
            }
        },
        _resetClosureVariables = function () {
            if (frameoverlay !== false) {
                frameoverlay.close();
                frameoverlay = false;
            }
            $addcontactsoverlay = false;
            $steps = [];
        },
        _checkInputValid = function (input) {
            var $this = $(input),
                value = $this.val().trim(),
                container = $this.closest('.eheader'),
                contactfield = $this.closest('.econtactfield');

            contactfield.removeClass('novalid');
            container.find('.novalidmsg').remove();

            if (!value) {
                contactfield.find('li[data-slug="ignore"]').trigger("click");
                return;
            }
            /*check for white spaces*/
            if (!(/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(value))) {
                contactfield.addClass('novalid');
                contactfield.find('.eheader').append('<div class="novalidmsg">' + ee.t.errornewfieldnovalid + '</div>');
            }

        },
        _isFormValid = function (form) {
            var count = 0;
            if (form.find('.econtactfield').hasClass('duplicate')) count += 1;
            if (form.find('.econtactfield').hasClass('novalid')) count += 1;

            if (count > 0) {
                form.find('.egotooptions').addClass('disabled').text(ee.t.errorformnovalid);
                return false;
            } else {
                form.find('.egotooptions').removeClass('disabled').text(ee.t.savesettings);
                return true;
            }
        },
        _findDuplicate = function () {
            var allSeleted = new Collection.Data($addcontactsoverlay.fieldchoosing.find('li.eselected').map(function () {
                var contactObject = {
                    name: this.dataset.name, slug: this.dataset.slug, $obj: $(this).closest('.econtactfield')
                }
                if (this.dataset.slug === "addnew") contactObject.slug = contactObject.$obj.find('input:first').val();
                return contactObject;
            }).get());
            while (allSeleted.data.length > 0) {
                var seleted = allSeleted.removeFirst();
                var duplicate = allSeleted.filter(['slug'], seleted.slug, true);
                allSeleted = allSeleted.filter(['slug'], seleted.slug, true, true);
                if (duplicate.data.length > 0) {
                    duplicate.each(function () { this.$obj.addClass('duplicate'); });
                    seleted.$obj.addClass('duplicate');
                } else {
                    seleted.$obj.removeClass('duplicate');
                }
            }
        },
        _successAddContacts = function (response, userStatus) {

            var contacts = response.contacts,
                roleemails = response.roleemailsfound,
                graph,
                statuses = response.validationstatus,
                duplicatedCount = (statuses && statuses.notuploadedduplicatedcount) ? statuses.notuploadedduplicatedcount : 0,
                syntaxErrorCount = (statuses && statuses.notuploadedsyntaxcount) ? statuses.notuploadedsyntaxcount : 0,
                contactdesc,
                colors = {
                    Active: '#5457FF',
                    Inactive: '#24cccc',
                    Invalid: '#24bbbb',
                    Unsubscribed: '#24ab91',
                    Transactional: '#24a097',
                    Complaint: '#24959c',
                    //Bounced: '#24959c',
                    Stale: '#248aa1',
                    'Syntax Error': '#d6d6d6',
                    Duplicated: '#e0e0e0'
                    //Abused: '#248aa1'
                };

            if (syntaxErrorCount) statuses.bouncedcount = statuses.bouncedcount - syntaxErrorCount;

            var $success = html.get('ContactUploadSuccess', {
                contacts: contacts.numberFormat(0),
                uploadedContacts: (contacts - duplicatedCount - syntaxErrorCount).numberFormat(0)
            });

            if (!statuses || !_checkResponse(statuses)) {

                userStatus = Number(userStatus);
                switch (userStatus) {
                    case -2:
                        userStatus = 'transactionalcount';
                        break;
                    case 4:
                        userStatus = 'inactivecount';
                        break;
                    case 1:
                        userStatus = 'bouncedcount';
                        break;
                    case 2:
                        userStatus = 'unsubscribecount';
                        break;
                    case 3:
                        userStatus = 'abusecount';
                        break;
                    default:
                        userStatus = 'activecount';
                }

                if (statuses) {
                    statuses[userStatus] = contacts;
                }
            };


            frameoverlay.$container.addClass('gradient').find('.eframeoverlay-content').addClass('container-fluid').removeClass('container').html($success);

            //$addcontactsoverlay.html($success);
            $success.find('.ecloseoverlay').one('click', function () {
                frameoverlay.close();
                new html.widget.ContactsOverviewGraphsWidget([ee.data.segments], {
                    $navigation: $('#eoverviewbuttons'),
                    $graphs: $('#egraphoverview')
                });
            });
            if (statuses) {
                html.Chart.init(function () {

                    graph = c3.generate({
                        bindto: "#eroundgraph",
                        size: {
                            height: 540
                        },
                        data: {
                            columns: [
                                ['Active', statuses.activecount || 0],
                                ['Inactive', statuses.inactivecount || 0],
                                ['Invalid', statuses.bouncedcount || 0],
                                ['Unsubscribed', statuses.unsubscribecount || 0],
                                ['Transactional', statuses.transactionalcount || 0],
                                ['Complaint', statuses.abusecount || 0],
                                //['Bounced', statuses.bouncedcount || 0],
                                ['Stale', statuses.stalecount || 0],
                                ['Duplicated', statuses.notuploadedduplicatedcount || 0],
                                ['Syntax Error', statuses.notuploadedsyntaxcount || 0]
                                //['Abused', statuses.abusecount || 0]
                            ],
                            colors: colors,
                            type: 'donut',
                        },
                        donut: {
                            label: {
                                format: function (value, ratio, id) {
                                    return __getPercForGraph((ratio * 100).toFixed(2)) + '%';
                                },
                                threshold: 0.05
                            }
                        },
                        legend: {
                            show: false
                        },
                        tooltip: {
                            contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                                var obj = d[0],
                                    percent = __getPercForGraph((obj.ratio * 100).toFixed(2)),
                                    descName = (obj.name === "Syntax Error") ? 'Syntax' : obj.name;

                                return html.get('ContactsUploadGraphTooltip', {
                                    name: obj.name,
                                    val: percent,
                                    status_desc: ee.t['status_desc_' + descName.toLowerCase()],
                                    color: color(obj)
                                }).html();
                            }
                        }
                    });
                    __showLegend(graph, statuses, colors);
                });
            }


            ee.data.availablefields = null;

            html.widget.Toast.info(ee.t.contactsuploading);
        },
        _patternRecognition = function (contactpattern) {
            _goToStep(2);
            var selectedfields = [];
            //Disabled Drag&Drop Events
            frameoverlay.$container.off('dragenter');
            $addcontactsoverlay.dropzone.remove();
            _.remove(contactpattern.headers, (pattern) => {
                return pattern.slug == "email"
            })
            //Bind events
            $addcontactsoverlay.fieldchoosing.on('click', '.dropdown-select-btn', function () {
                var $input = $(this);
                if ($input.prop('readonly')) {
                    return true;
                }
                return false;
            });

            $addcontactsoverlay.fieldchoosing.on('click', '.dropdown-menu li', function (e, skipFindDuplicate) {
                var $this = $(this), $select = $this.closest('.dropdown-select'), $input = $select.find('input:first'),
                    slug = $this.data('slug'), name = $this.data('name');
                $input.val(name);

                $input.prop('readonly', 'readonly');

                if (slug === 'addnew') {
                    $input.prop('readonly', false);
                    $input.focus();
                    $input.val($input.data('csvheader'));
                }

                var previouslySelected = $select.find('.eselected').data('slug');//remove previously selected item from array
                if (selectedfields.indexOf(previouslySelected) > -1) {
                    selectedfields.splice(selectedfields.indexOf(previouslySelected), 1);
                }
                //Remove novalid from econtacfield
                $select.closest('.econtactfield').removeClass('novalid')
                    .find('.novalidmsg').remove();
                //Ignore
                if (slug === 'ignore') {
                    $select.find("li.eselected").removeClass("eselected")
                    $select.closest('.econtactfield').attr("class", "row econtactfield eignor");
                } else {
                    selectedfields.push(slug);
                    //add new item
                    $(this).addClass('eselected').siblings().removeClass('eselected');
                    if (slug !== 'addnew') {
                        $select.closest('.econtactfield').addClass("ecorrect");
                    } else {
                        $select.closest('.econtactfield').removeClass("ecorrect");
                    }
                }
                //FIND DUPLICATE
                if (!skipFindDuplicate) {
                    _findDuplicate();
                }
                _isFormValid($addcontactsoverlay);
            });

            //First setup patterns
            $.each(contactpattern.pattern, function (index, pattern) {
                var duplicate = (selectedfields.indexOf(pattern.slug) > -1) ? true : false,
                    $row = html.get('ContactsField', { headers: contactpattern.headers, pattern: pattern, example: contactpattern.example[index] || false });
                $addcontactsoverlay.fieldchoosing.append($row);
                //Find the most frequently used elements
                var $input = $row.find('input:first');

                var $field = $row.find('.dropdown-menu li[data-slug="' + pattern.slug + '"]:first');
                if ($field.length === 0) {
                    $row.find('.dropdown-menu li[data-slug=ignore]:first').trigger('click', [true]);
                } else {
                    $field.trigger('click', [true]);
                    $input.data('value', pattern.name);

                }
            });
            var _addContactsText = function(contactpattern) {
                if (contactpattern.contacts > 1){
                return ee.t.contacts
                } else {
                return ee.t.contact
                }
            };

            var _addRecognizedContactsText = function(contactpattern) {
                if (contactpattern.contacts > 1){
                return ee.t.recognizedfields
                } else {
                return ee.t.recognizedfield
                }
            }
            _findDuplicate();
            //Create header for Patter Recognition
            contactpattern.fieldunknow = $addcontactsoverlay.fieldchoosing.find('input').map(function () {
                if (this.value === ee.t.unknowignore) return true;
            }).get().length;
            contactpattern.recognized = contactpattern.fieldsfound - contactpattern.fieldunknow;
            contactpattern.numberofcontacts = _addContactsText(contactpattern.contacts)
            contactpattern.recognizednumberofcontacts = _addRecognizedContactsText(contactpattern.contacts)
            contactpattern.issub = ee.data.account.issub;
            html.get('ContactPatternUploadInfo', contactpattern).appendTo($addcontactsoverlay.uploadinfo);
            $addcontactsoverlay.find('.egotooptions').click(function () {
                var isValid = _isFormValid($addcontactsoverlay);
                if (isValid === true) _assignContacts();
            });
            $addcontactsoverlay.newlistname.val(contactpattern.filename);
            $addcontactsoverlay.contactuploadsessionid.val(contactpattern.sessionid);
            $addcontactsoverlay.contactstringdata.val(contactpattern.stringData);
            $addcontactsoverlay.contactscount.val(contactpattern.contacts);
            $addcontactsoverlay.on("blur", 'input:not([readonly])', function () {
                _checkInputValid(this);
                _findDuplicate();
                _isFormValid($addcontactsoverlay);
            })

        },
        /*
        * Returns true/false depending on input validity against email rules
        * @param emailsArray - array containing strings to validate
        * */
        _isInputValid = function(emailsArray) {
            var isValid = true;
            _.forEach(emailsArray, function(mail) {
                if(!ee.tools.isValidEmail(mail.trim())) {
                    isValid = false;
                }
            });

            return isValid;
        },
        _showOverlay = function (listId, opt) {
            var now = new Date();
            $addcontactsoverlay = html.get('AddContacts', { enablecontactfeatures: ee.data.account.enablecontactfeatures, lists: ee.data.lists.getAll() });
            frameoverlay = new html.Frameoverlay($addcontactsoverlay).on({
                'beforeClose': function () {
                    $addcontactsoverlay = false;
                    frameoverlay = false;
                    $steps = [];
                    if (opt && opt.closeCallback) opt.closeCallback();
                }
            });

            $steps = $addcontactsoverlay.find('.este-content');
            //consent event
            $addcontactsoverlay.consent.on('change', function () {
                $addcontactsoverlay.consent.prop('disabled', 'disabled');
                _goToStep(1);
                //Drag&Drop events bind
                _eventFileUpload();
            });

            //Super Add Input
            var offset = $addcontactsoverlay.superaddinput[0].offsetHeight - $addcontactsoverlay.superaddinput[0].clientHeight;
            $addcontactsoverlay.superaddinput.on('keyup', function () {
                $addcontactsoverlay.superaddinput.css('height', 'auto').css('height', this.scrollHeight + offset);
                var splitMails = $(this).val().split(new RegExp('[\n,]', 'gm'));
                _.pull(splitMails, "");
                if (_isInputValid(splitMails)) {
                    $addcontactsoverlay.superaddcontactsbutton.prop("disabled", false);
                    if ($addcontactsoverlay.fileuploadbutton.hasClass('disabled')) {
                        $addcontactsoverlay.fileuploadbutton.removeClass('disabled');
                        $addcontactsoverlay.fileuploadbutton.click(function () {
                            $addcontactsoverlay.fileupload.trigger('click');
                        });
                    }
                } else {
                    if (!$addcontactsoverlay.fileuploadbutton.hasClass('disabled')) {
                        $addcontactsoverlay.fileuploadbutton.addClass('disabled');
                        $addcontactsoverlay.fileuploadbutton.off('click');
                    }
                    $addcontactsoverlay.superaddcontactsbutton.prop('disabled', 'disabled');
                }
            });
            $addcontactsoverlay.fileuploadbutton.click(function () {
                $addcontactsoverlay.fileupload.trigger('click');
            });
            //Input file Trigger
            $addcontactsoverlay.fileupload.on('change', function (e) {
                _fileUpload(this.files[0]);
            });
            //Send Contacts
            $addcontactsoverlay.superaddcontactsbutton.click(function (e) {
                e.stopPropagation();
                var stringData = $addcontactsoverlay.superaddinput.val();
                if (stringData !== '') {
                    ee.api.contactFindPattern(false, stringData, _patternRecognition);
                }
            });
            if (listId > 0) {
                $addcontactsoverlay.toexistinglist.removeClass('hidden');
                $addcontactsoverlay.contactuploadoptions.removeClass('hidden');
                $addcontactsoverlay.superselectlist.find('option[data-id=' + listId.toString() + ']').prop('selected', 'selected');//(listId.toString());
                $addcontactsoverlay.addtoexistinglist.trigger('click');
                $addcontactsoverlay.approveupload.prop("disabled", false);
            }
            html.inittooltip($addcontactsoverlay, '.tooltiptimeoption', {
                title: function () { return ($(this).data('mastertooltip')); },
                container: $addcontactsoverlay,
                html: true
            });
            return;
        },
        _showQuickAddModal = function (listID, opt) {
            var win = html.get("QuickAddModal", { lists: ee.data.lists.getAll() });
            var modal = html.modal.create(win, {
                title: ee.t.addcontact, buttons: [{
                    name: ee.t.addcontact,
                    css: 'btn_lg btn_primary',
                    callback: function () {
                        win.msg.empty();
                        _quickAddContact(win, modal)
                    }
                }],
                onClose: function () {
                    if (opt && opt.closeCallback) opt.closeCallback();
                }
            });

            modal.$content.on('click', '#addcontacts', function(){

                html.widget.AddContactsUI.init(null, { closeCallback: function () {
                    ee.goTo("#/contacts");
                    location.reload();
                    }
                });
            });

            if (listID)
                win.selectlist.val(listID);

            win.selectstatus.change(function () {

                if ($(this).val() == 1 || $(this).val() == 2 || $(this).val() == 3) {
                    win.subquickaddconsent.hide();
                    win.subquickaddconsenttracking.hide();
                    win.subquickaddconsentlist.text(ee.t.descaddblockedstatus);
                    $("#circlenumberthree").hide();
                } else if ($(this).val() == 4) {
                    win.subquickaddconsent.hide();
                    win.subquickaddconsenttracking.hide();
                    win.subquickaddconsentlist.hide();
                    $("#circlenumberthree").hide();
                }
                else {
                    win.subquickaddconsentlist.show();
                    win.subquickaddconsentlist.text(ee.t.descconsentsubquickadd);
                    win.subquickaddconsent.show();
                    win.subquickaddconsenttracking.show();
                    $("#circlenumberthree").show();
                }
            });

            win.subemail.on("keyup", function () {
                if (win.subemail.val().indexOf(',') != -1) { // comma
                    win.subfirst.hide();
                    win.sublast.hide();
                }
                else {
                    win.subfirst.show();
                    win.sublast.show();
                }
            });
            html.inittooltip(win, '.tooltiptimeoption', {
                title: function () { return ($(this).data('mastertooltip')); },
                container: win,
                html: true
            });
            return modal;
        },
        _quickAddContact = function (win, modal) {
            var firstname = (win.subfirst.val() === win.subfirst.data("placeholder")) ? "" : win.subfirst.val().replace(/"/ig, "'");
            var lastname = (win.sublast.val() === win.sublast.data("placeholder")) ? "" : win.sublast.val().replace(/"/ig, "'");
            //additional fields are at this moment available only for API calls
            var title = "";
            var organization = "";
            var city = "";
            var country = "";
            var state = "";
            var zip = "";
            var consentTracking = 0;
            var isAddNewChecked = $("#add_next_conact").is(":checked");

            if (win.subquickaddconsent.is(":visible") && !win.subquickaddconsent.is(":checked")) {
                html.modal.info(ee.t.descconsentsuberror, ee.t.descconsentsuberrortitle);
                return false;
            }
            if (win.subquickaddconsent.is(":visible")) {

                var inputradioval = $("input[type='radio'][name='consentTrackingSelect']:checked").data('value');

                consentTracking = inputradioval;
            }
            if (!win.subemail.val()) {
                html.modal.info(ee.t.emailaddressisempty, ee.t.error);
                return false;
            }

            ee.api.addContact(win.selectlist.val(), win.selectstatus.val(), win.subemail.val(), firstname, lastname, title, organization, city, country, state, zip, consentTracking, function () {
               // win.msg.html($('<div class="alert alert-success" id="eresultalert"><div>' + ee.t.quickaddsuccess.replace(/{{email}}/g, win.subemail.val()) + '</div></div>'));
                html.widget.Toast.info(ee.t.quickaddsuccess.replace(/{{email}}/g, win.subemail.val()));
                if (isAddNewChecked) {
                    win.find("input").val("").trigger("blur");
                    win.quickadd.find("input:first").trigger("focus");
                } else {
                    modal.$container.find("#emodalCancel").click();
                    html.Chart.init(function () {
                        new html.widget.ContactsOverviewGraphsWidget([ee.data.segments], {
                            $navigation: $('#eoverviewbuttons'),
                            $graphs: $('#egraphoverview')
                        });
                    });
                }
            });
            return false;
        },
        _assignContacts = function () {
            _goToStep(3);
            //Bind events for options newlist or existing

            ee.tools.validateEntityName(
                {
                    screen: $addcontactsoverlay,
                    inputname: '#enewlistname',
                    submit: "#eapproveupload"
                }
            )

            $addcontactsoverlay.addcontactoptions.on('click', '.eputherecontacts', function () {
                $addcontactsoverlay.addcontactoptions.find('.eputherecontacts').addClass('blure');
                $(this).removeClass('blure');
                $addcontactsoverlay.toexistinglist.addClass('hidden');
                $addcontactsoverlay.tonewlist.addClass('hidden');
                $addcontactsoverlay.contactuploadoptions.removeClass('hidden');
                $addcontactsoverlay.contacttracking.removeClass('hidden');
                $addcontactsoverlay.statusoptions.removeClass('hidden');
                var target = this.getAttribute('for');
                if (target === 'eaddtonewlist') {
                    $addcontactsoverlay.tonewlist.removeClass('hidden');

                    $addcontactsoverlay.approveupload.removeClass('disabled').prop("disabled", false);
                    if ($addcontactsoverlay.newlistname.hasClass('incorrect_input_value')) { //avoiding creation of 200+ chars long line
                        $addcontactsoverlay.approveupload.addClass('disabled').prop("disabled", true)
                    } else {
                        $addcontactsoverlay.approveupload.removeClass('disabled').prop("disabled", false)
                    }
                }
                if (target === 'eaddtoexistinglist') {
                    $addcontactsoverlay.toexistinglist.removeClass('hidden');
                    $addcontactsoverlay.approveupload.removeClass('disabled').prop("disabled", false)
                }
                //$addcontactsoverlay.approveupload.removeClass('disabled').prop("disabled", false);
            });

            $addcontactsoverlay.selectstatus.change(function () {

                if ($(this).val() == 4 || $(this).val() == 2 || $(this).val() == 3) {
                    $addcontactsoverlay.contacttracking.hide();
                }
                else {
                    $addcontactsoverlay.contacttracking.show();
                }
            });
            $addcontactsoverlay.approveupload.click(function () {
                var pattern = {},
                    statusVal,
                    createlist = false, listname = '', sessionid = $addcontactsoverlay.contactuploadsessionid.val(), stringdata = $addcontactsoverlay.contactstringdata.val(),
                    consentTracking = 'Unknown';

                $addcontactsoverlay.fieldchoosing.find('input').each(function () {
                    if ($(this).data('csvheader') && this.value !== "Unknown - Ignore") {
                        pattern[$(this).val()] = $(this).data('csvheader');
                    }
                });

                var listsdata = new Collection.Data(ee.data.lists.data);

                if ($addcontactsoverlay.optionlists.find('input[type=radio]:checked').val() === 'newlist') {
                    listname = $addcontactsoverlay.newlistname.val().trim();
                    var objectWithName = listsdata.get("listname", listname);
                    if (objectWithName) {
                        html.modal.info(ee.t.thesamelistname, ee.t.listexists);
                        return;
                    }
                    createlist = true;
                } else {
                    listname = $addcontactsoverlay.superselectlist.val();
                }
                if (listname === '') {
                    html.modal.info(ee.t.errorlistname, ee.t.errormsg);
                    return;
                } else if (listname.indexOf("'") !== -1) {
                    html.modal.info(ee.t.listnamerestriction, ee.t.errormsg);
                    return;
                }
                statusVal = $addcontactsoverlay.selectstatus.val();
                if (statusVal === '0' || statusVal === '-2') {
                    consentTracking = $addcontactsoverlay.consentTrackingSelect.val();
                }
                var createlog;

                if ($addcontactsoverlay.createcontactvalidationlog) {
                    var createlog = $addcontactsoverlay.createcontactvalidationlog.is(':checked');
                }

                ee.api.finishSuperAddContacts(sessionid, pattern, listname, createlist, statusVal, $('#eEncodingSelect').val(), createlog, consentTracking, function (response) {
                    _successAddContacts(response, statusVal);
                });
            });
            if (!ee.data.account.enablecontactfeatures) {
                $addcontactsoverlay.optionlists.find('label[for=eaddtoexistinglist]').trigger("click");
            }
        };


    var that = {
        init: function (listId, opt) {
            var lists = dataModel.create("Lists");
            var availablefields = dataModel.create("ContactFields");
            _resetClosureVariables()
            $.when(lists.require(), availablefields.require(),
            $.cachedScript(`${ee.tools.getLibPath()}lib/jquery-ui-autocomplete.js`))
                .then(function () {
                    _showOverlay(listId, opt);
                }).catch(function (err) { console.error(err) });
        },
        quickAdd: function (listID, opt) {
            var lists = dataModel.create("Lists");

            return $.when(lists.require())
            .then(function () {
                return _showQuickAddModal(listID, opt);
            });
        },
        //HELPERS Method below is used in some places, we need to refactor it
        backToUpload: function () {
            _goToStep(1);
            $addcontactsoverlay.uploadprogress.css('width', '0');
            $addcontactsoverlay.contactuploadprogress.hide();
        }

    }

    root.AddContactsUI = that;
}(html.widget));

var _autocompleteDomain = function() {
    var domains = [
    /* Default domains included */
    "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
    "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
    "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",
    /* Other global domains */
    "email.com", "fastmail.fm", "games.com" /* AOL */, "gmx.net", "hush.com", "hushmail.com", "icloud.com",
    "iname.com", "inbox.com", "lavabit.com", "love.com" /* AOL */, "outlook.com", "pobox.com", "protonmail.com",
    "rocketmail.com" /* Yahoo */, "safe-mail.net", "wow.com" /* AOL */, "ygm.com" /* AOL */,
    "ymail.com" /* Yahoo */, "zoho.com", "yandex.com",

    /* United States ISP domains */
    "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com",

    /* British ISP domains */
    "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
    "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
    "virgin.net", "wanadoo.co.uk", "bt.com",

    /* Domains used in Asia */
    "sina.com", "sina.cn", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph", "163.com", "126.com", "aliyun.com", "foxmail.com",

    /* French ISP domains */
    "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",

    /* German ISP domains */
    "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de" /* T-Mobile */, "web.de", "yahoo.de",

    /* Italian ISP domains */
    "libero.it", "virgilio.it", "hotmail.it", "aol.it", "tiscali.it", "alice.it", "live.it", "yahoo.it", "email.it", "tin.it", "poste.it", "teletu.it",

    /* Russian ISP domains */
    "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",

    /* Belgian ISP domains */
    "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",

    /* Argentinian ISP domains */
    "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",

    /* Domains used in Mexico */
    "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx",

    /* Domains used in Brazil */
    "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
  ];

      function split( val ) {
        return val.split( / \s*/ );
      }
      function extractLast( term ) {
        return split( term ).pop();
      }

      $( "#esuperaddinput" )

        .on( "keydown", function( event ) {
          if ( event.keyCode === $.ui.keyCode.TAB &&
              $( this ).autocomplete( "instance" ).menu.activeMenu ) {
            event.preventDefault();
          }
        })
        .autocomplete({
          minLength: 1,
          source: function( request, response ) {

          var afterLastSpace = request.term.split(' ').pop();


          if (afterLastSpace.includes("@") == true) {

              var domainPart = afterLastSpace.split('@')[1];

            response( $.ui.autocomplete.filter(domains, extractLast( domainPart ) ) );
          }
          },
          focus: function() {
            // prevent value inserted on focus
            return false;
          },
          select: function( event, ui ) {
            var emails = split( this.value );
            // remove the current input
            var emailToComplete = emails.pop();
            var firstPart = emailToComplete.split('@')[0];
            // add the selected item
            emails.push( firstPart + '@' + ui.item.value );
            // add placeholder to get the comma-and-space at the end

            this.value = emails.join( " " );

            return false;
          }
        });
    };

