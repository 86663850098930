(function (root) {
    "use strict";
    var $toastContainer = false, defaultOpt = {
        ico: '',
        css: '',
        fadeIn: 500,
        faeOut: 500,
        timeOut: 5000 //5s
    }

    function _checkToastContainer() {
        if ($("#toast-container").length > 0) {
            return;
        }
        $toastContainer = html.get('ToastContainer');
        ee.frame.append($toastContainer);
    }

    function _closeToast($toast) {
        $toast.fadeOut($toast.options.faeOut, function () {
            $toast.remove();
        });
    };

    function _toast(options) {
        _checkToastContainer();
        var timer, $toast;

        $toast = html.get("ToastItem", options, true);
        $toast.options = options;

        $toastContainer.append($toast)
        $toast.fadeIn(options.fadeIn);
        //Timer close
        if (options.timeOut > 0) {
            timer = setTimeout(function () {
                _closeToast($toast);
            }, options.timeOut);
        }
        //Force close
        $toast.on('click', function () {
            clearTimeout(timer);
            _closeToast($toast);
        });

        return $toast;
    };

    root.Toast = {
        info: function (text, title, options) {
            var opt = (options) ? $.extend({}, defaultOpt, options) : defaultOpt
            opt.ico = 'fa-info-circle';
            opt.css = 'toast-info';
            opt.text = text;
            return _toast(opt);
        },
        //TODO
        // warning: function (text, title, options) { },
        // success: function (text, title, options) { },
        // error: function (text, title, options) { },
        // clear: function () { },
        // remove: function () { }
    }

}(html.widget));

