var predefinedAccess;
(function (root, namespace) {
    var $screen, apikeys;
        //customAccess = { type: 'custom', description: '', value: 'None' },
        let predefinedAccess;
        let _initializePredefs = function() {
            predefinedAccess = new Collection.Data([
                {
                    type: 'none',
                    typename: "none",
                    friendlyname: 'None',
                    description: ee.t.accessnone,
                    value: 'None'.split(',')
                },
                {
                    type: 'view',
                    typename: "view",
                    friendlyname: 'View only',
                    description: ee.t.useraccessview,
                    value: ee.data.account.viewonlyaccesslevel.split(',')
                },
                {
                    type: 'limited',
                    typename: "limited",
                    friendlyname: 'Limited Access',
                    description: ee.t.useraccesslimited,
                    value: ee.data.account.userlimitedaccesslevel .split(',')
                },
                {
                    type: 'full',
                    typename: "full",
                    friendlyname: 'Full Access',
                    description: ee.t.apikeyaccessfull,
                    value: ee.data.account.apikeyfullaccesslevel.split(',')
                },
                {
                    type: 'full',
                    typename: "full",
                    friendlyname: 'Full Access',
                    description: ee.t.useraccessfull,
                    value: ee.data.account.userfullaccesslevel .split(',')
                },
                {
                    type: 'plugin',
                    typename: "plugin",
                    friendlyname: 'Plugin',
                    description: ee.t.apikeyaccessplugin,
                    value: ee.data.account.apikeypluginaccesslevel.split(',')
                },
                {
                    type: 'subscribeform',
                    typename: "subscribeform",
                    friendlyname: 'Elastic Email Sender & Subscribe form',
                    description: 'Access level required to use the "Elastic Email Sender" and Elastic Email Subscribe Form" plugins. To view or customize the specific permission settings, click the check box below.',
                    value: 'ViewAccount,ViewContacts,ViewReports,SendHttp'.split(',')
                },
            ]);
            return;
        };
        let _bindEvents = function (accesstype, options, collection) {
            let customAccess = $('input[name="accesstype"][value="custom"]');

            //Clicks and dependencies
            $('#edetailedaccesslevels').on("click", '.access-group input', function (e) {
                let that = $(this);

                if (accesstype == "user") {
                    //pre-select options for Users
                    let $requiredfor = $(".requiredfor" + $(this)[0].name.toLowerCase());
                    if (that.is(':checked') && that.val()) {
                        $requiredfor.each(function() {
                            if ($(this).prop("type") == "checkbox") {
                                $(this)
                                .prop("checked", true)
                                .prop("disabled", true)
                                .parent()
                                .prop("data-toggle", "tooltip")
                                .prop("title", ee.t.requiredfor + " " + that.prop("name"))
                            } else {
                                if (!$(this).is(":checked") && !$(this).next().is(":checked")) {
                                    $(this)
                                    .prop("checked", true);
                                }
                                $(this)
                                .prevAll('input').prop("disabled", true)
                                .parent()
                                .prop("data-toggle", "tooltip")
                                .prop("title", ee.t.requiredfor + " " + that.prop("name"))
                            }
                        })
                        $('[data-toggle="tooltip"]').tooltip();

                    } else {
                        $requiredfor.removeAttr("disabled").prevAll('input').removeAttr("disabled");
                    }
                }
                $('#eaccesstypes label').removeClass('selected');
                //customAccess.click();
                customAccess.prop("checked", true);
                $('.eaccessdescription span').text(customAccess.data("description"));
                $('.eaccessdescription').show();
            });
            $('input.accesstype').on('change', function() {
                let isCustom = this.value =="custom";
                $('#eaccesstypes label').removeClass('selected');
                $(this).closest('label').addClass("selected")
                if (!options.existing || !isCustom) {
                    $("#edetailedaccesslevels input").prop("checked", false);
                    $('.access' + this.value).prop('checked', true);
                }
                $('.eaccessdescription span').text(this.dataset["description"])
                $('.eaccessdescription').show();
                $('#eaccesslevels').show()
            });
            if (options.typename) {
                let $option = $('input[name="accesstype"][value="'+ options.typename + '"]')
                $option.attr("checked", true).closest('label').addClass("selected")
                if ($option.data("description")) {
                    $('.eaccessdescription span').text($option.data("description"))
                    $('.eaccessdescription').show();
                }
            }

            $('input.accesstype').on('change', function() {
                //get array of accesses
                //select each access from array
                let isCustom = this.value =="custom";
                let inputs = $("#edetailedaccesslevels input");
                $('#eaccesstypes label').removeClass('selected');
                $(this).closest('label').addClass("selected")
                if (!options.existing || !isCustom) {
                    let selectedAccessArray = this.dataset["accesslist"].split(",");
                    $("#edetailedaccesslevels input").prop("checked", false);
                    $("#edetailedaccesslevels input[value='']").prop("checked", true);
                    selectedAccessArray.map((access) => {
                        $('#edetailedaccesslevels')
                        .find(`input[data-value="${access}"]`)
                        .prop("checked", true)
                    })
                }
                $('.eaccessdescription span').text(this.dataset["description"])
                $('.eaccessdescription').show();
                $('#eaccesslevels').show()
            });
            if (options.typename) {
                let $option = $('input[name="accesstype"][value="'+ options.typename + '"]')
                $option.prop("checked", true).closest('label').addClass("selected")
                if ($option.data("description")) {
                    $('.eaccessdescription span').text($option.data("description"))
                    $('.eaccessdescription').show();
                }
            }

            //validation
            let _css = { success: "has-success", error: "incorrect_input_value", parentSelector: 'input' };
            let validationMethod;
            let inputname;
            let tokenName;
            let msg;
            if (accesstype == "user") {
                inputname = 'userName';
                tokenName = $('input[name="userName"]').val();
                msg = ee.t.emailinvalid;
                validationMethod = function () { return ee.tools.isValidEmail(this.val().trim()) };
            } else if (accesstype == "smtp") {
                inputname = 'tokenName';
                tokenName = $('input[name="tokenName"]').val();
                msg = ee.t.emailinvalid;
                validationMethod = function () { return ee.tools.isValidEmail(this.val().trim()) };
            } else {
                inputname = 'tokenName';
                tokenName = $('input[name="tokenName"]').val();
                validationMethod = function () { return this.val().trim().length > 0 };
            }
            new html.Validator($('#accessmanager'), {
                submit: $('#savebtn'),
                inputs: [
                    {
                        name: inputname,
                        validFunc: validationMethod,
                        messageSelector: ".validation_error_text",
                        msg,
                        css: _css
                    },
                    { name: 'accesstype', validFunc: function () {return $('input[name="accesstype"]:checked').length} }
                ]
            });

            //saving
            $('#savebtn').on('click', () => {
                ee.indiOn();
                let action;
                let query;

                if (accesstype == "user") {
                    action = options.existing ? EE_API.User.Edit : EE_API.User.Invite;
                } else if (accesstype == "apikey" || accesstype == "smtp") {
                    action = options.existing ? collection.editApikey : collection.invite;
                }

                query = $('#accesstextinputs').elasticCollectForm();
                if (accesstype == "apikey" || accesstype == "account") {
                    query.username = $('input[name=username]').val();
                    query.accesslevel = html.widget.AccessManager.getSelectedAccess($('#edetailedaccesslevels'));
                } else if (accesstype == "user") {
                    query.dashboardurl = ee.baseurl;
                    query.useraccess = html.widget.AccessManager.getSelectedAccess($('#edetailedaccesslevels'));
                }
               
                const expiryDate = $('#expiresDateDatePicker').val();

                if (expiryDate) {
                    const time = ee.tools.time(expiryDate);
                    query.expires = ee.tools.time(expiryDate).toUTC();
                }
                if (accesstype == "apikey") {
                    query.type = "APIKey";
                }
                if (accesstype == "smtp") {
                    query.type = "SMTPCredential";
                }
                //allow rename of existing token
                if (options.existing && query.tokenName && tokenName != query.tokenName) {
                    query.newTokenName = query.tokenName;
                    query.tokenName = tokenName;
                }
                //-- Send request to invite user
                return action(query, collection)
                .then(function () {
                    ee.indiOff();
                    if (accesstype == "user") {
                        ee.goTo("#/account/users")
                    } else if (options.existing && accesstype != "account") {
                        ee.goTo(`#/settings/${accesstype}`)
                    }
                })
                .catch(function (e) {
                    ee.indiOff();
                    console.log(e);
                });
            });

        };
        let that = {
            init: function ($target, accesstype, existing) {
                let options = {};
                let accessarray;
                let loadedItem;

                const { activePlan } = ee.data.account;
                options.base = ee.base;
                options.unlimited = (activePlan.typedesc === "ContactSubscription");
                options.emailapi = (activePlan.typedesc === "PayAsYouGo");

                options.userfullaccesslevel = ee.data.account.userfullaccesslevel;
                options.userlimitedaccesslevel = ee.data.account.userlimitedaccesslevel;
                options.viewonlyaccesslevel = ee.data.account.viewonlyaccesslevel;
                options.apikeyfullaccesslevel = ee.data.account.apikeyfullaccesslevel;
                options.apikeypluginaccesslevel = ee.data.account.apikeypluginaccesslevel;
                //if account
                if (accesstype == "account") {
                    options.account = true;
                    options.existing = true
                    options.accesslevel = {};
                    accessarray = existing.data.split(',');
                    accessarray.forEach(function (element) {
                        options.accesslevel[element] = true;
                    });
                    return that.renderAccessView($target, options, accesstype);
                } else {
                    options[accesstype] = true;
                    let Collection = dataModel.create(accesstype =="user" ? "UsersList" : "ApikeysList");
                    Collection.load(accesstype)
                    .then(() => {
                        options.accesslevel = {}
                        if (existing) {
                            loadedItem = Collection.get(accesstype =="user" ? 'username' : 'name', decodeURIComponent(existing));
                            options.existing = true;
                            options.typename = loadedItem.accesstype.name;
                            options.expanded = (loadedItem.restrictaccesstoiprange || !_.isEmpty(loadedItem.expires))
                            $.extend(options, _.pick(loadedItem, ['name', 'firstname', 'lastname', 'username', 'restrictaccesstoiprange']))

                            if (accesstype != "smtp") {
                                accessarray = accesstype == "apikey" ? loadedItem.accesslevel : loadedItem.useraccess;
                                accessarray.forEach(function (element) {
                                    options.accesslevel[element] = true;
                                });
                            }

                        } else if (accesstype == "smtp") {
                            options.name = ee.data.account.email;
                        }
                        return options;
                    })
                    .then((options) => {
                        return that.renderAccessView($target, options, accesstype, Collection, loadedItem);
                    })
                    .catch(function (msg) {
                        console.log(msg);
                    });
                }
            },
            renderAccessView: ($target, options, accesstype, collection, loadedItem) => {
                if ($target == "fullscreen") {
                    $target = $('#econtent');
                }
                $target.empty();
                // if admin - show only list of inputs
                let accesses = html.get("AccessLevelsList", options)
                accesses.appendTo($target);
                $('#expiresDateDatePicker').datepicker({
                    dateFormat: ee.tools.localDateFormatDatepicker(),
                    minDate: 1,
                })

                if (loadedItem && !_.isEmpty(loadedItem.expires)) {
                    const date = new Date(loadedItem.expires)
                    $('#expiresDateDatePicker').datepicker('setDate', date)
                }

                _bindEvents(accesstype, options, collection);
            },
            getAccessType: function (accessArray) {
                if (typeof predefinedAccess !== 'object') {
                    _initializePredefs();
                    return that.getAccessType(accessArray);
                }
                if (accessArray.length === ee.data.account.apikeyfullaccesslevel.split(',').length) {
                    var type = {
                        name: 'full',
                        typename: 'full',
                        friendlyname: 'Full access',
                        description: ee.t.apikeyaccessfull
                    };
                } else if (accessArray.length === ee.data.account.viewonlyaccesslevel.split(',').length) {
                    var type = {
                        name: 'view',
                        typename: 'view',
                        friendlyname: 'View only access',
                        description: ee.t.apikeyaccessview
                    };
                } else {
                    var type = {
                        name: 'custom',
                        typename: 'custom',
                        friendlyname: 'Custom access',
                        description: ee.t.apikeyaccesscustom
                    };
                }

                predefinedAccess.each(function () {
                    if (this.value.length == accessArray.length && _.isEqual(_.sortBy(this.value), _.sortBy(accessArray))) {
                        type = $.extend({name: this.type}, _.pick(this, ['typename', 'friendlyname', 'description']))
                        return false // break loop
                    }
                });
                return type;
            },
            getSelectedAccess: function ($target) {
                let access = [];
                let accesstring;
                $target.find('input:checked').each(function () {
                    var value = $(this).val().trim();
                    if (value.length) {
                        access.push(value)
                    }
                });
                if (access == "") {
                    accesstring = "None";
                } else {
                    if(access?.includes('VerifyEmails')){
                        access.push('VerifyEmails')
                    }
                    accesstring = (access.join(","))
                }
                return accesstring;
            },
        };


    namespace.AccessManager = that;
}(this, html.widget));
