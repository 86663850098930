(function (root) {
    "use strict"
    //Closure Variables
    var _formatEvent = function (data) {
        var actionoptions = [
            { type: 'None', link: '', classname: 'info', description: ee.t.none },
            { type: 'ContactUploadStart', link: '#/contacts/segments/', classname: 'info', description: ee.t.contactuploadstart, eventtype: ee.t.contacts, restriction: !ee.session.can.ViewContacts },
            { type: 'ContactUploadEnd', link: '#/contacts/segments/', classname: 'success', description: ee.t.contactuploadend, eventtype: ee.t.contacts, restriction: !ee.session.can.ViewContacts },
            { type: 'ContactUploadFailed', link: '#/contacts/segments/', classname: 'fail', description: ee.t.Contactuploadfailed, eventtype: ee.t.contacts, restriction: !ee.session.can.ViewContacts },
            { type: 'ContactExportStart', link: '#/exports/', classname: 'info', description: ee.t.contactexportstart, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'ContactExportEnd', link: '#/exports/', classname: 'success', description: ee.t.contactexportend, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'ContactExportFailed', link: '#/contacts/', classname: 'fail', description: ee.t.contactexportfailed, eventtype: ee.t.contacts, restriction: !ee.session.can.Export },
            { type: 'ListCreated', link: '#/contacts/staticlists/', classname: 'info', description: ee.t.wascreated, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'ListDeleted', link: '', classname: 'info', description: ee.t.wasdeleted, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'ListRandomStart', link: '#/contacts/staticlists/', classname: 'info', description: ee.t.listrandomstart, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'ListRandomCreated', link: '#/contacts/staticlists/', classname: 'success', description: ee.t.listrandomcreated, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'ListNthStart', link: '#/contacts/staticlists', classname: 'info', description: ee.t.listrandomstart, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'ListNthCreated', link: '#/contacts/staticlists/', classname: 'success', description: ee.t.listnthcreated, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'SegmentCreated', link: '#/contacts/segments/', classname: 'success', description: ee.t.wascreated, eventtype: ee.t.segment, restriction: !ee.session.can.ViewContacts },
            { type: 'SegmentDeleted', link: '#/contacts/', classname: 'info', description: ee.t.wasdeleted, eventtype: ee.t.segment, restriction: !ee.session.can.ViewContacts },
            { type: 'CampaignSubmit', link: '#/activity/', classname: 'success', description: ee.t.campaignsubmit, eventtype: ee.t.campaign, restriction: !ee.session.can.ViewCampaigns },
            { type: 'CampaignCancel', link: '#/campaigns', classname: 'info', description: ee.t.campaigncancel, eventtype: ee.t.campaign, restriction: !ee.session.can.ViewCampaigns },
            { type: 'CampaignExportStart', link: '#/exports/', classname: 'info', description: ee.t.campaignexportstart, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'CampaignExportEnd', link: '#/exports/', classname: 'success', description: ee.t.campaignexportend, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'CampaignExportFailed', link: '#/campaigns/', classname: 'fail', description: ee.t.campaignexportfailed, eventtype: ee.t.campaign, restriction: !ee.session.can.Export },
            { type: 'LogExportStart', link: '#/exports/', classname: 'info', description: ee.t.logexportstart, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LogExportEnd', link: '#/exports/', classname: 'success', description: ee.t.logexportend, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LogExportFailed', link: '#/activity/emails', classname: 'fail', description: ee.t.logexportfailed, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LinkLogExportStart', link: '#/exports/', classname: 'info', description: ee.t.linklogexportstart, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LinkLogExportEnd', link: '#/exports/', classname: 'success', description: ee.t.linklogexportend, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LinkLogExportFailed', link: '#/exports/', classname: 'fail', description: ee.t.linklogexportfailed, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'AccountPasswordReset', link: '#/account/', classname: 'info', description: ee.t.passwordreset, eventtype: ee.t.fromipaddress, restriction: !ee.session.can.ModifyProfile },
            { type: 'AccountPasswordChange', link: '#/account/', classname: 'info', description: ee.t.passwordchange, eventtype: ee.t.fromipaddress, restriction: !ee.session.can.ModifyProfile },
            { type: 'AccountPasswordForgot', link: '#/account/', classname: 'info', description: ee.t.passwordreminder, eventtype: ee.t.fromipaddress, restriction: !ee.session.can.ModifyProfile },
            { type: 'AccountEmailChange', link: '#/account/', classname: 'info', description: ee.t.emailchange, eventtype: ee.t.account, restriction: !ee.session.can.ModifyProfile },
            { type: 'AccountEmailReset', link: '#/account/', classname: 'info', description: ee.t.emailreset, eventtype: ee.t.fromipaddress, restriction: !ee.session.can.ModifyProfile },
            { type: 'LinkContactsExportStart', link: '#/exports/', classname: 'info', description: ee.t.linkcontactsexportstart, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LinkContactsExportEnd', link: '#/exports/', classname: 'success', description: ee.t.linkcontactsexportend, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'LinkContactsExportFailed', link: '#/exports/', classname: 'fail', description: ee.t.linkcontactsexportfailed, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'CustomFieldAddStart', link: '', classname: 'info', description: ee.t.customfieldaddstart, eventtype: ee.t.contactfield, restriction: !ee.session.can.ModifyContacts },
            { type: 'CustomFieldAddEnd', link: '#/contacts/', classname: 'success', description: ee.t.customfieldaddend, eventtype: ee.t.contactfield, restriction: !ee.session.can.ModifyContacts },
            { type: 'CustomFieldDeleteStart', link: '', classname: 'info', description: ee.t.customfielddeletestart, eventtype: ee.t.contactfield, restriction: !ee.session.can.ModifyContacts },
            { type: 'CustomFieldDeleteEnd', link: '#/contacts/', classname: 'success', description: ee.t.customfielddeleteend, eventtype: ee.t.contactfield, restriction: !ee.session.can.ModifyContacts },
            { type: 'PaymentStart', link: '', classname: 'info', description: ee.t.paymentstarted, eventtype: ee.t.payment, restriction: !ee.session.can.ViewBilling },
            { type: 'PaymentSuccessful', link: '#/account', classname: 'success', description: ee.t.paymentsuccesful, eventtype: ee.t.payment, restriction: !ee.session.can.ViewBilling },
            { type: 'PaymentDeclined', link: '#/payments', classname: 'fail', description: ee.t.paymentdeclined, eventtype: ee.t.payment, restriction: !ee.session.can.ViewBilling },
            { type: 'PaymentRegistrationStart', link: '', classname: 'info', description: ee.t.paymentregistrationstart, eventtype: ee.t.autorecharge, restriction: !ee.session.can.ViewBilling },
            { type: 'PaymentRegistrationSuccessful', link: '', classname: 'success', description: ee.t.paymentregistrationsuccess, eventtype: ee.t.autorecharge, restriction: !ee.session.can.ViewBilling },
            { type: 'PaymentRegistrationDeclined', link: '#/payments', classname: 'fail', description: ee.t.paymentregistrationdeclined, eventtype: ee.t.autorecharge, restriction: !ee.session.can.ViewBilling },
            { type: 'UnsubscribeExportStart', link: '#/exports', classname: 'info', description: ee.t.unsubscribeexportstart, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'UnsubscribeExportEnd', link: '#/exports', classname: 'success', description: ee.t.unsubscribeexportend, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'UnsubscribeExportFailed', link: '#/activity/unsubscribereasons', classname: 'fail', description: ee.t.unsubscribeexportfailed, eventtype: ee.t.file, restriction: !ee.session.can.Export },
            { type: 'ListCreationFailed', link: '#/contacts/staticlists', classname: 'fail', description: ee.t.listcreationfailed, eventtype: ee.t.list, restriction: !ee.session.can.ViewContacts },
            { type: 'AccountAPIKeyAdd', link: '#/settings/apikey', classname: 'info', description: ee.t.wasadded, eventtype: ee.t.apikey, restriction: !ee.session.can.Security},
            { type: 'AccountAPIKeyChange', link: '#/settings/apikey', classname: 'info', description: ee.t.waschanged, eventtype: ee.t.apikey, restriction: !ee.session.can.Security},
            { type: 'AccountAPIKeyDelete', link: '#/settings/apikey', classname: 'info', description: ee.t.wasremoved, eventtype: ee.t.apikey, restriction: !ee.session.can.Security},
            { type: 'AccountAddPaymentProvider', link: '', classname: 'info', description: ee.t.wasadded, eventtype: ee.t.paymentprovider , restriction: !ee.session.can.ViewBilling},
            { type: 'AccountRemovePaymentProvider', link: '', classname: 'info', description: ee.t.wasremoved, eventtype: ee.t.paymentprovider, restriction: !ee.session.can.ViewBilling},
            { type: 'DomainAdd', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainAddBlacklisted', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainSetAsDefault', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainDelete', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainDeleteDefault', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainDeleteCertificate', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainVerifySPF', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainVerifyDKIM', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainVerifyMX', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainVerifyTrackingCertificate', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainVerifyTracking', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'DomainSetVERP', link: '#/settings/domains', classname: 'info', description: "", eventtype: ee.t.domain, restriction: !ee.session.can.ModifySettings},
            { type: 'SMTPPasswordChange', link: '#/settings/smtp', classname: 'info', description: ee.t.waschanged, eventtype: ee.t.smtp, restriction: !ee.session.can.Security},
            { type: 'APIKeyChange', link: '#/settings/apikey', classname: 'info', description: ee.t.waschanged, eventtype: ee.t.apikey, restriction: !ee.session.can.Security}
        ]

        var optionsCollection = new Collection.Data(actionoptions);
        for (var i = 0; i < data.length; i++) {
            data[i].options = optionsCollection.get('type', data[i].eventid);
            //link will differ if uploaded to specific list
            data[i].date = new Date(data[i].datecreated);
            //data[i].hours = ('0' + data[i].date.getHours()).slice(-2) + ":" + ('0' + data[i].date.getMinutes()).slice(-2);
            data[i].date = ee.tools.time(data[i].datecreated).fromUTC("LT L");
            data[i].subaccount = (data[i].email !== ee.data.account.email) ? true : false;
            if ((data[i].eventobjectid != 0) && (data[i].eventid == 'ContactUploadStart' || data[i].eventid == 'ContactUploadEnd' || data[i].eventid == 'ContactUploadFailed')) {
                data[i].options.link = '#/contacts/staticlist/';
            }
        }

        //generate links
    },
        that = {
            init: function (data) {
                var $notificationsbell;
                var $notifications;
                var $notificationspopup;
                var $question;
                var notifications
                var previousLoad = ee.data.account.lasteventlogload;
                var newestNotification = (data[0] != undefined) ? data[0].datecreated : null;
                var newNotifications = ((data[0] != undefined && ee.data.account.lasteventlogload == null) || (ee.data.account.lasteventlogload < newestNotification)) ? true : false;
                var popupNotifications = (!ee.data.account.events) ? true : false;
                var $notificationsbell = ee.frame.find('#enotificationsbell');
                var $notifications = ee.frame.find('#enotifications');
                var $notificationspopup = ee.frame.find('#enotificationspopup');
                    
                $question = ee.frame.find('#equestion'),
                notifications = ee.data.account;
                _formatEvent(data);
                ee.data.account.previousevent = (ee.data.account.events && ee.data.account.events[0] != undefined) ? ee.data.account.events[0].datecreated : null;
                ee.data.account.events = data;

                if (newNotifications) {
                    $notificationsbell.addClass('new');
                    //for 10 seconds show popup about new notifications and hide it when user sees notifications
                    if (popupNotifications) {
                        $notificationspopup.show(0).fadeTo(1000, 1).delay(10000).fadeTo(1000, 0).hide(0);
                    }
                }
                $notifications.html('');
                html.get('Notificationlist', notifications).appendTo($notifications);
                $notificationspopup.off().on('click', function () {
                    $notificationspopup.hide();
                    $notificationsbell.click();
                });
                $notificationsbell.off().on('click', function () {
                    if ($notificationsbell.hasClass('new')) {
                        if (ee.session.can.ModifyAccount)
                            EE_API.Event.UpdateLastLoad();
                        ee.data.account.lasteventlogload = ee.data.account.events[0] ? ee.data.account.events[0].datecreated : null;
                    }
                    $notificationsbell.toggleClass('active').removeClass('new');
                    $notifications.toggleClass('active');
                    if ($notificationsbell.hasClass('active')){
                        var closedIcon = $('#enotificationsbell')[0].innerHTML;
                        var openedIcon = closedIcon.replace('far','fas');
                        $('#enotificationsbell')[0].innerHTML = openedIcon;
                    } else {
                        var openedIcon = $('#enotificationsbell')[0].innerHTML;
                        var closedIcon = openedIcon.replace('fas','far');
                        $('#enotificationsbell')[0].innerHTML = closedIcon;
                    }
                    $notificationspopup.hide();
                });
                function locationHashChanged() {
                    if (window.location.hash == '#/help'){
                        $question.addClass('active');
                    } else {
                        $question.removeClass('active');
                    }
                    if ( $("#equestion").length) {
                    if ($question.hasClass('active')){
                        var closedIcon = $('#equestion')[0].innerHTML;
                        var openedIcon = closedIcon.replace('far','fas');
                        $('#equestion')[0].innerHTML = openedIcon;
                    } else {
                        var openedIcon = $('#equestion')[0].innerHTML;
                        var closedIcon = openedIcon.replace('fas','far');
                        $('#equestion')[0].innerHTML = closedIcon;
                    }} else {return false}

                    if ($notificationsbell.hasClass('active')){
                        var closedIcon = $('#enotificationsbell')[0].innerHTML;
                        var openedIcon = closedIcon.replace('far','fas');
                        $('#enotificationsbell')[0].innerHTML = openedIcon;
                    } else {
                        var openedIcon = $('#enotificationsbell')[0].innerHTML;
                        var closedIcon = openedIcon.replace('fas','far');
                        $('#enotificationsbell')[0].innerHTML = closedIcon;
                    }
                };
                window.onhashchange = locationHashChanged;
            }
        };

    root.EventNotificationUI = that;

    function getLastEvent(eventsArray, eventId) {
        for (var i = 0; i < eventsArray.length; i++) {
            if(eventsArray[i].eventid === eventId) {
                return eventsArray[i];
            }
        }

        return false;
    }

}(html.widget));