/* eslint-disable */
(ee.views["automations"] = function journeys() {
    'use strict';

    var AutomationsList;
    var draftList;
    var automationSearch;
    var draftSearch;
    var automationList;
    var draftListColl;
    var automationListColl;
    var pagination = {
        draft: undefined,
        automation: undefined
    };
    var $screen;
    var $target;
    var searchVal = "";
    var activeFilters = [];
    var from;
    var to;
    let dropdownMenuIsClicked;

    var _showEditor = function () {

        ee.indiOn();
        var $content = html.get("Automations", {});
        ee.frame.content_wrapper.empty();
        $content.appendTo(ee.frame.content_wrapper);

        const url = `${ee.tools.getLibPath()}1.0/journeys.js`;
        $.getScript(url, function (data, textStatus, jqxhr) {
            var $section = $('#econtent_wrapper');
            $section.css('overflow', 'hidden');
            $section.animate({
                scrollLeft: $section.find('svg').width() / 2 - $section.width() / 2
            }, 100);
            ee.indiOff();
            console.log('%c Automation bundle loaded successfully', 'background:#BCF0D9; color:#008757; padding: .5em');
        });
    };

    var _splitDataByTabs = function (data) {
        draftList =  [];
        automationList = [];
        draftListColl = null;
        automationListColl = null;

        _.forEach(data, function (element) {
            if (element.Status === "Inactive") {
                draftList.push(element);
            } else {
                automationList.push(element);
            }
        });

        draftListColl = new Collection.Data(draftList);
        automationListColl = new Collection.Data(automationList);
    };

    var _searchAutomation = function (activeTab) {
        if ( !(automationListColl.data.length > 0) && (draftListColl.data.length > 0)) {
            $screen.find('li#draftTab').addClass('active');
            $screen.find('li#automationsTab').removeClass('active');
        }

        activeTab = activeTab ? activeTab : $screen.find('li.active')[0].id;

        automationSearch = automationListColl;
        draftSearch = draftListColl;
        var filterList = [];
        var checkInputs = $screen.find("#efilterselect input:checkbox:checked");

        for (var i = 0; i < checkInputs.length; i++) {
            activeFilters.push(checkInputs[i].value);
        }

        from = $screen.datefrom.datepicker('getDate');
        to = $screen.dateto.datepicker('getDate');

        if (activeTab === "automationsTab") {
            if (searchVal) {
                automationSearch = automationSearch.filter(['Name'], searchVal);
                $screen.searchinputautomation.val(searchVal);
            }
            if (from && to) {
                to.setHours(23);
                to.setMinutes(59);
                from.setHours(0);
                from.setMinutes(0);

                automationSearch = automationSearch.filterBetweenDate("DateCreated", from, to);
            }

            if (activeFilters.length > 0) {
                _.forEach(automationSearch.data, function (element) {
                    for (var i = -1; i < activeFilters.length; i++) {
                        if (element.Status == activeFilters[i]) {
                            filterList.push(element);
                        }
                    }
                });
                automationSearch = new Collection.Data(filterList);
            } else if (activeFilters.length === 0) {
                automationSearch = new Collection.Data([]);
            }
            pagination.automation = new html.Pagination(automationSearch.getAll(), {
                template: "AutomationsTableBody",
                footercontainer: $screen.paginationfooter
            });
            automationSearch = null;
            //ee.Router.setParameter('page', 1);
        } else {
            if (searchVal) {
                draftSearch = draftSearch.filter(['Name'], searchVal);
            }
            if (from && to) {
                to.setHours(23);
                to.setMinutes(59);
                from.setHours(0);
                from.setMinutes(0);

                draftSearch = draftSearch.filterBetweenDate("DateCreated", from, to);
            }
            pagination.draft = new html.Pagination(draftSearch.getAll(), {
                template: "AutomationsTableBody",
                footercontainer: $screen.paginationfooter
            });
            
            draftSearch = null;
            //ee.Router.setParameter('page', 1);
        }

        activeFilters = [];
        filterList = [];
    };

    var _bindEvents = function () {
        var intFromDate = new Date();
        var initToDate = new Date();
        var $loadingTemplate = html.get("TableLoadingAutomation", { info: ee.t.loadingautomations });
        $screen.refresh.on('click', function () {
            var $this = $(this);
            if (!automationList || !draftList) return;

            $this.attr('disabled', true);
            $screen.paginationresult.empty().append($loadingTemplate);

            $.when(AutomationsList.reload() )
            .then(function () {
                _splitDataByTabs(AutomationsList.data);
                _searchAutomation();
                dropdownMenuIsClicked = false;
                ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
                $this.removeAttr('disabled');
            })
            .catch(function (err) {
                console.log(err);
            });
        });
        $screen.on("click", ".eaction-preview", (e) => {
            dropdownMenuIsClicked = false;
            const that = e.target;
            const name = $(that).data("name").toString();

            ee.goTo(`#/automations/editor/${encodeURIComponent(name)}/preview`);

        });
        //edit
        $screen.on("click", ".editAutomation", (e) => {
            dropdownMenuIsClicked = false;
            const that = e.target;
            const name = $(that).data("name").toString();
            const status = $(that).data("status");

            if (status === 2) { //published
                html.modal.confirm(ee.t.editautomationerr, ee.t.editautomation, function () {
                    return API_V3.Journeys.QuickUpdateJourney(name, {Status: "Paused"})
                    .then( () => {
                        $(that).data("status", 1);
                        automationListColl.get("Name", name)['Status'] = "Paused";
                        automationListColl.get("Name", name)['StatusName'] = "Paused";
                        automationListColl.get("Name", name)['IsPaused'] = true;
                        automationListColl.get("Name", name)['IsPublished'] = false;
                    }).
                    then(function () {
                        ee.goTo(`#/automations/editor/${encodeURIComponent(name)}`);
                    })
                    .catch(function (err) {
                        html.modal.info("Something went wrong!");
                        console.log(err);
                    })
                }, undefined, { size: "emodal-md", confirmTitle: ee.t.editautomation }
                );
            } else {
                ee.goTo(`#/automations/editor/${encodeURIComponent(name)}`);
            }
        });
        html.bindSearch($screen.searchautomation, function (e) {
            automationSearch = null;
            draftSearch = null;

            if (!draftListColl && !automationListColl) return;

            searchVal = $screen.searchinputautomation.val();
            _searchAutomation();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
        });

        //create Automation btn
        $screen.find('#ecreateautomation').off().on('click', function (e) {
            ee.goTo("#/automations/editor/");
        });

        //change tab
        $screen.find('#automationsTabs li').off().on('click', function (e) {
            ee.Router.removeParameter("page");
            _searchAutomation(this.id);
            if (this.id === "draftTab") {
                $screen.filter.parent().hide();
                ee.changeHashQuietly('#/automations/drafts');
            } else {
                $screen.filter.parent().show();
                ee.changeHashQuietly("#/automations/");
            }
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
            $(window).on('hashchange', ()=>{
                if(ee.Router.currentPath().includes('automations?page')) {
                    dropdownMenuIsClicked = false;
                    ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked,that.setupMenuItemsOnClick)
                }
            })
        });

        //filter
        $screen.find("#efilterselect").on("click", 'li', function () {
            _searchAutomation();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
        });

        //daterange
        intFromDate.setMonth((initToDate.getMonth() - 24));
        $screen.dateto.datepicker({ dateFormat: "dd-mm-yy" }).datepicker("setDate", initToDate).change(function () {
            _searchAutomation();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
        });
        $screen.datefrom.datepicker({ dateFormat: "dd-mm-yy" }).datepicker("setDate", intFromDate).change(function (e) {
            _searchAutomation();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
        });
        $screen.dateselect.on('click', 'li', function () {
            var $that = $(this);
            var dateRange = $that.data('range');
            var to = new Date();
            var from = new Date();

            $screen.daterangetitle.html($that.html());
            if ($that.hasClass("drop-first") === true) {
                $screen.fromto.removeClass('hide');
            } else {
                $screen.fromto.addClass('hide');
            }
            switch (dateRange) {
                case 'day':
                    from.setTime(to.getTime() - 86400000);
                    break;
                case 'week':
                    from.setTime(to.getTime() - 604800000);
                    break;
                case '2weeks':
                    from.setTime(to.getTime() - (604800000 * 2));
                    break;
                case 'month':
                    from.setMonth((to.getMonth() - 1));
                    break;
                case '3months':
                    from.setMonth((to.getMonth() - 3));
                    break;
                case 'halfyear':
                    from.setMonth((to.getMonth() - 6));
                    break;
            }
            if (dateRange) {
                $screen.dateto.datepicker('setDate', to);
                $screen.datefrom.datepicker('setDate', from);
            }
            _searchAutomation();
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, that.setupMenuItemsOnClick)
        });

    };

    var _showAutomationWelcomeScreen = function() {
        $screen = html.get('AutomationWelcomeScreen', {});
        $screen.appendTo(ee.frame.content);

        const img = html.get('AutomationWelcomeSvg', {});
        $screen.find('.rightElem').append(img);

        $screen.find("#getStarted").on("click", function () {
            var marketingOptions = _.split(ee.data.account.marketingoptions, ',');
            marketingOptions.push('AutomationWelcomeInfoDisplayed');
            marketingOptions = _.join(marketingOptions, ',')

            ee.indiOn();
            return EE_API.Account.UpdateMarketingOptions({marketingOptions: marketingOptions})
            .then(function(resp){
                ee.data.account.marketingoptions = marketingOptions;
                ee.indiOff();
                ee.goTo("#/automations/editor");
            })
            .catch(function (err) {
                console.log(err);
            });
        });
    };

    var that = {
        show: function () {
            if (!ee.isAutomationsEnabled()) { return ee.goTo("#/dashboard"); }
           
            // if (ee.session.hasMarketingOption('AutomationWelcomeInfoDisplayed')) {
            //     switch (ee.Router.action()) {
            //         case 'editor':
            //             return ee.initNextGen();
            //         default:
            //             return ee.initNextGen();
            //     }
               
            // }
            return ee.initNextGen();
            // _showAutomationWelcomeScreen();
        },
        setupMenuItemsOnClick: (row) => {
            const normalThat = that;
            dropdownMenuIsClicked = true;
            if (row == null) {return;}
            $(row.parentElement).off('click');
            //delete
            $(row.parentElement).on("click", ".eaction-delete", function () {
                dropdownMenuIsClicked = false;
                
                const that = this;
                const name = $(that).data("name").toString();
                const status = $(that).data("status");
                const modalText = ee.t.deleteautomationmsg + " '<b>" + name + "</b>'?";

                const deleteAutomation = (data) => {
                    const usedAutomations = data ? data : {};

                    return API_V3.Journeys.DeleteJourney(encodeURIComponent(name))
                    .then(function () {
                        //that.isLoaded = false;
                        //return $.when(that.require());
                        if (status === 'Inactive') {
                            draftListColl.remove("Name", name);
                            pagination.draft.update(draftListColl.getAll());
                        } else {
                            automationListColl.remove("Name", name);
                            pagination.automation.update(automationListColl.getAll());
                        }
                        if (usedAutomations.length > 0) {
                            for (let i = 0; i < usedAutomations.length; i++) {
                                automationListColl.get("Name", usedAutomations[i])['Status'] = -1;
                                automationListColl.get("Name", usedAutomations[i])['StatusName'] = "Invalid";
                                pagination.automation.update(automationListColl.getAll());
                            }
                        }
                    }).then(()=>{
                        dropdownMenuIsClicked = false;
                        ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, normalThat.setupMenuItemsOnClick)
                    })
                    .catch( function(err) {
                        html.modal.info("Something went wrong!");
                        console.log(err);
                    })
                }

                return API_V3.Journeys.GetAutomationUsage(encodeURIComponent(name), {Resource: 3})
                .then(function(data) {
                    if (data.length > 0) {
                        const automatedCampaignName = data.join(', ');
                        html.modal.confirm(ee.t.deleteautomationusageinautomation.replace(/{{AutomatedCampaignName}}/g, "<span class='automationsName'>" + automatedCampaignName + "</span>"),
                        ee.t.removetemplate, deleteAutomation.bind(this, data), false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
                    } else {
                        html.modal.confirm(modalText, ee.t.removeautomation, deleteAutomation, undefined, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive" });
                    }
                })
                .catch(function(err) {
                    if (data && data.responseJSON) {
                        html.modal.error(data.responseJSON.Error);
                    }
                    console.log(err);
                })
            });
            
            //edit
            $(row.parentElement).on("click", ".editAutomation", function () {
                dropdownMenuIsClicked = false;
                var that = this;
                var name = $(that).data("name").toString();
                var status = $(that).data("status");

                if (status === 2) {
                    html.modal.confirm(ee.t.editautomationerr, ee.t.editautomation, function () {
                        return API_V3.Journeys.QuickUpdateJourney(name, {Status: "Paused"})
                        .then(function () {
                            $(that).data("status", 1);
                            automationListColl.get("Name", name)['Status'] = "Paused";
                            automationListColl.get("Name", name)['StatusName'] = "Paused";
                            automationListColl.get("Name", name)['IsPaused'] = true;
                            automationListColl.get("Name", name)['IsPublished'] = false;
                        }).
                        then(function () {
                            ee.goTo(`#/automations/editor/${encodeURIComponent(name)}`);
                        })
                        .catch(function (err) {
                            html.modal.info("Something went wrong!");
                            console.log(err);
                        })
                    }, undefined, { size: "emodal-md", confirmTitle: ee.t.editautomation }
                    );
                } else {
                    ee.goTo(`#/automations/editor/${encodeURIComponent(name)}`);
                }
            });

            //preview
            $(row.parentElement).on("click", ".eaction-preview", function () {
                dropdownMenuIsClicked = false;
                var that = this;
                var name = $(that).data("name").toString();

                ee.goTo(`#/automations/editor/${encodeURIComponent(name)}/preview`);

            });

            //go to reports
            $(row.parentElement).on("click", ".eaction-stats", function() {
                dropdownMenuIsClicked = false;
                const name = $(this).data("name").toString();
                ee.goTo(`#/activity/automation/single?name=${encodeURIComponent(name)}`);
            });

            //change status
            $(row.parentElement).on("click", ".eaction-changestatus", function () {
                dropdownMenuIsClicked = false;
                var that = this;
                var name = $(that).data("name").toString();
                var status = $(that).data("status");
                if (status === "Active") {
                    return API_V3.Journeys.QuickUpdateJourney(name, {Status: "Paused"})
                    .then(function () {
                        automationListColl.get("Name", name)['Status'] = "Paused";
                        automationListColl.get("Name", name)['StatusName'] = "Paused";
                        automationListColl.get("Name", name)['IsPaused'] = true;
                        automationListColl.get("Name", name)['IsPublished'] = false;
                        pagination.automation.update(automationListColl.getAll());
                    }).then(()=>{
                        dropdownMenuIsClicked = false;
                        ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, normalThat.setupMenuItemsOnClick)
                    }).catch(function (err) {
                        html.modal.info("Something went wrong!");
                        console.log(err);
                    })
                } else if (status === "Paused") {
                    return API_V3.Journeys.QuickUpdateJourney(name, {Status: "Active"})
                    .then(function () {
                        automationListColl.get("Name", name)['Status'] = "Active";
                        automationListColl.get("Name", name)['StatusName'] = "Active";
                        automationListColl.get("Name", name)['IsPaused'] = false;
                        automationListColl.get("Name", name)['IsPublished'] = true;
                        pagination.automation.update(automationListColl.getAll());
                    }).then(()=>{
                        dropdownMenuIsClicked = false;
                        ee.tools.setupMutationObserver('#eautomationtable tbody tr', dropdownMenuIsClicked, normalThat.setupMenuItemsOnClick)
                    }).catch(function (err) {
                        html.modal.info("Something went wrong!");
                        console.log(err);
                    })
                }
            });
    }
    };

    return that;
}());
