(function (root) {
    "use strict";
    var defaultOpt = {
        target: '.gallery-item',
        height: 850,
        width: 1179,
        background: "#FFF",
        format: 'image/jpeg',
        quality: 0.3,
        canvasheight: false,
        canvaswidth: false
    },

        // Return promise


        _generateTemplateThumbnail = function($thumbnailContainer, opt) {
            
            var $inprogress = $thumbnailContainer.find(".eproccessing:first");
            var $thumbnailImg = $thumbnailContainer.find('img[data-src]').first();
            $inprogress.css('display', 'block');

            var query = {
                 templateid: parseInt($thumbnailContainer.data('templateid'), 10),
                 ispublic: $thumbnailContainer.data('ispublic')
                },
            templateData = dataModel.create("Template", query);
            templateData.loadEmbededHtml()
            .then(function (result) {
                var options = $.extend({}, defaultOpt, opt);
                return html.widget.Thumbnail.generate(result, options);
            })
            .then(function (thumbnailSrc) {
                return templateData.saveThumbnail(thumbnailSrc)})
            .then(function(data) {
                const src = `${ee.api.path}/userfile${data.screenshot}?timestamp=${(new Date().getTime())}`;
                $inprogress.remove();
                $thumbnailContainer.find('img').each(function() {
                    var that = $(this);
                    that.attr('src', src);
                })
                return true;
            }).catch(function (msg) {
                console.log('Error: ' + msg.error ? msg.error : msg);
                if(msg instanceof Error) {
                    ee.captureStripeException(msg);
                } else {
                     ee.captureStripeMsg(msg.error ? msg.error : msg);
                }
                $thumbnailImg.parent().html($errorHTML);
                $inprogress.remove();
            });
        },
        that = {

            init: function ($screen, opt) {
                var options = $.extend({}, defaultOpt, opt), $errorHTML = html.getraw("ScreenshotNotFound");
                options.brokenimage = html.getraw("ImageNotFound");
                //TODO: Use here the "fetch" method in the future
                var imagesToLoad = [];
                $screen.find(options.target).each(function (i, el) { // Iterate through all .gallery-item
                    var $thumbnailContainer = $(el),
                        mediumScreenshotImgUrl = $thumbnailContainer.find('img[data-src]').last().data("src");
                      
                    const isSelectedTemplateInCampaignCreator = $thumbnailContainer[0].parentElement.className.includes('action-tempaltedetails-tab');
                    if (mediumScreenshotImgUrl) {
                        imagesToLoad.push(ee.tools.loadImage(`${mediumScreenshotImgUrl}?timestamp=${(new Date()).getTime()}`));
                            
                        if (isSelectedTemplateInCampaignCreator && !!$thumbnailContainer[0].dataset.scope) {
                            _generateTemplateThumbnail($thumbnailContainer, options);
                        }
                    } else if ($thumbnailContainer.data('templateid')) {
                        imagesToLoad.push(true);
                        _generateTemplateThumbnail($thumbnailContainer, options);
                    }
                });

                Promise.all(imagesToLoad)
                .then(function(loadedImage) {
                    $screen.find(options.target).each(function (i, el) {
                        //connect it better
                        var $thumbnailContainer = $(el);
                        const $thumbnailImg = $thumbnailContainer.find('img[data-src]').first();
                            const $mediumScreenshotImg = $thumbnailContainer.find('img[data-src]').last();
                            $thumbnailImg.hide();
                            if (loadedImage[i] && loadedImage[i].src) {
                               
                                $mediumScreenshotImg.attr('src', loadedImage[i].src).css('opacity', 1);
                            } else if ($thumbnailContainer.data('templateid')) {
                                _generateTemplateThumbnail($thumbnailContainer, options);
                                $mediumScreenshotImg.css('opacity', 1);
                            }
                        })
                    })
                    .catch(function (msg) {
                        console.log('Error: ' + msg.error ? msg.error : msg);
                        if(msg instanceof Error) {
                            ee.captureStripeException(msg);
                        } else {
                             ee.captureStripeMsg(msg.error ? msg.error : msg);
                        }
                    });

            },

        }
    root.TemplateThumbnail = that;
}(html.widget));
