(function Stars(root) {
    "use strict";

    var _getRepStars = function (reputation) {

            if (reputation == 0)
                return 0;

            if (reputation > 0 && reputation < 30)
                return 0.5;

            if (reputation >= 30 && reputation < 50)
                return 1;

            if (reputation >= 50 && reputation < 60)
                return 1.5

            if (reputation >= 60 && reputation < 70)
                return 2;

            if (reputation >= 70 && reputation < 80)
                return 2.5;

            if (reputation >= 80 && reputation < 90)
                return 3;

            if (reputation >= 90 && reputation < 95)
                return 3.5;

            if (reputation >= 95 && reputation < 98)
                return 4;

            if (reputation >= 98 && reputation < 99)
                return 4.5;

            return 5;
        },
        _getRepStarHtml = function (stars) {
            //5 stars html
            var html = "";
            for (var i = 1; i <= 5; i++) {
                var cls = "repstarempty";
                if (i <= stars)
                    cls = "repstar";
                else if (i <= stars + 0.5)
                    cls = "repstarhalf";

                html += "<div class='" + cls + "'></div>";
            }
            return html;
        };

    //W should build more universal widget, not only for reputation
    root.Stars = {
        get: function (value) {
            var count = _getRepStars(value),
                starsHtml = _getRepStarHtml(count);

            return {
                count: count,
                html: starsHtml
            }

        }
    };
}(html.widget));