(function (root) {
    var _version = "1.0.0",
        $frame,
        $nextButton,
        $progressElem,
        _overlay,
        _fromStorage,
        _stepsObj = {},
        _stepsArr = ['ProfileValidation', 'HowCollectContact', 'HowOldContactsList',
            'HowOffenRemoveContacts', 'WhatEmailsSend', 'EmailContentsDescription', 'DoRecipientsWantEmail'];
    function _changeProgressAnimation(val) {
        var width;
        switch (val) {
            case 0:
                width = "2.6rem";
                break;
            case 1:
                width = "8.2rem"
                break;
            case 2:
                width = "13.8rem"
                break;
            case 3:
                width = "19.4rem"
                break;
            case 4:
                width = '25rem';
                break;
            case 5:
                width = '30.6rem';
                break;
            case 6:
                width = '36.4rem';
                break;
        }
        $progressElem.css('width', width);
    };
    function _noValidateCRC() {
        _clearStorage();
        $frame.empty();
        var info = html.get('CRCInfoAccountDisabled', {});
        $('#ecrc_alert').remove();
        ee.data.account.underreviewnote = null;
        _overlay.off('beforeClose').changeContent(info);
    };
    function _accountReactiveted() {
        $frame = html.get('CRCInfoAccountReactiveted', $.extend({}, _stepsObj));
        _overlay.on({ 'beforeClose': function () { ee.indiOn(); window.location.reload(); } }).changeContent($frame);
    };
    function _validationSuccess() {
        _clearStorage();
        $frame = html.get('Terms&Conditions', $.extend({}, _stepsObj));
        _overlay.off('beforeClose').changeContent($frame);
        ee.data.account.crcstatus = 1;
        $frame.one('change', 'input[type="radio"]', function () {
            $frame.crc_submit.on('click', function () {
                var input = $frame.find('input[type="radio"]:checked'),
                    value;
                if (input.length === 0) return false;
                value = Number(input.val());
                ee.api.request('/account/ResolveCRCForm', { userAcceptedTerms: Boolean(value) }, function (data) {
                    ee.indiOff();
                    if (!data.success) return html.modal.error(data);
                    if (value === 1) return _accountReactiveted();
                    if (value === 0) {
                        _overlay.close();
                        var modal = html.modal.info("Your account has been closed!");
                        modal.close = function () { ee.views['login'].logout(); }
                    }
                });
            });
            $frame.crc_submit.removeClass('disabled');
        });

    };
    function _showWelcomeInfo(session) {
        var info;

        if (_fromStorage === true || session) {
            info = html.get('SessionInfo', {});
        } else {
            info = html.get('StartInfo', {});
        }
        $frame.crc_step.prepend(info);
    };
    function _generateSteps() {
        var storage,
            user = ee.data.account;

        if (ee.tools.storageAvailable('localStorage')) {
            storage = window.localStorage.getItem("crc_" + _version);
        };

        if (storage) {
            storage = JSON.parse(storage);
            _stepsObj = storage;
            _fromStorage = true;
        } else {
            _stepsObj = {
                version: _version
            };
            if (user.firstname) _stepsObj.firstname = user.firstname;
            if (user.lastname) _stepsObj.lastname = user.lastname;
            if (user.company) _stepsObj.company = user.company;
            if (user.address1) _stepsObj.address = user.address1;
            if (user.city) _stepsObj.city = user.city;
            (user.countryid) ? _stepsObj.countryid = user.countryid : _stepsObj.countryid = 0;
        }
    };
    function _clearStorage() {
        if (ee.tools.storageAvailable('localStorage')) {
            window.localStorage.removeItem("crc_" + _version);
        }
    };
    function _saveStorage() {
        if (ee.tools.storageAvailable('localStorage')) {
            window.localStorage.removeItem("crc_" + _version);
            window.localStorage.setItem("crc_" + _version, JSON.stringify(_stepsObj));
        }
    };
    function _initValidation(val) {

        $nextButton = $frame.crc_continue;

        if (val + 1 === _stepsArr.length) $nextButton = $frame.find('#ecrc_finish');

        switch (val) {
            case 0:
                _profileValidation();
                break;
            case 1:
            case 2:
            case 4:
                _checkBoxValidation();
                break;
            case 3:
                _radioValidation('remove_contacts');
                break;
            case 6:
                _radioValidation('want_emails');
                break;
            case 5:
                _emailContentsDescription();
                break;
        }
    };
    function _showErr($element) {
        var name = $element.prop('name');
        $element.addClass('ewarning');
        if (name) { $element.parent().find('#' + name + '_errMsg').show(); }
    };
    function _hideErr($element) {
        var name = $element.prop('name');
        $element.removeClass('ewarning');
        if (name) { $element.parent().find('#' + name + '_errMsg').hide(); }
    };
    function _emailContentsDescription() {
        var textarea = $frame.crc_step.find('textarea');
        textarea.on('blur', function () {
            var $this = $(this),
                val = $this.val().trim();

            if (!val) {
                delete _stepsObj['email_contents_desc'];
            } else {
                _stepsObj['email_contents_desc'] = val;
            }

        })
    };
    function _checkBoxValidation() {

        var inputs = $frame.crc_step.find('input[type="checkbox"]');
        new html.Validator($frame.crc_step, {
            submit: $frame.crc_continue,
            inputs: 'input[type="checkbox"]',
            callback: function (inputs, form) {
                return form.find('input:checked').length
            },
            eventType: 'change'
        });

        inputs.on('change', function () {
            var $this = $(this),
                name = $this.prop('name');

            if ($this.is(':checked')) {
                _stepsObj[name] = true;
            } else {
                delete _stepsObj[name];
            }
        });
    };
    function _radioValidation(name) {
        var inputs = $frame.crc_step.find('input');
        if (_stepsObj[name]) $frame.crc_step.find('input[value="' + _stepsObj[name] + '"]').prop('checked', true);

        new html.Validator($frame.crc_step, {
            submit: name === 'want_emails' ? $frame.crc_finish : $frame.crc_continue,
            inputs: 'input[type="radio"]',
            callback: function (inputs, form) {
                return form.find('input:checked').length
            },
            eventType: 'change'
        });

        inputs.on('change', function () {
            var $this = $(this),
                val = $this.val();

            delete _stepsObj[name];
            _stepsObj[name] = val;
        })

    };
    function _loadCRCFormProfile() {
        ee.api.request('/account/LoadProfileFromCRCForm', {}, function (data) {
            ee.indiOff();
            if (!data.success) {
                _stepsObj.firstname = ee.data.account.firstname;
                _stepsObj.lastname = ee.data.account.lastname;
            } else {
                _stepsObj.firstname = data.firstname;
                _stepsObj.lastname = data.lastname;
            };
            _validationSuccess();
            _showWelcomeInfo(true);
        });
    };
    function _profileValidation() {
        var inputs = $frame.crc_step.find('input'),
            countryInput = $frame.crc_step.find('select[name="countryid"]');

        countryInput.val(_stepsObj.countryid);


        new html.Validator($frame.crc_step, {
            submit: $frame.crc_continue,
            inputs: [
                { name: 'firstname', validFunc: 'noempty' },
                { name: 'lastname', validFunc: 'noempty' },
                { name: 'address', validFunc: 'noempty' },
                { name: 'city', validFunc: 'noempty' },
                { name: 'countryid', validFunc: function () { return this.val() !== '0' }, eventType: 'change', msg: ee.t.invalidcountry }
            ]
        });

        countryInput.on('change', function () {
            var $this = $(this),
                val = $this.val();

            _stepsObj['countryid'] = val;
        });

        inputs.on('blur', function () {
            var $this = $(this),
                name = $(this).prop('name'),
                val = $this.val().trim();

            if (!val) {
                delete _stepsObj[name];
            } else {
                _stepsObj[name] = val;
            }
        });

    };
    function _step(val) {
        _changeProgressAnimation(val);

        if (val < 0) val = 0;

        var templateObj = $.extend({}, _stepsObj);

        if (val === 0) templateObj.country = ee.tools.CountryList();

        var step = html.get(_stepsArr[val], templateObj);
        $frame.crc_step.empty();
        step.appendTo($frame.crc_step);
        $frame.crc_continue.prop('data-step', val);

        if (val === 0) {
            $frame.crc_back.hide();
        } else {
            $frame.crc_back.show();
        }

        if (val + 1 === _stepsArr.length) {

            $frame.crc_continue.hide();

            var finishButton = $('<button id="ecrc_finish" class="btn btn_primary pull-right">Finish</button>');
            finishButton.on('click', function () {

                var form = JSON.stringify(_stepsObj);

                ee.api.request('/account/ValidateCRCForm', { form: form }, function (data) {
                    ee.indiOff();
                    if (!data.success) return html.modal.error(data);
                    $(window).off("unload.crc");
                    if (data.data === true) return _validationSuccess();
                    if (data.data === false) return _noValidateCRC();
                }, undefined, true)
            });
            $frame.crc_finish = finishButton;
            $frame.crc_navi.prepend(finishButton);
        } else {
            if (!$frame.crc_continue.is(':visible')) $frame.crc_continue.show();
            if ($('#ecrc_finish').length) {
                $('#ecrc_finish').remove();
                delete $frame.crc_finish;
            }
        }

        _initValidation(val);
    };
    function _nextStep() {
        var val = $frame.crc_continue.prop('data-step');
        _step(val + 1);
    };
    function _prevStep() {
        var val = $frame.crc_continue.prop('data-step');
        _step(val - 1);
    };
    var that = {
        init: function () {
            if (!ee.data.account.underreviewnote) return false;
            $frame = html.get('CRCframe', {});

            _overlay = new html.Frameoverlay($frame).on({
                beforeClose: _saveStorage,
                afterShow: function () {
                    $progressElem = $frame.crc_animated_background;
                    _generateSteps();
                    if (ee.data.account.crcstatus === 1) {
                        _loadCRCFormProfile();
                    } else {
                        $(window).off("unload.crc").on("unload.crc", function () {
                            _saveStorage();
                        });
                        _step(0);
                    }
                    _showWelcomeInfo();
                    $frame.crc_continue.off('click').on('click', function () {
                        _nextStep();
                    });
                    $frame.crc_back.off('click').on('click', function () {
                        _prevStep();
                    });
                }
            });
        },
        showAlert: function ($container) {
            // var CRCinfo = html.get("CRCAlertDanger", {}),
            //     alertContent;
            var email = ee.data.account.email;
            var CRCAlertDanger = html.get("CRCAlertDanger", { msg: ee.t.underreview1.replace(/{{account}}/g, email) });
            var clicks = 0;
            var modal = html.modal.create(CRCAlertDanger, {
                
                buttons: [
                    {
                        name: ee.t.underreviewbuttonname.replace(/{{buttonname}}/g, "NEXT"),
                        css: 'btn-underreview next',
                        callback: function () {
                            $(".underreviewfirstscreen").hide();
                            $(".underreviewsecondscreen").show();
                            
                            clicks += 1
                            
                            if(clicks == 2){
                                $(".underreviewsecondscreen").hide();
                                $(".underreviewthirdscreen").show();
                                $(".next").hide();
                                $(".fill").show(); 
                            }
                            }
                    },
                    {
                        name: ee.t.fillinreactivation,
                        css: 'btn-underreview fill',
                        callback: function(){
                            html.widget.CustomerResolutionCenter.init();
                            modal.close();
                        }
                    }
                ]
            });
            $("#emodalCancel").remove();
            $('.closeunderreviewmodalbutton').on('click', function(){
               modal.close();
               
            });
           
           $('.emodal').attr('style', 'max-width:372px; left:30%; top:10%; border-radius:0px; box-shadow:none;');


            try {
                alertContent = html.get('CRCAlert_' + ee.data.account.underreviewnote, { user: ee.data.account });
            } catch (e) {
                /*NOTE: Mayby we shoud add Bugsnag notify in that place */
                //console.log(e);
                alertContent = html.get('CRCAlert_Generic', { user: ee.data.account })
            }

            CRCinfo.crc_alert_content.append(alertContent);

            CRCinfo.on("click", ".crc_alert_close", function () {
                CRCinfo.remove();
                return false;
            });

            CRCinfo.on("click", '.crc_btn', function () {
                that.init();
            })

            $container.append(CRCinfo);
        },
    }
    root.CustomerResolutionCenter = that;
}(html.widget));