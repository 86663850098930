//register with ee
(function () {
    ee.editors["raweditor"] = (function () {

        /*--- inheriting methods from the core editor for the object That ---*/
        function RawEditor() { };
        RawEditor.prototype = Object.create(ee.editors.core);
        RawEditor.prototype.constructor = RawEditor;
        /*--- END ---*/

        var rawdelay, _autoSaveWidget, $screen, raweditor, previewBody, RawInit;
        var that = new RawEditor();

        const disabledButtonsUndoRedo = () => {
            if (raweditor.doc.historySize().undo > 0 ) {
                $('#editorUndo').removeClass('disabled')
            } else {
                $('#editorUndo').addClass('disabled')
            }

            if (raweditor.doc.historySize().redo > 0 ) {
                $('#editorRedo').removeClass('disabled')
            } else {
                $('#editorRedo').addClass('disabled')
            }
        };
        
        var defaultOptions = {
            autoSaveNewTemplateCallback: function (result) {
                
            },
            screenHandle: false,
            defaultSettings: true,
            defaultButtons: true,
            menuWindow: '',
            saveMethod: false
        };
        var template = {};
        var self = this;

        that.show = function ($container, opts) {
            ee.indiOn();
            $('div').find('#econtent_wrapper').addClass('template-content');
            $(window).on('hashchange', () => {
                if (!location.hash.includes('templates/edit')) {
                    $('div').find('#econtent_wrapper').removeClass('template-content');
                }
            })
            
            $.cachedScript(
                ee.tools.getLibPath() + "lib/codemirror.js?version=" + ee.libVersion.codeMirror,
                {
                    success: function () {
                        if (!$container) { $container = ee.frame.content; }

                        $container.empty();
                        _setupOptions(opts);

                        //Prepare data
                        if (!ee.data.selectedtemplate) { ee.data.selectedtemplate = _getNewEmptyTemplate(); }
                        template = ee.data.selectedtemplate;
                        if (template.templatetype < 0) { template.templatetype = Math.abs(template.templatetype); }

                        //Display
                        $screen = html.get("RawEditor", { scope: ee.data.account.scope, template: template, options: that.options, islanding: template.templatetype === 2 });

                        $screen.appendTo($container);
                        that.screen = $screen;
                        
                        $('#etemplateoptions').on('click', function (e) {
                            let target = e.target;
                            if(target && target.hasAttribute('data-toggle')) {
                                $('#etemplateoptions').removeAttr('data-toggle');
                            }
                            $('#etemplateoptions').closest('.iconsintemplatemenu').toggleClass('open');
                            
                        })


                        _initRawEditor();
                        that.raweditor = raweditor;
                        
                        _bindMenu();
                        _autoformat();
                        ////Autosave enable
                        if (ee.editor.autosave) {
                            _autoSaveWidget = new ee.editor.autosave($screen.autosave, ee.editor.options.autoSaveNewTemplateCallback);
                            _autoSaveWidget.start();
                        };
                        ee.indiOff();
                        ee.tools.validateEntityName(
                            {
                                screen: $screen,
                                inputname: '#ename',
                                submit: "#esave",
                                emptyname: ee.t.requiredtemplatename
                            }
                        )
                        const TemplateNameH4 = $(".template-name");
                        const TemplateNameText = $(".js-template-name")
                        const TemplateNameInput = $('#edefaultsettings').find('#ename');
                        const TemplateNamePencilIcon = $(".js-edit-template-name");
                        TemplateNamePencilIcon.on("click", () => {
                            TemplateNameH4.addClass("hidden");
                            TemplateNameInput.removeClass("hidden");
                            TemplateNamePencilIcon.addClass("clicked")
                            TemplateNameInput.focus();
                        });

                        TemplateNameInput.blur(() => {
                            if (!$(TemplateNameInput).hasClass('incorrect_input_value')) {
                                TemplateNameInput.addClass("hidden");
                                TemplateNameH4.removeClass("hidden");
                                if (template.name !== TemplateNameInput.val()) {
                                    TemplateNameText.text(TemplateNameInput.val());
                                    template.name = TemplateNameInput.val();
                                }
                               
                            }
                        });

                        const handler = document.querySelector('.resizeline');
                        const wrapper = handler.closest('.twocolumnsinrawhtml');
                        const firstColumn = wrapper.querySelector('.bothcolumns');
                        let isHandlerDragging = false;

                        $('#raweditorcolumns').on('mousedown', (e) => {
                            if (e.target === handler) {
                                isHandlerDragging = true;
                                $('#erawpreview').addClass('iframeevents');
                            }
                        });
                        
                        $('#raweditorcolumns').on('mousemove', (e) => {
                            if (!isHandlerDragging) {
                                return;
                            }
                            const containerOffsetLeft = 259;

                            let pointerRelativeXpos = e.clientX - containerOffsetLeft;
                            
                            const firstColumnminWidth = 0;

                            firstColumn.style.width = (Math.max(firstColumnminWidth, pointerRelativeXpos )) + 'px';
                            firstColumn.style.flexGrow = 0;
                        });

                        $('#raweditorcolumns').on('mouseup', (e) => {
                            isHandlerDragging = false;
                            if ($('#erawpreview').hasClass('iframeevents')) {
                                $('#erawpreview').removeClass('iframeevents');
                            }
                            
                        });
                    }
                }
            );
        };
        let debouncedSave;
        var save = function (callback, silent) {
            if (_autoSaveWidget && _autoSaveWidget.stop) {
                _autoSaveWidget.stop();
                _autoSaveWidget = null;
            }
            if (!callback) callback = _saveCallback;
            that._collectDefaultOptions();
            ee.data.selectedtemplate.bodyhtml = that._XSSProtectHtml(getTemplateContent().bodyhtml);
            let howManyChanges = raweditor.historySize().undo;
            let isModalOpened = false;
            const templateSaveDebounced = () => {
                if (howManyChanges > 1) {
                    if (ee.data.selectedtemplate.bodyhtml.indexOf('{{') != -1) {
                        if (!isModalOpened) {
                            isModalOpened = true;
                            html.modal.confirm(ee.t.doublebrackets, ee.t.warning, () => {
                                return ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $("#erawpreview"))
                                .then(function(result) {callback(result)});
                            });
                            return;
                        }
                    } else {
                        if (debouncedSave) {
                            debouncedSave.cancel();
                        } 

                        debouncedSave = _.debounce (() => {
                            ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $("#erawpreview"))
                            .then(function(result) {
                                callback(result);
                                $('.frameoverlay-next').removeClass('disabled');
                                ee.indiOff();
                            });
                        }, 2000)
                        debouncedSave();
                    }
                }
            }
            const TemplateNameInput = $('#edefaultsettings').find('#ename');
            
            TemplateNameInput.on('change', () => {
                howManyChanges = raweditor.historySize().undo + 1;
                templateSaveDebounced();
            })
            raweditor.on('change', () => {
                howManyChanges = raweditor.historySize().undo;
                templateSaveDebounced();
            })
        };

        var reload = function () {
            destroy();
            raweditor.setValue(ee.data.selectedtemplate.bodyhtml);
            _autoformat();
            _updatePreview();
            if (!_autoSaveWidget) {
                _autoSaveWidget = new ee.editor.autosave($screen.autosave, ee.editor.options.autoSaveNewTemplateCallback);
                _autoSaveWidget.start();
            } else {
                _autoSaveWidget.start();
            }
        };

        var destroy = function () {
            if (_autoSaveWidget && _autoSaveWidget.stop) {
                _autoSaveWidget.stop();
                _autoSaveWidget = null;
            }
        };
        /*PRIVATE METHODS*/
        var _getNewEmptyTemplate = function () {
            var template = $.extend(true, {}, that._defaultTemplate);
            template.templatetype = 0;
            template.name = ee.t.newtemplate + ':'+ ee.api.formatDate(new Date(),'yy-mm-dd: hh:ii:ss');
            template.bodyhtml = html.template("BlankTemplate");
            

            return template;
        };

        /*Bind events on  right menu buttons*/
        var _bindMenu = function () {
            //Bind Floating Menu
            // $screen.floatmenu.elasticfloatingMenu(0, that.options.menuWindow, $screen);
            if (that.options.defaultButtons || location.hash.includes('#/campaigncreator')) {
                if ($('#emoible')) {
                    $('#emoible').click( () => {
                        $('#editorUndo').addClass('disabled');
                        $('#editorRedo').addClass('disabled');
                        $('#emoible').hide();
                        $('#edesktop').show();
                        $('.dcontainer').css('overflow', 'unset');
                        $('#emobilepreview').css('top', '11.5rem');
                    });
                    $('#edesktop').click(() => {
                        disabledButtonsUndoRedo();
                        $('#emoible').show();
                        $('.dcontainer').css('overflow', 'auto');
                        $('#edesktop').hide();
                    });
                }

                //Undo&Redo
                disabledButtonsUndoRedo();
                $('#editorUndo').on('click', function() {
                    raweditor.doc.undo();
                    disabledButtonsUndoRedo();
                });
                $('#editorRedo').on('click', function() {
                    raweditor.doc.redo();
                    disabledButtonsUndoRedo();
                });


                //Scope
                if ($screen.scope) {
                    $screen.scope.change(function () {
                        ee.data.selectedtemplate.isdirty = true;
                        that._dirty($screen, function () { save(); });
                    });
                }
                $('#eeditbodytext').click(function () {
                    that._showBodyText();
                });
                $('#eeditbodyamp').click(function () {
                    that._showBodyAmp();
                });
                //Show send test dialog box
                $('#esendtest').click(function () {
                    that._showSendTest();
                });
                //bind confirm to navigate;
                html.frame.bindConfirm(false, function () { return !ee.data.selectedtemplate.isdirty })
            }
            if (ee.data.selectedtemplate.templatetype === 2) {
                $('#publishLandingPage').click(() => {
                    ee.editors.core._publishLandingPage();

                    let domain = (ee.data.account.privatebrandingurl) ? `${ee.getProtocol()}//${ee.data.account.privatebrandingurl}` : ee.api.path;
                    
                    let linkToPage = ee.data.selectedtemplate.link;
                    linkToPage = linkToPage.split('/page')[1];
                    let linkToLandingPage = `${domain}/page${linkToPage}`;
                    
                    
                    $('#templateLink').val(linkToLandingPage);
                    
                    
                    $('#copyLinkToLandingpage').click ( () => {
                        $('#templateLink').val(linkToLandingPage).select();
                        document.execCommand("copy");
                        html.widget.Toast.info(ee.t.copiedlink, "", {fadeIn: 250, faeOut: 250, timeOut: 1000});
                    })
                
                });

                $('#downloadLandingPage').click ( () => {
                    let templateid = ee.data.selectedtemplate.templateid;
                    let query = {templateid: templateid}
                    
                    return EE_API.Template.DownloadTemplate(query)
                    .then ((data) => {
                        let blob = new Blob([data]);

                        let templatename = ee.data.selectedtemplate.name;
                        let fileName = `${templatename}.html`;
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    })
                    .catch((err) => {
                        console.log({err})
                    })
                });
                $('#previewLandingPage').click ( () => {
                    ee.views.templates.previewContent(ee.data.selectedtemplate.templateid,ee.data.selectedtemplate.ispublic)

                });
            }
            $('#editdefault').on('click', function (e) {
                const templateid = ee.data.selectedtemplate.templateid;
                const template = ee.data.selectedtemplate;
                const win = html.get("TemplateDefaultOptions", { template: template, accountscope: ee.data.account.scope, subaccount: ee.data.account.issub, islanding: template.templatetype === 2  });

                ee.logEvent('Template editor - show default options');

                html.widget.SelectFromEmail(win.fromemail, template.fromemail, true).then(function () {
                    var modal = html.modal.create(win, {
                        title: ee.t.templatedetail,
                        size: "emodal-md",
                        buttons: [{
                            name: ee.t.save,
                            css: "btn_lg btn_primary",
                            title: ee.t.ttsavedefaults,
                            callback: function () {
                                if (template.templatetype != 2) {
                                    template.name = win.find('input[name=name]').val();
                                    template.fromemail = win.find('input[name=fromemail]').val();
                                    template.fromname = win.fromname.val();
                                    template.subject = win.subject.val();
                                }
                                

                                if (win.scope) template.templatescope = win.scope.is(':checked') === true ? 1 : 0;
                                if (win.isdefault) template.isdefault = win.isdefault.is(':checked');

                                ee.indiOn();
                                if (ee.data.selectedtemplate.templateid == "0" || !ee.data.selectedtemplate.templateid) {
                                    return EE_API.Template.Save(template)
                                    .then(function(resp) {
                                        ee.data.selectedtemplate.templateid = resp;
                                        if(template.templatetype != 2) {
                                            $('#ename').val(template.name);
                                            $('.js-template-fromemail').val(template.fromemail);
                                            $('.js-template-fromname').val(template.fromname);
                                            $('.js-template-subject').val(template.subject);
                                            $(".js-template-name").html(template.name);
                                            $('.js_ename-value').val(template.name);
                                        }
                                        ee.indiOff();
                                        modal.close();
                                    })
                                } else {
                                    if(template.templatetype != 2) {
                                        $('#ename').val(template.name);
                                        $('.js-template-fromemail').val(template.fromemail);
                                        $('.js-template-fromname').val(template.fromname);
                                        $('.js-template-subject').val(template.subject);
                                        $(".js-template-name").html(template.name);
                                        $('.js_ename-value').val(template.name);
                                    }
                                    return EE_API.Template.Update(template)
                                    .then(function(){
                                        ee.indiOff();
                                        modal.close();
                                    });
                                }
                            }
                        }]
                    });
                    ee.tools.validateEntityName(
                        {
                            screen: win,
                            inputname: 'name',
                            emptyname: ee.t.requiredtemplatename,
                            submit: modal.$footer.find('.btn_primary')
                        }
                    )
                });
                
            });
            // if (that.options.defaultSettings) {
            //     //Default Edit
            //     html.widget.SelectFromEmail($screen.fromemail, ee.data.selectedtemplate.fromemail).then(function () {
            //         $screen.defaultsettings.find('input').keydown(function () {
            //             ee.data.selectedtemplate.isdirty = true;
            //             that._dirty($screen, function () { save(); });
            //         });
            //     });
            //     html.EmojiInput($screen.subject);
            // }
            //Mobile Previow
            that._mobileView($screen);
            //Filemanager event
            $('#efilemanager').on("click", function () {
                html.widget.FileManager.init(function (url, listname) {
                    if (listname == "imagelist") {
                        raweditor.replaceSelection('<img src="' + url + '" alt="#"/>');
                    } else {
                        raweditor.replaceSelection(url);
                    }
                });
            });
            //Convert css from <style> tag to inline styles
            const inlineStylesFunction = () => {
                raweditor.setValue(ee.tools.doInline(raweditor.getValue()));
                _autoformat();
                _updatePreview();
             
                if (!_autoSaveWidget) {
                    _autoSaveWidget = new ee.editor.autosave($screen.autosave, ee.editor.options.autoSaveNewTemplateCallback);
                    _autoSaveWidget.start();
                } else {
                    _autoSaveWidget.start();
                }
            }
            $('#einlinestyleconvert').on("click", function() {
                inlineStylesFunction();
            });
            $(()=>{
                let executed = false;
                raweditor.on('change', () => {
                    disabledButtonsUndoRedo();
                    const codeInRaw = raweditor.doc.getValue();
                    $('#autoformatDisplay').show();
                    if (codeInRaw.includes('<style') && !executed && ee.data.selectedtemplate.templatetype!=2) {
                        $('#inlineStylesDisplay').show();
                        executed = true;
                        const template = ee.data.selectedtemplate;
                        const win = html.get("InlineStylesInRaw", { template: template });
                        const modal = html.modal.create(win, {
                        title: ee.t.movestylesinline,
                        size: "emodal-md",
                        buttons: [{
                            name: ee.t.movestylesinline,
                            css: "btn_lg btn_primary",
                            callback: () => {
                                inlineStylesFunction();
                                modal.close();
                            }
                        }]
                    });
                    } else if (!codeInRaw.includes('<style')) {
                        $('#inlineStylesDisplay').hide();
                    } else if (codeInRaw.includes('<style') && executed) {
                        $('#inlineStylesDisplay').show();
                    }
                })
                
                $('#raweditor').closest('.container').addClass('templatecontainer');
                $(window).one('hashchange', () => {
                    $('.container').removeClass('templatecontainer');
                })
            })
            that._templateTagsFunction();
            //MergeFields Popup
            $('#emergefields').off().on("click", function () {
                that._showMergeFields(function (mergetag) {
                    raweditor.replaceSelection(mergetag);
                });
            });
            //AutoFormat trigger event
            $('#eautoformat').on("click", _autoformat);
        };

        var _saveCallback = function (result) {
            html.reloadTooltip($screen);
            ee.editor._disableDirty($screen);
            if (result.data) {
                ee.changeHashQuietly("#/templates/edit/" + result.data);
                return;
            }
            RawInit = true;
            if (ee.data.selectedtemplate.bodyhtml) {
                raweditor.setValue(ee.data.selectedtemplate.bodyhtml);
            }
            _autoSaveWidget.start();
        };

        var _setupOptions = function (opts) {
            //Reset internal statutes
            RawInit = true,  raweditor = false, previewBody = false, template = {}, $screen = false, rawdelay = false;
            that.options = $.extend(true, {}, defaultOptions, opts);
            if (!that.options.menuWindow) {
                that.options.menuWindow = ee.frame.content_wrapper
            }
        }

        /*Initialization plugin: codemirror */
        var _initRawEditor = function () {

            /* INIT CODE MIRROR PLUGIN */
            var $rawCode = $(that.screen[0]).find("#erawcode");
            
            if (!template.dateadded) {
                $('.frameoverlay-next').addClass('disabled');
            } else {
                $('.frameoverlay-next').removeClass('disabled');
            }
            var rawTemplate = (template) ? template : _getNewEmptyTemplate();

            $rawCode.val(rawTemplate.bodyhtml);

            raweditor = new CodeMirror.fromTextArea($rawCode[0], {
                mode: 'text/html',
                tabMode: 'indent',
                autoCloseTags: true,
                lineNumbers: true,
                lineWrapping: true,
                extraKeys: { 
                    "Ctrl-Q": function (cm) { 
                        cm.foldCode(cm.getCursor()); 
                    }, 
                    "Ctrl-S": function(cm) {
                        if (!$('#ename').hasClass('incorrect_input_value')) {
                            cm.save();
                            $('#templateChanges').text(ee.t.saving);
                            clearTimeout(rawdelay);
                            clearTimeout(timeout);
                            rawdelay = setTimeout(_updatePreview, 300);
                            template.bodyhtml = raweditor.getValue();
                            if (template.templateid) {
                                timeout = setTimeout(() => {
                                EE_API.Template.Update(template)
                                .then( () => {
                                    $('#templateChanges').text(ee.t.allchangessaved);
                                    template.isdirty = false;
                                });
                            }, 1000);
                        
                            }
                        }
                    }
                    },
                foldGutter: true,
                gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            });

            raweditor.setSize("100%", ((window.screen.availHeight - 316 ) + "px"));
            let timeout = null;
            rawdelay = setTimeout(_updatePreview, 300);
            const updateTemplate = () => {
                $('#templateChanges').text(ee.t.saving);
                clearTimeout(rawdelay);
                clearTimeout(timeout);
                rawdelay = setTimeout(_updatePreview, 300);
                raweditor.save();
                template.bodyhtml = raweditor.getValue();
                if (template.templateid){
                    timeout = setTimeout(() => {
                        ee.views.templates.saveWithScreenshot(ee.data.selectedtemplate, $("#erawpreview"))
                        .then((result) => {
                            callback(result)
                            $('#templateChanges').text(ee.t.allchangessaved);
                            template.isdirty = false;
                        });
                        timeout = null;
                    }, 1000);
                }
            }

            const TemplateNameInput = $('#edefaultsettings').find('#ename');
            let historySize = raweditor.historySize().undo;
            TemplateNameInput.on('change', () => {
                historySize = raweditor.historySize().undo + 1;
                if (!$('#ename').hasClass('incorrect_input_value') && (historySize > 1)) {
                    updateTemplate();
                }
            })
            raweditor.on("changes", () => {
                if (!$('#ename').hasClass('incorrect_input_value') && (raweditor.historySize().undo > 1)) {
                    updateTemplate();
                }
                
            });

            if (ee.tools.isNullOrEmpty(template.bodyhtml)) { ee.indiOff(); }

            $screen.on("keydown", function (e) {
                if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                    e.preventDefault();
                    if ($screen.savebutton) {
                        $screen.savebutton.trigger("click");
                    }
                }
            })
            const channelTemplate = ee.data.selectedChannelTemplate;
            let $templateRawEditorInCampaings = html.get("OnlyForRawEditorInCampaigns", {channel: channelTemplate, template: template} );
            $('#hiddenReplyToOptions').html($templateRawEditorInCampaings);
        };

        

        //Update preview
        var _updatePreview = function () {
            if (!previewBody) {
                previewBody = that.screen.rawpreview[0].contentWindow.document;
                _updatePreview();
                return;
            }
            if (previewBody) {
                $screen.rawpreview.off().on('load', function () {
                    $('#erawpreview').height(window.screen.availHeight - 316 );
                    //Disable link event in preview
                    $(previewBody).find("a").off('click').click(function (event) {
                        return false;
                    });
                });
                previewBody.open();
                previewBody.write(raweditor.getValue());
                previewBody.close();
                if (RawInit === true) {
                    ee.indiOff();
                    RawInit = false;
                } else {
                    ee.data.selectedtemplate.isdirty = true;
                }

                if (ee.data.selectedtemplate.isdirty) {
                    that._dirty($screen, function () { save(); });
                }
            }
        };
        /*Auto Formar Editorscreen*/
        var _autoformat = function () {
            raweditor.autoFormatRange({ line: 0, ch: 0 }, { line:  raweditor.lineCount(), ch:  raweditor.getTextArea().value.length });
            raweditor.setSelection({ line: 0, ch: 0 }, { line: 0, ch: 0 });
        };

        var getTemplateContent = function () {
            return { bodyhtml:  raweditor.getValue(), css: "" };
        }

        that.save = save;
        that.reload = reload;
        that.getNewEmptyTemplate = _getNewEmptyTemplate;
        that.getTemplateContent = getTemplateContent;
        that.autoformat = _autoformat;
        that.updatePreview = _updatePreview;
        that.destroy = destroy;
        that.options = {};

        return that;
    })();
}());
