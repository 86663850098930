ee.views["surveys"] = (function ViewSurveys() {
    var that = {}, $screen, pagination, _frameoverlay, _statusEnum_ = ['Expired', 'Active'], _surveysList, _paginationResponse
    var showSurveysList = function () {
        $screen = html.get("Surveys", {});
        $screen.appendTo(ee.frame.content);
        _renderSurveyList();

        html.bindSearch($screen.searchsurvey, function () {
            var searchSTR = $screen.searchinputsurvey.val();
            if (searchSTR === '') { ee.t.dynamicnosurveys = ee.t.nosurveys; } else { ee.t.dynamicnosurveys = ee.t.nosearchresults; }
            pagination.update(_searchSurvey());
        });

        $screen.refresh.click(function () {
            _surveysList.isLoaded = false;
            _renderSurveyList();
        });

        html.addDropdownFocus($screen.survey_table);
        if (ee.session.can.ModifySurveys) {
            $screen.createsurvey.click(function () {
                ee.changeHashQuietly("#/surveys/create/");
                overlayEditsurvey();
            });
        }
        $screen.paginationresult.on('click', '.action-responsessummary', function () {
            var publicsurveyid = $(this).closest('ul').data('publicsurveyid');
            ee.changeHashQuietly("#/surveys/summary/" + publicsurveyid);
            ee.indiOn();
            $.when(_surveysList.get(publicsurveyid).LoadSummary()).then(overlayResultsSummary).always(ee.indiOff);
        });
        $screen.paginationresult.on('click', '.action-responsesoverview', function () {
            var publicsurveyid = $(this).closest('ul').data('publicsurveyid');
            ee.changeHashQuietly("#/surveys/responses/" + publicsurveyid);
            ee.indiOn();
            $.when(_surveysList.get(publicsurveyid).LoadResponses())
            .then(overlayResponses)
            .catch(err => {console.log(err)})
            .always(ee.indiOff);
        });
        $screen.paginationresult.on('click', '.action-surveypreview', function () {
            _preview($(this).closest('ul').data('publicsurveyid'));
        });
        $screen.paginationresult.on('click', '.action-editsurvey', function () {
            var publicsurveyid = $(this).data('publicsurveyid');
            ee.changeHashQuietly("#/surveys/edit/" + publicsurveyid);
            ee.indiOn();
            $.when(_surveysList.get(publicsurveyid).Load()).then(overlayEditsurvey).always(ee.indiOff);
        });
        $screen.paginationresult.on('click', '.action-sharelink', function () {
            var $that = $(this), survey = _surveysList.get($that.closest('ul').data('publicsurveyid')),
                domains = dataModel.create('Domains');

            domains.require().then(function () {
                var dialog = html.get("ShareLink", { link: survey.GetShareLink(), domains: ee.data.domains.getAll() });
                if (dialog.predomain.find('option').length === 1) {
                    dialog.surveywhitlabel.hide();
                } else {
                    dialog.predomain.change(function () {
                        var domain = dialog.predomain.val().split(' ')[0];
                        dialog.sharelinkcontainer.html(survey.GetShareLink(domain));
                    });
                }
                dialog.predomain.trigger("change");
                html.modal.create(dialog, { title: ee.t.sharelink, maxToScreenHeight: true, cancel: { title: ee.t.close } });
                if (html.clickToCopy.test()) {
                    html.clickToCopy.init(dialog, true);
                }
            });
        });
        $screen.paginationresult.on('click', '.action-surveyexportresults', function () {
            _surveyExportResults($(this).closest('ul').data('publicsurveyid'));
        });
        $screen.paginationresult.on('click', '.action-deletesurvey', function () {
            var survey = _surveysList.get($(this).closest('ul').data('publicsurveyid'));
            html.modal.confirm(ee.t.deletesurveymsg, ee.t.removesurvey, function () {
                survey.Delete().then(function () {
                    _surveysList.remove('publicsurveyid', survey.publicsurveyid);
                    survey = {};
                    pagination.updateData(_searchSurvey());
                });
            }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_lg btn_destructive btn-danger" });
        });
    },
        overlayEditsurvey = function (survey) {
            var $screenEditor = that.editor.init(survey);
            _frameoverlay = new html.Frameoverlay($screenEditor).on({
                'beforeClose': function () {
                    if (that.editor.isDirty() === true) {
                        html.modal.confirm(ee.t.exiwithoutsaving, ee.t.info, function () {
                            _frameoverlay.off('beforeClose').close();
                            _frameoverlay = false;
                        }, false, { confirmTitle: ee.t.exit });
                        return false;
                    }
                },
                'afterClose': _closeOverlay,
                'afterShow': function () {
                    that.editor.initSortable();
                }
            });
        },
        overlayResultsSummary = function (survey) {
            var $summary = html.get("SurveyResultsSummary", survey);
            if (!_frameoverlay) {
                _frameoverlay = new html.Frameoverlay($summary).on({ 'afterClose': _closeOverlay });
            } else {
                _frameoverlay.changeContent($summary);
            }

            //Refresh main Screen
            $summary.refreshoverlay.click(function () {
                survey.summaryloaded = survey.contentloaded = false;
                overlayResultsSummary(survey.publicsurveyid);
            });
            //Print
            $summary.print.click(function () {
                window.print();
            });

            //Export
            $summary.surveysummaryexport.click(function () {
                _surveyExportResults($(this).data('publicsurveyid'));
            });
            //Survey preview
            $summary.prviewsurvey.on("click", function () {
                _preview($(this).data('publicsurveyid'));
            });
            $summary.find(".answer-list-text").each(function () {
                var $textanswersarea = $(this), $answersList = $textanswersarea.find("label");
                var maxAnswers = 6;
                if ($answersList.length > maxAnswers) {
                    $answersList.slice(0, maxAnswers).wrap("<div class='firstanswers'></div>")
                    $answersList.slice(maxAnswers, $answersList.length).wrapAll("<div class='moreanswers'></div>");;
                    var moreanswers = $textanswersarea.find('.moreanswers');
                    var buttonMore = $('<div class="btn btn-default btn-xs">' + ee.t.morewitharrow + '</div>');
                    buttonMore.click(function () {
                        moreanswers.toggle(300, "linear", function () {
                            if (moreanswers.css("display") == "none") {
                                buttonMore.html(ee.t.morewitharrow);
                            } else {
                                buttonMore.html(ee.t.lesswitharrow);
                            }
                        });
                    });
                    buttonMore.appendTo($textanswersarea);
                }

            });
        },
        overlayResponses = function (survey) {
            var $resultslist;
            $resultslist = html.get("SurveyResponse", survey);
            _frameoverlay = new html.Frameoverlay($resultslist).on({ 'afterClose': _closeOverlay });
            _paginationResponse = new html.Pagination(survey.resultslist.getAll(), {
                template: 'SurveyResponseList',
                resultcontainer: $resultslist.paginationresult,
                footercontainer: '.paginationlist',
                paginationinfo: true,
                firstlast: false
            });
            html.addDropdownFocus($resultslist.find('.table-responsive'));
            //Survey preview
            $resultslist.prviewsurvey.on("click", function () {
                _preview($(this).data('publicsurveyid'));
            });
            //Refresh main Screen
            $resultslist.refreshoverlay.click(function () {
                ee.indiOn();
                $.when(survey.LoadResponses()).then(function () {
                    _paginationResponse.updateData(survey.resultslist.getAll());
                }).always(ee.indiOff);
            });
            //Preview answer
            $resultslist.paginationresult.on('click', ".action-showanswer", function () {
                _previewSurveyResult($(this).closest('.dropdown-menu').attr("data-resultid"), survey);
            });
            $resultslist.paginationresult.on('click', ".action-deleteanswer", function () {
                deleteResults(survey, [$(this).closest('.dropdown-menu').attr("data-resultid")]);
            });
            //Delete answer/answers
            $resultslist.paginationresult.on('click', '.echeckbox', function () {
                var count = $resultslist.paginationresult.find('.echeckbox:checked').length;
                if (count > 0) {
                    if ($resultslist.cboxnavi.hasClass('fadein') === false) {
                        $resultslist.cboxnavi.addClass('fadein').fadeIn();
                    }
                } else {
                    $resultslist.cboxnavi.removeClass('fadein').fadeOut();
                }
                $resultslist.selectedcontactcount.html(count);
            });
            $resultslist.deletes.click(function () {
                deleteResults(survey, $resultslist.paginationresult.find(".echeckbox:checked").map(function () { return this.value; }).get());
            });
            $resultslist.cboxdeselect.click(function () {
                $resultslist.paginationresult.find('.echeckbox:checked').prop('checked', false);
                $resultslist.cboxnavi.removeClass('fadein').fadeOut();
            });
        },
        deleteResults = function (survey, resultsIdToDelete) {
            if (resultsIdToDelete.length <= 0 || survey.resultslist.getLength() === 0) { return; }
            html.modal.confirm(ee.t.deletesurveyresults, ee.t.removeresults, function (response) {
                ee.indiOn();
                $.when(survey.RemoveResponses(resultsIdToDelete)).then(function () {
                    _paginationResponse.updateData(survey.resultslist.getAll());
                    _frameoverlay.$content.cboxdeselect.click();
                }).always(ee.indiOff);
            }, false, { confirmTitle: ee.t.remove, confirmCSS: "btn_lg btn_destructive btn-danger" });
        },
        /*-- HELPERS --*/
        _closeOverlay = function () {
            _frameoverlay = false;
            html.modal.closeAll();
            $('body').find('.hasDatepicker').datepicker('destroy');
            ($screen) ? _renderSurveyList() : showSurveysList();
            ee.changeHashQuietly("#/surveys");
        },
        _renderSurveyList = function () {
            //Load Survey List
            if (_surveysList.isLoaded ===false) {
                $screen.paginationresult.html(html.getraw("LoadingPaginationResult", { colspan: 5 }));
            }
            _surveysList.require().then(function () {
                ee.t.dynamicnosurveys = ($screen.searchinputsurvey.val() === '') ? ee.t.nosurveys : ee.t.nosearchresults;
                pagination = new html.Pagination(_searchSurvey(), {
                    template: 'SurveysTable',
                    resultcontainer: $screen.paginationresult,
                    footercontainer: '.paginationlist',
                    perpage: 25,
                    paginationinfo: false,
                    firstlast: false
                });
            });
        },
        _searchSurvey = function () {
            return ee.data.surveys.filter(["name", "publicsurveyid"], $screen.searchinputsurvey.val()).getAll();
        },
        _surveyExportResults = function (publicsurveyid) {
            var survey = _surveysList.get(publicsurveyid),
                exportdialog = html.get("ExportDialog");
            html.modal.create(exportdialog, {
                title: ee.t.exportdata, size: "emodal-sm", buttons: [{
                    name: ee.t.exportdata,
                    css: "btn_primary btn_lg",
                    callback: function () {
                        var query = { publicsurveyid: survey.publicsurveyid };
                        query.fileformat = exportdialog.fileformat.val();
                        query.compressionformat = "None";
                        if (ee.data.account.isMac === false && exportdialog.compressed.is(':checked')) {
                            query.compressionformat = "Zip";
                        }
                        query.filename = (exportdialog.filename.val()).replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_');
                        ee.api.exportData(query, "/survey/export", function () {
                            if (_frameoverlay) {
                                _frameoverlay.off();
                            }
                            ee.goTo('#/exports');
                        });
                    }
                }]
            });
            exportdialog.filename.val(survey.name.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_'));
            if (ee.data.account.isMac === true) {
                exportdialog.compressedlist.hide();
                exportdialog.compressed.hide();
            }
        },
        _preview = function (publicsurveyid) {
            ee.indiOn();
            var survey = ee.data.surveys.get(publicsurveyid),
                iframe = html.get("EmptyIframe", {}),
                modal = html.modal.create(iframe, { title: ee.t.preview, size: "emodal-lg", alignToScreenHeight: true, cancel: { title: ee.t.close } });

            iframe[0].src = survey.GetShareLink();
            modal.$container.find(".emodal-content").css({ padding: 0 });

            iframe.on('load', function () {
                ee.indiOff();
            });
        },
        _previewSurveyResult = function (surveyresultid, survey) {
            var resultdata = survey.resultslist.get("surveyresultid", surveyresultid),
                modal = html.modal.create('', {
                    title: "",
                    alignToScreenHeight: true,
                    buttons: [{
                        name: ee.t.next,
                        css: "btn-default btn_lg btn_tertiary",
                        callback: function () {
                            var next = resultdata.index + 1;
                            if (next > survey.resultslist.data.length - 1) {
                                next = 0;
                            }
                            if (survey.resultslist.data[next]) {
                                resultdata = survey.resultslist.data[next];
                                displayResponse();
                            }
                        }
                    }, {
                        name: ee.t.previous,
                        css: "btn-default btn_lg btn_tertiary",
                        callback: function () {
                            var prev = resultdata.index - 1;
                            if (prev < 0) {
                                prev = survey.resultslist.data.length - 1;
                            }
                            if (survey.resultslist.data[prev]) {
                                resultdata = survey.resultslist.data[prev];
                                displayResponse();
                            }
                        }
                    },]
                }),
                displayResponse = function () {
                    var $response = html.get("SurveyResultAnswers", resultdata);
                    var No = 1;
                    survey.surveysteps.each(function () {
                        var step = this, answers, thisAnswer;
                        if (step.surveysteptype === 2) {
                            var answersArray = $.grep(resultdata.surveyresultanswers, function (n) { return (n.surveystepid == step.surveystepid) ? true : false; }), answerString = "";
                            if (step.questiontype < 4 && answersArray.length > 0) {
                                for (var j = 0, m = answersArray.length; j < m; j++) {
                                    answers = new Collection.Data(step.surveystepanswers);
                                    thisAnswer = answers.get("surveystepanswerid", answersArray[j].surveystepanswerid);
                                    answerString += (thisAnswer !== null) ? ", " + thisAnswer.content : "";
                                }
                            } else {
                                answerString = (!answersArray[0]) ? " " + ee.t.noanswer : " " + answersArray[0].content;
                            }
                            var $li = html.get("PreviewAnswerContainer", { content: step.content, answer: answerString.substring(1), No: No++ });
                            $li.appendTo($response.answerslist);
                        }
                    });
                    modal.setContent(("Answer No. " + resultdata.no), $response);
                };
            displayResponse();
            return;
        },
        /*-- PUBLIC METHODS --*/
        that = {
            show: function () {
                ee.frame.content.empty();
                if (!ee.session.can.ViewSurveys) {
                    return ee.goTo("#/dashboard");
                }
                _surveysList = dataModel.create("SurveysList");
                showSurveysList();
                switch (ee.Router.action()) {
                    case "create":
                        overlayEditsurvey();
                        break;
                    case "edit":
                        ee.indiOn();
                        $.when(dataModel.create('Survey', ee.Router.parameter('node0')).Load()).then(overlayEditsurvey).always(ee.indiOff);
                        break;
                    case "summary":
                        $.when(dataModel.create('Survey', ee.Router.parameter('node0')).LoadSummary()).then(overlayResultsSummary);
                        break;
                    case "responses":
                        $.when(dataModel.create('Survey', ee.Router.parameter('node0')).LoadResponses()).then(overlayResponses);
                        break;
                }
            },
        };
    return that;
})();
