/*-- SURVEY EDITOR --*/
ee.views["surveys"].editor = (function SurveyEditor() {
    var that = {}, $screen, _survey_, dirty,
        _NPScount = 0,
        loadedEditor = false, activeEditor = false, _inprogress = false,
        enumquestiontype = ["", "RadioButtons", "DropdownMenu", "Checkboxes", "Textbox", "LongAnswer", "Date", "NetPromoterScore"],
        enumstep = ["", "PageBreak", "Question", "TextMedia", "ConfirmationPage", "ExpiredPage"],

        formCorrect,//Flag for correct survey form
        /*--- Private Methods ---*/
        _toogleEditMode = function (disabled) {
            $screen.surveyexpirydate.prop('disabled', disabled);
            $screen.find('.survey-step input').prop('disabled', disabled);

            if (disabled === true) {
                $screen.find('.survey-page:not(:first)').addClass('disabled');
                $screen.find('.survey-disabled-overlay').removeClass('hidden');
                $screen.find('.survey-page-options, .survey-step .caret, .survey-step-options button, .survey-question-remove, .survey-question-move, .survey-step.empty').addClass('hidden');
            } else {
                $screen.find('.survey-page:not(:first)').removeClass('disabled');
                $screen.find('.survey-disabled-overlay').addClass('hidden');
                $screen.find('.survey-page-options, .survey-step .caret, .survey-step-options button, .survey-question-remove, .survey-question-move, .survey-step.empty').removeClass('hidden');
            }
        },
        _createStep = function () {
            var $step = html.get("StepSurvey")
            $step.typeInput = $step.find('.dropdown-select-btn:first');
            $step.dropdown = $step.find('.survey-step-type:first');
            $step.stepContent = $step.find('.survey-step-content:first');
            $step.requirequestion = $step.find('.requirequestion');
            return $step;
        },
        _createQuestion = function (questiontype, content) {
            var questionTemplate = 'QuestionText';
            if (questiontype === 7) {
                _NPScount += 1;
                questionTemplate = 'QuestionNetPromoterScore';
            }
            var $question = html.get(questionTemplate, { content: content });
            $question.type = questiontype;
            $question.addanswer = $question.find('.action-add-answers:first');
            $question.questionAnswers = $question.find('.question-answers:first');
            return $question;
        },
        _createAnswer = function ($question, surveyanswerid, content) {
            switch ($question.type) {
                case 4:
                    $question.addanswer.hide();
                    $question.questionAnswers.html(html.get("QuestionTextArea"));
                    break;
                case 5:
                    $question.addanswer.hide();
                    $question.questionAnswers.html(html.get("QuestionSingleInput"));
                    break;
                case 6:
                    $question.addanswer.hide();
                    $question.questionAnswers.html(html.get("QuestionSingleInput", { css: "edatapicker", placeholder: "mm/dd/yyyy" }));
                    break;
                case 7:
                    $question.addanswer.hide();
                    $question.questionAnswers.html(html.get("QuestionNetPromoterScoreInput"));
                    break;
                default:
                    $question.addanswer.show();
                    var $answer = html.get("QuestionInput", { surveyanswerid: surveyanswerid, content: content });
                    $question.questionAnswers.append($answer);
                    _cheackOrderNumber($question.questionAnswers, '.question-answer-order');
            }

        },
        _addPage = function (surveystepid) {
            var $surveyPage = html.get("PageSurvey", { surveystepid: surveystepid });
            $surveyPage.appendTo($screen.surveysteps);
            //Recalculate page numbers
            _cheackPagesNumber();

        },
        _addStep = function () {
            //Create jQuery Object for new Step
            var $page = $screen.surveysteps.find(".survey-page:last"),
                addNewStep = true,
                $step = _createStep();
            //Add Step
            $step.appendTo($page.find('.panel-body'));
            if ($page.find('.survey-step:not(.fake-step)').length > 1) {
                $page.find('.survey-page-options .survey-page-move').show();
            }
            $step.requirequestion.on('click', 'input', function () { dirty = true; });
            $step.on('click', '.survey-step-type li', function (e, surveystepid, content, required) {
                e.preventDefault();
                var $this = $(this);
                if ($this.hasClass('dropdown-submenu')) return;

                var steptype = parseInt($this.data('steptype'), 10), questiontype = parseInt($this.data('questiontype'), 10);
                //Add stepid when we load survey
                if (surveystepid) {
                    $step.data('surveystepid', surveystepid);
                    if (!required) {
                        $step.requirequestion.find('input:first').click();
                    }
                }
                $step.typeInput.val($this.text());
                $step.data('questiontype', questiontype);
                $step.data('steptype', steptype);
                //Add question
                if (steptype === 2) {
                    _addQuestion($step, questiontype, surveystepid, content);
                } else {
                    //Add Other step type
                    $step.stepContent.empty();
                    if ($step.$question) {
                        $step.$question.remove();
                        delete $step.$question;
                        _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
                    }
                    _addOtherStepType($step, steptype, content);
                }
                //Add new container for step
                if (addNewStep === true) {
                    addNewStep = false;
                    $step.removeClass('empty');
                    _addStep();
                }
                dirty = true;
            });
            //Hide add page 
            $step.dropdown.on('show.bs.dropdown', function () {
                if ($step.closest('.survey-step').hasClass('empty') === true && $page.find('.survey-step:not(.fake-step, .empty)').length > 0) {
                    $step.dropdown.find('.epagebreak').show();
                } else {
                    $step.dropdown.find('.epagebreak').hide();
                }
            });
        },
        //Add common Step Type content like: Text/media, PageBreak
        _addOtherStepType = function ($step, type, content) {
            //$step.data("questiontype", "");
            $step.requirequestion.hide();
            switch (type) {
                case 3:
                    var $textarea = html.get(enumstep[type], { type: type, content: content });
                    $step.stepContent.empty().html($textarea);
                    break;
                case 1:
                    _addPage($step.data('surveystepid'));
                    $step.remove();
                    $step = null;
                    break;

            }
        },
        //Add Question Step Type like: Radio, Dropdown, checkboxes, TextArea, DateSelection, Textbox
        _addQuestion = function ($step, questiontype, surveystepid, content) {
            var $question = $step.$question || undefined, $answerscopy = undefined;
            $step.requirequestion.show();
            //make backup old question
            if ($question) {
                //Break the function when question type not change
                if ($question.type === questiontype) {
                    return;
                }
                if (!content) {
                    content = $question.find(".survey-question-content .form-control[name=questiontext]:first").val();
                }
                if ($question.type <= 3) {
                    $answerscopy = $question.questionAnswers.find('li').clone();
                }
                if ($question.type === 7) {
                    _NPScount--;
                }
            }
            $question = _createQuestion(questiontype, content);
            $step.stepContent.empty().html($question);
            //Sotrable Answers

            if (typeof content === 'undefined') {
                $question.questionAnswers
                _initAnswerOrders($question.questionAnswers);
            }
            //set data
            $step.$question = $question;
            $question.addanswer.off().click(function (e, surveyanswerid, content) {
                _createAnswer($question, surveyanswerid, content);
                dirty = true;
            });
            if (!surveystepid) {
                $question.addanswer.trigger("click");
            }
            $question.find('.survey-question-content textarea:first').off('click').click(function () {
                $(this).removeClass('has-error');
            });
            _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
            $question.type = questiontype;
            if (questiontype <= 3 && $answerscopy) {
                $question.questionAnswers.html($answerscopy);
            }
        },
        /*-- HELPERS --*/
        _initAnswerOrders = function ($container) {
            $container.sortable({
                handle: '.survey-question-move',
                cancel: '',
                placeholder: "survey-drop-place",
                update: function (e, ui) {
                    _cheackOrderNumber($(this), '.question-answer-order');
                }
            });
        },
        _cheackOrderNumber = function ($container, selector, after) {
            var allQuestion = $container.find(selector);
            dirty = true;
            for (var i = 0, n = allQuestion.length; i < n; i++) {
                allQuestion[i].innerHTML = (i + 1) + (after || "");
            }
            return n;
        },
        _cheackPagesNumber = function () {
            if (_cheackOrderNumber($screen.surveysteps, ".survey-page-number") === 1) {
                $screen.surveysteps.find('.survey-page-options:first').hide();
            } else {
                $screen.surveysteps.find('.survey-page-options').show();
            }

        },
        _removeEmptyPages = function () {
            $screen.surveysteps.find('.survey-page').each(function () {
                var $page = $(this), n = $page.find('.survey-step:not(.fake-step)').length;
                if (n === 0) {
                    $page.find('.survey-page-remove').trigger('click');
                    return;
                }
                if ($page.find('.survey-step.empty').length === 1) {
                    $page.find('.survey-page-options .survey-page-move').hide();
                }
                if (n > 1) {
                    $page.find('.survey-page-options .survey-page-move').show();
                }
            });
        },
        _save = function () {
            _survey_.publicsurveyid = $screen.publicsurveyid.val();
            _survey_.name = $screen.surveyname.val().trim();
            _survey_.status = $screen.surveystatus.data('status');
            _survey_.expirydate = $screen.surveyexpirydate.val();
            _survey_.surveysteps = _collectSteps();
            if (formCorrect === true) {
                ee.indiOn();
                var request = (_survey_.publicsurveyid === "00000000-0000-0000-0000-000000000000") ? _survey_.Save() : _survey_.Update();
                request.then(function () {
                    if (_survey_.expirydate) {
                        _survey_.expirydate = ee.tools.time(_survey_.expirydate).fromUTC("L");
                    }
                    dirty = false;
                    $screen.surveysteps.find('.survey-step:not(.fake-step, .empty)').remove();
                    _removeEmptyPages();
                    _loadEditedSurvey();
                    ee.changeHashQuietly("#/surveys/edit/" + _survey_.publicsurveyid);
                }).catch(function (e) { console.log(e) }).always(function(){ee.indiOff()});
            } else {
                html.modal.info(ee.t.surveyerrorsave, ee.t.alert + "!");
            }
        },
        _collectSteps = function (preview) {
            var steps = [], fakeIDs = { 'surveystepID': 1, 'surveystepanswerID': 1 };
            formCorrect = true;
            $screen.surveysteps.find('.survey-page').each(function (i, v) {
                var $page = $(this), $steps = $page.find('.survey-step:not(.empty,.fake-step)');
                if ($steps.length <= 0) return true;//continue();
                if (i > 0) {
                    steps.push({ surveystepid: $page.data('surveystepid'), surveysteptype: 1, questiontype: 0, content: "", required: false, surveystepanswers: [] });
                }
                $steps.each(function () {
                    var $step = $(this),
                        item = {
                            surveystepid: $step.data("surveystepid") || ((preview === true) ? fakeIDs.surveystepID++ : 0),
                            surveysteptype: parseInt($step.data("steptype")) || 0,
                            questiontype: parseInt($step.data("questiontype")) || 0,
                            content: "",
                            required: false,
                            surveystepanswers: []
                        };

                    if (item.questiontype > 0) {
                        item.content = $step.find(".survey-question-content .form-control:first").val();
                        item.required = $step.find(".requirequestion input:first").is(":checked");
                        //Answers Data
                        var $answers = $step.find(".question-answers"), sequence = 0;
                        if (item.questiontype < 4 && item.questiontype > 0) {
                            $answers.find("li input").each(function () {
                                if (this.value) {
                                    item.surveystepanswers.push({ surveystepanswerid: ($(this).data("surveyanswerid") || ((preview === true) ? fakeIDs.surveystepanswerID++ : 0)), content: this.value, sequence: sequence++ });
                                }
                            });
                        }
                    }
                    if (item.surveysteptype === 3) {
                        item.content = $step.find(".step-text-media").html().trim();
                    }
                    //content for question is required
                    if (!item.content && item.surveysteptype != 1) {
                        if (item.questiontype > 0 && item.surveysteptype === 2) {
                            formCorrect = false;
                            $step.find(".survey-question-content textarea:first").addClass('has-error');
                        } else if (item.surveysteptype === 3) {
                            formCorrect = false;
                            $step.find(".step-text-media").addClass('has-error');
                        }
                    }
                    steps.push(item);
                });


            });
            //Add ConfimrationPage      
            steps.push({ surveystepid: ($screen.confirmpage.data("surveystepid") || 0), surveysteptype: 4, questiontype: 0, content: $screen.confirmcontent.html().trim(), required: false, surveystepanswers: [] });
            //Add Expired Page
            steps.push({ surveystepid: ($screen.expiredpage.data("surveystepid") || 0), surveysteptype: 5, questiontype: 0, content: $screen.experiedcontent.html().trim(), required: false, surveystepanswers: [] });
            return steps;

        },
        _loadEditedSurvey = function () {
            //load Survey
            $screen.surveyname.val(_survey_.name);
            $screen.publicsurveyid.val(_survey_.publicsurveyid);
            $screen.surveyexpirydate.val(_survey_.expirydate);
            _survey_.surveysteps.each(function () {
                var step = this;
                if (step.surveysteptype === 4) {
                    //updateConfirmation
                    $screen.confirmpage.find('.step-text-media:first').html(step.content);
                    $screen.confirmpage.data("surveystepid", step.surveystepid);
                    return;
                }
                if (step.surveysteptype === 5) {
                    //updateConfirmation
                    $screen.expiredpage.find('.step-text-media:first').html(step.content);
                    $screen.expiredpage.data("surveystepid", step.surveystepid);
                    return;
                }
                var $laststep = $screen.find(".survey-step.empty");
                $laststep.data("surveystepid", step.surveystepid);
                //surveysteptype = 2 is question sub-menu
                if (step.surveysteptype !== 2) {
                    $laststep.find('.dropdown-menu > li[data-steptype=' + step.surveysteptype + ']').trigger('click', [step.surveystepid, step.content]);
                }

                if (step.surveysteptype === 2 && step.questiontype > 0) {
                    $laststep.find('.dropdown-menu > li[data-questiontype=' + step.questiontype + ']').trigger('click', [step.surveystepid, step.content, step.required]);
                    //Answers to question
                    if (step.questiontype < 4 && step.surveystepanswers.length > 0) {
                        for (var j = 0, m = step.surveystepanswers.length; j < m; j++) {
                            $laststep.find(".action-add-answers:first").trigger("click", [step.surveystepanswers[j].surveystepanswerid, step.surveystepanswers[j].content]);
                            //$laststep.find(".question-answers > li:last input").val(step.surveystepanswer[j].content).attr("data-surveyanswerid", step.surveystepanswer[j].surveystepanswerid);
                        }
                    } else {
                        $laststep.find(".action-add-answers:first").trigger("click");
                    }
                }
            });
            $screen.surveystatus.find('li[data-status=' + _survey_.status + ']').click();
            dirty = false;
        },
        /*-- REDACTOR -- */
        _enableRedactor = function ($editedText) {
            if (activeEditor || !$editedText) {
                return;
            }
            if (ee.editors.core._loadColorPicker(function () { _enableRedactor($editedText); }) === false) {
                return;
            }
            if (!loadedEditor) {
                _loadRedactor($editedText);
                return;
            }
            $editedText.removeClass("has-error");
            activeEditor = $editedText.redactor({
                buttons: ['bold', 'italic', 'underline', 'deleted', 'link'],
                focus: true,
                lang: parent.ee.t._locale,
                plugins: ['elasticemail', 'source', 'alignment', 'fontfamily', 'fontsize', 'fontcolor', 'removeFormats', 'lineheight', 'fileManager'],
                callbacks: {
                    init: function () {
                        dirty = true;
                        $(document).mousedown(function (event) {
                            event.stopPropagation();
                            var el = $(event.toElement || event.target);
                            if (el.closest(".survey-step-content, .redactor-in, .redactor-toolbar, .sp-container, .redactor-dropdown, #redactor-modal-box, .emodal-overlay, #efilemanagerframe2").length > 0) {
                                return;
                            }
                            _disabledRedactor();
                        });
                    },
                    destroy: function () {
                        if (this.$toolbar) {
                            this.$toolbar.find("input.spectrum").spectrum('destroy');
                        }
                        $editedText = activeEditor = false;
                    },
                    click: function (e) {
                        this.observe.customFields();
                    }
                },
                mediaManager: html.widget.FileManager,
                mediaManagerUpload: html.widget.FileManagerUpload
            });
        },
        _disabledRedactor = function () {
            if (!activeEditor) {
                return;
            }
            activeEditor.redactor('core.destroy');
            $(document).off('mousedown');
        },
        _loadRedactor = function ($editedText) {
            if (_inprogress === false) {
                _inprogress = true;
                $.loadCssFile(ee.tools.getLibPath() + 'lib/redactor/redactor.css', function () {
                    $.cachedScript(ee.tools.getLibPath() + "lib/redactor/redactor.js?redactorVersion=" + ee.libVersion.redactor, {
                        success: function () {
                            var locale = $.Redactor.opts.availableLang.indexOf(ee.t._locale.toLowerCase()) > -1 ? ee.t._locale.toLowerCase() : 'en';
                            if (locale != "en") {
                                $.cachedScript(ee.tools.getLibPath() + "lib/redactor/language/" + locale + ".js?redactorVersion=" + ee.libVersion.redactor, {
                                    success: function () {
                                        loadedEditor = true;
                                        _enableRedactor($editedText);
                                        return;
                                    }
                                });
                                return;
                            } else {
                                loadedEditor = true;
                                _enableRedactor($editedText);
                                return;
                            }
                        }
                    });
                });
            }
        },
        /* ------ */
        /*-- Public --*/
        that = {
            init: function (survey) {
                if (!ee.session.can.ModifySurveys) {
                    return ee.goTo("#/surveys");
                }
                _survey_ = (dataModel.isModel('Survey', survey) === true) ? survey : dataModel.create("Survey");
                $screen = html.get("CreateSurvey", {});
                _NPScount = 0;
                $screen.surveyname.keyup(function () { dirty = true; });
                //init Redactor editor
                $screen.on('click', '.step-text-media', function () { _enableRedactor($(this)); });
                //Experied date
                $screen.surveyexpirydate.datepicker({
                    dateFormat: ee.tools.localDateFormatDatepicker(),
                    changeMonth: true,
                    changeYear: true,
                    showButtonPanel: true,
                    minDate: 1,
                    beforeShow: function (input, inst) {
                        setTimeout(function () {
                            var $buttonPanel = $(input).datepicker("widget").find(".ui-datepicker-buttonpane:first");
                            $("<button>", { text: "Never", click: function () { $(input).val('Never').datepicker('hide'); } }).appendTo($buttonPanel).addClass("ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all");
                        }, 1);
                    }
                });
                //Status changed
                $screen.surveystatus.on('click', '.dropdown-menu > li', function () {
                    var $this = $(this), status = $this.data('status');
                    $screen.surveystatusinput.val($this.text());
                    $screen.surveystatus.data('status', status);
                    if (status === 1) {
                        $screen.expiredpage.hide()
                        $screen.surveystatus.addClass("border-success").removeClass("border-danger");
                        _toogleEditMode(false);
                    } else {
                        $screen.expiredpage.show();
                        $screen.surveystatus.addClass("border-danger").removeClass("border-success");
                        _toogleEditMode(true);
                    }
                    dirty = true;
                });
                //Remove Page
                $screen.on('click', '.survey-page-remove', function () {
                    var $page = $(this).closest('.survey-page'), newStepRemoved = false;
                    if ($page.find('.survey-step.empty').length === 1) {
                        newStepRemoved = true;
                    }
                    $page.remove();
                    _cheackPagesNumber();
                    if (newStepRemoved) {
                        _addStep();
                    }
                    _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
                });
                //Remove Step
                $screen.on('click', '.survey-step-remove', function () {
                    var $step = $(this).closest('.survey-step');
                    if (parseInt($step.data("questiontype"), 10) === 7) {
                        _NPScount--;
                    }
                    $step.remove();
                    _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
                    _removeEmptyPages();
                });
                //Remove Answers
                $screen.on('click', '.survey-question-remove', function () {
                    var $answers = $(this).closest('.question-answers');
                    $(this).closest('.form-group').remove();
                    _cheackOrderNumber($answers, ".question-answer-order");
                });
                //Net Promoter® Score efect
                $screen.on('click', '.answers-nps label', function () {
                    var $that = $(this), $npsTable = $that.closest('.answers-nps');
                    if ($that.hasClass('active') === false) {
                        $npsTable.find("td > label.active").removeClass('active');
                    }
                    $that.toggleClass('active');
                });
                //Hide Net Promoter® Score if one is exist
                $screen.on("show.bs.dropdown", '.survey-step-type', function (event) {
                    //Check if there is one active secion of NPS and hide option for select another
                    if (_NPScount > 0) {
                        $(this).find('li[data-questiontype="7"]').addClass("hidden");
                    } else {
                        $(this).find('li[data-questiontype="7"]').removeClass("hidden");
                    }
                });
                //Save button
                $screen.find('.survey-save').click(_save);
                //Prview
                $screen.find('.survey-preview').click(that.showPreview);
                //Init First page and First step
                _addPage();
                _addStep();

                if (_survey_ && _survey_.publicsurveyid && _survey_.publicsurveyid !== "00000000-0000-0000-0000-000000000000") {
                    _loadEditedSurvey();
                }

                //Click on step efects
                $screen.on('click.stepactive', function (event) {
                    $screen.find('.survey-step').removeClass('active');
                    var el = $(event.toElement || event.target), $step = (el.hasClass('survey-step') === true) ? el : el.closest('.survey-step');
                    if ($step.hasClass('empty') || $step.hasClass('fake-step')) return;
                    if ($step.length > 0) {
                        $step.addClass('active');
                    }
                });

                //Reset dirty to false
                dirty = false;
                return $screen;
            },
            showPreview: function () {
                //var screen = ee.view.screen;
                var survey = { surveyid: 0, name: $screen.surveyname.val(), status: $screen.surveystatus.data('status'), surveysteps: _collectSteps(true) };
                ee.api.surveyLivePreview(survey, function (content) {
                    var iframe = html.get("EmptyIframe", {}), modal = html.modal.create(iframe, { title: ee.t.preview, size: "emodal-lg", alignToScreenHeight: true, cancel: { title: ee.t.close } });
                    modal.$container.find(".emodal-content").css({ padding: 0 });
                    iframe.on('load', function () {
                        ee.indiOff();
                    });
                    var doc = iframe[0].contentWindow.document;
                    doc.open();
                    doc.write(content);
                    doc.close();
                });
            },
            isDirty: function () {
                return dirty;
            },
            initSortable: function () {
                //Sortable Steps
                $screen.surveysteps.sortable({
                    items: '.survey-step:not(.empty)',
                    handle: '.survey-step-move',
                    placeholder: "survey-drop-place",
                    cancel: '',
                    tolerance: "pointer",
                    opacity: 0.85,
                    update: function () {
                        _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
                        _removeEmptyPages();
                    }
                });

                //Sortable pages
                $screen.surveypagesortable.sortable({
                    items: '.survey-page',
                    handle: '.survey-page-move',
                    placeholder: "survey-drop-place",
                    cancel: '',
                    opacity: 0.5,
                    tolerance: "pointer",
                    update: function () {
                        $screen.surveysteps.find('.survey-step.empty').remove();
                        _addStep();
                        _removeEmptyPages();
                        _cheackPagesNumber();
                        _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
                    }
                });

                //Sortable pages
                $screen.surveypagesortable.sortable({
                    items: '.survey-page',
                    handle: '.survey-page-move',
                    placeholder: "survey-drop-place",
                    cancel: '',
                    opacity: 0.5,
                    tolerance: "pointer",
                    update: function () {
                        $screen.surveysteps.find('.survey-step.empty').remove();
                        _addStep();
                        _removeEmptyPages();
                        _cheackPagesNumber();
                        _cheackOrderNumber($screen.surveysteps, ".survey-question-number", '.');
                    }
                });
                _initAnswerOrders($screen.find('.question-answers'));
            }
        }
    return that;
})();
