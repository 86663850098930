/**
* Menagment sub accounts email credits.
* @namespace
* @memberof html.widget
*/
html.widget.ManageCreditsUI = (function ViewManageCredits() {
    "use strict";

    var that, modal, subaccount, dialogbox

        /*-- PUBLIC --*/
        that = {
            show: function (sub) {
                modal = false;
                subaccount = sub;

                EE_API.Account.LoadSubAccountsEmailCreditsHistory({ subaccountemail: sub.email })
                .then((data) => {
                    var history = new Collection.Data(data);
                    history.each(function () {
                        this.date = ee.tools.time(this.date).fromUTC("L");
                        if (this.amount !== undefined && this.amount < 0) this.className = 'eredtext';
                    });
                    return history
                })
                .then(function (history) {

                    dialogbox = html.get("ManageEmailCredits", {
                        credits: subaccount.emailcredits,
                        list: history.getAll(),
                        email: subaccount.email,
                        history: history.getLength() > 0
                    });

                    dialogbox.on("click", '#eaddcredit', that.addCredits);
                    dialogbox.on("click", '#eremovecredit', that.removeCredits);
                    modal = html.modal.create(dialogbox, {
                        title: html.get('CreditsManageHeader', { email: subaccount.email, credits: subaccount.emailcredits }).html(),
                        size: "emodal-lg",
                        buttons: [],
                        css: 'emodalCredits',
                        onClose: function(){
                            subaccount = false;
                            dialogbox.remove();
                            dialogbox = false;
                        }
                    });

                }).always(ee.indiOff).catch(function (err) { console.log(err) });
            },
            addCredits: function () {
                ee.indiOn();

                const params = {
                    credits: Number(dialogbox.addcreditamount.val()),
                    notes: dialogbox.addcreditnotes.val().trim(),
                    subaccountemail: subaccount.email
                };

                EE_API.Account.AddSubAccountCredits(params)
                .then(function () {
                    subaccount.emailcredits = Number(subaccount.emailcredits) + Number(params.credits);
                })
                .then(modal.close).always(ee.indiOff);
            },
            removeCredits: function () {
                ee.indiOn();

                const params = {
                    credits: Number(dialogbox.addcreditamount.val()),
                    notes: dialogbox.addcreditnotes.val().trim(),
                    subaccountemail: subaccount.email,
                    creditType: "Email"
                }

                EE_API.Account.RemoveSubAccountCredits(params)
                .then(function () {
                    subaccount.emailcredits = Number(subaccount.emailcredits) - Number(params.credits);
                }).then(modal.close).always(ee.indiOff);

            },
        };

    return that;
})();
