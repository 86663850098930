(function (root) {
    "use strict";
    var defaultOpt = {
        target: '.gallery-item',
        height: 672,
        width: 1000,
        background: "#fafafa",
        format: 'image/png',
        quality: 0.3,
        canvasheight: false,
        canvaswidth: false
    },


        _generateFormThumbnail = function($thumbnailContainer, opt) {
            var $inprogress = $thumbnailContainer.find(".eproccessing:first");
            var $thumbnailImg = $thumbnailContainer.find('img[data-src]').first();
            $inprogress.css('display', 'block');
            var basicFormInfo = { formName: $thumbnailContainer.data('formname'), ispublic: $thumbnailContainer.data('ispublic') };
            var formData = dataModel.create("Form", basicFormInfo);
            formData.load(basicFormInfo).then(function (result) {
                //remove animation for the time of thumbnail generation
                var trimmedcss = result.data.css.replace(/(:root {([^}]*)})/g, "");
                var thumbnaildata = '<div style="margin:auto;">' + result.data.html + '</div>' + '<style>body{background:#FFFFFF;display: flex;justify-content: center;height: 100 %;}</style>' + trimmedcss;
                //load all the stuff here, then connect html and css, then generate thumbnail
                var options = $.extend({}, defaultOpt, opt, basicFormInfo);
                return html.widget.Thumbnail.generate(thumbnaildata, options);
        })
        .then(function (thumbnailSrc) {
            return formData.saveThumbnail(thumbnailSrc, basicFormInfo.formName)})
        .then(function(data) {
                var src = ee.api.path + "/userfile" + data.screenshot + "?" + Date.now();
                $inprogress.remove();
                $thumbnailContainer.find('img').each(function() {
                    var that = $(this);
                    that.attr('src', src);
                })
            return true;
        })
        .catch(function (msg) {
            console.log('Error: ' + msg.error ? msg.error : msg);
            if(msg instanceof Error) {
                ee.captureStripeException(msg);
            } else {
                 ee.captureStripeMsg(msg.error ? msg.error : msg);
            }
            $thumbnailImg.parent().html($errorHTML);
            $inprogress.remove();
        });
        },
        that = {
            init: function ($screen, opt) {
                let options = $.extend({}, defaultOpt, opt);
                let $errorHTML = html.getraw("ScreenshotNotFound");

                options.brokenimage = html.getraw("ImageNotFound");
                //TODO: Use here the "fetch" method in the future
                var imagesToLoad = [];
             
                $screen.find(options.target).each(function (i, el) {
                    const $thumbnailContainer = $(el);
                    const $thumbnailImg = $thumbnailContainer.find('img[data-src]').first();
                    const $mediumScreenshotImg = $thumbnailContainer.find('img[data-src]').last();
                    const mediumScreenshotImg = $thumbnailContainer.find('img[data-src]').last().data("src");

                    if (mediumScreenshotImg)
                        imagesToLoad.push(ee.tools.loadImage(mediumScreenshotImg));
                    else {
                        imagesToLoad.push(true);
                        _generateFormThumbnail($thumbnailContainer, options);
                    }

                    // Reload thumbnail again
                    // so last edited webform image is up to date
                    if ($mediumScreenshotImg.data("isupdated")) {
                        _generateFormThumbnail($thumbnailContainer, options);
                        $thumbnailImg.hide();
                        $mediumScreenshotImg.css('opacity', 1);
                        $mediumScreenshotImg.removeAttr("data-isupdated");
                    }
                });

                Promise.all(imagesToLoad)
                .then(function(data) {
                    $screen.find(options.target).each(function (i, el) {
                        //connect it better
                        var $thumbnailContainer = $(el);
                        var $thumbnailImg = $thumbnailContainer.find('img[data-src]').first();
                        var $mediumScreenshotImg = $thumbnailContainer.find('img[data-src]').last();

                        $thumbnailImg.hide();
                        if (data[i] && data[i].src) {
                            $mediumScreenshotImg.attr('src', data[i].src).css('opacity', 1);
                        } else {
                            _generateFormThumbnail($thumbnailContainer, options);
                            $mediumScreenshotImg.css('opacity', 1)

                        }
                    })
                })
                .catch(function (msg) {
                    console.log('Error: ' + msg.error ? msg.error : msg);
                    if(msg instanceof Error) {
                        ee.captureStripeException(msg);
                    } else {
                         ee.captureStripeMsg(msg.error ? msg.error : msg);
                    }
                });

            }
        }
    root.FormThumbnail = that;
}(html.widget));
