/**
* Controller from the subaccouunt screen.
* @namespace
* @memberof ee.views
*/
ee.views['subaccount'] = (function ViewTemplates() {
    "use strict";

    var that, $screen, pagination, subaccountsList,

        __isIpPoolsAvailable = function () {
            return !ee.data.account.issub && ee.data.account.mailerid > 0
        },
        __loadIpPoolsSelect = function ($content, poolname) {
            var ippools;
            if (!ee.data.ippools) {
                ippools = new dataModel.IpPools();
                ee.data.ippools = ippools;
                ippools.Load(function () {
                    __showIpPoolsList();
                });
            } else {
                ippools = ee.data.ippools;
                __showIpPoolsList();
            }

            function __showIpPoolsList() {
                var options = html.get('IPpoolsSelectOptions', { list: ippools.getAll() });
                $content.ippoolsselect.html(options);
                if (poolname) $content.ippoolsselect.val(poolname);
            }
        },
        _formatSubAccounts = (subaccounts) => {
            _.map(subaccounts, (o) => {
                o.repstarhtml = html.widget.Stars.get(o.reputation).html;
                o.lastactivityformatted = ee.tools.time(o.lastactivity).fromUTC("L LT")
                if (o.monthlyrefillcredits === 0) {
                    o.monthlyrefillcreditsSTR = ee.t.none
                } else {
                    o.monthlyrefillcreditsSTR = String(o.monthlyrefillcredits);
                };
                if (o.dailysendlimit === 0) {
                    o.dailysendlimitSTR = ee.t.unlimited;
                } else {
                    o.dailysendlimitSTR = String(o.dailysendlimit);
                }

                o.totalemailssentSTR = o.totalemailssent.numberFormat();
            })
            _.map(subaccounts, (o) => {
                o.poolnameToDisplay = (o.mailerid < 0 && o.poolname === "default") ? "shared" : o.poolname;
            })
        },
        _subAccountSettings = function (opt) {
            const enableContactFeatures = ee.data.account.enablecontactfeatures && !(ee.data.account.activePlan.pricingbillingplan >= 5);
            const isUnlimitedPlan = ee.data.account.activePlan.typedesc === "ContactSubscription";
            const canSeeSubAccountPool = ee.data?.account?.can?.AdminModifyIP || !isUnlimitedPlan;
            var $content = html.get('AddSubAccount', { emailSize: parseInt(ee.data.account.emailsizelimitFormatted), IpPools: __isIpPoolsAvailable(), sub: opt.sub, isContactFeaturesEnabled: enableContactFeatures, canSeeSubAccountPool });
            if ($content.ippoolsselect) {
                __loadIpPoolsSelect($content, opt.sub.poolname);
            }
            var modal = html.modal.create($content, {
                title: opt.title,
                size: 'emodal-lg', 
                onShow: () => {
                    $('#emailsizelimitmax').on('change', () => {
                        let max = parseInt(ee.data.account.emailsizelimitFormatted);
                        if (parseInt($('#emailsizelimitmax').val()) > max) {
                            $('#emailsizelimitmax').val(max); 
                        }
                    })
                },
                buttons: [
                    {
                        name: opt.btnSuccessName,
                        css: 'btn_md btn_primary btn-add_sub',
                        callback: function () {
                            let form = $content.elasticCollectForm(),
                                sendingPermission = 0;
                            
                            if (form.sendingpermissionSMPT === true) sendingPermission += 1;
                            if (form.sendingpermissionAPI === true) sendingPermission += 2;
                            if (form.sendingpermissionInterface === true) sendingPermission += 4;

                            if (ee.data.account.activePlan.pricingbillingplan >= 5 && !opt.sub) {
                                form.enablecontactfeatures = true;
                            }

                            if (form.enableprivateiprequest) {
                                delete form.poolname;
                            }
                            form.sendingPermission = sendingPermission;

                            const {sendingpermissionSMPT, sendingpermissionAPI, sendingpermissionInterface, ...trimmedForm} = form;
                            $(this).prop( "disabled", true );
                          
                            opt.request(trimmedForm);
                        }
                    }
                ],
                cancel: { title: ee.t.cancel, css: "eeform_btn_cancel" }
            });
            if (opt.validRules) {
                new html.Validator($content, {
                    submit: modal.$footer.find('.btn-add_sub'),
                    inputs: opt.validRules
                });
            }
            if ($content.ippoolsselect) {
                $content.enableprivateiprequest.on('change', function () {
                    var disabled = $content.ippoolsselect.prop('disabled');
                    $content.ippoolsselect.prop('disabled', !disabled);
                })
            }
            return modal;
        }

    /*-- PUBLIC --*/
    let dropdownMenuIsClicked;
    that = {
        show: function () { 
            ee.goTo('#/account/subaccounts');
            return;
        },
        
        setupMenuItemsOnClick: (row) => {
            dropdownMenuIsClicked = true;
            if (row == null) {return;}
            $(row.parentElement).off('click');

            $(row.parentElement).on('click', '.eloadstatistics', function () {
                const subaccount = _.find(pagination.getCurrentData(), o => o.email === $(this).data("email"));
                that.showStatistic(subaccount);
                dropdownMenuIsClicked = false;
            });

            $(row.parentElement).on("click", ".edeletesubaccount", function () {
                const subaccount = _.find(pagination.getCurrentData(), o => o.email === $(this).data("email"));
                if (!subaccount) return html.modal.info(email + ' not found');

                html.modal.confirm(ee.t.subaccountdeleteconfirm, ee.t.subaccountdeleteheader, function () {
                    ee.indiOn();
                    EE_API.Account.DeleteSubAccount({ subaccountemail: subaccount.email })
                    .then(function () {
                        subaccountsList = _.remove(pagination.getCurrentData(), o => o.email == subaccount.email)
                        subaccount = false;
                        pagination.updateData(pagination.getCurrentData());
                        that.showList();
                    }).always(ee.indiOff);
                    ee.indiOff();
                }, false, { confirmTitle: ee.t.deletes, confirmCSS: "btn_destructive btn_lg" })
                dropdownMenuIsClicked = false;
            });

            $(row.parentElement).on("click", ".eeditaccount", function () {
                const subaccount = _.find(pagination.getCurrentData(), o => o.email === $(this).data("email"));
                that.showEdit(subaccount);
                dropdownMenuIsClicked = false;
            });

            $(row.parentElement).on("click", ".eaddcredits", function () {
                const subaccount = _.find(pagination.getCurrentData(), o => o.email === $(this).data("email"));
                if (!subaccount) {
                    throw new Error('subaccount not found');
                    return;
                };
                html.widget.ManageCreditsUI.show(subaccount);
                dropdownMenuIsClicked = false;
            });
            // Subaccount drop-down
            $(row.parentElement).on("click", ".eloadaccount", function () {
                const subaccount = _.find(pagination.getCurrentData(), o => o.email === $(this).data("email"));
                
                ee.session.loadSubAccount(subaccount.email, '#/account', subaccount.email);
                dropdownMenuIsClicked = false;
            });

        },
        showList: function () {
            dropdownMenuIsClicked = false;
            ee.tools.setupMutationObserver('.tablerow', dropdownMenuIsClicked, that.setupMenuItemsOnClick);
            ee.frame.content.empty();
            const CHUNK_SIZE = 10;

            subaccountsList = dataModel.create("SubaccountsList");
            $screen = html.get("SubAccountsDetail", { admin: ee.data.account, issub: ee.data.account.issub, privateIP: ee.data.account.mailerid > 0 });
            $screen.appendTo(ee.frame.content);

            const currentPage = ee.Router.parameter('page') ? ee.Router.parameter('page') : 1;
            const end = (currentPage === 1) ? 0 : (currentPage+1) * CHUNK_SIZE
            EE_API.Account.GetSubAccountList({limit: CHUNK_SIZE, offset: end})

            .then((subaccountsList) => {
                _formatSubAccounts(subaccountsList);
                
                pagination = new html.PaginationPromise(subaccountsList, {
                    template: 'SubAccountList',
                    resultcontainer: $screen.paginationresulttable,
                    footercontainer: '.paginationlist',
                    perpage: CHUNK_SIZE,
                    paginationinfo: false,
                    firstlast: false,
                    param: { privateIP: ee.data.account.mailerid > 0 },
                    totalElements: ee.data.account.subaccountscount,
                    loadElements: EE_API.Account.GetSubAccountList,
                    searchElements: EE_API.Account.GetSubAccountList,
                    formatElements: _formatSubAccounts,
                    
                }).eventChangePage(() => { 
                    dropdownMenuIsClicked = false;
                    html.reloadTooltip($screen);
                });
                
                //-- Events

                // Bind Search
                html.bindSearch($screen.searchsubaccount, function (searchSTR) {
                    dropdownMenuIsClicked = false;
                    if (searchSTR === '') { ee.t.dynamicnosubaccounts = ee.t.nosubaccounts; } else { ee.t.dynamicnosubaccounts = ee.t.nosearchresults; }

                    searchSTR = searchSTR.replace(/\+/g, '\\+');
                    searchSTR = searchSTR.replace(/\?/g, '\\?');

                    pagination.searchfilter(searchSTR, ['email']);
                    ee.t.dynamicnosubaccounts = ee.t.nosearchresults;
                    
                });

                //refresh
                $screen.refresh.click(function () {
                    subaccountsList.isLoaded = false;
                    that.showList();
                });
                // //Add focus efect to tr in table
                html.addDropdownFocus($screen.paginationresult);

                // Custom branding
                if ($('#eshowcustombranding')) {
                    $('#eshowcustombranding').on('click', that.showCustomBranding);
                }

                // Add subaccounts
                if ($('#eshowaddsubaccount')) {
                    $('#eshowaddsubaccount').on('click', that.showAdd);
                }
            }).catch(function (e) { console.log(e); });
        },
        showAdd: function () {
            var modal = _subAccountSettings({
                sub: false,
                title: ee.t.addsubaccountspopuptitle,
                btnSuccessName: ee.t.addsubaccountspopuptitle,
                request: function (query) {
                    ee.indiOn();
                    dataModel.create('Subaccount').Create(query).then(function () {
                        subaccountsList.isLoaded = false;
                        that.showList();
                        modal.close();
                    }).always(ee.indiOff);
                },
                validRules: [
                    { name: 'email', validFunc: function () { return ee.tools.isValidEmail(this.val().trim()) }, msg: ee.t.eeform_error_email },
                    { name: 'password', validFunc: function () { return this.val().length >= 6 }, msg: ee.t.eeform_error_password },
                    {
                        name: 'confirmPassword', validFunc: function (inputs) {
                            var password = inputs.get('name', 'password');
                            if (password && password.$elem) return this.val() === password.$elem.val();
                        }, msg: ee.t.eeform_error_confirm
                    }
                ]
            });
        },
        showEdit: function (subaccount) {
            subaccount.grantedSendingPermission = ee.tools.getSendingPermission(subaccount.sendingpermission);
            var modal = _subAccountSettings({
                sub: subaccount,
                title: ee.t.editsubaccount,
                btnSuccessName: ee.t.save,
                request: function (query) {
                    ee.indiOn();

                    const params = {...query, subaccountemail: subaccount.email}

                    EE_API.Account.UpdateSubAccount(params)
                    .then(function () {
                        subaccountsList.isLoaded = false;
                        that.showList();
                        modal.close();
                        ee.indiOff();
                    })
                    .catch((err) => {
                        modal.close();
                        ee.indiOff();
                        
                    });
                }
            });
        },
        showCustomBranding: function () {
            ee.api.loadAdvancedoptions(function() {                       
                var $content = html.get('CustomBranding', {
                    account: ee.data.account,
                    EnablePrivateBrandingCustomHeader: ee.data.account.enableprivatebrandingcustomheader,
                    hideLogin: ee.session.hasOption('CustomBrandingHideLogin')
                }),
                modal = html.modal.create($content, {
                    title: 'Custom branding',
                    cancel: { title: ee.t.cancel, css: "eeform_btn_cancel" },
                    buttons: [
                        {
                            name: ee.t.save,
                            css: 'btn_md btn_primary',
                            callback: function () {

                                const form = $content.elasticCollectForm();
                                const query = {
                                    enablePrivateBranding: form.enableprivatebranding,
                                    logoUrl: form.logourl,
                                    paymentUrl: form.paymenturl,
                                    privateBrandingUrl: form.privatebrandingurl,
                                    smtpAddress: form.smtpaddress,
                                    smtpAlternative: form.smtpalternative,
                                    supportLink: form.supportlink,
                                    customBouncesDomain: form.custombouncesdomain,
                                    authProviderURL: form.authority,
                                    authProviderClientID: form.clientid,
                                    authProviderLogoutUrl: form.logouturl,
                                    authProviderHideLogin: form.hidelogin,
                                    AddLogoToSubAccountsEmails: form.putlogoinemails,
                                }

                                if (ee.data.account.enableprivatebrandingcustomheader) {
                                    query.PrivateBrandingCustomHeader = form.privatebrandingcustomheader;
                                }

                                /*NOTE: Code below shoudby change when Account dataModel willby available */
                                ee.api.request('/account/updatecustombranding', query, function (data) {
                                    ee.indiOff();
                                    if (!data.success) return html.modal.error(data);
                                    ee.data.account.enableprivatebranding = query.enablePrivateBranding;
                                    ee.data.account.logourl = query.logoUrl;
                                    ee.data.account.paymentformurl = query.paymentUrl;
                                    ee.data.account.privatebrandingurl = query.privateBrandingUrl;
                                    ee.data.account.smtp = query.smtpAddress;
                                    ee.data.account.smtpalternative = query.smtpAlternative;
                                    ee.data.account.supportlink = query.supportLink;
                                    ee.data.account.authproviderurl = query.authProviderURL;
                                    ee.data.account.authproviderclientid = query.authProviderClientID;
                                    ee.data.account.authproviderlogouturl = query.authProviderLogoutUrl;
                                    ee.data.account.privatebrandingcustomheader = query.PrivateBrandingCustomHeader;
                                    ee.data.account.addlogotosubaccountsemails = query.AddLogoToSubAccountsEmails;
                                    if (ee.session.hasOption('CustomBrandingHideLogin') && !query.authProviderHideLogin) {
                                        ee.data.account.options = ee.data.account.options.replace('CustomBrandingHideLogin', '');
                                    }
                                    if (!ee.session.hasOption('CustomBrandingHideLogin') && query.authProviderHideLogin) {
                                        ee.data.account.options = ee.data.account.options + ',CustomBrandingHideLogin';
                                    }
                                    modal.close();
                                    that.show();
                                });
                            }
                        }
                    ]
                });
            //fixed for invisible tooltip
            modal.$container.parent().css("z-index", "10");
            modal.$container.css("z-index", "11");

            $content.enableprivatebranding.on('change', function () {
                $content.find('.eeform_checkbox_toggle').toggleClass('eeform_toggle_off');
                $content.find('.eeform_toggle').toggleClass('eeform_toggle_off');
                $content.find('.eeform_toggle input:not(.form-control_disabled)').each(function () {
                    var prop = $(this).prop('disabled');
                    $(this).prop('disabled', !prop);
                });
                $content.find('.eeform_toggle textarea:not(.form-control_disabled)').each(function () {
                    var prop = $(this).prop('disabled');
                    $(this).prop('disabled', !prop);
                });
            });
            })

        },
        showStatistic: function (subaccount) {
            subaccount.statusname = ee.t[subaccount.status.toLowerCase()];
            html.modal.create(html.get('SubAccountStatistic', { sub: subaccount, IpPools: __isIpPoolsAvailable() }), {
                size: 'emodal-lg',
                cancel: { title: ee.t.close, css: "eeform_btn_close" }
            });
        }
    }
    return that;
})();
