(ee.views["journey"] = function JourneyScreen() {

    var _startJourneyEditor = function (journeyid, frameoverlay) {
            var Editor = new ee.plugins.Journey({
                journeyid: journeyid,
                container: '#journey-container'
            });

            return $.when(frameoverlay, Editor.load()).then(function (overlay, journeyData) {

                _bindEditorWithOverlay(Editor, overlay);
                overlay.$container.loading.remove();
                return Editor.init();

            }).catch(function (err) { console.error(err) });
        },
        _initializeOverlay = function () {

            var def = $.Deferred(),
                frameoverlay = new html.Frameoverlay('', {
                    templateName: 'JourneyFrameOverlayEditor',
                    animation: false
                });

            frameoverlay.on('afterShow', function () {

                var overlay;

                _decorator.overlay = frameoverlay;

                overlay = _decorator.getDecorator('NavBar');
                overlay = overlay.getDecorator('Canvas');
                overlay = overlay.getDecorator('ZoomWidget');
                overlay = overlay.decorate();

                _renderEditBodyHight(overlay);

                def.resolve(overlay);

            });
            return def.promise();
        },
        _bindEditorWithOverlay = function (Editor, overlay) {

            var zoom_setings = ee.plugins.Journey._conf.zoom_setings;

            overlay.on("afterResize", function () {
                _renderEditBodyHight(overlay);
                Editor.fitEditorToParent();
            });
            overlay._el.$topNav.on('click', '.exit-journey', function () {
                ee.goTo('#/journey');
            });

            overlay._el.$topNav.on('click', '.publish-journey', function () {

                html.modal.confirm('Publish journey?', undefined, function () {
                    ee.indiOn();
                    Editor.publish().then(function () {
                        ee.goTo('/journey');
                        ee.indiOff();
                    })
                });

            });

            overlay._el.$canvasContainer.on('scale.change', function (e, val) {

                var widget = overlay._el.$zoomWidget;

                val = Number(val);
                widget.find('.quo').text(val.toFixed(1))
                widget.find('.in').removeClass('off')
                widget.find('.out').removeClass('off');
                if (val >= zoom_setings.maxScale) widget.find('.in').addClass('off');
                if (val <= zoom_setings.minScale) widget.find('.out').addClass('off');

            });
            overlay._el.$zoomWidget.on('click', '.in', function () {
                if (Editor._views && Editor._views.PaperView) {
                    Editor._views.PaperView.zoomIn();
                }
            });
            overlay._el.$zoomWidget.on('click', '.out', function () {
                if (Editor._views && Editor._views.PaperView) {
                    Editor._views.PaperView.zoomOut();
                }
            });
        },
        _showAddJourneyUI = function (JourneysList, callback) {
            var $content = html.get("AddJourneyModal", {}),
                modal = html.modal.create($content, {
                    title: "Add journey",
                    buttons: [
                        {
                            name: "Save",
                            callback: function () {

                                var value = $content.find("#ejourneyname").val(),
                                    stepName = $content.find('#ejourney_step').val();
                                ee.indiOn();

                                JourneysList.Add(value, _createStartingStep(stepName))
                                    .then(function () {
                                        if (callback) callback();
                                        modal.close();
                                    });
                            }
                        }
                    ]
                });
        },
        _createStartingStep = function (stepName) {
            var stepObj = { action: 15 };

            switch (stepName) {
                case "manual":
                    stepObj.StartOnContactEvent = 0;
                    break
                case "addedtolist":
                    stepObj.StartOnContactEvent = 1;
                    stepObj.content = JSON.stringify({ target: 0 });
                    break;
            }
            return stepObj;
        },
        _renderEditBodyHight = function (frameoverlay) {

            var $editbody = frameoverlay._el.$editorBody,
                $topnav = frameoverlay._el.$topNav;

            $editbody.height(frameoverlay.$container.height() - $topnav.height())
        },
        _load = {
            init: function () {
                //var frameoverlay = _initializeOverlay();
                return $.when(_load.graphLibraryDependencies())
                    .then(function () {
                        return $.when(_load.graphLibraryCoreAndPlugins());
                    })
                    .then(function () {
                        return $.when(
                            _load.editorCore()
                        );
                    });
            },
            graphLibraryDependencies: function () {
                if (window.Backbone && window._) return true;
                return $.when(
                    $.cachedScript("https://cdnjs.cloudflare.com/ajax/libs/lodash.js/3.10.1/lodash.js")
                ).then(function () {
                    return $.when(
                        $.cachedScript("https://cdnjs.cloudflare.com/ajax/libs/backbone.js/1.3.3/backbone.js"),
                        $.cachedScript("https://cdnjs.cloudflare.com/ajax/libs/dagre/0.8.2/dagre.min.js"),
                        $.cachedScript("https://dagrejs.github.io/project/graphlib/v2.1.2/graphlib.min.js")
                    );
                });
            },
            graphLibraryCoreAndPlugins: function () {
                if (window.joint) return true;
                return $.when(
                    $.cachedScript("https://cdnjs.cloudflare.com/ajax/libs/jointjs/2.0.0/joint.min.js"),
                    $.loadCssFile("https://cdnjs.cloudflare.com/ajax/libs/jointjs/2.0.0/joint.min.css")
                );
            },
            editorCore: function () {
                if (ee.plugins.Journey) return true;
                return $.when(
                    $.cachedScript(ee.tools.getLibPath() + "plugins/journey-editor.js")
                );
            }
        },
        _decorator = {
            overlay: undefined,
            decorate: function () {
                if (!this.overlay) throw new Error('Set property overlay in _decoratorUI');

                var $topNav = this.overlay.$container.find(".journey-editor-topnav:first"),
                    $canvascontainer = this.overlay.$container.find(".editor-content"),
                    $editorBody = this.overlay.$container.find('.editor-body');

                this.overlay._el = {
                    $topNav: $topNav,
                    $canvasContainer: $canvascontainer,
                    $editorBody: $editorBody
                };

                return this.overlay;
            },
            getDecorator: function (deco) {
                _decorator[deco].prototype = this;
                return new _decorator[deco];
            },
            NavBar: function () {
                this.decorate = function () {
                    this.NavBar.prototype.decorate();
                    var $template = html.get('JourneyTopNav');
                    this.overlay._el.$topNav.append($template);
                    return this.overlay;
                }
            },
            Canvas: function () {
                this.decorate = function () {
                    this.Canvas.prototype.decorate();
                    var $template = html.get('JourneyCanvas');
                    this.overlay._el.$canvasContainer.append($template);
                    return this.overlay;
                }
            },
            ZoomWidget: function () {
                this.decorate = function () {
                    this.ZoomWidget.prototype.decorate();
                    var $template = html.get('JourneyZoomWidget');
                    this.overlay._el.$canvasContainer.append($template);
                    this.overlay._el.$zoomWidget = $template;
                    return this.overlay;
                }
            }
        },
        that = {
            show: function () {
                var that = this;
                if (!ee.session.can.AdminView) return ee.goTo('#/contacts');

                var JourneysList = dataModel.create("JourneysList");


                switch (ee.Router.action()) {

                    case 'show':
                        that.showMain();
                        return;
                    case 'edit':
                        var frameoverlay = _initializeOverlay();
                        $.when(JourneysList.require()).then(function () {
                            var journeyid = ee.Router.parameter('node0');


                            if (JourneysList.get('id', Number(journeyid))) {
                                _load.init().then(function () {
                                    _startJourneyEditor(ee.Router.parameter('node0'), frameoverlay);
                                });
                            } else {
                                ee.goTo('#/journey');
                            }
                        })
                        return;
                    default:
                        return ee.goTo('#/journey');
                }

            },
            showMain: function () {

                var $template = html.get("JourneyMain", {}),
                    JourneysList = dataModel.create("JourneysList"),
                    pagination;

                ee.frame.content.append($template);

                JourneysList.require().then(
                    function () {

                        pagination = new html.Pagination(JourneysList.getAll(), {
                            template: "JourneysTableBody",

                        });
                    }
                );

                $template.on('click', '.addjourney', function () {
                    _showAddJourneyUI(JourneysList, function () {
                        pagination.update(JourneysList.getAll());
                        ee.indiOff();
                    });
                });
                $template.on('click', '.deletejourney', function () {
                    var $this = $(this),
                        value = $(this).data('journeyid');

                    $this.prop('disabled', true);

                    JourneysList.Delete(value)
                        .then(function () {

                            pagination.update(JourneysList.getAll());

                        }).catch(function (err) {
                            $this.prop('disabled', false);
                        })

                })

                $template.on('click', '.pausejourney', function () {
                    var $this = $(this),
                        value = $(this).data('journeyid');

                    $this.prop('disabled', true);

                    JourneysList.ChangeItemStatus(value, 1)
                        .then(function () {

                            pagination.update(JourneysList.getAll());

                        }).catch(function (err) {
                            $this.prop('disabled', false);
                        })

                })

            }

        }

    return that;
}());